var eando = angular.module('aic.eando', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router'
]);

eando.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('eando', {
            parent: 'workspace',
            abstract: true,
            templateUrl: 'eando/eando.html'
        })
        .state('eando.list', {
            url: '/eando/list',
            templateUrl: 'eando/eando.list.html',
            controller: 'EAndOListCtrl'
        })
        .state('eando.add', {
            url: '/eando/add',
            templateUrl: 'eando/add/license.list.html',
            controller: 'EAndOAddLicenseListCtrl'
        })
        .state('eando.view', {
            url: '/eando/view?policyno&inscategory&carrier&insurancecategorydescr',
            templateUrl: 'eando/eando.view.html',
            controller: 'EAndOViewCtrl'
        });
    }
])

/**
 * E&O controllers.
 */
.controller('EAndOListCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'dialogs', 'EAndOService',
    function($scope, $state, $http, DTOptionsBuilder, dialogs, EAndOService) {
        $scope.eandos = [];
        var allEandOs = [];
        var activeEandOs = [];

        $scope.dtOptions = DTOptionsBuilder.newOptions().withBootstrap();

        EAndOService.getEandOs($scope.roleContext.ciprid)
            .then(function(res) {
                allEandOs = res.data;
                activeEandOs = _.filter(allEandOs, function(e){ return e.coveragestatus!=='Expired';});
                $scope.eandos = $scope.viewExpired ? allEandOs : activeEandOs;
            });

        $scope.viewEandOCoverage = function(eando) {
             $state.go('eando.view', {
                policyno: eando.policyno,
                inscategory: eando.insurancecategory,
                carrierpartyid: eando.carrierpartyid,
                carrier: eando.carriername,
                insurancecategorydescr: eando.insurancecategorydescr
            });
        };

        $scope.editEandOCoverage = function(eando) {
            var data = {
                title: 'Edit Errors and Omissions Coverage',
                eando : eando
            };

            dialogs.create('eando/dialogs/edit.html', 'EditOrgEandODialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                $http.post('/' + $scope.roleContext.ciprid + '/eando/covered', {
                    data: {
                        eando: eando
                    }
                })
                .then(function(res) {
                    $scope.loading = true;
                    EAndOService.getEandOs($scope.roleContext.ciprid)
                        .then(function(res) {
                            allEandOs = res.data;
                            activeEandOs = _.filter(allEandOs, function(e){ return e.coveragestatus!=='Expired';});
                            $scope.eandos = $scope.viewExpired ? allEandOs : activeEandOs;
                            $scope.loading = false;
                        });
                    });
            });
        };

        $scope.showExpired = function($event) {
            var checkbox = $event.target;
            $scope.eandos = checkbox.checked ? allEandOs : activeEandOs;

        };

        $scope.addEandO = function(){
            $state.go('eando.add');
        };

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando){
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando){
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando){
            return eando.coveragestatus.trim() == 'Pending';
        };

    }
])
.controller('EAndOAddLicenseListCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'LicenseService',
    function($scope, $state, $http, DTOptionsBuilder, LicenseService) {
        $scope.licenses = [];
        $scope.activeLicenses = [];
        $scope.selectedLicenseIds = [];
        $scope.applications = [];
        $scope.loading = true;

        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);



        /**
         * Load licenses
         */
        LicenseService.getLicenses($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.activeLicenses = res.data;
            $http.get('/' + $scope.roleContext.ciprid + '/licenses/applications/all')
            .then(function(res) {
                $scope.applications = res.data;
                $scope.licenses = _.union($scope.activeLicenses, $scope.applications);

                // Default select all
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });

                $scope.loading = false;
            });

        });



        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            $state.go('licenses.eando', {selectedLicenseIds: $scope.selectedLicenseIds});
        };


    }
])
.controller('EAndOViewCtrl', ['$scope', '$state', '$http', '$stateParams', 'DTOptionsBuilder',
    function($scope, $state, $http, $stateParams, DTOptionsBuilder) {
        $scope.coveredEandos = [];
        $scope.policyno = $stateParams.policyno;
        $scope.inscategory = $stateParams.insurancecategorydescr;
        $scope.carrierName = $stateParams.carrier;
        $scope.loading = true;

        $scope.dtOptions = DTOptionsBuilder.newOptions().withBootstrap();

        $http.get('/' + $scope.roleContext.ciprid + '/eando/covered', {
            params: {
                policyno: $stateParams.policyno,
                inscategory: $stateParams.inscategory,
                carrierpartyid: $stateParams.carrierpartyid
            }
        })
        .then(function(res) {
            $scope.loading = false;
            $scope.coveredEandos = res.data;
        });
    }
])

/**
 * Dialog controllers.
 */
.controller('EandOAddDialogCtrl', ['$scope', '$uibModalInstance', '$http',  '$filter', 'data', 'EAndOService',
    function($scope, $uibModalInstance, $http, $filter, data, EAndOService) {

        $scope.args = data;

        $scope.eando = {};
        $scope.selectedPolicy = undefined;
        $scope.selectedPolicyText = '';
        $scope.carriers = [];
        $scope.dateFormat = 'MMM dd, yyyy';
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.carrierTooltip = "<p>Only undewriters recognized in Alberta are on this list. </p><p> If you cannot find your undewriter on the list please contact the person who sold you the policy  to get the carrier underwriting your policy.</p>";
        // --- Initializers ---

        $http.get('/party/bytype?type=EO_CARRIER')
        .then(function(res) {
            $scope.carriers = res.data;
        });


        // --- Called from the dialog window ---
        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setEffectiveDate = function(selectedDate) {
            $scope.eando.effectivedate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        /**
         * Called when datepicker is dismissed
         * @param selectedDate
         */
        $scope.setEndDate = function(selectedDate) {
            $scope.eando.enddate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        /**
         * Called when a selection is made from typeahead
         * @param item
         * @param model
         * @param label
         */
        $scope.setPolicy = function(item, model, label) {
            $scope.selectedPolicyText = model.policyno;
            $scope.eando.inscategory = model.insurancecategory;
            $scope.eando.carrier = {carrierpartyid: model.carrierpartyid, party_nm: model.carriername};
        };

        /**
         * Carrier typeahead
         * @param item
         * @param model
         */
        $scope.setCarrier = function(item, model) {
            $scope.eando.carrier = {carrierpartyid: model.id, party_nm: model.party_nm};
        };

        /**
         * When 'OK' is clicked.
          */
        $scope.save = function() {
            $scope.eando.new = true;
            /* because Angular is dumb and doesn't allow duplicate empty ids */
            $scope.eando.coverageid = Math.random().toString(36).substring(8);
            $scope.eando.carriername = $scope.eando.carrier.party_nm;
            $scope.eando.carrierpartyid = $scope.eando.carrier.carrierpartyid;
            $scope.eando.policyno = _.has($scope.selectedPolicy, 'policyno') ? $scope.selectedPolicy.policyno : $scope.selectedPolicy;
            $scope.eando.coveragestatus = EAndOService.coveragestatus($scope.eando.effectivedate, $scope.eando.enddate);
            $uibModalInstance.close($scope.eando);
        };

        /**
         * When 'Reset' ic clicked
         */
        $scope.resetEO = function() {
            $scope.selectedPolicy = undefined;
            $scope.selectedPolicyText = '';

            $scope.eando = {};
            $scope.eando.certificateno = undefined;
            $scope.eando.carrier = {};
            $scope.eando.effectivedate = '';
            $scope.eando.enddate = '';
            $scope.eando.coveragestatus = '';
        };

        /**
         * When 'Cancel' is clicked.
         */
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])
.controller('EditOrgEandODialogCtrl', ['$scope', '$uibModalInstance', '$http',  '$filter', 'data', 'EAndOService',
    function($scope, $uibModalInstance, $http, $filter, data, EAndOService) {

        $scope.args = data;

        $scope.eando = $scope.args.eando;
        $scope.eando.oldPolicyNo = $scope.args.eando.policyno;
        $scope.eando.oldInsCategory =  $scope.args.eando.insurancecategory;
        $scope.eando.oldCarrierPartyId = $scope.args.eando.carrierpartyid;
        $scope.eando.carrier = {carrierpartyid: $scope.eando.carrierpartyid, party_nm: $scope.eando.carriername};
        $scope.selectedPolicy = undefined;
        $scope.carriers = [];
        $scope.datePickerFormat = 'M dd, yyyy';
        var dbFormat = "yyyy-MM-dd";
        $scope.carrierTooltip = "<p>Only undewriters recognized in Alberta are on this list. </p><p> If you cannot find your undewriter on the list please contact the person who sold you the policy  to get the carrier underwriting your policy.</p>";
        // --- Initializers ---

        $http.get('/party/bytype?type=EO_CARRIER')
            .then(function(res) {
                $scope.carriers = res.data;
            });


        // --- Called from the dialog window ---
        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setEffectiveDate = function(selectedDate) {
            $scope.eando.coveragestartdate = $filter('date')(selectedDate, dbFormat);
        };

        /**
         * Called when datepicker is dismissed
         * @param selectedDate
         */
        $scope.setEndDate = function(selectedDate) {
            $scope.eando.coverageenddate = $filter('date')(selectedDate, dbFormat);
        };

        /**
         * Carrier
         * @param item
         * @param model
         */
        $scope.setCarrier = function(item, model) {
            $scope.eando.carrier = {carrierpartyid: model.id, party_nm: model.party_nm};
        };

        /**
         * When 'OK' is clicked.
         */
        $scope.save = function() {

            $scope.eando.carriername = $scope.eando.carrier.party_nm;
            $scope.eando.carrierpartyid = $scope.eando.carrier.carrierpartyid;
            $scope.eando.coveragestatus = EAndOService.coveragestatus($scope.eando.effectivedate, $scope.eando.enddate);
            $uibModalInstance.close($scope.eando);
        };

        /**
         * When 'Cancel' is clicked.
         */
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])

/**
 * E&O services
 */
.service('EAndOService', ['$http','$filter',
    function($http, $filter) {

        this.addPoliciesToLicenses = function(ciprid, policies, licenseids) {
            return $http({
                url: '/' + ciprid + '/eando',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {policies: policies, licenseids: licenseids}
            });
        };
        this.getEandOs = function(ciprId) {
            return $http.get('/' + ciprId + '/eando');
        };

        this.coveragestatus = function(effectivedate, enddate){
            var current  = moment();
            if (!enddate) {
                enddate = moment();
            }
            if(current.isAfter(moment(effectivedate)) && current.isBefore(moment(enddate).add(1, 'd')))
                return 'Active';
            else if(moment(effectivedate).isAfter(current))
                return 'Pending';
            else
                return 'Expired';
        };

        this.delete = function(ciprid, policy){
            return $http.delete('/' + ciprid + '/eando/' + policy.coverageid);
        };

        this.getDaysToAllowVoid = function(ciprid){
            return $http.get('/' + ciprid + '/eando/daystoallowvoid');
        };

        this.checkIfDuplicate = function(eandos, eando){
           return _.some(eandos, { carrierpartyid: eando.carrierpartyid , policyno: eando.policyno, certificateno: eando.certificateno || "",
                effectivedate: $filter('date')(eando.effectivedate, "MMM dd, yyyy"), enddate: $filter('date')(eando.enddate, "MMM dd, yyyy") });

        };
    }
]);
