var cecourselookup = angular.module('aic.cecourselookup', [
    'ngResource',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'aic.ng-jquery'
]);

var mapForHoursRanges = [
    { id: 1, name: "ANY", from: 0.0, to: 100000.00 },
    { id: 2, name: "0-4", from: 0.0, to: 4.99 },
    { id: 3, name: "5-9", from: 5.0, to: 9.99 },
    { id: 4, name: "10-14", from: 10.0, to: 14.99 },
    { id: 5, name: "15+", from: 15.0, to: 100000.00 }
];

var courseDeliveryMethods = [
    { id: "CLS   ", code: "CLS", description: "Classroom"},
    { id: "COR   ", code: "COR", description: "Correspondence"},
    { id: "INT   ", code: "INT", description: "Internet"},
    { id: "MUL   ", code: "MUL", description: "Multiple"},
    { id: "SEM   ", code: "SEM", description: "Seminar"}
];

cecourselookup.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('cecourselookup', {
            parent: 'publiclookupentry',
            url: '/cecourselookup',
            templateUrl: 'lookups/cecourse/cecourselookup.html',
            controller: 'CELookupCtrl',
            data: {
                requiresLogin: false
            }
        });
    }
])
.controller('CELookupCtrl', ['OPTIONS','$rootScope', '$scope', '$state', '$stateParams', '$filter', '$http', '$uibModal', 'toastr','CELookupService', 'UtilService', 'DTOptionsBuilder','DTColumnDefBuilder',
    function(OPTIONS, $rootScope, $scope, $state, $stateParams, $filter, $http, $uibModal, toastr, CELookupService, UtilService, DTOptionsBuilder, DTColumnDefBuilder) {
        $scope._ = _;
        $scope.courses = [];
        $scope.courseProviders = [];
        $scope.alerts = [];
        $scope.selectedSourceProvider = undefined;
        $scope.loading = false;
        $rootScope.isAac = true;
        $scope.isBeforeToday = false;

        $scope.today = function() {
            $scope.courseDate = new Date();
        };
        $scope.today();

        $scope.open = function() {
            $scope.popup.opened = true;
        };

        $scope.popup = {
            opened: false
        };
        $scope.calendarOptions = {
            showWeeks: false
        };

        $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('processing', true)
            .withOption('pageLength', 100)
            .withOption('order', []);

        $scope.mapForHoursRanges = mapForHoursRanges;
        $scope.deliveryMethods = courseDeliveryMethods;

        CELookupService.getProviders()
            .then(function(response){
                $scope.providers = response.data;
            });

        CELookupService.getLocations()
            .then(function(response){
                $scope.locations = response.data;
            });

        $scope.search = function() {
           $scope.loading = true;
           CELookupService.search($scope.courseNumber, $scope.courseProvider, $scope.courseName, $scope.location, $scope.deliveryMethod, $scope.lifeHrs, $scope.asHrs, $scope.generalHrs, $scope.adjusterHrs,  $scope.courseDate)
                 .then(function(response){
                    var courses = response.data;
                    courses.forEach(function(c) {
                        c.deliveryMethod =  _.find($scope.deliveryMethods, {id: c.ce_delv_method_cd});
                        c.contactPhone = UtilService.toPhone(c.cntct_phone, c.cntct_area_code, c.cntct_ext);
                    });
                    $scope.loading = false;
                    $scope.courses = courses;
                });
        };

        $scope.checkCourseProvider = function() {
            if(typeof $scope.courseProvider !== 'object'){
                $scope.courseProvider = undefined;
            }
        };
        $scope.reset = function() {
            $scope.courses = [];
            $scope.courseProvider = undefined;
            $scope.courseName = undefined;
            $scope.location = undefined;
            $scope.deliveryMethod = undefined;
            $scope.lifeHrs = undefined;
            $scope.asHrs = undefined;
            $scope.generalHrs = undefined;
            $scope.adjusterHrs = undefined;
            $scope.courseDate = new Date();
            $scope.alerts = [];
            $scope.ceCourseLookupForm.$setPristine();
        };

        $scope.resetCourseNum = function() {
            $scope.courses = [];
            $scope.courseNumber = undefined;
            $scope.alerts = [];
            $scope.ceCourseLookupForm.$setPristine();
        };


        $scope.$watch('courseDate', function() {
            if((moment($scope.courseDate).startOf("day")).isBefore(moment(new Date()).startOf("day"))){
                $scope.isBeforeToday = true;
            } else{
                $scope.isBeforeToday = false;
            }
        });
    }
])

/**
 * Services
 */
.service('CELookupService', ['$http','$filter',
    function($http, $filter) {

        this.getLocations = function() {
            return $http.get('/lookup/locations/');
        };

        this.getProviders = function() {
            return $http.get('/lookup/providers/');
        };

        this.search = function(courseNumber, prov, courseName, location, deliveryMethod, lifeHrs, asHrs, generalHrs, adjusterHrs, date) {
            return $http.get('/lookup/ce/search/', {
                params: {
                    courseNumber: courseNumber,
                    provider: prov != undefined ? prov.providerId : null,
                    courseName: courseName,
                    location: location != undefined ? location.city : null,
                    deliveryMethod: deliveryMethod != undefined ? deliveryMethod.code : null,
                    lifeHrsFrom: lifeHrs != undefined ? lifeHrs.from : null,
                    lifeHrsTo: lifeHrs != undefined ? lifeHrs.to : null,
                    asHrsFrom: asHrs != undefined ? asHrs.from : null,
                    asHrsTo: asHrs != undefined ? asHrs.to : null,
                    generalHrsFrom: generalHrs != undefined ? generalHrs.from : null,
                    generalHrsTo: generalHrs != undefined ? generalHrs.to : null,
                    adjusterHrsFrom:  adjusterHrs != undefined ? adjusterHrs.from : null,
                    adjusterHrsTo: adjusterHrs != undefined ? adjusterHrs.to : null,
                    date: $filter('date')(date, "MM-dd-yyyy")
                }
            }).then(function(res) {
                return res;
            });
        };
    }
]);
