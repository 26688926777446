var ce = angular.module('aic.ce', [
    'ngResource',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'aic.ng-jquery'
]);

ce.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('ce', {
            parent: 'workspace',
            abstract: true,
            templateUrl: 'ce/ce.html'
        })
        .state('ce.summary', {
            url: '/ce.summary',
            templateUrl: 'ce/ce.summary.html',
            controller: 'CESummaryCtrl'
        })
        .state('ce.courses', {
            url: '/ce.courses',
            templateUrl: 'ce/ce.courses.html',
            controller: 'CECoursesCtrl'
        })
        .state('ce.add', {
            url: '/ce.add',
            templateUrl: 'ce/ce.courses.report.html',
            controller: 'CEAddCourseCtrl'
        })
        ;
    }
])

/**
 * Controller.
 */
.controller('CESummaryCtrl', ['$scope', '$state', '$stateParams', '$http', 'CEService',
    function($scope, $state, $stateParams, $http, CEService) {
        $scope.categories = [];
        $scope.loading = true;
        $scope.showdetails = false;

        $scope.meetsRequirements = function(category) {
            return parseFloat(category.remainingHours) <= 0;
        };

        $scope.isRed = function(categorydetail) {
            return categorydetail.hasShortfall && !categorydetail.hasInactivityRequirement;
        };

        $scope.isYellow = function(categorydetail) {
            return categorydetail.hasShortfall && categorydetail.hasInactivityRequirement;
        };

        $scope.isGreen = function(categorydetail) {
            return !$scope.isRed(categorydetail) && !$scope.isYellow(categorydetail);
        };

        $scope.showCourses = function() {
            $state.go('ce.courses');
        };

        $scope.addCourse = function() {
            $state.go('ce.add');
        };

        CEService.getDetailedSummary($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.categories = (res.data.ce && res.data.ce.categories) || [];
            $scope.loading = false;
        });
    }
])
.controller('CECoursesCtrl', ['$scope', '$state', '$stateParams', '$http', 'DTOptionsBuilder', 'SweetAlert',
    function($scope, $state, $stateParams, $http, DTOptionsBuilder, SweetAlert) {
        $scope.cecourses = [];
        $scope.showVoided = false;
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [3, 'desc']);


        // Keeping two lists ( one with all courses and one with non voided ) vs doing ng-show based on the showVoided
        // flag is required for datatables to display record counts properly
        var nonVoidedCourses = [];
        var allCourses = [];

        $scope.showStatus = function() {
            $state.go('ce.summary');
        };

        $scope.addCourse = function() {
            $state.go('ce.add');
        };

        $scope.voidCourse = function(ceCourse) {
            SweetAlert.swal({
                title: "Delete course.",
                text: "Are you sure you want to delete course?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $http.delete('/' + $scope.roleContext.ciprid + '/ce', {
                        params: {
                            ceCourseCertificateId: ceCourse.courseCertificateId
                        }
                    })
                    .then(function(data, status, headers, config) {
                        //TODO change IRA Ce Course delete to send the voided on timestamp back in the response
                        ceCourse.voidedOn = new Date();

                        //remove course from the non voided list
                        nonVoidedCourses = _.without(nonVoidedCourses, ceCourse);
                        $scope.cecourses = $scope.showVoided ? allCourses : nonVoidedCourses;
                        $scope.dtOptions.reloadData();
                    });
                }

            });



        };

        $scope.$watch('showVoided', function(showVoided) {
            $scope.cecourses = showVoided ? allCourses : nonVoidedCourses;
        });

        $http.get('/' + $scope.roleContext.ciprid + '/ce/courses/')
        .then(function(res) {
            allCourses = res.data;
            nonVoidedCourses = _.filter(allCourses, {'voidedOn': ''});

            // by default show non voided courses only
            $scope.cecourses = nonVoidedCourses;
            $scope.showVoided = false;
        });
    }
])
.controller('CeConfirmCtrl', ['$scope', '$state', '$uibModalInstance', '$http',
    function($scope, $state, $uibModalInstance, $http) {
        $scope.alerts = [];

        $scope.addAlert = function(msg) {
            $scope.alerts.push({type: 'danger', msg: msg});
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.continue = function() {
            $http.put('/' + $scope.params.ciprId + '/ce', {
                data: {
                    instructor: $scope.params.instructor,
                    courseId: $scope.params.courseId,
                    completionDate: $scope.params.completionDate
                }
            })
            .then(function() {
                $state.go('ce.courses');
                $uibModalInstance.dismiss('cancel');
            }, function(data, status) {
                _.isArray(data.data.err.msg) ? $scope.addAlert(data.data.err.msg[0]) : $scope.addAlert(data.data.err.msg);
            });
        };
    }
])
.controller('CEAddCourseCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$filter', '$http', '$uibModal', 'toastr',
    function($rootScope, $scope, $state, $stateParams, $filter, $http, $uibModal, toastr) {
        $scope._ = _;
        $scope.selectedCourse = undefined;
        $scope.courseCompletionDate = undefined;
        $scope.ceCourseInstructor = 'N';
        $scope.courseBegin = '';
        $scope.courseEnd = '';
        $scope.dateFormat = 'MM dd, yyyy';

        $scope.alerts = [];

        $scope.$watch('selectedCourse', function(selectedCourse) {
            if(typeof selectedCourse === 'object') {
                $scope.courseBegin = selectedCourse.start_dt ? $filter('date')(selectedCourse.start_dt, "MM/dd/yyyy") : '';
                $scope.courseEnd = $filter('date')(selectedCourse.end_dt || new Date(), "MM/dd/yyyy");
            }
        });

        $scope.setCourseCompletionDate = function(selectedDate) {
            $scope.courseCompletionDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        $scope.getCourses = function(val, limit) {
            return $http.get('/lookup/ce/typeahead', {
                params: {
                    courseid: val,
                    limit: limit
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data: [];
            });
        };

        $scope.checkCourse = function() {
            if(typeof $scope.selectedCourse !== 'object'){
                $scope.selectedCourse = undefined;
            }
        };

        $scope.reset = function() {
            $scope.selectedCourse = undefined;
            $scope.courseCompletionDate = undefined;
            $scope.ceCourseInstructor = 'N';
            $scope.courseBegin = '';
            $scope.courseEnd = '';
            $scope.alerts = [];
            $scope.ceCourseForm.$setPristine();
        };

        $scope.addAlert = function(msg) {
            $scope.alerts.push({type: 'danger', msg: msg});
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.saveCourse = function() {
            //            if($scope.courseNumberErrors.length > 0 || $scope.courseDateErrors.length > 0) {
            //                addAlert('Please enter course number and completion date.');
            //                return;
            //            }
            $scope.alerts = [];
            // check if course is in the current period
            return $http.get('/' + $scope.roleContext.ciprid + '/ce/iscurrent', {
                params: {
                    completiondate: $scope.courseCompletionDate
                }
            }).then(function(res) {
                if(res.data[0].catCount == 0) {
                    var scope = $rootScope.$new();
                    scope.params = {
                        ciprId: $scope.roleContext.ciprid,
                        instructor: $scope.ceCourseInstructor,
                        courseId: $scope.selectedCourse.ce_course_seq,
                        completionDate: $scope.courseCompletionDate
                    };

                    var modalInstance = $uibModal.open({
                        scope: scope,
                        templateUrl: 'ce/ce.courses.confirmation.html',
                        controller: 'CeConfirmCtrl',
                        size: 400
                    });
                } else {
                    //save course
                    $http.put('/' + $scope.roleContext.ciprid + '/ce', {
                        data: {
                            instructor: $scope.ceCourseInstructor,
                            courseId: $scope.selectedCourse.ce_course_seq,
                            completionDate: $scope.courseCompletionDate
                        }
                    })
                    .then(function(res) {
                        toastr.success('Success!', 'Course successfully saved: ' + $scope.selectedCourse.ce_course_nm);
                        $state.go('ce.courses');
                    }, function(data, status) {
                        _.isArray(data.data.err.msg) ? $scope.addAlert(data.data.err.msg[0]) : $scope.addAlert(data.data.err.msg);
                    });
                }
            });
        };

        $scope.showStatus = function() {
            $state.go('ce.summary');
        };

        $scope.showCourses = function() {
            $state.go('ce.courses');
        };
    }
])

/**
 * Services
 */
.service('CEService', ['$http',
    function($http) {

        this.getDetailedSummary = function(ciprid) {
            return $http.get('/' + ciprid + '/ce/detailedsummary/');
        };

        this.getSummary = function(ciprid) {
            return $http.get('/' + ciprid + '/ce/summary/');
        };
    }
]);

