var aglookup = angular.module('aic.aglookup', [
    'ngResource',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'aic.filters'
]);

aglookup.config(['$httpProvider', '$stateProvider',
    function ($httpProvider, $stateProvider) {

        $stateProvider
            .state('aglookup', {
                parent: 'publiclookupentry',
                url: '/aglookup',
                templateUrl: 'lookups/license/aglookup.html',
                controller: 'AgLicensesLookupCtrl',
                data: {
                    requiresLogin: false
                }
            })
            .state('aglookup.agency', {
                parent: 'publiclookupentry',
                url: '/aglookup/{agencyId:[0-9]+}/view?{year}',
                templateUrl: 'lookups/license/agency.view.html',
                controller: 'AgencyViewCtrl',
                data: {
                    requiresLogin: false
                }
            })
            .state('aglookup.agency.licenses', {
                parent: 'publiclookupentry',
                url: '/aglookup/{agencyId:[0-9]+}/viewlicenses',
                templateUrl: 'lookups/license/license.list.view.html',
                controller: 'AgLookupLicenseListViewCtrl',
                data: {
                    requiresLogin: false
                }
            });
     }
])
.controller('AgLicensesLookupCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$filter', '$http', '$uibModal', 'dialogs', 'PartyLookupService', 'OrgLookupService','DTOptionsBuilder',
    function ($rootScope, $scope, $state, $stateParams, $filter, $http, $uibModal, dialogs, PartyLookupService, OrgLookupService, DTOptionsBuilder) {
        $scope._ = _;
        $scope.firstName = PartyLookupService.firstName;
        $scope.lastName = PartyLookupService.lastName;
        $scope.showHistory = PartyLookupService.showHistory;
        $scope.licenses = PartyLookupService.licenses;

        $scope.lookupType = OrgLookupService.lookupType;
        $scope.agencies = OrgLookupService.agencies;
        $scope.city = OrgLookupService.city;
        $scope.agency = OrgLookupService.agency;
        $rootScope.isAac = false;

        $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('processing', true)
            .withOption('pageLength', 25)
            .withOption('order', []);

        $scope.$watch('lookupType', function(newVal, oldVal) {
            if(newVal !== oldVal) {
                 $scope.licenses = [];
                $scope.agencies = [];
            }
        });

        $scope.$watch('showHistory', function(newVal, oldVal) {
            if(newVal !== oldVal && !$scope.allFieldsEmpty()) {
                $scope.search();
            }
        });

        $scope.search = function () {
            $scope.loading = true;

            if($scope.lookupType ==='agency'){
                OrgLookupService.search($scope.agency, $scope.city)
                    .then(function(res) {
                        $scope.loading = false;
                        $scope.agencies = res.data;
                        OrgLookupService.setAgencies(res.data);
                        OrgLookupService.setLookupType('agency');
                        OrgLookupService.setAgency($scope.agency);
                        OrgLookupService.setCity($scope.city);
                    });
            } else{
                PartyLookupService.search($scope.firstName, $scope.lastName, $scope.city, $scope.lookupType, $scope.showHistory)
                    .then(function (response) {
                        $scope.loading = false;
                        $scope.licenses = response.data;
                        PartyLookupService.setLicenses(response.data);
                        PartyLookupService.setSearch($scope.firstName, $scope.lastName, $scope.showHistory);
                        OrgLookupService.setLookupType($scope.lookupType);
                        OrgLookupService.setCity($scope.city);
                    });
            }

        };

        $scope.viewAgency = function(agencyId, year) {
             $state.go('aglookup.agency', {agencyId:  agencyId, year: year});
        };

        $scope.viewAgencyLicenses = function(agencyId) {
            $state.go('aglookup.agency.licenses', {agencyId:  agencyId});
        };

        $scope.viewLicense = function (license) {
            var modalInstance = $uibModal.open({
                templateUrl: 'lookups/license/license.view.html',
                resolve: {
                    license: license
                },
                controller: 'AgLookupLicenseViewCtrl',
                size: 'lg'
            });
        };

        $scope.allFieldsEmpty = function () {
           if($scope.lookupType === 'agent' || $scope.lookupType === 'adjuster'){
               return ($scope.firstName == undefined || $scope.firstName.length == 0) &&
               ($scope.lastName == undefined || $scope.lastName.length == 0) && ($scope.city == undefined || $scope.city.length == 0);
           } else if ($scope.lookupType === 'agency'){
               return ($scope.agency == undefined || $scope.agency.length == 0) && ($scope.city == undefined || $scope.city.length == 0);
           }else{
               return true;
           }

        };


        $scope.cancel = function () {
            modalInstance.dismiss('cancel');
        };

        $scope.reset = function () {
            $scope.firstName = undefined;
            $scope.lastName = undefined;
            $scope.city = undefined;
            $scope.agency = undefined;
            $scope.showHistory = false;
            $scope.licenses=[];
            $scope.agencies=[];
        };
    }
])
.controller('AgencyViewCtrl', ['$rootScope', '$scope', '$state', '$previousState', '$stateParams', '$filter', '$http', 'OrgLookupService', 'UtilService',
    function ($rootScope, $scope, $state, $previousState, $stateParams, $filter, $http, OrgLookupService, UtilService) {
        OrgLookupService.viewAgencyDetails($stateParams.agencyId, $stateParams.year)
            .then(function(res) {
                $scope.drs = res.data.drs;
                $scope.addresses = res.data.addresses;
                $scope.agency = res.data.agency[0];
         });

        $scope.toPhoneFromString = function(tel, areaCode) {
           return UtilService.toPhoneFromString(tel, areaCode);
        };

        $scope.goPrevious = function() {
            $previousState.go();
        };
    }
])
.controller('AgLookupLicenseListViewCtrl', ['$rootScope', '$scope', '$state', '$previousState', '$stateParams', '$filter', '$http', '$uibModal', 'OrgLookupService', 'UtilService', 'DTOptionsBuilder',
    function ($rootScope, $scope, $state, $previousState, $stateParams, $filter, $http, $uibModal, OrgLookupService, UtilService, DTOptionsBuilder) {
        $scope.loading = true;
        $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('processing', true)
            .withOption('pageLength', 25)
            .withOption('order', []);

        OrgLookupService.viewAgencyLicenses($stateParams.agencyId)
            .then(function(res) {
                $scope.licenses = res.data;
                $scope.loading = false;
            });

        $scope.goPrevious = function() {
            $previousState.go();
        };

        $scope.viewLicense = function (license) {
            var modalInstance = $uibModal.open({
                templateUrl: 'lookups/license/license.view.html',
                resolve: {
                    license: license
                },
                controller: 'AgLookupLicenseViewCtrl',
                size: 'lg'
            });
        };
    }
])
/**
 * Dialog and modal controllers
 */
.controller('AgLookupLicenseViewCtrl', ['$scope', '$uibModalInstance', 'license',
    function ($scope, $uibModalInstance, license) {
        $scope.license = license;

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
])
/**
 * Services
 */
.service('PartyLookupService', ['$http', '$filter',
    function ($http, $filter) {
        this.licenses = [];
        this.showHistory = false;
        this.firstName = undefined;
        this.lastName = undefined;

        this.search = function (firstName, lastName, city, lookupType, showHistory) {
            return $http.get('/lookup/license/search/', {
                params: {
                    firstName: firstName,
                    lastName: lastName,
                    city: city,
                    lookupType: lookupType,
                    showHistory: showHistory
                }
            }).then(function (res) {
                return res;
            });
        };

        this.setLicenses = function(licenses) {
            this.licenses = licenses;
        };
        this.setSearch = function(firstName, lastName, showHistory) {
            this.firstName = firstName;
            this.lastName = lastName;
            this.showHistory = showHistory;
        };

    }
])
.service('OrgLookupService', ['$http', '$filter',
    function ($http, $filter) {
        this.agencies = [];
        this.lookupType = 'agent';
        this.city = undefined;
        this.agency = undefined;

        this.search = function (agencyName, city) {
            return $http.get('/lookup/agency/search/', {
                params: {
                    agencyName: agencyName,
                    city: city
                }
            }).then(function (res) {
                 return res;
            });
        };

        this.viewAgencyDetails = function (agencyId, year) {
             return $http.get('/lookup/agency/details/', {
                params: {
                    id: agencyId,
                    year: year
                }
            }).then(function (res) {
                return res;
            });
        };

        this.viewAgencyLicenses = function (agencyId) {
            return $http.get('/lookup/license/search/', {
                params: {
                    lookupType: 'all',
                    agencyId: agencyId
                }
            }).then(function (res) {
                return res;
            });
        };

        this.setLookupType = function(lookupType) {
            this.lookupType = lookupType;
        };

        this.setAgencies = function(agencies) {
            this.agencies = agencies;
        };

        this.setAgency = function(agency) {
            this.agency = agency;
        };

        this.setCity = function(city) {
            this.city = city;
        };
    }
]);

