var nonrenlookup = angular.module('aic.nonrenlookup', [
    'ngResource',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'aic.filters'
]);

var insCats = [
    { type: "Agent", code:"GENERAL", description: "General Agent"},
    { type: "Agent", code:"LIFE", description: "Life and A&S Agent"},
    { type: "Agent", code:"ADJ", description: "Adjuster"},
    { type: "Corporate", code:"GENERAL", description: "General Corporate"},
    { type: "Corporate", code:"LIFE", description: "Life and A&S Corporate"},
    { type: "Corporate", code:"ADJ", description: "Adjusting Firm Corporate"}
];

nonrenlookup.config(['$httpProvider', '$stateProvider',
    function ($httpProvider, $stateProvider) {

        $stateProvider
            .state('nonrenlookup', {
                parent: 'publiclookupentry',
                url: '/nonrenlookup',
                templateUrl: 'lookups/license/nonrenlookup.html',
                controller: 'NonRenewedLicensesLookupCtrl',
                data: {
                    requiresLogin: false
                }
            });
     }
])
.controller('NonRenewedLicensesLookupCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$filter', '$http', 'dialogs','DTOptionsBuilder','LicenseLookupService',
    function ($rootScope, $scope, $state, $stateParams, $filter, $http, dialogs, DTOptionsBuilder, LicenseLookupService) {
        $scope._ = _;
        $rootScope.isAac = false;
        $scope.lookupType = 'Agent';
        $scope.insCats = _.filter(insCats, { 'type': $scope.lookupType});
        $scope.alphabet = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");
        $scope.selectedLetter = undefined;
        $scope.groupedLicenses=[];

        $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('processing', true)
            .withOption('pageLength', 25)
            .withOption('order', []);


        $scope.search = function () {
            $scope.loading = true;
            var type = $scope.lookupType == "Agent" && $scope.insCat.code == 'ADJ' ? "Adjuster" : $scope.lookupType;
             LicenseLookupService.search(type, $scope.insCat.code)
                .then(function(response){
                    var licenses = response.data;
                    $scope.licenses = licenses;
                    $scope.selectedLetter = undefined;
                    $scope.groupedLicenses =_.keys(_.groupBy(licenses, function(license){
                        var name = $scope.lookupType =='Agent' ? license.lhlastname :  license.agencyname;
                        return  name.substr(0,1).toUpperCase();
                    }));
                    $scope.loading = false;
                });
        };

        $scope.allFieldsEmpty = function () {
             return $scope.insCat == undefined  || $scope.insCat.length == 0;
        };

        $scope.$watch('lookupType', function(lookupType) {
            $scope.insCats = _.filter(insCats, { 'type': lookupType});
            $scope.licenses = [];

        });

        $scope.$watch('insCat', function(insCat) {
            if(insCat != null){
                LicenseLookupService.getExpiryDate(insCat.code)
                    .then(function(response){
                        $scope.expiryDate  = response.data[0].expiryDate;
                    });
            }
        });

        $scope.reset = function () {
            $scope.expiryDate = undefined;
            $scope.licenses=[];
            $scope.groupedLicenses=[];
            $scope.lookupType = 'Agent';
            $scope.insCats = _.filter(insCats, { 'type': $scope.lookupType});
            $scope.selectedLetter = undefined;

        };

        $scope.dtInstance = {};
        $scope.searchByAlphaNum = function (value){
            $scope.selectedLetter = value;
            $scope.dtInstance.DataTable.column(0).search("^"+ value, true, false ).draw();
        };
    }
])
/**
 * Services
 */
.service('LicenseLookupService', ['$http', '$filter',
    function ($http, $filter) {
        this.licenses = [];

        this.search = function (type, insCat) {
            return $http.get('/lookup/license/nonren/search/', {
                params: {
                    type: type,
                    insCat: insCat
                }
            }).then(function (res) {
                return res;
            });
        };

        this.getExpiryDate = function (insCat) {
            return $http.get('/lookup/license/nonren/expiry', {
                params: {
                    insCat: insCat
                }
            }).then(function (res) {
                return res;
            });
        };
    }
]);

