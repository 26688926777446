var invoices = angular.module('aic.invoices', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'aic.services'
]);

invoices.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('invoices', {
            parent: 'workspace',
            abstract: true,
            templateUrl: 'invoices/invoices.html'
        })
        .state('invoices.list', {
            url: '/invoices',
            templateUrl: 'invoices/invoices.list.html',
            controller: 'InvoiceListCtrl'
        })
        .state('invoices.view', {
            url: '/invoices/{invoiceId:[0-9]+}/view',
            templateUrl: 'invoices/invoices.view.html',
            controller: 'InvoiceViewCtrl'
        })
        .state("invoices.receipt", {
            url: '{ciprId:[0-9]+}/invoices/{invoiceId:[0-9]+}/receipt/{paymentId:[0-9]+}',
            onEnter: ['$stateParams', 'InvoiceReceiptService', function($stateParams, InvoiceReceiptService) {
                InvoiceReceiptService.getReceipt($stateParams.ciprId, $stateParams.invoiceId, $stateParams.paymentId);
            }],
            templateUrl: 'invoices/invoices.view.html',
            controller: 'InvoiceViewCtrl'
        });
    }
])

.controller('InvoiceListCtrl', ['$scope', '$state', '$http', 'InvoiceService', 'DTOptionsBuilder',
    function($scope, $state, $http, InvoiceService, DTOptionsBuilder) {
        $scope.invoices = [];
        $scope.loading = true;
        InvoiceService.getInvoices($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.invoices = res.data;
            $scope.loading = false;
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc']);

        $scope.isInvoiceDue = function(invoice) {
            return invoice.inv_status_cd === 'INVOPN'
        };

        $scope.isInvoicePaid = function(invoice) {
            return invoice.inv_status_cd === 'INVPAD'
        };

        $scope.isInvoiceProcessing = function(invoice) {
            return invoice.inv_status_cd === 'INVPRC'
        };

        $scope.isInvoiceVoided = function(invoice) {
            return invoice.inv_status_cd === 'INVVOD'
        };

        $scope.getStatus = function(invoice) {
            return parseFloat(invoice.balance) == 0;
        };

        $scope.viewInvoice = function(invoice) {
            $state.go('invoices.view', {invoiceId: invoice.invoiceid});
        };
    }
])
.controller('InvoiceViewCtrl', ['$scope', '$state', '$stateParams', '$window', '$http', 'toastr', 'blockUI', 'dialogs', 'SweetAlert', 'DTOptionsBuilder', 'InvoiceReceiptService', 'InvoiceService', 'DownloadService', 'ExamCountdownService', '$timeout', '$q',
    function($scope, $state, $stateParams, $window, $http, toastr, blockUI, dialogs, SweetAlert, DTOptionsBuilder, InvoiceReceiptService, InvoiceService, DownloadService, ExamCountdownService, $timeout, $q) {
        $scope.invoice = {};
        $scope.items = [];
        $scope.payments = [];
        $scope.buttons = false;
        $scope.loading = true;
        $scope.owneremails = [];
        $scope.paywithcc = false;
        $scope.paywithmoa = false;
        $scope.cc = {};
        $scope.minExpiryYear = (new Date().getFullYear() + '').slice(-2);
        $scope.maxExpiryYear = (moment().add('y', 10).toDate().getFullYear() + '').slice(-2);

        $scope.moa = {};
        $scope.maxAmount = 0;
        $scope.itemDetailsLoading = false;
        $scope.emailsLoading = false;
        $scope.dtInstance = {};
        $scope.isPaymentBYCCInProgress = false;
        $scope.paymentDistributionInProgress = false;
        /**
         * Initializers
         */
        var invoiceBlock = blockUI.instances.get('invoice-block');
        invoiceBlock.start('Preparing to show invoice ...');

        var fetchInvoice = function() {
            return $http.get('/' + $scope.roleContext.ciprid + '/invoices/' + $stateParams.invoiceId + '/particulars?details=' + ['payments']);
        };

        var render = function(res) {
            var data = res.data;
            $scope.loading = false;
            $scope.invoice = data.summary;
            $scope.payments = data.payments;

            $http.get('/' + $scope.roleContext.ciprid + '/account/summary', {
                params: {
                    ownerpartyid: $scope.invoice.ownerpartyid
                }
            })
            .then(function(res) {
                var account = _.find(res.data, function(o) {
                    return o.accnt_cd.indexOf("UC_FEE_HOLD") == 0;
                });
                $scope.account = account;
                $scope.maxAmount = Math.min(parseFloat($scope.invoice.balance || 0), account.balance);
            });
        };

        var fetchOwnerEmails = function() {
            return $http.get('/' + $scope.roleContext.ciprid + '/invoices/' + $stateParams.invoiceId + '/owneremails');
        };
        var fetchAndRender = function(){
            fetchInvoice()
            .then(function(res) {
                render(res);
                invoiceBlock.stop();
            }, function(res) {
                fetchInvoice()
                .then(function(res) {
                    invoiceBlock.stop();
                    render(res);
                }, function(res) {
                    invoiceBlock.stop();
                });
            });
        }

        fetchAndRender();
        // 2 attempts to show invoice


        $scope.getItemDetails = function() {
            $scope.itemDetailsLoading = true;
            $http.get('/' + $scope.roleContext.ciprid + '/invoices/' + $stateParams.invoiceId + '/particulars?details=' + ['items', 'items.license']).then
            (function(res) {
                var data = res.data;
                $scope.itemDetailsLoading = false;
                $scope.items = data.items;
                var corpLicenses = _.filter($scope.items, $scope.isCorpLicense);
                $scope.items = _.map($scope.items, function(item) {
                    if($scope.isDRLicense(item)) {
                        var corplicitem = _.find(corpLicenses, function(corpLicenseInvoiceItem) {
                            var corpLicense = corpLicenseInvoiceItem.stklic;
                            return item.stklic.corporatelicenseclass == corpLicense.licenseclass.trim() && item.stklic.agencyid == corpLicense.agencyid;
                        });
                        item.corplic = corplicitem;
                        item.corplicitemid = corplicitem ? corplicitem.invoiceitemid : '';
                        return item;
                    }
                    return item;
                });
                $scope.dtInstance.reloadData();
                // Paginate when the invoice items are more than 100
                $scope.dtOptions = DTOptionsBuilder
                .newOptions()
                .withBootstrap()
                .withOption('paging', $scope.items.length > 100)
                .withOption('order', [1, 'asc'])
                .withOption('aoColumnDefs', [
                    {"bSortable": false, "aTargets": [6]}
                ]);
            }, function(res) {
                toastr.error('Error!', 'Please try again later');
            })
        };
        // -- Called from form --

        $scope.showButtons = function() {
            $scope.buttons = true;
            invoiceBlock.stop();
        };

        $scope.hideButtons = function() {
            $scope.buttons = false;
        };

        $scope.isLicenseApplication = function(item) {
            return item.itemtypecd == "LIC_APP";
        };

        $scope.isLicenseRenewal = function(item) {
            return item.itemtypecd == "LIC_REN";
        };

        $scope.isProctorFee = function(item) {
            return item.itemtypecd == "OP_FEE";
        };

        $scope.isGST = function(item) {
            return item.itemtypecd == "GST";
        };

        $scope.isDRLicense = function(item) {
            return item.licenseid && item.stklic && item.stklic.drlicense == "Y";
        };

        $scope.isCorpLicense = function(item) {
            return item.licenseid && item.stklic && item.stklic.licensetype == "C";
        };

        $scope.isExamApplication = function(item) {
            return item.itemtypecd == "EXAM_APP";
        };

        $scope.isCECourse = function(item) {
            return item.itemtypecd == "CE_COURSE";
        };

        $scope.isAssessmentInvoice = function(item) {
            return item.itemtypecd == "AI" || item.itemtypecd == "RAI";
        };

        $scope.isInvoiceOpen = function(invoice) {
            return invoice.statuscd == 'INVOPN';
        };

        $scope.isInvoicePaid = function(invoice) {
            return invoice.statuscd == 'INVPAD';
        };

        $scope.totalAmountPaidOnInvoice = function(payments){
            return _.sumBy(payments, function(payment){ return parseFloat(payment.amountpaidoninvoice)});
        };

        $scope.isPaymentReceived = function(invoice) {
            return invoice.statuscd == 'INVPRC';
        };

        $scope.isInvoiceQueued = function(invoice) {
            return invoice.statuscd == 'INVPRC';
        };

        $scope.isInvoiceClosed = function(invoice) {
            return invoice.statuscd == "INVCLS";
        };

        $scope.isItemPaid = function(item) {
            return parseFloat(item.itemamt) == parseFloat(item.paidamt) && item.itemstatuscd.trim() === "IPAID" ;
        };

        $scope.showReceipt = function(invoiceid, paymentid) {
            InvoiceReceiptService.getReceipt($scope.roleContext.ciprid, invoiceid, paymentid);
        };

        $scope.payWithCC = function(invoiceId) {
            $scope.paywithcc = true;
            $scope.paywithmoa = false;
        };

        $scope.payWithMOA = function(invoiceId) {
            $scope.paywithmoa = true;
            $scope.paywithcc = false;
        };

        $scope.canDelete = function(invoice) {
            return invoice.itemcount == 0;
        };

        $scope.canDeleteAllItems = function(invoice) {
            return $scope.hasItems(invoice) && $scope.isInvoiceOpen(invoice)
        };

        $scope.hasItems = function(invoice) {
            return invoice.itemcount > 0;
        };

        $scope.canBeDeleteItem = function(invoice, item) {
            return !$scope.isCorpLicense(item) && !$scope.isItemPaid(item) && !$scope.isInvoiceClosed(invoice) && !$scope.isInvoicePaid(invoice)  && !$scope.isInvoiceQueued(invoice) && !$scope.isProctorFee(item) && !$scope.isGST(item);
        };

        $scope.canLock = function(invoice) {
            return !$scope.isInvoiceClosed(invoice) && !$scope.isInvoiceQueued(invoice) && !$scope.isInvoicePaid(invoice) && !$scope.isInvoicePaid(invoice) && invoice.statuscd !== 'INVVOD';
        };


        /**
         * Delete invoice.
         * @param invoiceId
         */
        $scope.deleteInvoice = function(invoiceId) {
            InvoiceService.deleteInvoice($scope.roleContext.ciprid, invoiceId)
            .then(function(data, status) {
                toastr.success('Success!', 'Invoice has been deleted.');
                if($scope.isStaff()){
                    $window.history.back();
                }else
                    $state.go('invoices.list');
            }, function(data, status) {
                toastr.error('Error!', 'Invoice cannot be deleted at this time.');
            });
        };

        /**
         * Mark invoice as closed.
         */
        $scope.closeInvoice = function() {
            dialogs.create('invoices/dialogs/close.html', 'InvoiceCloseCtrl', $scope.invoice, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                InvoiceService.closeInvoice($scope.roleContext.ciprid, $scope.invoice.invoiceid)
                .then(function(data, status) {
                    toastr.success('Success!', 'Invoice has been closed for editing.');
                    if($scope.isStaff()){
                        $window.history.back();
                    }else
                        $state.go('invoices.list');
                }, function(data, status) {
                    toastr.error('Error!', 'Invoice cannot be closed for editing at this time.');
                });
            });
        };

        $scope.deleteAllInvoiceItems = function() {
            SweetAlert.swal({
                title: "Delete all Invoice Items",
                text: "Are you sure you want to delete all invoice items?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    InvoiceService.deleteAllInvoiceItems($scope.roleContext.ciprid, $scope.invoice.invoiceid)
                    .then(function(res) {
                        toastr.success('Success!', 'Invoice items has been deleted.');
                        fetchAndRender();
                    }, function(res) {
                        toastr.error('Error!', 'Invoice items cannot be deleted at this time.');
                    });
                }
            });


        };

        $scope.distributePaidInvoice = function() {

            SweetAlert.swal({
                title: "Distribute payment",
                text: "Are you sure you want to send invoice# "+$scope.invoice.invoiceid+ " for Distribution? Only run this if you are sure the payment process failed previously!!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $scope.paymentDistributionInProgress = true;
                    InvoiceService.distributePaidInvoice($scope.roleContext.ciprid, $scope.invoice.invoiceid)
                    .then(function(res) {
                        $scope.paymentDistributionInProgress = false;
                        toastr.success('Success!', 'Invoice sent for distribution of payment. The page should refresh within 10 secs. If the process still not succeed contact IT');
                        $timeout(function(){fetchAndRender();},7000);
                    }, function(res) {
                        $scope.paymentDistributionInProgress = false;
                        toastr.error('Error!', 'Invoice cannot be sent for distribution of payment.');
                    });
                }
            });



        };

        $scope.deleteOrMoveInvoiceItem = function(item) {
            if($scope.isPerson() || $scope.isExamApplication(item)) return $scope.deleteInvoiceItem(item);
            var confirmoptions = {license: item.license};
            dialogs.create('invoices/dialogs/deleteormove.html', 'InvoiceItemDeleteOrMoveCtrl', confirmoptions, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(data) {
                if(data.delete)
                    return $scope.deleteInvoiceItem(item);
                else
                    return $scope.moveInvoiceItem(item);
            });
        };

        /**
         * Delete invoice item.
         * @param item
         */
        $scope.deleteInvoiceItem = function(item) {
            if($scope.isDRLicense(item)) return $scope.deleteDRLicenseInvoiceItem(item);
            if($scope.isExamApplication(item) && _.filter($scope.items,{examid:item.examid}).length > 1) return $scope.deleteExamInvoiceItems(item)
            return $scope.deleteSingleInvoiceItem(item)
        };

        $scope.deleteSingleInvoiceItem = function(item) {
            InvoiceService.deleteInvoiceItem($scope.roleContext.ciprid, $scope.invoice.invoiceid, item.invoiceitemid)
            .then(function(res) {
                $scope.items = _.without($scope.items, item);
                toastr.success('Success!', 'Invoice item has been deleted.');
                fetchAndRender();
            }, function(res) {
                toastr.error('Error!', 'Invoice item cannot be deleted at this time.');
            });
        };

        $scope.deleteExamInvoiceItems = function(item) {
            var examInvoiceItems = _.filter($scope.items,{examid:item.examid})
            var promiseDeleteInvoiceItems = [];
            _.forEach(examInvoiceItems, function(item) {
                promiseDeleteInvoiceItems.push(InvoiceService.deleteInvoiceItem($scope.roleContext.ciprid, $scope.invoice.invoiceid, item.invoiceitemid));
            });
            $q.all(promiseDeleteInvoiceItems)
            .then(function(res) {
                _.forEach(examInvoiceItems, function(item) {
                    $scope.items = _.without($scope.items, item);
                });
                toastr.success('Success!', 'Invoice items has been deleted.');
                fetchAndRender();
            }, function(res) {
                toastr.error('Error!', 'Invoice items cannot be deleted at this time.');
            });

        };



        /**
         * Delete DR License and Corp License invoice item.
         * @param item
         */
        $scope.deleteDRLicenseInvoiceItem = function(item) {
            var confirmoptions = {
                drlicense: item.license,
                corplicense: item.corplic ? item.corplic.license : '',
                delete: true
            };
            dialogs.create('invoices/dialogs/delete.html', 'InvoiceItemDeleteCtrl', confirmoptions, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                var promiseDeleteInvoiceItems = [];
                promiseDeleteInvoiceItems.push(InvoiceService.deleteInvoiceItem($scope.roleContext.ciprid, $scope.invoice.invoiceid, item.invoiceitemid))
                promiseDeleteInvoiceItems.push(InvoiceService.deleteInvoiceItem($scope.roleContext.ciprid, $scope.invoice.invoiceid, item.corplicitemid))
                $q.all(promiseDeleteInvoiceItems)
                .then(function(res) {
                    $scope.items = _.without($scope.items, item);
                    $scope.items = _.without($scope.items, _.find($scope.items, {'invoiceitemid': item.corplicitemid}));
                    toastr.success('Success!', 'Invoice items has been deleted.');
                    fetchAndRender();
                }, function(res) {
                    toastr.error('Error!', 'Invoice items cannot be deleted at this time.');
                });
            });
        };

        /**
         * Move invoice item.
         * @param item
         */
        $scope.moveInvoiceItem = function(item) {
            if($scope.isDRLicense(item)) return $scope.moveDRLicenseInvoiceItem(item);
            return $scope.moveSingleInvoiceItemToPayables(item)
        };


        /**
         * Move single invoice item.
         * @param item
         */
        $scope.moveSingleInvoiceItemToPayables = function(item) {
            InvoiceService.moveInvoiceItemToPayables($scope.roleContext.ciprid, $scope.invoice.invoiceid, item.invoiceitemid)
            .then(function(res) {
                $scope.items = _.without($scope.items, item);
                toastr.success('Success!', 'Invoice item has been moved to payables.');
                fetchAndRender();
            }, function(res) {
                toastr.error('Error!', 'Invoice item cannot be moved at this time.');
            });
        };

        /**
         * Move DR License and Corp License invoice item.
         * @param item
         */
        $scope.moveDRLicenseInvoiceItem = function(item) {
            var confirmoptions = {drlicense: item.license, corplicense: item.corplic.license, delete: false};
            dialogs.create('invoices/dialogs/delete.html', 'InvoiceItemDeleteCtrl', confirmoptions, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                var promiseMoveInvoiceItems = [];
                promiseMoveInvoiceItems.push(InvoiceService.moveInvoiceItemToPayables($scope.roleContext.ciprid, $scope.invoice.invoiceid, item.invoiceitemid))
                promiseMoveInvoiceItems.push(InvoiceService.moveInvoiceItemToPayables($scope.roleContext.ciprid, $scope.invoice.invoiceid, item.corplicitemid))
                $q.all(promiseMoveInvoiceItems)
                .then(function(res) {
                    $scope.items = _.without($scope.items, item);
                    $scope.items = _.without($scope.items, _.find($scope.items, {'invoiceitemid': item.corplicitemid}));
                    toastr.success('Success!', 'Invoice items has been moved.');
                    fetchAndRender();
                }, function(res) {
                    toastr.error('Error!', 'Invoice items cannot be moved at this time.');
                });
            });
        };

        /**
         * update Invoice notification email.
         * @param invoice
         */
        $scope.updateNotifyEmail = function(invoice) {
            InvoiceService.updateNotifyEmail($scope.roleContext.ciprid, $scope.invoice.invoiceid, invoice)
            .then(function(res) {
                toastr.success('Success!', 'Paid notification successfully changed.');
            }, function(res) {
                toastr.error('Error!', 'Paid notification cannot be changed at this time.');
            });
        };

        /**
         * Send email to invoice owner.
         */
        $scope.emailInvoiceOwner = function() {
            $scope.emailsLoading = true;
            fetchOwnerEmails()
            .then(function(res) {
                $scope.emailsLoading = false;
                $scope.owneremails = _.union(res.data.ownerEmails, res.data.orgContacts);
                var data = {
                    ciprid: $scope.roleContext.ciprid,
                    invoice: $scope.invoice,
                    owneremails: $scope.owneremails
                };
                dialogs.create('invoices/dialogs/email.invoice.owner.html', 'InvoiceOwnerEmailCtrl', data, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                })
            });

        };

        /**
         * Constructs a download link
         * @param invoice
         * @returns {string}
         */
        $scope.downloadLink = function(invoice) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/invoices/" + invoice.invoiceid + "/pdf";
            var anchorText = 'Invoice-' + invoice.invoiceid;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        var invoicePayBlock = blockUI.instances.get('invoice-pay-block');

        /**
         * Pay
         * @param invoice
         */
        $scope.pay = function(invoice) {
            $scope.isPaymentBYCCInProgress = true;
            invoicePayBlock.start('Processing your payment. This could take upto a minute to finish.');
            $http.post('/' + $scope.roleContext.ciprid + '/invoices/' + invoice.invoiceid + '/pay/with/cc', {
                data: {
                    invoiceid: invoice.invoiceid,
                    amount: parseFloat(invoice.balance),
                    ccNumber: $scope.cc.number,
                    ccExpiryMonth: $scope.cc.expiryMonth,
                    ccExpiryYear: $scope.cc.expiryYear,
                    ciprid: $scope.roleContext.ciprid
                }
            })
            .then(function(res) {
                invoicePayBlock.stop();
                var data = res.data;
                if(data.success) {
                    $scope.paywithcc = false;
                    $scope.invoice.statuscd = 'INVPRC';
                    ExamCountdownService.reset();
                    InvoiceReceiptService.getReceipt($scope.roleContext.ciprid, invoice.invoiceid, data.authId);
                    $timeout(function(){fetchAndRender();},10000);
                } else {
                    $scope.isPaymentBYCCInProgress = false;
                    dialogs.create('invoices/dialogs/ccerror.html', 'InvoicePaymentErrorCtrl', data, {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    }).result.then(function(data) {
                        $state.go('invoices.view', {invoiceId: invoice.invoiceid});
                    });
                }
            }, function(res, status) {
                $scope.isPaymentBYCCInProgress = false;
                invoicePayBlock.stop();
                dialogs.create('invoices/dialogs/ccerror.html', 'InvoicePaymentErrorCtrl', res.data, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(data) {
                    $timeout(function() {
                        $state.go('invoices.view', {invoiceId: invoice.invoiceid});
                    }, 500);
                });
            });
        };

        $scope.isInvoiceDeleted = function(invoice){
            return invoice.statuscd === 'INVVOD' || invoice.statuscd == 'INVCLS';
        }

        $scope.canPayWithMOA = function(account, invoice){
            return account && account.balance > 0 && invoice.balance > 0 && parseFloat(invoice.balance || 0) <= parseFloat(account.balance || 0)
        };

        var invoicePayMOABlock = blockUI.instances.get('invoice-pay-moa-block');
        $scope.paymoa = function(invoice) {
            invoicePayMOABlock.start('Processing your payment. This could take upto a minute to finish.');
            $http.post('/' + $scope.roleContext.ciprid + '/invoices/' + invoice.invoiceid + '/pay/with/moa', {
                data: {
                    invoiceid: invoice.invoiceid,
                    amount: parseFloat(invoice.balance),
                    ownerpartyid: invoice.ownerpartyid
                }
            })
            .then(function(data, status) {
                $scope.paywithmoa = false;
                $scope.invoice.statuscd = 'INVPRC';
                invoicePayMOABlock.stop();
                ExamCountdownService.reset();
                toastr.success('Success!', 'Invoice paid from funds in your account.');
                $timeout(function(){fetchAndRender();},3000);
            }, function(data, status) {
                invoicePayMOABlock.stop();
                toastr.error('Error!', "Error paying invoice " + data.msg);
            });
        };


    }
])
.controller('InvoicePayMOACtrl', ['$scope', '$state', '$stateParams', '$http', 'blockUI', 'toastr', 'DTOptionsBuilder', 'ExamCountdownService',
    function($scope, $state, $stateParams, $http, blockUI, toastr, DTOptionsBuilder, ExamCountdownService) {
        $scope.invoice = {};
        $scope.items = [];

        $scope.moa = {};
        $scope.maxAmount = 0;

        /**
         * Initializers
         */
        var invoiceBlock = blockUI.instances.get('invoice-block');
        invoiceBlock.start();

        $http.get('/' + $scope.roleContext.ciprid + '/invoices/' + $stateParams.invoiceId + '/particulars?details=' + ['items'])
        .then(function(res) {
            $scope.invoice = res.data.summary;
            $scope.items = res.data.items;

            $http.get('/' + $scope.roleContext.ciprid + '/account/summary', {
                params: {
                    ownerpartyid: $scope.invoice.ownerpartyid
                }
            })
            .then(function(res) {
                var account = _.find(res.data, function(o) {
                    return o.accnt_cd.indexOf("UC_FEE_HOLD") == 0;
                });
                $scope.account = account;
                $scope.maxAmount = Math.min(parseFloat($scope.invoice.balance || 0), account.balance);
            });

            invoiceBlock.stop();

            $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('paging', $scope.items.length > 100)
            .withOption('ordering', false)
            .withOption('order', [1, 'asc']);

        });

        $scope.isItemPaid = function(item) {
            return parseFloat(item.itemamt) == parseFloat(item.paidamt);
        };

        /**
         * Pay
         * @param invoice
         */
        $scope.pay = function(invoice) {
            invoiceBlock.start('Processing your payment. This could take upto a minute to finish.');
            $http.post('/' + $scope.roleContext.ciprid + '/invoices/' + invoice.invoiceid + '/pay/with/moa', {
                data: {
                    invoiceid: invoice.invoiceid,
                    amount: $scope.moa.amount,
                    ownerpartyid: invoice.ownerpartyid
                }
            })
            .then(function(data, status) {
                invoiceBlock.stop();
                ExamCountdownService.reset();
                toastr.success('Success!', 'Invoice paid with $' + $scope.moa.amount + ' from funds in your account.');
                $state.go('invoices.view', {
                    invoiceId: invoice.invoiceid
                });
            }, function(data, status) {
                invoiceBlock.stop();
                toastr.error('Error!', "Error paying invoice " + data.msg);
            });
        };
    }
])
/**
 * Dialog and modal controllers
 */
.controller('InvoiceReceiptCtrl', ['$scope', '$uibModalInstance', 'receipt',
    function($scope, $uibModalInstance, receipt) {
        $scope.receipt = receipt;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
])
.controller('InvoiceItemDeleteOrMoveCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.invoiceitem = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.delete = function() {
            $uibModalInstance.close({'delete': true});
        };

        $scope.move = function() {
            $uibModalInstance.close({'delete': false});
        };
    }
])
.controller('InvoiceItemDeleteCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.invoiceitem = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])
.controller('InvoiceCloseCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.invoice = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])
.controller('InvoicePaymentErrorCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.error = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])
.controller('InvoiceOwnerEmailCtrl', ['$scope', '$uibModalInstance', 'data', 'toastr', 'InvoiceService',
    function($scope, $uibModalInstance, data, toastr, InvoiceService) {

        $scope.owneremails = data.owneremails;
        $scope.invoice = data.invoice;
        $scope.ciprid = data.ciprid;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.addOwnerEmail = function() {
            $scope.owneremails.push({email: $scope.owneremail, party_seq: $scope.invoice.ownerpartyid});
        };

        $scope.removeOwnerEmail = function(index) {
            $scope.owneremails.splice(index, 1);
        };

        $scope.send = function() {
            InvoiceService.sendEmail(data.ciprid, $scope.invoice.invoiceid, $scope.owneremails)
            .then(function(data) {
                toastr.success('Success!', 'Email has been sent.');
                $uibModalInstance.close();
            });

        };
    }
])
/**
 * Invoice factories
 */
.factory('InvoiceReceiptService', ['$http', '$uibModal',
    function($http, $uibModal) {
        return {
            getReceipt: function(ciprid, invoiceid, paymentid) {
                $http.get('/' + ciprid + '/invoices/payments/' + paymentid)
                .then(function(res) {
                    $uibModal.open({
                        templateUrl: 'invoices/invoices.receipt.html',
                        resolve: {
                            receipt: function() {
                                return res.data[0].receiptText;
                            }
                        },
                        controller: 'InvoiceReceiptCtrl'
                    });
                });
            }
        };
    }
])

/**
 * Invoice services
 */
.service('InvoiceService', ['$http',
    function($http) {

        this.getInvoices = function(ciprid) {
            return $http.get('/' + ciprid + '/invoices');
        };

        this.getUnpaidInvoices = function(ciprid) {
            return $http.get('/' + ciprid + '/invoices/unpaid');
        };

        this.getInvoiceById = function(ciprid, invoiceid) {
            return $http.get('/' + ciprid + '/invoices/' + invoiceid);
        };

        this.closeInvoice = function(ciprid, invoiceid) {
            return $http.post('/' + ciprid + '/invoices/' + invoiceid + "/close");
        };

        this.deleteInvoice = function(ciprid, invoiceid) {
            return $http.delete('/' + ciprid + '/invoices/' + invoiceid);
        };

        this.deleteInvoiceItem = function(ciprid, invoiceid, invoiceitemid) {
            return $http.delete('/' + ciprid + '/invoices/' + invoiceid + '/items/' + invoiceitemid);
        };

        this.deleteAllInvoiceItems = function(ciprid, invoiceid) {
            return $http.delete('/' + ciprid + '/invoices/' + invoiceid + '/items/');
        };

        this.distributePaidInvoice = function(ciprid, invoiceid) {
            return $http.post('/' + ciprid + '/invoices/' + invoiceid + '/distributepaidinvoice/');
        };

        this.moveInvoiceItemToPayables = function(ciprid, invoiceid, invoiceitemid) {
            return $http.post('/' + ciprid + '/invoices/' + invoiceid + '/items/' + invoiceitemid + '/movetopayables');
        };

        this.markaspaid = function(ciprid, invoiceid) {
            return $http.post('/' + ciprid + '/invoices/' + invoiceid + "/mark/as/paid");
        };

        this.addRenewalsToInvoice = function(ciprid, invoiceid, renewals) {
            var data = _.map(renewals, function(r) {
                return {licenseid: r.license.licenseid, price: r.license.fee};
            });
            return $http({
                url: '/' + ciprid + '/invoices/' + invoiceid + '/items',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'renewals': data
                }
            });
        };


        this.addRenewalsToNewInvoice = function(ciprid, renewals) {
            var data = _.map(renewals, function(r) {
                return {licenseid: r.license.licenseid, price: r.license.fee};
            });
            return $http({
                url: '/' + ciprid + '/invoices',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'renewals': data
                }
            });
        };
        this.addRenewalsToShoppingCart = function(ciprid, renewals) {
            var data = _.map(renewals, function(r) {
                return {licenseid: r.license.licenseid, price: r.license.fee};
            });
            return $http({
                url: '/' + ciprid + '/invoices/shoppingcart/items',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'renewals': data
                }
            });
        };

        this.addApplicationsToShoppingCart = function(ciprid, applications) {
            var data = _.map(applications, function(a) {
                return {licenseid: a.licenseid, price: a.fee};
            });
            return $http({
                url: '/' + ciprid + '/invoices/shoppingcart/items',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'applications': data
                }
            });
        };

        this.addNoDrLicenseToInvoice = function(ciprid, invoiceid, licenses) {
            var data = _.map(licenses, function(l) {
                return {licenseid: l.licenseid, price: l.fee};
            });
            return $http({
                url: '/' + ciprid + '/invoices/' + invoiceid + '/items',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'applications': data
                }
            });
        };


        this.addNoDrLicenseToNewInvoice = function(partyId, licenses) {
            var data = _.map(licenses, function(l) {
                return {licenseid: l.licenseid, price: l.fee};
            });
            return $http.put('/staff/inv/new/addLicense', {
                data: {
                    partyId: partyId,
                    'applications': data,
                }
            });
        };

        this.getOpenInvoices = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/openinvoices/');
        };

        this.addExamsToShoppingCart = function(ciprid, exams, partyid) {
            var data = _.map(exams, function(e) {
                return {regid: e.regid, price: e.fee};
            });
            return $http({
                url: '/' + ciprid + '/invoices/shoppingcart/items',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'exams': data,
                    partyid: partyid
                }
            });
        };

        this.addCECoursesToInvoice = function(invoiceId, ceCourses) {
            return $http.put('/staff/inv/' + invoiceId + '/items', {
                    data: {
                        'ceCourses': ceCourses
                    }
                }
            );
        };

        this.removeCECourseFromInvoice = function(invoiceId, ceCourseId) {
            return $http.delete('/staff/inv/' + invoiceId + '/items/', {
                   params: {
                       ceCourseId : ceCourseId
                    }
                }
            );
        };

        this.addCECoursesToNewInvoice = function(orgId, ceCourses) {
            return $http.put('/staff/inv/new/additem', {
                    data: {
                        'ceCourses': ceCourses,
                         orgId:orgId
                    }
                }
            );
        };

        this.updateNotifyEmail = function(ciprid, invoiceid, invoice) {
            return $http({
                url: '/' + ciprid + '/invoices/' + invoiceid + '/notifyEmail',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'paidnotifyemail': invoice.paidnotifyemail,
                    'paidnotifyall': invoice.paidnotifyall
                }
            });

        };

        this.addPayablesToInvoice = function(ciprid, invoiceid, invoiceitemids) {
            return $http({
                url: '/' + ciprid + '/payables/to/invoice',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'invoiceid': invoiceid,
                    'invoiceitemids': invoiceitemids
                }
            });
        };

        this.addPayablesToNewInvoice = function(ciprid, invoiceitemids) {
            return $http({
                url: '/' + ciprid + '/payables/to/newinvoice',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'invoiceitemids': invoiceitemids
                }
            });
        };

        this.sendEmail = function(ciprid, invoiceid, owneremails) {
            return $http({
                url: '/' + ciprid + '/invoices/' + invoiceid + '/owneremails',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'owneremails': owneremails
                }
            });
        };
    }
]);

