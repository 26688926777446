angular.module('aic.directives', [])

.directive('back', ['$window', function($window) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            elem.bind('click', function() {
                $window.history.back();
            });
        }
    };
}])
.directive('reload', ['$window', function($window) {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            elem.bind('click', function() {
                $window.location.reload();
            });
        }
    };
}])
.directive('repeatEnd', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            if(scope.$last) {
                scope.$eval(attrs.repeatEnd);
            }
        }
    };
})
.directive('selectOnClick', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.on('click', function() {
                this.select();
            });
        }
    };
})
.directive('loading', function() {
    return {
        restrict: 'E',
        template: "<div class='loading-message loading-message-boxed' align='center'><img src='/img/loading-spinner-default.gif'><span>&nbsp;&nbsp;LOADING...</span></div>"
    };
})
.directive('hasPermission', function(permissions) {
    return {
        link: function(scope, element, attrs) {
            var value = attrs.hasPermission.trim();
            var notPermissionFlag = value[0] === '!';
            if(notPermissionFlag) {
                value = value.slice(1).trim();
            }

            function toggleVisibilityBasedOnPermission() {
                var hasPermission = permissions.hasPermission(value);

                if(hasPermission && !notPermissionFlag || !hasPermission && notPermissionFlag)
                    element.show();
                else
                    element.hide();
            }

            toggleVisibilityBasedOnPermission();
            scope.$on('permissionsChanged', toggleVisibilityBasedOnPermission);
        }
    };
})
.directive("plainObjectValidator", function(){
    return {
        require: 'ngModel',
        link: function(scope, ele, attrs, c) {
            scope.$watch(attrs.ngModel, function() {
                c.$setValidity('plainObject', _.isPlainObject(c.$modelValue));
            });
        }
    };
})
.directive('focusOnShow', function($timeout) {
    return {
        restrict: 'A',
        link: function($scope, $element, $attr) {
            if ($attr.ngShow){
                $scope.$watch($attr.ngShow, function(newValue){
                    if(newValue){
                        $timeout(function(){
                            $element[0].focus();
                        }, 0);
                    }
                })
            }
            if ($attr.ngHide){
                $scope.$watch($attr.ngHide, function(newValue){
                    if(!newValue){
                        $timeout(function(){
                            $element[0].focus();
                        }, 0);
                    }
                })
            }

        }
    };
})
.directive("allowedHoursValidator", function() {
    return {
        require: 'ngModel',
        link: function(scope, ele, attrs, c) {
            scope.$watch(attrs.ngModel, function() {
                 c.$setValidity('allowedHours', c.$modelValue === undefined ||  isNaN(c.$modelValue) || c.$modelValue % 0.25 === 0  );
            });
        }
    };
})
.directive("paymentDetails", function() {
    return {
        restrict: 'A',
        template: "<table class=\"table table-striped table-bordered\">\n" +
        " <thead>\n" +
        "  <tr>\n" +
        "    <th>From Party</th>\n" +
        "    <th>E-Commerce Payment</th>\n" +
        "    <th>Requested Amount</th>\n" +
        "    <th>Updated By</th>\n" +
        "    <th>Updated Date</th>\n" +
        "  </tr>\n" +
        " </thead>\n" +
        " <tbody>\n" +
        "  <tr>\n" +
        "    <td>{{payment.from_name}}({{payment.from_id}})</td>\n" +
        "    <td>{{payment.web_payment_yn}}</td>\n" +
        "    <td>{{payment.request_amt}}</td>\n" +
        "    <td>{{payment.updated_by}}</td>\n" +
        "    <td>{{payment.updatedDt}}</td>\n" +
        "  </tr>\n" +
        " </tbody>\n" +
        "</table>",
        link: function(scope, ele, attrs) {
        }
    };
})
.directive('iframeOnload', [function(){
    return {
        restrict: 'A',
        scope: {
            callBack: '&iframeOnload',
        },
        link: function(scope, ele, attrs){
            scope.$on('$destroy', function() { ele.off('load'); })
            ele.on('load', function(){
                return scope.callBack({ele:ele});
            })
        }
    }}]);
