var event = angular.module('aic.event', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
event.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;


    }
])
.service('EventService', ['$http', '$q',
    function($http, $q) {
        this.getPersonEvents = function(partyId, eventFromDate, eventToDate, incCommEvent, incWebEvent) {
            return $http.get('/staff/event/stk/' + partyId , {
                params: {
                    eventFromDate: eventFromDate,
                    eventToDate: eventToDate,
                    incCommEvent: incCommEvent,
                    incWebEvent: incWebEvent
                }
            });
        };

        this.getOrgEvents = function(orgId, eventFromDate, eventToDate, incCommEvent, incWebEvent) {
            return $http.get('/staff/event/org/' + orgId , {
                params: {
                    eventFromDate: eventFromDate,
                    eventToDate: eventToDate,
                    incCommEvent: incCommEvent,
                    incWebEvent: incWebEvent
                }
            });
        };

        this.getCeCourseEvents = function(ceCourseId) {
            return $http.get('/staff/event/cecourse/' + ceCourseId );
        };

        this.getCeProviderEvents = function(ceProviderId) {
            return $http.get('/staff/event/ceprovider/' + ceProviderId );
        };

        this.getAssessmentInvoiceEventHistory = function(assessId) {
            return $http.get('/staff/event/assessmentInvoice/' + assessId );
        };

    }
]);
