var address = angular.module('aic.address', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'dialogs.main',
    'dialogs.default-translations',
    'aic.directives',
    'aic.filters',
    'oitozero.ngSweetAlert'
]);

const CALGARY = "Calgary";
const CALGARY_ID = "91";
const VARIOUS = "Various";
const VARIOUS_ID = "1995725";
const ALBERTA = "Alberta";
const ALBERTA_ID = "24";
const CANADA = "Canada";
const CANADA_ID = "2";

/**
 * Routing and config
 */
address.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {
    }
])

/**
 * Dialog and modal controllers
 */
.controller('AddressEditDialogCtrl', ['$scope', '$uibModalInstance', 'AddressService', 'data', 'SweetAlert',
    function($scope, $uibModalInstance, AddressService, data, SweetAlert) {

        $scope.address = data;

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };

        $scope.setCity = function(item, model, label) {
            $scope.address.city = model.cityname;
            $scope.address.cityid = model.cityid;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.address.province = model.provname;
            $scope.address.provinceid = model.provid;
            $scope.address.provabbrev = model.provabbreviation;
        };

        $scope.setCountry = function(item, model, label) {
            $scope.address.country = model.countryname;
            $scope.address.countryid = model.countryid;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.address);
        };

        $scope.checkCity = function() {
            if(!$scope.address.cityid) {
                $scope.address.city = "";
            }
        };
        $scope.checkProvince = function() {
            if(!$scope.address.provinceid) {
                $scope.address.province = "";
            }
        };
        $scope.checkCountry = function() {
            if(!$scope.address.countryid) {
                $scope.address.country = "";
            }
        };

        $scope.$watch('noResultsCity', function(noNewCity) {
            if(noNewCity){
                $scope.address.cityid = "";
                return;
            }
        });
        $scope.$watch('noResultsCountry', function(noNewCountry) {
            if(noNewCountry){
                $scope.address.countryid = "";
                return;
            }
        });
        $scope.$watch('noResultsProvince', function(noNewProvince) {
            if(noNewProvince){
                $scope.address.provinceid = "";
                return;
            }
        });


    }
])

/**
 * Renewals services
 */
.service('AddressService', ['$http',
    function($http) {
        this.getCities = function(phrase) {
            return $http.get('/address/cities', {
                params: {
                    name: phrase
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getProvinces = function(phrase) {
            return $http.get('/address/provinces', {
                params: {
                    name: phrase
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getCountries = function(phrase) {
            return $http.get('/address/countries', {
                params: {
                    name: phrase
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getAddressTypes = function(phrase) {
            return $http.get('/address/types', {
                params: {
                    name: phrase
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.copyAddress = function(license) {
            return _.pick(license, [
                'street', 'city', 'cityid', 'provabbrev', 'province', 'provinceid', 'provabbrev',
                'postalcode', 'country', 'countryid', 'phone', 'fax', 'email'
            ]);
        };

        this.isAddressValid = function(address) {
            return !_.isEmpty(address.city)
            && !_.isEmpty(address.country)
            && !_.isEmpty(address.email)
            && !_.isEmpty(address.phone)
            && !_.isEmpty(address.postalcode)
            && !_.isEmpty(address.province)
            && !_.isEmpty(address.street);
        };
    }
]);
