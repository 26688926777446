var authorization = angular.module('aic.authorization', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router',
    'ui.ace',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * config
 */
authorization.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('authorization', {
            parent: 'workspace',
            abstract: true,
            url: '/authorization',
            templateUrl: 'authorization/authorization.html',
            controller: 'AuthorizationCtrl'
        })
        .state('authorization.operations', {
            url: '/operations',
            templateUrl: 'authorization/authorization.operations.html',
            controller: 'AuthorizationOperationsCtrl'
        })
        .state('authorization.policies', {
            url: '/policies',
            templateUrl: 'authorization/authorization.policies.html',
            controller: 'AuthorizationPoliciesCtrl'
        });
    }
])

/**
 * Controllers.
 */
.controller('AuthorizationCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'DTOptionsBuilder', 'AuthorizationService',
    function($scope, $state, $stateParams, dialogs, toastr, DTOptionsBuilder, AuthorizationService) {

        $scope.loading = true;
        $scope.resources = {};

        /**
         * Called when 'Add Resource' button is clicked. Presents a dialog.
         */
        $scope.addResource = function() {
            dialogs.create('authorization/dialogs/resource.add.html', 'ResourceAddDialogCtrl', {}, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(form) {
                AuthorizationService.addResource(form.resource, form.operation, form.routes)
                .then(function(reply) {
                    $state.go($state.current, {}, {reload: true});
                    toastr.success('Success!', 'Resource ' + form.resource + ' has been added.');
                }, function(reply) {
                    toastr.error('Adding resource failed', 'Resource could not be added');
                });
            });
        };

        /**
         * Called when 'Add Resource' button is clicked. Presents a dialog.
         */
        $scope.addRole = function() {
            dialogs.create('authorization/dialogs/role.add.html', 'RoleAddDialogCtrl', {}, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(form) {
                AuthorizationService.addRole(form.role, form.operation.resource.name, form.operation.name, form.constraintName, form.constraintFunction)
                .then(function(reply) {
                    $state.go($state.current, {}, {reload: true});
                    toastr.success('Success!', 'Role ' + form.role + ' has been added.');
                }, function(reply) {
                    toastr.error('Adding resource failed', 'Role could not be added');
                });
            });
        };
    }
])
.controller('AuthorizationOperationsCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'DTOptionsBuilder', 'AuthorizationService',
    function($scope, $state, $stateParams, dialogs, toastr, DTOptionsBuilder, AuthorizationService) {

        $scope.loading = true;
        $scope.resources = {};

        /**
         * Fetch operations from server and bind to form
         */
        $scope.showOperations = function() {
            AuthorizationService.getOperations()
            .then(function(res) {
                $scope.resources = _.chain(res.data)
                .groupBy(function(o) {
                    return o.resource.name;
                })
                .value();
                $scope.loading = false;
            });
        };

        /**
         * Called when 'Add Operation' button is clicked. Presents a dialog.
         */
        $scope.addOperation = function(resource) {
            dialogs.create('authorization/dialogs/operation.add.html', 'OperationAddDialogCtrl', {}, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(operation) {
                AuthorizationService.addOperation(resource, operation)
                .then(function(reply) {
                    $scope.showOperations();
                    toastr.success('Success!', 'Operation ' + operation.name + ' has been added.');
                }, function(reply) {
                    toastr.error('Adding operation failed', 'Operation could not be added');
                });
            });
        };

        /**
         * Called when 'Edit Operation' button is clicked. Presents a dialog.
         * @param operation
         */
        $scope.editOperation = function(operation) {
            dialogs.create('authorization/dialogs/operation.add.html', 'OperationEditDialogCtrl', operation, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(operation) {
                AuthorizationService.editOperation(operation)
                .then(function(reply) {
                    $scope.showOperations();
                    toastr.success('Success!', 'Operation ' + operation.name + ' has been modified.');
                }, function(reply) {
                    toastr.error('Adding operation failed', 'Operation could not be modified');
                });
            });
        };

        /**
         * Called when 'Delete Operation' button is clicked. Presents a dialog.
         * @param operation
         */
        $scope.deleteOperation = function(operation) {
            dialogs.create('authorization/dialogs/operation.delete.html', 'OperationDeleteDialogCtrl', operation, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                AuthorizationService.deleteOperation(operation.id)
                .then(function(reply) {
                    $scope.showOperations();
                    toastr.success('Success!', 'Operation ' + operation.name + ' has been deleted.');
                }, function(reply) {
                    toastr.error('Deleting operation failed', 'Operation could not be deleted');
                });
            });
        };

        // When state loads
        $scope.showOperations();
    }
])
.controller('AuthorizationPoliciesCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'DTOptionsBuilder', 'AuthorizationService',
    function($scope, $state, $stateParams, dialogs, toastr, DTOptionsBuilder, AuthorizationService) {

        $scope.loading = true;
        $scope.roles = {};
        $scope.rows = [];

        /**
         * Flatten tree structure to a show as table.
         */
        $scope.showPolicies = function() {
            AuthorizationService.getPolicies()
            .then(function(res) {
                var data = res.data;
                var roles = _.chain(data)
                .groupBy(function(o) {
                    return o.role.name;
                })
                .mapValues(function(value, role) {
                    return _.chain(value)
                    .groupBy(function(o) {
                        return o.resource.name;
                    })
                    .value();
                })
                .value();

                $scope.rows = [];
                _.forEach(roles, function(resources, role) {
                    $scope.rows.push({
                        type: 'role',
                        name: role
                    });
                    _.forEach(resources, function(policies, resource) {
                        $scope.rows.push({
                            type: 'resource',
                            name: resource,
                            parent: role
                        });
                        _.forEach(policies, function(policy) {
                            $scope.rows.push({
                                type: 'policy',
                                parent: resource,
                                role: role,
                                resource: resource,
                                name: policy.name
                            });
                            _.forEach(policy.constraints, function(fn, name) {
                                $scope.rows.push({
                                    type: 'constraint',
                                    name: name,
                                    fn: fn,
                                    parent: policy.name,
                                    role: role,
                                    resource: resource,
                                    policy: policy
                                });
                            });
                        });
                    });
                });

                $scope.roles = roles;
                $scope.loading = false;
            });
        };

        /**
         * Called when 'Add Constraint' button is clicked. Presents a dialog.
         * @param role
         * @param resource
         * @param policy
         */
        $scope.addPolicy = function(role, resource, policy) {
            dialogs.create('authorization/dialogs/policy.add.html', 'PolicyAddDialogCtrl', {
                role: role,
                resource: resource,
                policy: policy
            }, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(form) {
                console.log(form);
                AuthorizationService.addPolicy(form.role, form.resource, form.policy, form.constraintName, form.constraintFunction)
                .then(function(reply) {
                    $scope.showPolicies();
                    toastr.success('Success!', 'Policy ' + form.constraintName + ' has been added.');
                }, function(reply) {
                    toastr.error('Adding policy failed', 'Please try again');
                });
            });
        };

        /**
         * Called when 'Edit Constraint' button is clicked. Presents a dialog.
         * @param role
         * @param resource
         * @param policy
         * @param constraintName
         * @param constraintFunction
         */
        $scope.editPolicy = function(role, resource, policy, constraintName, constraintFunction) {
            dialogs.create('authorization/dialogs/policy.edit.html', 'PolicyEditDialogCtrl', {
                role: role,
                resource: resource,
                policy: policy,
                constraintName: constraintName,
                constraintFunction: constraintFunction
            }, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(form) {
                AuthorizationService.editPolicy(form.role, form.resource, form.policy, form.constraintName, form.constraintFunction)
                .then(function(reply) {
                    $scope.showPolicies();
                    toastr.success('Success!', 'Policy ' + form.constraintName + ' has been updated.');
                }, function(reply) {
                    toastr.error('Updating policy failed', 'Please try again');
                });
            });
        };

        /**
         * Called when 'Delete Constraint' button is clicked. Presents a dialog.
         * @param role
         * @param resource
         * @param policy
         * @param constraintName
         * @param constraintFunction
         */
        $scope.deletePolicy = function(role, resource, policy, constraintName, constraintFunction) {
            dialogs.create('authorization/dialogs/policy.delete.html', 'PolicyDeleteDialogCtrl', {
                role: role,
                resource: resource,
                policy: policy,
                constraintName: constraintName,
                constraintFunction: constraintFunction
            }, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(form) {
                AuthorizationService.deletePolicy(form.policy, form.constraintName)
                .then(function(reply) {
                    $scope.showPolicies();
                    toastr.success('Success!', 'Policy ' + form.constraintName + ' has been removed.');
                }, function(reply) {
                    toastr.error('Updating policy failed', 'Please try again');
                });
            });
        };

        // When state loads
        $scope.showPolicies();
    }
])

/**
 * Dialog and modal controllers
 */
.controller('ResourceAddDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.availableRoutes = [];

        // form data
        $scope.form = {};

        /**
         * Get available routes in the system
         */
        AuthorizationService.getRoutes()
        .then(function(res) {
            $scope.availableRoutes = res.data;
        });

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.form);
        };
    }
])
.controller('RoleAddDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.availableOperations = [];

        // form data
        $scope.form = {};
        $scope.constraintFunction = '';

        /**
         * Get available operations in the system
         */
        AuthorizationService.getOperations()
        .then(function(res) {
            $scope.availableOperations = res.data;
        });

        $scope.groupByResource = function(item) {
            return item.resource.name;
        };

        $scope.aceLoaded = function(_editor) {
            // Editor part
            var _session = _editor.getSession();
            var _renderer = _editor.renderer;

            // Options
            _editor.$blockScrolling = Infinity;
            _editor.setOptions({
                showGutter: true,
                showLineNumbers: true,
                fontSize: "14pt",
                mode: 'ace/mode/javascript',
                theme: 'ace/theme/github'
            });
            _session.setUndoManager(new ace.UndoManager());

            _editor.getSession().on('change', function() {
                $scope.constraintFunction = _editor.getSession().getValue();
            });
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.form.constraintFunction = window.btoa($scope.constraintFunction);
            $uibModalInstance.close($scope.form);
        };
    }
])

.controller('OperationAddDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.availableRoutes = [];
        $scope.selectedRoutes = [];

        // form data
        $scope.operation = {};

        /**
         * Get available routes in the system
         */
        AuthorizationService.getRoutes()
        .then(function(res) {
            $scope.availableRoutes = res.data;
        });

        /**
         * If invoice is selected, application is added to invoice
         * @param item
         * @param model
         */
        $scope.addRoute = function(item, model) {
            $scope.selectedRoutes.push(model);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.operation.routes = $scope.selectedRoutes;
            $uibModalInstance.close($scope.operation);
        };
    }
])
.controller('OperationEditDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.availableRoutes = [];
        $scope.selectedRoutes = [];

        // form data
        $scope.operation = data;

        /**
         * Get available routes in the system
         */
        AuthorizationService.getRoutes()
        .then(function(res) {
            $scope.availableRoutes = res.data;
        });

        /**
         * If invoice is selected, application is added to invoice
         * @param item
         * @param model
         */
        $scope.addRoute = function(item, model) {
            $scope.selectedRoutes.push(model);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.operation);
        };
    }
])
.controller('OperationDeleteDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.operation = data;
        $scope.policies = [];

        /**
         * Get available routes in the system
         */
        AuthorizationService.getPolicies($scope.operation.id)
        .then(function(res) {
            $scope.policies = res.data;
        });

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.policies);
        };
    }
])

.controller('PolicyAddDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.form = data;
        $scope.constraintFunction = '';

        $scope.aceLoaded = function(_editor) {
            // Editor part
            var _session = _editor.getSession();
            var _renderer = _editor.renderer;

            // Options
            _editor.$blockScrolling = Infinity;
            _editor.setOptions({
                showGutter: true,
                showLineNumbers: true,
                fontSize: "14pt",
                mode: 'ace/mode/javascript',
                theme: 'ace/theme/github'
            });
            _session.setUndoManager(new ace.UndoManager());

            _editor.getSession().on('change', function() {
                $scope.constraintFunction = _editor.getSession().getValue();
            });
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.form.constraintFunction = window.btoa($scope.constraintFunction);
            $uibModalInstance.close($scope.form);
        };
    }
])
.controller('PolicyEditDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.form = data;
        $scope.constraintFunction = '';

        $scope.aceLoaded = function(_editor) {
            // Editor part
            var _session = _editor.getSession();
            var _renderer = _editor.renderer;

            // Options
            _editor.$blockScrolling = Infinity;
            _editor.setOptions({
                showGutter: true,
                showLineNumbers: true,
                fontSize: "14pt",
                mode: 'ace/mode/javascript',
                theme: 'ace/theme/github'
            });
            _session.setUndoManager(new ace.UndoManager());

            _editor.getSession().on('change', function() {
                $scope.constraintFunction = _editor.getSession().getValue();
            });
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.form.constraintFunction = window.btoa($scope.constraintFunction);
            $uibModalInstance.close($scope.form);
        };
    }
])
.controller('PolicyDeleteDialogCtrl', ['$scope', '$uibModalInstance', 'AuthorizationService', 'data',
    function($scope, $uibModalInstance, AuthorizationService, data) {

        $scope.form = data;

        $scope.aceLoaded = function(_editor) {
            // Editor part
            var _session = _editor.getSession();
            var _renderer = _editor.renderer;

            // Options
            _editor.$blockScrolling = Infinity;
            _editor.setOptions({
                showGutter: true,
                showLineNumbers: true,
                fontSize: "14pt",
                mode: 'ace/mode/javascript',
                theme: 'ace/theme/github',
                readOnly: true
            });
            _session.setUndoManager(new ace.UndoManager());

            _editor.getSession().on('change', function() {
                $scope.constraintFunction = _editor.getSession().getValue();
            });
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.form);
        };
    }
])

/**
 * Services
 */
.service('AuthorizationService', ['$http',
    function($http) {

        this.getRoutes = function() {
            return $http.get('/staff/authorization/routes');
        };

        this.addResource = function(resourceName, operationName, routes) {
            return $http.post('/staff/authorization/resources', {
                resourceName: resourceName,
                operationName: operationName,
                routes: _.map(routes, function(route) {
                    return {method: route.method, uri: route.uri};
                })
            });
        };

        this.addRole = function(roleName, resourceName, operationName, constraintName, constraintFunction) {
            return $http.post('/staff/authorization/roles', {
                roleName: roleName,
                resourceName: resourceName,
                operationName: operationName,
                constraintName: constraintName,
                constraintFunction: constraintFunction
            });
        };

        // -- operations --

        this.getOperations = function() {
            return $http.get('/staff/authorization/operations');
        };

        this.addOperation = function(resource, operation) {
            return $http.post('/staff/authorization/operations', {
                resourceName: resource,
                operationName: operation.name,
                routes: _.map(operation.routes, function(route) {
                    return {method: route.method, uri: route.uri};
                })
            });
        };

        this.editOperation = function(operation) {
            return $http.put('/staff/authorization/operations', {
                operationId: operation.id,
                operationName: operation.name,
                routes: _.map(operation.routes, function(route) {
                    return {method: route.method, uri: route.uri};
                })
            });
        };

        this.deleteOperation = function(operationid) {
            var params = {operationid: operationid};
            return $http.delete('/staff/authorization/operations', {params: params});
        };

        // -- policies --

        this.getPolicies = function(operationid) {
            var params = operationid ? {operationid: operationid} : {};
            return $http.get('/staff/authorization/policies', {params: params});
        };

        this.addPolicy = function(roleName, resourceName, operationName, constraintName, constraintFunction) {
            return $http.post('/staff/authorization/policies/', {
                roleName: roleName,
                resourceName: resourceName,
                operationName: operationName,
                constraintName: constraintName,
                constraintFunction: constraintFunction
            });
        };

        this.editPolicy = function(role, resource, policy, constraintName, constraintFunction) {
            return $http.put('/staff/authorization/policies/' + policy.id + '/constraints', {
                role: role,
                resource: resource,
                constraintName: constraintName,
                constraintFunction: constraintFunction
            });
        };

        this.deletePolicy = function(policy, constraintName) {
            return $http({
                method: 'DELETE',
                url: '/staff/authorization/policies/' + policy.id + '/constraints',
                data: {constraintName: constraintName},
                headers: {'Content-Type': 'application/json;charset=utf-8'}
            });
        };
    }
]);

