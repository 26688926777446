var payables = angular.module('aic.payables', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'aic.invoices'
]);

payables.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('payables', {
            parent: 'workspace',
            abstract: true,
            templateUrl: 'payables/payables.html'
        })
        .state('payables.list', {
            url: '/payables',
            templateUrl: 'payables/payables.list.html',
            controller: 'PayablesListCtrl'
        })
        .state('payables.pay', {
            url: '/pay',
            params: {
                selectedItemIds: {array: true}
            },
            templateUrl: 'payables/payables.pay.html',
            controller: 'PayablesPayCtrl'
        });
    }
])

.controller('PayablesListCtrl', ['$scope', '$state', '$http', '$filter', '$q', 'blockUI', 'toastr', 'PayableService', 'DTOptionsBuilder',
    function($scope, $state, $http, $filter, $q, blockUI, toastr, PayableService, DTOptionsBuilder) {
        $scope.page = {
            payables: [],
            selectedItemIds: [],
            searchPhrase: '',

            items: [],
            totalItems: 0,
            begin: 0,
            end: 0,
            pageSize: 10,
            currentPage: 1
        };

        /**
         * Initializers
         */
        var payablesBlock = blockUI.instances.get('payables-block');
        payablesBlock.start();

        PayableService.getPayables($scope.roleContext.ciprid)
        .then(function(res) {

            $scope.page.payables = res.data;

            var corpLicenses = _.filter($scope.page.payables, $scope.isCorpLicense);
            $scope.page.payables = _.map($scope.page.payables, function(payable) {
                if($scope.isDRLicense(payable)) {
                    var corplicitem = _.find(corpLicenses, function(corpLicenseInvoiceItem) {
                        var corpLicense = corpLicenseInvoiceItem.stklic;
                        return payable.stklic.corporatelicenseclass == corpLicense.licenseclass.trim() && payable.stklic.agencyid == corpLicense.agencyid;
                    });
                    payable.corplic = corplicitem;
                    payable.corplicitemid = corplicitem ? corplicitem.invoiceitemid : '';
                    return payable;
                }
                return payable;
            });

            $scope.renderPage();
            payablesBlock.stop();
        });

        // -- called from form

        $scope.renderPage = function() {
            $scope.page.begin = (($scope.page.currentPage - 1) * $scope.page.pageSize);
            $scope.page.end = $scope.page.begin + $scope.page.pageSize;
            $scope.filteredItems = $scope.filter($scope.page.payables);
            $scope.page.totalItems = $scope.filteredItems.length;
            $scope.page.items = $scope.filteredItems.slice($scope.page.begin, $scope.page.end);
        };

        $scope.filter = function(payables) {
            var filteredItems = [];

            // filter by search phrase
            filteredItems = $filter('filter')(payables, function(r) {
                if($scope.search(r.license, $scope.page.searchPhrase)) return true;
                if($scope.search(r.licholderpartyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.exampartyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.agencypartyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.exampartyname, $scope.page.searchPhrase)) return true;
                return false;
            });

            return filteredItems;
        };

        $scope.search = function(haystack, needle) {
            return needle ? haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1 : true;
        };

        $scope.isPaid = function(item) {
            return parseFloat(item.balance) == 0;
        };

        $scope.isLicenseApplication = function(item) {
            return item.itemtypecd == "LIC_APP";
        };

        $scope.isLicenseRenewal = function(item) {
            return item.itemtypecd == "LIC_REN";
        };

        $scope.isDRLicense = function(item) {
            return item.licenseid && item.stklic.drlicense == "Y";
        };

        $scope.isCorpLicense = function(item) {
            return item.licenseid && item.stklic.licensetype == "C";
        };

        $scope.isExamApplication = function(item) {
            return item.itemtypecd == "EXAM_APP";
        };

        /**
         * Called when checkbox is clicked to select/unselect all.
         *
         * @param $event
         */
        $scope.updateAllSelection = function($event) {
            $scope.page.selectedItemIds = [];

            var checkbox = $event.target;
            if(!checkbox.checked) return;

            var selected = $scope.filter($scope.page.items);
            _.forEach(selected, function(item) {
                if($scope.isDRLicense(item) && item.corplicitemid) {
                    $scope.page.selectedItemIds.push(item.corplicitemid);
                }
                $scope.page.selectedItemIds.push(item.invoiceitemid);
            });
        };

        /**
         * Removes item from payable list
         *
         * @param item
         */
        $scope.deleteInvoiceItem = function(item) {
            if($scope.isDRLicense(item) && item.corplicitemid) return $scope.deleteDRLicenseInvoiceItem(item);
            PayableService.deleteInvoiceItem($scope.roleContext.ciprid, item.invoiceitemid)
            .then(function(res) {
                $scope.page.items = _.without($scope.page.items, item);
                $scope.page.payables = _.without($scope.page.payables, item);
                toastr.success('Success!', 'Invoice item has been removed.');
            }, function(res) {
                toastr.error('Error!', 'Invoice item cannot be deleted at this time.');
            });
        };

        $scope.deleteDRLicenseInvoiceItem = function(item) {
            $q.all([PayableService.deleteInvoiceItem($scope.roleContext.ciprid, item.invoiceitemid)], PayableService.deleteInvoiceItem($scope.roleContext.ciprid, item.corplicitemid))
            .then(function(res) {
                var corpitem = _.find($scope.page.items, {'invoiceitemid': item.corplicitemid});
                $scope.page.items = _.without($scope.page.items, item, corpitem);
                $scope.page.payables = _.without($scope.page.payables, item, corpitem);
                toastr.success('Success!', 'Invoices item has been removed.');
            },function(err){
                toastr.error('Error!', 'Invoice items cannot be deleted at this time.');
            })

        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param item
         */
        $scope.updateSelection = function($event, item) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.page.selectedItemIds.indexOf(item.invoiceitemid) === -1) {
                if($scope.isDRLicense(item) && item.corplicitemid) {
                    $scope.page.selectedItemIds.push(item.corplicitemid);
                }
                $scope.page.selectedItemIds.push(item.invoiceitemid);
            }

            if(!checkbox.checked && $scope.page.selectedItemIds.indexOf(item.invoiceitemid) !== -1) {
                if($scope.isDRLicense(item) && item.corplicitemid) {
                    $scope.page.selectedItemIds.splice($scope.page.selectedItemIds.indexOf(item.corplicitemid), 1);
                }
                $scope.page.selectedItemIds.splice($scope.page.selectedItemIds.indexOf(item.invoiceitemid), 1);
            }
        };

        /**
         * Is any item selected
         * @param selectedItemIds
         * @returns {boolean}
         */
        $scope.isSelected = function(selectedItemIds) {
            return $scope.page.selectedItemIds.indexOf(selectedItemIds) >= 0;
        };

        /**
         * Presents an invoice selection page.
         */
        $scope.addToInvoice = function() {
            $state.go('payables.pay', {selectedItemIds: $scope.page.selectedItemIds});
        };

    }
])
.controller('PayablesPayCtrl', ['$scope', '$state', '$stateParams', '$filter', 'dialogs', 'toastr', 'blockUI', 'PayableService', 'InvoiceService',
    function($scope, $state, $stateParams, $filter, dialogs, toastr, blockUI, PayableService, InvoiceService) {

        $scope.page = {
            payables: [],
            unpaidInvoices: [],
            invoice: undefined,
            searchPhrase: '',

            items: [],
            totalItems: 0,
            begin: 0,
            end: 0,
            pageSize: 10,
            currentPage: 1
        };

        /**
         * Initializers
         */
        var block = blockUI.instances.get('payables-pay-block');
        block.start();

        PayableService.getSelectedPayables($scope.roleContext.ciprid, $stateParams.selectedItemIds)
        .then(function(res) {
            $scope.page.payables = res.data;

            $scope.renderPage();
            block.stop();
        });

        /**
         * Fetch unpaid invoices and prepopulate $scope.unpaidInvoices
         */
        InvoiceService.getUnpaidInvoices($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.page.unpaidInvoices.push({
                'invoiceid': 'new'
            });
            $scope.page.unpaidInvoices = $scope.page.unpaidInvoices.concat(res.data);
        });

        // -- Pagination --

        $scope.renderPage = function() {
            $scope.page.begin = (($scope.page.currentPage - 1) * $scope.page.pageSize);
            $scope.page.end = $scope.page.begin + $scope.page.pageSize;
            $scope.filteredItems = $scope.filter($scope.page.payables);
            $scope.page.totalItems = $scope.filteredItems.length;
            $scope.page.items = $scope.filteredItems.slice($scope.page.begin, $scope.page.end);
        };

        $scope.filter = function(payables) {
            var filteredItems = [];

            // filter by search phrase
            filteredItems = $filter('filter')(payables, function(r) {
                if($scope.search(r.license, $scope.page.searchPhrase)) return true;
                if($scope.search(r.licholderpartyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.exampartyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.agencypartyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.exampartyname, $scope.page.searchPhrase)) return true;
                return false;
            });

            return filteredItems;
        };

        $scope.search = function(haystack, needle) {
            return needle ? haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1 : true;
        };

        // --- Called from the form ---

        $scope.isDRRenewal = function(renewal) {
            return renewal.drlicense == 'Y';
        };

        $scope.isLicenseApplication = function(item) {
            return item.itemtypecd == "LIC_APP";
        };

        $scope.isLicenseRenewal = function(item) {
            return item.itemtypecd == "LIC_REN";
        };

        $scope.isDRLicense = function(item) {
            return item.licenseid && item.stklic.drlicense == "Y";
        };

        $scope.isCorpLicense = function(item) {
            return item.licenseid && item.stklic.licensetype == "C";
        };

        $scope.isExamApplication = function(item) {
            return item.itemtypecd == "EXAM_APP";
        };

        /**
         * If invoice is selected, application is added to invoice
         * @param item
         * @param model
         */
        $scope.selectInvoice = function(item, model) {
            $scope.page.invoice = model;
        };

        /**
         * When submit is clicked, add licenses to selected invoice.
         */
        $scope.save = function() {
            if($scope.page.invoice.invoiceid == 'new') $scope.addToNewInvoice();
            else $scope.addToInvoice();
        };

        /**
         * Add to selected invoice.
         */
        $scope.addToInvoice = function() {
            block.start("Adding to Invoice");
            InvoiceService.addPayablesToInvoice($scope.roleContext.ciprid, $scope.page.invoice.invoiceid, $stateParams.selectedItemIds)
            .then(function() {
                block.stop();
                toastr.success('Success!', $stateParams.selectedItemIds.length + ' items added to invoice');
                $state.go('invoices.view', {invoiceId: $scope.page.invoice.invoiceid});
            }, function() {
                block.stop();
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };

        /**
         * Add to a New invoice.
         */
        $scope.addToNewInvoice = function() {
            block.start("Adding to Invoice");
            InvoiceService.addPayablesToNewInvoice($scope.roleContext.ciprid, $stateParams.selectedItemIds)
            .then(function(res) {
                block.stop();
                toastr.success('Success!', $stateParams.selectedItemIds.length + ' items added to invoice');
                $state.go('invoices.view', {invoiceId: res.data.invoiceid});
            }, function() {
                block.stop();
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };
    }
])

/**
 * Invoice services
 */
.service('PayableService', ['$http', '$q',
    function($http, $q) {

        this.getPayables = function(ciprid) {
            return $http.get('/' + ciprid + '/payables');
        };

        this.getSelectedPayables = function(ciprId, invoiceItemIds) {
            var deferred = $q.defer();
            deferred.resolve([]);

            return !_.isArray(invoiceItemIds) || invoiceItemIds.length == 0 ? deferred.promise : $http({
                url: '/' + ciprId + '/payables/selected',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'invoiceitemids': invoiceItemIds
                }
            });
        };

        this.deleteInvoiceItem = function(ciprid, invoiceitemid) {
            return $http.delete('/' + ciprid + '/payables//items/' + invoiceitemid)
        };

        this.addApplicationsToPayables = function(ciprid, applications) {
            var data = _.map(applications, function(a) {
                return {licenseid: a.licenseid, price: a.fee};
            });
            return $http({
                url: '/' + ciprid + '/payables/items',
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'applications': data
                }
            });
        };
    }
]);


