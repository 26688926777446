var orgs = angular.module('aic.organizations', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'ui.bootstrap.tabs',
    'aic.address',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.subscribe',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert',
    'ngTagsInput',
    'ngJsTree'
]);

orgs.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {
        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('organizations', {
            parent: 'workspace',
            abstract: true,
            url: '/organizations',
            templateUrl: 'organizations/organizations.html'
        })
        .state('organizations.search', {
            url: '/search',
            params: {
                cache: true
            },
            templateUrl: 'organizations/organizations.search.html',
            controller: 'OrganizationsSearchCtrl'
        })
        .state('organizations.init', {
            url: '/init',
            templateUrl: 'organizations/organizations.init.html',
            controller: 'OrganizationsInitCtrl'
        })
        .state('organizations.agency', {
            url: '/agency',
            templateUrl: 'organizations/organizations.agency.init.html',
            controller: 'OrganizationsAgencyInitCtrl'
        });


        $stateProvider
        .state('organizations.specific', {
            url: '/{organizationId:[0-9]+}',
            abstract: true,
            resolve: {
                promisedOrg: ['$stateParams', 'AuthService', 'OrganizationsResolverService',
                    function($stateParams, AuthService, OrganizationsResolverService) {
                        var ciprId = AuthService.getRoleContext().ciprid;
                        var organizationId = $stateParams.organizationId;
                        var resolved = OrganizationsResolverService.get(ciprId, organizationId);
                        return resolved;
                    }
                ]
            },
            controller: 'OrganizationsSpecificCtrl',
            templateUrl: 'organizations/organizations.specific.html'
        });

        // Organization profile tab
        $stateProvider
        .state('organizations.specific.profile', {
            url: '/profile',
            abstract: true,
            templateUrl: 'organizations/specific/profile/profile.html',
            controller: 'OrganizationsSpecificProfileCtrl'
        })
        .state('organizations.specific.profile.view', {
            url: '/view',
            templateUrl: 'organizations/specific/profile/view.html',
            controller: 'OrganizationsSpecificProfileViewCtrl'
        })
        .state('organizations.specific.profile.edit', {
            url: '/edit',
            templateUrl: 'organizations/specific/profile/edit.html',
            controller: 'OrganizationsSpecificProfileEditCtrl'
        });

        // Organization LOB tab
        $stateProvider
        .state('organizations.specific.lob', {
            url: '/lob',
            abstract: true,
            templateUrl: 'organizations/specific/lob/lob.html',
            controller: 'OrganizationsSpecificLobCtrl'
        })
        .state('organizations.specific.lob.list', {
            url: '/insurer',
            templateUrl: 'organizations/specific/lob/list.html',
            controller: 'OrganizationsSpecificLobListCtrl'
        })
        .state('organizations.specific.lob.insurer', {
            url: '/insurer',
            templateUrl: 'organizations/specific/lob/insurer.html',
            controller: 'OrganizationsSpecificLobInsurerCtrl'
        })
        .state('organizations.specific.lob.agency', {
            url: '/agency',
            templateUrl: 'organizations/specific/lob/agency.html',
            controller: 'OrganizationsSpecificLobAgencyCtrl'
        })
        .state('organizations.specific.lob.ce', {
            url: '/ce',
            templateUrl: 'organizations/specific/lob/ce.html',
            controller: 'OrganizationsSpecificLobCECtrl'
        })
        .state('organizations.specific.lob.eo', {
            url: '/ce',
            templateUrl: 'organizations/specific/lob/eo.html',
            controller: 'OrganizationsSpecificLobEOCtrl'
        });


        // Organization users tab
        $stateProvider
        .state('organizations.specific.users', {
            url: '/users',
            abstract: true,
            templateUrl: 'organizations/specific/users/users.html',
            controller: 'OrganizationsSpecificUsersCtrl'
        })
        .state('organizations.specific.users.list', {
            url: '/list',
            templateUrl: 'organizations/specific/users/list.html',
            controller: 'OrganizationsSpecificUsersListCtrl'
        })
        .state('organizations.specific.users.new', {
            url: '/new',
            templateUrl: 'organizations/specific/users/new.html',
            controller: 'OrganizationsSpecificUsersNewCtrl'
        })
        .state('organizations.specific.users.password', {
            url: '/password',
            templateUrl: 'organizations/specific/users/password.html',
            controller: 'OrganizationsSpecificUsersPasswordCtrl'
        })
        .state('organizations.specific.users.declarations', {
            url: '/declarations',
            templateUrl: 'organizations/specific/users/declarations.html',
            controller: 'OrganizationsSpecificUsersDeclarationsCtrl'
        })
        .state('organizations.specific.users.view', {
            url: '/{ciprId:[0-9]+}',
            templateUrl: 'organizations/specific/users/view.html',
            controller: 'OrganizationsSpecificUsersViewCtrl'
        });

        // Organization Communication tab
        $stateProvider
        .state('organizations.specific.communicationchannels', {
            url: '/communicationchannels',
            abstract: true,
            templateUrl: 'organizations/specific/communicationchannels/communicationchannels.html',
            controller: 'OrganizationsSpecificCommunicationChannelsCtrl'
        })
        .state('organizations.specific.communicationchannels.list', {
            url: '/list',
            templateUrl: 'organizations/specific/communicationchannels/list.html',
            controller: 'OrganizationsSpecificCommunicationChannelsListCtrl'
        })

        // Organization Invoices tab
        $stateProvider
        .state('organizations.specific.invoices', {
            url: '/invoices',
            abstract: true,
            templateUrl: 'organizations/specific/invoices/invoices.html',
            controller: 'OrganizationsSpecificInvoicesCtrl'
        })
        .state('organizations.specific.invoices.list', {
            url: '/list',
            templateUrl: 'invoices/invoices.list.html',
            controller: 'OrganizationsSpecificInvoicesListCtrl'
        });

        // Organization payables tab
        $stateProvider
        .state('organizations.specific.payables', {
            url: '/payables',
            abstract: true,
            templateUrl: 'organizations/specific/payables/payables.html',
            controller: 'OrganizationsSpecificPayablesCtrl'
        })
        .state('organizations.specific.payables.list', {
            url: '/list',
            templateUrl: 'orgs/payables/payables.list.html',
            controller: 'OrganizationsSpecificPayablesListCtrl'
        });

        // Organization E&O tab
        $stateProvider
        .state('organizations.specific.eando', {
            url: '/eando',
            abstract: true,
            templateUrl: 'organizations/specific/eando/eando.html',
            controller: 'OrganizationsSpecificEAndOCtrl'
        })
        .state('organizations.specific.eando.list', {
            url: '/list',
            templateUrl: 'orgs/eando/eando.list.html',
            controller: 'OrganizationsSpecificEAndOListCtrl'
        });


        // Organization Licenses tab
        $stateProvider
        .state('organizations.specific.licenses', {
            url: '/licenses',
            abstract: true,
            templateUrl: 'organizations/specific/licenses/licenses.html',
            controller: 'OrganizationsSpecificLicensesCtrl'
        })
        .state('organizations.specific.licenses.issued', {
            url: '/issued',
            templateUrl: 'orgs/licenses/licenses.list.html',
            controller: 'OrganizationsSpecificLicensesIssuedCtrl'
        })
        .state('organizations.specific.licenses.applications', {
            url: '/applications',
            templateUrl: 'orgs/applications/application.list.html',
            controller: 'OrganizationsSpecificLicensesApplicationsCtrl'
        })
        .state('organizations.specific.licenses.renewals', {
            url: '/renewals',
            templateUrl: 'orgs/renewals/renewals.list.html',
            controller: 'OrganizationsSpecificLicensesRenewalsCtrl'
        });

        // Organization Notes tab
        $stateProvider
        .state('organizations.specific.notes', {
            url: '/notes',
            abstract: true,
            templateUrl: 'organizations/specific/notes/notes.html',
            controller: 'OrganizationsSpecificNotesCtrl'
        })
        .state('organizations.specific.notes.list', {
            url: '/list',
            templateUrl: 'staff/notes/notes.list.html',
            controller: 'OrganizationsSpecificNotesListCtrl'
        });

        // Organization Events tab
        $stateProvider
        .state('organizations.specific.events', {
            url: '/events',
            abstract: true,
            templateUrl: 'organizations/specific/events/events.html',
            controller: 'OrganizationsSpecificEventsCtrl'
        })
        .state('organizations.specific.events.list', {
            url: '/list',
            templateUrl: 'staff/event/event.list.html',
            controller: 'OrganizationsSpecificEventsListCtrl'
        });

        // Organization Accounts tab
        $stateProvider
        .state('organizations.specific.accounts', {
            url: '/accounts',
            abstract: true,
            templateUrl: 'organizations/specific/accounts/accounts.html',
            controller: 'OrganizationsSpecificAccountsCtrl'
        });


    }
])
.controller('OrganizationsSearchCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', 'OrganizationsService',
    function($scope, $state, $stateParams, $http, $location, OrganizationsService) {
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        $scope.searchStr = '';

        $scope.organizations = [];
        $scope.totalItems = 0;

        $scope.setPage = function(pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $scope.search();
        };

        $scope.search = function() {
            OrganizationsService.browseOrganizations($scope.roleContext.ciprid, $scope.searchStr, $scope.currentPage, $scope.pageSize)
            .then(function(res) {
                $scope.organizations = res.data.slice;
                $scope.totalItems = res.data.totalSize;
            });
        };

        $scope.viewProfile = function(organization) {
            $state.go('organizations.specific.profile.view', {organizationId: organization.orgId});
        };

    }
])
.controller('OrganizationsInitCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', '$location', 'FileUploader', 'blockUI', 'DownloadService', 'OrganizationsService', 'PartyService',
    function($scope, $state, $stateParams, $http, $filter, $location, FileUploader, blockUI, DownloadService, OrganizationsService, PartyService) {
        var limitFilter = {
            name: 'limitFilter',
            fn: function() {
                return this.queue.length < 10;
            }
        };
        var pdfFilter = {
            name: 'pdfFilter',
            fn: function(item) {
                return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
            }
        };
        $scope.inscats = [];
        $scope.insLiccls = [];
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.activeDate = moment().format('MMMM DD, YYYY');
        $scope.activeDateBegin = moment("1970-01-01").format('MMMM DD, YYYY');
        $scope.activeDateEnd = moment().format('MMMM DD, YYYY');
        $scope.warningDate = moment().subtract(6, 'month');
        var organizationInitBlock = blockUI.instances.get('organization-init-block');

        $scope.statuses = [{id: "ACTIVE", "description": 'Active'},
            {"id": "PENDING", "description": 'Pending'},
            {"id": 'INACTIVE', "description": 'Inactive'}];
        $scope.lobs = [
            {
                "id": "INSCO",
                "description": 'Insurance Company',
                "status": _.find($scope.statuses, {id: "PENDING"}),
                "activeDate": moment().format('MMMM DD, YYYY')
            },
            {
                "id": "EOCARRIER",
                "description": "E & O Carrier",
                "status": _.find($scope.statuses, {id: "PENDING"}),
                "activeDate": moment().format('MMMM DD, YYYY')
            }
        ]
        ;
        var initForm = function() {
            $scope.legalExists = false;
            $scope.soiExists = false;
            $scope.activeOldDate = false;
            $scope.org = {
                lob: '',
                legalName: '',
                tradeName: '',
                eocarrier: 'N',
                primaryemail: '',
                selectedLOBs: [],
                selectedInscatIds: [],
                selectedLicclsIds: [],
                admin: '',
                comments: ''
            };
        };

        initForm();

        OrganizationsService.getInscats($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.inscats = _.reject(res.data, {id: "ADJ"});
        });

        OrganizationsService.getInsLiccls($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.insLiccls = res.data;
        });

        $scope.uploader = new FileUploader({
            autoUpload: false,
            filters: [limitFilter, pdfFilter],
            headers: {
                Authorization: 'Bearer ' + $scope.jwtToken
            },
            onSuccessItem: function(item, response, status, headers) {
                toastr.success('Success!', 'Successfully uploaded file ' + item.file.name);
            },
            onErrorItem: function(item, response, status, headers) {
                toastr.error('Error!', 'Error uploading File ' + item.file.name);
            }
        });

        $scope.createProfile = function() {
            organizationInitBlock.start();
            var onsuccess = function(res) {
                organizationInitBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', 'Organization cannot be created at this time.');
                }
                if(res.data.org[0]) {
                    toastr.success('Success!', 'Organization successfully created.');
                    $scope.upload(res.data.org[0]);
                    $state.go('organizations.specific.profile.view', {organizationId: res.data.org[0].partyId});
                } else
                    toastr.error('Error!', 'Organization cannot be created at this time.');
            };
            var onerror = function() {
                organizationInitBlock.stop();
                toastr.error('Error!', 'Organization cannot be created at this time.');
            };


            OrganizationsService.init($scope.roleContext.ciprid, $scope.org)
            .then(onsuccess, onerror);

        };

        $scope.clear = function() {
            initForm();
        };

        $scope.isOldDate = function(lob){
            return $scope.warningDate.isAfter($filter('date')(lob.activeDate, "MM dd, yyyy"));
        };
        // $scope.setActiveDate = function(selectedDate) {
        //     $scope.activeDate = $filter('date')(selectedDate, "MM dd, yyyy");
        //     $scope.activeOldDate = $scope.warningDate.isAfter($scope.activeDate);
        // };

        $scope.setInsuranceCompany = function($event) {
            let checkbox = $event.target;
            if(checkbox.checked)
                $scope.org.insurancecompany = 'Y';
        };

        /**
         * Used in typeahead for parties
         * @param phrase
         * @returns {*}
         */
        $scope.getParties = function(phrase, limit) {
            return PartyService.getParties(phrase, limit);
        };

        $scope.checkLegalName = function(legalName) {
            $scope.legalExists = false;
            OrganizationsService.getOrgByLegalName($scope.roleContext.ciprid, legalName)
            .then(function(res) {
                $scope.legalExists = !_.isEmpty(res.data);
            });

        };

        $scope.checkSOILicense = function(licenseNum) {
            $scope.soiExists = false;
            OrganizationsService.getOrgBySOILicense($scope.roleContext.ciprid, licenseNum)
            .then(function(res) {
                $scope.soiExists = !_.isEmpty(res.data);
            });
        };

        /**
         * upload file and set the url with orgId
         * @param org
         */
        $scope.upload = function(org) {
            var items = $scope.uploader.getNotUploadedItems();
            _.map(items, function(item, index) {
                item.url = '/' + $scope.roleContext.ciprid + '/organizations/' + org.partyId + '/upload/soidocuments';
                // item.url = '/' + $scope.roleContext.ciprid + '/organizations/50/upload/soidocuments';
            });
            $scope.uploader.uploadAll();
        };


        /**
         * update selected inscats
         * @param $event
         * @param inscatId
         */
        $scope.updateInscats = function($event, inscatId) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.org.selectedInscatIds.indexOf(inscatId) === -1) {
                $scope.org.selectedInscatIds.push(inscatId);
            }

            if(!checkbox.checked && $scope.org.selectedInscatIds.indexOf(inscatId) !== -1) {
                $scope.org.selectedInscatIds.splice($scope.org.selectedInscatIds.indexOf(inscatId), 1);
            }
        };


        /**
         * update selected liccls
         * @param $event
         * @param licclsId
         */
        $scope.updateLiccls = function($event, licclsId) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.org.selectedLicclsIds.indexOf(licclsId) === -1) {
                $scope.org.selectedLicclsIds.push(licclsId);
            }

            if(!checkbox.checked && $scope.org.selectedLicclsIds.indexOf(licclsId) !== -1) {
                $scope.org.selectedLicclsIds.splice($scope.org.selectedLicclsIds.indexOf(licclsId), 1);
            }
        };

        $scope.isSelectedInscat = function(inscatId){
            return $scope.org.selectedInscatIds.indexOf(inscatId) >= 0;
        };

        $scope.isSelectedLiccls = function(licclsId){
            return $scope.org.selectedLicclsIds.indexOf(licclsId) >= 0;
        };

        $scope.isEmptyInscat = function(){
            return _.isEmpty($scope.org.selectedInscatIds);
        };

        /**
         * update selected lob
         * @param $event
         * @param lobId
         */
        $scope.updateLOBs = function($event, lobId){
            var checkbox = $event.target;
            const lobObj = _.find($scope.org.selectedLOBs, {id: lobId});
            if(checkbox.checked && !lobObj) {
                $scope.org.selectedLOBs.push(_.find($scope.lobs, {id: lobId}));
                if(lobId === "EOCARRIER" && !_.find($scope.org.selectedLOBs, {id: "INSCO"}))
                    $scope.org.selectedLOBs.push(_.find($scope.lobs, {id: "INSCO"}));
            }

            if(!checkbox.checked && lobObj) {
                $scope.org.selectedLOBs = _.reject($scope.org.selectedLOBs, {id: lobId})
            }
        };

        $scope.isSelectedLOB = function(lobId){
            return _.find($scope.org.selectedLOBs, {id: lobId}) != undefined;
        };

        $scope.isEmptyLOB = function(){
            return _.isEmpty($scope.org.selectedLOBs)
        };

    }
])
.controller('OrganizationsAgencyInitCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', '$location', 'store', 'blockUI', 'OrganizationsService', 'PartyService',
    function($scope, $state, $stateParams, $http, $filter, $location, store, blockUI, OrganizationsService, PartyService) {
        let applicationAgencyCreateObj = store.get("application_agency_create");
        $scope.legalTradeExists = false;
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.activeDate = moment().format('MMMM DD, YYYY');
        $scope.activeDateBegin = moment("1970-01-01").format('MMMM DD, YYYY');
        $scope.activeDateEnd = moment().format('MMMM DD, YYYY');
        $scope.warningDate = moment().subtract(6, 'month');
        $scope.isRestricted = applicationAgencyCreateObj ? applicationAgencyCreateObj.isRestricted : false;
        $scope.isExternal = applicationAgencyCreateObj ? applicationAgencyCreateObj.isExternal : false;
        $scope.isSoleProprietorship = applicationAgencyCreateObj ? applicationAgencyCreateObj.isSoleProprietorship : false;
        var organizationAgencyInitBlock = blockUI.instances.get('organization-agency-init-block');

        $scope.statuses = [{id: "ACTIVE", "description": "Active"},
            {"id": "PENDING", "description": "Pending"},
            {"id": "INACTIVE", "description": "Inactive"}];
        $scope.agencyTypes = [{id: "CORPORATION", "description": "Corporation"},
            {"id": "PARTNERSHIP", "description": "Partnership"},
            {"id": "SOLE_PROP", "description": "Sole Proprietorship"}];


        $scope.lobs = [
            {
                "id": "AGENCY",
                "description": 'Agency',
                "status": _.find($scope.statuses, {id: "PENDING"}),
                "activeDate": moment().format('MMMM DD, YYYY')
            },
            {
                "id": "RES_INS_AGENCY",
                "description": "Restricted Ins. Agency",
                "status": _.find($scope.statuses, {id: "PENDING"}),
                "activeDate": moment().format('MMMM DD, YYYY')
            }
        ];

        var initForm = function() {
            $scope.legalTradeExists = false;
            $scope.activeOldDate = false;
            $scope.org = {
                legalName: "",
                tradeName: "",
                primaryemail: "",
                regType: undefined,
                corpRegNo: "",
                selectedLOBs: [],
                selectedRIATypeIds: [],
                admin: "",
                notes: "",
                additionalInfo: "",
                partners: "",
                orgType: $scope.isSoleProprietorship  ? "S" : "A"
            };
        };

        initForm();


        if($scope.isRestricted) {
            $scope.org.selectedLOBs.push(_.find($scope.lobs, {id: "RES_INS_AGENCY"}));
        } else
            $scope.org.selectedLOBs.push(_.find($scope.lobs, {id: "AGENCY"}));

        if($scope.isSoleProprietorship) $scope.org.regType = _.find($scope.agencyTypes, {id: "SOLE_PROP"});


        OrganizationsService.getRIATypes($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.RIATypes = res.data;
        });

        OrganizationsService.getLicClassBusTypeMapping($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.licClassBusTypeMapping = res.data;
            if($scope.isRestricted) {
                _.map($scope.licClassBusTypeMapping, function(licMap) {
                    if(licMap.licClassId.trim() === applicationAgencyCreateObj.licClass.trim())
                        $scope.org.selectedRIATypeIds.push(licMap.RIATypeID);
                });
            }
        });

        $scope.restrictedCheck = function(){
            return $scope.isRestricted && _.isEmpty($scope.org.selectedRIATypeIds);
        };

        $scope.createAgency = function(){
            organizationAgencyInitBlock.start();

            OrganizationsService.validateAgency($scope.roleContext.ciprid, $scope.org)
            .then(function(res) {
                var validationErrors = res.data;
                if(!_.isEmpty(validationErrors)) {
                    organizationAgencyInitBlock.stop();
                    $scope.agencyCreationErrors = validationErrors;
                    return;
                }

                var onsuccess = function(res){
                    organizationAgencyInitBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', 'Agency cannot be created at this time.');
                    }
                    if(res.data.org[0]) {
                        toastr.success('Success!', 'Agency successfully created.');
                        if($scope.isExternal) {
                            applicationAgencyCreateObj.agency = res.data.org[0];
                            store.set('application_agency_create', applicationAgencyCreateObj);
                            $state.go('application.init');
                        } else {
                            applicationAgencyCreateObj.agency = res.data.org[0];
                            store.set('application_agency_create', applicationAgencyCreateObj);
                            $state.go('appl.init');
                        }

                    } else
                        toastr.error('Error!', 'Agency cannot be created at this time.');
                };
                var onerror = function(){
                    organizationAgencyInitBlock.stop();
                    toastr.error('Error!', 'Agency cannot be created at this time.');
                };


                OrganizationsService.agencyCreate($scope.roleContext.ciprid, $scope.org)
                .then(onsuccess, onerror);
            });

        };

        $scope.clear = function() {
            initForm();
        };

        $scope.isOldDate = function(lob){
            return $scope.warningDate.isAfter($filter('date')(lob.activeDate, "MM dd, yyyy"));
        };
        // $scope.setActiveDate = function(selectedDate) {
        //     $scope.activeDate = $filter('date')(selectedDate, "MM dd, yyyy");
        //     $scope.activeOldDate = $scope.warningDate.isAfter($scope.activeDate);
        // };


        /**
         * Used in typeahead for parties
         * @param phrase
         * @returns {*}
         */
        $scope.getParties = function(phrase, limit) {
            return PartyService.getParties(phrase, limit);
        };

        $scope.checkName = function(org){
            $scope.legalTradeExists = false;
            OrganizationsService.getOrgByLegalAndTradeName($scope.roleContext.ciprid, org.legalName, org.tradeName)
            .then(function(res) {
                $scope.legalTradeExists = !_.isEmpty(res.data);
            });

        };

        /**
         * update selected inscats
         * @param $event
         * @param inscatId
         */
        $scope.updateRIATypes = function($event, typeId) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.org.selectedRIATypeIds.indexOf(typeId) === -1) {
                $scope.org.selectedRIATypeIds.push(typeId);
            }

            if(!checkbox.checked && $scope.org.selectedRIATypeIds.indexOf(typeId) !== -1) {
                $scope.org.selectedRIATypeIds.splice($scope.org.selectedRIATypeIds.indexOf(typeId), 1);
            }
        };


        $scope.isSelectedRIAType = function(typeId){
            return $scope.org.selectedRIATypeIds.indexOf(typeId) >= 0;
        };

        $scope.isEmptyRIAType = function(){
            return _.isEmpty($scope.org.selectedRIATypeIds);
        };

        /**
         * update selected lob
         * @param $event
         * @param lobId
         */
        $scope.updateLOBs = function($event, lobId){
            var checkbox = $event.target;
            const lobObj = _.find($scope.org.selectedLOBs, {id: lobId});
            if(checkbox.checked && !lobObj) {
                $scope.org.selectedLOBs.push(_.find($scope.lobs, {id: lobId}));
            }

            if(!checkbox.checked && lobObj) {
                $scope.org.selectedLOBs = _.reject($scope.org.selectedLOBs, {id: lobId})
            }
        };

        $scope.isSelectedLOB = function(lobId){
            return _.find($scope.org.selectedLOBs, {id: lobId}) != undefined;
        };

        $scope.isEmptyLOB = function(){
            return _.isEmpty($scope.org.selectedLOBs)
        };

    }
])
.controller('OrganizationsSpecificCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'promisedOrg', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, promisedOrg, dialogs, blockUI, toastr) {
        $scope.org = promisedOrg;
        console.log($scope.org);
    }
])

/**
 * Profile Tab
 */
.controller('OrganizationsSpecificProfileCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificProfileViewCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificProfileEditCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr', 'OrganizationsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr, OrganizationsService) {
        $scope.profile = {
            legalName: $scope.org.profile.legalName,
            tradeNames: $scope.org.profile.tradeNames || []
        };

        $scope.addTradeName = function() {
            $scope.profile.tradeNames.push({
                value: ''
            });
        };

        $scope.removeTradeName = function(index) {
            $scope.profile.tradeNames.splice(index, 1);
        };

        $scope.saveProfile = function() {
            var profile = $scope.profile;

            OrganizationsService.saveProfile($scope.roleContext.ciprid, $scope.org.orgId, profile)
            .then(function() {
                $state.go('organizations.specific.profile.view', {organizationId: $scope.org.orgId}, {reload: true});
            });
        };
    }
])

/**
 * Line of Business tab
 */
.controller('OrganizationsSpecificLobCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificLobListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificLobInsurerCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificLobAgencyCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificLobCECtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificLobEOCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])

/**
 * Users Tab
 */
.controller('OrganizationsSpecificUsersCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificUsersListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'OrganizationsService', 'SelectionService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, OrganizationsService, SelectionService) {
        $scope.currentPage = 1;
        $scope.pageSize = 10;
        $scope.searchStr = '';

        $scope.users = [];
        $scope.totalItems = 0;

        $scope.setPage = function(pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function() {
            $scope.search();
        };

        $scope.search = function() {
            OrganizationsService.browseUsers($scope.roleContext.ciprid, $scope.org.orgId, $scope.searchStr, $scope.currentPage, $scope.pageSize)
            .then(function(res) {
                $scope.users = res.data.slice;
                $scope.totalItems = res.data.totalSize;
            });
        };

        $scope.viewProfile = function(user) {
            SelectionService.addUser(user);
            $state.go('organizations.specific.users.view', {
                organizationId: $scope.org.orgId,
                ciprId: user.profile.ciprid
            });
        };


        $scope.$on('$viewContentLoaded', function() {
            $scope.search();
        });
    }
])
.controller('OrganizationsSpecificUsersNewCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {

    }
])
.controller('OrganizationsSpecificUsersPasswordCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {

    }
])
.controller('OrganizationsSpecificUsersDeclarationsCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {

    }
])
.controller('OrganizationsSpecificUsersViewCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr', 'SelectionService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr, SelectionService) {
        $scope.user = SelectionService.getUser();
        $scope.vm = {};

        var newId = 1;
        $scope.vm.ignoreChanges = false;
        $scope.vm.newNode = {};
        $scope.vm.originalData = [
            {id: 'org', parent: '#', text: 'Organization', state: {opened: true}},
            {id: 'contact', parent: 'org', text: 'Contact Maintenance', state: {opened: true}},
            {id: 'delegation', parent: 'org', text: 'Delegation Maintenance', state: {opened: true}},
            {id: 'invoice', parent: 'org', text: 'Pay Invoice', state: {opened: true}},
            {id: 'insurer', parent: '#', text: 'Insurance Company', state: {opened: true}},
            {id: 'life', parent: 'insurer', text: 'Life', state: {opened: true}},
            {id: 'recommend_life', parent: 'life', text: 'Recommend Application', state: {opened: true}},
            {id: 'review_life', parent: 'life', text: 'Review Application', state: {opened: true}},
            {id: 'initiate_life', parent: 'life', text: 'Initiate Application', state: {opened: true}},
            {id: 'terminate_life', parent: 'life', text: 'Terminate Application', state: {opened: true}},
            {id: 'as', parent: 'insurer', text: 'A & S', state: {opened: true}},
            {id: 'recommend_as', parent: 'as', text: 'Recommend Application', state: {opened: true}},
            {id: 'review_as', parent: 'as', text: 'Review Application', state: {opened: true}},
            {id: 'initiate_as', parent: 'as', text: 'Initiate Application', state: {opened: true}},
            {id: 'terminate_as', parent: 'as', text: 'Terminate Application', state: {opened: true}}
        ];

        $scope.vm.treeData = [];

        angular.copy($scope.vm.originalData, $scope.vm.treeData);
        $scope.vm.treeConfig = {
            core: {
                multiple: true,
                animation: true,
                error: function(error) {
                    $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
                },
                check_callback: true,
                worker: true
            },
            types: {
                default: {
                    icon: 'fa fa-key'
                },
                star: {
                    icon: 'glyphicon glyphicon-star'
                },
                cloud: {
                    icon: 'glyphicon glyphicon-cloud'
                }
            },
            version: 1,
            plugins: ['types', 'checkbox']
        };

        $scope.vm.reCreateTree = function() {
            $scope.vm.ignoreChanges = true;
            angular.copy(this.originalData, this.treeData);
            $scope.vm.treeConfig.version++;
        };

        $scope.vm.simulateAsyncData = function() {
            $scope.vm.promise = $timeout(function() {
                $scope.vm.treeData.push({
                    id: (newId++).toString(),
                    parent: $scope.vm.treeData[0].id,
                    text: 'Async Loaded'
                })
            }, 3000);
        };

        $scope.vm.addNewNode = function() {
            $scope.vm.treeData.push({
                id: (newId++).toString(),
                parent: $scope.vm.newNode.parent,
                text: $scope.vm.newNode.text
            });
        };

        this.setNodeType = function() {
            var item = _.findWhere(this.treeData, {id: this.selectedNode});
            item.type = this.newType;
        };

        this.readyCB = function() {
            $timeout(function() {
                $scope.vm.ignoreChanges = false;
            });
        };

        this.createCB = function(e, item) {
            $timeout(function() {
            });
        };

        this.applyModelChanges = function() {
            return !$scope.vm.ignoreChanges;
        };
    }
])
/**
 * Contacts Tab
 */
.controller('OrganizationsSpecificCommunicationChannelsCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificCommunicationChannelsListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'DTOptionsBuilder', 'OrganizationsService', 'AddressService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, DTOptionsBuilder, OrganizationsService, AddressService) {
        var self = this;
        self.org = $scope.org;
        var block = blockUI.instances.get('communication-channel-block');
        $scope.communicationData = [];
        $scope.communicationChannels = [];
        $scope.allCommunicationPurposes = [];
        $scope.emailCommunicationChannels = [];
        $scope.emailCommunicationChannelIds = [];
        $scope.selectedPurpose = undefined;
        $scope.lobs = [];
        if($scope.org.partyInfo.insco_lob_status) $scope.lobs.push('INSCO');
        if($scope.org.partyInfo.eoc_lob_status) $scope.lobs.push('EOCARRIER');
        // for new form purposes
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('iDisplayLength', 50);


        $scope.initForm = function(){
            $scope.form = {
                type: '',
                address: {province: "Alberta", country: "Canada"},
                email: '',
                phone: {},
                url: '',
                fax: {}
            };
            $scope.selectedPurposeTypeIds = [];
        };

        $scope.initForm();
        block.start();
        OrganizationsService.getCommunicationChannels($scope.roleContext.ciprid, self.org.orgId)
        .then(function(res) {
            block.stop();
            $scope.communicationData = res.data;
            console.log($scope.communicationData);
            $scope.reinitiateData();
        });

        $scope.reinitiateData = function() {
            $scope.allCommunicationPurposes = _.pickBy($scope.communicationData.allCommunicationPurposes, function(p){return _.includes($scope.lobs, p.lob)});
            var groupedPurposes = _.groupBy($scope.communicationData.purposes, "channelId");
            $scope.communicationChannels = _.map($scope.communicationData.channels, function(channel) {
                channel.purpose = groupedPurposes[channel.id] || [];
                return channel;
            });
            $scope.emailCommunicationChannels = _.filter($scope.communicationData.channels, {"communicationType": "CM_EMAIL"});
            $scope.emailCommunicationChannelIds = _.map($scope.emailCommunicationChannels, 'id');
        };

        $scope.hasAddress = function(cc){
            return cc.street || cc.city || cc.postalCode || cc.country;
        };

        $scope.hasPhone = function(cc){
            return cc.number;
        };

        $scope.hasFax = function(cc){
            return cc.number;
        };

        $scope.hasEmail = function(cc){
            return cc.eAddr;
        };

        $scope.hasURL = function(cc){
            return cc.eAddr;
        };

        $scope.isSelectedPurpose = function(communicationChannel, purpose){
            let communicatChannelPurposes = _.find($scope.communicationChannels, function(cc){
                return cc.id == communicationChannel.id
            }).purpose;
            return _.find(communicatChannelPurposes, function(ccp){
                return ccp.purposeTypeId == purpose.purposeTypeId
            });
        };

        /*
            for disabling checkbox for email type if only 1 is left
         */
        $scope.isSingleInstance = function(purpose){

            let emailCommunicationPurposes = _.pickBy($scope.communicationData.purposes, function(p){ return _.includes($scope.emailCommunicationChannelIds, p.channelId)});
            return _.size(_.filter(emailCommunicationPurposes, function(ecp){
                return ecp.purposeTypeId == purpose.purposeTypeId
            })) == 1;
        };

        /*
            Can remove communication channel
         */
        $scope.canRemoveChannel = function(communicationChannel){
            let canRemove = true;
            let emailCommunicationPurposes = _.pickBy($scope.communicationData.purposes, function(p){ return _.includes($scope.emailCommunicationChannelIds, p.channelId)});
            let communicationPurposes = _.find($scope.communicationChannels, function(cc) {
                return cc.id == communicationChannel.id
            }).purpose;
            _.forEach(communicationPurposes, function(purpose) {
                if(_.size(_.filter(emailCommunicationPurposes, function(cd){
                    return cd.purposeTypeId == purpose.purposeTypeId
                })) == 1)
                    canRemove = false;
            });
            return canRemove;
        };

        /*
            Remove communication channel
         */
        $scope.removeChannel = function(communicationChannel){
            block.start();
            OrganizationsService.removeCommunicationChannel($scope.roleContext.ciprid, self.org.orgId, communicationChannel.id)
            .then(function(res) {
                block.stop();
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', res.data.error);
                }
                ;
                toastr.success('Success!', "Communication Channel" + communicationChannel.communicationTypeDescription + " has been removed.");
                _.remove($scope.communicationData.channels, function(c){
                    return c.id == communicationChannel.id
                });
                _.remove($scope.communicationData.purposes, function(c){
                    return c.channelId == communicationChannel.id
                });
                $scope.reinitiateData();
            });
        };

        /*
            for updating list based on button of selected purpose clicked
         */
        $scope.checkSelectedPurpose = function(communicationChannel, selectedPurpose){
            if(!selectedPurpose) return false;
            return !_.find(communicationChannel.purpose, function(p) {
                return p.purposeTypeId == selectedPurpose
            });
        };

        $scope.updatePurpose = function($event, communicationChannel, purpose){
            var checkbox = $event.target;

            if(checkbox.checked) {
                OrganizationsService.addPurpose($scope.roleContext.ciprid, self.org.orgId, communicationChannel.id, purpose.purposeTypeId)
                .then(function(res){
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', "Error during adding communication channel purpose");
                    }
                    ;
                    toastr.success('Success!', "Communication Channel Purpose " + purpose.descr + " has been added.");
                    $scope.communicationData.purposes.push(res.data.results[0]);
                    $scope.reinitiateData();
                });
                return;
            }

            let communicatChannelPurposes = _.find($scope.communicationChannels, function(cc){
                return cc.id == communicationChannel.id
            }).purpose;
            let selectedPurpose = _.find(communicatChannelPurposes, function(ccp){
                return ccp.purposeTypeId == purpose.purposeTypeId
            });
            if(!checkbox.checked && selectedPurpose) {
                OrganizationsService.removePurpose($scope.roleContext.ciprid, self.org.orgId, communicationChannel.id, selectedPurpose.commPurposeId)
                .then(function(res){
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', "Error during removing communication channel purpose");
                    }
                    ;
                    toastr.success('Success!', "Communication Channel Purpose " + selectedPurpose.purposeDescr + " has been removed.");
                    $scope.communicationData.purposes = _.reject($scope.communicationData.purposes, function(c){
                        return c.commPurposeId == selectedPurpose.commPurposeId
                    });
                    $scope.reinitiateData();
                });
            }


        };


        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param purposeTypeId
         */
        $scope.updateSelection = function($event, purposeTypeId) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedPurposeTypeIds.indexOf(purposeTypeId) === -1) {
                $scope.selectedPurposeTypeIds.push(purposeTypeId);
            }

            if(!checkbox.checked && $scope.selectedPurposeTypeIds.indexOf(purposeTypeId) !== -1) {
                $scope.selectedPurposeTypeIds.splice($scope.selectedPurposeTypeIds.indexOf(purposeTypeId), 1);
            }
        };


        $scope.isSelected = function(purposeTypeId) {
            return $scope.selectedPurposeTypeIds.indexOf(purposeTypeId) >= 0;
        };


        $scope.reset = function(){
            $scope.addressForm.$setPristine(true);
            $scope.selectedPurposeTypeIds = [];
        };

        /*
            Save communication channel
         */
        $scope.saveCommunicationChannel = function(){
            block.start();
            var communicationChannel = {
                type: $scope.form.type,
                purposeTypeIds: $scope.selectedPurposeTypeIds
            };
            if($scope.form.type === 'address') communicationChannel.address = $scope.form.address;
            if($scope.form.type === 'email') communicationChannel.email = $scope.form.email;
            if($scope.form.type === 'phone') {
                communicationChannel.areaCode = $scope.form.phone.areaCode;
                communicationChannel.number = $scope.form.phone.number;
            }
            if($scope.form.type === 'fax') {
                communicationChannel.areaCode = $scope.form.fax.areaCode;
                communicationChannel.number = $scope.form.fax.number;
            }
            if($scope.form.type === 'url') communicationChannel.url = $scope.form.url;

            OrganizationsService.saveCommunicationChannel($scope.roleContext.ciprid, $scope.org.orgId, communicationChannel)
            .then(function(res) {
                if(!_.isEmpty(res.data.error)) {
                    block.stop();
                    return toastr.error('Error!', res.data.error);
                }
                toastr.success('Success!', "Communication Channel " + $scope.form.type + " has been successfully added.");
                $scope.initForm();
                OrganizationsService.getCommunicationChannels($scope.roleContext.ciprid, self.org.orgId)
                .then(function(res) {
                    block.stop();
                    $scope.communicationData = res.data;
                    console.log($scope.communicationData);
                    $scope.reinitiateData();
                });

            });
        };

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };

        $scope.setCity = function(item, model, label) {
            $scope.form.address.city = model.cityname;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.form.address.province = model.provname;
        };

        $scope.setCountry = function(item, model, label) {
            $scope.form.address.country = model.countryname;
        };


    }
])

/**
 * Contacts Tab
 */
.controller('OrganizationsSpecificContactsCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificContactsListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'OrganizationsService', 'SelectionService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, OrganizationsService, SelectionService) {
        $scope.contacts = $scope.org.contacts;
        var block = blockUI.instances.get('block');

        $scope.hasAddress = function(address) {
            return address.street || address.city || address.postalCode || address.country;
        };

        $scope.hasPhone = function(address) {
            return address.phone;
        };

        $scope.hasFax = function(address) {
            return address.fax;
        };

        $scope.hasEmail = function(address) {
            return address.email;
        };

        $scope.purposeIsLicensing = function(tags) {
            return tags.indexOf("Licensing") >= 0;
        };

        $scope.purposeIsBilling = function(tags) {
            return tags.indexOf("Billing") >= 0;
        };

        $scope.purposeIsAssessment = function(tags) {
            return tags.indexOf("Assessment") >= 0;
        };

        $scope.purposeIsComplaints = function(tags) {
            return tags.indexOf("Complaints") >= 0;
        };

        $scope.purposeIsLicensing = function(tags) {
            return tags.indexOf("Licensing") >= 0;
        };

        $scope.purposeIsNotifications = function(tags) {
            return tags.indexOf("Notifications") >= 0;
        };

        $scope.deleteContact = function(contact) {
            OrganizationsService.deleteContact($scope.roleContext.ciprid, $scope.org.orgId, contact.id)
            .then(function() {
                $state.go('organizations.specific.contacts.list', {organizationId: $scope.org.orgId}, {reload: true});
            });
        };

        $scope.editContact = function(contact) {
            SelectionService.addContact(contact);
            $state.go('organizations.specific.contacts.edit', {organizationId: $scope.org.orgId});
        };
    }
])
.controller('OrganizationsSpecificContactsNewCtrl', ['$scope', '$state', '$stateParams', '$http', '$q', '$timeout', '$previousState', 'blockUI', 'toastr', 'AddressService', 'OrganizationsService',
    function($scope, $state, $stateParams, $http, $q, $timeout, $previousState, blockUI, toastr, AddressService, OrganizationsService) {
        $scope.purposes = [
            {text: 'Licensing'},
            {text: 'Assessment'},
            {text: 'Billing'},
            {text: 'Complaints'},
            {text: 'Notifications'}
        ];
        $scope.form = {
            type: '',
            address: {},
            email: '',
            phone: '',
            purpose: []
        };

        var block = blockUI.instances.get('block');

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };

        $scope.setCity = function(item, model, label) {
            $scope.form.address.city = model.cityname;
            $scope.form.address.cityid = model.cityid;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.form.address.province = model.provname;
            $scope.form.address.provinceid = model.provid;
            $scope.form.address.provabbrev = model.provabbreviation;
        };

        $scope.setCountry = function(item, model, label) {
            $scope.form.address.country = model.countryname;
            $scope.form.address.countryid = model.countryid;
        };

        $scope.cancel = function() {

        };

        $scope.checkCity = function() {
            if(!$scope.form.address.cityid) {
                $scope.form.address.city = "";
            }
        };
        $scope.checkProvince = function() {
            if(!$scope.form.address.provinceid) {
                $scope.form.address.province = "";
            }
        };
        $scope.checkCountry = function() {
            if(!$scope.form.address.countryid) {
                $scope.form.address.country = "";
            }
        };

        $scope.$watch('noResultsCity', function(noNewCity) {
            if(noNewCity) {
                $scope.form.address.cityid = "";
                return;
            }
        });
        $scope.$watch('noResultsCountry', function(noNewCountry) {
            if(noNewCountry) {
                $scope.form.address.countryid = "";
                return;
            }
        });
        $scope.$watch('noResultsProvince', function(noNewProvince) {
            if(noNewProvince) {
                $scope.form.address.provinceid = "";
                return;
            }
        });

        $scope.getPurposes = function($query) {
            var deferred = $q.defer();
            deferred.resolve($scope.tags);
            return deferred.promise;
        };

        $scope.saveContact = function() {
            var contact = {
                type: $scope.form.type,
                purpose: _.map($scope.form.purpose, "text")
            };
            if($scope.form.type === 'address') contact.address = $scope.form.address;
            if($scope.form.type === 'email') contact.email = $scope.form.email;
            if($scope.form.type === 'phone') contact.phone = $scope.form.phone;

            OrganizationsService.saveContact($scope.roleContext.ciprid, $scope.org.orgId, contact)
            .then(function() {
                block.stop();
                $state.go('organizations.specific.contacts.list', {organizationId: $scope.org.orgId}, {reload: true});
            });
        };

    }
])
.controller('OrganizationsSpecificContactsEditCtrl', ['$scope', '$state', '$stateParams', '$http', '$q', '$timeout', '$previousState', 'blockUI', 'toastr', 'AddressService', 'OrganizationsService', 'SelectionService',
    function($scope, $state, $stateParams, $http, $q, $timeout, $previousState, blockUI, toastr, AddressService, OrganizationsService, SelectionService) {
        $scope.purposes = [
            {text: 'Licensing'},
            {text: 'Assessment'},
            {text: 'Billing'},
            {text: 'Complaints'},
            {text: 'Notifications'}
        ];

        $scope.form = {
            type: '',
            address: {},
            email: '',
            phone: '',
            purpose: []
        };

        (function() {
            var contact = SelectionService.getContact();
            $scope.form.id = contact.id;
            $scope.form.type = contact.type;
            $scope.form.purpose = _.map(contact.purpose, function(value) {
                return {text: value};
            });

            if(contact.type === "address") $scope.form.address = contact;
            else if(contact.type === "email") $scope.form.email = contact;
            else if(contact.type === "phone") $scope.form.phone = contact;

        })();

        var block = blockUI.instances.get('block');

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };

        $scope.setCity = function(item, model, label) {
            $scope.form.address.city = model.cityname;
            $scope.form.address.cityid = model.cityid;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.form.address.province = model.provname;
            $scope.form.address.provinceid = model.provid;
            $scope.form.address.provabbrev = model.provabbreviation;
        };

        $scope.setCountry = function(item, model, label) {
            $scope.form.address.country = model.countryname;
            $scope.form.address.countryid = model.countryid;
        };

        $scope.cancel = function() {

        };

        $scope.checkCity = function() {
            if(!$scope.form.address.cityid) {
                $scope.form.address.city = "";
            }
        };
        $scope.checkProvince = function() {
            if(!$scope.form.address.provinceid) {
                $scope.form.address.province = "";
            }
        };
        $scope.checkCountry = function() {
            if(!$scope.form.address.countryid) {
                $scope.form.address.country = "";
            }
        };

        $scope.$watch('noResultsCity', function(noNewCity) {
            if(noNewCity) {
                $scope.form.address.cityid = "";
                return;
            }
        });
        $scope.$watch('noResultsCountry', function(noNewCountry) {
            if(noNewCountry) {
                $scope.form.address.countryid = "";
                return;
            }
        });
        $scope.$watch('noResultsProvince', function(noNewProvince) {
            if(noNewProvince) {
                $scope.form.address.provinceid = "";
                return;
            }
        });

        $scope.getPurposes = function($query) {
            var deferred = $q.defer();
            deferred.resolve($scope.tags);
            return deferred.promise;
        };

        $scope.updateContact = function() {
            OrganizationsService.updateContact($scope.roleContext.ciprid, $scope.org.orgId, $scope.form)
            .then(function() {
                block.stop();
                $state.go('organizations.specific.contacts.list', {organizationId: $scope.org.orgId}, {reload: true});
            });
        };
    }
])

/**
 * Delegations Tab
 */
.controller('OrganizationsProfileDelegationsTabCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])

/**
 * Invoices Tab
 */

.controller('OrganizationsSpecificInvoicesCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificInvoicesListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrganizationsService', 'SelectionService', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, DTOptionsBuilder, dialogs, blockUI, OrganizationsService, SelectionService, OrgsService) {
        var self = this;

        self.org = $scope.org;
        $scope.invoices = [];
        $scope.loading = true;

        /**
         * Load invoices
         */
        OrgsService.getInvoices(self.org.orgId)
        .then(function(res) {
            $scope.invoices = res.data;
            $scope.loading = false;
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc']);

        $scope.isPaid = function(invoice) {
            return parseFloat(invoice.balance) == 0;
        };

        $scope.viewInvoice = function(invoice) {
            $state.go('payments.view', {invoiceId: invoice.invoiceid});
        };
    }
])

/**
 * Payables Tab
 */

.controller('OrganizationsSpecificPayablesCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificPayablesListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrganizationsService', 'SelectionService', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, DTOptionsBuilder, dialogs, blockUI, OrganizationsService, SelectionService, OrgsService) {
        /**
         * Initializers
         */
        self.org = $scope.org;
        $scope.payables = [];
        $scope.loading = true;

        OrgsService.getPayables(self.org.orgId)
        .then(function(res) {
            $scope.payables = res.data;
            $scope.loading = false;
        });

        $scope.isPaid = function(item) {
            return parseFloat(item.balance) == 0;
        };

        $scope.isLicenseApplication = function(item) {
            return item.itemtypecd == "LIC_APP";
        };

        $scope.isLicenseRenewal = function(item) {
            return item.itemtypecd == "LIC_REN";
        };

        $scope.isDRLicense = function(item) {
            return item.licenseid && item.stklic.drlicense == "Y";
        };

        $scope.isCorpLicense = function(item) {
            return item.licenseid && item.stklic.licensetype == "C";
        };

        $scope.isExamApplication = function(item) {
            return item.itemtypecd == "EXAM_APP";
        };
    }
])


/**
 * E & O Tab
 */

.controller('OrganizationsSpecificEAndOCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificEAndOListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, DTOptionsBuilder, dialogs, blockUI, OrgsService) {
        var self = this;

        self.org = $scope.org;
        $scope.eandos = [];
        $scope.loading = true;
        /**
         * Load eandos
         */
        OrgsService.getEandOs(self.org.orgId)
        .then(function(res) {
            $scope.eandos = res.data;
            $scope.loading = false;
        });
        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };


        $scope.viewEandOCoverage = function(eando) {
            $state.go('orgs.eandoview', {
                policyno: eando.policyno,
                inscategory: eando.insurancecategory,
                carrierpartyid: eando.carrierpartyid,
                carrier: eando.carriername,
                insurancecategorydescr: eando.insurancecategorydescr,
                orgid: self.org.orgId
            });
        };

    }
])

/**
 * Licenses Tab
 */

.controller('OrganizationsSpecificLicensesCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificLicensesIssuedCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, DTOptionsBuilder, dialogs, blockUI, OrgsService) {
        var self = this;

        self.org = $scope.org;
        $scope.licenses = [];
        $scope.selectedLicenseIds = [];
        $scope.loading = true;

        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        /**
         * Load licenses
         */
        OrgsService.getLicenses(self.org.orgId)
        .then(function(res) {
            $scope.licenses = res.data;
            $scope.loading = false;
        });


        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        $scope.viewLicense = function(license) {
            $state.go('licenses.view', {licenseType: license.licensetype, licenseId: license.licenseid});
        };

        $scope.showEditAddress = function() {
            return self.org.org_type === 'Agency';
        };

        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            $state.go('licenses.eando', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

    }
])
.controller('OrganizationsSpecificLicensesApplicationsCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrgsService', 'ApplicationService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, DTOptionsBuilder, dialogs, blockUI, OrgsService, ApplicationService) {
        var self = this;

        self.org = $scope.org;
        $scope.licenses = [];
        $scope.selectedLicenseIds = [];
        $scope.loading = true;

        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        /**
         * Load licenses
         */
        OrgsService.getLicenses(self.org.orgId)
        .then(function(res) {
            $scope.licenses = res.data;
            $scope.loading = false;
        });


        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        $scope.viewLicense = function(license) {
            $state.go('licenses.view', {licenseType: license.licensetype, licenseId: license.licenseid});
        };

        $scope.showEditAddress = function() {
            return self.org.org_type === 'Agency';
        };

        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            $state.go('licenses.eando', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

    }
])
.controller('OrganizationsSpecificLicensesRenewalsCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, DTOptionsBuilder, dialogs, blockUI, OrgsService) {
        var self = this;

        self.org = $scope.org;
        $scope.selectedLicenseIds = [];
        $scope.renewals = [];
        $scope.loading = true;
        /**
         * Load renewals
         */
        OrgsService.getRenewals(self.org.orgId)
        .then(function(res) {
            $scope.renewals = res.data;
            $scope.loading = false;
        });

        $scope.viewRenewal = function(renewal) {
            $state.go('renewals.open.view', {renewalId: renewal.licenseid});
        };


        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.renewals, function(renewal) {
                    $scope.selectedLicenseIds.push(renewal.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /*
         Edit address only for Agency
         */
        $scope.showEditAddress = function() {
            return self.org.org_type === 'Agency';
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };


    }
])

/**
 * Notes Tab
 */

.controller('OrganizationsSpecificNotesCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificNotesListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrganizationsService', 'SelectionService', 'NotesService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, DTOptionsBuilder, dialogs, blockUI, OrganizationsService, SelectionService, NotesService) {
        var self = this;

        self.org = $scope.org;
        $scope.notes = [];
        $scope.noteTypes = [];
        $scope.loading = true;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [5, 'desc']);


        NotesService.getNotes(self.org.orgId)
        .then(function(res) {
            $scope.notes = res.data.notes;
            $scope.noteTypes = res.data.noteTypes;
            $scope.loading = false;
        });


        /**
         * Presents an edit dialog.
         * @param note
         */
        $scope.editNote = function(note) {
            var data = {note: note, noteTypes: $scope.noteTypes};
            dialogs.create('staff/notes/dialogs/addoredit.html', 'NoteAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(note) {

                var onsuccess = function(res) {
                    toastr.success('Success!', 'Note has been updated.');
                    $scope.notes = _.reject($scope.notes, {'id': note.id});
                    $scope.notes.push(note);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Note cannot be able to updated. Please try later');
                };

                NotesService.updateNote(self.org.orgId, note).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an add dialog.
         */
        $scope.addNote = function() {
            var data = {note: undefined, noteTypes: $scope.noteTypes};
            dialogs.create('staff/notes/dialogs/addoredit.html', 'NoteAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(note) {

                var onsuccess = function(res) {
                    var data = res.data;
                    toastr.success('Success!', 'Note has been created.');
                    note = data[0];
                    $scope.notes.push(note);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Unable to create note at this time. Please try later');
                };

                NotesService.createNote(self.org.orgId, note).then(onsuccess, onerror);
            });
        };

        $scope.removeNote = function(note) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Note has been removed.');
                $scope.notes = _.reject($scope.notes, {'id': note.id});
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Note cannot be removed at this time. Please try later');
            };

            NotesService.removeNote(self.org.orgId, note).then(onsuccess, onerror);
        };
    }
])

/**
 * Events Tab
 */

.controller('OrganizationsSpecificEventsCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificEventsListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', '$filter', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrganizationsService', 'EventService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, $filter, DTOptionsBuilder, dialogs, blockUI, OrganizationsService, EventService) {
        var self = this;

        self.org = $scope.org;
        $scope.events = [];
        $scope.loading = true;
        $scope.incCommEvent = 'N';
        $scope.incWebEvent = 'N';
        $scope.dateFormat = 'MM dd, yyyy';
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        $scope.fromDate = moment().subtract(30, 'days').format('MMMM DD, YYYY');
        $scope.toDate = moment(d).format('MMMM DD, YYYY');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc']);

        EventService.getOrgEvents(self.org.orgId, $scope.fromDate, $scope.toDate, $scope.incCommEvent, $scope.incWebEvent)
        .then(function(res) {
            $scope.events = res.data;
            $scope.loading = false;
        });

        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setFromDate = function(selectedDate) {
            $scope.fromDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        /**
         * Called when datepicker is dismissed
         * @param selectedDate
         */
        $scope.setToDate = function(selectedDate) {
            $scope.toDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        $scope.setCommEvent = function($event) {
            var checkbox = $event.target;
            $scope.incCommEvent = checkbox.checked ? 'Y' : 'N';
        };

        $scope.setWebEvent = function($event) {
            var checkbox = $event.target;
            $scope.incWebEvent = checkbox.checked ? 'Y' : 'N';
        };


        $scope.getEvents = function() {
            $scope.loading = true;
            EventService.getOrgEvents(self.org.orgId, $scope.fromDate ? $filter('date')($scope.fromDate, "yyyy-MM-dd") : null, $scope.toDate ? $filter('date')($scope.toDate, "yyyy-MM-dd") : null, $scope.incCommEvent, $scope.incWebEvent)
            .then(function(res) {
                $scope.events = res.data;
                $scope.loading = false;
            });
        }
    }
])

/**
 * Accounts Tab
 */

.controller('OrganizationsSpecificAccountsCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'dialogs', 'blockUI', 'toastr',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, dialogs, blockUI, toastr) {
    }
])
.controller('OrganizationsSpecificAccountsListCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', '$filter', 'DTOptionsBuilder', 'dialogs', 'blockUI', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, $filter, DTOptionsBuilder, dialogs, blockUI, OrgsService) {
        var self = this;

        self.org = $scope.org;
        OrgsService.getAllAccounts($scope.roleContext.ciprid, self.org.orgId)
        .then(function(results) {
            $scope.org.accounts = results;
        });
    }
])

/**
 * services
 */
.service('SelectionService', [
    function() {
        var selectedUser = {};
        var selectedContact = {};

        var addUser = function(user) {
            selectedUser = user;
        };

        var getUser = function() {
            return selectedUser;
        };

        var addContact = function(contact) {
            selectedContact = contact;
        };

        var getContact = function() {
            return selectedContact;
        };

        return {
            addUser: addUser,
            getUser: getUser,
            addContact: addContact,
            getContact: getContact
        };
    }
])
.service('OrganizationsService', ['$http',
    function($http) {
        this.init = function(ciprid, org) {
            return $http.post('/' + ciprid + '/organizations', {
                org: org
            });
        };

        this.validateAgency = function(ciprid, org) {
            return $http.post('/' + ciprid + '/organizations/validateAgency', {
                org: org
            });
        };

        this.getTypes = function(ciprid) {
            return $http.get('/' + ciprid + '/organizations/types/', {cache: true});
        };

        this.getInscats = function(ciprid) {
            return $http.get('/' + ciprid + '/organizations/inscats/', {cache: true});
        };

        this.getRIATypes = function(ciprid) {
            return $http.get('/' + ciprid + '/organizations/riatypes/', {cache: true});
        };

        this.getLicClassBusTypeMapping = function(ciprid) {
            return $http.get('/' + ciprid + '/organizations/licClassBusTypeMapping/', {cache: true});
        };

        this.getInsLiccls = function(ciprid) {
            return $http.get('/' + ciprid + '/organizations/insliccls/', {cache: true});
        };

        this.getOrgByLegalName = function(ciprid, legalName) {
            return $http.get('/' + ciprid + '/organizations/lookup/', {params: {legalName: legalName}});
        };

        this.getOrgByLegalAndTradeName = function(ciprid, legalName, tradeName) {
            return $http.get('/' + ciprid + '/organizations/lookupByLegalAndTradeName/', {
                params: {
                    "legalName": legalName,
                    "tradeName": tradeName
                }
            });
        };

        this.agencyCreate = function(ciprid, org) {
            return $http.post('/' + ciprid + '/organizations/agencycreate', {
                org: org
            });
        };

        this.getOrgBySOILicense = function(ciprid, licenseNum) {
            return $http.get('/' + ciprid + '/organizations/lookup/', {params: {licenseNum: licenseNum}});
        };

        this.getCommunicationChannels = function(ciprid, organizationId, licenseNum) {
            return $http.get("/" + ciprid + "/organizations/" + organizationId + "/communicationChannels/");
        };

        this.removeCommunicationChannel = function(ciprid, organizationId, channelId){
            return $http({
                url: "/" + ciprid + "/organizations/" + organizationId + "/communicationchannels",
                method: 'DELETE',
                data: {
                    channelId: channelId
                },
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };

        this.removePurpose = function(ciprid, organizationId, channelId, purposeId){
            return $http({
                url: "/" + ciprid + "/organizations/" + organizationId + "/communicationpurposes",
                method: 'DELETE',
                data: {
                    channelId: channelId,
                    purposeId: purposeId
                },
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };

        this.addPurpose = function(ciprid, organizationId, channelId, purposeType) {
            return $http.post('/' + ciprid + '/organizations/' + organizationId + '/communicationpurposes', {
                channelId: channelId,
                purposeType: purposeType
            });
        };

        this.saveCommunicationChannel = function(ciprid, organizationId, communicationChannel) {
            return $http.post('/' + ciprid + '/organizations/' + organizationId + '/communicationchannels', {
                communicationChannel: communicationChannel
            });
        };

        this.browseOrganizations = function(ciprid, searchStr, pageNumber, perPage) {
            return $http.get('/' + ciprid + '/organizations/search', {
                params: {
                    searchStr: searchStr,
                    pageNumber: pageNumber,
                    perPage: perPage
                }
            });
        };

        this.browseUsers = function(ciprid, organizationId, searchStr, pageNumber, perPage) {
            return $http.get('/' + ciprid + '/organizations/' + organizationId + '/users', {
                params: {
                    searchStr: searchStr,
                    pageNumber: pageNumber,
                    perPage: perPage
                }
            });
        };

        this.saveProfile = function(ciprid, organizationId, profile) {
            return $http.post('/' + ciprid + '/organizations/' + organizationId + '/profile', {
                profile: profile
            });
        };

        this.saveContact = function(ciprid, organizationId, contact) {
            return $http.post('/' + ciprid + '/organizations/' + organizationId + '/contacts', {
                contact: contact
            });
        };

        this.updateContact = function(ciprid, organizationId, contact) {
            return $http.put('/' + ciprid + '/organizations/' + organizationId + '/contacts', {
                contact: contact
            });
        };

        this.deleteContact = function(ciprid, organizationId, contactId) {
            return $http({
                url: '/' + ciprid + '/organizations/' + organizationId + '/contacts',
                method: 'DELETE',
                data: {
                    contactId: contactId
                },
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };
    }
])
.service('OrganizationsResolverService', ['$resource', '$http',
    function($resource, $http) {

        /**
         * Returns a promise which resolves a org object.
         *
         * @param ciprid
         * @param orgid
         * @returns {*}
         */
        this.get = function(ciprid, orgid) {
            return $http.get('/staff/orgs/' + orgid)
            .then(function(res) {

                var org = res.data.org;

                org.accounts = res.data.accounts;
                org.types = res.data.types;
                org.statuses = res.data.statuses;
                org.partyInfo = res.data.partyInfo[0];

                return org;
            });
        };
    }
]);
