var renewals = angular.module('aic.renewals', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router',
    'ui.bootstrap',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.invoices',
    'aic.wizard',
    'dialogs.main',
    'dialogs.default-translations',
    'pasvaz.bindonce'
]);

var insCatMap = {
    'A & S': 'A_S',
    'General': 'GENERAL',
    'Life': 'LIFE',
    'Adjuster': 'ADJ'
};

/**
 * Routing and config
 */
renewals.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $urlRouterProvider.when('/renewals/wizard', '/renewals/wizard/1');

        $stateProvider
        .state('renewals', {
            parent: 'workspace',
            abstract: true,
            url: '/renewals',
            templateUrl: 'renewals/renewals.html'
        })
        .state('renewals.list', {
            url: '/list',
            params: {
                cache: true
            },
            templateUrl: 'renewals/renewals.list.html',
            controller: 'RenewalListCtrl',
            resolve: {
                promisedInsCategories: ['$stateParams', 'AuthService', 'RenewalResolverService',
                    function($stateParams, AuthService, RenewalResolverService) {
                        var ctx = AuthService.getRoleContext();
                        var inscats = _.union(ctx.approve ? ctx.approve.inscat : [], ctx.create ? ctx.create.inscat : [], ctx.terminate ? ctx.terminate.inscat : []);
                        inscats = _.uniq(inscats, function(item, key) {
                            return item.code;
                        });
                        return inscats;
                    }
                ]
            }
        })
        .state('renewals.eando', {
            url: '/eando',
            params: {
                selectedRenewalIds: {array: true}
            },
            templateUrl: 'renewals/renewals.eando.html',
            controller: 'RenewalAddEandOCtrl'
        })
        .state('renewals.buy', {
            url: '/buy',
            params: {
                selectedRenewalIds: {array: true}
            },
            templateUrl: 'renewals/renewals.buy.html',
            controller: 'RenewalBuyCtrl'
        })
        .state('renewals.select', {
            url: '/select',
            params: {
                cache: true
            },
            templateUrl: 'renewals/wizard/select.html',
            controller: 'RenewalSelectionCtrl'
        })
        .state('renewals.wizard', {
            url: '/steps',
            params: {
                selectedRenewalIds: {array: true}
            },
            templateUrl: 'renewals/wizard/wizard.html',
            controller: 'RenewalWizardCtrl'
        })
        .state('renewals.wizard.payer', {
            url: '/payer',
            templateUrl: 'renewals/wizard/wizard.step.payer.html',
            controller: 'PayerCtrl'
        })
        .state('renewals.wizard.address', {
            url: '/address',
            templateUrl: 'renewals/wizard/wizard.step.address.html',
            controller: 'AddressCtrl'
        })
        .state('renewals.wizard.eando', {
            url: '/eando',
            templateUrl: 'renewals/wizard/wizard.step.eando.html',
            controller: 'EandOCtrl'
        })
        .state('renewals.wizard.questions', {
            url: '/questions',
            templateUrl: 'renewals/wizard/wizard.step.questionsdecls.html',
            controller: 'QuestionsAndDeclsCtrl'
        })
        .state('renewals.wizard.summary', {
            url: '/summary',
            templateUrl: 'renewals/wizard/wizard.step.summary.html',
            controller: 'SubmitWizardCtrl'
        })
        .state('renewals.acknowledge', {
            url: '/acknowledge',
            params: {
                selectedRenewalIds: {array: true}
            },
            templateUrl: 'renewals/wizard/acknowledge.html',
            controller: 'RenewalAcknowledgeCtrl'
        })
        .state('renewals.open', {
            url: '/{renewalId:[0-9]+}',
            abstract: true,
            resolve: {
                promisedLicense: ['$stateParams', 'AuthService', 'RenewalResolverService',
                    function($stateParams, AuthService, RenewalResolverService) {
                        var jwtToken = AuthService.getToken();
                        var ciprid = AuthService.getRoleContext().ciprid;
                        var licenseid = $stateParams.renewalId;
                        return RenewalResolverService.get(jwtToken, ciprid, licenseid);
                    }
                ],
                promisedCorpLicense: ['$stateParams', 'AuthService', 'promisedLicense', 'RenewalResolverService',
                    function($stateParams, AuthService, promisedLicense, RenewalResolverService) {
                        var jwtToken = AuthService.getToken();
                        var ciprid = AuthService.getRoleContext().ciprid;
                        if(promisedLicense.drlicense === 'Y' && !_.isEmpty(promisedLicense.corpLicense) && promisedLicense.corpLicense.statuscd.trim() === 'KREN') {
                            return RenewalResolverService.get(jwtToken, ciprid, promisedLicense.corpLicense.licenseid)
                        }

                        return {};
                    }
                ]
            },
            controller: 'RenewalOpenCtrl',
            template: '<div ui-view></div>'
        })
        .state('renewals.open.view', {
            url: '',
            views: {
                '': {
                    templateUrl: 'renewals/renewals.view.html',
                    controller: 'RenewalViewCtrl'
                },
                'header@renewals.open.view': {
                    templateUrl: 'applications/header/header.html'
                },
                'address@renewals.open.view': {
                    templateUrl: 'applications/address/address.view.html'
                },
                'eo@renewals.open.view': {
                    templateUrl: 'applications/eo/eo.view.html',
                    controller: 'RenewalEandoCtrl'
                },
                'qa@renewals.open.view': {
                    templateUrl: 'applications/qa/qa.view.html',
                    controller: 'RenewalQuestionsCtrl'
                },
                'payment@renewals.open.view': {
                    templateUrl: 'applications/payment/payment.html',
                    controller: 'RenewalPaymentCtrl'
                }
            }
        })
        .state('renewals.open.edit', {
            url: '',
            views: {
                '': {
                    templateUrl: 'renewals/renewals.edit.html',
                    controller: 'RenewalEditCtrl'
                },
                'header@renewals.open.edit': {
                    templateUrl: 'applications/header/header.html'
                },
                'address@renewals.open.edit': {
                    templateUrl: 'applications/address/address.edit.html',
                    controller: 'AddressCtrl'
                },
                'eo@renewals.open.edit': {
                    templateUrl: 'applications/eo/eo.add.html',
                    controller: 'RenewalEandoCtrl'
                },
                'qa@renewals.open.edit': {
                    templateUrl: 'applications/qa/qa.edit.html',
                    controller: 'RenewalQuestionsCtrl'
                },
                'payment@renewals.open.edit': {
                    templateUrl: 'applications/payment/payment.html',
                    controller: 'RenewalPaymentCtrl'
                }
            }
        });
    }
])

/**
 * Www routes controllers
 */
.controller('RenewalListCtrl', ['$scope', '$state', '$stateParams', '$filter', 'dialogs', 'toastr', 'blockUI', 'promisedInsCategories', 'RenewalService', 'InvoiceService',
    function($scope, $state, $stateParams, $filter, dialogs, toastr, blockUI, promisedInsCategories, RenewalService, InvoiceService) {

        $scope.page = {
            renewals: [],
            corpRenewals: [],
            selectedRenewalIds: [],
            searchPhrase: '',
            employeesOnly: false,

            items: [],
            totalItems: 0,
            begin: 0,
            end: 0,
            pageSize: 10,
            currentPage: 1
        };

        /**
         * Initializers
         */
        var renewalsBlock = blockUI.instances.get('renewals-block');
        renewalsBlock.start();

        RenewalService.getRenewals($scope.roleContext.ciprid, $stateParams.cache)
        .then(function(res) {
            var data = res.data;
            var codes = _.map(promisedInsCategories, 'code');

            $scope.page.renewals = _.filter(data[0], function(r) {
                return _.includes(codes, r.inscatcd);
            });
            $scope.page.corpRenewals = data[1];

            // link the corp license to the D/R license
            var drRenewals = _.filter($scope.page.renewals, {drlicense: 'Y'});
            _.forEach(drRenewals, function(renewal) {
                renewal.corpLicense = _.find($scope.page.corpRenewals, {
                    licenseclass: renewal.corporatelicenseclass,
                    agencyid: renewal.agencyid
                });
            });

            $scope.renderPage();
            // Default select all
            var selected = $scope.filter($scope.page.renewals);
            _.forEach(selected, function(renewal) {
                $scope.page.selectedRenewalIds.push(renewal.licenseid);
                if(renewal.corpLicense) {
                    $scope.page.selectedRenewalIds.push(renewal.corpLicense.licenseid);
                }
            });


            renewalsBlock.stop();
        });

        // -- called from form

        $scope.renderPage = function() {
            $scope.page.begin = (($scope.page.currentPage - 1) * $scope.page.pageSize);
            $scope.page.end = $scope.page.begin + $scope.page.pageSize;
            $scope.filteredItems = $scope.filter($scope.page.renewals);
            $scope.page.totalItems = $scope.filteredItems.length;
            $scope.page.items = $scope.filteredItems.slice($scope.page.begin, $scope.page.end);
        };

        $scope.filter = function(renewals) {
            var filteredItems = [];

            // filter by employee flag
            filteredItems = $scope.page.employeesOnly ? _.filter(renewals, {insureremployee: 'Y'}) : renewals;

            // filter by search phrase
            filteredItems = $filter('filter')(filteredItems, function(r) {
                if($scope.search(r.license, $scope.page.searchPhrase)) return true;
                if($scope.search(r.licensedescr, $scope.page.searchPhrase)) return true;
                if($scope.search(r.licenseholdername, $scope.page.searchPhrase)) return true;
                if($scope.search(r.agencyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.sponsorname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.appstatus, $scope.page.searchPhrase)) return true;
                return false;
            });

            return filteredItems;
        };

        $scope.search = function(haystack, needle) {
            return needle ? haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1 : true;
        };

        /**
         * View license
         * @param renewal
         */
        $scope.viewRenewal = function(renewal) {
            $state.go('renewals.open.view', {renewalId: renewal.licenseid});
        };

        /**
         * Shows a dialog to add to an invoice
         * @param renewal
         */
        $scope.payWith = function(renewal) {
            var data = {
                ciprid: $scope.roleContext.ciprid,
                license: renewal.license,
                licensedescr: renewal.licensedescr,
                agencyname: renewal.agencyname,
                sponsorname: renewal.sponsorname
            };
            dialogs.create('renewals/dialogs/invoice.html', 'RenewalPayWithDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(selectedInvoice) {
                if(selectedInvoice.invoiceid == 'new') $scope.addToShoppingCart(renewal, selectedInvoice.invoiceid);
                else $scope.addToInvoice(renewal);
            });
        };

        /**
         * Add to selected invoice.
         */
        $scope.addToInvoice = function(renewal, invoiceid) {
            InvoiceService.addRenewalsToInvoice($scope.roleContext.ciprid, invoiceid, [renewal])
            .then(function() {
                toastr.success('Success!', renewal.license + ' added to Invoice ' + invoiceid);
            }, function() {
                toastr.error('Error!', 'An error occurred while applying policies. Please try again.');
            });
        };

        /**
         * Add to first available invoice.
         */
        $scope.addToShoppingCart = function(renewal) {
            InvoiceService.addRenewalsToShoppingCart($scope.roleContext.ciprid, [renewal])
            .then(function() {
                toastr.success('Success!', renewal.license + ' added to invoice');
            }, function() {
                toastr.error('Error!', 'An error occurred while applying policies. Please try again.');
            });
        };

        /**
         * Called when checkbox is clicked to select/unselect all.
         *
         * @param $event
         */
        $scope.updateAllSelection = function($event) {
            $scope.page.selectedRenewalIds = [];

            var checkbox = $event.target;
            if(!checkbox.checked) return;

            var selected = $scope.filter($scope.page.renewals);
            _.forEach(selected, function(renewal) {
                $scope.page.selectedRenewalIds.push(renewal.licenseid);
                if(renewal.corpLicense) {
                    $scope.page.selectedRenewalIds.push(renewal.corpLicense.licenseid);
                }
            });
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;
            var selectedRenewal = _.find($scope.page.renewals, {licenseid: licenseid});

            if(checkbox.checked && $scope.page.selectedRenewalIds.indexOf(licenseid) === -1) {
                $scope.page.selectedRenewalIds.push(licenseid);
                if(selectedRenewal.corpLicense) {
                    $scope.page.selectedRenewalIds.push(selectedRenewal.corpLicense.licenseid);
                }
            }

            if(!checkbox.checked && $scope.page.selectedRenewalIds.indexOf(licenseid) !== -1) {
                $scope.page.selectedRenewalIds.splice($scope.page.selectedRenewalIds.indexOf(licenseid), 1);
                if(selectedRenewal.corpLicense) {
                    $scope.page.selectedRenewalIds.splice($scope.page.selectedRenewalIds.indexOf(selectedRenewal.corpLicense.licenseid), 1);
                }
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.page.selectedRenewalIds.indexOf(licenseid) >= 0;
        };

        $scope.isInvoiced = function(renewal) {
            return !_.isEmpty(renewal.invoiceid);
        };

        $scope.isViewCorporate = function(renewal) {
            return $scope.isDRRenewal(renewal) && (renewal.appstatuscd == 'LAS_REG_SUBMIT' || !$scope.isPerson());
        };

        $scope.isWithCandidate = function(renewal) {
            return RenewalService.isWithCandidate(renewal);
        };

        $scope.isWithRegulator = function(renewal) {
            return RenewalService.isWithRegulator(renewal);
        };

        $scope.isCERequirementMet = function(license) {
            return license.cerequired == 'Y' ? license.requirementMet : true;
        };

        $scope.isDRRenewal = function(renewal) {
            return renewal.drlicense == 'Y';
        };

        $scope.isInsurerEmployee = function(renewal) {
            return renewal.insureremployee == 'Y';
        };

        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            $state.go('renewals.eando', {selectedRenewalIds: $scope.page.selectedRenewalIds});
        };

        /**
         * Presents an invoice selection page.
         */
        $scope.addToInvoice = function() {
            $state.go('renewals.buy', {selectedRenewalIds: $scope.page.selectedRenewalIds});
        };

        /**
         * For  D/R licenses license fee is sum of the D/R License Fee and Corp License Fee
         */
        $scope.getFee = function(r) {
            var licenseFee = parseFloat(r.fee);
            return r.drlicense != 'Y' || !r.corpLicense ? licenseFee : licenseFee + parseFloat(r.corpLicense.fee);
        };

        $scope.getFeeBalance = function(r) {
            var licenseFeeBalance = parseFloat(r.feebalance);
            return r.drlicense != 'Y' || !r.corpLicense ? licenseFeeBalance : licenseFeeBalance + parseFloat(r.corpLicense.feebalance);
        };
    }
])
.controller('RenewalAddEandOCtrl', ['$scope', '$state', '$stateParams', '$filter', 'dialogs', 'toastr', 'blockUI', 'EAndOService', 'RenewalService',
    function($scope, $state, $stateParams, $filter, dialogs, toastr, blockUI, EAndOService, RenewalService) {

        $scope.page = {
            renewals: [],
            policies: [],
            selectedRenewalIds: $stateParams.selectedRenewalIds,
            searchPhrase: '',

            items: [],
            totalItems: 0,
            begin: 0,
            end: 0,
            pageSize: 10,
            currentPage: 1
        };

        /**
         * Initializers
         */
        var block = blockUI.instances.get('renewals-eando-block');
        block.start();

        RenewalService.getSelectedRenewals($scope.roleContext.ciprid, $stateParams.selectedRenewalIds, ['eandos'])
        .then(function(res) {
            var data = res.data;
            var groupedEandos = (!data || !data.eandos) ? [] : _.groupBy(data.eandos, "licenseid");

            $scope.page.renewals = (!data || !data.licenses) ? [] : _.map(data.licenses, function(license) {
                var renewal = {};
                renewal.license = license;
                renewal.eandos = groupedEandos[license.licenseid];
                return renewal;
            });

            $scope.renderPage();
            block.stop();
        });

        // -- Pagination --

        $scope.renderPage = function() {
            $scope.page.begin = (($scope.page.currentPage - 1) * $scope.page.pageSize);
            $scope.page.end = $scope.page.begin + $scope.page.pageSize;
            $scope.filteredItems = $scope.filter($scope.page.renewals);
            $scope.page.totalItems = $scope.filteredItems.length;
            $scope.page.items = $scope.filteredItems.slice($scope.page.begin, $scope.page.end);
        };

        $scope.filter = function(renewals) {
            var filteredItems = [];

            // filter by employee flag
            filteredItems = $scope.page.employeesOnly ? _.filter(renewals, {insureremployee: 'Y'}) : renewals;

            // filter by search phrase
            filteredItems = $filter('filter')(filteredItems, function(r) {
                if($scope.search(r.license.license, $scope.page.searchPhrase)) return true;
                if($scope.search(r.license.licensedescr, $scope.page.searchPhrase)) return true;
                if($scope.search(r.license.licenseholdername, $scope.page.searchPhrase)) return true;
                return false;
            });

            return filteredItems;
        };

        $scope.search = function(haystack, needle) {
            return needle ? haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1 : true;
        };

        /**
         * Presents a dialog to add a new policy
         */
        $scope.addPolicy = function() {
            var insurancecategories = _.chain($scope.page.renewals)
            .groupBy(function(renewal) {
                return renewal.license.insurancecategory;
            })
            .keys()
            .value();

            var data = {
                title: 'Add Errors and Omissions Coverage to ' + $scope.page.renewals.length + ' license(s)',
                insurancecategories: insurancecategories
            };

            dialogs.create('eando/dialogs/add.html', 'EandOAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                var addpolicy = function(r) {
                    r.eandos = _.isArray(r.eandos) ? r.eandos : [];
                    var licEandO = _(eando).clone();
                    licEandO.duplicate = EAndOService.checkIfDuplicate(r.eandos, eando);
                    r.eandos.push(licEandO);
                    return r;
                };

                $scope.page.renewals = _.map($scope.page.renewals, addpolicy);
                $scope.page.policies.push(eando);
            });
        };

        /**
         * Presents a dialog to add a new policy
         */
        $scope.saveRenewals = function() {
            block.start("Adding Errors and Omissions Coverage");
            EAndOService.addPoliciesToLicenses($scope.roleContext.ciprid, $scope.page.policies, $scope.page.selectedRenewalIds)
            .then(function() {
                block.stop();
                var l = $scope.page.selectedRenewalIds.length;
                _.forEach($scope.policies, function(policy) {
                    toastr.success('Success!', 'Policy by ' + policy.carriername + ' has been added to ' + l + ' license(s).');
                });

                $state.go($state.current, {selectedRenewalIds: $scope.page.selectedRenewalIds}, {reload: true});

            }, function() {
                block.stop();
                toastr.error('Error!', 'An error occurred while applying policies. Please try again.');
            });
        };

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };
    }
])
.controller('RenewalBuyCtrl', ['$scope', '$state', '$stateParams', '$filter', 'dialogs', 'toastr', 'blockUI', 'RenewalService', 'InvoiceService',
    function($scope, $state, $stateParams, $filter, dialogs, toastr, blockUI, RenewalService, InvoiceService) {

        $scope.page = {
            renewals: [],
            unpaidInvoices: [],
            invoice: undefined,
            searchPhrase: '',

            items: [],
            totalItems: 0,
            begin: 0,
            end: 0,
            pageSize: 10,
            currentPage: 1
        };

        /**
         * Initializers
         */
        var block = blockUI.instances.get('renewals-buy-block');
        block.start();

        RenewalService.getSelectedRenewals($scope.roleContext.ciprid, $stateParams.selectedRenewalIds, [])
        .then(function(res) {
            $scope.page.renewals = _.isEmpty(res.data) ? [] :
            _.chain(res.data.licenses)
            .map(function(license) {
                return {license: license};
            })
            .filter(function(r) {
                return r.license.invoiceid == '';
            })
            .value();

            $scope.renderPage();
            block.stop();
        });

        /**
         * Fetch unpaid invoices and prepopulate $scope.unpaidInvoices
         */
        InvoiceService.getUnpaidInvoices($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.page.unpaidInvoices.push({
                'invoiceid': 'new'
            });
            $scope.page.unpaidInvoices = $scope.page.unpaidInvoices.concat(res.data);
        });

        // -- Pagination --

        $scope.renderPage = function() {
            $scope.page.begin = (($scope.page.currentPage - 1) * $scope.page.pageSize);
            $scope.page.end = $scope.page.begin + $scope.page.pageSize;
            $scope.filteredItems = $scope.filter($scope.page.renewals);
            $scope.page.totalItems = $scope.filteredItems.length;
            $scope.page.items = $scope.filteredItems.slice($scope.page.begin, $scope.page.end);
        };

        $scope.filter = function(renewals) {
            var filteredItems = renewals;

            // filter by search phrase
            filteredItems = $filter('filter')(filteredItems, function(r) {
                if($scope.search(r.license.license, $scope.page.searchPhrase)) return true;
                if($scope.search(r.license.licensedescr, $scope.page.searchPhrase)) return true;
                if($scope.search(r.license.licenseholdername, $scope.page.searchPhrase)) return true;
                if($scope.search(r.license.agencyname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.license.sponsorname, $scope.page.searchPhrase)) return true;
                if($scope.search(r.license.appstatus, $scope.page.searchPhrase)) return true;
                return false;
            });

            return filteredItems;
        };

        $scope.search = function(haystack, needle) {
            return needle ? haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1 : true;
        };

        // --- Called from the form ---

        $scope.isDRRenewal = function(renewal) {
            return renewal.drlicense == 'Y';
        };

        /**
         * If invoice is selected, application is added to invoice
         * @param item
         * @param model
         */
        $scope.selectInvoice = function(item, model) {
            $scope.page.invoice = model;
        };

        /**
         * When submit is clicked, add licenses to selected invoice.
         */
        $scope.save = function() {
            if($scope.page.invoice.invoiceid == 'new') $scope.addToNewInvoice();
            else $scope.addToInvoice();
        };

        /**
         * Add to selected invoice.
         */
        $scope.addToInvoice = function() {
            block.start("Adding to Invoice");
            InvoiceService.addRenewalsToInvoice($scope.roleContext.ciprid, $scope.page.invoice.invoiceid, $scope.page.renewals)
            .then(function() {
                block.stop();
                toastr.success('Success!', 'Renewals added to invoice');
                $state.go('renewals.list', {cache: false});
            }, function() {
                block.stop();
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };

        /**
         * Add to a New invoice.
         */
        $scope.addToNewInvoice = function() {
            block.start("Adding to Invoice");
            InvoiceService.addRenewalsToNewInvoice($scope.roleContext.ciprid, $scope.page.renewals)
            .then(function() {
                block.stop();
                toastr.success('Success!', 'Renewals added to invoice');
                $state.go('renewals.list', {cache: false});
            }, function() {
                block.stop();
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };
    }
])

/**
 * Www wizard controllers
 */
.controller('RenewalSelectionCtrl', ['$scope', '$state', '$stateParams', '$timeout', 'DTOptionsBuilder', 'dialogs', 'toastr', 'blockUI', 'SweetAlert', 'RenewalService', 'InvoiceService', 'CEService',
    function($scope, $state, $stateParams, $timeout, DTOptionsBuilder, dialogs, toastr, blockUI, SweetAlert, RenewalService, InvoiceService, CEService) {

        $scope.page = {
            renewals: [],
            corpRenewals: [],
            selectedRenewalIds: [],
            employeesOnly: false
        };

        /**
         * Initializers
         */
        RenewalService.getRenewalAllow()
        .then(function(res) {

            if(res.data[0].renewalallow === 'N' && $scope.isPerson()) {
                $scope.renewalPeriodNotStarted = true;
            }
        });

        var renewalsBlock = blockUI.instances.get('renewals-block');
        renewalsBlock.start();

        RenewalService.getRenewals($scope.roleContext.ciprid, false /*don't use cache*/)
        .then(function(res) {
            $scope.page.renewals = res.data[0];
            $scope.page.corpRenewals = res.data[1];

            var partition = _.partition($scope.page.renewals, {insureremployee: 'Y'});
            $scope.page.employeeLicenses = partition[0];
            $scope.page.nonEmployeeLicenses = partition[1];

            // link the corp license to the D/R license
            var drRenewals = _.filter($scope.page.renewals, {drlicense: 'Y'});
            _.forEach(drRenewals, function(renewal) {
                renewal.corpLicense = _.find($scope.page.corpRenewals, {
                    licenseclass: renewal.corporatelicenseclass,
                    agencyid: renewal.agencyid
                });
            });

            if($scope.isPerson()) {
                CEService.getSummary($scope.roleContext.ciprid)
                .then(function(res) {
                    RenewalService.initRenewalCEStatus(res.data.ce, $scope.page.renewals);
                });
            }

            renewalsBlock.stop();
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        // -- called from form

        /**
         * If 'employee licenses only' checkbox is selected, filter renewals by 'insureremployee'
         */
        $scope.toggleEmployeeLicenses = function() {
            $timeout(function() {
                var block = blockUI.instances.get('renewals-block');
                block.start('Filtering...');
                $scope.page.renewals = $scope.page.employeesOnly ? $scope.page.employeeLicenses : _.union($scope.page.employeeLicenses, $scope.page.nonEmployeeLicenses);
                block.stop();
            });
        };

        /**
         * View license
         * @param renewal
         */
        $scope.viewRenewal = function(renewal) {
            if(RenewalService.isBackToAgent(renewal) && !RenewalService.isWithRegulator(renewal)) {
                $state.go('renewals.open.edit', {renewalId: renewal.licenseid});
            } else
                $state.go('renewals.open.view', {renewalId: renewal.licenseid});
        };

        $scope.hideRenew = function() {
            return !!$scope.renewalPeriodNotStarted;
        };

        $scope.viewCESummary = function() {
            SweetAlert.swal({
                title: "Continue Education Summary",
                text: "It Looks like you are leaving renewal. In order to come back please click on 'Licenses' from the menu.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $state.go('ce.summary');
                }
            });

        };

        $scope.addCE = function() {
            SweetAlert.swal({
                title: "Continue Education",
                text: "It Looks like you are leaving renewal. In order to come back please click on 'Licenses' from the menu.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $state.go('ce.add');
                }
            });

        };

        /**
         * Shows a dialog to add to an invoice
         * @param renewal
         */
        $scope.payWith = function(renewal) {
            var data = {
                ciprid: $scope.roleContext.ciprid,
                license: renewal.license,
                licensedescr: renewal.licensedescr,
                agencyname: renewal.agencyname,
                sponsorname: renewal.sponsorname
            };
            dialogs.create('renewals/dialogs/invoice.html', 'RenewalPayWithDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(selectedInvoice) {
                if(selectedInvoice.invoiceid == 'new') $scope.addToNewInvoice(renewal);
                else $scope.addToInvoice(selectedInvoice.invoiceid, renewal);
            });
        };

        /**
         * Called when checkbox is clicked to select/unselect all.
         *
         * @param $event
         */
        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;
            $scope.page.selectedRenewalIds = [];

            if(checkbox.checked) {
                _.forEach($scope.page.renewals, function(renewal) {
                    if($scope.canRenew(renewal)) {
                        $scope.page.selectedRenewalIds.push(renewal.licenseid);
                        if(renewal.corpLicense) {
                            $scope.page.selectedRenewalIds.push(renewal.corpLicense.licenseid);
                        }
                    }
                });
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;
            var selectedRenewal = _.find($scope.page.renewals, {licenseid: licenseid});

            if(checkbox.checked && $scope.page.selectedRenewalIds.indexOf(licenseid) === -1) {
                $scope.page.selectedRenewalIds.push(licenseid);
                if(selectedRenewal.corpLicense) {
                    $scope.page.selectedRenewalIds.push(selectedRenewal.corpLicense.licenseid);
                }
            }

            if(!checkbox.checked && $scope.page.selectedRenewalIds.indexOf(licenseid) !== -1) {
                $scope.page.selectedRenewalIds.splice($scope.page.selectedRenewalIds.indexOf(licenseid), 1);
                if(selectedRenewal.corpLicense) {
                    $scope.page.selectedRenewalIds.splice($scope.page.selectedRenewalIds.indexOf(selectedRenewal.corpLicense.licenseid), 1);
                }
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.page.selectedRenewalIds.indexOf(licenseid) >= 0;
        };

        $scope.canRenew = function(license) {
            return $scope.isPerson() ? $scope.isCERequirementMet(license) && license.appstatuscd == 'LAS_CAND_INPUT' && license.reviewstatuscd != 'LIC_REV_B2A' : true;
        };

        $scope.isInvoiced = function(renewal) {
            return !_.isEmpty(renewal.invoiceid);
        };

        $scope.isViewCorporate = function(renewal) {
            return $scope.isDRRenewal(renewal) && (renewal.appstatuscd == 'LAS_REG_SUBMIT' || !$scope.isPerson());
        };

        $scope.isWithCandidate = function(renewal) {
            return RenewalService.isWithCandidate(renewal);
        };

        $scope.isWithRegulator = function(renewal) {
            return RenewalService.isWithRegulator(renewal);
        };

        $scope.isBackToAgent = function(renewal) {
            return RenewalService.isBackToAgent(renewal);
        };

        $scope.isCERequirementMet = function(license) {
            return license.cerequired == 'Y' ? license.requirementMet : true;
        };

        $scope.isDRRenewal = function(renewal) {
            return renewal.drlicense == 'Y';
        };


        /**
         * Is it a restricted corp license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isRestrictedCorpRenewal = function(renewal) {
            return renewal.licensetype == 'CO';
        };

        $scope.getEmployeeCount = function(r) {
            return r.employeecountcd ? parseInt(r.employeecountcd.replace('DC', '')) : r.employeecountcd;
        };

        /**
         * Starts the wizard with selected licenses.
         */
        $scope.beginWizard = function() {
            $state.go('renewals.wizard', {selectedRenewalIds: $scope.page.selectedRenewalIds});
        };

        /**
         * For  D/R licenses license fee is sum of the D/R License Fee and Corp License Fee
         */
        $scope.getFee = function(r) {
            var licenseFee = parseFloat(r.fee);
            return r.drlicense != 'Y' || !r.corpLicense ? licenseFee : licenseFee + parseFloat(r.corpLicense.fee);
        };

        $scope.getFeeBalance = function(r) {
            var licenseFeeBalance = parseFloat(r.feebalance);
            return r.drlicense != 'Y' || !r.corpLicense ? licenseFeeBalance : licenseFeeBalance + parseFloat(r.corpLicense.feebalance);
        };

        /**
         * Add to selected invoice.
         */
        $scope.addToInvoice = function(invoiceid, renewal) {
            var r = {license: renewal};
            InvoiceService.addRenewalsToInvoice($scope.roleContext.ciprid, invoiceid, [r])
            .then(function() {
                toastr.success('Success!', renewal.license + ' added to Invoice ' + invoiceid);
            }, function() {
                toastr.error('Error!', 'An error occurred while applying policies. Please try again.');
            });
        };

        /**
         * Add to first available invoice.
         */
        $scope.addToNewInvoice = function(renewal) {
            var r = {license: renewal};
            InvoiceService.addRenewalsToNewInvoice($scope.roleContext.ciprid, [r])
            .then(function() {
                toastr.success('Success!', renewal.license + ' added to invoice');
            }, function() {
                toastr.error('Error!', 'An error occurred while applying policies. Please try again.');
            });
        };

        $scope.$on('$viewContentLoaded', function() {
            App.initAjax();
        });
    }
])
.controller('RenewalWizardCtrl', ['$rootScope', '$scope', '$state', '$stateParams', 'blockUI', 'AICBroadcastEvents', 'WizardService', 'RenewalService',
    function($rootScope, $scope, $state, $stateParams, blockUI, AICBroadcastEvents, WizardService, RenewalService) {

        $scope.renewals = [];
        $scope.wizard = WizardService.init([
            {state: 'renewals.wizard.payer', label: 'Step1', desc: '', icon: 'fa fa-shopping-cart'},
            {state: 'renewals.wizard.address', label: 'Step2', desc: '', icon: 'fa fa-globe'},
            {
                state: 'renewals.wizard.eando',
                label: 'Step3',
                desc: '',
                icon: 'fa fa-umbrella'
            },
            {
                state: 'renewals.wizard.questions',
                label: 'Step4',
                desc: '',
                icon: 'fa fa-question'
            },
            {
                state: 'renewals.wizard.summary',
                label: 'Step5',
                desc: '',
                icon: 'fa fa-rocket'
            }
        ]);

        /**
         * Initializers
         */
        var block = blockUI.instances.get('wizard-block');
        block.start('Preparing wizard...');

        $rootScope.$broadcast(AICBroadcastEvents.renewals.begin);

        RenewalService.getSelectedRenewals($scope.roleContext.ciprid, $stateParams.selectedRenewalIds,
        ['eandos', 'questions', 'declarations', 'occupations', 'attachments', 'invoices'])
        .then(function(res) {
            var wizardData = res.data;
            var groupedEandos = _.groupBy(wizardData.eandos, "licenseid");
            var groupedQuestions = _.groupBy(wizardData.questions, "licenseid");
            var groupedDeclarations = _.groupBy(wizardData.declarations, "licenseid");
            var groupedAttachments = _.groupBy(wizardData.attachments, "licenseid");
            var groupedInvoices = _.groupBy(wizardData.invoices, "licenseid");
            var groupedOccupations = _.groupBy(wizardData.occupations, "licenseid");

            $scope.renewals = _.map(wizardData.licenses, function(license) {
                var renewal = {};
                renewal.license = license;
                renewal.eandos = groupedEandos[license.licenseid] || [];
                renewal.questions = groupedQuestions[license.licenseid] || [];
                renewal.declarations = groupedDeclarations[license.licenseid] || [];
                renewal.attachments = groupedAttachments[license.licenseid] || [];
                renewal.occupations = groupedOccupations[license.licenseid] || [];

                if($scope.isInvoiced(renewal)) {
                    var invoice = groupedInvoices[license.licenseid][0] || {};
                    renewal.invoice = invoice;
                    renewal.payer = {
                        context: $scope.getPayerContext(license, invoice),
                        name: invoice.ownerpartyname,
                        ciprid: invoice.ownerciprid,
                        partyid: invoice.ownerpartyid
                    };
                }

                if(!$scope.isInvoiced(renewal)) {
                    renewal.payer = {
                        context: 'person',
                        name: renewal.license.licenseholdername,
                        ciprid: renewal.license.licenseholderciprid,
                        partyid: renewal.license.licenseholderpersonid
                    };
                }

                return renewal;
            });
            $scope.corpRenewals = _.filter($scope.renewals, {license: {licensetype: 'C'}});
            $scope.renewals = _.difference($scope.renewals, $scope.corpRenewals);
            $scope.restrictedRenewals = _.filter($scope.renewals, {license: {licensetype: 'CO'}});
            $scope.nonRestrictedRenewals = _.difference($scope.renewals, $scope.restrictedRenewals);
            $scope.drRenewals = _.filter($scope.renewals, {license: {drlicense: 'Y'}});

            // link the corp license to the D/R license
            _.forEach($scope.drRenewals, function(renewal) {
                _.forEach($scope.corpRenewals, function(corpRenewal) {
                    if(corpRenewal.license.licenseclass.trim() == renewal.license.corporatelicenseclass.trim() && corpRenewal.license.agencyid == renewal.license.agencyid) {
                        renewal.corpLicense = corpRenewal;
                    }
                });
            });

            block.stop();

            $scope.wizard.begin();
        });

        /**
         * Is it a DI license.
         * @param renewal
         * @returns {boolean}
         */
        $scope.isDIRenewal = function(renewal) {
            return renewal.licensetype == 'CO';
        };

        /**
         * Is it a DR license.
         * @param renewal
         * @returns {boolean}
         */
        $scope.isDRRenewal = function(renewal) {
            return renewal.drlicense == 'Y';
        };

        /**
         * Is it a restricted corp license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isRestrictedCorpRenewal = function(renewal) {
            return renewal.licensetype == 'CO';
        };

        /**
         * Has user selected scnrio_cd ?
         */
        $scope.hasRestrictedCorpLicenseFee = function(renewal) {
            return _.isObject(renewal.employeecountcd);
        };

        /**
         * For  D/R licenses license fee is sum of the D/R License Fee and Corp License Fee
         */
        $scope.getFee = function(r) {
            if($scope.isRestrictedCorpRenewal(r.license) && !$scope.hasRestrictedCorpLicenseFee(r.license)) return undefined;

            var licenseFee = parseFloat(r.license.fee);
            return r.license.drlicense != 'Y' || !r.corpLicense ? licenseFee : licenseFee + parseFloat(r.corpLicense.license.fee);
        };

        /**
         * Balance owing (including corp balance)
         * @param r
         * @returns {Number}
         */
        $scope.getFeeBalance = function(r) {
            var licenseFeeBalance = parseFloat(r.license.feebalance);
            return r.license.drlicense != 'Y' || !r.corpLicense ? licenseFeeBalance : licenseFeeBalance + parseFloat(r.corpLicense.license.feebalance);
        };

        /**
         * Is balance due == 0?
         * @param r
         * @returns {Boolean}
         */
        $scope.isPaid = function(r) {
            return $scope.getFeeBalance(r) == 0;
        };

        // --- Called from the form ---

        $scope.goNext = function() {
            $scope.wizard.next();
        };

        $scope.goBack = function() {
            $scope.wizard.previous();
        };

        /**
         * Is on an invoice?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isInvoiced = function(renewal) {
            return !_.isEmpty(renewal.license.invoiceid);
        };

        /**
         * Is context user paying for this license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isInvoicedToContextUser = function(renewal) {
            return renewal.payer.ciprid == $scope.roleContext.ciprid;
        };

        /**
         * Is agency paying for this license license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isInvoicedToAgency = function(renewal) {
            return renewal.payer.context == 'agency';
        };

        /**
         * Is sponsor paying for this license license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isInvoicedToSponsor = function(renewal) {
            return renewal.payer.context == 'sponsor';
        };

        /**
         * Is renewal paid by person, agency or sponsor?
         * @param renewal
         * @param invoice
         * @returns {*}
         */
        $scope.getPayerContext = function(renewal, invoice) {
            if(invoice.ownerpartyid == renewal.license.licenseholderid) return 'person';
            if(invoice.ownerpartyid == renewal.license.agencyid) return 'agency';
            if(invoice.ownerpartyid == renewal.license.sponsorid) return 'sponsor';
            return '';
        };
    }
])
.controller('PayerCtrl', ['$scope', '$state', '$http', 'InvoiceService', 'RenewalService',
    function($scope, $state, $http, InvoiceService, RenewalService) {

        $scope.hasPayerErrors = false;
        $scope.hasEmployeeErrors = false;
        $scope.feeStructure = [];
        $scope.hasRestrictedLicenses = _.some($scope.renewals, {license: {licensetype: 'CO'}});

        /**
         * Initializers
         */
        RenewalService.getLicenseFeeStructure()
        .then(function(res) {
            $scope.feeStructure = res.data;
            $scope.restrictedRenewals = _.map($scope.restrictedRenewals, function(renewal) {
                if(!_.isObject(renewal.license.employeecountcd)) {
                    renewal.license.employeecountcd = _.find($scope.feeStructure, {scnrio_cd: renewal.license.employeecountcd});
                }
                return renewal;
            });
        });

        // --- Called from the form ---

        /**
         * Set payer model from dropdown
         * @param r
         */
        $scope.setPayer = function(r) {
            var payer = {};

            if(r.payercontext == 'person') {
                payer = {
                    context: 'person',
                    name: r.license.licenseholdername,
                    ciprid: r.license.licenseholderciprid,
                    partyid: r.license.licenseholderpersonid
                };
            } else if(r.payercontext == 'agency') {
                payer = {
                    context: 'agency',
                    name: r.license.agencyname,
                    ciprid: r.license.agencyciprid,
                    partyid: r.license.agencyid
                };
            } else if(r.payercontext == 'sponsor') {
                payer = {
                    context: 'sponsor',
                    name: r.license.sponsorname,
                    ciprid: r.license.sponsorciprid,
                    partyid: r.license.sponsorid
                };
            }

            r.payer = payer;
        };

        /**
         * In case of restricted license, set fee using scenario code.
         */
        $scope.setRestrictedLicenseFee = function(renewal) {
            renewal.license.fee = renewal.license.employeecountcd.amount;
        };

        /**
         * In case of restricted license, set fee using scenario code.
         */
        $scope.isSoleProprietor = function(renewal) {
            return renewal.license.issoleproprietor == 'Y';
        };

        /**
         * Check if required info is completed
         */
        $scope.isFormValid = function() {
            $scope.renewals = _.map($scope.renewals, function(r) {
                r.errors = {};
                r.errors.payer = !$scope.isInvoiced(r) && !r.payer ? ['Please select who is paying for this renewal.'] : [];
                r.errors.employee = $scope.isRestrictedCorpRenewal(r.license) && !$scope.hasRestrictedCorpLicenseFee(r.license) ? ['Please choose employee counts'] : [];
                return r;
            });

            $scope.hasPayerErrors = _.chain($scope.renewals)
            .map('errors')
            .map('payer')
            .flatten()
            .some(Boolean)
            .value();

            $scope.hasEmployeeErrors = _.chain($scope.renewals)
            .map('errors')
            .map('employee')
            .flatten()
            .some(Boolean)
            .value();

            return !$scope.hasPayerErrors && !$scope.hasEmployeeErrors;
        };

        /**
         * Go back
         */
        $scope.backward = function() {
            $scope.goBack();
        };

        /**
         * When forward is clicked, validate and call goNext()
         */
        $scope.forward = function() {
            if(!$scope.isFormValid()) return;

            $scope.hasErrors = _.some($scope.renewals, function(r) {
                return r.errors.length > 0;
            });

            _.chain($scope.renewals)
            .filter({license: {licensetype: 'CO'}})
            .each(function(renewal) {
                renewal.license.fee = renewal.license.scnrio_cd.amount;
            });

            $scope.goNext();
        };
    }
])
.controller('AddressCtrl', ['$scope', '$state', '$http', 'dialogs', 'RenewalService', 'AddressService',
    function($scope, $state, $http, dialogs, RenewalService, AddressService) {

        $scope.incompleteAddress = false;

        /**
         * Initializers
         */
        _.map($scope.renewals, function(r) {
            r.address = AddressService.copyAddress(r.license);
            r.address.city = r.address.cityid ? r.address.city : '';
            r.address.province = r.address.provinceid ? r.address.province : '';
            return r;
        });

        /**
         * Presents an edit dialog.
         * @param license
         */
        $scope.editAddress = function(license) {
            dialogs.create('address/dialogs/edit.html', 'AddressEditDialogCtrl', license.address, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(address) {
                var identical = angular.equals(license.address, address);
                license.address = address;
                license.address.tobesaved = !identical;
            });
        };

        $scope.copyAddress = function(license, corpLicense) {
            corpLicense.address = _.cloneDeep(license.address);
            corpLicense.address.tobesaved = true;
        };

        /**
         * Deletes all fields of an address
         * @param license
         */
        $scope.deleteAddress = function(license) {
            license.address = {};
        };

        /**
         * Check if required info is completed
         */
        $scope.isFormValid = function() {
            $scope.renewals = _.map($scope.renewals, function(r) {
                r.errors = AddressService.isAddressValid(r.address) ? [] : ['Address is incomplete'];
                return r;
            });

            $scope.incompleteAddress = _.chain($scope.renewals)
            .map('errors')
            .flatten()
            .some(Boolean)
            .value();

            return !$scope.incompleteAddress;
        };

        /**
         * Go back
         */
        $scope.backward = function() {
            $scope.goBack();
        };

        /**
         * When continue is clicked, validate and call goNext()
         */
        $scope.forward = function() {
            if(!$scope.isFormValid()) return;
            $scope.goNext();
        };
    }
])
.controller('EandOCtrl', ['$scope', '$state', '$stateParams', '$http', 'RenewalService', 'dialogs',
    function($scope, $state, $stateParams, $http, RenewalService, dialogs) {

        $scope.missingPolicies = false;

        /**
         * Presents E&O window
         * @param renewal
         */
        $scope.addEandO = function(renewal) {
            var licensestr = renewal.license.drlicense == 'Y' ? renewal.license.license + " and " + renewal.corpLicense.license.license : renewal.license.license;
            var data = {
                title: 'Add Errors and Omissions Coverage to ' + licensestr,
                insurancecategories: [renewal.license.insurancecategory]
            };
            dialogs.create('eando/dialogs/add.html', 'EandOAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                if(!renewal.eandos) renewal.eandos = [];
                renewal.eandos.push(eando);
            });
        };

        /**
         * Check if required info is completed
         */
        $scope.isFormValid = function() {
            var clause = function(eando) {
                return _.has(eando, 'deleted') ? eando.deleted == false : true;
            };

            $scope.renewals = _.map($scope.renewals, function(r) {
                var filterObj = _.chain(r.eandos).filter(clause);
                r.errors = r.license.eorequired == 'N' || (r.license.eorequired == 'Y' && filterObj.size().value() > 0 && _.includes(filterObj.map('coveragestatus').flatten().trim().value(), 'Active')) ? [] : ['At least one active policy is required'];
                return r;
            });

            $scope.missingPolicies = _.chain($scope.renewals)
            .map('errors')
            .flatten()
            .some(Boolean)
            .value();

            return !$scope.missingPolicies;
        };

        /**
         * Go back
         */
        $scope.backward = function() {
            $scope.goBack();
        };

        /**
         * When continue is clicked, validate and call goNext()
         */
        $scope.forward = function() {
            if(!$scope.isFormValid()) return;
            $scope.goNext();
        };

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };

    }
])
.controller('QuestionsAndDeclsCtrl', ['$scope', '$state', '$http', 'FileUploader', 'RenewalService',
    function($scope, $state, $http, FileUploader, RenewalService) {

        $scope.legalEntities = [];
        $scope.incompleteQuestions = false;
        $scope.incompleteDeclarations = false;
        $scope.incompleteOccupations = false;

        /**
         * Initializers
         */
        var limitFilter = {
            name: 'limitFilter',
            fn: function() {
                return this.queue.length < 10;
            }
        };

        var pdfFilter = {
            name: 'pdfFilter',
            fn: function(item) {
                return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
            }
        };
        $scope.legalEntities = _.chain(_.union($scope.nonRestrictedRenewals, $scope.restrictedRenewals, $scope.corpRenewals))
        .groupBy(function(renewal) {
            return renewal.license.licenseholderid;
        })
        .map(function(renewals, licenseholderid) {
            var legalEntity = {
                renewals: renewals,
                licenseholderciprid: licenseholderid
            };

            legalEntity.uniqueDeclarations = _.chain(renewals).map('declarations').flatten().uniqBy('declarationtext').sortBy('declarationtext').value();
            legalEntity.uniqueOccupations = _.chain(renewals).map('occupations').flatten().uniqBy('otheroccupationcode').value();

            var allQuestions = _.chain(renewals)
            .map('questions')
            .flatten()
            .value();

            legalEntity.uniqueQuestions = _.chain(renewals)
            .map('questions')
            .flatten()
            .uniqBy('questionid')
            .map(function(q, index) {
                var formData = _.chain(renewals)
                .map('license')
                .flatten()
                .map(function(license) {
                    var ques = _.find(allQuestions, {
                        'questionid': q.questionid,
                        licenseid: license.licenseid
                    });
                    var answerid = ques.answerid;
                    return JSON.stringify({
                        licenseid: license.licenseid,
                        partyid: license.licenseholderid,
                        answerid: answerid,
                        questionnum: q.questionnum,
                        questionpart: q.questionpart
                    });
                })
                .value();

                q.index = index + 1;
                q.uploader = new FileUploader({
                    autoUpload: true,
                    method: 'PUT',
                    url: '/' + $scope.roleContext.ciprid + '/renewals/',
                    filters: [limitFilter, pdfFilter],
                    formData: {fields: formData},
                    headers: {
                        Authorization: 'Bearer ' + $scope.jwtToken
                    }
                });
                return q;
            })
            .value();
            return legalEntity;
        })
        .value();

        // --- Called from the form ---

        /**
         * Fetch default occupation
         */
        $scope.defaultOccupationDetails = function(occupation) {
            if(occupation.detailsrequired == 'Y') {
                RenewalService.getPrevOtherOccupaDetail($scope.roleContext.ciprid, occupation)
                .then(function(res) {
                    if(!_.isEmpty(res.data)) {
                        occupation.details = res.data[0].details;
                    }
                });
            }
        };

        /**
         * Show text area.
         * @param question
         * @returns {boolean}
         */
        $scope.showAnswerDetails = function(question) {
            return question.hasotheroccupation != 'Y' && question.answer && question.answer != question.affirmativeanswer;
        };

        /**
         * Shows other occupations.
         * @param question
         * @returns {boolean}
         */
        $scope.showOtherOccupations = function(question) {
            return question.hasotheroccupation == 'Y' && question.answer && question.answer != question.affirmativeanswer;
        };

        /**
         * Shows text area for other occupation.
         * @param occupation
         * @returns {boolean}
         */
        $scope.showOtherOccupationDetails = function(occupation) {
            return occupation.detailsrequired == 'Y' && occupation.selected;
        };

        /**
         * Check if required info is completed
         */
        $scope.isFormValid = function() {
            var otheroccupationclause = function(o) {
                return o.selected ? true : false;
            };

            var checkDeclarations = function(d) {
                d.errors = d.response == 'true' ? [] : ['Do you confirm?'];
                return d;
            };
            var checkOtherOccupations = function(o) {
                o.errors = o.selected && o.detailsrequired == 'Y' && !o.details ? ['Please provide occupation details'] : [];
                return o;
            };

            $scope.legalEntities = _.map($scope.legalEntities, function(legalEntity) {
                //_.map(legalEntity.uniqueQuestions, checkQuestions);
                _.map(legalEntity.uniqueQuestions, function(q) {
                    q.errors = q.answer ? [] : ['Please answer this question'];
                    q.answerdetailserrors = q.answer != q.affirmativeanswer && q.hasotheroccupation == 'N' && _.isEmpty(q.answerdetails) ? ['Please provide details to this question'] : [];
                    q.otheroccupationserrors = q.answer != q.affirmativeanswer && q.hasotheroccupation == 'Y' && _.chain(legalEntity.uniqueOccupations).filter(otheroccupationclause).size().value() <= 0 ? ['Please provide other occupation details to this question'] : [];
                    return q;
                });
                _.map(legalEntity.uniqueDeclarations, checkDeclarations);
                _.map(legalEntity.uniqueOccupations, checkOtherOccupations);

                return legalEntity;
            });

            $scope.incompleteQuestions = _.chain($scope.legalEntities)
            .map('uniqueQuestions')
            .flatten()
            .map('errors')
            .flatten()
            .some(Boolean)
            .value();

            $scope.incompleteAnswerDetails = _.chain($scope.legalEntities)
            .map('uniqueQuestions')
            .flatten()
            .map('answerdetailserrors')
            .flatten()
            .some(Boolean)
            .value();

            $scope.incompleteOtherOccupationAnswer = _.chain($scope.legalEntities)
            .map('uniqueQuestions')
            .flatten()
            .map('otheroccupationserrors')
            .flatten()
            .some(Boolean)
            .value();

            $scope.incompleteDeclarations = _.chain($scope.legalEntities)
            .map('uniqueDeclarations')
            .flatten()
            .map('errors')
            .flatten()
            .some(Boolean)
            .value();

            $scope.incompleteOccupations = _.chain($scope.legalEntities)
            .map('uniqueOccupations')
            .flatten()
            .map('errors')
            .flatten()
            .some(Boolean)
            .value();

            return !$scope.incompleteQuestions && !$scope.incompleteAnswerDetails && !$scope.incompleteOtherOccupationAnswer && !$scope.incompleteDeclarations && !$scope.incompleteOccupations;
        };

        /**
         * When continue is clicked, validate and call goNext()
         */
        $scope.forward = function() {
            if(!$scope.isFormValid()) return;

            _.forEach($scope.legalEntities, function(legalEntity) {
                _.forEach(legalEntity.renewals, function(renewal) {

                    // for each renewal in the legal entity update renewal declarations using responses from
                    // unique declaration list for that legal entity.
                    _.forEach(renewal.declarations, function(d) {
                        var declaration = _.find(legalEntity.uniqueDeclarations, {declarationid: d.declarationid});
                        d.response = declaration.response;
                    });

                    // for each renewal in the legal entity update renewal questions using answers and answer
                    // details from unique question list for that legal entity.
                    _.forEach(renewal.questions, function(q) {
                        var question = _.find(legalEntity.uniqueQuestions, {questionid: q.questionid});
                        q.answer = question.answer;
                        q.answerdetails = question.answerdetails;

                        if(question.hasotheroccupation == 'Y') {
                            _.forEach(renewal.occupations, function(o) {
                                var occupation = _.find(legalEntity.uniqueOccupations, {otheroccupationcode: o.otheroccupationcode});
                                o.answerseq = q.answerid;
                                o.details = occupation.details;
                                o.selected = occupation.selected;
                            });
                        }
                    });
                });
            });

            $scope.renewals = _.chain($scope.legalEntities)
            .map('renewals')
            .flatten()
            .value();

            $scope.goNext();
        };

        /**
         * Clear declarations and go back
         */
        $scope.backward = function() {
            _.forEach($scope.legalEntities, function(legalEntity) {
                _.forEach(legalEntity.uniqueDeclarations, function(decl) {
                    decl.response = '';
                });

                _.forEach(legalEntity, function(renewal) {
                    _.forEach(renewal.declarations, function(decl) {
                        decl.response = '';
                    });
                });
            });
            $scope.goBack();
        };
    }
])
.controller('SubmitWizardCtrl', ['$rootScope', '$scope', '$state', '$http', 'toastr', 'dialogs', 'AICBroadcastEvents', 'RenewalService', 'InvoiceService',
    function($rootScope, $scope, $state, $http, toastr, dialogs, AICBroadcastEvents, RenewalService, InvoiceService) {

        $scope.payerGroups = _.groupBy($scope.renewals, function(r) {
            return r.payer.name;
        });

        /**
         * compute totals by payer group
         */
        $scope.getTotal = function(renewals) {
            return _.reduce(renewals, function(sum, license) {
                return sum + $scope.getFee(license);
            }, 0);
        };

        /**
         * Go back
         */
        $scope.backward = function() {
            $scope.goBack();
        };

        function copyeandos(license, corpLicense) {
            var deleteclause = function(eando) {
                return _.has(eando, 'deleted') ? eando.deleted == true : false;
            };
            var deletedEandos = _.chain(license.eandos).filter(deleteclause).value();
            var newclause = function(eando) {
                return _.has(eando, 'new') ? eando.new == true : false;
            };
            var newEandos = _.chain(license.eandos).filter(newclause).value();

            _.forEach(deletedEandos, function(eando) {
                var e = _.find(corpLicense.eandos, {policyno: eando.policyno, carrierpartyid: eando.carrierpartyid});
                e.deleted = eando.deleted;
            });

            _.forEach(newEandos, function(eando) {
                var copyeando = _.cloneDeep(eando);
                copyeando.licenseid = corpLicense.license.licenseid;
                corpLicense.eandos.push(copyeando);
            });

        }

        /**
         * Finish
         */
        $scope.submit = function() {
            // set data for corp renewals - address, E&O, payer, invoice need to be identical
            // as the corresponding D/R license
            _.forEach($scope.drRenewals, function(renewal) {
                _.forEach($scope.corpRenewals, function(corpRenewal) {
                    if(corpRenewal.license.licenseclass.trim() == renewal.license.corporatelicenseclass.trim()) {

                        // copy address
                        corpRenewal.address = renewal.address;

                        //copy payment/invoice info
                        corpRenewal.payer = renewal.payer;
                        corpRenewal.invoice = renewal.invoice;

                        // copy E&Os
                        copyeandos(renewal, corpRenewal);

                    }
                });
            });

            $scope.renewals = _.map($scope.renewals, function(renewal) {
                renewal.questions = _.map(renewal.questions, function(q) {
                    q.uploader = undefined;
                    return q;
                });
                return renewal;
            });

            $scope.corpRenewals = _.map($scope.corpRenewals, function(corprenewal) {
                corprenewal.questions = _.map(corprenewal.questions, function(q) {
                    q.uploader = undefined;
                    return q;
                });
                return corprenewal;
            });

            RenewalService.finish($scope.roleContext.ciprid, _.union($scope.renewals, $scope.corpRenewals))
            .then(function(reply) {

                $rootScope.$broadcast(AICBroadcastEvents.renewals.end);

                var selectedRenewalIds = _.map(_.union($scope.renewals, $scope.corpRenewals), function(r) {
                    return r.license.licenseid;
                });
                $state.go('renewals.acknowledge', {selectedRenewalIds: selectedRenewalIds}, {
                    location: false,
                    inherit: true,
                    relative: $state.$current,
                    notify: true
                });
            }, function(reply, status) {
                var msgs = _.groupBy(reply.error, 'license');
                dialogs.create('renewals/dialogs/errors.html', 'RenewalErrorsDialogCtrl', msgs, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                });
            });
        };
    }
])
.controller('RenewalAcknowledgeCtrl', ['$scope', '$sta' +
'te', '$http', '$stateParams', '$uibModal', 'blockUI', 'dialogs', 'RenewalService', 'InvoiceReceiptService',
    function($scope, $state, $http, $stateParams, $uibModal, blockUI, dialogs, RenewalService, InvoiceReceiptService) {

        $scope.renewals = [];
        $scope.unpaidInvoices = [];
        $scope.balanceDue = 0;
        $scope.paidbycc = false;
        $scope.invoice = {};
        $scope.cc = {};
        $scope.minExpiryYear = (new Date().getFullYear() + '').slice(-2);
        $scope.maxExpiryYear = (moment().add('y', 10).toDate().getFullYear() + '').slice(-2);
        $scope.isPaymentBYCCInProgress = false;
        var invoiceBlock = blockUI.instances.get('invoice-pay-block');
        /**
         * Initializers
         */
        var block = blockUI.instances.get('next-steps');
        block.start('Your next steps...');

        RenewalService.getSelectedRenewals($scope.roleContext.ciprid, $stateParams.selectedRenewalIds, ['invoices'])
        .then(function(res) {
            var invoicesByLicense = _.groupBy(res.data.invoices, "licenseid");
            var invoicesByOwner = _.groupBy(res.data.invoices, "ownerpartyname");

            $scope.renewals = res.data.licenses;
            $scope.renewals = _.map(res.data.licenses, function(renewal) {
                var invoice = invoicesByLicense[renewal.licenseid][0];
                renewal.invoice = invoice;
                renewal.payer = {
                    context: $scope.getPayerContext(renewal, invoice),
                    name: invoice.ownerpartyname,
                    ciprid: invoice.ownerciprid,
                    partyid: invoice.ownerpartyid
                };
                return renewal;
            });

            $scope.corpRenewals = _.filter($scope.renewals, {licensetype: 'C'});
            $scope.renewals = _.difference($scope.renewals, $scope.corpRenewals);
            $scope.drRenewals = _.filter($scope.renewals, {drlicense: 'Y'});

            // link the corp license to the D/R license
            _.forEach($scope.drRenewals, function(renewal) {
                _.forEach($scope.corpRenewals, function(corpRenewal) {
                    if(corpRenewal.licenseclass.trim() == renewal.corporatelicenseclass.trim() && corpRenewal.agencyid == renewal.agencyid) {
                        renewal.corpLicense = corpRenewal;
                    }
                });
            });

            $scope.unpaidInvoices = invoicesByOwner[$scope.roleContext.name];
            $scope.unpaidInvoices = _.uniqBy($scope.unpaidInvoices, 'inovoiceid');
            $scope.balanceDue = _.reduce($scope.renewals, function(sum, r) {
                var due = $scope.isInvoicedToContextUser(r) ? $scope.getFeeBalance(r) : 0;
                return sum + parseFloat(due);
            }, 0);
            $scope.invoice = $scope.balanceDue != 0 ? $scope.unpaidInvoices[0] : {};
            block.stop();
        });

        /**
         * Is it a DR license.
         * @param renewal
         * @returns {boolean}
         */
        $scope.isDRRenewal = function(renewal) {
            return renewal.drlicense == 'Y';
        };

        /**
         * Is license added to any invoice.
         * @param license
         * @returns {boolean}
         */
        $scope.isInvoiced = function(license) {
            return !!license.invoiceid;
        };

        /**
         * Is context user paying for this license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isInvoicedToContextUser = function(renewal) {
            return renewal.payer.ciprid == $scope.roleContext.ciprid;
        };

        /**
         * Is agency paying for this license license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isInvoicedToAgency = function(renewal) {
            return renewal.payer.context == 'agency';
        };

        /**
         * Is sponsor paying for this license license?
         * @param renewal
         * @returns {boolean}
         */
        $scope.isInvoicedToSponsor = function(renewal) {
            return renewal.payer.context == 'sponsor';
        };

        $scope.getPayerContext = function(license, invoice) {
            if(invoice.ownerpartyid == license.licenseholderid) return 'person';
            if(invoice.ownerpartyid == license.agencyid) return 'agency';
            if(invoice.ownerpartyid == license.sponsorid) return 'sponsor';
            return '';
        };

        /**
         * License fee (including corp fee)
         * @param r
         * @returns {Number}
         */
        $scope.getFee = function(r) {
            var licenseFee = parseFloat(r.fee);
            return r.drlicense != 'Y' || !r.corpLicense ? licenseFee : licenseFee + parseFloat(r.corpLicense.fee);
        };

        /**
         * Balance owing (including corp balance)
         * @param r
         * @returns {Number}
         */
        $scope.getFeeBalance = function(r) {
            var licenseFeeBalance = parseFloat(r.feebalance);
            return r.drlicense != 'Y' || !r.corpLicense ? licenseFeeBalance : licenseFeeBalance + parseFloat(r.corpLicense.feebalance);
        };

        /**
         * Is balance due == 0?
         * @param r
         * @returns {Boolean}
         */
        $scope.isPaid = function(r) {
            return $scope.getFeeBalance(r) == 0;
        };

        /**
         * Returns true if all renewals are paid.
         * @returns {Number}
         */
        $scope.allRenewalsPaid = function() {
            return _.every($scope.renewals, function(r) {
                return $scope.getFeeBalance(r) == 0;
            });
        };

        /**
         * Open invoice view
         */
        $scope.viewInvoice = function(invoice) {
            $state.go('invoices.view', {invoiceId: invoice.invoiceid});
        };

        /**
         * Pay
         * @param invoice
         */
        $scope.pay = function(invoice) {
            $scope.isPaymentBYCCInProgress = true;
            invoiceBlock.start('Processing your payment. This could take up to a minute to finish.');
            $http.post('/' + $scope.roleContext.ciprid + '/invoices/' + invoice.invoiceid + '/pay/with/cc', {
                data: {
                    invoiceid: invoice.invoiceid,
                    amount: parseFloat(invoice.balance),
                    ccNumber: $scope.cc.number,
                    ccExpiryMonth: $scope.cc.expiryMonth,
                    ccExpiryYear: $scope.cc.expiryYear,
                    ciprid: $scope.roleContext.ciprid
                }
            })
            .then(function(res) {
                invoiceBlock.stop();
                var data = res.data;
                if(data.success) {
                    $scope.paidbycc = true;
                    $http.get('/' + $scope.roleContext.ciprid + '/invoices/payments/' + data.authId)
                    .then(function(res) {
                        $scope.isPaymentBYCCInProgress = false;
                        $uibModal.open({
                            templateUrl: 'invoices/invoices.receipt.html',
                            resolve: {
                                receipt: function() {
                                    return res.data[0].receiptText;
                                }
                            },
                            controller: 'InvoiceReceiptCtrl'
                        });
                    });
                } else {
                    $scope.isPaymentBYCCInProgress = false;
                    dialogs.create('invoices/dialogs/ccerror.html', 'InvoicePaymentErrorCtrl', data, {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    });
                }
            }, function(res, status) {
                $scope.isPaymentBYCCInProgress = false;
                invoiceBlock.stop();
                dialogs.create('invoices/dialogs/ccerror.html', 'InvoicePaymentErrorCtrl', res.data, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                });
            });
        };


    }
])

/**
 * Renewals View controllers
 */
.controller('RenewalOpenCtrl', ['$scope', '$state', '$stateParams', '$window', 'dialogs', 'promisedLicense', 'promisedCorpLicense', 'RenewalService',
    function($scope, $state, $stateParams, $window, dialogs, promisedLicense, promisedCorpLicense, RenewalService) {

        $scope.license = promisedLicense;
        $scope.corpLicense = promisedCorpLicense;

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid;
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) == 0;
        };

        $scope.isDRApp = function(license) {
            return RenewalService.isDRApp(license);
        };

        $scope.isCorpLicenseExists = function(corpLicense) {
            return RenewalService.isCorpLicenseExists(corpLicense);
        };

        $scope.isRestrictedCorpRenewal = function(renewal) {
            return renewal.licensetype == 'CO';
        };

        $scope.isWithRegulator = function(license) {
            return RenewalService.isWithRegulator(license);
        };

        $scope.isRenewal = function(license) {
            return RenewalService.isRenewal(license);
        };
        /**
         * Called when 'Delete' is clicked. Abandons renewals.
         * @param license
         */
        $scope.deleteRenewal = function(license) {
            var copy = {
                license: license.license,
                licensedescr: license.licensedescr,
                corplicense: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.license : '',
                corplicensedescr: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licensedescr : ''
            };
            dialogs.create('renewals/dialogs/delete.html', 'RenewalDeleteDialogCtrl', copy, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                if(RenewalService.isDRRenewal(license) && RenewalService.isCorpLicenseExists($scope.corpLicense) && RenewalService.isRenewal($scope.corpLicense)) {
                    RenewalService.discard($scope.roleContext.ciprid, license.licenseid)
                    .then(function() {
                        RenewalService.discard($scope.roleContext.ciprid, $scope.corpLicense.licenseid)
                        .then(function(results) {
                            toastr.success('Success!', 'Renewals ' + license.license + ' and ' + $scope.corpLicense.license + ' has been deleted.');
                            $window.history.back();
                        })
                    });
                } else {
                    RenewalService.discard($scope.roleContext.ciprid, license.licenseid)
                    .then(function(data) {
                        toastr.success('Success!', 'Renewal ' + license.license + ' has been deleted.');
                        $window.history.back();
                    });
                }
            });
        };

        $scope.can = {
            address: {
                view: true,
                edit: $scope.isPerson()
            },
            eando: {
                view: true,
                edit: $scope.isPerson()
            },
            questions: {
                view: true,
                edit: $scope.isPerson()
            },
            declarations: {
                view: $scope.isPerson(),
                edit: $scope.isPerson()
            },
            notes: {
                view: false,
                edit: false
            },
            submit: {
                view: false,
                edit: false
            }
        };

    }
])
.controller('RenewalDeleteDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.license = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])
.controller('RenewalEandoCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'RenewalService',
    function($scope, $state, $stateParams, dialogs, RenewalService) {

        /**
         * Presents a dialog to add a new policy
         */
        $scope.addPolicy = function() {
            var data = {
                title: 'Add Errors and Omissions Coverage to the License: ' + $scope.license.license,
                insurancecategories: [$scope.license.insurancecategory]
            };
            dialogs.create('eando/dialogs/add.html', 'EandOAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                if(!$scope.license.eandos) $scope.license.eandos = [];
                $scope.license.eandos.push(eando);
            });
        };

        /**
         * Mark policy as 'Removed'
         * @param eando
         */
        $scope.removeEandO = function(eando) {
            eando.deleted = true;
            if(eando.new) {
                $scope.license.eandos = _.reject($scope.license.eandos, eando);
            }
        };

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };

    }
])
.controller('RenewalViewCtrl', ['$scope', '$state', '$stateParams', '$location', '$anchorScroll', '$timeout', 'dialogs', 'toastr', 'RenewalService',
    function($scope, $state, $stateParams, $location, $anchorScroll, $timeout, dialogs, toastr, RenewalService) {
        $scope.submitted = false;
        $scope.licenseValidationErrors = [];

        if($scope.license.licensetype === 'C') {
            toastr.warning('Warning!', 'Please open D/R Renewal associated with it.');
            $state.go('renewals.select');
            return;
        }
        ;

        if(RenewalService.isBackToAgent($scope.license) && !RenewalService.isWithRegulator($scope.license)) {
            $state.go('renewals.open.edit', {renewalId: $scope.license.licenseid});
        }

    }
])
.controller('RenewalEditCtrl', ['$scope', '$state', '$stateParams', '$location', '$anchorScroll', '$q', '$timeout', 'dialogs', 'toastr', 'ApplicationService',
    function($scope, $state, $stateParams, $location, $anchorScroll, $q, $timeout, dialogs, toastr, ApplicationService) {

        $scope.submitted = false;
        $scope.licenseValidationErrors = [];
        $scope.licenseToSave = _.cloneDeep($scope.license);
        $scope.corpLicenseToSave = _.cloneDeep($scope.corpLicense);

        if($scope.license.licensetype === 'C') {
            toastr.warning('Warning!', 'Please open D/R Renewal associated with it.');
            $state.go('renewals.select');
            return;
        }
        ;

        /**
         * Show EO form if license.eorequired is "Y"
         */
        $scope.isEORequired = function() {
            return $scope.license.eorequired == "Y";
        };

        var hasErrors = function(validationErrors) {
            return !_.isEmpty(validationErrors.eandoErrors)
            || !_.isEmpty(validationErrors.questionErrors)
            || !_.isEmpty(validationErrors.occupationErrors)
            || !_.isEmpty(validationErrors.addressErrors)
            || !_.isEmpty(validationErrors.paymentErrors);
        };

        var scrollToErrors = function(validationErrors) {
            var scrollto = '';
            if(!_.isEmpty(validationErrors.addressErrors)) scrollto = 'address-portlet';
            else if(!_.isEmpty(validationErrors.eandoErrors)) scrollto = 'eo-portlet';
            else if(!_.isEmpty(validationErrors.questionErrors)) scrollto = 'questions-portlet';
            else if(!_.isEmpty(validationErrors.occupationErrors)) scrollto = 'questions-portlet';
            else if(!_.isEmpty(validationErrors.paymentErrors)) scrollto = 'payment-portlet';

            $location.hash(scrollto);
            $anchorScroll();
        };

        /**
         * Submits application for approval.
         */
        $scope.submitApplication = function() {
            $scope.submitted = true;
            if(ApplicationService.isDRApp($scope.license) && ApplicationService.isCorpLicenseExists($scope.corpLicense)) {
                return submitDRCorpApplication();
            }

            var copy = _.cloneDeep($scope.license);
            copy.questions = _.map(copy.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            ApplicationService.validateApplication($scope.roleContext.ciprid, copy)
            .then(function(res) {
                var validationErrors = res.data;
                if(hasErrors(validationErrors)) {
                    $scope.licenseValidationErrors = validationErrors;

                    var title = 'Incomplete Application';
                    var body = 'Your application is incomplete. Please enter missing information and submit your application again.';
                    dialogs.error(title, body, {
                        'size': 'sm'
                    }).result.then(function() {
                        scrollToErrors(validationErrors);
                    });
                } else {
                    dialogs.create('applications/dialogs/submit.html', 'SubmitDialogCtrl', $scope.licenseToSave.declarations, {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    }).result.then(function() {
                        updateLicense($scope.licenseToSave);
                        ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.licenseToSave)
                        .then(function() {
                            toastr.success('Success!', 'Application ' + $scope.licenseToSave.license + ' has been submitted.');
                            $state.go('renewals.select');
                        });
                    });
                }
            });
        };

        var submitDRCorpApplication = function() {
            var copy = _.cloneDeep($scope.license);
            copy.questions = _.map(copy.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            // copy address
            $scope.corpLicense.address = $scope.license.address;

            //copy payment/invoice info
            $scope.corpLicense.paidby = $scope.license.paidby;
            $scope.corpLicense.invoice = $scope.license.invoice;

            // copy E&Os
            copyeandos($scope.license, $scope.corpLicense);


            var copycorp = _.cloneDeep($scope.corpLicense);
            copycorp.questions = _.map(copycorp.questions, function(q) {
                return _.omit(q, 'uploader');
            });

            $q.all([ApplicationService.validateApplication($scope.roleContext.ciprid, copy), ApplicationService.validateApplication($scope.roleContext.ciprid, copycorp)])
            .then(function(res) {
                var results = res.data;
                if(hasErrors(results[0].data) || hasErrors(results[1].data)) {
                    $scope.licenseValidationErrors = _.merge(results[0].data, results[1].data);

                    var title = 'Incomplete Application';
                    var body = 'Your application is incomplete. Please enter missing information and submit your application again.';

                    dialogs.error(title, body, {'size': 'sm'}).result.then(function() {
                        scrollToErrors(_.merge(results[0].data, results[1].data));
                    });
                } else {
                    dialogs.create('applications/dialogs/submit.html', 'SubmitDialogCtrl', ($scope.licenseToSave.declarations).concat($scope.corpLicenseToSave.declarations), {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    }).result.then(function() {
                        updateLicense($scope.licenseToSave);

                        updateCorpLicense($scope.corpLicenseToSave);
                        $q.all([ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.licenseToSave), ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.corpLicenseToSave)])
                        .then(function(results) {
                            toastr.success('Success!', 'Applications ' + $scope.licenseToSave.license + ' and ' + $scope.corpLicenseToSave.license + ' has been submitted to regulator.');
                            $state.go('renewals.select');
                        });
                    });
                }


            });

        };

        function copyeandos(license, corpLicense) {
            var deleteclause = function(eando) {
                return _.has(eando, 'deleted') ? eando.deleted == true : false;
            };
            var deletedEandos = _.chain(license.eandos).filter(deleteclause).value();
            var newclause = function(eando) {
                return _.has(eando, 'new') ? eando.new == true : false;
            };
            var newEandos = _.chain(license.eandos).filter(newclause).value();

            _.forEach(deletedEandos, function(eando) {
                var e = _.find(corpLicense.eandos, {policyno: eando.policyno, carrierpartyid: eando.carrierpartyid});
                e.deleted = eando.deleted;
            });

            _.forEach(newEandos, function(eando) {
                var copyeando = _.cloneDeep(eando);
                copyeando.licenseid = $scope.corpLicense.licenseid;
                corpLicense.eandos.push(copyeando);
            });

        }

        function updateLicense(licenseToSave) {
            licenseToSave.address = $scope.license.address;

            // get the list of updated questions and declarations and empl history
            licenseToSave.questions = _.filter($scope.license.questions, function(obj) {
                return obj.answer === '' || !_.find(licenseToSave.questions, _.omit(obj, '$$hashKey'));
            });
            licenseToSave.questions = _.map(licenseToSave.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            licenseToSave.eandos = _.filter($scope.license.eandos, function(obj) {
                return !_.find(licenseToSave.eandos, _.omit(obj, '$$hashKey'));
            });
            // get other occupation question
            var otherOccupaQuestion = _.filter($scope.license.questions, {hasotheroccupation: 'Y'});
            licenseToSave.occupations = [];
            if(otherOccupaQuestion && otherOccupaQuestion[0] && otherOccupaQuestion[0].answer != 'N') {
                licenseToSave.occupations = _.filter($scope.license.occupations, {selected: true});
            }
            _.forEach(licenseToSave.occupations, function(occupation) {
                occupation.answerseq = otherOccupaQuestion[0].answerid;
            });

            licenseToSave.paidby = $scope.license.paidby;
        }

        function updateCorpLicense(corpLicenseToSave) {
            corpLicenseToSave.address = $scope.corpLicense.address;
            // get the list of updated questions and declarations and empl history
            corpLicenseToSave.questions = _.filter($scope.corpLicense.questions, function(obj) {
                return obj.answer === '' || !_.find(corpLicenseToSave.questions, _.omit(obj, '$$hashKey'));
            });
            corpLicenseToSave.questions = _.map(corpLicenseToSave.questions, function(q) {
                return _.omit(q, 'uploader');
            });

            corpLicenseToSave.eandos = _.filter($scope.corpLicense.eandos, function(obj) {
                return !_.find(corpLicenseToSave.eandos, _.omit(obj, '$$hashKey'));
            });

            // get other occupation question
            var otherOccupaQuestion = _.filter($scope.corpLicense.questions, {hasotheroccupation: 'Y'});
            corpLicenseToSave.occupations = [];
            if(otherOccupaQuestion && otherOccupaQuestion[0] && otherOccupaQuestion[0].answer != 'N') {
                corpLicenseToSave.occupations = _.filter($scope.corpLicense.occupations, {selected: true});
            }
            _.forEach(corpLicenseToSave.occupations, function(occupation) {
                occupation.answerseq = otherOccupaQuestion[0].answerid;
            });

            corpLicenseToSave.paidby = $scope.corpLicense.paidby;
        }

    }
])
.controller('RenewalQuestionsCtrl', ['$scope', '$state', '$stateParams', '$http', 'toastr', 'RenewalService', 'DownloadService',
    function($scope, $state, $stateParams, $http, toastr, RenewalService, DownloadService) {
        $scope.defaultOccupationDetails = function(occupation) {
            if(occupation.detailsrequired == 'Y') {
                RenewalService.getPrevOtherOccupaDetail($scope.roleContext.ciprid, occupation)
                .then(function(res) {
                    if(!_.isEmpty(res.data)) {
                        occupation.details = res.data[0].details;
                    }
                });
            }
        };

        /**
         * Constructs a download link.
         * @param license
         * @param question
         * @param attachment
         * @returns {string}
         */
        $scope.downloadLink = function(license, question, attachment) {
            var anchorText = attachment.displayname;
            var anchorHref = S("/{{ciprid}}/licenses/{{licenseid}}/attachments/{{filename}}?questionnum={{questionnum}}&party={{partyid}}&questionpart={{questionpart}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: license.licenseholderid,
                licenseid: license.licenseid,
                filename: attachment.displayname,
                questionnum: question.questionnum,
                questionpart: question.questionpart
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Deletes an attachment
         * @param attachment
         */
        $scope.removeAttachment = function(question, attachment) {
            var ciprid = $scope.roleContext.ciprid;
            var licenseid = $scope.license.licenseid;
            var attachmentid = attachment.attachmentid;
            $http.delete('/' + ciprid + '/licenses/' + licenseid + '/attachments/' + attachmentid)
            .then(function(res) {
                toastr.success('Success!', 'File ' + attachment.displayname + ' has been removed.');
                $scope.license.attachments[question.answerid] = _.reject($scope.license.attachments[question.answerid], {'attachmentid': attachmentid});
            });
        };
    }
])
.controller('RenewalPaymentCtrl', ['$scope', 'dialogs', 'toastr', 'RenewalService', 'ApplicationService',
    function($scope, dialogs, toastr, RenewalService, ApplicationService) {

        $scope.paidby = '';

        $scope.agentPays = function(license) {
            if(RenewalService.isWithCandidate(license)) {
                $scope.paidby = 'agent';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.licenseholdername,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.agencyPays = function(license) {
            if(RenewalService.isWithCandidate(license)) {
                $scope.paidby = 'agency';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.agencyname,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.sponsorPays = function(license) {
            if(RenewalService.isWithCandidate(license)) {
                $scope.paidby = 'sponsor';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.sponsorname,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.isAgentPaying = function() {
            return $scope.paidby == 'agent';
        };

        $scope.isAgencyPaying = function() {
            return $scope.paidby == 'agency';
        };

        $scope.isSponsorPaying = function() {
            return $scope.paidby == 'sponsor';
        };

        $scope.isInvoicedToContextUser = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerciprid == $scope.roleContext.ciprid;
        };

        $scope.isOnAgentInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.licenseholderid;
        };

        $scope.isOnAgencyInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.agencyid;
        };

        $scope.isOnSponsorInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.sponsorid;
        };

        $scope.isInvoiceQueued = function(application) {
            return application.invoice.statuscd == 'INVPRC';
        };

        function showConfirmDialog(payoptions) {
            dialogs.create('applications/dialogs/payment.html', 'PaymentDialogCtrl', payoptions, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                ApplicationService.savePaymentOption($scope.roleContext.ciprid, payoptions.licenseid)
                .then(function(data) {
                    toastr.success('Success!', 'Renewal ' + payoptions.license + ' is invoiced to ' + payoptions.name);
                    $state.go('renewals.open.view', {renewalId: payoptions.licenseid});
                });
            });
        }

    }
])

/**
 * Dialog controllers.
 */
.controller('RenewalPayWithDialogCtrl', ['$scope', '$uibModalInstance', 'data', 'InvoiceService',
    function($scope, $uibModalInstance, data, InvoiceService) {

        $scope.license = data;
        $scope.invoice = undefined;
        $scope.unpaidInvoices = [];

        /**
         * Fetch unpaid invoices and prepopulate $scope.unpaidInvoices
         */
        InvoiceService.getUnpaidInvoices(data.ciprid)
        .then(function(res) {
            $scope.unpaidInvoices.push({
                'invoiceid': 'new'
            });
            $scope.unpaidInvoices = $scope.unpaidInvoices.concat(res.data);
        });

        /**
         * If invoice is selected, application is added to invoice
         * @param item
         * @param model
         */
        $scope.addToInvoice = function(item, model) {
            $scope.invoice = model;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.save = function() {
            $uibModalInstance.close($scope.invoice);
        };
    }
])
.controller('RenewalErrorsDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.errors = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])

/**
 * Renewals services
 */
.service('RenewalService', ['$http', '$q',
    function($http, $q) {

        // ---- Utility functions ----
        this.isWithCandidate = function(license) {
            return license.appstatuscd == 'LAS_CAND_INPUT';
        };

        this.isWithRegulator = function(license) {
            return license.appstatuscd == 'LAS_REG_SUBMIT';
        };

        this.isBackToAgent = function(license) {
            return license.reviewstatuscd == 'LIC_REV_B2A';
        };

        this.isDRApp = function(license) {
            return license.drlicense == 'Y';
        };

        this.isRenewal = function(license) {
            return license.statuscd.trim() == 'KREN';
        };

        this.isDRRenewal = function(license) {
            return license.drlicense == 'Y';
        };


        this.isCorpLicenseExists = function(corpLicense) {
            return corpLicense && corpLicense.licenseid && corpLicense.licensetype === "C";
        };

        this.discard = function(ciprId, licenseid) {
            return $http({method: 'DELETE', url: '/' + ciprId + '/licenses/' + licenseid});
        };

        this.copyAddress = function(license) {
            return _.pick(license, [
                'street', 'city', 'cityid', 'provabbrev', 'province', 'provinceid', 'provabbrev',
                'postalcode', 'country', 'countryid', 'phone', 'fax', 'email'
            ]);
        };

        this.getLicenseFeeStructure = function() {
            return $http({
                url: '/lookup/license/fee/structure',
                method: 'GET',
                cache: true,
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.getRenewalDate = function() {
            return $http({
                url: '/renewalDate',
                method: 'GET',
                cache: true,
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.getRenewalAllow = function() {
            return $http({
                url: '/renewalAllow',
                method: 'GET',
                cache: true,
                headers: {'Content-Type': 'application/json'}
            });
        };

        // ---- user specific requests ----

        this.getRenewals = function(ciprId, cache) {
            return $http({
                url: '/' + ciprId + '/renewals',
                method: 'GET',
                cache: cache,
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.getSelectedRenewals = function(ciprId, renewalIds, details) {
            var deferred = $q.defer();
            deferred.resolve([]);

            return !_.isArray(renewalIds) || renewalIds.length == 0 ? deferred.promise : $http({
                url: '/' + ciprId + '/renewals/particulars',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'licenseids': renewalIds,
                    'details': details
                }
            });
        };

        this.getEandOs = function(ciprId, renewalIds) {
            return $http({
                url: '/' + ciprId + '/licenses/eandos',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                params: {
                    'licenseids[]': renewalIds
                }
            }).then(function(res) {
                return _.flatten(res.data);
            });
        };

        this.initRenewalCEStatus = function(ce, renewals) {
            var categoriesWithCEComplete = _.filter(ce, function(category) {
                return category.remainingHours == 0
            });
            _.forEach(categoriesWithCEComplete, function(ceCategory) {
                _.forEach(renewals, function(renewal) {
                    if(renewal.inscatcd == insCatMap[ceCategory.category]) {
                        renewal.requirementMet = true;
                    }
                });
            });
        };

        this.getPrevOtherOccupaDetail = function(ciprId, occupation) {
            return $http.get('/' + ciprId + '/occupation/latest', {
                params: {
                    occupatype: occupation.otheroccupationcode,
                    partyid: occupation.partyid
                }
            });
        };

        this.finish = function(ciprid, renewals) {
            return $http({
                url: '/' + ciprid + '/renewals',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {renewals: renewals}
            });
        };

        this.acknowledgement = function(ciprId, renewalIds) {
            var deferred = $q.defer();
            deferred.resolve([]);

            return !_.isArray(renewalIds) || renewalIds.length == 0 ? deferred.promise : $http({
                url: '/' + ciprId + '/renewals/done',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                params: {
                    'licenseids[]': renewalIds
                }
            });
        };

    }
])
.service('RenewalResolverService', ['$resource', '$http', 'FileUploader', 'RenewalService',
    function($resource, $http, FileUploader, RenewalService) {

        /**
         * Returns a promise which resolves a license object.
         *
         * @param ciprid
         * @param licenseid
         * @returns {*}
         */
        this.get = function(jwtToken, ciprid, licenseid) {
            return $http({
                url: '/' + ciprid + '/renewals/' + licenseid,
                method: 'GET',
                cache: true,
                headers: {'Content-Type': 'application/json'}
            })
            .then(function(res) {
                var limitFilter = {
                    name: 'limitFilter',
                    fn: function() {
                        return this.queue.length < 10;
                    }
                };
                var pdfFilter = {
                    name: 'pdfFilter',
                    fn: function(item) {
                        return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
                    }
                };

                var license = res.data.license[0];

                license.address = RenewalService.copyAddress(license);
                license.eandos = res.data.eandos;
                license.questions = res.data.questions;
                license.questions = _.map(res.data.questions, function(q, index) {
                    q.index = index + 1;
                    q.uploader = new FileUploader({
                        autoUpload: true,
                        filters: [limitFilter, pdfFilter],
                        headers: {
                            Authorization: 'Bearer ' + jwtToken
                        }
                    });
                    return q;
                });

                license.attachments = _.groupBy(res.data.attachments, 'answerid');
                license.occupations = _.uniqBy(res.data.occupations, 'otheroccupationcode');
                license.declarations = res.data.declarations.length == 0 ? [] : res.data.declarations;
                license.recommendations = res.data.recommendations;
                license.invoice = _.isArray(res.data.invoice) ? res.data.invoice[0] : res.data.invoice;
                license.corpLicense = _.isArray(res.data.corpLicense) ? res.data.corpLicense[0] : res.data.corpLicense;

                _.forEach(license.occupations, function(occupation) {
                    occupation.selected = !_.isEmpty(occupation.answerseq);
                });

                return license;
            });
        };
    }
])

/**
 * Renewals factory used by
 */
.factory('RenewalFactory', ['$http',
    function($http) {
        function getData(ciprId, callback) {
            $http({
                url: '/' + ciprId + '/renewals',
                method: 'GET',
                cache: true,
                headers: {'Content-Type': 'application/json'}
            })
            .then(callback);
        }

        return {
            list: getData,

            find: function(ciprId, name, callback) {
                getData(function(data) {
                    var country = data.filter(function(entry) {
                        return entry.name === name;
                    })[0];
                    callback(country);
                });
            }
        };
    }
])

/**
 * Renewals filters
 */
.filter('restrictedLicensesFilter', function() {
    return function(items, props) {
        return _.filter(items, function(r) {
            return r.license.licensetype == 'CO';
        });
    }
});
