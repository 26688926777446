var parties = angular.module('aic.parties', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ngclipboard',
    'ui.router',
    'angularFileUpload',
    'blockUI',
    'ui.bootstrap.tabs',
    'aic.address',
    'aic.eando',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.invoices',
    'aic.subscribe',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

/**
 * Routing and config
 */
parties.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('parties', {
            parent: 'workspace',
            abstract: true,
            url: '/parties',
            templateUrl: 'parties/parties.html'
        })
        .state('parties.search', {
            url: '/search',
            params: {
                cache: true
            },
            templateUrl: 'parties/search.html',
            controller: 'PartySearchCtrl'
        })
        .state('parties.import', {
            url: '/import',
            params: {
                cache: true
            },
            templateUrl: 'parties/import.html',
            controller: 'PartyImportCtrl'
        })
        .state('parties.link', {
            url: '/link',
            params: {
                cache: false
            },
            templateUrl: 'parties/link.html',
            controller: 'PartyLinkCtrl'
        })
        .state('parties.open', {
            url: '/{partyId:[0-9]+}',
            params: {'tab': ''},
            abstract: true,
            resolve: {
                promisedParty: ['$stateParams', 'AuthService', 'PartyResolverService',
                    function($stateParams, AuthService, PartyResolverService) {
                        var ciprId = AuthService.getRoleContext().ciprid;
                        var partyId = $stateParams.partyId;
                        var resolved = PartyResolverService.get(ciprId, partyId);
                        return resolved;
                    }
                ]
            },
            controller: 'PartyOpenCtrl',
            templateUrl: 'parties/parties.edit.html'
        });

        $stateProvider
        .state('parties.open.account', {
            url: '/account',
            templateUrl: 'staff/account/account.view.html',
            controller: 'PartyAccountCtrl'
        });

        $stateProvider
        .state('parties.open.exam', {
            url: '/exam',
            templateUrl: 'parties/exam/exam.html',
            controller: 'ExamsManageCtrl'
        });
        $stateProvider
        .state('parties.open.crimcheck', {
            url: '/crimcheck',
            templateUrl: 'crimcheck/crimcheck.summary.html',
            controller: 'CrimCheckSummaryCtrl'
        });
        $stateProvider
        .state('parties.open.employment', {
            url: '/employment',
            templateUrl: 'parties/employment/employment.history.edit.html',
            controller: 'PartyEmploymentHistoryCtrl'
        });
        $stateProvider
        .state('parties.open.license', {
            url: '/license',
            templateUrl: 'parties/licenses/licenses.list.html',
            controller: 'PartyLicenseListCtrl'
        });
        $stateProvider
        .state('parties.open.ce', {
            url: '/ce',
            templateUrl: 'ce/ce.summary.html',
            controller: 'PartyCESummaryCtrl'
        });
        $stateProvider
        .state('parties.open.cecourse', {
            url: '/cecourse',
            templateUrl: 'parties/ce/ce.courses.html',
            controller: 'PartyCECoursesCtrl'
        });
        $stateProvider
        .state('parties.open.invoice', {
            url: '/invoice',
            templateUrl: 'staff/invoices/invoices.list.html',
            controller: 'PartyInvoiceCtrl'
        });
        $stateProvider
        .state('parties.open.address', {
            url: '/address',
            templateUrl: 'parties/address/address.list.html',
            controller: 'PartyAddressCtrl'
        });
        $stateProvider
        .state('parties.open.notes', {
            url: '/notes',
            templateUrl: 'staff/notes/notes.list.html',
            controller: 'PartyNotesCtrl'
        });
        $stateProvider
        .state('parties.open.event', {
            url: '/event',
            templateUrl: 'staff/event/event.list.html',
            controller: 'PartyEventCtrl'
        });
        $stateProvider
        .state('parties.open.eplicense', {
            url: '/eplicense',
            templateUrl: 'parties/eplicenses/eplicenses.list.html',
            controller: 'PartyEPlicenseCtrl'
        });
        $stateProvider
        .state('parties.open.refund', {
            url: '/refund',
            templateUrl: 'staff/refund/refund.list.html',
            controller: 'PartyRefundCtrl'
        });
        $stateProvider
        .state('parties.open.nsf', {
            url: '/nsf',
            templateUrl: 'staff/nsf/nsf.list.html',
            controller: 'PartyNsfCtrl'
        });
        $stateProvider
        .state('parties.open.wo', {
            url: '/wo',
            templateUrl: 'staff/wo/wo.list.html',
            controller: 'PartyWoCtrl'
        });
        $stateProvider
        .state('parties.open.documents', {
            url: '/documents',
            views: {
                '': {
                    templateUrl: 'documents/documents.list.table.html',
                    controller: 'PartyDocumentCtrl'
                },
                'documents@parties.open.documents': {
                    templateUrl: 'documents/documents.list.table.html'
                }
            }
        });
        $stateProvider
        .state('parties.open.appointee', {
            url: '/appointee',
            templateUrl: 'parties/appointee/appointee.list.html',
            controller: 'PartyAppointeeCtrl'
        });
    }
])

/**
 * Staff routes controllers
 */
.controller('PartySearchCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'blockUI', 'toastr', 'PartyService',
    function($scope, $state, $stateParams, DTOptionsBuilder, blockUI, toastr, PartyService) {

        $scope.parties = PartyService.parties;
        $scope.partySearchStr = PartyService.partySearchStr;
        // var partyBlock = blockUI.instances.get('party-block');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true);

        $scope.searchParty = function() {
            $scope.loading = true;
            PartyService.setPartySearchStr($scope.partySearchStr);
            PartyService.search($scope.partySearchStr)
            .then(function(res) {
                $scope.loading = false;
                $scope.parties = res.data;
                PartyService.setParties(res.data);
            });


        };

        $scope.viewParty = function(party) {
            // partyBlock.start();
            $state.go('parties.open.account', {partyId: party.partyId});
            // partyBlock.stop();
        }

    }
])
.controller('PartyImportCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'blockUI', 'toastr', 'PartyService',
    function($scope, $state, $stateParams, DTOptionsBuilder, blockUI, toastr, PartyService) {

        var partiesBlock = blockUI.instances.get('parties-block');

        $scope.import = function() {
            $scope.parties = undefined;
            $scope.loading = true;

            var onsuccess = function(res, status) {
                $scope.loading = false;
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', res.data.error);
                } else if(res.data.length == 0) {
                    return toastr.warning('Possible duplicate party!', 'It appears the CIPR registrant with CIPR # ' + $scope.ciprId + ' is possible duplicate');
                }
                $scope.parties = res.data;
            };
            var onerror = function() {
                $scope.loading = false;
                toastr.error('Error!', 'Profile cannot be imported at this time. Please try later');
            };
            PartyService.import($scope.ciprId)
            .then(onsuccess, onerror);


        };

        $scope.viewParty = function(party) {
            $state.go('parties.open.account', {partyId: party.partyId});
        }

    }
])
.controller('PartyLinkCtrl', ['$scope', '$state', '$stateParams', '$http', 'DTOptionsBuilder', 'blockUI', 'toastr', 'SweetAlert', 'PartyService',
    function($scope, $state, $stateParams, $http, DTOptionsBuilder, blockUI, toastr, SweetAlert, PartyService) {
        $scope.parties = undefined;
        $scope.profile = undefined;
        $scope.party = undefined;
        $scope.updateRequestInProgress = false;
        $scope.ciprLinkedPartyId = undefined;
        $scope.link = function() {
            $scope.parties = undefined;
            $scope.partyLinkError = undefined;
            $scope.partyError = undefined;
            $scope.ciprError = undefined;
            let message = "Are you sure you want to link CIPR# " + $scope.ciprId + " to Party# " + $scope.partyId + " ?";
            let successmsg = "Successfully linked CIPR# " + $scope.ciprId + " to Party# " + $scope.partyId;
            if($scope.profile.firstName.toLowerCase() !== $scope.party.first_nm.toLowerCase() || $scope.profile.lastName.toLowerCase() !== $scope.party.last_nm.toLowerCase() || $scope.profile.birthDate.toLowerCase() !== $scope.party.birth_dt_fmt.toLowerCase()) {
                message = message + " Party first name, last name or birthdate is not matching with CIPR Profile. Are you sure you want to continue?"
            }
            var onsuccess = function(res, status) {
                $scope.loading = false;
                $scope.updateRequestInProgress = false;
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', res.data.error);
                }
                toastr.success('Success!', successmsg);
                $scope.parties = res.data;
            };
            var onerror = function() {
                $scope.loading = false;
                $scope.updateRequestInProgress = false;
                toastr.error('Error!', 'Unable to link CIPR to Party at this time. Please try again later');
            };


            SweetAlert.swal({
                title: "Link CIPR to Party",
                text: message,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $scope.loading = true;
                    $scope.updateRequestInProgress = true;
                    PartyService.link($scope.ciprId, $scope.partyId)
                    .then(onsuccess, onerror);
                }

            });
        };


        $scope.unlink = function(partyId, ciprId, refreshCIPRInfo) {
            $scope.partyLinkError = undefined;
            $scope.partyError = undefined;
            $scope.ciprError = undefined;
            $scope.parties = undefined;
            let message = "Are you sure you want to un-link PartyId# " + partyId + " from CIPR# " + ciprId + " ?";
            var onsuccess = function(res, status) {
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', res.data.error);
                }
                if(res.data[0]) {
                    if(refreshCIPRInfo)
                        $scope.getCIPRInfo(ciprId);
                    else {
                        $scope.party = res.data[0];
                        if($scope.party.ciprNo) {
                            $scope.partyError = 'Party already link with CIPR# ' + $scope.party.ciprNo;
                        }
                    }
                }
                toastr.success('Success!', 'Successfully un-linked Party from CIPR');

            };
            var onerror = function() {
                toastr.error('Error!', 'Unable to un-link Party from CIPR at this time. Please try again later');
            };


            SweetAlert.swal({
                title: "UnLink Party from CIPR",
                text: message,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    PartyService.unlink(partyId, ciprId)
                    .then(onsuccess, onerror);
                }

            });
        };

        $scope.getCIPRInfo = function(ciprid) {
            $scope.ciprLinkedPartyId = undefined;
            if(!ciprid) return;
            $scope.ciprError = undefined;
            $scope.profile = undefined;
            $scope.parties = undefined;
            $http.get('/lookup/user/byciprid', {
                params: {
                    ciprid: ciprid
                }
            }).then(function(res) {
                if(!res.data.profile || res.data.profile.errors)
                    $scope.ciprError = 'Invalid CIPR ID#';
                else
                    $scope.profile = res.data.profile;

            });

            PartyService.getPartyByCIPR(ciprid)
            .then(function(res) {
                if(res.data[0]) {
                    $scope.ciprLinkedPartyId = res.data[0].partyId;
                    if($scope.ciprLinkedPartyId) {
                        $scope.ciprError = 'CIPR already link with Party# ' + $scope.ciprLinkedPartyId;
                    }
                }

            });
        }

        $scope.getParty = function(partyId) {
            if(!partyId) return;
            $scope.party = undefined;
            $scope.partyError = undefined;
            $scope.parties = undefined;
            PartyService.getPartyByIdPersonActive(partyId)
            .then(function(res) {
                if(res.data[0]) {
                    $scope.party = res.data[0];
                    if($scope.party.ciprNo) {
                        $scope.partyError = 'Party already link with CIPR# ' + $scope.party.ciprNo;
                    }
                } else
                    $scope.partyError = 'Invalid PartyId#';

            });
        }
        $scope.reset = function() {
            $scope.partyLinkError = undefined;
            $scope.partyError = undefined;
            $scope.ciprError = undefined;
            $scope.partyId = undefined;
            $scope.ciprId = undefined;
            $scope.profile = undefined;
            $scope.party = undefined;
            $scope.parties = undefined;
            $scope.alerts = [];
            $scope.ciprLinkedPartyId = undefined;
            $scope.partyLinkForm.$setPristine();
        };

        $scope.viewParty = function(party) {
            $state.go('parties.open.account', {partyId: party.partyId});
        }

    }
])
.controller('PartyOpenCtrl', ['$scope', '$state', '$stateParams', '$location', '$previousState', 'promisedParty', 'blockUI', 'FileUploader', 'PartyService', 'CrimCheckService',
    function($scope, $state, $stateParams, $location, $previousState, promisedParty, blockUI, FileUploader, PartyService, CrimCheckService) {

        $scope.party = promisedParty;
        $scope.yesNoTypes = [{"type": "Y", "description": "Yes"}, {"type": 'N', "description": "No"}];
        $scope.profileRoles = [{"type": "AGENT", "description": "Agent"},
            {"type": "ADJUSTER", "description": "Adjuster"},
            {"type": "APPOINTEE", "description": "Appointee"},
            {"type": "CE_PROVIDER", "description": "CE Provider"},
            {"type": "CORP_DI", "description": "Corporate DI"}];

        $scope.can = {
            employment: {
                view: true,
                edit: $scope.isStaff()
            }
        };

        $scope.goPrevious = function() {
            $previousState.go();
        };

        $scope.selectedProfileRoleTypes = _.map($scope.party.profileRoles, 'type');
        $scope.isSelectedProfileRole = function(typeId) {
            return $scope.selectedProfileRoleTypes.indexOf(typeId) >= 0;
        };

        $scope.updateProfileRole = function($event, type) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                PartyService.addPersonRole($scope.party.partyid, type)
                .then(function(res) {
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', "Error during adding Role ");
                    }
                    $scope.selectedProfileRoleTypes.push(type);
                });
                return;
            }

            let selectedProfileRole = _.find($scope.profileRoles, function(pr) {
                return pr.type == type
            });
            if(!checkbox.checked && selectedProfileRole) {
                PartyService.removePersonRole($scope.party.partyid, type)
                .then(function(res) {
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', "Error during removing role");
                    }
                    $scope.selectedProfileRoleTypes = _.reject($scope.selectedProfileRoleTypes, function(typ) {
                        return typ == type
                    });
                });
            }


        };

        $scope.getNonResident = function() {
            return _.find($scope.yesNoTypes, {type: $scope.party.stkInfo.nonResident}).description;
        };

        $scope.getOnlineExamAllowed = function() {
            return _.find($scope.yesNoTypes, {type: $scope.party.stkInfo.onlineExamAllow}).description;
        };

        $scope.getInPersonExamAllowed = function() {
            return _.find($scope.yesNoTypes, {type: $scope.party.stkInfo.inPersonExamAllow}).description;
        };

        $scope.editProfile = function() {
            $scope.profileRoleEditing = true;
        };

        $scope.viewProfile = function() {
            $scope.profileRoleEditing = false;
        };

        $scope.updateParty = function() {
            $scope.updatePartyErrors = undefined;
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.updatePartyErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    toastr.error('Error!', 'Profile cannot be updated at this time.');
                    $scope.updateRequestInProgress = false;
                } else {
                    $scope.updateRequestInProgress = false;
                    $scope.party.status = _.find($scope.party.statuses, {id: $scope.party.statusId}).description;
                    toastr.success('Success!', 'Profile successfully updated.');
                    $scope.profileRoleEditing = false;
                }

            };
            var onerror = function() {
                $scope.updateRequestInProgress = false;
                toastr.error('Error!', 'Profile cannot be updated at this time.');
            };
            $scope.updateRequestInProgress = true;
            PartyService.update($scope.party)
            .then(onsuccess, onerror);
        };

        $scope.validInactiveStatus = true;
        $scope.validStopStatus = true;
        $scope.isValidStatus = function() {
            if($scope.party.statusId.trim() === 'SINA' && $scope.party.licenses.licenseCount > 0)
                $scope.validInactiveStatus = false;
            else
                $scope.validInactiveStatus = true;
            if($scope.party.statusId.trim() === 'SSTP' && $scope.party.stkInfo.cmsInvestigator == "")
                $scope.validStopStatus = false;
            else
                $scope.validStopStatus = true;
        };

        $scope.go = function(state) {
            $state.go(state, {partyId: $scope.party.partyid});
        };

    }
])
.controller('PartyEditCtrl', ['$scope', '$state', '$stateParams', '$location', '$anchorScroll', '$timeout', 'dialogs', 'toastr', 'blockUI',
    function($scope, $state, $stateParams, $location, $anchorScroll, $timeout, dialogs, toastr, blockUI) {
    }
])
.controller('PartyAccountCtrl', ['$scope', '$http', '$state', '$stateParams', '$location', '$anchorScroll', '$timeout', 'dialogs', 'toastr', 'PartyService',
    function($scope, $http, $state, $stateParams, $location, $anchorScroll, $timeout, dialogs, toastr, PartyService) {

        self.party = $scope.party;

        $http.get('/' + $scope.roleContext.ciprid + '/account/types/all', {cache: true})
        .then(function(res) {
            $scope.accountTypes = res.data;
        });


        $scope.accountsByAccountSummaryType = function(label) {
            return _.filter($scope.party.accounts, {'label': label})
        };

        $scope.countsOfNegativeBalanceAccountsByAccountSummaryType = function(label) {
            return _.filter($scope.party.accounts, function(account) {
                return account.label == label && parseFloat(account.balance) < 0;
            }).length
        };

        $scope.balanceByAccountSummaryType = function(label) {
            return _.sumBy(_.filter($scope.party.accounts, {'label': label}), function(account) {
                return parseFloat(account.balance)
            });
        };

        $scope.accountSummaryTypes = _.map(_.uniq(_.map($scope.party.accounts, 'label')), function(accountSummaryType) {
            return {
                label: accountSummaryType,
                collapsed: true,
                balance: $scope.balanceByAccountSummaryType(accountSummaryType)
            }
        });

        $scope.getAccountTrans = function(accntCD) {
            $scope.totalDebits = undefined;
            $scope.totalCredits = undefined;
            $scope.totalBalance = undefined;
            $scope.selectedAccnt = accntCD;
            $scope.accountTrans = undefined;
            $scope.loading = true;
            PartyService.getAccountTrans(accntCD, $scope.party.partyid)
            .then(function(res) {
                $scope.loading = false;
                $scope.accountTrans = res.data;
                $scope.totalDebits = _.sumBy(_.filter($scope.accountTrans, {'db_accnt_cd': accntCD.trim()}), function(trans) {
                    return parseFloat(trans.amt)
                });
                $scope.totalCredits = _.sumBy(_.filter($scope.accountTrans, {'cr_accnt_cd': accntCD.trim()}), function(trans) {
                    return parseFloat(trans.amt)
                });
                $scope.totalBalance = $scope.totalDebits - $scope.totalCredits;
            });
        };

        $scope.getAccountDescription = function(accntCD) {
            var account = _.find($scope.accountTypes, function(accnt) {
                return accnt.id.trim() == accntCD.trim()
            });
            return (account) ? account.description : "";
        };

    }
])
.controller('ExamsManageCtrl', ['$scope', '$state', '$stateParams', '$filter', '$http', 'toastr', 'dialogs', 'SweetAlert', 'blockUI', 'DTOptionsBuilder', 'PartyService', 'ExamService', 'InvoiceService', 'ExamRegisterService', 'DownloadService',
    function($scope, $state, $stateParams, $filter, $http, toastr, dialogs, SweetAlert, blockUI, DTOptionsBuilder, PartyService, ExamService, InvoiceService, ExamRegisterService, DownloadService) {
        self.party = $scope.party;
        $scope.showAllExams = false;
        $scope.examResults = [];
        $scope.getExams = function(partyId) {
            var examBlock = blockUI.instances.get('exam-block');
            examBlock.start();
            PartyService.getExams(partyId, $scope.showAllExams)
            .then(function(res) {
                examBlock.stop();
                $scope.examResults = res.data;
            });

        };


        /* Initializer */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('scrollX', true)
        .withOption('scrollCollapse', true)
        .withOption('scroller', true)
        .withOption('order', [0, 'desc']);

        $scope.getExams(self.party.partyid);


        ExamService.getProviders($scope.roleContext.ciprid).then(function(res) {
            $scope.providers = res.data;
        });


        ExamService.getAllExamVersions($scope.roleContext.ciprid).then(function(res) {
            $scope.versions = res.data;
        });

        ExamService.getExamStatuses($scope.roleContext.ciprid).then(function(res) {
            $scope.examstatuses = res.data;
        });


        ExamService.getReScheduledHours($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.rescheduleHours = res.data.rescheduleHours;
        });

        $scope.manage = function(scheduleId) {
            $state.go('register.manage', {scheduleId: scheduleId});
        };

        $scope.canReschedule = function(examdate, examtime) {
            var examDateTime = moment(examdate + 'T' + examtime);
            var now = moment();
            var hoursToExam = examDateTime.diff(now, 'hours');
            if(hoursToExam <= $scope.rescheduleHours) {
                return false;
            }
            return true;
        };


        $scope.notWritten = function(exam) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Exam marked as unwritten.');
                $scope.examResults = _.reject($scope.examResults, {'id': exam.id});
                exam.status = _.find($scope.examstatuses, {'id': data[0].statusid}).description;
                exam.scnrio_cd = data[0].statusid;
                exam.gooduntil = data[0].gooduntil;
                exam.c_current = data[0].c_current;
                $scope.examResults.push(exam);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to marked as unwritten.');
            };

            ExamService.notWritten($scope.roleContext.ciprid, exam.id).then(onsuccess, onerror);

        };

        $scope.remove = function(exam) {

            var onsuccess = function(res) {
                toastr.success('Success!', 'Exam Registration has been voided.');
                $scope.examResults = _.reject($scope.examResults, {'id': exam.id});
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Exam Registration cannot be able to deleted.');
            };

            SweetAlert.swal({
                title: "Void Registration",
                text: "Are you sure you want to void exam registration?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    ExamService.remove($scope.roleContext.ciprid, exam.id).then(onsuccess, onerror);
                }

            });

        };

        $scope.refund = function(exam) {

            var onsuccess = function(res) {
                var data = res.data;
                toastr.success('Success!', 'Exam Fee has been successfully returned to money on account.');
                $scope.examResults = _.reject($scope.examResults, {'id': exam.id});
                exam.feepaidbalance = data[0].feepaidbalance;
                $scope.examResults.push(exam);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Exam fee cannot be returned at this time.');
            };
            var data = {
                title: 'Return Examination fee for ' + exam.c_party_Nm,
                registeruser: exam
            };

            dialogs.create('exams/dialogs/refundreason.html', 'RefundReasonDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(reason) {
                ExamService.refund($scope.roleContext.ciprid, exam.id, reason).then(onsuccess, onerror);
            });


        };


        $scope.reschedule = function(exam) {
            ExamRegisterService.setExamLoc({"description": exam.exmloc_desc, "id": exam.exmloc_cd.trim()});
            ExamRegisterService.setExamType({"description": exam.exmtyp_desc, "id": exam.exmtyp_cd});
            var onsuccess = function(res) {
                var data = res.data;
                if(data.error) {
                    var errorval = typeof data.error === 'object' ? 'Error during exam reschedule' : data.error;
                    toastr.error('Error!', errorval);
                } else {
                    toastr.success('Success!', 'Exam Reschedule success');
                    $scope.getExams(self.party.partyid);
                }
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Exam Rescheduled failed.');
            };


            var rescheduleAllowed = $scope.canReschedule(exam.c_exam_dt, exam.start_tm_format);


            if(!rescheduleAllowed) {
                swal("Can't reschedule exam within " + $scope.rescheduleHours + " hour(s) to exam")
                return;
            }

            var data = {
                ciprid: $scope.roleContext.ciprid,
                regid: exam.id,
                examType: exam.exmtyp_cd,
                examLoc: exam.exmloc_cd
            };
            dialogs.create('exams/dialogs/reschedule.html', 'ExamRescheduleDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(data) {
                ExamService.reschedule($scope.roleContext.ciprid, data.regid, data.scheduleid).then(onsuccess, onerror);
            });


        };


        $scope.viewExam = function(exam) {
            $state.go('exams.view', {examId: exam.id, showDialog: false});
        };


        $scope.versionsByExam = function(exmtyp) {
            return _.filter($scope.versions, {
                exmtyp: exmtyp
            });
        };

        $scope.providersByType = function(exam) {
            if(exam.life === 'Y')
                return _.filter($scope.providers, {life: 'Y'});
            else if(exam.a_s === 'Y')
                return _.filter($scope.providers, {A_S: 'Y'});
            else if(exam.gen == 'Y')
                return _.filter($scope.providers, {GEN: 'Y'});
            else if(exam.adj == 'Y')
                return _.filter($scope.providers, {ADJ: 'Y'});
            else
                return [];
        };

        $scope.update = function(exam) {

            exam.providername = exam.provid ? _.find($scope.providers, {'provid': exam.provid}).providername : '';


            var onsuccess = function(res) {
                toastr.success('Success!', 'Exam Registration has been updated.');
                $scope.examResults = _.reject($scope.examResults, {'id': exam.id});
                $scope.examResults.push(exam);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Exam Registration cannot be able to updated.');
            };

            ExamService.update($scope.roleContext.ciprid, exam.id, exam.provid).then(onsuccess, onerror);
        };

        $scope.score = function(exam) {
            var onsuccess = function(res) {
                var data = res.data;
                toastr.success('Success!', 'Exam successfully scored.');
                $scope.examResults = _.reject($scope.examResults, {'id': exam.id});
                exam.status = _.find($scope.examstatuses, {'id': data[0].statusid}).description;
                exam.scnrio_cd = data[0].statusid;
                exam.gooduntil = data[0].gooduntil;
                exam.c_current = data[0].c_current;
                $scope.examResults.push(exam);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to scored exam.');
            };

            ExamService.score($scope.roleContext.ciprid, exam.id, exam.score, exam.versionid).then(onsuccess, onerror);
        };

        /**
         * Constructs a download reg link
         * @returns {string}
         */
        $scope.downloadRegLink = function(exam) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + exam.id + "/registrationtopdf?ciprid=" + exam.ciprid;
            var anchorText = S('registration_{{examId}}.pdf').template({examId: exam.id}).s;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Constructs a download dec link
         * @returns {string}
         */
        $scope.downloadDecLink = function(exam) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + exam.id + "/declarationtopdf?ciprid=" + exam.ciprid;
            var anchorText = S('declaration_{{examId}}.pdf').template({examId: exam.id}).s;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Constructs a download waiver link
         * @returns {string}
         */
        $scope.downloadWaiverLink = function(exam) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + exam.id + "/waivertopdf?ciprid=" + exam.ciprid;
            var anchorText = S('waiver_{{examId}}.pdf').template({examId: exam.id}).s;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };


        /**
         * Add to shopping cart.
         */
        $scope.addToShoppingCart = function(exam) {
            var e = {regid: exam.id, fee: exam.exam_fee};
            InvoiceService.addExamsToShoppingCart($scope.roleContext.ciprid, [e], self.party.partyid)
            .then(function() {
                toastr.success('Success!', exam.id + ' added to Invoice');
                $scope.getExams(self.party.partyid);
            }, function() {
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };


        $scope.getAllExams = function($event) {
            var checkbox = $event.target;
            $scope.showAllExams = checkbox.checked;
            var examBlock = blockUI.instances.get('exam-block');
            examBlock.start();
            PartyService.getExams(self.party.partyid, $scope.showAllExams)
            .then(function(res) {
                examBlock.stop();
                $scope.examResults = res.data;
            });

        };

    }
])
.controller('CrimCheckSummaryCtrl', ['$scope', '$state', '$stateParams', '$http', '$q', 'SweetAlert', 'dialogs', 'FileUploader', 'CrimCheckService', 'DownloadService',
    function($scope, $state, $stateParams, $http, $q, SweetAlert, dialogs, FileUploader, CrimCheckService, DownloadService) {
        var self = this;

        self.party = $scope.party;
        $scope.crimchecks = [];
        //$scope.loading = true;
        $scope.types = [{id: 'BACKCHECK', description: 'Back Check'}, {
            id: 'MANUAL',
            description: 'Police Security Clearance'
        }];
        $scope.newCrimCheckForm = false;
        $scope.showAllCrimChecks = false;

        var limitFilter = {
            name: 'limitFilter',
            fn: function() {
                return this.queue.length < 10;
            }
        };
        var pdfFilter = {
            name: 'pdfFilter',
            fn: function(item) {
                return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
            }
        };


        var setFileUploader = function() {
            $scope.crimchecks = _.map($scope.crimchecks, function(cc, index) {
                cc.uploader = new FileUploader({
                    autoUpload: true,
                    filters: [limitFilter, pdfFilter],
                    headers: {
                        Authorization: 'Bearer ' + $scope.jwtToken
                    },
                    onSuccessItem: function(item, response, status, headers) {
                        toastr.success('Success!', 'Successfully uploaded file ' + item.file.name);
                        cc.displayname = item.file.name;
                    },
                    onErrorItem: function(item, response, status, headers) {
                        toastr.error('Error!', 'Error uploading File ' + item.file.name);
                    }
                });

                return cc;
            });
        };

        $scope.getAllCrimChecks = function($event) {
            var checkbox = $event.target;
            $scope.showAllCrimChecks = checkbox.checked;
            CrimCheckService.getCrimChecks($scope.roleContext.ciprid, $scope.showAllCrimChecks, self.party.partyid)
            .then(function(res) {
                $scope.crimchecks = res.data || [];
                $scope.loading = false;
                setFileUploader();
            });
        };

        $scope.getCrimChecks = function() {
            CrimCheckService.getCrimChecks($scope.roleContext.ciprid, $scope.showAllCrimChecks, self.party.partyid)
            .then(function(res) {
                $scope.crimchecks = res.data || [];
                $scope.loading = false;
                setFileUploader();
            });
        };

        $q.all([CrimCheckService.getCrimChecks($scope.roleContext.ciprid, $scope.showAllCrimChecks, self.party.partyid), CrimCheckService.getDaysToExpire($scope.roleContext.ciprid)]).then(function(results) {
            $scope.crimchecks = results[0].data || [];
            $scope.daystoexpire = results[1].data.daystoexpire || 90;
            $scope.loading = false;
            setFileUploader();
        });

        addAlert = function(msg) {
            $scope.alerts.push({type: 'danger', msg: msg});
        };

        $scope.getCCTypeDescription = function(type) {
            return _.filter($scope.types, {id: type})[0].description
        };

        $scope.save = function(cctype) {
            $scope.alerts = [];

            var onsuccess = function(res) {
                var data = res.data;
                if(data.error) {
                    toastr.error('Error!', 'Unable to save Crim check record at this time.');
                } else {
                    $scope.newCrimCheckForm = false;
                    var crimcheck = data[0];
                    if(crimcheck.type == 'MANUAL') {
                        $scope.upload(crimcheck);
                    } else
                        $scope.getCrimChecks();
                    toastr.success('Success!', 'Successfully saved Criminal Record Check.');
                }
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to save Crim check record at this time.');
            };


            CrimCheckService.save($scope.roleContext.ciprid, cctype.id, self.party.partyid).then(onsuccess, onerror);
        };

        // Iniatlize uploader with autoupload false when they select police security clearance
        $scope.uploader = new FileUploader({
            autoUpload: false,
            filters: [limitFilter, pdfFilter],
            headers: {
                Authorization: 'Bearer ' + $scope.jwtToken
            },
            onSuccessItem: function(item, response, status, headers) {
                toastr.success('Success!', 'Successfully uploaded file ' + item.file.name);
                $('#uploaderId').val("");
                $scope.getCrimChecks();
            },
            onErrorItem: function(item, response, status, headers) {
                toastr.error('Error!', 'Error uploading File ' + item.file.name);
            }
        });

        // upload file and set the url with crimcheckid
        $scope.upload = function(crimcheck) {
            var items = $scope.uploader.getNotUploadedItems();
            _.map(items, function(item, index) {
                item.url = '/' + $scope.roleContext.ciprid + '/crimcheck/' + crimcheck.id + '/upload?lholderid=' + crimcheck.partyid;
            });
            $scope.uploader.uploadAll();
        };

        // void crimcheck record
        $scope.remove = function(cc) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Criminal record check has been voided.');
                $scope.crimchecks = _.reject($scope.crimchecks, {'id': cc.id});

            };
            var onerror = function(res) {
                toastr.error('Error!', 'Error voiding criminal record check');
            };

            SweetAlert.swal({
                title: "Void Criminal Record Check",
                text: "Are you sure you want to void criminal record check?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    CrimCheckService.remove($scope.roleContext.ciprid, cc.id).then(onsuccess, onerror);
                }
            });
        };

        // trash crimcheck record  delete record and file
        $scope.trash = function(cc) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Criminal record check has been deleted.');
                $scope.crimchecks = _.reject($scope.crimchecks, {'id': cc.id});

            };
            var onerror = function(res) {
                toastr.error('Error!', 'Error deleting criminal record check');
            };

            SweetAlert.swal({
                title: "Delete Criminal Record Check",
                text: "Are you sure you want to delete criminal record check and file associated with it?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    CrimCheckService.trash($scope.roleContext.ciprid, cc.id).then(onsuccess, onerror);
                }
            });
        };


        // set crimcheck record not valid
        $scope.notOk = function(cc) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Criminal record check has been marked Not Ok.');
                $scope.getCrimChecks();
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to save changes at this time');
            };

            SweetAlert.swal({
                title: "Not Ok Criminal Record Check",
                text: "Are you sure you want to mark not ok criminal record check?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    CrimCheckService.notOk($scope.roleContext.ciprid, cc.id).then(onsuccess, onerror);
                }

            });
        };

        // accept crimcheck record
        $scope.ok = function(cc) {

            var onsuccess = function(res) {
                toastr.success('Success!', 'Criminal Record Check has been successfully marked Ok.');
                $scope.getCrimChecks();
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to save changes at this time.');
            };
            var data = {
                daystoexpire: $scope.daystoexpire,
                expirydate: cc.expiry_dt,
                title: 'Marked Okay Criminal Record Check for ' + self.party.party_nm
            };

            dialogs.create('crimcheck/dialogs/okconfirm.html', 'OkConfirmDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(expirydate) {
                cc.expiry_dt = expirydate;
                CrimCheckService.ok($scope.roleContext.ciprid, cc.id, cc.expiry_dt).then(onsuccess, onerror);
            });


        };

        /**
         * Constructs a download link
         * @param crimcheck
         * @returns {string}
         */
        $scope.downloadLink = function(crimcheck) {
            var anchorText = crimcheck.displayname;
            var anchorHref = S("/{{ciprid}}/crimcheck/{{crimcheckid}}/download/{{filename}}?party={{partyid}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: crimcheck.partyid,
                crimcheckid: crimcheck.id,
                filename: crimcheck.displayname
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

    }
])
.controller('OkConfirmDialogCtrl', ['$scope', '$uibModalInstance', '$http', '$filter', 'data',
    function($scope, $uibModalInstance, $http, $filter, data) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.args = data;

        $scope.expiryDate = data.expirydate ? moment(data.expirydate, 'YYYY-MM-DD').format('MMMM DD, YYYY') : moment().add(data.daystoexpire, 'd').format('MMMM DD, YYYY');


        $scope.setExpiryDate = function(selectedDate) {
            $scope.expiryDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };
        /**
         * When 'OK' is clicked.
         */
        $scope.confirm = function() {
            $uibModalInstance.close($scope.expiryDate);
        };

        /**
         * When 'Cancel' is clicked.
         */
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])
.controller('PartyLicenseListCtrl', ['$scope', '$state', '$http', 'dialogs', 'DTOptionsBuilder', 'SweetAlert', 'blockUI', 'LicenseService', 'PartyService', 'ApplicationService', 'ApplService', 'DownloadService', 'RenewalService', 'TerminationLetterPrintService',
    function($scope, $state, $http, dialogs, DTOptionsBuilder, SweetAlert, blockUI, LicenseService, PartyService, ApplicationService, ApplService, DownloadService, RenewalService, TerminationLetterPrintService) {
        var self = this;

        self.party = $scope.party;
        $scope.licenses = [];
        $scope.activeLicenses = [];
        $scope.selectedLicenseIds = [];
        $scope.renewalPeriodNotStarted = true;
        var partyLicensesListBlock = blockUI.instances.get('party-licenses-list-block');
        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        RenewalService.getRenewalAllow()
        .then(function(res) {

            if(res.data[0].renewalallow === 'Y') {
                $scope.renewalPeriodNotStarted = false;
            }
        });
        /**
         * Load licenses
         */
        var init = function() {
            $scope.loading = true;
            $scope.showHistory = false;
            PartyService.getLicenses(self.party.partyid)
            .then(function(res) {
                $scope.licenses = res.data;
                $scope.activeLicenses = _.filter($scope.licenses, function(license) {
                    if(license.statuscd.trim() == 'KISS')
                        return license
                });
                $scope.party.licenses.licenseCount = $scope.activeLicenses.length;
                $scope.loading = false;
            });
        };

        init();

        // -- Called from the form --

        $scope.getAllLicenses = function($event) {
            var checkbox = $event.target;
            $scope.loading = true;
            if(checkbox.checked) {
                $scope.showHistory = true;
                PartyService.getLicensesHistory(self.party.partyid)
                .then(function(res) {
                    $scope.licenses = res.data;
                    $scope.loading = false;
                });
            }

            if(!checkbox.checked) {
                $scope.showHistory = false;
                PartyService.getLicenses(self.party.partyid)
                .then(function(res) {
                    $scope.licenses = res.data;
                    $scope.loading = false;
                });
            }
        };

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.licenses, function(license) {
                    if(license.statuscd.trim() != 'KSUS')
                        $scope.selectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            if(containsOtherStatus())
                sweetAlert("Error", "You can't add E&O Policies on suspended licenses!", "error");
            else
                $state.go('licenses.eando', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        $scope.viewLicense = function(license) {
            $state.go('licenses.view', {licenseType: license.licensetype, licenseId: license.licenseid});
        };

        $scope.viewApplication = function(license) {
            if(license.statuscd.trim() == 'KREN') return $scope.viewRenewal(license);
            if(!ApplicationService.isOnlineApp(license) && (ApplicationService.isIncomplete(license) || ApplicationService.isWithRegulator(license)))
                $state.go('appl.open.edit', {appId: license.licenseid});
            else
                $state.go('appl.open.view', {appId: license.licenseid});
        };

        $scope.viewApplicationSnapshot = function(license) {
            $state.go('appl.snapshot.view', {appId: license.licenseid});
        };

        $scope.viewRenewal = function(license) {
            $state.go('renewals.open.view', {renewalId: license.licenseid});
        };

        var containsOtherStatus = function() {
            var fLicenses = _.filter($scope.licenses, function(license) {
                return _.includes($scope.selectedLicenseIds, license.licenseid);
            });
            return _.some(fLicenses, {'status': "License Suspended"});
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            if(containsOtherStatus())
                sweetAlert("Error", "You can't update address on suspended licenses!", "error");
            else
                $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        /**
         * Constructs a download license history
         * @returns {string}
         */
        $scope.exportHistory = function() {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/licenses/history?lholderid=" + self.party.partyid;
            var anchorText = 'license history.pdf';
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        $scope.applicationInit = function() {
            $state.go('appl.init', {partyId: self.party.partyid});
        };


        /**
         * Presents an suspend dialog.
         */
        $scope.suspend = function() {
            $scope.suspendErrors = undefined;
            var data = {
                activeLicenses: $scope.activeLicenses,
                party: $scope.party
            };
            dialogs.create('parties/licenses/dialogs/suspend.html', 'PartyLicenseSuspendDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'xlg'
            }).result.then(function(response) {
                var onsuccess = function(res) {
                    partyLicensesListBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.suspendErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Licenses cannot be suspended at this time. Please try later');
                    }
                    toastr.success('Success!', 'Licenses has been suspended.');
                    init();
                    if(response.terminationLetter)
                        generateTerminationLetters(response.licenseIds);
                };
                var onerror = function(res) {
                    partyLicensesListBlock.stop();
                    toastr.error('Error!', 'Licenses cannot be suspended at this time. Please try later');
                };
                partyLicensesListBlock.start("Suspending Licenses...");
                ApplService.suspendLicenses(response.licenseIds, response.reasonId).then(onsuccess, onerror);
            });
        };

        var generateTerminationLetters = function(licenseIds) {
            var onsuccess = function(res) {
                partyLicensesListBlock.stop();
                var data = res.data;
                if(!_.isEmpty(res.data.error)) {
                    $scope.suspendErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Unable to generate termination letters');
                }
                buildTerminationLetterPages(data);
            };
            var onerror = function(res) {
                partyLicensesListBlock.stop();
                toastr.error('Error!', 'Unable to generate termination letters');
            };
            partyLicensesListBlock.start("Generating Termination Letters...");
            ApplService.terminationLetters(licenseIds).then(onsuccess, onerror);
        }

        var buildTerminationLetterPages = function(data) {
            var document = {};
            document.content = [];
            document.info = {title: "Termination Letter"};
            document.styles = TerminationLetterPrintService.getStyles();
            if(data.agent.length > 0)
                document = TerminationLetterPrintService.generateAgentLicenseTerminationPages(document, data.agent);
            if(data.agency.length > 0)
                document = TerminationLetterPrintService.generateAgencyLicenseTerminationPages(document, data.agency);
            if(data.sponsor.length > 0)
                document = TerminationLetterPrintService.generateSponsorLicenseTerminationPages(document, data.sponsor);
            if(data.adjuster.length > 0)
                document = TerminationLetterPrintService.generateAdjLicenseTerminationPages(document, data.adjuster);
            if(data.adjAgency.length > 0)
                document = TerminationLetterPrintService.generateAdjAgencyLicenseTerminationPages(document, data.adjAgency);
            var win = window.open('', '_blank');
            pdfMake.createPdf(document).open({}, win);
        }


        /**
         * Presents an reverse dialog.
         */
        $scope.reverse = function(license) {
            $scope.reverseErrors = undefined;
            var data = {
                license: license
            };
            dialogs.create('parties/licenses/dialogs/reverse.html', 'PartyLicenseReverseDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'xlg'
            }).result.then(function(reason) {
                partyLicensesListBlock.start("Reversing License...");
                ApplService.checkReverse(license.licenseid, reason)
                .then(function(res) {
                    if(!_.isEmpty(res.data)) {
                        partyLicensesListBlock.stop();
                        return toastr.error('Error!', res.data[0].error_msg);
                    }
                    var onsuccess = function(res) {
                        partyLicensesListBlock.stop();

                        if(!_.isEmpty(res.data.error)) {
                            return toastr.error('Error!', 'License cannot be reversed at this time. Please try later');
                        }
                        toastr.success('Success!', 'License has been reversed.');
                        init();
                    };
                    var onerror = function(res) {
                        partyLicensesListBlock.stop();
                        toastr.error('Error!', 'License cannot be reversed at this time. Please try later');
                    };

                    ApplService.reverseLicense(license.licenseid, reason).then(onsuccess, onerror);
                });
            });
        };

        /**
         * Presents an generate renewal dialog.
         */
        $scope.generateRenewal = function() {
            $scope.renewalErrors = undefined;
            var data = {
                partyid: $scope.party.partyid
            };
            dialogs.create('staff/renewals/dialogs/generate.renewal.html', 'PartyLicenseGenerateRenewalDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'xlg'
            }).result.then(function(response) {
                init();
            });
        };


    }
])
.controller('PartyInvoiceCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'PartyService', 'InvoiceService',
    function($scope, $state, $http, DTOptionsBuilder, PartyService, InvoiceService) {
        var self = this;
        self.party = $scope.party;
        $scope.loading = true;
        $scope.invoices = [];
        $scope.allInvoices = [];
        $scope.showHistory = false;
        $scope.displayInvoices = undefined;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc']);

        $scope.isPaid = function(invoice) {
            return parseFloat(invoice.balance) == 0;
        };

        $scope.viewInvoice = function(invoice) {
            $state.go('payments.view', {invoiceId: invoice.invoiceid});
        };

        $scope.updateList = function() {
            $scope.invoices = _.filter($scope.allInvoices, function(invoice) {
                if(invoice.inv_status_cd.trim() !== 'INVVOD')
                    return invoice
            });
            $scope.displayInvoices = $scope.showHistory ? $scope.allInvoices : $scope.invoices;
        };

        PartyService.getInvoices(self.party.partyid)
        .then(function(res) {
            $scope.loading = false;
            $scope.allInvoices = res.data;
            $scope.updateList();
        });

        $scope.toggleList = function($event) {
            var checkbox = $event.target;
            $scope.showHistory = checkbox.checked;
            $scope.updateList();
        };

        $scope.isInvoiceDue = function(invoice) {
            return invoice.inv_status_cd === 'INVOPN'
        };

        $scope.isInvoicePaid = function(invoice) {
            return invoice.inv_status_cd === 'INVPAD'
        };

        $scope.isInvoiceProcessing = function(invoice) {
            return invoice.inv_status_cd === 'INVPRC'
        };

        $scope.isInvoiceVoided = function(invoice) {
            return invoice.inv_status_cd === 'INVVOD'
        };

        $scope.canDelete = function(invoice) {
            return !invoice.item_cnt || invoice.item_cnt == 0;
        };

        /**
         * Delete invoice.
         * @param invoiceId
         */
        $scope.deleteInvoice = function(invoiceId) {
            InvoiceService.deleteInvoice($scope.roleContext.ciprid, invoiceId)
            .then(function(data, status) {
                $scope.displayInvoices = undefined;
                $scope.loading = true;
                toastr.success('Success!', 'Invoice has been deleted.');
                PartyService.getInvoices(self.party.partyid)
                .then(function(res) {
                    $scope.loading = false;
                    $scope.allInvoices = res.data;
                    $scope.updateList();
                });
            }, function(data, status) {
                toastr.error('Error!', 'Invoice cannot be deleted at this time.');
            });
        };

    }
])
.controller('PartyEmploymentHistoryCtrl', ['$scope', '$http', '$resource', '$filter', 'EmploymentService',
    function($scope, $http, $resource, $filter, EmploymentService) {
        var self = this;

        self.party = $scope.party;
        $scope.employment = {};
        $scope.employment.employed = 'Y';
        $scope.employment.employer = '';
        $scope.employment.positionheld = '';
        $scope.employment.fromdate = '';
        $scope.employment.todate = '';
        $scope.dateFormat = 'MM dd, yyyy';

        $scope.employmentForm = false;

        $scope.remove = function(employment) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Employment record has been voided.');
                $scope.party.employmentHistory = _.reject($scope.party.employmentHistory, {'partyemploymentid': employment.partyemploymentid});

            };
            var onerror = function(res) {
                toastr.error('Error!', 'Error voiding employment record');
            };

            EmploymentService.remove($scope.roleContext.ciprid, employment.partyemploymentid).then(onsuccess, onerror);
        };

        $scope.save = function() {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Employment record has been saved.');
                $scope.hideEmploymentForm();
                EmploymentService.get($scope.roleContext.ciprid, self.party.partyid)
                .then(function(res) {
                    $scope.party.employmentHistory = res.data || [];
                });

            };
            var onerror = function(res) {
                toastr.error('Error!', 'Error saving employment record');
            };

            $scope.employment.fromdate = $filter('date')($scope.employment.fromdate, "yyyy-MM-dd");
            $scope.employment.todate = $scope.employment.todate ? $filter('date')($scope.employment.todate, "yyyy-MM-dd") : null;
            var employment = {
                employer: $scope.employment.employer,
                positionheld: $scope.employment.positionheld,
                fromdate: $scope.employment.fromdate,
                todate: $scope.employment.todate,
                employed: $scope.employment.employed
            };

            //if(!$scope.party.employmentHistory) $scope.party.employmentHistory = [];

            EmploymentService.save($scope.roleContext.ciprid, self.party.partyid, employment).then(onsuccess, onerror);
        };

        $scope.update = function(employment) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Employment record has been updated.');
                $scope.hideEmploymentForm();
                EmploymentService.get($scope.roleContext.ciprid, self.party.partyid)
                .then(function(res) {
                    $scope.party.employmentHistory = res.data || [];
                });
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Error updating employment record');
            };

            employment.fromdate = $filter('date')(employment.fromdate, "yyyy-MM-dd");
            employment.todate = employment.todate ? $filter('date')(employment.todate, "yyyy-MM-dd") : null;


            EmploymentService.update($scope.roleContext.ciprid, self.party.partyid, employment).then(onsuccess, onerror);
        };

        $scope.showEmploymentForm = function() {
            $scope.employmentForm = true;
        };

        $scope.hideEmploymentForm = function() {
            $scope.employmentForm = false;
            $scope.employment = {};
            $scope.employment.fromdate = '';
            $scope.employment.todate = '';
        };
    }
])
.controller('PartyCESummaryCtrl', ['$scope', '$state', '$stateParams', '$http', 'CEService',
    function($scope, $state, $stateParams, $http, CEService) {
        var self = this;
        self.party = $scope.party;
        $scope.categories = [];
        $scope.loading = true;
        $scope.showdetails = false;

        $scope.meetsRequirements = function(category) {
            return parseFloat(category.remainingHours) <= 0;
        };

        $scope.isRed = function(categorydetail) {
            return categorydetail.hasShortfall && !categorydetail.hasInactivityRequirement;
        };

        $scope.isYellow = function(categorydetail) {
            return categorydetail.hasShortfall && categorydetail.hasInactivityRequirement;
        };

        $scope.isGreen = function(categorydetail) {
            return !$scope.isRed(categorydetail) && !$scope.isYellow(categorydetail);
        };

        $scope.showCourses = function() {
            $state.go('ce.courses');
        };

        $scope.addCourse = function() {
            $state.go('ce.add');
        };

        $http.get('/' + $scope.roleContext.ciprid + '/ce/detailedsummary/', {
            cache: true,
            params: {
                lholderid: self.party.partyid
            }
        }).then(function(res) {
            $scope.categories = (res.data.ce && res.data.ce.categories) || [];
            $scope.loading = false;
        });


    }
])
.controller('PartyCECoursesCtrl', ['$scope', '$state', '$stateParams', '$http', 'dialogs', 'DTOptionsBuilder', 'SweetAlert', 'PartyService',
    function($scope, $state, $stateParams, $http, dialogs, DTOptionsBuilder, SweetAlert, PartyService) {
        var self = this;
        self.party = $scope.party;
        $scope.cecourses = [];
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [3, 'desc']);


        var nonVoidedCourses = [];
        var allCourses = [];

        $scope.verify = function(cecourse, $event) {
            var checkbox = $event.target;
            var onsuccess = function(res) {
                toastr.success('Success!', cecourse.courseName + ' verification has been updated.');
            };
            var onerror = function(res) {
                toastr.error('Error!', cecourse.courseName + ' cannot update verification. Please try later');
                cecourse.verified = cecourse.verified == 'Y' ? 'N' : 'Y';
            };

            PartyService.verifyCECourse(cecourse.courseCertificateId, checkbox.checked ? 'Y' : 'N').then(onsuccess, onerror);
        };

        $scope.voidCourse = function(ceCourse) {
            SweetAlert.swal({
                title: "Delete course.",
                text: "Are you sure you want to delete course?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $http.delete('/' + $scope.roleContext.ciprid + '/ce', {
                        params: {
                            ceCourseCertificateId: ceCourse.courseCertificateId
                        }
                    })
                    .then(function(data, status, headers, config) {
                        ceCourse.voidedOn = new Date();
                        //remove course from the non voided list
                        nonVoidedCourses = _.without(nonVoidedCourses, ceCourse);

                    });
                }
            });
        };

        $scope.showVoided = function($event) {
            var checkbox = $event.target;
            $scope.cecourses = checkbox.checked ? allCourses : nonVoidedCourses;
        };

        var init = function() {
            PartyService.getCECourses(self.party.partyid)
            .then(function(res) {
                allCourses = res.data;
                nonVoidedCourses = _.filter(allCourses, {'voidedOn': ''});
                // by default show non voided courses only
                $scope.cecourses = nonVoidedCourses;
            });
        };

        init();

        $scope.reportCourse = function() {
            var data = {
                ciprid: $scope.roleContext.ciprid,
                profile: $scope.party.profile,
                partyid: self.party.partyid
            };
            dialogs.create('parties/ce/dialogs/ce.courses.report.html', 'CECourseAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(ceCourse) {
                init();
            });
        };

    }
])
.controller('CECourseAddDialogCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$filter', '$http', '$uibModal', '$uibModalInstance', 'toastr', 'data',
    function($rootScope, $scope, $state, $stateParams, $filter, $http, $uibModal, $uibModalInstance, toastr, data) {
        $scope._ = _;
        $scope.selectedCourse = undefined;
        $scope.courseCompletionDate = undefined;
        $scope.ceCourseInstructor = 'N';
        $scope.courseBegin = '';
        $scope.courseEnd = '';
        $scope.dateFormat = 'MM dd, yyyy';
        $scope.alerts = [];

        $scope.$watch('selectedCourse', function(selectedCourse) {
            if(typeof selectedCourse === 'object') {
                $scope.courseBegin = selectedCourse.start_dt ? $filter('date')(selectedCourse.start_dt, "MM/dd/yyyy") : '';
                $scope.courseEnd = $filter('date')(selectedCourse.end_dt || new Date(), "MM/dd/yyyy");
            }
        });

        $scope.setCourseCompletionDate = function(selectedDate) {
            $scope.courseCompletionDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        $scope.getCourses = function(val, limit) {
            return $http.get('/lookup/ce/lookup/typeahead', {
                params: {
                    courseid: val,
                    limit: limit
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        $scope.checkCourse = function() {
            if(typeof $scope.selectedCourse !== 'object') {
                $scope.selectedCourse = undefined;
            }
        };

        $scope.addAlert = function(msg) {
            $scope.alerts.push({type: 'danger', msg: msg});
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.saveCourse = function() {
            $scope.alerts = [];
            //save course
            $http.put('/staff/parties/' + data.partyid + '/cecourses/', {
                data: {
                    instructor: $scope.ceCourseInstructor,
                    courseId: $scope.selectedCourse.ce_course_seq,
                    completionDate: $scope.courseCompletionDate
                }
            })
            .then(function(res) {
                toastr.success('Success!', 'Course successfully saved: ' + $scope.selectedCourse.ce_course_nm);
                $uibModalInstance.close();
            }, function(data, status) {
                _.isArray(data.data.err.msg) ? $scope.addAlert(data.data.err.msg[0]) : $scope.addAlert(data.data.err.msg);
            });
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])
.controller('PartySponsorsCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'PartyService',
    function($scope, $state, $http, DTOptionsBuilder, PartyService) {
        var self = this;
        self.party = $scope.party;
        $scope.loading = true;
        $scope.sponsors = [];
        $scope.showAllSponsors = false;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [4, 'asc']);


        PartyService.getSponsors(self.party.partyid)
        .then(function(res) {
            $scope.sponsors = res.data;
            $scope.loading = false;
        });

        $scope.showAll = function($event) {
            var checkbox = $event.target;
            $scope.showAllSponsors = checkbox.checked;
        };


    }
])
.controller('PartyAddressCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'PartyService', 'AddressService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, PartyService, AddressService) {
        var self = this;

        self.party = $scope.party;
        $scope.addresses = [];
        $scope.loading = true;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [4, 'asc']);

        PartyService.getAddresses(self.party.partyid)
        .then(function(res) {
            $scope.addresses = res.data;
            $scope.loading = false;
        });

        AddressService.getAddressTypes()
        .then(function(res) {
            $scope.addressTypes = res;
            $scope.addressTypes = _.filter($scope.addressTypes, function(adrtype) {
                return adrtype.addr_type_cd == 'P'
            });
        });

        /**
         * Presents an edit dialog.
         * @param address
         */
        $scope.editAddress = function(address) {
            var data = {address: address, addressTypes: $scope.addressTypes};
            dialogs.create('parties/address/dialogs/addoredit.html', 'PartyAddressAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(address) {

                var onsuccess = function(res) {
                    toastr.success('Success!', 'Address has been updated.');
                    $scope.addresses = _.reject($scope.addresses, {'id': address.id});
                    address.description = _.find($scope.addressTypes, function(atyp) {
                        return atyp.id.trim() == address.scnrio_cd.trim()
                    }).description;
                    $scope.addresses.push(address);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Address cannot be able to updated. Please try later');
                };

                PartyService.updateAddress(self.party.partyid, address).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an add dialog.
         */
        $scope.addAddress = function() {
            var data = {addressTypes: $scope.addressTypes};
            dialogs.create('parties/address/dialogs/addoredit.html', 'PartyAddressAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(address) {

                var onsuccess = function(res) {
                    var data = res.data;
                    toastr.success('Success!', 'Address has been created.');
                    address.id = data[0].id;
                    address.description = _.find($scope.addressTypes, function(atyp) {
                        return atyp.id.trim() == address.scnrio_cd.trim()
                    }).description;
                    $scope.addresses.push(address);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Unable to create address at this time. Please try later');
                };

                PartyService.createAddress(self.party.partyid, address).then(onsuccess, onerror);
            });
        };

        $scope.removeAddress = function(address) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Address has been removed.');
                $scope.addresses = _.reject($scope.addresses, {'id': address.id});
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Address cannot be removed at this time. Please try later');
            };

            PartyService.removeAddress(self.party.partyid, address).then(onsuccess, onerror);
        };

    }
])
.controller('PartyAddressAddEditDialogCtrl', ['$scope', '$uibModalInstance', 'AddressService', 'PartyService', 'data',
    function($scope, $uibModalInstance, AddressService, OrgsService, data) {
        $scope.address = data.address;
        $scope.addressTypes = data.addressTypes;

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };

        $scope.setCity = function(item, model, label) {
            $scope.address.city = model.cityname;
            $scope.address.cityid = model.cityid;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.address.province = model.provname;
            $scope.address.provinceid = model.provid;
            $scope.address.provabbrev = model.provabbreviation;
        };

        $scope.setCountry = function(item, model, label) {
            $scope.address.country = model.countryname;
            $scope.address.countryid = model.countryid;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.address);
        };

        $scope.checkCity = function() {
            if(!$scope.address.cityid) {
                $scope.address.city = "";
            }
        };
        $scope.checkProvince = function() {
            if(!$scope.address.provinceid) {
                $scope.address.province = "";
            }
        };
        $scope.checkCountry = function() {
            if(!$scope.address.countryid) {
                $scope.address.country = "";
            }
        };

        $scope.$watch('noResultsCity', function(noNewCity) {
            if(noNewCity) {
                $scope.address.cityid = "";
                return;
            }
        });
        $scope.$watch('noResultsCountry', function(noNewCountry) {
            if(noNewCountry) {
                $scope.address.countryid = "";
                return;
            }
        });
        $scope.$watch('noResultsProvince', function(noNewProvince) {
            if(noNewProvince) {
                $scope.address.provinceid = "";
                return;
            }
        });
    }
])
.controller('PartyNotesCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'NotesService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, NotesService) {
        var self = this;

        self.party = $scope.party;
        $scope.notes = [];
        $scope.noteTypes = [];
        $scope.loading = true;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [5, 'desc']);


        NotesService.getNotes(self.party.partyid)
        .then(function(res) {
            $scope.notes = res.data.notes;
            $scope.noteTypes = res.data.noteTypes;
            $scope.loading = false;
        });
        /**
         * Presents an edit dialog.
         * @param note
         */
        $scope.editNote = function(note) {
            var data = {note: note, noteTypes: $scope.noteTypes};
            dialogs.create('staff/notes/dialogs/addoredit.html', 'NoteAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(note) {

                var onsuccess = function(res) {
                    toastr.success('Success!', 'Note has been updated.');
                    $scope.notes = _.reject($scope.notes, {'id': note.id});
                    $scope.notes.push(note);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Note cannot be able to updated. Please try later');
                };

                NotesService.updateNote(self.party.partyid, note).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an add dialog.
         */
        $scope.addNote = function() {
            var data = {note: undefined, noteTypes: $scope.noteTypes};
            dialogs.create('staff/notes/dialogs/addoredit.html', 'NoteAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(note) {

                var onsuccess = function(res) {
                    var data = res.data;
                    toastr.success('Success!', 'Note has been created.');
                    note = data[0];
                    $scope.notes.push(note);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Unable to create note at this time. Please try later');
                };

                NotesService.createNote(self.party.partyid, note).then(onsuccess, onerror);
            });
        };

        $scope.removeNote = function(note) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Note has been removed.');
                $scope.notes = _.reject($scope.notes, {'id': note.id});
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Note cannot be removed at this time. Please try later');
            };

            NotesService.removeNote(self.party.partyid, note).then(onsuccess, onerror);
        };
    }
])
.controller('PartyEventCtrl', ['$scope', '$state', '$stateParams', '$filter', 'dialogs', 'DTOptionsBuilder', 'EventService',
    function($scope, $state, $stateParams, $filter, dialogs, DTOptionsBuilder, EventService) {
        var self = this;

        self.party = $scope.party;
        $scope.events = [];
        $scope.loading = true;
        $scope.incCommEvent = 'N';
        $scope.incWebEvent = 'N';
        $scope.dateFormat = 'MM dd, yyyy';
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        $scope.fromDate = moment().subtract(30, 'days').format('MMMM DD, YYYY');
        $scope.toDate = moment(d).format('MMMM DD, YYYY');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc']);


        EventService.getPersonEvents(self.party.partyid, $scope.fromDate, $scope.toDate, $scope.incCommEvent, $scope.incWebEvent)
        .then(function(res) {
            $scope.events = res.data;
            $scope.loading = false;
        });

        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setFromDate = function(selectedDate) {
            $scope.fromDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        /**
         * Called when datepicker is dismissed
         * @param selectedDate
         */
        $scope.setToDate = function(selectedDate) {
            $scope.toDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        $scope.setCommEvent = function($event) {
            var checkbox = $event.target;
            $scope.incCommEvent = checkbox.checked ? 'Y' : 'N';
        };

        $scope.setWebEvent = function($event) {
            var checkbox = $event.target;
            $scope.incWebEvent = checkbox.checked ? 'Y' : 'N';
        };

        $scope.getEvents = function() {
            $scope.loading = true;
            EventService.getPersonEvents(self.party.partyid, $scope.fromDate ? $filter('date')($scope.fromDate, "yyyy-MM-dd") : null, $scope.toDate ? $filter('date')($scope.toDate, "yyyy-MM-dd") : null, $scope.incCommEvent, $scope.incWebEvent)
            .then(function(res) {
                $scope.events = res.data;
                $scope.loading = false;
            });
        }
    }
])
.controller('PartyEPlicenseCtrl', ['$scope', '$state', '$http', 'dialogs', 'DTOptionsBuilder', 'EPLicensesService',
    function($scope, $state, $http, dialogs, DTOptionsBuilder, EPLicensesService) {
        var self = this;
        self.party = $scope.party;
        $scope.eplicenses = [];
        $scope.regulators = [];
        $scope.ceExempt = false;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc']);


        var init = function() {
            EPLicensesService.getEPLicenses($scope.roleContext.ciprid, self.party.partyid)
            .then(function(res) {
                var resp = res.data;
                $scope.eplicenses = resp.eplicenses.concat(resp.previousEplicenses);
                $scope.regulators = resp.regulators;
                $scope.eplicenses = _.filter($scope.eplicenses, {status: 'REPORTED'})
                // if at least one of the province regulators is exempt for at least one ins. category, set the
                // ceExempt
                // flag ( meaning the agent can enter his/her extra prov license and it will be used  to calculate
                // his/her CE status).
                _.forEach($scope.regulators, function(regulator) {
                    if(regulator.ceExemptForLife == 'Y' || regulator.ceExemptForA_S == 'Y' || regulator.ceExemptForGeneral == 'Y' || regulator.ceExemptForAdjuster == 'Y') {
                        $scope.ceExempt = true;
                    }
                });
            });
        };

        init();

        $scope.voidEPLicense = function(eplicense) {
            EPLicensesService.voidEPLicense($scope.roleContext.ciprid, eplicense.id)
            .then(function(data) {
                $scope.eplicenses = _.without($scope.eplicenses, eplicense);
                toastr.success('Success!', 'Non-Alberta license ' + eplicense.licenseNumber + ' has been deleted.');
            });
        };

        /**
         * Presents an add dialog.
         */
        $scope.addEPLicense = function() {
            var data = {
                ciprid: $scope.roleContext.ciprid,
                regulators: $scope.regulators,
                ceExempt: $scope.ceExempt,
                profile: $scope.party.profile,
                partyid: self.party.partyid
            };
            dialogs.create('parties/eplicenses/dialogs/eplicenses.add.html', 'EPLicenseAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eplicense) {
                init();
            });
        };

    }
])
.controller('EPLicenseAddDialogCtrl', ['$scope', '$http', '$uibModalInstance', 'EPLicensesService', 'data',
    function($scope, $http, $uibModalInstance, EPLicensesService, data) {

        $scope.regulators = data.regulators;
        $scope.regulator = undefined;
        $scope.categories = undefined;
        $scope.category = undefined;
        $scope.declaration = undefined;
        $scope.ceExempt = data.ceExempt;
        $scope.profile = data.profile;
        $scope.licensePeriods = undefined;
        $scope.licensePeriod = undefined;

        $scope.setRegulator = function(item, model) {
            $scope.regulator = model;
        };

        $scope.saveEPLicense = function(licenseNumber) {
            EPLicensesService.saveEPLicense(data.ciprid, $scope.category.id, $scope.regulator, licenseNumber, $scope.declaration, $scope.licensePeriod.id, data.partyid)
            .then(function(resp) {
                toastr.success('Success!', 'Non-Alberta license ' + licenseNumber + ' has been added.');
                $uibModalInstance.close();
            });
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.setDeclaration = function(category) {
            var categories = [];
            categories.push(category.label);
            EPLicensesService.getDeclaration(data.ciprid, categories, data.profile.address.province)
            .then(function(res) {
                $scope.declaration = res.data[0];
            });

        };

        $scope.$watch('regulator', function(regulator) {
            if(typeof regulator === 'object') {
                EPLicensesService.getCategories(data.ciprid, regulator.id)
                .then(function(res) {
                    $scope.categories = res.data.categories;
                    $scope.declaration = undefined;
                });
            }
        });

        $scope.$watch('category', function(category) {
            if(typeof category === 'object') {
                $scope.setDeclaration(category);
                EPLicensesService.getPeriods(data.ciprid, category.id)
                .then(function(res) {
                    $scope.licensePeriods = res.data;
                });
            }
        });

    }
])
.controller('PartyNsfCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'NsfService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, NsfService) {
        var self = this;

        self.party = $scope.party;
        $scope.nsfs = [];
        $scope.nsfAccounts = [];
        $scope.loading = true;
        var partyNsfBlock = blockUI.instances.get('party-nsf-block');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();

        NsfService.getNsfAccounts()
        .then(function(res) {
            $scope.nsfAccounts = res.data;
        });

        NsfService.getNsfStatuses()
        .then(function(res) {
            $scope.nsfStatuses = res.data;
        });

        NsfService.getNsfs(self.party.partyType, self.party.partyid)
        .then(function(res) {
            $scope.nsfs = res.data;
            $scope.loading = false;
        });

        $scope.getStatusDescription = function(statusCD) {
            var st = _.find($scope.nsfStatuses, function(status) {
                return status.id.trim() == statusCD.trim()
            });
            return (st) ? st.description : "";
        };

        /**
         * Presents an add dialog.
         */
        $scope.addNSF = function() {
            $scope.creationNsfErrors = undefined;
            var data = {
                nsf: undefined,
                party: $scope.party,
                ciprid: $scope.roleContext.ciprid,
                nsfStatuses: $scope.nsfStatuses,
                nsfAccounts: $scope.nsfAccounts
            };
            dialogs.create('staff/nsf/dialogs/addoredit.html', 'NsfAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(nsf) {
                var onsuccess = function(res) {
                    partyNsfBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationNsfErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Nsf cannot be created at this time. Please try later');
                    }
                    toastr.success('Success!', 'Nsf has been created.');
                    nsf = data[0];
                    if($scope.nsfs == undefined)
                        $scope.nsfs = [nsf];
                    else
                        $scope.nsfs.unshift(nsf);
                };
                var onerror = function(res) {
                    partyNsfBlock.stop();
                    toastr.error('Error!', 'Unable to create nsf at this time. Please try later');
                };
                partyNsfBlock.start("Creating Nsf...");
                NsfService.createNsf(self.party.partyType, self.party.partyid, nsf).then(onsuccess, onerror);
            });
        };

        $scope.canEdit = function(nsf) {
            return _.includes(["FLIC", "FEXM", "FMSC", "FCEE", "FPPD"], nsf.scnrio_cd.trim());
        };

        /**
         * Presents an edit dialog.
         */
        $scope.editNSF = function(nsf) {
            $scope.creationNsfErrors = undefined;
            var data = {
                nsf: nsf,
                party: $scope.party,
                ciprid: $scope.roleContext.ciprid,
                nsfStatuses: $scope.nsfStatuses,
                nsfAccounts: $scope.nsfAccounts
            };
            dialogs.create('staff/nsf/dialogs/addoredit.html', 'NsfAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(nsf) {
                var onsuccess = function(res) {
                    partyNsfBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationNsfErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Nsf cannot be updated at this time. Please try later');
                    }
                    toastr.success('Success!', 'Nsf has been updated.');
                    nsf = data[0];
                    $scope.nsfs = _.reject($scope.nsfs, {'id': nsf.id});
                    if($scope.nsfs == undefined)
                        $scope.nsfs = [nsf];
                    else
                        $scope.nsfs.unshift(nsf);
                };
                var onerror = function(res) {
                    partyNsfBlock.stop();
                    toastr.error('Error!', 'Unable to update nsf at this time. Please try later');
                };
                partyNsfBlock.start("Updating Nsf...");
                NsfService.updateNsf(self.party.partyType, self.party.partyid, nsf).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an writeOff dialog.
         */
        $scope.writeOffNSF = function(nsf) {
            $scope.creationNsfErrors = undefined;
            var data = {
                nsf: nsf,
                party: $scope.party,
                ciprid: $scope.roleContext.ciprid
            };
            dialogs.create('staff/nsf/dialogs/wo.html', 'NsfWoDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(reason) {
                var onsuccess = function(res) {
                    partyNsfBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationNsfErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Nsf cannot be write off at this time. Please try later');
                    }
                    toastr.success('Success!', 'Nsf has been written off.');
                    nsf = data[0];
                    $scope.nsfs = _.reject($scope.nsfs, {'id': nsf.id});
                    if($scope.nsfs == undefined)
                        $scope.nsfs = [nsf];
                    else
                        $scope.nsfs.unshift(nsf);
                };
                var onerror = function(res) {
                    partyNsfBlock.stop();
                    toastr.error('Error!', 'Unable to write off nsf at this time. Please try later');
                };
                partyNsfBlock.start("Write Off Nsf...");
                NsfService.writeOffNsf(self.party.partyType, self.party.partyid, nsf, reason).then(onsuccess, onerror);
            });
        };


    }
])
.controller('PartyRefundCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'RefundService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, RefundService) {
        var self = this;

        self.party = $scope.party;
        $scope.refunds = [];
        $scope.refundAccounts = [];
        $scope.loading = true;
        var partyRefundBlock = blockUI.instances.get('party-refund-block');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();

        RefundService.getRefundAccounts()
        .then(function(res) {
            $scope.refundAccounts = res.data;
        });

        RefundService.getRefundStatuses()
        .then(function(res) {
            $scope.refundStatuses = res.data;
        });

        RefundService.getRefunds(self.party.partyType, self.party.partyid)
        .then(function(res) {
            $scope.refunds = res.data;
            $scope.loading = false;
        });


        $scope.getStatusDescription = function(statusCD) {
            var st = _.find($scope.refundStatuses, function(status) {
                return status.id.trim() == statusCD.trim()
            });
            return (st) ? st.description : "";
        };

        /**
         * Presents an add dialog.
         */
        $scope.addRefund = function() {
            $scope.creationRefundErrors = undefined;
            var data = {
                refund: undefined,
                ciprid: $scope.roleContext.ciprid,
                party: $scope.party,
                refundAccounts: $scope.refundAccounts
            };
            dialogs.create('staff/refund/dialogs/add.html', 'RefundAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(refund) {

                var onsuccess = function(res) {
                    partyRefundBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationRefundErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Refund cannot be created at this time. Please try later');
                    }
                    toastr.success('Success!', 'Refund has been created.');
                    refund = data[0];
                    $scope.refunds.unshift(refund);
                };
                var onerror = function(res) {
                    partyRefundBlock.stop();
                    toastr.error('Error!', 'Unable to create refund at this time. Please try later');
                };
                partyRefundBlock.start("Saving Refund...");
                RefundService.createRefund(self.party.partyType, self.party.partyid, refund).then(onsuccess, onerror);
            }, function() {
            });
        };

        $scope.cancelRefund = function(refund) {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', 'Refund cannot be cancelled at this time. Please try later');
                }
                toastr.success('Success!', 'Refund has been removed.');
                var data = res.data;
                $scope.refunds = _.reject($scope.refunds, {'id': refund.id});
                refund = data[0];
                $scope.refunds.unshift(refund);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Refund cannot be removed at this time. Please try later');
            };

            RefundService.cancelRefund(refund).then(onsuccess, onerror);
        };

        /**
         * Presents an cheque dialog.
         */
        $scope.refundCheque = function(refund) {
            $scope.creationRefundErrors = undefined;
            var data = {chqbatId: refund.chqbatId, ciprid: $scope.roleContext.ciprid, party: $scope.party};
            dialogs.create('staff/refund/dialogs/refund.cheque.html', 'RefundChequeDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(refundCheque) {

                var onsuccess = function(res) {
                    partyRefundBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationRefundErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Refund cheque cannot be updated at this time. Please try later');
                    }
                    toastr.success('Success!', 'Refund cheque has been updated.');
                };
                var onerror = function(res) {
                    partyRefundBlock.stop();
                    toastr.error('Error!', 'Unable to update refund cheque at this time. Please try later');
                };
                partyRefundBlock.start("Saving refund cheque...");
                RefundService.updateRefundCheque(refundCheque).then(onsuccess, onerror);
            });
        };


    }
])
.controller('PartyWoCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'WoService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, WoService) {
        var self = this;

        self.party = $scope.party;
        $scope.wos = [];

        $scope.loading = true;
        var partyWoBlock = blockUI.instances.get('party-wo-block');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();

        WoService.getWoStatuses()
        .then(function(res) {
            $scope.woStatuses = res.data;
        });

        WoService.getWos(self.party.partyType, self.party.partyid)
        .then(function(res) {
            $scope.wos = res.data;
            $scope.loading = false;
        });

        $scope.getStatusDescription = function(statusCD) {
            var st = _.find($scope.woStatuses, function(status) {
                return status.id.trim() == statusCD.trim()
            });
            return (st) ? st.description : "";
        };


    }
])
.controller('PartyDocumentCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'DocumentService', 'DownloadService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, DocumentService, DownloadService) {
        var self = this;

        self.party = $scope.party;
        $scope.documents = {};
        $scope.loading = true;
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withOption('order', [0, 'desc'])
        .withBootstrap();

        var party = [];
        party.id = self.party.partyid;
        DocumentService.searchByParty(party)
        .then(function(res) {
            $scope.documents = res.data;
            $scope.loading = false;
        });

        $scope.downloadLink = function(doc) {
            var anchorHref = S("/staff/documents/{{docid}}/download").template({docid: doc.docId}).s;
            var anchorText = doc.fileName;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        $scope.editDocument = function(doc) {
            $state.go('documents.open.edit', {docId: doc.docId});
        };
    }
])
.controller('PartyAppointeeCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'AppointeeService', 'OrginsService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, AppointeeService, OrginsService) {
        var self = this;
        self.party = $scope.party;
        $scope.appointees = [];
        $scope.displayAppointees = [];
        $scope.activeAppointees = [];
        $scope.orgins = [];
        $scope.activeOrgins = [];
        $scope.loading = true;
        $scope.showHistory = false;
        var partyAppointeeBlock = blockUI.instances.get('party-appointee-block');

        $scope.updateList = function() {
            $scope.activeAppointees = _.filter($scope.appointees, function(appointee) {
                if(appointee.scnrio_cd.trim() == 'UACT')
                    return appointee
            });
            $scope.displayAppointees = $scope.showHistory ? $scope.appointees : $scope.activeAppointees;
        };

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'asc']);

        AppointeeService.getAppointeesByStk(self.party.partyid)
        .then(function(res) {
            $scope.loading = false;
            $scope.appointees = res.data;
            $scope.updateList();
        });

        $scope.toggleList = function($event) {
            var checkbox = $event.target;
            $scope.showHistory = checkbox.checked;
            $scope.updateList();

        };

    }
])
.controller('PartyLicenseSuspendDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'DTOptionsBuilder', 'SweetAlert', 'data', 'PartyService', 'ApplService',
    function($scope, $http, $uibModalInstance, $filter, DTOptionsBuilder, SweetAlert, data, PartyService, ApplService) {

        /**
         * Initializers
         */
        $scope.activeLicenses = data.activeLicenses;
        $scope.activeSelectedLicenseIds = [];
        $scope.suspendReason = "TERM";
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ])
        .withOption('iDisplayLength', 5);

        ApplService.getSuspendReasons()
        .then(function(res) {
            $scope.suspendReasons = res.data;
        });

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.activeLicenses, function(license) {
                    $scope.activeSelectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.activeSelectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.activeSelectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.activeSelectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.activeSelectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.activeSelectedLicenseIds.splice($scope.activeSelectedLicenseIds.indexOf(licenseid), 1);
            }
        };


        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.activeSelectedLicenseIds.indexOf(licenseid) >= 0;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.suspend = function(withTerminationLetter) {
            let msg = "Are you sure you want to terminate " + $scope.activeSelectedLicenseIds.length + " license(s)" + (withTerminationLetter ? " and generate Termination Letter" : "") + "?";
            SweetAlert.swal({
                html: 'true',
                title: "License Suspension",
                text: msg,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $uibModalInstance.close({
                        reasonId: $scope.suspendReason,
                        licenseIds: $scope.activeSelectedLicenseIds,
                        terminationLetter: withTerminationLetter
                    });
                }
            });
        };

    }
])
.controller('PartyLicenseReverseDialogCtrl', ['$scope', '$http', '$uibModalInstance', 'data',
    function($scope, $http, $uibModalInstance, data) {

        /**
         * Initializers
         */
        $scope.license = data.license;
        $scope.reason = "";


        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.reason);
        };


    }
])
.controller('PartyLicenseGenerateRenewalDialogCtrl', ['$scope', '$http', '$uibModalInstance', 'DTOptionsBuilder', 'data', 'PartyService', 'ApplService',
    function($scope, $http, $uibModalInstance, DTOptionsBuilder, data, PartyService, ApplService) {

        /**
         * Initializers
         */
        $scope.renewalGroup = "LIFE";
        $scope.partyid = data.partyid;
        $scope.results = [];
        $scope.renewalGenerated = false;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        ApplService.getRenewalGroups()
        .then(function(res) {
            $scope.renewalGroups = res.data;
        });


        $scope.$watch('renewalGroup', function(newRenewalGroup) {
            if(!newRenewalGroup) return;
            $scope.results = [];
        });

        $scope.cancel = function() {
            if($scope.renewalGenerated)
                $uibModalInstance.close($scope.results)
            else
                $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.results = [];
            $scope.generateRenewalsInProgress = true;

            var onsuccess = function(res) {
                $scope.generateRenewalsInProgress = false;
                if(res.data.error) {
                    toastr.error('Error!', 'Error occurred during renewal generation process. Please try again later');
                } else {
                    $scope.results = res.data;
                    $scope.renewalGenerated = true;
                    toastr.success('Success!', 'Renewal generation process ran successfully.');
                }

            };
            var onerror = function(res) {
                $scope.generateRenewalsInProgress = false;
                toastr.error('Error!', 'Error occurred during renewal generation process. Please try again later');
            };
            ApplService.generateRenewals($scope.partyid, $scope.renewalGroup)
            .then(onsuccess, onerror);

        };


    }
])
/**
 * Party services
 */
.service('PartyService', ['$http', '$q',
    function($http, $q) {
        this.parties = [];
        this.partySearchStr = undefined;

        this.setPartySearchStr = function(partySearchStr) {
            this.partySearchStr = partySearchStr
        };

        this.setParties = function(parties) {
            this.parties = parties
        };

        this.search = function(partySearchStr) {
            return $http.get('/staff/parties/search?searchStr=' + partySearchStr);
        };

        this.import = function(ciprId) {
            return $http.post('/staff/parties/import?ciprId=' + ciprId);
        };

        this.link = function(ciprId, partyId) {
            return $http.put('/staff/parties/link', {
                data: {
                    ciprId: ciprId,
                    partyId: partyId
                }
            });
        };

        this.unlink = function(partyId, ciprId) {
            return $http.put('/staff/parties/unlink', {
                data: {
                    partyId: partyId,
                    ciprId: ciprId
                }
            });
        };

        // Returns staff users Only
        this.getStaffUsers = function() {
            return $http.get('/staff/parties/users', {cache: true});
        };

        // Returns Person Only
        this.getParties = function(phrase, limit) {
            return $http.get('/lookup/parties/', {
                params: {
                    name: phrase,
                    limit: limit
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        // Returns both Person and Organization
        this.getPartiesBoth = function(phrase, limit) {
            return $http.get('/lookup/partiesboth/', {
                params: {
                    name: phrase,
                    limit: limit
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getDMSPartiesBoth = function(phrase, limit) {
            return $http.get('/lookup/dmspartiesboth/', {
                params: {
                    name: phrase,
                    limit: limit
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getExams = function(partyId, all) {
            return $http.get('/staff/parties/' + partyId + '/exam', {
                params: {
                    all: all
                }
            });
        };

        this.getLmsUID = function() {
            return $http.get('/staff/parties/lmsUID', {cache: true});
        };

        this.getLicenses = function(partyId) {
            return $http.get('/staff/parties/' + partyId + '/licenses/');
        };

        this.getInvoices = function(partyId) {
            return $http.get('/staff/parties/' + partyId + '/invoices/');
        };

        this.getSponsors = function(partyId) {
            return $http.get('/staff/parties/' + partyId + '/sponsors/', {cache: true});
        };

        this.getAddresses = function(partyId) {
            return $http.get('/staff/parties/' + partyId + '/address/', {cache: true});
        };

        this.updateAddress = function(partyId, address) {
            return $http.post('/staff/parties/' + partyId + '/address/' + address.id, {
                data: {
                    address: address
                }
            });
        };

        this.removeAddress = function(partyId, address) {
            return $http.delete('/staff/parties/' + partyId + '/address/' + address.id);
        };

        this.createAddress = function(partyId, address) {
            return $http.put('/staff/parties/' + partyId + '/address/', {
                data: {
                    address: address
                }
            });
        };

        this.getLicensesHistory = function(partyId) {
            return $http.get('/staff/parties/' + partyId + '/licenses/history/', {cache: false});
        };

        this.getCECourses = function(partyId) {
            return $http.get('/staff/parties/' + partyId + '/cecourses/', {cache: false});
        };

        this.verifyCECourse = function(courseCertificateId, verifiedYN) {
            return $http.put('/staff/parties/cecourses/verify', {
                data: {
                    verifiedYN: verifiedYN,
                    courseCertificateId: courseCertificateId
                }
            });
        };

        this.update = function(party) {
            return $http.post('/staff/parties/' + party.partyid, {
                stkInfo: party.stkInfo,
                statusId: party.statusId
            });
        };

        this.addPersonRole = function(partyId, type) {
            return $http.post('/staff/parties/' + partyId + '/role', {
                roleTypeId: type
            });
        };
        this.removePersonRole = function(partyId, type) {
            return $http({
                url: "/staff/parties/" + partyId + "/role",
                method: 'DELETE',
                data: {
                    roleTypeId: type
                },
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };

        this.getAccountTrans = function(accntCD, partyId) {
            return $http.get('/staff/parties/' + partyId + '/accountTransactions/', {
                params: {
                    accntCD: accntCD
                }
            });
        };

        this.getPartyById = function(id) {
            return $http.get('/party/byid', {
                params: {
                    id: id
                }
            });
        };

        this.getPartyByIdPersonActive = function(id) {
            return $http.get('/party/byidpersonactive', {
                params: {
                    id: id
                }
            });
        };

        this.getPartyByCIPR = function(ciprid) {
            return $http.get('/staff/parties/byciprid', {
                params: {
                    id: ciprid
                }
            });
        };

    }
])
.service('PartyResolverService', ['$resource', '$http', 'FileUploader', 'PartyService',
    function($resource, $http, FileUploader, PartyService) {

        /**
         * Returns a promise which resolves a party object.
         *
         * @param ciprid
         * @param partyid
         * @returns {*}
         */
        this.get = function(ciprid, partyid) {
            return $http.get('/staff/parties/' + partyid, {cache: false})
            .then(function(res) {

                var party = res.data.party[0];
                party.profile = res.data.profile;
                //party.examResults = res.data.examResults;
                party.accounts = res.data.accounts;
                party.licenses = res.data.licenses[0];
                party.invoices = res.data.invoices[0];
                party.exams = res.data.exams[0];
                party.employmentHistory = res.data.employmentHistory;
                party.profileRoles = res.data.profileRoles;
                party.stkInfo = res.data.stkInfo[0];
                party.statuses = res.data.statuses;
                party.appointees = res.data.appointees[0];
                //party.eandos = res.data.eandos;

                return party;
            });
        };
    }
])
.service('EmploymentService', ['$http', '$q',
    function($http, $q) {

        this.get = function(ciprId, lholderid) {
            return $http.get('/' + ciprId + '/employment/', {
                params: {
                    lholderid: lholderid
                }
            });
        };

        this.remove = function(ciprId, employmentid) {
            return $http.delete('/' + ciprId + '/employment/' + employmentid);
        };

        this.save = function(ciprId, lholderid, employment) {
            return $http.put('/' + ciprId + '/employment/', {
                data: {
                    lholderid: lholderid,
                    employer: employment.employer,
                    positionheld: employment.positionheld,
                    fromdate: employment.fromdate,
                    todate: employment.todate,
                    employed: employment.employed
                }
            })
        };

        this.update = function(ciprId, lholderid, employment) {
            return $http.post('/' + ciprId + '/employment/' + employment.partyemploymentid, {
                data: {
                    lholderid: lholderid,
                    employer: employment.employer,
                    positionheld: employment.positionheld,
                    fromdate: employment.fromdate,
                    todate: employment.todate,
                    employed: employment.employed
                }
            })
        };
    }
])
.service('TerminationLetterPrintService', ['$http', '$q',
    function($http, $q) {


        this.generateAgentLicenseTerminationPages = function(document, licenses) {
            licenses.forEach(function(license, i) {
                document.content.push(getAgentLicenseTerminationPage(license, document.content.length));
            });
            return document;
        };

        var getAgentLicenseTerminationPage = function(license, i) {
            var agentLicenseTerminationPage = [

                {
                    pageBreak: i > 0 ? 'before' : '',
                    text: (license.salutation ? license.salutation + ' ' : '') + license.name,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 150}
                },
                {
                    text: license.home_address ? license.home_address : '',
                    style: 'body',
                    absolutePosition: {x: 50, y: 165}
                },
                {text: license.c_dt_frmt, style: 'body', alignment: 'right', absolutePosition: {x: 380, y: 150}},
                {text: 'Dear Sir/Madam,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 250}},
                {
                    text: 'The ' + license.liccls_desc.toUpperCase() + ' Certificate of Authority in Alberta, ' + license.license + ', for ' + license.name.toUpperCase() + ' to represent ' + (license.agencyname.toUpperCase() || license.sponsor_nm.toUpperCase()) + ' has been suspended as of ' + license.expiry_dt_frmt.toUpperCase() + '.',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 270}
                },
                {text: 'Sincerely,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 350}},
                {
                    text: 'per:\r\nJoseph Fernandez\r\nRegistrar',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 430}
                },
                {
                    text: 'cc: ' + license.sponsor_nm + '',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 500}
                },
                {
                    text: license.employer_seq ? 'cc: ' + license.agencyname.toUpperCase() : '',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 510}
                },
            ];
            return agentLicenseTerminationPage;
        };

        this.generateAgencyLicenseTerminationPages = function(document, licenses) {
            licenses.forEach(function(license, i) {
                document.content.push(getAgencyLicenseTerminationPage(license, document.content.length));
            });
            return document;
        };

        var getAgencyLicenseTerminationPage = function(license, i) {
            var agencyLicenseTerminationPage = [
                {
                    pageBreak: i > 0 ? 'before' : '',
                    text: license.agencyname,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 150}
                },
                {
                    text: license.address_formatted ? license.address_formatted : '',
                    style: 'body',
                    absolutePosition: {x: 50, y: 165}
                },
                {text: license.c_dt_frmt, style: 'body', alignment: 'right', absolutePosition: {x: 380, y: 150}},
                {text: 'Dear Sir/Madam,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 250}},
                {
                    text: 'The ' + license.liccls_desc.toUpperCase() + ' Certificate of Authority in Alberta, ' + license.license + ', for ' + (license.lic_for_cd === 'C' ? license.agencyname.toUpperCase() : license.name.toUpperCase()) + '  has been suspended as of ' + license.expiry_dt_frmt.toUpperCase() + '.',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 270}
                },
                {text: 'Sincerely,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 350}},
                {
                    text: 'per:\r\nJoseph Fernandez\r\nRegistrar',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 430}
                },
                {
                    text: 'cc: ' + license.sponsor_nm,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 500}
                },
                {
                    text: license.lic_for_cd === 'C' ? '' : 'cc: ' + license.name.toUpperCase(),
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 510}
                },
            ];
            return agencyLicenseTerminationPage;
        };


        this.generateSponsorLicenseTerminationPages = function(document, licenses) {
            licenses.forEach(function(license, i) {
                document.content.push(getSponsorLicenseTerminationPage(license, document.content.length));
            });
            return document;
        };

        var getSponsorLicenseTerminationPage = function(license, i) {
            var sponsorLicenseTerminationPage = [
                {
                    pageBreak: i > 0 ? 'before' : '',
                    text: license.sponsor_nm,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 150}
                },
                {
                    text: license.sponsor_addr ? license.sponsor_addr : '',
                    style: 'body',
                    absolutePosition: {x: 50, y: 165}
                },
                {text: license.c_dt_frmt, style: 'body', alignment: 'right', absolutePosition: {x: 380, y: 150}},
                {text: 'Dear Sir/Madam,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 250}},
                {
                    text: 'The ' + license.liccls_desc.toUpperCase() + ' Certificate of Authority in Alberta, ' + license.license + ', for ' + (license.lic_for_cd === 'C' ? license.agencyname.toUpperCase() : license.name.toUpperCase()) + '  has been suspended as of ' + license.expiry_dt_frmt.toUpperCase() + '.',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 270}
                },
                {text: 'Sincerely,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 350}},
                {
                    text: 'per:\r\nJoseph Fernandez\r\nRegistrar',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 430}
                },
                {
                    text: license.lic_for_cd === 'C' ? '' : 'cc: ' + license.name.toUpperCase(),
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 500}
                },
                {
                    text: license.employer_seq ? 'cc: ' + license.agencyname.toUpperCase() : '',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 510}
                },
            ];
            return sponsorLicenseTerminationPage;
        };

        this.generateAdjLicenseTerminationPages = function(document, licenses) {
            licenses.forEach(function(license, i) {
                document.content.push(getAdjLicenseTerminationPage(license, document.content.length));
            });
            return document;
        };

        var getAdjLicenseTerminationPage = function(license, i) {
            var adjLicenseTerminationPage = [
                {
                    pageBreak: i > 0 ? 'before' : '',
                    text: (license.salutation ? license.salutation + ' ' : '') + license.name,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 150}
                },
                {
                    text: license.home_address ? license.home_address : '',
                    style: 'body',
                    absolutePosition: {x: 50, y: 165}
                },
                {text: license.c_dt_frmt, style: 'body', alignment: 'right', absolutePosition: {x: 380, y: 150}},
                {text: 'Dear Sir/Madam,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 250}},
                {
                    text: 'The ' + license.liccls_desc.toUpperCase() + ' Adjuster\'s Certificate in Alberta, ' + license.license + ', for ' + license.name.toUpperCase() + ' has been suspended as of ' + license.expiry_dt_frmt.toUpperCase() + '.',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 270}
                },
                {text: 'Sincerely,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 350}},
                {
                    text: 'per:\r\nJoseph Fernandez\r\nRegistrar',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 430}
                },
                {
                    text: license.employer_seq ? 'cc: ' + license.agencyname.toUpperCase() : '',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 500}
                },
            ];
            return adjLicenseTerminationPage;
        }

        this.generateAdjAgencyLicenseTerminationPages = function(document, licenses) {
            licenses.forEach(function(license, i) {
                document.content.push(getAdjAgencyLicenseTerminationPage(license, document.content.length));
            });
            return document;
        };

        var getAdjAgencyLicenseTerminationPage = function(license, i) {
            var adjAgencyLicenseTerminationPage = [
                {
                    pageBreak: i > 0 ? 'before' : '',
                    text: license.agencyname,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 150}
                },
                {
                    text: license.address_formatted ? license.address_formatted : '',
                    style: 'body',
                    absolutePosition: {x: 50, y: 165}
                },
                {text: license.c_dt_frmt, style: 'body', alignment: 'right', absolutePosition: {x: 380, y: 150}},
                {text: 'Dear Sir/Madam,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 250}},
                {
                    text: 'The ' + license.liccls_desc.toUpperCase() + ' Adjuster\'s Certificate in Alberta, ' + license.license + ', for ' + (license.lic_type === 'C' ? license.agencyname.toUpperCase() : license.name.toUpperCase()) + '  has been suspended as of ' + license.expiry_dt_frmt.toUpperCase() + '.',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 270}
                },
                {text: 'Sincerely,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 350}},
                {
                    text: 'per:\r\nJoseph Fernandez\r\nRegistrar',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 430}
                },
                {
                    text: license.lic_type === 'C' ? '' : 'cc: ' + license.name.toUpperCase(),
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 500}
                },
            ];
            return adjAgencyLicenseTerminationPage;
        };


        this.generateCorpAgencyLicenseTerminationPages = function(document, licenses) {
            licenses.forEach(function(license, i) {
                document.content.push(getCorpAgencyLicenseTerminationPage(license, document.content.length));
            });
            return document;
        };

        var getCorpAgencyLicenseTerminationPage = function(license, i) {
            var corpAgencyLicenseTerminationPage = [
                {
                    pageBreak: i > 0 ? 'before' : '',
                    text: license.agencyname ? license.agencyname : license.employer_nm,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 150}
                },
                {
                    text: license.address_formatted ? license.address_formatted : '',
                    style: 'body',
                    absolutePosition: {x: 50, y: 165}
                },
                {text: license.c_dt_frmt, style: 'body', alignment: 'right', absolutePosition: {x: 380, y: 150}},
                {text: 'Dear Sir/Madam,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 250}},
                {
                    text: 'The ' + license.liccls_desc.toUpperCase() + ' Certificate of Authority in Alberta, ' + license.license + ', for ' + license.agencyname.toUpperCase() + '  has been suspended as of ' + license.expiry_dt_frmt.toUpperCase() + '.',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 270}
                },
                {text: 'Sincerely,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 350}},
                {
                    text: 'per:\r\nJoseph Fernandez\r\nRegistrar',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 430}
                },
                {
                    text: 'cc: ' + license.sponsor_nm,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 500}
                },
            ];
            return corpAgencyLicenseTerminationPage;
        };

        this.generateCorpSponsorLicenseTerminationPages = function(document, licenses) {
            licenses.forEach(function(license, i) {
                document.content.push(getCorpSponsorLicenseTerminationPage(license, document.content.length));
            });
            return document;
        };

        var getCorpSponsorLicenseTerminationPage = function(license, i) {
            var corpSponsorLicenseTerminationPage = [
                {
                    pageBreak: i > 0 ? 'before' : '',
                    text: license.sponsor_nm,
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 150}
                },
                {
                    text: license.sponsor_addr ? license.sponsor_addr : '',
                    style: 'body',
                    absolutePosition: {x: 50, y: 165}
                },
                {text: license.c_dt_frmt, style: 'body', alignment: 'right', absolutePosition: {x: 380, y: 150}},
                {text: 'Dear Sir/Madam,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 250}},
                {
                    text: 'The ' + license.liccls_desc.toUpperCase() + ' Certificate of Authority in Alberta, ' + license.license + ', for ' + license.agencyname.toUpperCase() + '  has been suspended as of ' + license.expiry_dt_frmt.toUpperCase() + '.',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 270}
                },
                {text: 'Sincerely,', style: 'body', alignment: 'left', absolutePosition: {x: 50, y: 350}},
                {
                    text: 'per:\r\nJoseph Fernandez\r\nRegistrar',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 430}
                },
                {
                    text: license.employer_seq ? 'cc: ' + license.agencyname.toUpperCase() : '',
                    style: 'body',
                    alignment: 'left',
                    absolutePosition: {x: 50, y: 500}
                },
            ];
            return corpSponsorLicenseTerminationPage;
        };


        this.getStyles = function() {
            return {
                header: {
                    fontSize: 18,
                    bold: true,
                },
                table: {
                    margin: [0, 5, 0, 15]
                },
                body: {fontSize: 10},
                tablefillsmall: {
                    bold: true,
                    fontSize: 9,
                    fillColor: 'gray',
                    alignment: 'center'
                },
                tablesmall: {
                    fontSize: 9,
                    alignment: 'center'
                }
            }
        };

    }
]);
