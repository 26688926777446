angular.module('aic.subscribe', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router',
    'aic.exams'
])

.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {
    }
])

.controller('SubscribeNotificationsCtrl', ['$scope', '$state', 'socket', 'toastr', 'AuthService', 'ExamCountdownService',
    function($scope, $state, socket, toastr, AuthService, ExamCountdownService) {
        $scope.new = 0;
        $scope.notifications = [];

        $scope.markAsRead = function() {
            $scope.new = 0;
        };

        socket.on('connect', function(data) {
            socket.emit('authenticate', {token: AuthService.getToken()});

            socket.on('authenticated', function() {
                socket.emit('register', {ciprid: $scope.roleContext.ciprid});
            });
        });

        socket.on('payment:distributed', function(data) {
            var msg = "Invoice " + data.invoiceid + " was processed. Payments were applied successfully.";
            $scope.notifications.push({
                type: 'license',
                text: msg
            });
            toastr.success('Success!', msg);
        });

        socket.on('license:issued', function(data) {
            var msg = 'Your license ' + data.license + ' has been issued.';
            $scope.notifications.push({
                type: 'license',
                text: msg
            });
            toastr.success('Success!', msg);
        });

        socket.on('license:notpaid', function(data) {
            var msg = 'Your license ' + data.license + ' cannot be issued until paid in full.';
            $scope.notifications.push({
                type: 'license',
                text: msg
            });
            toastr.success('Success!', msg);
        });

        socket.on('exam:registration.abandoned', function(data) {
            ExamCountdownService.reset();
            var msg = 'Your exam registration for ' + data.examtype + ' on ' + data.examtime + ' has been cancelled.';
            $scope.notifications.push({
                type: 'exam',
                text: msg
            });
        });

        socket.on('exam:registration.success', function(data) {
            ExamCountdownService.reset();
            var msg = 'Your have successfully register for exam of ' + data.examtype + ' on ' + data.examtime;
            $scope.notifications.push({
                type: 'exam',
                text: msg
            });
        });

        socket.on('staff:published', function(data) {
            $scope.notifications.push({
                type: 'staff',
                text: data.message
            });
            toastr.success(data.subject, data.message);
        });
    }
])
.factory('socket', ['$rootScope', '$window',
    function($rootScope, $window) {
        var socket = io.connect($window.location.origin, {
            'reconnection': true,
            'reconnectionDelay': 2000,
            'reconnectionAttempts': 5,
            'transports': ['websocket']
        });

        return {
            on: function(eventName, callback) {
                socket.on(eventName, function() {
                    var args = arguments;
                    $rootScope.$apply(function() {
                        callback.apply(socket, args);
                    });
                });
            },
            emit: function(eventName, data, callback) {
                socket.emit(eventName, data, function() {
                    var args = arguments;
                    $rootScope.$apply(function() {
                        if(callback) {
                            callback.apply(socket, args);
                        }
                    });
                })
            }
        };
    }
]);
