var todos = angular.module('aic.todos', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
todos.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('todos', {
            parent: 'workspace',
            abstract: true,
            url: '/todos',
            templateUrl: 'staff/todos/todos.html'
        })
        .state('todos.search', {
            url: '/search',
            params: {
                cache: true
            },
            templateUrl: 'staff/todos/search.html',
            controller: 'ToDosSearchCtrl'
        });
    }
])
.controller('ToDosAddEditDialogCtrl', ['$scope', '$uibModalInstance', '$filter', 'data', 'ToDosService', 'PartyService',
    function($scope, $uibModalInstance, $filter, data, ToDosService, PartyService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.todo = data.todo || {statusType: "TPND"};
        $scope.todo.forparty = data.todo && data.todo.partyId ? {
            partyid: data.todo.partyId,
            party_nm: data.todo.for
        } : {};
        $scope.todo.statusType = $scope.todo.statusType.trim();
        $scope.statusTypes = data.statusTypes;
        $scope.todo.assignedDate = (data.todo && data.todo.todo_dt) ? moment(data.todo.todo_dt, 'YYYY-MM-DD').format('MMMM DD, YYYY') : moment().format('MMMM DD, YYYY');
        $scope.setAssignedDate = function(selectedDate) {
            $scope.todo.assignedDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        PartyService.getStaffUsers()
        .then(function(res) {
            $scope.todousers = res.data;
        });

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.todo);
        };

        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getPartiesBoth(phrase, limit);
        };


    }
])
.controller('ToDosSearchCtrl', ['$scope', '$state', '$http', '$stateParams', '$filter', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'toastr', 'ToDosService', 'PartyService',
    function($scope, $state, $http, $stateParams, $filter, dialogs, DTOptionsBuilder, blockUI, toastr, ToDosService, PartyService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.search = true;
        $scope.todo = ToDosService.todoSearchInfo;
        $scope.toDoTasks = ToDosService.toDoTasks;
        $scope.toDoTasksWithoutCancelAndCompleted = ToDosService.toDoTasksWithoutCancelAndCompleted;
        $scope.selectedToDoIds = [];
        var todoSearchBlock = blockUI.instances.get('todo-search-result-block');
        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('stateSave', true)
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ])
        .withOption('language', {
            zeroRecords: 'No To-Do tasks found for the search criteria'
        });

        var initForm = function(clearData) {
            $scope.search = true;
            if(clearData) {
                $scope.todo = {};
                $scope.toDoTasks = undefined;
                $scope.toDoTasksWithoutCancelAndCompleted = undefined;
            }
        };
        initForm();


        ToDosService.getStatusTypes()
        .then(function(res) {
            $scope.statusTypes = res.data;
        });

        PartyService.getStaffUsers()
        .then(function(res) {
            $scope.todousers = res.data;
            var users = _.clone(res.data);
            users.push({uid: 'data', user_id: 'data', full_nm: 'Data'});
            $scope.todoUsersWithData = _.orderBy(users, [function(user){return user.user_id.toLowerCase()}], ['asc']);
        });

        $scope.setAssignedDate = function(selectedDate) {
            $scope.todo.assignedDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.setDoneDate = function(selectedDate) {
            $scope.todo.doneDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getPartiesBoth(phrase, limit);
        };


        $scope.searchToDo = function() {
            $scope.toDoTasks = undefined;
            $scope.searchToDoRequestInProgress = true;
            ToDosService.setToDoSearchInfo($scope.todo);
            ToDosService.search($scope.todo)
            .then(function(res) {
                $scope.searchToDoRequestInProgress = false;
                $scope.toDoTasks = res.data;
                $scope.toDoTasksWithoutCancelAndCompleted = _.reject($scope.toDoTasks, function(t) {
                    return t.statusType.trim() == 'TCOM' || t.statusType.trim() == 'TCAN';
                });
                ToDosService.setToDoTasks($scope.toDoTasks);
                ToDosService.setToDoTasksWithoutCancelAndCompleted($scope.toDoTasksWithoutCancelAndCompleted);
            });
        };

        $scope.clear = function() {
            initForm(true);
        };


        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.toDoTasksWithoutCancelAndCompleted, function(toDoTask) {
                    $scope.selectedToDoIds.push(toDoTask.id);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedToDoIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param toDoTaskId
         */
        $scope.updateSelection = function($event, toDoTaskId) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedToDoIds.indexOf(toDoTaskId) === -1) {
                $scope.selectedToDoIds.push(toDoTaskId);
            }

            if(!checkbox.checked && $scope.selectedToDoIds.indexOf(toDoTaskId) !== -1) {
                $scope.selectedToDoIds.splice($scope.selectedToDoIds.indexOf(toDoTaskId), 1);
            }
        };

        $scope.isSelected = function(toDoTaskId) {
            return $scope.selectedToDoIds.indexOf(toDoTaskId) >= 0;
        };


        $scope.assignToDosToMe = function() {
            var userId = _.find($scope.todousers, {uid: $scope.profile.uid}).user_id;
            $scope.assignToDos(userId);
        };
        $scope.assignToDos = function(userId) {
            todoSearchBlock.start("Assigning ToDos...");
            if($scope.selectedToDoIds.length == 0) {
                toastr.warning('Nothing to do!', 'ToDos selected here cannot be assigned.');
                return;
            }

            ToDosService.assignToUser(userId, $scope.selectedToDoIds)
            .then(function(res) {
                todoSearchBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', 'To Do cannot be assigned at this time.');
                }
                toastr.success('Success!', 'To Do Tasks has been successfully assigned to ' + userId);
                $scope.searchToDo();
            }, function() {
                todoSearchBlock.stop();
                toastr.error('Error!', 'An error occurred while assigning ToDos. Please try again.');
            });
        };

        $scope.completeTasks = function() {
            todoSearchBlock.start("Completing ToDos...");
            if($scope.selectedToDoIds.length == 0) {
                toastr.warning('Nothing to do!', 'ToDos selected here cannot be completed.');
                return;
            }

            ToDosService.completeTasks($scope.selectedToDoIds)
            .then(function(res) {
                todoSearchBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', 'To Do cannot be completed at this time.');
                }
                toastr.success('Success!', 'To Do Tasks has been successfully completed');
                $scope.selectedToDoIds = [];
                $scope.searchToDo();
            }, function() {
                todoSearchBlock.stop();
                toastr.error('Error!', 'An error occurred while completing ToDos. Please try again.');
            });
        };

        $scope.cancelTasks = function() {
            todoSearchBlock.start("Cancelling ToDos...");
            if($scope.selectedToDoIds.length == 0) {
                toastr.warning('Nothing to do!', 'ToDos selected here cannot be cancelled.');
                return;
            }

            ToDosService.cancelTasks($scope.selectedToDoIds)
            .then(function(res) {
                todoSearchBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', 'To Do cannot be cancelled at this time.');
                }
                toastr.success('Success!', 'To Do Tasks has been successfully cancelled');
                $scope.selectedToDoIds = [];
                $scope.searchToDo();
            }, function() {
                todoSearchBlock.stop();
                toastr.error('Error!', 'An error occurred while cancelling ToDos. Please try again.');
            });
        };


        /**
         * Presents an add dialog.
         */
        $scope.editTask = function(toDoTask) {
            var data = {todo: toDoTask, statusTypes: $scope.statusTypes};
            dialogs.create('staff/todos/dialogs/addoredit.html', 'ToDosAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(todo) {

                var onsuccess = function(res) {
                    todoSearchBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', 'To Do cannot be updated at this time.');
                    }
                    toastr.success('Success!', 'To Do has been updated.');
                    $scope.toDoTasks = _.reject($scope.toDoTasks, {'id': todo.id});
                    var data = res.data;
                    $scope.toDoTasks.push(data[0]);
                };
                var onerror = function(res) {
                    todoSearchBlock.stop();
                    toastr.error('Error!', 'Unable to update todo at this time. Please try later');
                };

                todoSearchBlock.start("Updating ToDo...");
                ToDosService.updateToDo(todo).then(onsuccess, onerror);
            });
        };


    }
])
.service('ToDosService', ['$http', '$q',
    function($http, $q) {
        this.toDoTasks= undefined;
        this.toDoTasksWithoutCancelAndCompleted = undefined;
        this.todoSearchInfo = {};
        this.setToDoSearchInfo = function(todoSearchInfo) {
            this.todoSearchInfo = todoSearchInfo
        };

        this.setToDoTasks = function(toDoTasks) {
            this.toDoTasks = toDoTasks
        };

        this.setToDoTasksWithoutCancelAndCompleted = function(toDoTasksWithoutCancelAndCompleted) {
            this.toDoTasksWithoutCancelAndCompleted = toDoTasksWithoutCancelAndCompleted
        };

        this.getStatusTypes = function() {
            return $http.get('/staff/todos/statustypes', {cache: true});
        };

        this.updateToDos = function(todo) {
            return $http.post('/staff/todos/' + todo.id, {
                data: {
                    todo: todo
                }
            });
        };

        this.removeTodos = function(todo) {
            return $http.delete('/staff/todos/' + todo.id);
        };

        this.createToDo = function(todo) {
            return $http.put('/staff/todos', {
                data: {
                    todo: todo
                }
            });
        };

        this.assignToUser = function(userId, toDoIds) {
            return $http({
                url: '/staff/todos/assigntouser',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {userId: userId, toDoIds: toDoIds}
            });
        };

        this.completeTasks = function(toDoIds) {
            return $http({
                url: '/staff/todos/completetasks',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {toDoIds: toDoIds}
            });
        };
        this.cancelTasks = function(toDoIds) {
            return $http({
                url: '/staff/todos/canceltasks',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {toDoIds: toDoIds}
            });
        };

        this.search = function(todo) {
            return $http.get('/staff/todos/search/', {
                params: {
                    from: todo.from,
                    statusType: todo.statusType,
                    to: todo.to,
                    assignedDate: todo.assignedDate,
                    doneDate: todo.doneDate,
                    task: todo.task,
                    forparty: !_.isEmpty(todo.forparty) ? todo.forparty.partyid : null,
                }
            });
        };

        this.updateToDo = function(todo) {
            return $http.post('/staff/todos/' + todo.id, {
                data: {
                    todo: todo
                }
            });
        };


    }
]);
