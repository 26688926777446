var appl = angular.module('aic.appl', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);
var revLicClassMap = {
    'PE': 'PA',
    'H': 'F',
    'I': 'G'
};

var licClassMap = {
    'PA': 'PE',
    'F ': 'H ',
    'G ': 'I '
};

/**
 * Routing and config
 */
appl.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('appl', {
            parent: 'workspace',
            abstract: true,
            url: '/appl',
            templateUrl: 'applications/application.html'
        })
        .state('appl.list', {
            url: '/list',
            controller: 'ApplListCtrl',
            templateUrl: 'staff/appl/appl.list.html'
        })
        .state('appl.mylist', {
            url: '/mylist',
            controller: 'ApplMyListCtrl',
            templateUrl: 'staff/appl/appl.my.list.html'
        })
        .state('appl.init', {
            url: '/init/:partyId?',
            resolve: {
                promisedParty: ['$stateParams', 'AuthService', 'PartyResolverService',
                    function($stateParams, AuthService, PartyResolverService) {
                        var ciprId = AuthService.getRoleContext().ciprid;
                        var partyId = $stateParams.partyId;
                        var resolved = partyId ? PartyResolverService.get(ciprId, partyId) : '';
                        return resolved;
                    }
                ]
            },
            controller: 'ApplInitCtrl',
            templateUrl: 'staff/appl/appl.init.html'
        })
        .state('appl.open', {
            url: '/{appId:[0-9]+}',
            abstract: true,
            resolve: {
                promisedLicense: ['$stateParams', 'AuthService', 'ApplResolverService',
                    function($stateParams, AuthService, ApplResolverService) {
                        var jwtToken = AuthService.getToken();
                        var ciprid = AuthService.getRoleContext().ciprid;
                        var licenseid = $stateParams.appId;
                        return ApplResolverService.get(jwtToken, ciprid, licenseid);
                    }
                ],
                promisedCorpLicense: ['$stateParams', 'AuthService', 'promisedLicense', 'ApplResolverService',
                    function($stateParams, AuthService, promisedLicense, ApplResolverService) {
                        var jwtToken = AuthService.getToken();
                        var ciprid = AuthService.getRoleContext().ciprid;
                        if(promisedLicense.drlicense === 'Y' && !_.isEmpty(promisedLicense.corpLicense) && promisedLicense.corpLicense.statuscd.trim() === 'KAPP') {
                            return ApplResolverService.get(jwtToken, ciprid, promisedLicense.corpLicense.licenseid)
                        }

                        return {};
                    }
                ]
            },
            controller: 'ApplOpenCtrl',
            template: '<div ui-view></div>'
        })
        .state('appl.open.edit', {
            url: '',
            views: {
                '': {
                    templateUrl: 'applications/application.edit.html',
                    controller: 'ApplEditCtrl'
                },
                'header@appl.open.edit': {
                    templateUrl: 'staff/appl/header/header.html',
                    controller: 'ApplHeaderCtrl'
                },
                'address@appl.open.edit': {
                    templateUrl: 'applications/address/address.edit.html',
                    controller: 'ApplAddressCtrl'
                },
                'crimcheck@appl.open.edit': {
                    templateUrl: 'applications/crimcheck/crimcheck.view.html',
                    controller: 'CriminalCheckCtrl'
                },
                'eo@appl.open.edit': {
                    templateUrl: 'applications/eo/eo.add.html',
                    controller: 'ApplEandOCtrl'
                },
                'employment@appl.open.edit': {
                    templateUrl: 'applications/employment/employment.history.edit.html',
                    controller: 'ApplEmploymentHistoryCtrl'
                },
                'qa@appl.open.edit': {
                    templateUrl: 'applications/qa/qa.edit.html',
                    controller: 'ApplQuestionsCtrl'
                },
                'payment@appl.open.edit': {
                    templateUrl: 'staff/appl/payment/payment.html',
                    controller: 'ApplPaymentCtrl'
                },
                'declaration@appl.open.edit': {
                    templateUrl: 'staff/appl/declaration/declaration.edit.html',
                    controller: 'ApplDeclarationCtrl'
                },
                'recommendation@appl.open.edit': {
                    templateUrl: 'staff/appl/recommendation/recommendation.edit.html',
                    controller: 'ApplRecommendationCtrl'
                }
            }
        })
        .state('appl.open.view', {
            url: '',
            views: {
                '': {
                    templateUrl: 'applications/application.view.html',
                    controller: 'ApplViewCtrl'
                },
                'header@appl.open.view': {
                    templateUrl: 'staff/appl/header/header.html',
                    controller: 'ApplHeaderCtrl'
                },
                'address@appl.open.view': {
                    templateUrl: 'applications/address/address.view.html'
                },
                'crimcheck@appl.open.view': {
                    templateUrl: 'applications/crimcheck/crimcheck.view.html',
                    controller: 'CriminalCheckCtrl'
                },
                'eo@appl.open.view': {
                    templateUrl: 'applications/eo/eo.view.html',
                    controller: 'ApplEandOCtrl'
                },
                'employment@appl.open.view': {
                    templateUrl: 'applications/employment/employment.history.view.html',
                    controller: 'ApplEmploymentHistoryCtrl'
                },
                'qa@appl.open.view': {
                    templateUrl: 'applications/qa/qa.view.html',
                    controller: 'ApplQuestionsCtrl'
                },
                'payment@appl.open.view': {
                    templateUrl: 'staff/appl/payment/payment.html',
                    controller: 'ApplPaymentCtrl'
                },
                'declaration@appl.open.view': {
                    templateUrl: 'staff/appl/declaration/declaration.view.html',
                    controller: 'ApplDeclarationCtrl'
                },
                'recommendation@appl.open.view': {
                    templateUrl: 'staff/appl/recommendation/recommendation.view.html',
                    controller: 'ApplRecommendationCtrl'
                }
            }
        })
        .state('appl.snapshot', {
            url: '/snapshot/{appId:[0-9]+}',
            abstract: true,
            resolve: {
                promisedLicense: ['$stateParams', 'AuthService', 'ApplResolverService',
                    function($stateParams, AuthService, ApplResolverService) {
                        var jwtToken = AuthService.getToken();
                        var licenseid = $stateParams.appId;
                        return ApplResolverService.getSnapshot(jwtToken, licenseid);
                    }
                ]
            },
            controller: 'ApplSnapshotCtrl',
            template: '<div ui-view></div>'
        })
        .state('appl.snapshot.view', {
            url: '',
            views: {
                '': {
                    templateUrl: 'applications/application.snapshot.view.html',
                    controller: 'ApplSnapshotViewCtrl'
                },
                'header@appl.snapshot.view': {
                    templateUrl: 'applications/header/header.html',
                    controller: 'ApplicationHeaderCtrl'
                },
                'address@appl.snapshot.view': {
                    templateUrl: 'applications/address/address.view.html'
                },
                'crimcheck@appl.snapshot.view': {
                    templateUrl: 'applications/crimcheck/crimcheck.view.html',
                    controller: 'CriminalCheckCtrl'
                },
                'eo@appl.snapshot.view': {
                    templateUrl: 'applications/eo/eo.view.html',
                    controller: 'ApplEandOCtrl'
                },
                'employment@appl.snapshot.view': {
                    templateUrl: 'applications/employment/employment.history.view.html',
                    controller: 'ApplEmploymentHistoryCtrl'
                },
                'qa@appl.snapshot.view': {
                    templateUrl: 'staff/appl/qa/qa.snapshot.view.html',
                    controller: 'ApplQuestionsCtrl'
                },
                'payment@appl.snapshot.view': {
                    templateUrl: 'staff/appl/payment/payment-snapshot.html',
                    controller: 'ApplPaymentCtrl'
                },
                'declaration@appl.snapshot.view': {
                    templateUrl: 'staff/appl/declaration/declaration.view.html',
                    controller: 'ApplDeclarationCtrl'
                },
                'recommendation@appl.snapshot.view': {
                    templateUrl: 'staff/appl/recommendation/recommendation.view.html',
                    controller: 'ApplRecommendationCtrl'
                },
                'reviewer@appl.snapshot.view': {
                    templateUrl: 'staff/appl/reviews/reviewers.html',
                    controller: 'ApplReviewerCtrl'
                },
                'comments@appl.snapshot.view': {
                    templateUrl: 'staff/appl/reviews/comments.html',
                    controller: 'ApplReviewCommentsCtrl'
                }
            }
        });

    }
])
.controller('ApplListCtrl', ['$scope', '$state', '$http', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'ApplicationService', 'ApplService', 'InvoiceService',
    function($scope, $state, $http, dialogs, DTOptionsBuilder, blockUI, ApplicationService, ApplService, InvoiceService) {
        $scope.applications = [];
        $scope.loading = true;
        $scope.assignedtousers = [];
        $scope.selectedApplicationsIds = [];

        var applicationBlock = blockUI.instances.get('appl-list-block');
        applicationBlock.start();

        ApplService.getApplications($scope.roleContext.ciprid)
        .then(function(res) {
            setApplications(res.data);
            applicationBlock.stop();
        });

        var setApplications = function(data) {
            $scope.applications = data[0];
            $scope.corpApplications = data[1];

            // link the corp license to the D/R license
            var drApplications = _.filter($scope.applications, {drlicense: 'Y'});
            _.forEach(drApplications, function(application) {
                application.corpLicense = _.find($scope.corpApplications, {
                    licenseclass: application.corporatelicenseclass,
                    agencyid: application.agencyid
                });
            });
        };

        $http.get('/' + $scope.roleContext.ciprid + '/licenses/assignedtousers')
        .then(function(res) {
            $scope.assignedtousers = res.data;
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true)
        .withOption('order', [1, 'asc']);

        $scope.viewApplication = function(application) {
            applicationBlock.start();
            if($scope.isStaff() && !ApplicationService.isOnlineApp(application) && (ApplicationService.isIncomplete(application) || ApplicationService.isWithRegulator(application)))
                $state.go('appl.open.edit', {appId: application.licenseid});
            else
                $state.go('appl.open.view', {appId: application.licenseid});
        };


        $scope.isIncomplete = function(application) {
            return ApplicationService.isIncomplete(application);
        };

        $scope.assignedTo = function(userCIPRId) {
            ApplService.setAssignedTo(userCIPRId, $scope.selectedApplicationsIds)
            .then(function() {
                toastr.success('Success!', 'Application(s) successfully assigned.');
                applicationBlock.start();
                ApplService.getApplications($scope.roleContext.ciprid)
                .then(function(res) {
                    setApplications(res.data);
                    applicationBlock.stop();
                });
            }, function() {
                toastr.error('Error!', 'Application(s) cannot be assigned at this time.');
            });
        };

        $scope.getAssignedTo = function(ciprId) {
            if(!ciprId) {
                return '';
            } else {
                var s = _.find($scope.assignedtousers, {
                    ciprId: ciprId
                });
                return s ? s.name : '';
            }
        };

        $scope.isDRApp = function(license) {
            return ApplicationService.isDRApp(license);
        };

        /**
         * For  D/R licenses license fee is sum of the D/R License Fee and Corp License Fee
         */
        $scope.getFee = function(a) {
            var licenseFee = parseFloat(a.fee);
            return a.drlicense != 'Y' || !a.corpLicense ? licenseFee : licenseFee + parseFloat(a.corpLicense.fee);
        };

        $scope.getFeeBalance = function(a) {
            var licenseFeeBalance = parseFloat(a.feebalance);
            return a.drlicense != 'Y' || !a.corpLicense ? licenseFeeBalance : licenseFeeBalance + parseFloat(a.corpLicense.feebalance);
        };

        $scope.applicationInit = function() {
            $state.go('appl.init', {partyId: ''});
        };

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid || !!license.payableid;
        };
        /**
         * Presents an payment dialog.
         */
        $scope.addToInvoice = function(application) {
            // var items = ApplicationService.isDRApp(application) &&
            // ApplicationService.isCorpLicenseExists(application.corpLicense) ? [application, application.corpLicense]
            // : [application];
            var data = {application: application};
            dialogs.create('staff/appl/dialogs/payment.edit.html', 'ApplPayerDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(application) {
                var onsuccess = function(res) {
                    applicationBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        console.log(_.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "");
                        return toastr.error('Error!', 'Application(s) cannot be added to invoice at this time. Please try later');
                    }
                    toastr.success('Success!', 'Application(s) has been successfully added to invoice.');
                    applicationBlock.start();
                    ApplService.getApplications($scope.roleContext.ciprid)
                    .then(function(res) {
                        setApplications(res.data);
                        applicationBlock.stop();
                    });
                };
                var onerror = function(res) {
                    applicationBlock.stop();
                    toastr.error('Error!', 'Application(s) cannot be added to invoice at this time. Please try later');
                };
                applicationBlock.start("Adding to invoice...");
                if(ApplicationService.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense) && ApplicationService.isApplication(application.corpLicense)) {
                    ApplService.savePayment(application)
                    .then(function() {
                        application.corpLicense.paidby = application.paidby;
                        application.corpLicense.drPartyId = application.licenseholderid;
                        ApplService.savePayment(application.corpLicense)
                        .then(onsuccess, onerror);
                    });
                } else {
                    ApplService.savePayment(application).then(onsuccess, onerror);
                }
            }, function() {
            });
        };


        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;
            if(!checkbox.checked) {
                return $scope.selectedApplicationsIds = [];
            }

            _.forEach($scope.applications, function(application) {
                if($scope.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense)) {
                    $scope.selectedApplicationsIds.push(application.corpLicense.licenseid);
                }
                $scope.selectedApplicationsIds.push(application.licenseid);
            });


        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, application) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedApplicationsIds.indexOf(application.licenseid) === -1) {
                if($scope.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense)) {
                    $scope.selectedApplicationsIds.push(application.corpLicense.licenseid);
                }
                $scope.selectedApplicationsIds.push(application.licenseid);
            }

            if(!checkbox.checked && $scope.selectedApplicationsIds.indexOf(application.licenseid) !== -1) {
                $scope.selectedApplicationsIds.splice($scope.selectedApplicationsIds.indexOf(application.licenseid), 1);
                if($scope.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense)) {
                    $scope.selectedApplicationsIds.splice($scope.selectedApplicationsIds.indexOf(application.corpLicense.licenseid), 1);
                }
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedApplicationsIds.indexOf(licenseid) >= 0;
        };


    }
])
.controller('ApplMyListCtrl', ['$scope', '$state', '$http', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'ApplicationService', 'ApplService', 'InvoiceService',
    function($scope, $state, $http, dialogs, DTOptionsBuilder, blockUI, ApplicationService, ApplService, InvoiceService) {
        $scope.applications = [];
        $scope.corpApplications = [];
        $scope.loading = true;
        $scope.selectedApplicationsIds = [];

        var applicationBlock = blockUI.instances.get('appl-my-list-block');
        applicationBlock.start();

        ApplService.getMyApplications($scope.roleContext.ciprid)
        .then(function(res) {
            setApplications(res.data);
            applicationBlock.stop();
        });

        var setApplications = function(data) {
            $scope.applications = data[0];
            $scope.corpApplications = data[1];

            // link the corp license to the D/R license
            var drApplications = _.filter($scope.applications, {drlicense: 'Y'});
            _.forEach(drApplications, function(application) {
                application.corpLicense = _.find($scope.corpApplications, {
                    licenseclass: application.corporatelicenseclass,
                    agencyid: application.agencyid
                });
            });
        };

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true)
        .withOption('order', [1, 'asc']);

        $scope.viewApplication = function(application) {
            applicationBlock.start();
            if($scope.isStaff() && !ApplicationService.isOnlineApp(application) && (ApplicationService.isIncomplete(application) || ApplicationService.isWithRegulator(application)))
                $state.go('appl.open.edit', {appId: application.licenseid});
            else
                $state.go('appl.open.view', {appId: application.licenseid});
        };

        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;
            if(!checkbox.checked) {
                return $scope.selectedApplicationsIds = [];
            }

            _.forEach($scope.applications, function(application) {
                if($scope.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense)) {
                    $scope.selectedApplicationsIds.push(application.corpLicense.licenseid);
                }
                $scope.selectedApplicationsIds.push(application.licenseid);
            });


        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, application) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedApplicationsIds.indexOf(application.licenseid) === -1) {
                if($scope.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense)) {
                    $scope.selectedApplicationsIds.push(application.corpLicense.licenseid);
                }
                $scope.selectedApplicationsIds.push(application.licenseid);
            }

            if(!checkbox.checked && $scope.selectedApplicationsIds.indexOf(application.licenseid) !== -1) {
                $scope.selectedApplicationsIds.splice($scope.selectedApplicationsIds.indexOf(application.licenseid), 1);
                if($scope.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense)) {
                    $scope.selectedApplicationsIds.splice($scope.selectedApplicationsIds.indexOf(application.corpLicense.licenseid), 1);
                }
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedApplicationsIds.indexOf(licenseid) >= 0;
        };

        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            $state.go('licenses.eando', {selectedLicenseIds: $scope.selectedApplicationsIds});
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedApplicationsIds});
        };

        $scope.isDRApp = function(license) {
            return ApplicationService.isDRApp(license);
        };

        /**
         * For  D/R licenses license fee is sum of the D/R License Fee and Corp License Fee
         */
        $scope.getFee = function(a) {
            var licenseFee = parseFloat(a.fee);
            return a.drlicense != 'Y' || !a.corpLicense ? licenseFee : licenseFee + parseFloat(a.corpLicense.fee);
        };

        $scope.getFeeBalance = function(a) {
            var licenseFeeBalance = parseFloat(a.feebalance);
            return a.drlicense != 'Y' || !a.corpLicense ? licenseFeeBalance : licenseFeeBalance + parseFloat(a.corpLicense.feebalance);
        };

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid || !!license.payableid;
        };
        /**
         * Presents an payment dialog.
         */
        $scope.addToInvoice = function(application) {
            // var items = ApplicationService.isDRApp(application) &&
            // ApplicationService.isCorpLicenseExists(application.corpLicense) ? [application, application.corpLicense]
            // : [application];
            var data = {application: application};
            dialogs.create('staff/appl/dialogs/payment.edit.html', 'ApplPayerDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(application) {
                var onsuccess = function(res) {
                    applicationBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        console.log(_.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "");
                        return toastr.error('Error!', 'Application(s) cannot be added to invoice at this time. Please try later');
                    }
                    toastr.success('Success!', 'Application(s) has been successfully added to invoice.');
                    applicationBlock.start();
                    ApplService.getMyApplications($scope.roleContext.ciprid)
                    .then(function(res) {
                        setApplications(res.data);
                        applicationBlock.stop();
                    });
                };
                var onerror = function(res) {
                    applicationBlock.stop();
                    toastr.error('Error!', 'Application(s) cannot be added to invoice at this time. Please try later');
                };
                applicationBlock.start("Adding to invoice...");
                if(ApplicationService.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense) && ApplicationService.isApplication(application.corpLicense)) {
                    ApplService.savePayment(application)
                    .then(function() {
                        application.corpLicense.paidby = application.paidby;
                        application.corpLicense.drPartyId = application.licenseholderid;
                        ApplService.savePayment(application.corpLicense)
                        .then(onsuccess, onerror);
                    });
                } else {
                    ApplService.savePayment(application).then(onsuccess, onerror);
                }
            }, function() {
            });
        };


    }
])
.controller('ApplInitCtrl', ['$scope', '$state', '$stateParams', '$http', 'store', 'promisedParty', 'dialogs', 'toastr', 'ApplicationService', 'InvoiceService', 'ApplService', 'PartyService',
    function($scope, $state, $stateParams, $http, store, promisedParty, dialogs, toastr, ApplicationService, InvoiceService, ApplService, PartyService) {
        //TODO findout why blockui conflict with license class
        $scope.classes = [];
        $scope.sponsors = [];
        $scope.unpaidInvoices = [];
        $scope.drLicenses = [];
        $scope.party = promisedParty;
        let applicationAgencyObj = store.get("application_agency_create");
        $scope.createApplicationInProgress = false;
        // --- Initializers ---
        var initForm = function() {
            $scope.party = promisedParty || undefined;
            $scope.class = {};
            $scope.category = "person";
            $scope.licHolderEmail = undefined;
            $scope.agency = undefined;
            $scope.sponsor = undefined;
            $scope.person = undefined;
            $scope.insureremployee = undefined;
            $scope.invoice = undefined;
            $scope.paidby = undefined;
            $scope.creationErrors = [];
            $scope.isTransfer = 'N';
            $scope.isReplace = 'N';
            $scope.transferableLicenses = [];
            $scope.upgradableLicenses = [];
            $scope.replaceLicense = undefined;
            $scope.downgradableLicense = undefined;
            $scope.corpLicense = {};
            $scope.expedited = 'N';
        };

        $scope.paidbyOptions = [
            {label: 'I haven\'t decided yet', value: ''},
            {label: 'Agent is paying', value: 'agent'},
            {label: 'We are paying', value: $scope.roleContext.type}
        ];

        initForm();

        if(applicationAgencyObj) {
            $scope.agency = applicationAgencyObj.agency;
            $scope.category = applicationAgencyObj.category;
            $scope.party = applicationAgencyObj.party;
        }
        $scope.paidby = $scope.paidbyOptions[0];

        /**
         * Fetch classes and prepopulate $scope.classes
         */
        ApplicationService.getClasses($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.classes = res.data;
            $scope.class = applicationAgencyObj ? _.find($scope.classes, {licenseclass: applicationAgencyObj.licClass}) : {};
            ApplicationService.setClasses($scope.classes);
            $scope.updateClasses();
        });

        $scope.updateClasses = function() {
            if($scope.category == 'person') {
                $scope.classes = _.filter(ApplicationService.classes, function(licenseclass) {
                    return _.includes(["PERSON", "OTHER"], licenseclass['onlinegroup']);
                });
            } else {
                $scope.classes = _.filter(ApplicationService.classes, function(licenseclass) {
                    return _.includes(["RESTRICTED"], licenseclass['onlinegroup']);
                });
            }
        };


        // --- Watchers ---

        $scope.$watch('party', function(newParty) {
            if(!_.has(newParty, 'partyid')) return;


            ApplicationService.getDRLicenses($scope.roleContext.ciprid, $scope.party.partyid)
            .then(function(res) {
                $scope.drLicenses = res.data;
            });

        });


        $scope.$watch('agency', function(newAgency) {
            if(!_.has(newAgency, 'agencyid')) return;

            $scope.sponsor = {
                sponsorid: $scope.agency.sponsorid,
                sponsorname: $scope.agency.sponsorname
            };
            getCorpLicense();
            getReplaceLicense();
            getRestrictedTransferableLicenses();
        });

        $scope.$watch('class', function(newClass) {
            if(!_.has(newClass, 'licenseclass')) return;

            $scope.isTransfer = 'N';
            $scope.transferableLicenses = [];
            getTransferableLicenses();
            getUpgradableLicenses();
            getDowngradableLicense();
            getCorpLicense();
            getReplaceLicense();
        });

        $scope.$watch('insureremployee', function(newInsureremployee) {
            if(newInsureremployee == 'SP') {
                $scope.initAgency(null, $scope.class);
            }
        });

        var getUpgradableLicenses = function() {
            if($scope.party && $scope.class.level) {
                ApplicationService.getUpgradableLicenses($scope.roleContext.ciprid, $scope.class, $scope.party.partyid).then(function(res) {
                    var upgradableLicenses = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, upgradableLicenses);
                });
            }
        };

        var getTransferableLicenses = function() {
            if($scope.party && !$scope.isRestrictedClass($scope.class)) {
                ApplicationService.getTransferableLicenses($scope.roleContext.ciprid, $scope.class.licenseclass, $scope.party.partyid).then(function(res) {
                    var transferableLicenses = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, transferableLicenses);
                });
            }
        };

        var getRestrictedTransferableLicenses = function() {
            if($scope.party && $scope.isRestrictedClass($scope.class) && $scope.agency.agencyid) {
                ApplicationService.getRestrictedTransferableLicenses($scope.roleContext.ciprid, $scope.class.licenseclass, $scope.agency.agencyid, $scope.party.partyid).then(function(res) {
                    $scope.transferableLicenses = res.data;
                });
            }
        };

        var getCorpLicense = function() {
            if($scope.class.isdrlicense === 'Y' && $scope.agency && $scope.agency.agencyid) {
                ApplicationService.getCorpLicense($scope.roleContext.ciprid, $scope.class.corp_liccls_cd, $scope.agency.agencyid).then(function(res) {
                    $scope.corpLicense = res.data[0] ? res.data[0] : {};
                });
            }
        };

        var getReplaceLicense = function() {
            $scope.isReplace = "N";
            $scope.replaceLicense = undefined;
            if($scope.party && $scope.class && ($scope.isRestrictedClass($scope.class) || $scope.class.isdrlicense === 'Y') && $scope.agency.agencyid) {
                ApplicationService.getReplaceLicense($scope.roleContext.ciprid, $scope.class.licenseclass, $scope.agency.agencyid, $scope.party.partyid).then(function(res) {
                    $scope.replaceLicense = _.isArray(res.data) ? res.data[0] : res.data;
                    $scope.isReplace = !_.isEmpty($scope.replaceLicense) ? "Y" : "N";
                    $scope.isTransfer = $scope.isReplace === 'Y' ? 'N' : $scope.isTransfer;
                });
            }
        };

        var getDowngradableLicense = function() {
            if($scope.party && $scope.class && _.includes(['M', 'Q', 'G', 'I'], $scope.class.licenseclass.trim())) {
                ApplicationService.getDowngradableLicense($scope.roleContext.ciprid, $scope.class, $scope.party.partyid).then(function(res) {
                    var downgradableLicense = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, downgradableLicense);
                });
            }
        };
        // --- Called from the form ---

        /**
         * Reset the form
         */
        $scope.clear = function() {
            initForm();
        };


        /**
         * Used in typeahead for Agencies
         * @param phrase
         * @returns {*}
         */
        $scope.getAgencies = function(phrase, limit) {
            return ApplicationService.getAgencies($scope.class.licenseclass, phrase, limit, $scope.isRestrictedClass($scope.class) || $scope.class.isdrlicense === 'Y' ? 'N' : '');
        };

        /**
         * Used in typeahead for sponsors
         * @param phrase
         * @returns {*}
         */
        $scope.getSponsors = function(phrase, limit) {
            return ApplicationService.getSponsors($scope.class.licenseclass, phrase, limit);
        };

        /**
         * Used in typeahead for parties
         * @param phrase
         * @returns {*}
         */
        $scope.getParties = function(phrase, limit) {
            return PartyService.getParties(phrase, limit);
        };

        /**
         * Set transferred from License
         * @param item
         * @param model
         */
        $scope.setTransferredFrom = function(item, model) {
            $scope.transferredFromLicense = model;
        };

        /**
         * Set Upgrade from License
         * @param item
         * @param model
         */
        $scope.setUpgradeFrom = function(item, model) {
            $scope.upgradeFromLicense = model;
        };

        /**
         * return whether the class is restricted
         * @returns {boolean}
         */
        $scope.isRestrictedClass = function(cls) {
            if(!cls) return false;
            return cls.licensefor === 'C' && cls.isorglicense == 'Y';
        };

        $scope.isDRClass = function(cls) {
            return ApplicationService.isDRClass(cls);
        };

        $scope.initAgency = function(legalName, cls) {
            store.set('application_agency_create', {
                "category": $scope.category,
                "sponsor": $scope.sponsor,
                "licClass": cls.licenseclass,
                "isExternal": false,
                "party": $scope.party,
                "isRestricted": $scope.isRestrictedClass(cls),
                "isSoleProprietorship": $scope.insureremployee == 'SP'
            });
            $state.go('organizations.agency');
        };


        /**
         * Called by Staff to create application form
         */
        $scope.createApplication = function() {
            var ciprid = $scope.roleContext.ciprid;
            var licclass = $scope.class.licenseclass;
            var licensedescr = $scope.class.descr;
            var agencyid = $scope.agency ? $scope.agency.agencyid : '';
            var agencyname = $scope.agency ? $scope.agency.agencyname : '';
            var sponsorid = $scope.sponsor && $scope.class.sponsorrequired === 'Y' ? $scope.sponsor.sponsorid : null;
            var sponsorname = $scope.sponsor && $scope.class.sponsorrequired === 'Y' ? $scope.sponsor.sponsorname : null;
            var insureremployee = $scope.insureremployee;
            var expedited = $scope.expedited;
            var transferredFromLicenseId = $scope.isTransfer === 'Y' && $scope.transferredFromLicense ? $scope.transferredFromLicense.licenseid : null;
            transferredFromLicenseId = $scope.isReplace === 'Y' && $scope.replaceLicense ? $scope.replaceLicense.licenseid : transferredFromLicenseId;
            var isdrlicense = $scope.class.isdrlicense;
            var corpLicenseId = !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licenseid : null;
            var corpLicenseClass = $scope.class.corp_liccls_cd;
            var employeecountcd = $scope.isTransfer === 'Y' && $scope.transferredFromLicense ? $scope.transferredFromLicense.employeecountcd : null;
            employeecountcd = $scope.isReplace === 'Y' && $scope.replaceLicense ? $scope.replaceLicense.employeecountcd : employeecountcd;
            store.remove("application_agency_create");
            if(!$scope.party) {
                toastr.error('Error!', 'License Holder is required.');
                return;
            }
            if($scope.party && !$scope.party.partyid) {
                toastr.error('Error!', 'License Holder does not exists in system.');
                return;
            }
            ;

            if($scope.agency && !agencyid) {
                toastr.error('Error!', 'The Agency does not exists in system.');
                return;
            }
            ;

            if($scope.sponsor && $scope.class.sponsorrequired === 'Y' && !sponsorid) {
                toastr.error('Error!', 'The Sponsor does not exists in system.');
                return;
            }
            ;

            var confirmoptions = {
                licensedescr: licensedescr,
                licenseholdername: $scope.party.name,
                sponsorrequired: $scope.class.sponsorrequired,
                agencyname: agencyname,
                sponsorname: sponsorname,
                insureremployee: insureremployee,
                expedited: expedited,
                isdrlicense: $scope.class.isdrlicense,
                corpLicense: $scope.corpLicense
            };

            var onsuccess = function(res) {
                toastr.success('Success!', 'Application has been created.');
                $state.go('appl.open.edit', {appId: res.data.licenseid});
            };
            var onerror = function(data, status) {
                $scope.createApplicationInProgress = false;
                toastr.error('Error!', 'Application cannot be created at this time.');
            };

            ApplicationService.checkParams(ciprid, licclass, agencyid, sponsorid, insureremployee, transferredFromLicenseId ? 'Y' : 'N', $scope.party.partyid)
            .then(function(res) {
                $scope.creationErrors = [];
                if(!_.isEmpty(res.data)) {
                    $scope.creationErrors = res.data;
                    return;
                }

                dialogs.create('applications/dialogs/init.html', 'InitDialogCtrl', confirmoptions, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function() {
                    $scope.createApplicationInProgress = true;
                    ApplService.create($scope.party.partyid, licclass, agencyid, sponsorid, insureremployee, null, transferredFromLicenseId, expedited, isdrlicense, corpLicenseId, corpLicenseClass, employeecountcd)
                    .then(onsuccess, onerror);
                });
            });
        };

        $scope.isEmptyDRLicense = function(cls) {
            return _.isEmpty($scope.drLicenses) || !_.includes(_.map($scope.drLicenses, "insuranceclass"), cls.insclass.trim());
        };

        $scope.isGeneralOrAdjusterLicense = function() {
            return _.includes(['C', 'D'], $scope.class.insclass.trim());
        }
    }
])
.controller('ApplOpenCtrl', ['$scope', '$state', '$stateParams', '$window', 'dialogs', 'promisedLicense', 'promisedCorpLicense', 'ApplicationService',
    function($scope, $state, $stateParams, $window, dialogs, promisedLicense, promisedCorpLicense, ApplicationService) {

        $scope.license = promisedLicense;
        $scope.corpLicense = promisedCorpLicense;

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid || !!license.payableid;
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) <= 0;
        };

        $scope.isWithCandidate = function(license) {
            return ApplicationService.isWithCandidate(license);
        };

        $scope.isWithApprover = function(license) {
            return ApplicationService.isWithApprover(license);
        };

        $scope.isWithRegulator = function(license) {
            return ApplicationService.isWithRegulator(license);
        };

        $scope.isSentBackFromRegulator = function(license) {
            return ApplicationService.isSentBackFromRegulator(license);
        };

        $scope.isCorpApp = function(license) {
            return ApplicationService.isCorpApp(license);
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isDRApp = function(license) {
            return ApplicationService.isDRApp(license);
        };

        $scope.isCorpLicenseExists = function(license) {
            return ApplicationService.isCorpLicenseExists(license);
        };

        $scope.isApplication = function(license) {
            return ApplicationService.isApplication(license);
        };

        $scope.isRecommendationRequired = function(license) {
            return $scope.isStaff() && license.recommendations && license.recommendations.length > 0
        };

        $scope.isDeclarationRequired = function(license) {
            return $scope.isStaff() && license.declarations && license.declarations.length > 0
        };

        /**
         * Called when 'Delete' is clicked. Abandons application.
         * @param license
         */
        $scope.deleteApplication = function(license) {
            var copy = {
                license: license.license,
                licensedescr: license.licensedescr,
                corplicense: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.license : '',
                corplicensedescr: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licensedescr : ''
            };
            dialogs.create('applications/dialogs/delete.html', 'DeleteDialogCtrl', copy, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                if(ApplicationService.isDRApp(license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                    ApplicationService.discard($scope.roleContext.ciprid, license.licenseid)
                    .then(function() {
                        ApplicationService.discard($scope.roleContext.ciprid, $scope.corpLicense.licenseid)
                        .then(function(results) {
                            toastr.success('Success!', 'Applications ' + license.license + ' and ' + $scope.corpLicense.license + ' has been deleted.');
                            $window.history.back();
                        })
                    });
                } else {
                    ApplicationService.discard($scope.roleContext.ciprid, license.licenseid)
                    .then(function(data) {
                        toastr.success('Success!', 'Application ' + license.license + ' has been deleted.');
                        $window.history.back();
                    });
                }
            });
        };

        $scope.can = {
            address: {
                view: true,
                edit: $scope.isStaff()
            },
            employment: {
                view: true,
                edit: $scope.isStaff()
            },
            eando: {
                view: true,
                edit: $scope.isStaff() && ApplicationService.isIncomplete($scope.license)
            },
            questions: {
                view: true,
                edit: false
            },
            declarations: {
                view: $scope.isStaff(),
                edit: false
            },
            recommendations: {
                view: false,
                edit: false
            },
            notes: {
                view: false,
                edit: false
            },
            submit: {
                view: false,
                edit: false
            }
        };

    }
])
.controller('ApplSnapshotCtrl', ['$scope', '$state', '$stateParams', 'promisedLicense', 'ApplicationService',
    function($scope, $state, $stateParams, promisedLicense, ApplicationService) {

        $scope.license = promisedLicense;
        $scope.corpLicense = {};

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid || !!license.payableid;
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) <= 0;
        };

        $scope.isWithCandidate = function(license) {
            return ApplicationService.isWithCandidate(license);
        };

        $scope.isWithApprover = function(license) {
            return ApplicationService.isWithApprover(license);
        };

        $scope.isWithRegulator = function(license) {
            return ApplicationService.isWithRegulator(license);
        };

        $scope.isSentBackFromRegulator = function(license) {
            return ApplicationService.isSentBackFromRegulator(license);
        };

        $scope.isCorpApp = function(license) {
            return ApplicationService.isCorpApp(license);
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isDRApp = function(license) {
            return ApplicationService.isDRApp(license);
        };

        $scope.isCorpLicenseExists = function(license) {
            return ApplicationService.isCorpLicenseExists(license);
        };

        $scope.isApplication = function(license) {
            return ApplicationService.isApplication(license);
        };

        $scope.isRecommendationRequired = function(license) {
            return $scope.isStaff() && license.recommendations && license.recommendations.length > 0
        };

        $scope.isDeclarationRequired = function(license) {
            return $scope.isStaff() && license.declarations && license.declarations.length > 0
        };


        $scope.can = {
            address: {
                view: true,
                edit: false
            },
            employment: {
                view: true,
                edit: false
            },
            eando: {
                view: true,
                edit: false
            },
            questions: {
                view: true,
                edit: false
            },
            declarations: {
                view: $scope.isStaff(),
                edit: false
            },
            recommendations: {
                view: false,
                edit: false
            },
            notes: {
                view: false,
                edit: false
            },
            submit: {
                view: false,
                edit: false
            }
        };

    }
])
.controller('ApplViewCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'ApplicationService',
    function($scope, $state, $stateParams, dialogs, toastr, ApplicationService) {
        if($scope.license.licensetype === 'C') {
            toastr.warning('Warning!', 'Please open D/R Application associated with it.');
            $state.go('appl.list');
            return;
        }
        ;

    }
])
.controller('ApplSnapshotViewCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'ApplicationService',
    function($scope, $state, $stateParams, dialogs, toastr, ApplicationService) {


    }
])
.controller('ApplEditCtrl', ['$scope', '$state', '$stateParams', '$location', '$anchorScroll', '$timeout', '$q', 'dialogs', 'toastr', 'blockUI', 'ApplicationService', 'ApplService',
    function($scope, $state, $stateParams, $location, $anchorScroll, $timeout, $q, dialogs, toastr, blockUI, ApplicationService, ApplService) {

        $scope.submitted = false;
        $scope.licenseValidationErrors = [];
        $scope.licenseToSave = _.cloneDeep($scope.license);
        $scope.corpLicenseToSave = _.cloneDeep($scope.corpLicense);
        var applicationBlock = blockUI.instances.get('application-edit-block');
        if($scope.license.licensetype === 'C') {
            toastr.warning('Warning!', 'Please open D/R Application associated with it.');
            $state.go('appl.mylist');
            return;
        }
        ;


        if(!$scope.isStaff() || !ApplicationService.isIncomplete($scope.license)) {
            $state.go('appl.open.view', {appId: $scope.license.licenseid});
            return;
        }

        /**
         * Show EO form if license.eorequired is "Y"
         */
        $scope.isEORequired = function() {
            return $scope.license.eorequired == "Y";
        };

        /**
         * Saves application (even with errors)
         */
        $scope.saveApplication = function() {
            if(ApplicationService.isDRApp($scope.license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense))
                return saveDRCorpApplication();

            updateLicense($scope.licenseToSave);


            ApplicationService.saveApplication($scope.roleContext.ciprid, $scope.licenseToSave)
            .then(function() {
                if($scope.licenseToSave.invoiceid) {
                    toastr.success('Saved', 'Application is saved and added to Invoice ' + $scope.license.invoiceid);
                    $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                } else {
                    dialogs.notify('Saved!', 'Application ' + $scope.license.license + ' has been saved.', {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    }).result.then(function(btn) {
                        $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                    });
                }
            });
        };

        var recommendationRequired = function(license) {
            return $scope.isStaff() && license.recommendations && license.recommendations.length > 0
        };

        function saveDRCorpApplication() {
            updateLicense($scope.licenseToSave);

            // copy address
            //$scope.corpLicense.address = $scope.license.address;

            //copy payment/invoice info
            $scope.corpLicense.paidby = $scope.license.paidby;
            $scope.corpLicense.invoice = $scope.license.invoice;

            // copy E&Os
            copyeandos($scope.license, $scope.corpLicense);

            updateCorpLicense($scope.corpLicenseToSave);

            ApplicationService.saveApplication($scope.roleContext.ciprid, $scope.licenseToSave)
            .then(function() {
                ApplicationService.saveApplication($scope.roleContext.ciprid, $scope.corpLicenseToSave)
                .then(function() {
                    if($scope.licenseToSave.invoiceid) {
                        toastr.success('Saved', 'Applications are saved and added to Invoice ' + $scope.license.invoiceid);
                        $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                    } else {
                        dialogs.notify('Saved!', 'Applications ' + $scope.license.license + ' and ' + $scope.corpLicense.license + ' has been saved.', {
                            'keyboard': false,
                            'backdrop': 'static',
                            'size': 'lg'
                        }).result.then(function(btn) {
                            $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                        });
                    }
                })
            });
        }

        var hasErrors = function(validationErrors) {
            return !_.isEmpty(validationErrors.eandoErrors)
            || !_.isEmpty(validationErrors.questionErrors)
            || !_.isEmpty(validationErrors.occupationErrors)
            // || !_.isEmpty(validationErrors.employmentErrors)
            || !_.isEmpty(validationErrors.addressErrors)
            || !_.isEmpty(validationErrors.crimcheckErrors)
            || !_.isEmpty(validationErrors.paymentErrors);
        };

        var scrollToErrors = function(validationErrors) {
            var scrollto = '';
            if(!_.isEmpty(validationErrors.addressErrors)) scrollto = 'address-portlet';
            else if(!_.isEmpty(validationErrors.crimcheckErrors)) scrollto = 'crimcheck-portlet';
            else if(!_.isEmpty(validationErrors.eandoErrors)) scrollto = 'eo-portlet';
            // else if(!_.isEmpty(validationErrors.employmentErrors)) scrollto = 'employment-portlet';
            else if(!_.isEmpty(validationErrors.questionErrors)) scrollto = 'questions-portlet';
            else if(!_.isEmpty(validationErrors.occupationErrors)) scrollto = 'questions-portlet';
            else if(!_.isEmpty(validationErrors.paymentErrors)) scrollto = 'payment-portlet';
            else if(!_.isEmpty(validationErrors.declarationErrors)) scrollto = 'declaration-portlet';
            else if(!_.isEmpty(validationErrors.recommendationErrors)) scrollto = 'recommendation-portlet';

            $location.hash(scrollto);
            $anchorScroll();
        };

        var validateDec = function(license) {
            var declarationErrors = [];
            if(!license.declarationDate) declarationErrors.push({msg: 'Please enter declaration date'});
            return {declarationErrors: declarationErrors};
        };

        var validateRec = function(license) {
            var recommendationErrors = [];
            if(!recommendationRequired(license)) return {recommendationErrors: recommendationErrors};
            if(!license.recommendationDate) recommendationErrors.push({msg: 'Please enter recommendation date'});
            if(!license.approvedbyid && license.approvers.length > 1) recommendationErrors.push({msg: 'Please select an recommender'});
            return {recommendationErrors: recommendationErrors};
        };

        /**
         * Submits application for approval.
         */
        $scope.submitApplication = function() {
            $scope.submitted = true;
            if(ApplicationService.isDRApp($scope.license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                return submitDRCorpApplication();
            }
            var copy = _.cloneDeep($scope.license);
            copy.questions = _.map(copy.questions, function(q) {
                return _.omit(q, 'uploader');
            });

            applicationBlock.start();
            ApplicationService.validateApplication($scope.roleContext.ciprid, copy)
            .then(function(res) {
                var validationErrors = res.data;
                _.extend(validationErrors, validateDec(copy), validateRec(copy));
                if(hasErrors(validationErrors)) {
                    $scope.licenseValidationErrors = validationErrors;
                    applicationBlock.stop();
                    var title = 'Incomplete Application';
                    var body = 'Your application is incomplete. Please enter missing information and submit your application again.';
                    dialogs.error(title, body, {'size': 'sm'}).result.then(function() {
                        scrollToErrors(validationErrors);
                    });
                } else {

                    updateLicense($scope.licenseToSave);
                    //update declarations
                    ApplService.submitToRegulator($scope.roleContext.ciprid, $scope.licenseToSave)
                    .then(function() {
                        applicationBlock.stop();
                        toastr.success('Success!', 'Application ' + $scope.licenseToSave.license + ' has been submitted to regulator.');
                        $state.go('appl.mylist');
                    });

                }

            });


        };

        var submitDRCorpApplication = function() {
            var copy = _.cloneDeep($scope.license);
            copy.questions = _.map(copy.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            // copy address
            // $scope.corpLicense.address = $scope.license.address;

            //copy payment/invoice info
            $scope.corpLicense.paidby = $scope.license.paidby;
            $scope.corpLicense.invoice = $scope.license.invoice;

            // copy E&Os
            copyeandos($scope.license, $scope.corpLicense);


            var copycorp = _.cloneDeep($scope.corpLicense);
            copycorp.questions = _.map(copycorp.questions, function(q) {
                return _.omit(q, 'uploader');
            });

            $q.all([ApplicationService.validateApplication($scope.roleContext.ciprid, copy), ApplicationService.validateApplication($scope.roleContext.ciprid, copycorp)])
            .then(function(results) {
                var validationErrors = results[0].data;
                _.extend(validationErrors, validateDec(copy), validateRec(copy));
                var validationCorpErrors = results[1].data;
                _.extend(validationErrors, validateDec(copy), validateRec(copy));
                if(hasErrors(validationErrors) || hasErrors(validationCorpErrors)) {
                    $scope.licenseValidationErrors = validationErrors;
                    $scope.corpLicenseValidationErrors = validationCorpErrors;

                    var title = 'Incomplete Application';
                    var body = 'Your application is incomplete. Please enter missing information and submit your application again.';
                    var crimcheckError = "You are required to submit criminal record check. <br> "
                    if(!_.isEmpty(results[0].data.crimcheckErrors)) {
                        body = body + '<br><br>' + ' You are required to submit criminal record check'
                        body = body + '<br>' + 'Click <a target="_blank" href="https://pages.sterlingbackcheck.ca/landing-pages/a/aic/">here</a> for BackCheck';
                        body = body + '<br>' + 'For Manual upload click criminal record check link beside criminal record check status on security clearance section.';
                    }
                    dialogs.error(title, body, {'size': 'sm'}).result.then(function() {
                        if(hasErrors(validationErrors)) scrollToErrors(validationErrors);
                        else scrollToErrors(validationCorpErrors);
                    });
                } else {
                    updateLicense($scope.licenseToSave);
                    updateCorpLicense($scope.corpLicenseToSave);
                    ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.licenseToSave)
                    .then(function() {
                        ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.corpLicenseToSave)
                        .then(function() {
                            toastr.success('Success!', 'Applications ' + $scope.licenseToSave.license + ' and ' + $scope.corpLicenseToSave.license + ' has been submitted to regulator.');
                            $state.go('appl.list');
                        });
                    });
                }
            });

        };

        function copyeandos(license, corpLicense) {
            var deleteclause = function(eando) {
                return _.has(eando, 'deleted') ? eando.deleted == true : false;
            };
            var deletedEandos = _.chain(license.eandos).filter(deleteclause).value();
            var newclause = function(eando) {
                return _.has(eando, 'new') ? eando.new == true : false;
            };
            var newEandos = _.chain(license.eandos).filter(newclause).value();

            _.forEach(deletedEandos, function(eando) {
                var e = _.find(corpLicense.eandos, {policyno: eando.policyno, carrierpartyid: eando.carrierpartyid});
                e.deleted = eando.deleted;
            });

            _.forEach(newEandos, function(eando) {
                var copyeando = _.cloneDeep(eando);
                copyeando.licenseid = $scope.corpLicense.licenseid;
                corpLicense.eandos.push(copyeando);
            });

        }

        function getApprover(license) {
            if(!recommendationRequired(license)) return undefined;
            return license.approvedbyid ? _.find(license.approvers, {partyId: license.approvedbyid}) : license.approvers.length == 1 ? license.approvers[0] : undefined;
        }

        function updateLicense(licenseToSave) {
            licenseToSave.address = $scope.license.address;

            // get the list of updated questions and declarations and empl history
            licenseToSave.questions = _.filter($scope.license.questions, function(obj) {
                return obj.answer === '' || !_.find(licenseToSave.questions, _.omit(obj, '$$hashKey'));
            });
            licenseToSave.questions = _.map(licenseToSave.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            licenseToSave.employmentHistory = _.filter($scope.license.employmentHistory, function(obj) {
                return !_.find(licenseToSave.employmentHistory, _.omit(obj, '$$hashKey'));
            });
            licenseToSave.eandos = _.filter($scope.license.eandos, function(obj) {
                return !_.find(licenseToSave.eandos, _.omit(obj, '$$hashKey'));
            });
            // get other occupation question
            var otherOccupaQuestion = _.filter($scope.license.questions, {hasotheroccupation: 'Y'});
            licenseToSave.occupations = [];
            if(otherOccupaQuestion && otherOccupaQuestion[0] && otherOccupaQuestion[0].answer != 'N') {
                licenseToSave.occupations = _.filter($scope.license.occupations, {selected: true});
            }
            _.forEach(licenseToSave.occupations, function(occupation) {
                occupation.answerseq = otherOccupaQuestion[0].answerid;
            });

            licenseToSave.approver = licenseToSave.approvedbyid !== $scope.license.approvedbyid || !$scope.license.approvedbyid ? getApprover($scope.license) : undefined;
            _.forEach($scope.licenseToSave.declarations, function(declaration) {
                declaration.response = 'Y';
                declaration.responsetimestamp = $scope.license.declarationDate;
            });
            _.forEach($scope.licenseToSave.recommendations, function(recommendation) {
                recommendation.response = 'Y';
                recommendation.responsetimestamp = $scope.license.recommendationDate;
            });

            licenseToSave.fee = $scope.license.fee;
            licenseToSave.paidby = $scope.license.paidby;
            licenseToSave.employeecountcd = $scope.license.employeecountcd;
            licenseToSave.forceyn = $scope.license.forceyn;
        }

        function updateCorpLicense(corpLicenseToSave) {
            corpLicenseToSave.address = $scope.corpLicense.address;
            // get the list of updated questions and declarations and empl history
            corpLicenseToSave.questions = _.filter($scope.corpLicense.questions, function(obj) {
                return obj.answer === '' || !_.find(corpLicenseToSave.questions, _.omit(obj, '$$hashKey'));
            });
            corpLicenseToSave.questions = _.map(corpLicenseToSave.questions, function(q) {
                return _.omit(q, 'uploader');
            });

            corpLicenseToSave.eandos = _.filter($scope.corpLicense.eandos, function(obj) {
                return !_.find(corpLicenseToSave.eandos, _.omit(obj, '$$hashKey'));
            });

            // get other occupation question
            var otherOccupaQuestion = _.filter($scope.corpLicense.questions, {hasotheroccupation: 'Y'});
            corpLicenseToSave.occupations = [];
            if(otherOccupaQuestion && otherOccupaQuestion[0] && otherOccupaQuestion[0].answer != 'N') {
                corpLicenseToSave.occupations = _.filter($scope.corpLicense.occupations, {selected: true});
            }
            _.forEach(corpLicenseToSave.occupations, function(occupation) {
                occupation.answerseq = otherOccupaQuestion[0].answerid;
            });

            corpLicenseToSave.approver = $scope.licenseToSave.approver;
            _.forEach($scope.corpLicenseToSave.declarations, function(declaration) {
                declaration.response = 'Y';
                declaration.responsetimestamp = $scope.license.declarationDate;
            });
            _.forEach($scope.corpLicenseToSave.recommendations, function(recommendation) {
                recommendation.response = 'Y';
                recommendation.responsetimestamp = $scope.license.recommendationDate;
            });

            corpLicenseToSave.fee = $scope.corpLicense.fee;
            corpLicenseToSave.paidby = $scope.corpLicense.paidby;
            corpLicenseToSave.drPartyId = $scope.license.licenseholderid;
        }

    }
])
/**
 * Template controllers
 */
.controller('ApplHeaderCtrl', ['$scope', '$state', '$stateParams', 'ApplicationService', 'ApplService',
    function($scope, $state, $stateParams, ApplicationService, ApplService) {
        $scope.orignalLicenseFee = $scope.license.fee;
        $scope.isAmendment = parseFloat($scope.license.fee) === 25 ? 'Y' : 'N';
        if($scope.isDRApp($scope.license) && $scope.isCorpLicenseExists($scope.corpLicense))
            $scope.orignalCorpLicenseFee = $scope.corpLicense.fee;
        /**
         * Initializers
         */
        ApplicationService.getLicenseFeeStructure()
        .then(function(res) {
            $scope.feeStructure = res.data;
            $scope.license.employeecountcd = _.find($scope.feeStructure, {scnrio_cd: $scope.license.employeecountcd.trim()});
        });

        // $scope.isAllowUpdateRestrictedApplicationFee = function(license) {
        //     return ApplicationService.isRestrictedCorpApp(license) && license.c_app_trn_status === 'New Application'
        // && !$scope.isApplicationPaid(license) && !ApplicationService.isInvoiced(license) &&
        // (ApplicationService.isWithCandidate(license) || ApplicationService.isIncomplete(license)); };

        $scope.isAllowUpdateFee = function(license) {
            //return $scope.isStaff() && !$scope.isApplicationPaid(license) && !ApplicationService.isInvoiced(license);
            return $scope.isStaff() && ApplicationService.isApplication(license);
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) <= 0;
        };

        /**
         * In case of restricted license, set fee using scenario code.
         */
        $scope.setRestrictedLicenseFee = function(license) {
            if($scope.isAmendment === 'Y')
                license.fee = "25.00";
            else
                license.fee = license.employeecountcd.amount;
        };

        $scope.setRestrictedLicFee = function($event, license) {
            var checkbox = $event.target;
            if(checkbox.checked)
                license.fee = "25.00";
            else
                license.fee = license.employeecountcd.amount;

        };

        $scope.applicationUpdateFeeInProgress = false;

        /**
         * fee edit
         */
        $scope.updateFee = function(license, LicenseFee, employeecountcd) {
            $scope.applicationUpdateFeeInProgress = true;
            var onsuccess = function(res) {
                $scope.applicationUpdateFeeInProgress = false;
                $scope.feeEditing = false;
                if(!_.isEmpty(res.data.error)) {
                    console.log(_.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "");
                    return toastr.error('Error!', 'License fee cannot be updated at this time. Please try later');
                }
                license.fee = LicenseFee;
                $scope.orignalLicenseFee = LicenseFee;
                toastr.success('Success!', 'License fee has been updated successfully.');
            };
            var onerror = function(res) {
                $scope.feeEditing = false;
                $scope.applicationUpdateFeeInProgress = false;
                toastr.error('Error!', 'License fee cannot be update at this time. Please try later');
            };
            ApplService.updateFee(license, LicenseFee, employeecountcd).then(onsuccess, onerror);
        };

        /**
         * fee edit
         */
        $scope.updateCorporateFee = function(license, LicenseFee) {
            $scope.corpApplicationUpdateFeeInProgress = true;
            var onsuccess = function(res) {
                $scope.corpApplicationUpdateFeeInProgress = false;
                $scope.corpFeeEditing = false;
                if(!_.isEmpty(res.data.error)) {
                    console.log(_.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "");
                    return toastr.error('Error!', 'Corporate license fee cannot be updated at this time. Please try later');
                }
                license.fee = LicenseFee;
                $scope.origanlCorpLicenseFee = LicenseFee;
                toastr.success('Success!', 'Corporate license fee has been updated successfully.');
            };
            var onerror = function(res) {
                $scope.corpFeeEditing = false;
                $scope.corpApplicationUpdateFeeInProgress = false;
                toastr.error('Error!', 'Corporate license fee cannot be updated at this time. Please try later');
            };
            ApplService.updateFee(license, LicenseFee, '').then(onsuccess, onerror);
        };


    }
])
.controller('ApplAddressCtrl', ['$scope', '$resource', '$http', 'toastr', 'dialogs', 'ApplicationService',
    function($scope, $resource, $http, toastr, dialogs, ApplicationService) {
        /**
         * Presents an edit dialog.
         * @param license
         */
        $scope.editAddress = function(license) {
            dialogs.create('address/dialogs/edit.html', 'AddressEditDialogCtrl', license.address, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(address) {
                var identical = angular.equals(license.address, address);
                license.address = address;
                license.address.tobesaved = !identical;
            });
        };

        $scope.copyAddress = function(license, corpLicense) {
            corpLicense.address = _.cloneDeep(license.address);
            corpLicense.address.tobesaved = true;
        }
    }
])
.controller('ApplEmploymentHistoryCtrl', ['$scope', '$state', '$http', '$filter',
    function($scope, $state, $http, $filter) {
        $scope.employment = {};
        $scope.employment.employed = 'Y';
        $scope.employment.employer = '';
        $scope.employment.positionheld = '';
        $scope.employment.fromdate = '';
        $scope.employment.todate = '';
        $scope.dateFormat = 'MM dd, yyyy';

        $scope.employmentForm = false;

        $scope.remove = function(employment) {
            employment.deleted = true;
        };

        $scope.save = function() {
            $scope.employment.fromdate = $filter('date')($scope.employment.fromdate, "yyyy-MM-dd");
            $scope.employment.todate = $scope.employment.todate ? $filter('date')($scope.employment.todate, "yyyy-MM-dd") : null;

            if(!$scope.license.employmentHistory) $scope.license.employmentHistory = [];
            $scope.license.employmentHistory.push($scope.employment);
            $scope.hideEmploymentForm();
        };

        $scope.update = function(employment) {
            employment.fromdate = $filter('date')(employment.fromdate, "yyyy-MM-dd");
            employment.todate = employment.todate ? $filter('date')(employment.todate, "yyyy-MM-dd") : null;

            $scope.license.employmentHistory = _.reject($scope.license.employmentHistory, {'partyemploymentid': employment.partyemploymentid});
            $scope.license.employmentHistory.push(employment);
        };

        $scope.showEmploymentForm = function() {
            $scope.employmentForm = true;
        };

        $scope.hideEmploymentForm = function() {
            $scope.employmentForm = false;
            $scope.employment = {};
            $scope.employment.fromdate = '';
            $scope.employment.todate = '';
        };
    }
])
.controller('ApplEandOCtrl', ['$scope', '$state', '$http', 'dialogs',
    function($scope, $state, $http, dialogs) {

        /**
         * Presents a dialog to add a new policy
         */
        $scope.addPolicy = function() {
            var data = {
                title: 'Add Errors and Omissions Coverage to the License: ' + $scope.license.license,
                insurancecategories: [$scope.license.insurancecategory]
            };
            dialogs.create('eando/dialogs/add.html', 'EandOAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                if(!$scope.license.eandos) $scope.license.eandos = [];
                $scope.license.eandos.push(eando);
            });
        };

        /**
         * Mark policy as 'Removed'
         * @param eando
         */
        $scope.removeEandO = function(eando) {
            eando.deleted = true;
            if(eando.new) {
                $scope.license.eandos = _.reject($scope.license.eandos, eando);
            }
        };

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };

    }
])
.controller('CriminalCheckCtrl', ['$scope', '$state', '$stateParams', '$http', 'toastr', 'ApplicationService', 'DownloadService',
    function($scope, $state, $stateParams, $http, toastr, ApplicationService, DownloadService) {
        /**
         * Constructs a download link
         * @param crimcheck
         * @returns {string}
         */
        $scope.downloadLink = function(crimcheck) {
            var anchorText = crimcheck.displayname;
            var anchorHref = S("/{{ciprid}}/crimcheck/{{crimcheckid}}/download/{{filename}}?party={{partyid}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: crimcheck.partyid,
                crimcheckid: crimcheck.id,
                filename: crimcheck.displayname
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };
    }
])
.controller('ApplQuestionsCtrl', ['$scope', '$state', '$stateParams', '$http', 'toastr', 'ApplicationService', 'DownloadService',
    function($scope, $state, $stateParams, $http, toastr, ApplicationService, DownloadService) {
        $scope.defaultOccupationDetails = function(occupation) {
            if(occupation.detailsrequired == 'Y') {
                ApplicationService.getPrevOtherOccupaDetail($scope.roleContext.ciprid, occupation)
                .then(function(res) {
                    if(!_.isEmpty(res.data)) {
                        occupation.details = res.data[0].details;
                    }
                });
            }
        };

        $scope.queshistory = function(q, license) {
            return _.filter(license.questionshistory, {answerid: q.answerid});
        }

        /**
         * Constructs a download link.
         * @param license
         * @param question
         * @param attachment
         * @returns {string}
         */
        $scope.downloadLink = function(license, question, attachment) {
            var anchorText = attachment.displayname;
            var anchorHref = S("/{{ciprid}}/licenses/{{licenseid}}/attachments/{{filename}}?questionnum={{questionnum}}&party={{partyid}}&questionpart={{questionpart}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: license.licenseholderid,
                licenseid: license.licenseid,
                filename: attachment.displayname,
                questionnum: question.questionnum,
                questionpart: question.questionpart
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };


        $scope.downloadSnapshotLink = function(license, question, attachment) {
            var anchorText = attachment.displayname;
            var anchorHref = S("/{{ciprid}}/licenses/{{licenseid}}/attachments/snapshot/{{filename}}?questionnum={{questionnum}}&party={{partyid}}&questionpart={{questionpart}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: license.licenseholderid,
                licenseid: license.licenseid,
                filename: attachment.displayname,
                questionnum: question.questionnum,
                questionpart: question.questionpart
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };


        $scope.downloadCorpLink = function(license, question, attachment, licenseholderid) {
            var anchorText = attachment.displayname;
            var anchorHref = S("/{{ciprid}}/licenses/{{licenseid}}/attachments/{{filename}}?questionnum={{questionnum}}&party={{partyid}}&questionpart={{questionpart}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: licenseholderid,
                licenseid: license.licenseid,
                filename: attachment.displayname,
                questionnum: question.questionnum,
                questionpart: question.questionpart
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Deletes an attachment
         * @param attachment
         */
        $scope.removeAttachment = function(question, attachment) {
            var ciprid = $scope.roleContext.ciprid;
            var licenseid = $scope.license.licenseid;
            var attachmentid = attachment.attachmentid;
            $http.delete('/' + ciprid + '/licenses/' + licenseid + '/attachments/' + attachmentid)
            .then(function(res) {
                toastr.success('Success!', 'File ' + attachment.displayname + ' has been removed.');
                $scope.license.attachments[question.answerid] = _.reject($scope.license.attachments[question.answerid], {'attachmentid': attachmentid});
            });
        };
    }
])
.controller('ApplPaymentCtrl', ['$scope', 'dialogs', 'toastr', 'ApplicationService',
    function($scope, dialogs, toastr, ApplicationService) {

        $scope.paidby = '';

        $scope.agentPays = function(license) {
            $scope.paidby = 'agent';
            $scope.license.paidby = $scope.paidby;
        };

        $scope.agencyPays = function(license) {
            $scope.paidby = 'agency';
            $scope.license.paidby = $scope.paidby;
        };

        $scope.sponsorPays = function(license) {
            $scope.paidby = 'sponsor';
            $scope.license.paidby = $scope.paidby;
        };

        $scope.isCorpApp = function(license) {
            return ApplicationService.isCorpApp(license);
        };

        this.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isAgentPaying = function() {
            return $scope.paidby == 'agent';
        };

        $scope.isAgencyPaying = function() {
            return $scope.paidby == 'agency';
        };

        $scope.isSponsorPaying = function() {
            return $scope.paidby == 'sponsor';
        };

        $scope.isInvoicedToContextUser = function(application) {
            return _.isObject(application.invoice);
        };

        $scope.isOnAgentInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.licenseholderid;
        };

        $scope.isOnAgencyInvoice = function(application) {
            return (_.isObject(application.invoice) && application.invoice.ownerpartyid == application.agencyid) || (_.isObject(application.payable) && application.payable.ownerpartyid == application.agencyid);
        };

        $scope.isOnSponsorInvoice = function(application) {
            return (_.isObject(application.invoice) && application.invoice.ownerpartyid == application.sponsorid) || (_.isObject(application.payable) && application.payable.ownerpartyid == application.sponsorid);
        };

        $scope.isInvoiceQueued = function(application) {
            return application.invoice.statuscd == 'INVPRC';
        };

        function showConfirmDialog(payoptions) {
            dialogs.create('applications/dialogs/payment.html', 'PaymentDialogCtrl', payoptions, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                ApplicationService.savePaymentOption($scope.roleContext.ciprid, payoptions.licenseid)
                .then(function(res) {
                    toastr.success('Success!', 'Application ' + payoptions.license + ' is invoiced to ' + payoptions.name);
                    $state.go('application.open.view', {appId: payoptions.licenseid});
                });
            });
        }
    }
])
.controller('ApplDeclarationCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', 'toastr', 'ApplicationService',
    function($scope, $state, $stateParams, $http, $filter, toastr, ApplicationService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.setDeclarationDate = function(selectedDate) {
            $scope.license.declarationDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };
    }
])
.controller('ApplRecommendationCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', 'toastr', 'ApplicationService',
    function($scope, $state, $stateParams, $http, $filter, toastr, ApplicationService) {

        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.setRecommendationDate = function(selectedDate) {
            $scope.license.recommendationDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

    }
])
.controller('ApplReviewerCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'ReviewService',
    function($scope, $state, $stateParams, dialogs, toastr, ReviewService) {
        $scope.staffreviewer = undefined;
        $scope.mgmtreviewer = undefined;
        /**
         * Initializers
         */
        ReviewService.getReviewers($scope.license.licenseid)
        .then(function(res) {
            var data = _.isArray(res.data) ? res.data[0] : res.data;
            $scope.staffreviewer = data.staffreviewer;
            $scope.mgmtreviewer = data.mgmtreviewer;
            $scope.councilreviewer = data.councilreviewer;
        });
    }
])
.controller('ApplReviewCommentsCtrl', ['$scope', '$state', '$stateParams', '$q', 'dialogs', 'toastr', 'ReviewService', 'ReviewCommentService',
    function($scope, $state, $stateParams, $q, dialogs, toastr, ReviewService, ReviewCommentService) {

        $scope.comment = '';
        $scope.commentHistory = [];
        $scope.currentUserCommentHistory = [];

        /**
         * Initializers
         */
        ReviewService.getCommentHistory($scope.license.licenseid)
        .then(function(res) {
            var data = _.isArray(res.data) ? res.data[0] : res.data;
            $scope.commentHistory = _.isEmpty(data.comments) ? [] : JSON.parse(data.comments);
            $scope.staffreviewer = data.staffreviewer;
            $scope.mgmtreviewer = data.mgmtreviewer;
        });

        // -- called from form

        $scope.isUnderReview = function(license) {
            return license.reviewstatuscd == 'LIC_REV_REVIEW';
        };
    }
])
.controller('ApplSubmitDialogCtrl', ['$scope', '$uibModalInstance', '$filter', 'data',
    function($scope, $uibModalInstance, $filter, data) {

        $scope.agreed = false;
        $scope.declarations = data;
        $scope.declarationDate = moment().format("MM DD, YYYY");
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.setDeclarationDate = function(selectedDate) {
            $scope.declarationDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.agreed = true;
            $uibModalInstance.close($scope.declarationDate);
        };
    }
])
.controller('ApplPayerDialogCtrl', ['$scope', '$uibModalInstance', 'data', 'ApplicationService',
    function($scope, $uibModalInstance, data, ApplicationService) {

        $scope.license = data.application;
        $scope.paidby = '';

        $scope.agentPays = function(license) {
            $scope.paidby = 'agent';
            $scope.license.paidby = $scope.paidby;
        };

        $scope.agencyPays = function(license) {
            $scope.paidby = 'agency';
            $scope.license.paidby = $scope.paidby;
        };

        $scope.sponsorPays = function(license) {
            $scope.paidby = 'sponsor';
            $scope.license.paidby = $scope.paidby;
        };

        $scope.isCorpApp = function(license) {
            return ApplicationService.isCorpApp(license);
        };

        this.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isAgentPaying = function() {
            return $scope.paidby == 'agent';
        };

        $scope.isAgencyPaying = function() {
            return $scope.paidby == 'agency';
        };

        $scope.isSponsorPaying = function() {
            return $scope.paidby == 'sponsor';
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.license);
        };

    }
])
/** Services **/

.service('PartyResolverService', ['$resource', '$http', 'PartyService',
    function($resource, $http, PartyService) {

        /**
         * Returns a promise which resolves a party object.
         *
         * @param ciprid
         * @param partyid
         * @returns {*}
         */
        this.get = function(ciprid, partyid) {
            return $http.get('/staff/parties/' + partyid, {cache: true})
            .then(function(res) {

                var party = res.data.party[0];
                party.profile = res.data.profile;
                //party.examResults = res.data.examResults;
                party.accounts = res.data.accounts;
                party.licenses = res.data.licenses[0];
                party.invoices = res.data.invoices[0];
                party.exams = res.data.exams[0];
                party.employmentHistory = res.data.employmentHistory;
                //party.eandos = res.data.eandos;

                return party;
            });
        };
    }
])
.service('ApplResolverService', ['$resource', '$http', 'FileUploader', 'ApplicationService',
    function($resource, $http, FileUploader, ApplicationService) {
        /**
         * Returns a promise which resolves a license object.
         *
         * @param jwtToken
         * @param ciprid
         * @param licenseid
         * @returns {*}
         */
        this.get = function(jwtToken, ciprid, licenseid) {
            return $http.get('/' + ciprid + '/licenses/' + licenseid + '/application')
            .then(function(res) {
                var limitFilter = {
                    name: 'limitFilter',
                    fn: function() {
                        return this.queue.length < 10;
                    }
                };
                var pdfFilter = {
                    name: 'pdfFilter',
                    fn: function(item) {
                        return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
                    }
                };

                var license = res.data.license[0];

                license.address = ApplicationService.copyAddress(license);
                license.crimcheck = _.isArray(res.data.crimcheck) ? res.data.crimcheck[0] : res.data.crimcheck;
                license.displayCrimCheck = res.data.displaycrimcheck[0];
                license.questions = res.data.questions;
                license.questions = _.map(res.data.questions, function(q, index) {
                    q.index = index + 1;
                    q.uploader = new FileUploader({
                        autoUpload: true,
                        filters: [limitFilter, pdfFilter],
                        headers: {
                            Authorization: 'Bearer ' + jwtToken
                        }
                    });
                    return q;
                });

                license.attachments = _.groupBy(res.data.attachments, 'answerid');
                license.occupations = _.uniqBy(res.data.occupations, 'otheroccupationcode');
                license.declarations = res.data.declarations.length == 0 ? [] : res.data.declarations;
                var firstDecl = _.first(license.declarations);
                license.declarationDate = firstDecl != undefined ? firstDecl.responsetimestamp : undefined;
                license.recommendations = res.data.recommendations;
                var firstRecommendation = _.first(license.recommendations);
                license.recommendationDate = firstRecommendation != undefined ? firstRecommendation.responsetimestamp : undefined;
                license.employmentHistory = res.data.employmentHistory;
                license.eandos = res.data.eandos;
                license.invoice = _.isArray(res.data.invoice) ? res.data.invoice[0] : res.data.invoice;
                license.payable = _.isArray(res.data.payable) ? res.data.payable[0] : res.data.payable;

                license.permissions = {
                    application: {
                        approve: res.data.permissions.canapprove,
                        sendbacktoagent: res.data.permissions.canreview
                    },
                    recommendations: {
                        view: res.data.permissions.canapprove,
                        edit: res.data.permissions.canapprove
                    }
                };
                license.approvers = res.data.approvers;

                license.corpLicense = _.isArray(res.data.corpLicense) ? res.data.corpLicense[0] : res.data.corpLicense;

                _.forEach(license.occupations, function(occupation) {
                    occupation.selected = !_.isEmpty(occupation.answerseq);
                });

                return license;
            });
        };


        /**
         * Returns a promise which resolves a license object.
         *
         * @param jwtToken
         * @param ciprid
         * @param licenseid
         * @returns {*}
         */
        this.getSnapshot = function(jwtToken, licenseid) {
            return $http.get('/staff/lic/' + licenseid + '/application')
            .then(function(res) {
                var limitFilter = {
                    name: 'limitFilter',
                    fn: function() {
                        return this.queue.length < 10;
                    }
                };
                var pdfFilter = {
                    name: 'pdfFilter',
                    fn: function(item) {
                        return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
                    }
                };

                var license = res.data.license[0];

                license.address = ApplicationService.copyAddress(license);
                license.crimcheck = _.isArray(res.data.crimcheck) ? res.data.crimcheck[0] : res.data.crimcheck;
                license.displayCrimCheck = res.data.displaycrimcheck[0];
                license.questions = res.data.questions;
                license.questions = _.map(res.data.questions, function(q, index) {
                    q.index = index + 1;
                    q.uploader = new FileUploader({
                        autoUpload: true,
                        filters: [limitFilter, pdfFilter],
                        headers: {
                            Authorization: 'Bearer ' + jwtToken
                        }
                    });
                    return q;
                });

                license.attachments = _.groupBy(res.data.attachments, 'answerid');
                license.occupations = _.uniqBy(res.data.occupations, 'otheroccupationcode');
                license.declarations = res.data.declarations.length == 0 ? [] : res.data.declarations;
                var firstDecl = _.first(license.declarations);
                license.declarationDate = firstDecl != undefined ? firstDecl.responsetimestamp : undefined;
                license.recommendations = res.data.recommendations;
                var firstRecommendation = _.first(license.recommendations);
                license.recommendationDate = firstRecommendation != undefined ? firstRecommendation.responsetimestamp : undefined;
                license.employmentHistory = res.data.employmentHistory;
                license.eandos = res.data.eandos;
                license.invoice = _.isArray(res.data.invoice) ? res.data.invoice[0] : res.data.invoice;
                license.payable = _.isArray(res.data.payable) ? res.data.payable[0] : res.data.payable;

                license.permissions = {
                    application: {
                        approve: false,
                        sendbacktoagent: false
                    },
                    recommendations: {
                        view: true,
                        edit: false
                    }
                };

                _.forEach(license.occupations, function(occupation) {
                    occupation.selected = !_.isEmpty(occupation.answerseq);
                });

                return license;
            });
        };


    }
])
.service('ApplService', ['$resource', '$http',
    function($resource, $http) {
        this.create = function(forPartyId, licenseClass, agencyId, sponsorId, insureremployee, invoiceId, transferredFromLicenseId, expedited, isdrlicense, corpLicenseId, corpLicenseClass, employeecountcd) {
            if(agencyId && licenseClass in licClassMap) licenseClass = licClassMap[licenseClass];

            return $http.put('/staff/lic/', {
                forPartyId: forPartyId,
                licenseclass: licenseClass,
                agencyid: agencyId,
                sponsorid: sponsorId,
                insureremployee: insureremployee,
                invoiceid: invoiceId,
                transferredFromLicenseId: transferredFromLicenseId,
                expedited: expedited,
                isdrlicense: isdrlicense,
                corpLicenseId: corpLicenseId,
                corpLicenseClass: corpLicenseClass,
                employeecountcd: employeecountcd
            });
        };

        this.getApplications = function(ciprId) {
            return $http.get('/' + ciprId + '/licenses/staff/applications', {cache: false});
        };

        this.getMyApplications = function(ciprId) {
            return $http.get('/' + ciprId + '/licenses/staff/myapplications', {cache: false});
        };

        this.submitToRegulator = function(ciprId, license) {
            //update the status
            license.submitTo = 'regulator';

            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/submittoregulator', license);
        };

        this.getSuspendReasons = function() {
            return $http.get('/staff/lic/suspendreasons', {cache: true});
        };

        this.suspendLicenses = function(licenseids, reasonId) {
            return $http.post('/staff/lic/suspendlicenses', {
                licenseids: licenseids,
                reasonId: reasonId
            });
        };

        this.suspendCorpLicenses = function(licenseids, reasonId, expiryDate) {
            return $http.post('/staff/lic/suspendcorp', {
                licenseids: licenseids,
                reasonId: reasonId,
                suspendExpiryDate: expiryDate
            });
        };
        this.approveSuspendCorpLicenses = function(licenseids, reasonId) {
            return $http.post('/staff/lic/approvesuspendcorp', {
                licenseids: licenseids
            });
        };

        this.removeApproveSuspendCorpLicenses = function(licenseids) {
            return $http.post('/staff/lic/removeapprovesuspendcorp', {
                licenseids: licenseids
            });
        };

        this.terminationLetters = function(licenseIds) {
            return $http.post('/staff/lic/terminationLetters', {
                licenseIds: licenseIds
            });
        };

        this.checkReverse = function(licenseid, reason) {
            return $http.get('/staff/lic/checkReverse', {
                params: {
                    licenseid: licenseid,
                    reason: reason
                }
            });
        };

        this.reverseLicense = function(licenseid, reason) {
            return $http.post('/staff/lic/' + licenseid + '/reverse', {
                reason: reason
            });
        };

        this.savePayment = function(license) {
            return $http.post('/staff/lic/' + license.licenseid + '/payment', license);
        };

        this.setAssignedTo = function(userCIPRId, applicationIds) {
            return $http({
                url: '/staff/lic/assigntouser',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {userCIPRId: userCIPRId, applicationIds: applicationIds}
            });
        };

        this.updateFee = function(license, licenseFee, employeecountcd) {
            return $http.post('/staff/lic/' + license.licenseid + '/fee', {
                licenseFee: licenseFee,
                employeecountcd: employeecountcd
            });
        };

        this.getRenewalGroups = function() {
            return $http.get('/staff/lic/renewalgroups', {cache: true});
        };

        this.generateRenewals = function(partyId, renewalGroup) {
            return $http.post('/staff/lic/generaterenewals', {
                partyId: partyId,
                renewalGroup: renewalGroup
            });
        };

    }
]);
