var reviews = angular.module('aic.reviews', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.invoices',
    'aic.subscribe',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

/**
 * Routing and config
 */
reviews.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('reviews', {
            parent: 'workspace',
            abstract: true,
            url: '/reviews',
            templateUrl: 'reviews/reviews.html'
        })
        .state('reviews.staff', {
            url: '/staff',
            params: {
                cache: true
            },
            templateUrl: 'reviews/staff/list.html',
            controller: 'ReviewStaffCtrl'
        })
        .state('reviews.mgmt', {
            url: '/management',
            params: {
                cache: true
            },
            templateUrl: 'reviews/mgmt/list.html',
            controller: 'ReviewMgmtCtrl'
        })
        .state('reviews.council', {
            url: '/council',
            params: {
                cache: true
            },
            templateUrl: 'reviews/council/list.html',
            controller: 'ReviewCouncilCtrl'
        })
        .state('reviews.open', {
            url: '/{licenseid:[0-9]+}/:corplicenseid',
            abstract: true,
            resolve: {
                promisedLicense: ['$stateParams', 'AuthService', 'ReviewResolverService',
                    function($stateParams, AuthService, ReviewResolverService) {
                        var ciprid = AuthService.getRoleContext().ciprid;
                        var licenseid = $stateParams.licenseid;
                        var resolved = ReviewResolverService.get(ciprid, licenseid);
                        return resolved;
                    }
                ],
                promisedCorpLicense: ['$stateParams', 'AuthService', 'promisedLicense', 'ReviewResolverService',
                    function($stateParams, AuthService, promisedLicense, ReviewResolverService) {
                        var ciprid = AuthService.getRoleContext().ciprid;
                        var corplicenseid = $stateParams.corplicenseid;
                        if(promisedLicense.drlicense === 'Y' && corplicenseid)
                            return ReviewResolverService.get(ciprid, corplicenseid);
                        return {};
                    }
                ]
            },
            controller: 'ReviewOpenCtrl',
            template: '<div ui-view></div>'
        })
        .state('reviews.open.application', {
            url: '/edit',
            views: {
                '': {
                    templateUrl: 'reviews/reviews.application.html',
                    controller: 'ReviewApplicationCtrl'
                },
                'header@reviews.open.application': {
                    templateUrl: 'staff/appl/header/header.html',
                    controller: 'ReviewHeaderCtrl'
                },
                'address@reviews.open.application': {
                    templateUrl: 'applications/address/address.view.html'
                },
                'eo@reviews.open.application': {
                    templateUrl: 'applications/eo/eo.view.html',
                    controller: 'ReviewEandOCtrl'
                },
                'employment@reviews.open.application': {
                    templateUrl: 'applications/employment/employment.history.view.html'
                },
                'crimcheck@reviews.open.application': {
                    templateUrl: 'applications/crimcheck/crimcheck.view.html',
                    controller: 'CriminalCheckCtrl'
                },
                'qa@reviews.open.application': {
                    templateUrl: 'applications/qa/qa.history.view.html',
                    controller: 'ReviewQuestionCtrl'
                },
                'recommendation@reviews.open.application': {
                    templateUrl: 'reviews/application/recommendation/recommendation.edit.html',
                    controller: 'ReviewApplicationRecommendationCtrl'
                },
                'reviewer@reviews.open.application': {
                    templateUrl: 'reviews/reviewers.html',
                    controller: 'ReviewerCtrl'
                },
                'comments@reviews.open.application': {
                    templateUrl: 'reviews/actions/comments.html',
                    controller: 'ReviewCommentsCtrl'
                },
                'buttons@reviews.open.application': {
                    templateUrl: 'reviews/actions/buttons.html',
                    controller: 'ReviewActionsCtrl'
                }
            }
        })
        .state('reviews.open.renewal', {
            url: '/view',
            views: {
                '': {
                    templateUrl: 'reviews/reviews.renewal.html',
                    controller: 'ReviewRenewalCtrl'
                },
                'header@reviews.open.renewal': {
                    templateUrl: 'applications/header/header.html'
                },
                'address@reviews.open.renewal': {
                    templateUrl: 'applications/address/address.view.html'
                },
                'eo@reviews.open.renewal': {
                    templateUrl: 'applications/eo/eo.view.html',
                    controller: 'ReviewEandOCtrl'
                },
                //'crimcheck@review.open.view': {
                //    templateUrl: 'applications/crimcheck/crimcheck.view.html',
                //    controller:'CriminalCheckCtrl'
                //},
                'qa@reviews.open.renewal': {
                    templateUrl: 'applications/qa/qa.history.view.html',
                    controller: 'ReviewQuestionCtrl'
                },
                'reviewer@reviews.open.renewal': {
                    templateUrl: 'reviews/reviewers.html',
                    controller: 'ReviewerCtrl'
                },
                'comments@reviews.open.renewal': {
                    templateUrl: 'reviews/actions/comments.html',
                    controller: 'ReviewCommentsCtrl'
                },
                'buttons@reviews.open.renewal': {
                    templateUrl: 'reviews/actions/buttons.html',
                    controller: 'ReviewActionsCtrl'
                }
            }
        });
    }
])

/**
 * Staff routes controllers
 */
.controller('ReviewHeaderCtrl', ['$scope', '$state', '$stateParams', 'ApplicationService', 'ApplService',
    function($scope, $state, $stateParams, ApplicationService, ApplService) {
        $scope.orignalLicenseFee = $scope.license.fee;
        $scope.isAmendment = parseFloat($scope.license.fee) === 25 ? 'Y' : 'N';
        if($scope.isDRApp($scope.license) && $scope.isCorpLicenseExists($scope.corpLicense))
            $scope.orignalCorpLicenseFee = $scope.corpLicense.fee;
        /**
         * Initializers
         */
        ApplicationService.getLicenseFeeStructure()
        .then(function(res) {
            $scope.feeStructure = res.data;
            $scope.license.employeecountcd = _.find($scope.feeStructure, {scnrio_cd: $scope.license.employeecountcd.trim()});
        });

        // $scope.isAllowUpdateRestrictedApplicationFee = function(license) {
        //     return ApplicationService.isRestrictedCorpApp(license) && license.c_app_trn_status === 'New Application'
        // && !$scope.isApplicationPaid(license) && !ApplicationService.isInvoiced(license) &&
        // (ApplicationService.isWithCandidate(license) || ApplicationService.isIncomplete(license)); };

        $scope.isAllowUpdateFee = function(license) {
            //return $scope.isStaff() && !$scope.isApplicationPaid(license) && !ApplicationService.isInvoiced(license);
            return $scope.isStaff() && ApplicationService.isApplication(license);
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) <= 0;
        };

        /**
         * In case of restricted license, set fee using scenario code.
         */
        $scope.setRestrictedLicenseFee = function(license) {
            if($scope.isAmendment === 'Y')
                license.fee = "25.00";
            else
                license.fee = license.employeecountcd.amount;
        };

        $scope.setRestrictedLicFee = function($event, license) {
            var checkbox = $event.target;
            if(checkbox.checked)
                license.fee = "25.00";
            else
                license.fee = license.employeecountcd.amount;

        };

        $scope.applicationUpdateFeeInProgress = false;

        /**
         * fee edit
         */
        $scope.updateFee = function(license, LicenseFee, employeecountcd) {
            $scope.applicationUpdateFeeInProgress = true;
            var onsuccess = function(res) {
                $scope.applicationUpdateFeeInProgress = false;
                $scope.feeEditing = false;
                if(!_.isEmpty(res.data.error)) {
                    console.log(_.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "");
                    return toastr.error('Error!', 'License fee cannot be updated at this time. Please try later');
                }
                license.fee = LicenseFee;
                $scope.orignalLicenseFee = LicenseFee;
                toastr.success('Success!', 'License fee has been updated successfully.');
            };
            var onerror = function(res) {
                $scope.feeEditing = false;
                $scope.applicationUpdateFeeInProgress = false;
                toastr.error('Error!', 'License fee cannot be update at this time. Please try later');
            };
            ApplService.updateFee(license, LicenseFee, employeecountcd).then(onsuccess, onerror);
        };

        /**
         * fee edit
         */
        $scope.updateCorporateFee = function(license, LicenseFee) {
            $scope.corpApplicationUpdateFeeInProgress = true;
            var onsuccess = function(res) {
                $scope.corpApplicationUpdateFeeInProgress = false;
                $scope.corpFeeEditing = false;
                if(!_.isEmpty(res.data.error)) {
                    console.log(_.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "");
                    return toastr.error('Error!', 'Corporate license fee cannot be updated at this time. Please try later');
                }
                license.fee = LicenseFee;
                $scope.origanlCorpLicenseFee = LicenseFee;
                toastr.success('Success!', 'Corporate license fee has been updated successfully.');
            };
            var onerror = function(res) {
                $scope.corpFeeEditing = false;
                $scope.corpApplicationUpdateFeeInProgress = false;
                toastr.error('Error!', 'Corporate license fee cannot be updated at this time. Please try later');
            };
            ApplService.updateFee(license, LicenseFee, '').then(onsuccess, onerror);
        };


    }
])
.controller('ReviewStaffCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'blockUI', 'toastr', 'ReviewService',
    function($scope, $state, $stateParams, DTOptionsBuilder, blockUI, toastr, ReviewService) {

        $scope.licenses = [];
        $scope.corpLicenses = [];

        var reviewsBlock = blockUI.instances.get('reviews-block');
        reviewsBlock.start();

        ReviewService.getStaffReviews(true)
        .then(function(res) {
            $scope.licenses = res.data[0];
            $scope.corpLicenses = res.data[1];

            // link the corp license to the D/R license
            var drLicenses = _.filter($scope.licenses, {drlicense: 'Y'});
            _.forEach(drLicenses, function(license) {
                license.corpLicense = _.find($scope.corpLicenses, {
                    licenseclass: license.corporatelicenseclass,
                    agencyid: license.agencyid
                });
            });

            reviewsBlock.stop();
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true)
        .withOption('order', [5, 'asc'])
        .withOption('iDisplayLength', 50);

        $scope.isApplication = function(license) {
            return license.statuscd.trim() == 'KAPP';
        };

        $scope.isRenewal = function(license) {
            return license.statuscd.trim() == 'KREN';
        };

        $scope.view = function(license) {
            reviewsBlock.start();
            if($scope.isRenewal(license)) $state.go('reviews.open.renewal', {
                licenseid: license.licenseid,
                ciprid: license.licenseholderciprid,
                corplicenseid: $scope.isDRLicense(license) && license.corpLicense && !_.isEmpty(license.corpLicense) ? license.corpLicense.licenseid : ''
            });
            if($scope.isApplication(license)) $state.go('reviews.open.application', {
                licenseid: license.licenseid,
                ciprid: license.licenseholderciprid,
                corplicenseid: $scope.isDRLicense(license) && license.corpLicense && !_.isEmpty(license.corpLicense) ? license.corpLicense.licenseid : ''
            });
        };

        $scope.bottom = function(license) {
            reviewsBlock.start();
            ReviewService.sendToTheBottom(license.licenseid)
            .then(function(reply) {
                toastr.success(license.license + ' Success!', 'License has been sent to bottom of the list.');
                $state.go($state.current, {}, {reload: true});
            });
        };

        $scope.isDRLicense = function(license) {
            return ReviewService.isDRLicense(license);
        };

    }
])
.controller('ReviewMgmtCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'blockUI', 'ReviewService',
    function($scope, $state, $http, DTOptionsBuilder, blockUI, ReviewService) {

        $scope.licenses = [];
        $scope.corpLicenses = [];

        var reviewsBlock = blockUI.instances.get('reviews-block');
        reviewsBlock.start();

        ReviewService.getPendingReviews()
        .then(function(res) {
            $scope.licenses = res.data[0];
            $scope.corpLicenses = res.data[1];

            // link the corp license to the D/R license
            var drLicenses = _.filter($scope.licenses, {drlicense: 'Y'});
            _.forEach(drLicenses, function(license) {
                license.corpLicense = _.find($scope.corpLicenses, {
                    licenseclass: license.corporatelicenseclass,
                    agencyid: license.agencyid
                });
            });
            reviewsBlock.stop();
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true)
        .withOption('order', [5, 'asc'])
        .withOption('iDisplayLength', 50);

        $scope.isApplication = function(license) {
            return license.statuscd.trim() == 'KAPP';
        };

        $scope.isRenewal = function(license) {
            return license.statuscd.trim() == 'KREN';
        };

        $scope.view = function(license) {
            reviewsBlock.start();
            if($scope.isRenewal(license)) $state.go('reviews.open.renewal', {
                licenseid: license.licenseid,
                ciprid: license.licenseholderciprid,
                corplicenseid: $scope.isDRLicense(license) && license.corpLicense && !_.isEmpty(license.corpLicense) ? license.corpLicense.licenseid : ''
            });
            if($scope.isApplication(license)) $state.go('reviews.open.application', {
                licenseid: license.licenseid,
                ciprid: license.licenseholderciprid,
                corplicenseid: $scope.isDRLicense(license) && license.corpLicense && !_.isEmpty(license.corpLicense) ? license.corpLicense.licenseid : ''
            });
        };

        $scope.bottom = function(license) {
            reviewsBlock.start();
            ReviewService.sendToTheBottom(license.licenseid)
            .then(function(reply) {
                toastr.success(license.license + ' Success!', 'License has been sent to bottom of the list.');
                $state.go($state.current, {}, {reload: true});
            });
        };

        $scope.isDRLicense = function(license) {
            return ReviewService.isDRLicense(license);
        };
    }
])
.controller('ReviewCouncilCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'blockUI', 'ReviewService',
    function($scope, $state, $http, DTOptionsBuilder, blockUI, ReviewService) {

        $scope.licenses = [];
        $scope.corpLicenses = [];

        var reviewsBlock = blockUI.instances.get('reviews-block');
        reviewsBlock.start();

        ReviewService.getCouncilReviews()
        .then(function(res) {
            $scope.licenses = res.data[0];
            $scope.corpLicenses = res.data[1];

            // link the corp license to the D/R license
            var drLicenses = _.filter($scope.licenses, {drlicense: 'Y'});
            _.forEach(drLicenses, function(license) {
                license.corpLicense = _.find($scope.corpLicenses, {
                    licenseclass: license.corporatelicenseclass,
                    agencyid: license.agencyid
                });
            });
            reviewsBlock.stop();
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true)
        .withOption('order', [5, 'asc'])
        .withOption('iDisplayLength', 25);

        $scope.isApplication = function(license) {
            return license.statuscd.trim() == 'KAPP';
        };

        $scope.isRenewal = function(license) {
            return license.statuscd.trim() == 'KREN';
        };

        $scope.view = function(license) {
            reviewsBlock.start();
            if($scope.isRenewal(license)) $state.go('reviews.open.renewal', {
                licenseid: license.licenseid,
                ciprid: license.licenseholderciprid,
                corplicenseid: $scope.isDRLicense(license) && license.corpLicense && !_.isEmpty(license.corpLicense) ? license.corpLicense.licenseid : ''
            });
            if($scope.isApplication(license)) $state.go('reviews.open.application', {
                licenseid: license.licenseid,
                ciprid: license.licenseholderciprid,
                corplicenseid: $scope.isDRLicense(license) && license.corpLicense && !_.isEmpty(license.corpLicense) ? license.corpLicense.licenseid : ''
            });
        };

        $scope.isDRLicense = function(license) {
            return ReviewService.isDRLicense(license);
        };

    }
])
.controller('ReviewOpenCtrl', ['$scope', '$state', '$stateParams', 'promisedLicense', 'promisedCorpLicense', 'socket', 'toastr', 'ReviewService', 'CrimCheckService', 'DownloadService',
    function($scope, $state, $stateParams, promisedLicense, promisedCorpLicense, socket, toastr, ReviewService, CrimCheckService, DownloadService) {

        $scope.collaborators = [];
        $scope.license = promisedLicense;
        $scope.corpLicense = promisedCorpLicense;

        var onsuccess = function(res) {
            var data = res.data;
            $scope.license.crimcheck = _.isArray(data) ? data[0] : data;
        };
        var onerror = function(res) {
            $scope.license.crimcheck = [];
        };
        CrimCheckService.getCurrent($scope.roleContext.ciprid, $scope.license.licenseholderid)
        .then(onsuccess, onerror);

        socket.on('connect', function(data) {
            var ciprid = $scope.roleContext.ciprid;
            socket.emit('review:join', {
                ciprid: ciprid,
                licenseid: $scope.license.licenseid,
                name: $scope.profile.displayName
            });
        });

        socket.on('review:collaborators', function(data) {
            $scope.collaborators = _.merge($scope.collaborators, data);
            toastr.success('New Collaborator!', data);
        });

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid;
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) <= 0;
        };

        $scope.isDRLicense = function(license) {
            return ReviewService.isDRLicense(license);
        };

        $scope.isDRApp = function(license) {
            return license.drlicense == 'Y';
        };

        $scope.isCorpLicenseExists = function(corpLicense) {
            return ReviewService.isCorpLicenseExists(corpLicense);
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ReviewService.isRestrictedCorpApp(license);
        };

        $scope.isCorpApp = function(license) {
            return ReviewService.isCorpApp(license);
        };

        $scope.isApplication = function(license) {
            return license.statuscd.trim() == 'KAPP';
        };

        /**
         * Constructs a download link.
         * @param license
         * @param question
         * @param attachment
         * @returns {string}
         */
        $scope.downloadLink = function(license, question, attachment) {
            var anchorText = attachment.displayname;
            var anchorHref = S("/{{ciprid}}/licenses/{{licenseid}}/attachments/{{filename}}?questionnum={{questionnum}}&questionpart={{questionpart}}&party={{partyid}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: license.licensetype == 'C' ? license.dr_partyid : license.licenseholderid,
                licenseid: license.licenseid,
                filename: attachment.displayname,
                questionnum: question.questionnum,
                questionpart: question.questionpart
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };
    }
])
.controller('ReviewApplicationCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'socket', 'toastr', 'ReviewService', 'PartyService',
    function($scope, $state, $stateParams, dialogs, socket, toastr, ReviewService, PartyService) {
        $scope.orignalLicenseApprovedBy = $scope.license.approvedbyname;
        $scope.authByEditing = false;
        $scope.isRecommendationRequired = function(license) {
            return $scope.isStaff() && license.recommendations && license.recommendations.length > 0
        };

        $scope.getParties = function(phrase, limit) {
            return PartyService.getParties(phrase, limit);
        };

        $scope.checkParty = function(authBy) {
            if(!authBy.partyid) {
                authBy.partyid = "";
            }
        };

        $scope.applicationUpdateAuthByInProgress = false;

        /**
         * Recommender edit
         */
        $scope.updateRecommender = function(license, authBy) {
            $scope.applicationUpdateAuthByInProgress = true;
            var onsuccess = function(res) {
                $scope.applicationUpdateAuthByInProgress = false;
                $scope.authByEditing = false;
                if(!_.isEmpty(res.data.error)) {
                    console.log(_.isObject(res.data.error) && _.isArray(res.data.error)? res.data.error:"");
                    return toastr.error('Error!', 'License Recommender cannot be updated at this time. Please try later');
                }
                license.approvedbyname = authBy.partyName;
                license.approvedbyid = authBy.partyid;
                $scope.orignalLicenseApprovedBy = authBy.partyName;
                toastr.success('Success!', 'License Recommender has been updated successfully.');
            };
            var onerror = function(res) {
                $scope.authByEditing = false;
                $scope.applicationUpdateAuthByInProgress = false;
                toastr.error('Error!', 'License recommender cannot be update at this time. Please try later');
            };

            if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)){
                ReviewService.updateRecommender(license, authBy.partyid)
                .then(function() {
                    ReviewService.updateRecommender($scope.corpLicense, authBy.partyid)
                    .then(onsuccess, onerror);
                });
            }else{
                ReviewService.updateRecommender(license, authBy.partyid).then(onsuccess, onerror);
            }


        };


    }
])
.controller('ReviewRenewalCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'socket', 'toastr', 'ReviewService',
    function($scope, $state, $stateParams, dialogs, socket, toastr, ReviewService) {
    }
])
.controller('ReviewEandOCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr',
    function($scope, $state, $stateParams, dialogs, toastr) {

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };
    }
])
.controller('ReviewerCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'ReviewService', 'ReviewCommentService',
    function($scope, $state, $stateParams, dialogs, toastr, ReviewService, ReviewCommentService) {
        $scope.staffreviewer = undefined;
        $scope.mgmtreviewer = undefined;
        /**
         * Initializers
         */
        ReviewService.getReviewers($scope.license.licenseid)
        .then(function(res) {
            var data = _.isArray(res.data) ? res.data[0] : res.data;
            $scope.staffreviewer = data.staffreviewer;
            $scope.mgmtreviewer = data.mgmtreviewer;
            $scope.councilreviewer = data.councilreviewer;
        });
    }
])
.controller('ReviewQuestionCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'ReviewService',
    function($scope, $state, $stateParams, dialogs, toastr, ReviewService) {

        $scope.queshistory = function(q, license) {
            return _.filter(license.questionshistory, {answerid: q.answerid});
        }

    }
])
.controller('ReviewApplicationRecommendationCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', 'toastr',
    function($scope, $state, $stateParams, $http, $filter, toastr) {

        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.setRecommendationDate = function(selectedDate) {
            $scope.license.recommendationDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

    }
])
.controller('ReviewCommentsCtrl', ['$scope', '$state', '$stateParams', '$q', 'dialogs', 'toastr', 'ReviewService', 'ReviewCommentService',
    function($scope, $state, $stateParams, $q, dialogs, toastr, ReviewService, ReviewCommentService) {

        $scope.comment = '';
        $scope.commentHistory = [];
        ReviewCommentService.setCommentHistory([]);
        $scope.currentUserCommentHistory = [];
        ReviewCommentService.setCurrentUserCommentHistory([]);

        /**
         * Initializers
         */
        ReviewService.getCommentHistory($scope.license.licenseid)
        .then(function(res) {
            var data = _.isArray(res.data) ? res.data[0] : res.data;
            $scope.commentHistory = _.isEmpty(data.comments) ? [] : JSON.parse(data.comments);
            $scope.staffreviewer = data.staffreviewer;
            $scope.mgmtreviewer = data.mgmtreviewer;
            $scope.currentUserCommentHistory = _.filter($scope.commentHistory,{user:$scope.roleContext.name});
            ReviewCommentService.setCommentHistory($scope.commentHistory);
            ReviewCommentService.setCurrentUserCommentHistory($scope.currentUserCommentHistory);
        });

        // -- called from form

        $scope.isUnderReview = function(license) {
            return license.reviewstatuscd == 'LIC_REV_REVIEW';
        };

        $scope.saveComment = function(license) {
            if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)) return $scope.saveDRCorpComment(license);
            $scope.commentHistory = ReviewCommentService.addComment($scope.roleContext.name, Date.now(), $scope.comment);
            $scope.currentUserCommentHistory = ReviewCommentService.addCurrentUserComment($scope.roleContext.name, Date.now(), $scope.comment);

            ReviewService.saveComments(license.licenseid, $scope.commentHistory, license)
            .then(function(reply) {
                toastr.success('Saved!', 'Your comment has been saved');
                $scope.comment = '';
            });
        };

        $scope.saveDRCorpComment = function(license) {
            $scope.commentHistory = ReviewCommentService.addComment($scope.roleContext.name, Date.now(), $scope.comment);
            $scope.currentUserCommentHistory = ReviewCommentService.addCurrentUserComment($scope.roleContext.name, Date.now(), $scope.comment);

            $q.all([ReviewService.saveComments(license.licenseid, $scope.commentHistory, license), ReviewService.saveComments($scope.corpLicense.licenseid, $scope.commentHistory, $scope.corpLicense)])
            .then(function(results) {
                toastr.success('Saved!', 'Your comment has been saved');
                $scope.comment = '';
            });
        };
    }
])
.controller('ReviewActionsCtrl', ['$scope', '$state', '$stateParams', '$q', 'dialogs', 'toastr', 'blockUI', 'SweetAlert', 'ReviewService', 'ReviewCommentService',
    function($scope, $state, $stateParams, $q, dialogs, toastr, blockUI, SweetAlert, ReviewService, ReviewCommentService) {
        var reviewsBlock = blockUI.instances.get('reviews-block');
        $scope.isUnderReview = function(license) {
            return license.reviewstatuscd == 'LIC_REV_REVIEW';
        };

        $scope.isUnderManagementReview = function(license) {
            return license.reviewstatuscd == 'LIC_REV_MGMT_REV';
        };

        $scope.isUnderCouncilReview = function(license) {
            return license.reviewstatuscd == 'LIC_REV_COUNCIL_REV';
        };

        $scope.sendBackToAgent = function(license) {
            var data = {
                title: 'Send Back To ' + license.licenseholdername,
                license: license
            };

            dialogs.create('reviews/dialogs/sendbacktoagent.html', 'SendBackToAgentDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(reason) {
                reviewsBlock.start();
                var commentHistory = ReviewCommentService.addComment($scope.roleContext.name, Date.now(), 'Send Back To Agent : ' + reason);

                if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)) {
                    $q.all([ReviewService.saveComments(license.licenseid, commentHistory, license), ReviewService.saveComments($scope.corpLicense.licenseid, commentHistory, $scope.corpLicense)])
                    .then(function(results) {
                    });

                    ReviewService.sendBackToAgent(license.licenseid, reason)
                    .then(function() {
                        ReviewService.sendBackToAgent($scope.corpLicense.licenseid, reason)
                        .then(function(results) {
                            toastr.success('Success!', 'Application send back to agent');
                            reviewsBlock.stop();
                            if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                                $state.go('reviews.mgmt', {}, {reload: true});
                            else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                                $state.go('reviews.council', {}, {reload: true});
                            else
                                $state.go('reviews.staff', {}, {reload: true});
                        })
                    });

                } else {
                    ReviewService.saveComments(license.licenseid, commentHistory, license)
                    .then(function(reply) {
                        //toastr.success('Saved!', 'Your comment has been saved');
                    });
                    ReviewService.sendBackToAgent(license.licenseid, reason)
                    .then(function(reply) {
                        toastr.success('Success!', 'Application send back to agent');
                        reviewsBlock.stop();
                        if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                            $state.go('reviews.mgmt', {}, {reload: true});
                        else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                            $state.go('reviews.council', {}, {reload: true});
                        else
                            $state.go('reviews.staff', {}, {reload: true});
                    });

                }

            });

        };

        $scope.approve = function(license) {
            if(_.isEmpty(ReviewCommentService.getCurrentUserCommentHistory())) {
                dialogs.error('No comments found!', 'Comments are required.');
                return;
            }

            var data = {
                license: license.license,
                licensedescr: license.licensedescr,
                corplicense: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.license : '',
                corplicensedescr: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licensedescr : ''
            };
            dialogs.create('reviews/dialogs/approve.html', 'ReviewApproveDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(validDate) {
                reviewsBlock.start();
                if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)) {
                    ReviewService.approveLicense(license.licenseid, license.forceyn, validDate)
                    .then(function() {
                        ReviewService.approveLicense($scope.corpLicense.licenseid, license.forceyn, validDate)
                        .then(function(results) {
                            reviewsBlock.stop();
                            toastr.success('Success!', 'License ' + license.license + ' and ' + $scope.corpLicense.license + ' has been approved.');
                            if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                                $state.go('reviews.mgmt', {}, {reload: true});
                            else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                                $state.go('reviews.council', {}, {reload: true});
                            else
                                $state.go('reviews.staff', {}, {reload: true});
                        })
                    });
                } else {
                    ReviewService.approveLicense(license.licenseid, license.forceyn, validDate)
                    .then(function(reply) {
                        reviewsBlock.stop();
                        toastr.success('Success!', 'License ' + license.license + ' has been approved.');
                        if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                            $state.go('reviews.mgmt', {}, {reload: true});
                        else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                            $state.go('reviews.council', {}, {reload: true});
                        else
                            $state.go('reviews.staff', {}, {reload: true});

                    });
                }
            }, function() {});

        };

        $scope.refuse = function(license) {
            var data = {
                license: license.license,
                licensedescr: license.licensedescr,
                corplicense: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.license : '',
                corplicensedescr: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licensedescr : ''
            };
            dialogs.create('reviews/dialogs/refuse.html', 'ReviewRefuseDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(reasonId) {
                reviewsBlock.start();
                if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)) {
                    ReviewService.refuseLicense(license.licenseid, reasonId)
                    .then(function() {
                        ReviewService.refuseLicense($scope.corpLicense.licenseid, reasonId)
                        .then(function(res) {
                            reviewsBlock.stop();
                            if(!_.isEmpty(res.data.error)) {
                                return toastr.error('Error!', 'License ' + license.license + ' and ' + $scope.corpLicense.license + ' cannot be refused at this time.');
                            }
                            toastr.success('Success!', 'License ' + license.license + ' and ' + $scope.corpLicense.license + ' has been refused.');
                            if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                                $state.go('reviews.mgmt', {}, {reload: true});
                            else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                                $state.go('reviews.council', {}, {reload: true});
                            else
                                $state.go('reviews.staff', {}, {reload: true});
                        })
                    });
                } else {
                    ReviewService.refuseLicense(license.licenseid, reasonId)
                    .then(function(res) {
                        reviewsBlock.stop();
                        if(!_.isEmpty(res.data.error)) {
                            return toastr.error('Error!', 'License ' + license.license + ' cannot be refused at this time.');
                        }
                        toastr.success('Success!', 'License ' + license.license + ' has been refused.');
                        if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                            $state.go('reviews.mgmt', {}, {reload: true});
                        else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                            $state.go('reviews.council', {}, {reload: true});
                        else
                            $state.go('reviews.staff', {}, {reload: true});

                    });
                }
            });

        };

        $scope.decline = function(license) {
            if(_.isEmpty(ReviewCommentService.getCurrentUserCommentHistory())) {
                dialogs.error('No comments found!', 'Comments are required.');
                return;
            }
            if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)) {
                $q.all([ReviewService.declineLicense(license.licenseid), ReviewService.declineLicense($scope.corpLicense.licenseid)])
                .then(function(results) {
                    toastr.success('Success!', 'License ' + license.license + ' and ' + $scope.corpLicense.license + ' has been declined.');
                    if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                        $state.go('reviews.mgmt', {}, {reload: true});
                    else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                        $state.go('reviews.council', {}, {reload: true});
                    else
                        $state.go('reviews.staff', {}, {reload: true});
                });
            } else {
                ReviewService.declineLicense(license.licenseid)
                .then(function(reply) {
                    toastr.success('Success!', 'License ' + license.license + ' has been declined.');
                    if(license.reviewstatuscd == 'LIC_REV_MGMT_REV')
                        $state.go('reviews.mgmt', {}, {reload: true});
                    else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV')
                        $state.go('reviews.council', {}, {reload: true});
                    else
                        $state.go('reviews.staff', {}, {reload: true});
                });
            }
        };

        $scope.sendForManagementReview = function(license) {
            if(_.isEmpty(ReviewCommentService.getCurrentUserCommentHistory())) {
                dialogs.error('No comments found!', 'Comments are required.');
                return;
            }
            if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)) {
                $q.all([ReviewService.sendForManagementReview(license.licenseid), ReviewService.sendForManagementReview($scope.corpLicense.licenseid)])
                .then(function(results) {
                    toastr.success('Success!', 'License' + license.license + ' and ' + $scope.corpLicense.license + ' has been sent for management review.');
                    $state.go('reviews.staff', {}, {reload: true});
                });
            } else {
                ReviewService.sendForManagementReview(license.licenseid)
                .then(function(reply) {
                    toastr.success('Success!', 'License ' + license.license + ' has been sent for management review.');
                    $state.go('reviews.staff', {}, {reload: true});
                });
            }
        };

        $scope.sendForCouncilReview = function(license) {
            if(_.isEmpty(ReviewCommentService.getCurrentUserCommentHistory())) {
                dialogs.error('No comments found!', 'Comments are required.');
                return;
            }
            if(ReviewService.isDRLicense(license) && $scope.corpLicense && !_.isEmpty($scope.corpLicense)) {
                $q.all([ReviewService.sendForCouncilReview(license.licenseid), ReviewService.sendForCouncilReview($scope.corpLicense.licenseid)])
                .then(function(results) {
                    toastr.success('Success!', 'License' + license.license + ' and ' + $scope.corpLicense.license + ' has been sent for council review.');
                    $state.go('reviews.mgmt', {}, {reload: true});
                });
            } else {
                ReviewService.sendForCouncilReview(license.licenseid)
                .then(function(reply) {
                    toastr.success('Success!', 'License ' + license.license + ' has been sent for council review.');
                    $state.go('reviews.mgmt', {}, {reload: true});
                });
            }
        };
    }
])
.controller('ReviewRefuseDialogCtrl', ['$scope', '$uibModalInstance', '$http', '$filter', 'data', 'ReviewService',
    function($scope, $uibModalInstance, $http, $filter, data, ReviewService) {

        $scope.data = data;
        $scope.refuseReasons = data.refuseReasons;
        $scope.refuseReason = "LIC";
        /**
         * Initializers
         */
        ReviewService.getRefuseReasons()
        .then(function(res) {
            $scope.refuseReasons = res.data;
        });

        /**
         * When 'OK' is clicked.
         */
        $scope.refuse = function() {
            $uibModalInstance.close($scope.refuseReason);
        };

        /**
         * When 'Cancel' is clicked.
         */
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])
.controller('ReviewApproveDialogCtrl', ['$scope', '$uibModalInstance', '$http', '$filter', 'data',
    function($scope, $uibModalInstance, $http, $filter, data) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.data = data;
        $scope.currentDate = moment().format('MMMM DD, YYYY');
        $scope.validDate = $scope.currentDate;


        $scope.setValidDate = function(selectedDate) {
            $scope.validDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };
        /**
         * When 'Approve' is clicked.
         */
        $scope.approve = function() {
            $uibModalInstance.close($scope.validDate);
        };

        /**
         * When 'Cancel' is clicked.
         */
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])
.controller('SendBackToAgentDialogCtrl', ['$scope', '$uibModalInstance', '$http', '$filter', 'data', 'ReviewService', 'ReviewCommentService',
    function($scope, $uibModalInstance, $http, $filter, data, ReviewService, ReviewCommentService) {

        $scope.args = data;

        $scope.reason = undefined;

        // --- Initializers ---

        /**
         * When 'OK' is clicked.
         */
        $scope.send = function() {
            $uibModalInstance.close($scope.reason);
        };

        /**
         * When 'Cancel' is clicked.
         */
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])
.controller('CriminalCheckCtrl', ['$scope', '$state', '$stateParams', '$http', 'toastr', 'ApplicationService', 'DownloadService',
    function($scope, $state, $stateParams, $http, toastr, ApplicationService, DownloadService) {
        /**
         * Constructs a download link
         * @param crimcheck
         * @returns {string}
         */
        $scope.downloadLink = function(crimcheck) {
            var anchorText = crimcheck.displayname;
            var anchorHref = S("/{{ciprid}}/crimcheck/{{crimcheckid}}/download/{{filename}}?party={{partyid}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: crimcheck.partyid,
                crimcheckid: crimcheck.id,
                filename: crimcheck.displayname
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };
    }
])
/**
 * Review services
 */
.service('ReviewService', ['$http', '$q',
    function($http, $q) {

        this.isWithCandidate = function(license) {
            return license.appstatuscd == 'LAS_CAND_INPUT';
        };

        this.isWithRegulator = function(license) {
            return license.appstatuscd == 'LAS_REG_SUBMIT';
        };

        this.isDRLicense = function(license) {
            return license.drlicense == 'Y';
        };

        this.isCorpLicenseExists = function(corpLicense) {
            return corpLicense && corpLicense.licenseid && corpLicense.licensetype === "C";
        };

        this.isRestrictedCorpApp = function(license) {
            return license.licensetype == 'CO';
        };

        this.isCorpApp = function(license) {
            return license.licensetype == 'C' || license.licensetype == 'CO';
        };


        this.getStaffReviews = function(cache) {
            return $http({
                url: '/staff/reviews/staff',
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.getPendingReviews = function() {
            return $http({
                url: '/staff/reviews/management',
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.getCouncilReviews = function() {
            return $http({
                url: '/staff/reviews/council',
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.getCommentHistory = function(licenseid) {
            return $http.get('/staff/reviews/' + licenseid + '/comment');
        };

        this.getReviewers = function(licenseid) {
            return $http.get('/staff/reviews/' + licenseid + '/reviewer');
        };

        this.saveComments = function(licenseid, comments, license) {
            var url = '/staff/reviews/staffcomment';
            if(license.reviewstatuscd == 'LIC_REV_MGMT_REV') {
                url = '/staff/reviews/managementcomment';
            } else if(license.reviewstatuscd == 'LIC_REV_COUNCIL_REV') {
                url = '/staff/reviews/councilcomment';
            }
            return $http({
                url: url,
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {licenseid: licenseid, comments: comments}
            });
        };

        this.approveLicense = function(licenseid, force, validDate) {
            return $http({
                url: '/staff/reviews/approve',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {licenseid:licenseid, force: force, validDate:validDate}
            });
        };

        this.getRefuseReasons = function() {
            return $http.get('/staff/reviews/refusereasons',{cache:true});
        };

        this.refuseLicense = function(licenseid, reasonId) {
            return $http({
                url: '/staff/reviews/' + licenseid + '/refuse',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {reasonId: reasonId}
            });
        };

        this.declineLicense = function(licenseid) {
            return $http({
                url: '/staff/reviews/' + licenseid + '/decline',
                method: 'POST',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.sendForManagementReview = function(licenseid) {
            return $http({
                url: '/staff/reviews/' + licenseid + '/management',
                method: 'POST',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.sendForCouncilReview = function(licenseid) {
            return $http({
                url: '/staff/reviews/' + licenseid + '/council',
                method: 'POST',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.sendBackToAgent = function(licenseid, reason) {
            return $http({
                url: '/staff/reviews/' + licenseid + '/sendbacktoagent',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {reason: reason}
            });
        };

        this.sendToTheBottom = function(licenseid) {
            return $http({
                url: '/staff/reviews/' + licenseid + '/bottomofthelist',
                method: 'POST',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.getCollaborators = function(licenseid) {
            return $http({
                url: '/staff/collab/reviewing/' + licenseid,
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            });
        };

        this.updateRecommender = function(license, recommenderId) {
            return $http.post('/staff/lic/' + license.licenseid + '/recommender', {
                recommenderId: recommenderId,
            });
        };
    }
])
.service('ReviewCommentService', [
    function() {
        var service = {};

        service.commentHistory = [];
        service.currentUserCommentHistory = [];

        service.setCommentHistory = function(commentHistory) {
            this.commentHistory = commentHistory;
        };

        service.getCommentHistory = function() {
            return this.commentHistory;
        };

        service.addComment = function(user, when, what) {
            this.commentHistory.push({
                user: user,
                when: when,
                what: what
            });
            return this.commentHistory;
        };

        service.setCurrentUserCommentHistory = function(currentUserCommentHistory) {
            this.currentUserCommentHistory = currentUserCommentHistory;
        };

        service.getCurrentUserCommentHistory = function() {
            return this.currentUserCommentHistory;
        };

        service.addCurrentUserComment = function(user, when, what) {
            this.currentUserCommentHistory.push({
                user: user,
                when: when,
                what: what
            });
            return this.currentUserCommentHistory;
        };

        return service;

    }
])
.service('ReviewResolverService', ['$resource', '$http', 'FileUploader', 'AddressService',
    function($resource, $http, FileUploader, AddressService) {

        /**
         * Returns a promise which resolves a license object.
         *
         * @param ciprid
         * @param licenseid
         * @returns {*}
         */
        this.get = function(ciprid, licenseid) {
            return $http({
                url: '/' + ciprid + '/licenses/particulars',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'licenseids': [licenseid],
                    'details': ['eandos', 'questions', 'occupations', 'attachments', 'questionshistory', 'employmenthistory', 'displaycrimcheck', 'recommendations']
                }
            })
            .then(function(res) {
                var license = res.data.licenses[0];
                license.address = AddressService.copyAddress(license);
                license.eandos = res.data.eandos;
                license.displayCrimCheck = res.data.displaycrimcheck[0];
                license.employmentHistory = res.data.employmentHistory;
                license.questions = res.data.questions;
                license.questions = _.map(res.data.questions, function(q, index) {
                    q.index = index + 1;
                    return q;
                });
                license.questionshistory = res.data.questionshistory;
                license.questionshistory = _.map(res.data.questionshistory, function(q, index) {
                    q.index = index + 1;
                    return q;
                });
                license.attachments = _.groupBy(res.data.attachments, 'answerid');
                license.occupations = res.data.occupations;
                license.declarations = res.data.declarations.length == 0 ? [] : res.data.declarations;
                license.recommendations = res.data.recommendations;
                var firstRecommendation = _.first(license.recommendations);
                license.recommendationDate = firstRecommendation != undefined ? firstRecommendation.responsetimestamp : undefined;
                license.invoice = _.isArray(res.data.invoice) ? res.data.invoice[0] : res.data.invoice;

                _.forEach(license.occupations, function(occupation) {
                    occupation.selected = !_.isEmpty(occupation.answerseq);
                });

                return license;
            });
        };
    }
]);
