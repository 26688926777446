var wizard = angular.module('aic.wizard', [
    'ngResource',
    'ui.router'
]);

/**
 * Routing and config
 */
wizard.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {

    }
])

/**
 * Services. Initiate a wizard like so:
 *
 *  module.controller('SampleWizardCtrl', ['$scope', 'WizardService',
 *      function($scope, $state, $stateParams, blockUI, WizardService) {
 *          $scope.wizard = WizardService.init([
 *              {state: 'some.state1', label: 'Step 1'},
 *              {state: 'some.state2', label: 'Step 2'},
 *              {state: 'some.state3', label: 'Done!'}
 *          ]);
 *      }
 *  );
 */
.service('WizardService', ['$state',
    function($state) {
        var wizard = function(steps) {
            this.steps = steps;
            this.currentStep = 0;

            /**
             * Go to next state in path if current state is not last.
             */
            this.begin = function() {
                this.reset();
                $state.go(this.steps[this.currentStep].state, {}, {
                    location: false,
                    inherit: true,
                    relative: $state.$current,
                    notify: true
                });
            };

            /**
             * Go to next state in path if current state is not last.
             */
            this.reset = function() {
                this.currentStep = 0;
            };

            /**
             * Return steps
             * @returns {*}
             */
            this.getSteps = function() {
                return this.steps;
            };

            /**
             * Number of steps in the wizard.
             * @returns {*}
             */
            this.size = function() {
                return this.steps.length;
            };

            /**
             * Get current step number.
             * @returns {*}
             */
            this.getCurrentStep = function() {
                return this.currentStep;
            };

            /**
             * Is first state in path.
             * @returns {boolean}
             */
            this.isFirstStep = function() {
                return this.currentStep == 0;
            };

            /**
             * Is last state in path.
             * @returns {boolean}
             */
            this.isLastStep = function() {
                return this.currentStep >= this.steps.length;
            };

            /**
             * Go to next state in path if current state is not last.
             */
            this.next = function() {
                if(!this.isLastStep()) {
                    this.currentStep = this.currentStep + 1;
                }
                $state.go(this.steps[this.currentStep].state, {}, {
                    location: false,
                    inherit: true,
                    relative: $state.$current,
                    notify: true
                });
            };

            /**
             * Go to previous state in path if current state is not first.
             */
            this.previous = function() {
                if(!this.isFirstStep()) {
                    this.currentStep = this.currentStep - 1;
                }
                $state.go(this.steps[this.currentStep].state, {}, {
                    location: false,
                    inherit: true,
                    relative: $state.$current,
                    notify: true
                });
            };
        };

        /**
         * Construct a wizard with given steps.
         */
        return {
            init: function(steps) {
                return new wizard(steps);
            }
        };
    }
]);

