var profile = angular.module('aic.profile', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router'
]);

profile.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('profile', {
            parent: 'workspace',
            url: '/my/profile',
            templateUrl: 'profile/profile.html',
            controller: 'ProfileViewCtrl'
        });
    }
])

.controller('ProfileViewCtrl', ['$scope', '$state', '$stateParams', '$http',
    function($scope, $state, $stateParams, $http) {
    }
]);
