'use strict';

angular.module('aic.lookups', [
    'angular-jwt',
    'angular-storage',
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
])

.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('publiclookuplayout', {
            abstract: true,
            templateUrl: 'lookups/lookupentry.html',
            controller: 'PublicLookupLayoutCtrl'
        })
        .state('publiclookupentry', {
            abstract: true,
            parent: 'publiclookuplayout',
            views: {
                '': {
                    template: '<ui-view></ui-view>'
                }
            },
            data: {
                requiresLogin: false
            }
        });
    }
])
.controller('PublicLookupLayoutCtrl', ['$scope','$http',
    function($scope, $http) {
        $scope.bodyClass = 'publiclookup';
        $scope.env = '';
        $scope.release = '';
        $scope.copyrightYear = (new Date()).getFullYear();

        $http.get('/version')
            .then(function(res) {
                $scope.env = res.data.env;
                $scope.release = res.data.version;
            });

        $scope.$on('$viewContentLoaded', function() {
            Layout.initFooter();
        });

    }
]);


