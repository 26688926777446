var orgins = angular.module('aic.orgins', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

/**
 * Routing and config
 */
orgins.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;


    }
])
.controller('OrginsAddEditDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data', 'OrgsService',
    function($scope, $http, $uibModalInstance, $filter, data, OrgsService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.activeDateEnd = moment().format('MMMM DD, YYYY');
        $scope.orgins = data.orgins || {};
        var orgId = data.org.orgId;
        $scope.insclasses = data.insclasses;
        $scope.orgins.activeDate = (data.orgins && data.orgins.active_dt) ? moment(data.orgins.active_dt, 'YYYY-MM-DD').format('MMMM DD, YYYY') :  moment().format('MMMM DD, YYYY');
        $scope.orgins.inactiveDate = (data.orgins && data.orgins.inactive_dt) ? moment(data.orgins.inactive_dt, 'YYYY-MM-DD').format('MMMM DD, YYYY') :  undefined;
        $scope.orgins.addr = (data.orgins && data.orgins.addr_seq) ? data.orgins.addr_seq : '';
        OrgsService.getAddresses(orgId)
        .then(function(res) {
            $scope.addresses = res.data;
        });


        $scope.setActiveDate = function(selectedDate) {
            $scope.orgins.activeDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.setInActiveDate = function(selectedDate) {
            $scope.orgins.inactiveDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.clearInActiveDate = function(){
            $scope.orgins.inactiveDate = undefined;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.orgins);
        };


    }
])
.service('OrginsService', ['$http', '$q',
    function($http, $q) {
        this.getOrgins = function(orgId) {
            return $http.get('/staff/orgins/', {
                params: {
                    orgId: orgId
                }
            })
        };
        this.getInsclasses = function() {
            return $http.get('/staff/orgins/insclasses');
        };

        this.addOrgins = function(orgId, orgins) {
            return $http.put('/staff/orgins/', {
                data: {
                    orgId: orgId,
                    orgins: orgins
                }
            });
        };

        this.updateOrgins = function(orgins) {
            return $http.post('/staff/orgins/' + orgins.id, {
                data: {
                    orgins: orgins
                }
            });
        };

        this.reverseOrgins = function(orgId, orgins) {
            return $http.post('/staff/orgins/' + orgins.id + '/reverse', {
                data: {
                    orgId: orgId,
                    orgins: orgins
                }
            });
        };

    }
]);