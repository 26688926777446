'use strict';

angular.module('aic.wwwlogin', [
    'angular-jwt',
    'angular-storage',
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
])

.constant('AICBroadcastEvents', {
    renewals: {
        begin: 'begin',
        end: 'end'
    }
})

.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('entrylayout', {
            abstract: true,
            templateUrl: 'wwwlogin/entry.html',
            controller: 'EntryLayoutCtrl'
        })
        .state('findmyciprlayout', {
            abstract: true,
            templateUrl: 'wwwlogin/entry.html',
            controller: 'FindMyCiprLayoutCtrl'
        })
        .state('workspacelayout', {
            abstract: true,
            templateUrl: 'wwwlogin/workspace.html',
            controller: 'WorkspaceLayoutCtrl'
        });

        $stateProvider
        .state('entry', {
            abstract: true,
            parent: 'entrylayout',
            views: {
                '': {
                    template: '<ui-view></ui-view>'
                }
            },
            data: {
                requiresLogin: false
            }
        })
        .state('workspace', {
            abstract: true,
            parent: 'workspacelayout',
            views: {
                'header': {
                    templateUrl: 'wwwlogin/header.html',
                    controller: 'HeaderCtrl'
                },
                'menu': {
                    templateUrl: 'wwwlogin/menu.html',
                    controller: 'MenuCtrl'
                },
                '': {
                    template: '<ui-view></ui-view>'
                },
                'footer': {
                    templateUrl: 'wwwlogin/footer.html',
                    controller: 'FooterCtrl'
                }
            },
            data: {
                requiresLogin: true
            }
        })
        .state('findmyciprentry', {
            abstract: true,
            parent: 'findmyciprlayout',
            views: {
                '': {
                    template: '<ui-view></ui-view>'
                }
            },
            data: {
                requiresLogin: false
            }
        })

        $stateProvider
        .state('loginwithcipr', {
            parent: 'entry',
            url: '/login/with/cipr',
            templateUrl: 'wwwlogin/wwwlogin.cipr.html',
            controller: 'AuthCiprCtrl'
        })
        .state('loginwithemail', {
            parent: 'entry',
            url: '/login/with/email',
            templateUrl: 'wwwlogin/wwwlogin.email.html',
            controller: 'AuthEmailCtrl'
        })
        .state('remember', {
            parent: 'entry',
            url: '/remember',
            templateUrl: 'wwwlogin/wwwlogin.remember.html',
            controller: 'RememberMeCtrl'
        })
        .state('logout', {
            parent: 'entry',
            url: '/logout',
            templateUrl: 'wwwlogin/wwwlogin.cipr.html',
            controller: 'AuthCiprCtrl'
        });

        $stateProvider
        .state('home', {
            parent: 'workspace',
            url: '/',
            templateUrl: 'wwwlogin/home.html',
            controller: 'HomeCtrl'
        })
        .state('person', {
            parent: 'workspace',
            url: '/person',
            templateUrl: 'wwwlogin/home.html',
            controller: 'PersonCtrl'
        })
        .state('agency', {
            parent: 'workspace',
            url: '/agency/{ciprid}',
            templateUrl: 'wwwlogin/home.html',
            controller: 'AgencyCtrl'
        })
        .state('sponsor', {
            parent: 'workspace',
            url: '/sponsor/{ciprid}',
            templateUrl: 'wwwlogin/home.html',
            controller: 'SponsorCtrl'
        });

        $stateProvider
        .state('403', {
            parent: 'workspace',
            url: '/403',
            templateUrl: 'errors/403.html'
        })
        .state('404', {
            parent: 'workspace',
            url: '/404',
            templateUrl: 'errors/404.html'
        })
        .state('410', {
            parent: 'workspace',
            url: '/410',
            templateUrl: 'errors/410.html'
        })
        .state('500', {
            parent: 'workspace',
            url: '/500',
            params: {
                requestId: null
            },
            templateUrl: 'errors/500.html',
            controller: '500Ctrl'
        });
    }
])

.controller('EntryLayoutCtrl', ['$scope', '$http',
    function($scope, $http) {
        $scope.bodyClass = 'login';

        $scope.env = '';
        $scope.release = '';
        $scope.copyrightYear = (new Date()).getFullYear();

        $http.get('/version')
        .then(function(res) {
            $scope.env = res.data.env;
            $scope.release = res.data.version;
        });

        $scope.isDev = function() {
            return $scope.env == 'dev' || $scope.env == 'development' || $scope.env.match(/^dev/);
        };

        $scope.isTest = function() {
            return $scope.env == 'test';
        };

        $scope.isUat = function() {
            return $scope.env == 'uat';
        };

        $scope.isProd = function() {
            return $scope.env == 'prod';
        };
    }
])
.controller('FindMyCiprLayoutCtrl', ['$scope',
    function($scope) {
        $scope.bodyClass = 'findmycipr';
    }
])
.controller('WorkspaceLayoutCtrl', ['$scope', '$rootScope', '$state', '$window', '$http', 'AuthService',
    function($scope, $rootScope, $state, $window, $http, AuthService) {
        $scope.bodyClass = 'page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-content-white';

        $scope.profile = AuthService.getProfile();
        $scope.roleContext = AuthService.getRoleContext();
        $scope.jwtToken = AuthService.getToken();
        $window.document.title = $scope.roleContext && $scope.roleContext.name || '';

        $scope.env = '';
        $scope.release = '';
        $scope.copyrightYear = (new Date()).getFullYear();

        $http.get('/version')
        .then(function(res) {
            $scope.env = res.data.env;
            $scope.release = res.data.version;
        });

        $scope.isPerson = function() {
            return $scope.roleContext.type == 'person';
        };

        $scope.isSponsor = function() {
            return $scope.roleContext.type == 'sponsor';
        };

        $scope.isAgency = function() {
            return $scope.roleContext.type == 'agency';
        };

        $scope.isStaff = function() {
            return $scope.roleContext.type == 'staff';
        };

        $scope.isDev = function() {
            return $scope.env == 'dev' || $scope.env == 'development' || $scope.env.match(/^dev/);
        };

        $scope.isTest = function() {
            return $scope.env == 'test';
        };

        $scope.isUat = function() {
            return $scope.env == 'uat';
        };

        $scope.isProd = function() {
            return $scope.env == 'prod';
        };
    }
])
.controller('HomeCtrl', ['$scope', '$state', '$rootScope', '$http', 'AuthService', 'RenewalService',
    function($scope, $state, $rootScope, $http, AuthService, RenewalService) {
        $scope.profile = AuthService.getProfile();
        $scope.renewalPeriodNotStarted = true;
        $http.get('/' + $scope.roleContext.ciprid + '/licenses/tile')
        .then(function(res) {
            $scope.licenseCount = res.data[0].licenseCount;
        });
        $http.get('/' + $scope.roleContext.ciprid + '/invoices/tile')
        .then(function(res) {
            $scope.invSummary = res.data[0];
        });
        $http.get('/' + $scope.roleContext.ciprid + '/exams/tile')
        .then(function(res) {
            $scope.examSummary = res.data[0];
        });
        $http.get('/' + $scope.roleContext.ciprid + '/ce/tile')
        .then(function(res) {
            $scope.ceCourseSummary = res.data[0];
        });
        $http.get('/' + $scope.roleContext.ciprid + '/ce/summary/')
        .then(function(res) {
            $scope.categories = res.data.ce || [];
        });
        $scope.meetsRequirements = function(category) {
            return parseFloat(category.remainingHours) <= 0;
        };

        $http.get('/' + $scope.roleContext.ciprid + '/exams/pendingTasks')
        .then(function(res) {
            $scope.examPendingTasks = res.data;
        });

        $http.get('/' + $scope.roleContext.ciprid + '/licenses/badges')
        .then(function(res) {
            var kapp = _.find(res.data, {statuscd: 'KAPP'});
            var kren = _.find(res.data, {statuscd: 'KREN'});
            $scope.applicationCount = kapp ? kapp.count : 0;
            $scope.renewalCount = kren ? kren.count : 0;
        });

        RenewalService.getRenewalAllow()
        .then(function(res) {

            if(res.data[0].renewalallow === 'Y') {
                $scope.renewalPeriodNotStarted = false;
            }
        });

        $scope.goToLicenseList = function() {
            if($scope.isPerson())
                $state.go('licenses.combine.list');
            else
                $state.go('licenses.list');
        };

        $scope.goToRenewalList = function() {
            if($scope.isPerson())
                $state.go('licenses.combine.list');
            else
                $state.go('renewals.select');
        };


    }
])
.controller('PersonCtrl', ['$scope', '$rootScope', '$state', '$stateParams', '$http', 'AuthService',
    function($scope, $rootScope, $state, $stateParams, $http, AuthService) {
        $scope.profile = AuthService.getProfile();
        $scope.ciprid = $scope.profile.ciprid;

        $state.go('home', $stateParams, {reload: true, inherit: true});
    }
])
.controller('AgencyCtrl', ['$scope', '$rootScope', '$state', '$stateParams', '$http', 'AuthService',
    function($scope, $rootScope, $state, $stateParams, $http, AuthService) {
        $scope.profile = AuthService.getProfile();
        $scope.ciprid = $stateParams.ciprid;

        $http.get('/' + $scope.roleContext.ciprid + '/licenses/tile')
        .then(function(res) {
            $scope.licenseCount = res.data[0].licenseCount;
        });
        $http.get('/' + $scope.roleContext.ciprid + '/invoices/tile')
        .then(function(res) {
            $scope.invSummary = res.data[0];
        });
    }
])
.controller('SponsorCtrl', ['$scope', '$rootScope', '$state', '$stateParams', '$http', 'AuthService',
    function($scope, $rootScope, $state, $stateParams, $http, AuthService) {
        $scope.profile = AuthService.getProfile();
        $scope.ciprid = $stateParams.ciprid;

        $http.get('/' + $scope.roleContext.ciprid + '/licenses/tile')
        .then(function(res) {
            $scope.licenseCount = res.data[0].licenseCount;
        });

        $http.get('/' + $scope.roleContext.ciprid + '/invoices/tile')
        .then(function(res) {
            $scope.invSummary = res.data[0];
        });
    }
])
.controller('HeaderCtrl', ['$scope', '$rootScope', '$state', '$window', 'AuthService',
    function($scope, $rootScope, $state, $window, AuthService) {
        $scope.searchPhrase = '';
        $scope.profile = AuthService.getProfile();
        $scope.agencies = AuthService.getRoles().agency;
        $scope.sponsors = AuthService.getRoles().insurer;
        $scope.orgs = AuthService.getRoles().org;
        $scope.roleContext = AuthService.getRoleContext();

        $scope.logout = function() {
            AuthService.logout().then(function() {
                $state.go('loginwithcipr');
            });
        };

        $scope.$on('$viewContentLoaded', function() {
            Layout.initHeader();
            $('.dropdown-toggle').dropdownHover();
        });
    }
])
.controller('FooterCtrl', ['$scope', '$http',
    function($scope, $http) {
        $scope.$on('$viewContentLoaded', function() {
            Layout.initFooter();
        });
    }
])
.controller('MenuCtrl', ['$scope', '$rootScope', '$state', '$http', '$timeout', 'SweetAlert', 'AICBroadcastEvents', 'AuthService', 'RenewalService',
    function($scope, $rootScope, $state, $http, $timeout, SweetAlert, AICBroadcastEvents, AuthService, RenewalService) {
        $scope.profile = AuthService.getProfile();
        $scope.roleContext = AuthService.getRoleContext();
        $scope.applicationCount = 0;
        $scope.renewalCount = 0;
        $scope.renewalPeriod = false;
        $scope.agencies = AuthService.getRoles().agency;
        $scope.sponsors = AuthService.getRoles().insurer;
        $scope.sponsors = AuthService.getRoles().org;

        $http.get('/' + $scope.roleContext.ciprid + '/licenses/tile')
        .then(function(res) {
            $scope.licenseCount = res.data[0].licenseCount ? res.data[0].licenseCount : 0;
        });


        $http.get('/' + $scope.roleContext.ciprid + '/licenses/badges')
        .then(function(res) {
            var kapp = _.find(res.data, {statuscd: 'KAPP'});
            var kren = _.find(res.data, {statuscd: 'KREN'});
            $scope.applicationCount = kapp ? kapp.count : 0;
            $scope.renewalCount = kren ? kren.count : 0;

            //if($state.includes('home') && $scope.renewalCount && !$scope.renewalPeriodNotStarted) {
            //    $timeout(function() {
            //        SweetAlert.swal({
            //            title: "Renewals",
            //            text: "Looks like you have " + $scope.renewalCount + " renewals pending. Would you like to
            // renew now?", type: "warning", showCancelButton: true, confirmButtonColor: "#f2784b", confirmButtonText:
            // "Yes, let's renew!", closeOnConfirm: true, closeOnCancel: true }, function(confirmed) { if(confirmed) {
            // $state.go('renewals.select'); } }); }, 1000); }
        });

        $scope.isPerson = function() {
            return $scope.roleContext.type == 'person';
        };

        RenewalService.getRenewalAllow()
        .then(function(res) {

            if(res.data[0].renewalallow === 'Y') {
                $scope.renewalPeriod = true;
            }
        });

        // Event handlers

        $scope.$on(AICBroadcastEvents.renewals.begin, function() {
            console.log('Renewals beginning');
        });

        $scope.$on(AICBroadcastEvents.renewals.end, function() {
            $http.get('/' + $scope.roleContext.ciprid + '/licenses/badges')
            .then(function(res) {
                var kapp = _.find(res.data, {statuscd: 'KAPP'});
                var kren = _.find(res.data, {statuscd: 'KREN'});
                $scope.applicationCount = kapp ? kapp.count : 0;
                $scope.renewalCount = kren ? kren.count : 0;
            });
        });

        $scope.$on('$viewContentLoaded', function() {
            Layout.initSidebar();
        });

        $scope.logout = function() {
            AuthService.logout().then(function() {
                $state.go('loginwithcipr');
            });
        };
    }
])
.controller('AuthCiprCtrl', ['$scope', '$rootScope', '$state', '$stateParams', '$window', 'blockUI', 'AuthService',
    function($scope, $rootScope, $state, $stateParams, $window, blockUI, AuthService) {
        $window.document.title = 'AIC Licensing';

        $scope.cipr = '';
        $scope.password = '';
        $scope.rememberme = 'no';
        $scope.error = undefined;

        $scope.login = function() {
            var loginBlock = blockUI.instances.get('login-block');
            loginBlock.start('Authenticating...');

            var credentials = {
                cipr: $scope.cipr,
                password: $scope.password,
                rememberme: $scope.rememberme
            };
            AuthService.login(credentials).then(function() {
                loginBlock.stop();
                $state.go($rootScope.welcomeState, $rootScope.welcomeStateParams);
            }, function(res) {
                loginBlock.stop();
                $scope.error = res.status;
            });
        };
    }
])
.controller('AuthEmailCtrl', ['$scope', '$rootScope', '$state', '$stateParams', '$window', 'blockUI', 'AuthService',
    function($scope, $rootScope, $state, $stateParams, $window, blockUI, AuthService) {
        $window.document.title = 'AIC Licensing';

        $scope.email = '';
        $scope.password = '';
        $scope.rememberme = 'no';
        $scope.error = undefined;

        $scope.login = function() {
            var loginBlock = blockUI.instances.get('login-block');
            loginBlock.start('Authenticating...');

            var credentials = {
                email: $scope.email,
                password: $scope.password,
                rememberme: $scope.rememberme
            };
            AuthService.login(credentials).then(function() {
                loginBlock.stop();
                $state.go($rootScope.welcomeState, $rootScope.welcomeStateParams);
            }, function(res) {
                loginBlock.stop();
                $scope.error = res.status;
            });
        };
    }
])
.controller('RememberMeCtrl', ['$scope', '$rootScope', '$state', '$stateParams', 'AuthService',
    function($scope, $rootScope, $state, $stateParams, AuthService) {
        $scope.error = undefined;

        AuthService.login({}, function() {
            $state.go($rootScope.welcomeState, $rootScope.welcomeStateParams);
        }, function(data, status) {
            $scope.error = status;
            $state.go('loginwithcipr');
        });
    }
])
.controller('DevToolsCtrl', ['$scope', '$rootScope', '$state', '$window', '$timeout', '$templateCache', 'dialogs',
    function($scope, $rootScope, $state, $window, $timeout, $templateCache, dialogs) {
        $scope.clearTemplateCache = function() {

            var dlg = dialogs.confirm('Clear Template Cache', 'Do you want to clear all templates?');
            dlg.result.then(function(btn) {
                $templateCache.removeAll();
                console.log('Template cache cleared');
            }, function(btn) {
                console.log('Template cache NOT cleared');
            });
        };
    }
])

/**
 * HTTP status controllers
 */
.controller('500Ctrl', ['$scope', '$rootScope', '$state', '$stateParams',
    function($scope, $rootScope, $state, $stateParams) {
        $scope.requestId = $stateParams.requestId;
    }
])

/**
 * Factories
 */
.factory('AuthInterceptor', ['$q', '$location', '$cookies', '$injector', 'store',
    function($q, $location, $cookies, $injector, store) {
        return {
            response: function(response) {
                var receivedToken = response.headers('x-token');
                if(receivedToken && receivedToken != null){
                    if(!response.config.cache){
                        store.set('licensing', receivedToken);
                    }
                }
                return response;
            },
            responseError: function(rejection) {
                var requestId = rejection.data ? rejection.data.requestId : '';
                switch(rejection.status) {
                    case 401:
                        !rejection.config.ignore401 ? $injector.get('$state').go('logout') : '';
                        break;
                    case 403:
                        $injector.get('$state').go('403');
                        break;
                    case 404:
                        $injector.get('$state').go('404');
                        break;
                    case 410:
                        $injector.get('$state').go('410');
                        break;
                    case 412:
                    case 417:
                        $injector.get('$state').go('logout');
                        break;
                    case 500:
                        $injector.get('$state').go('500', {requestId: requestId});
                        break;
                }
                return $q.reject(rejection);
            }
        };
    }
])
.factory('AuthService', ['$http', '$cookies', 'store', 'jwtHelper',
    function($http, $cookies, store, jwtHelper) {

        return {

            isAuthenticated: function() {
                return store.get('licensing') && !jwtHelper.isTokenExpired(store.get('licensing'));
            },

            login: function(credentials) {
                var self = this;

                return $http({
                    method: 'POST',
                    url: '/login',
                    data: credentials,
                    ignore401: true,
                    headers: {'Content-Type': 'application/json'}
                }).then(function(response) {

                    // following deprecated
                    store.set('licensing', response.data.token);
                    store.set('profile', response.data.profile);
                    store.set('roles', response.data.roles);
                    store.set('roleContext', response.data.roles.person[0]);
                });
            },

            logout: function() {
                return $http({
                    method: 'POST',
                    url: '/logout'
                }).then(function(response) {
                    $cookies.remove('rememberme');
                    store.remove('licensing');
                    store.remove('roleContext');
                });
            },

            clearContexts: function() {
                store.remove('licensing');
            },

            setRoleContext: function(ciprid) {
                var profile = store.get('profile');
                var roles = store.get('roles');
                if(!profile) return;

                var needle = ciprid || profile.ciprid;
                var contexts = _.union(roles.insurer, roles.agency, roles.person);
                var context = _.find(contexts, {ciprid: needle.toString()});
                store.set('roleContext', context);
            },

            getToken: function() {
                return store.get('licensing');
            },

            getRoleContext: function() {
                return store.get('roleContext');
            },

            getProfile: function() {
                return store.get('profile');
            },

            getRoles: function() {
                return store.get('roles');
            }
        };
    }
]);
