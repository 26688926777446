angular.module('aic.filters', [])

.filter('trim', function() {
    return function(text) {
        return test ? String(text).trim() : '';
    };
})
.filter('phone', function() {
    return function(tel) {
        if(!tel) {
            return '';
        }

        var value = tel.toString().trim().replace(/^\+/, '');

        if(value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch(value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if(country == 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + " (" + city + ") " + number).trim();
    };
})
/**
 * http://plnkr.co/edit/a3KlK8dKH3wwiiksDSn2?p=preview
 * AngularJS default filter with the following expression:
 * "person in people | filter: {name: $select.search, age: $select.search}"
 * performs a AND between 'name: $select.search' and 'age: $select.search'.
 * We want to perform a OR.
 */
.filter('orFilter', function() {
    return function(items, props) {
        var out = [];

        if(angular.isArray(items)) {
            items.forEach(function(item) {
                var itemMatches = false;

                var keys = Object.keys(props);
                for(var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if(item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if(itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    }
})
.filter('time', function() {
    return function(time) {
        return moment(new Date(time)).format('HH:mm');
    };
})
.filter('timewithmeridiem', function() {
    return function(time) {
        return  moment(new Date(time)).format('h:mm a');
    };
})
.filter('timezone', function() {
    return function(date) {
        return moment(date,'YYYY-MM-DD hh:mm').tz('America/Edmonton').format('z');
    };
})
.filter('dtwithtz', function() {
    return function(dt) {
        return moment(dt,'YYYY-MM-DD hh:mm').tz('America/Edmonton').format('dddd, MMM DD, YYYY @ hh:mm A z');
    };
})
.filter('dtwithtzshort', function() {
    return function(dt) {
        return moment(dt,'YYYY-MM-DD hh:mm').tz('America/Edmonton').format('MMM DD, YYYY hh:mm A z');
    };
})
.filter('to_trusted', ['$sce', function($sce){
    return function(text) {
        return $sce.trustAsHtml(text);
    };
}])
.filter('capitalize', function() {
    return function(input, scope) {
        if (input!=null)
            input = input.toLowerCase();
        return input.substring(0,1).toUpperCase()+input.substring(1);
    }
});



