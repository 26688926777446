angular.module('aic.publish', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router'
])

.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('publisher', {
            parent: 'workspace',
            url: '/publish',
            templateUrl: 'notifications/publish/publish.html',
            controller: 'PublishNotificationsCtrl'
        });
    }
])

.controller('PublishNotificationsCtrl', ['$scope', '$state', '$http', 'toastr',
    function($scope, $state, $http, toastr) {
        $scope.cipr = "";
        $scope.subject = "";
        $scope.message = "";

        $scope.publish = function() {
            $http.post('/staff/collab/publish/' + $scope.cipr, {
                subject: $scope.subject,
                message: $scope.message
            })
            .then(function(res) {
                toastr.success('Success!', 'Message delivered');
            }, function(res) {
                toastr.error('Oops!', 'Message could not be delivered. Try again?');
            });
        };

        $scope.resetForm = function() {
            $scope.cipr = "";
            $scope.subject = "";
            $scope.message = "";
        };
    }
]);