var wo = angular.module('aic.wo', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
wo.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;


    }
])
.service('WoService', ['$http', '$q',
    function($http, $q) {
        this.getWos = function(partyType, partyId) {
            return $http.get('/staff/wo/', {
                params: {
                    partyType: partyType,
                    partyId: partyId
                }
            });
        };

        this.getWoStatuses = function() {
            return $http.get('/staff/wo/statuses', {cache: true});
        };

    }
]);