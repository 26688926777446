var exams = angular.module('aic.exams', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router',
    'ui.bootstrap.timepicker',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'aic.subscribe',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

exams.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {
        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('exams', {
            parent: 'workspace',
            abstract: true,
            url: '/exams',
            templateUrl: 'exams/exams.html',
            controller: 'ExamsCtrl'
        })
        .state('exams.results', {
            url: '/results',
            templateUrl: 'exams/exams.results.html',
            controller: 'ExamsResultsCtrl'
        })
        .state('exams.result', {
            url: '/{examId:[0-9]+}/result',
            templateUrl: 'exams/exams.result.view.html',
            controller: 'ExamsResultsViewCtrl'
        })
        .state('exams.scheduled', {
            url: '/scheduled',
            templateUrl: 'exams/exams.scheduled.html',
            controller: 'ScheduledExamsCtrl'
        })
        .state('exams.view', {
            url: '/{examId:[0-9]+}/view',
            templateUrl: 'exams/exams.view.html',
            params: {'showDialog': false},
            controller: 'ExamsViewCtrl'
        })
        .state('exams.registration', {
            url: '/registration',
            templateUrl: 'exams/exams.registration.html',
            controller: 'ExamsRegistrationCtrl'
        })
        .state('exams.registration.unsuccess', {
            url: '/unsuccess',
            templateUrl: 'exams/exams.registration.unsuccess.html'
        });


        $stateProvider
        .state('register', {
            parent: 'workspace',
            abstract: true,
            url: '/register',
            templateUrl: 'exams/register.html'
        })
        .state('register.schedule', {
            url: '/schedule',
            templateUrl: 'exams/exams.register.html',
            controller: 'ExamsRegisterCtrl'
        })
        .state('register.search', {
            url: '/search',
            templateUrl: 'exams/exams.register.search.html',
            controller: 'ExamsRegisterSearchCtrl'
        })
        .state('register.proctor', {
            url: '/proctor',
            templateUrl: 'exams/proctor/exams.proctor.html',
            controller: 'ExamsProctorReportCtrl'
        })
        .state('register.manage', {
            url: '/{scheduleId:[0-9]+}/manage',
            templateUrl: 'exams/exams.register.manage.html',
            controller: 'ExamsRegisterManageCtrl'
        });
    }
])
.controller('ExamsCtrl', ['$scope', '$state', '$stateParams', '$http', 'DTOptionsBuilder',
    function($scope, $state, $stateParams, $http, DTOptionsBuilder) {
        $scope.now = new Date();

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();
    }
])
.controller('ExamsResultsCtrl', ['$scope', '$state', '$stateParams', 'ExamService',
    function($scope, $state, $stateParams, ExamService) {

        ExamService.getExamResults($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.results = res.data;
        });

        $scope.viewExamResults = function(examResult) {
            $state.go('exams.result', {examId: examResult.id});
        }
    }
])
.controller('ExamsResultsViewCtrl', ['$scope', '$state', '$stateParams', 'ExamService',
    function($scope, $state, $stateParams, ExamService) {
        ExamService.getExamDetails($scope.roleContext.ciprid, $stateParams.examId, []).then(function(res) {
            $scope.examResult = res.data.exam[0];
        });
    }
])
.controller('ScheduledExamsCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'SweetAlert', 'ExamService', 'ExamRegisterService',
    function($scope, $state, $stateParams, dialogs, SweetAlert, ExamService, ExamRegisterService) {

        ExamService.getScheduledExams($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.exams = res.data;
        });

        ExamService.getReScheduledHours($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.rescheduleHours = res.data.rescheduleHours;
        });

        $scope.viewScheduledExam = function(exam) {
            $state.go('exams.view', {examId: exam.id, showDialog: false});
        };

        $scope.reschedule = function(exam) {
            var examDateTime = moment(exam.exam_dt + 'T' + exam.start_tm);
            var now = moment();
            var hoursToExam = examDateTime.diff(now, 'hours');

            if(hoursToExam <= $scope.rescheduleHours) {
                swal("Can't reschedule exam within " + $scope.rescheduleHours + " hour(s) to exam")
                return;
            }
            ExamRegisterService.setExamLoc({"description": exam.exmloc_desc, "id": exam.exmloc_cd});
            ExamRegisterService.setExamType({"description": exam.exmtyp_desc, "id": exam.exmtyp_cd});
            var onsuccess = function(res) {
                var data = res.data;
                if(data.error) {
                    var errorval = typeof data.error === 'object' ? 'Error during exam reschedule' : data.error;
                    toastr.error('Error!', errorval);
                } else {
                    toastr.success('Success!', 'Exam Reschedule success');
                    ExamService.getScheduledExams($scope.roleContext.ciprid)
                    .then(function(res) {
                        $scope.exams = res.data;
                    });
                }
            };
            var onerror = function(data, status) {
                toastr.error('Error!', 'Exam Rescheduled failed.');
            };

            var data = {
                ciprid: $scope.roleContext.ciprid,

                regid: exam.id,
                examType: exam.exmtyp_desc,
                examLoc: exam.exmloc_desc,
                isPerson: $scope.isPerson(),
                profile: $scope.profile
            };
            dialogs.create('exams/dialogs/reschedule.html', 'ExamRescheduleDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(data) {
                ExamService.reschedule($scope.roleContext.ciprid, data.regid, data.scheduleid).then(onsuccess, onerror);
            });
        };


    }
])
.controller('ExamsViewCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'blockUI', 'ExamService', 'ExamCountdownService', 'InvoiceService', 'DownloadService',
    function($scope, $state, $stateParams, dialogs, toastr, blockUI, ExamService, ExamCountdownService, InvoiceService, DownloadService) {
        $scope.specialCondition = 'N';
        var dateFormat = 'MMMM DD, YYYY';
        var examViewBlock = blockUI.instances.get('exam-view-block');
        examViewBlock.start();
        ExamService.getExamDetails($scope.roleContext.ciprid, $stateParams.examId, ['questions', 'declarations', 'invoice', 'invoiceitems']).then(function(res) {
            examViewBlock.stop();
            $scope.examResult = res.data;
            $scope.examResult.exam = res.data.exam[0];
            var firstDecl = _.first($scope.examResult.declarations);
            $scope.examResult.declDate = firstDecl != undefined ? firstDecl.responsetimestamp ? moment(firstDecl.responsetimestamp).format(dateFormat) : '' : undefined;
        });

        ExamService.getReScheduledHours($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.rescheduleHours = res.data.rescheduleHours;
        });

        $scope.getExamDeclaration = function(decl) {
            if(decl.declarationgroup === "EXAM_APP")
                return decl;
        };

        $scope.getOnlineExamDeclaration = function(decl) {
            if(decl.declarationgroup === "EXAM_APP_ONLINE")
                return decl;
        };

        $scope.onlineDeclarationExists = function(declarations) {
            return _.filter(declarations, {declarationgroup: 'EXAM_APP_ONLINE'}).length > 0
        };

        $scope.getCOVIDWaiverExamDeclaration = function(decl) {
            if(decl.declarationgroup === "EXAM_APP_COVID_WAIVER")
                return decl;
        };


        $scope.canReschedule = function(examdate, examtime) {
            var examDateTime = moment(examdate + 'T' + examtime);
            var now = moment();
            var hoursToExam = examDateTime.diff(now, 'hours');
            if(hoursToExam <= $scope.rescheduleHours) {
                return false;
            }
            return true;
        };


        $scope.submitCondAndNote = function() {
            var onsuccess = function() {
                toastr.success('Success!', 'Exam condition and note has been saved.');
            };
            var onerror = function() {
                toastr.error('Error!', 'Exam condition and note could not be saved at this time.');
            };
            ExamService.SubmitCondAndNote($scope.roleContext.ciprid, $scope.examResult.exam.note, $scope.examResult.exam.specialCondition, $scope.examResult.exam.id).then(onsuccess, onerror);
        };

        $scope.submitExamQuesAndDecl = function() {
            if(!$scope.isFormValid()) return;

            ExamService.SubmitQuesAndDecls($scope.roleContext.ciprid, $scope.examResult, $scope.examResult.exam.id)
            .then(function(reply) {
                toastr.success('Success!', 'Exam Registration Question and Declarations saved.');
                if($scope.isPerson() && $stateParams.showDialog) {
                    ExamService.getScheduleDetails($scope.roleContext.ciprid, $scope.examResult.exam.exmsch_seq).then(function(res) {
                        $scope.schedule = res.data[0];
                        var rescheduleAllowed = $scope.canReschedule($scope.schedule.exam_dt, $scope.schedule.start_tm_format);

                        var confirmation = {
                            exam: $scope.examResult.exam.exmtyp_desc,
                            schedule: $scope.schedule.description,
                            location: $scope.schedule.location,
                            fee: $scope.examResult.exam.fee,
                            id: $scope.examResult.exam.id,
                            rescheduleAllowed: rescheduleAllowed,
                            invoice: $scope.examResult.invoice[0],
                            invoiceitems: $scope.examResult.invoiceitems
                        };
                        dialogs.create('exams/dialogs/confirmation.html', 'ExamConfirmationDialogCtrl', confirmation, {
                            'keyboard': false,
                            'backdrop': 'static',
                            'size': 'lg'
                        }).result.then(function(data) {
                            if(data.finish)
                                if($scope.examResult.invoice[0].totaldue == 0 && $scope.examResult.invoice[0].itemcount > 0) {
                                    examViewBlock.start();
                                    InvoiceService.markaspaid($scope.roleContext.ciprid, $scope.examResult.invoice[0].invoiceid)
                                    .then(function() {
                                        ExamCountdownService.reset();
                                        examViewBlock.stop();
                                        $state.go('invoices.view', {invoiceId: $scope.examResult.exam.invoiceid});
                                    }, function() {
                                        examViewBlock.stop();
                                        $state.go('invoices.view', {invoiceId: $scope.examResult.exam.invoiceid});
                                    });
                                } else
                                    $state.go('invoices.view', {invoiceId: $scope.examResult.exam.invoiceid});
                            else
                                $state.go('exams.registration');

                        }, function() {
                            $scope.abandon();
                        });

                    });

                }
            }, function(reply, status) {
                var msgs = reply.error;
                dialogs.create('exams/dialogs/errors.html', 'ExamErrorsDialogCtrl', msgs, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                });
            });

        };


        $scope.abandon = function() {
            var onsuccess = function() {
                toastr.success('Success!', 'Exam registration has been cancelled.');
            };
            var onerror = function() {
                toastr.error('Error!', 'Exam registration could not be cancelled at this time.');
            };

            ExamService.abandon($scope.roleContext.ciprid, $scope.examResult.exam.id).then(onsuccess, onerror);

            if($scope.isPerson() && $stateParams.showDialog) {
                ExamCountdownService.reset();
            }
            $state.go('exams.registration', {});

        };

        $scope.isFormValid = function() {
            var checkDeclarations = function(d) {
                d.errors = d.response == 'Y' ? [] : ['Do you confirm?'];
                return d;
            };

            _.map($scope.examResult.questions, function(q) {
                q.errors = q.answer ? [] : ['Please answer this question'];
                q.answerdetailserrors = q.answer != q.affirmativeanswer && q.hasanswerdetails == 'Y' && _.isEmpty(q.answerdetails) ? ['Please provide details to this question'] : [];
                return q;
            });

            _.map($scope.examResult.declarations, checkDeclarations);


            $scope.incompleteQuestions = _.chain($scope.examResult.questions)
            .flatten()
            .map('errors')
            .flatten()
            .some(Boolean)
            .value();

            $scope.incompleteAnswerDetails = _.chain($scope.examResult.questions)
            .flatten()
            .map('answerdetailserrors')
            .flatten()
            .some(Boolean)
            .value();

            $scope.incompleteDeclarations = _.chain($scope.examResult.declarations)
            .flatten()
            .map('errors')
            .flatten()
            .some(Boolean)
            .value();

            return !$scope.incompleteQuestions && !$scope.incompleteAnswerDetails && !$scope.incompleteDeclarations;

        };

        /**
         * Constructs a download reg link
         * @returns {string}
         */
        $scope.downloadRegLink = function() {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + $stateParams.examId + "/registrationtopdf?ciprid=" + $scope.roleContext.ciprid;
            var anchorText = S('registration_{{examId}}.pdf').template({examId: $stateParams.examId}).s;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Constructs a download dec link
         * @returns {string}
         */
        $scope.downloadDecLink = function() {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + $stateParams.examId + "/declarationtopdf?ciprid=" + $scope.roleContext.ciprid;
            var anchorText = S('declaration_{{examId}}.pdf').template({examId: $stateParams.examId}).s;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Constructs a download waiver link
         * @returns {string}
         */
        $scope.downloadWaiverLink = function() {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + $stateParams.examId + "/waivertopdf?ciprid=" + $scope.roleContext.ciprid;
            var anchorText = S('waiver_{{examId}}.pdf').template({examId: $stateParams.examId}).s;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

    }
])
.controller('ExamErrorsDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.errors = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])
/* External user registration */
.controller('ExamsRegistrationCtrl', ['$scope', '$state', '$stateParams', '$filter', '$http', 'dialogs', 'SweetAlert', 'toastr', 'ExamCountdownService', 'ExamService',
    function($scope, $state, $stateParams, $filter, $http, dialogs, SweetAlert, toastr, ExamCountdownService, ExamService) {
        $scope.examTypes = [];
        $scope.examLocs = [];
        $scope.generalProviders = [];
        $scope.examSchedules = [];
        // TODO: set as global variable
        $scope.examMailLink = "mailto: exams@abcouncil.ab.ca";
        $scope.examType = undefined;
        $scope.examLoc = undefined;
        $scope.disableRegistration = undefined;
        $scope.registrationError = undefined;
        $scope.loading = false;
        $scope.examtosave = [];
        $scope.specialCondition = 'N';
        // -- Initializers --
        ExamService.getExamTypes($scope.roleContext.ciprid).then(function(res) {
            $scope.examTypes = res.data;
        });

        ExamService.getExamLocations($scope.roleContext.ciprid).then(function(res) {
            $scope.examLocs = res.data;
            ExamService.getExamAllow($scope.roleContext.ciprid, $scope.roleContext.partyid).then(function(res) {
                var onlineExamAllow = res.data[0] && res.data[0].onlineExamAllow ? res.data[0].onlineExamAllow : "";
                var inPersonExamAllow = res.data[0] && res.data[0].inPersonExamAllow ? res.data[0].inPersonExamAllow : "";
                var onlineExamAllowedByLocation = res.data[0] && res.data[0].onlineExamAllowedByLocation ? res.data[0].onlineExamAllowedByLocation : "";
                $scope.filterExamLocations(onlineExamAllow, inPersonExamAllow, onlineExamAllowedByLocation);
            });


        });

        $scope.filterExamLocations = function(onlineExamAllow, inPersonExamAllow, onlineExamAllowedByLocation) {
            if($scope.isPerson() && $scope.profile.address.province.trim().toLowerCase() !== 'alberta') {
                    _.remove($scope.examLocs, function(loc) {
                        return loc.id.trim() === 'OE'
                    });
            }

            if(onlineExamAllowedByLocation === 'Y' && $scope.profile.address.province.trim().toLowerCase() === 'alberta' && _.includes(['calgary','edmonton', 'red deer', 'medicine hat', 'lethbridge', 'fort mcmurray', 'grande prairie'],$scope.profile.address.city.trim().toLowerCase())){
                _.remove($scope.examLocs, function(loc) {
                    return loc.id.trim() === 'OE'
                });
            }
            if(onlineExamAllow === 'N') {
                _.remove($scope.examLocs, function(loc) {
                    return loc.id.trim() === 'OE'
                });
            }
            if(inPersonExamAllow === 'N') {
                _.remove($scope.examLocs, function(loc) {
                    return loc.id.trim() !== 'OE'
                });
            }
        }

        ExamService.getGeneralProviders($scope.roleContext.ciprid).then(function(res) {
            $scope.generalProviders = res.data;
        });

        ExamService.getReScheduledHours($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.rescheduleHours = res.data.rescheduleHours;
        });


        $scope.$watch('examType', function() {
            $scope.examSchedules = [];
        });

        $scope.$watch('examLoc', function() {
            $scope.examSchedules = [];
        });

        // -- Called from form --

        $scope.canReschedule = function(schedule) {
            var examDateTime = moment(schedule.exam_dt + 'T' + schedule.start_tm_format);
            var now = moment();
            var hoursToExam = examDateTime.diff(now, 'hours');
            if(hoursToExam <= $scope.rescheduleHours) {
                return false;
            }
            return true;
        };

        $scope.reset = function() {
            $scope.examSchedules = [];
            $scope.disableRegistration = undefined;
            $scope.registrationError = undefined;
            $scope.examType = undefined;
            $scope.generalProvider = undefined;
            $scope.examLoc = undefined;
        };


        $scope.getSchedules = function() {
            $scope.loading = true;

            ExamService.getSchedules($scope.roleContext.ciprid, $scope.examType.id, $scope.examLoc.id).then(function(res) {
                $scope.loading = false;
                $scope.examSchedules = res.data[0];
                $scope.examSchedulesAvailability = res.data[1];
                if($scope.examSchedules.length === 0) $scope.registrationError = "No upcoming Exam schedules found for selected criteria.";
                else $scope.registrationError = undefined;

                var addavailabilitydetails = function(s) {
                    s.examTimezone = moment(s.exam_dt).tz('America/Edmonton').format('z');
                    s.availabilitydetails = _.find($scope.examSchedulesAvailability, {
                        exam_dt: s.exam_dt,
                        start_tm: s.start_tm,
                        exmloc_cd: s.exmloc_cd
                    });
                    return s;
                };


                $scope.examSchedules = _.map($scope.examSchedules, addavailabilitydetails);

            });
        };

        $scope.save = function(schedule, llqpProviderId) {
            $http.put('/' + $scope.roleContext.ciprid + '/exams/', {
                data: {
                    schId: schedule.id,
                    examType: $scope.examType.id,
                    llqpProviderId: llqpProviderId,
                    specialCondition: $scope.specialCondition
                }
            })
            .then(function(res) {
                var results = res.data;
                if(results.error) {
                    $scope.disableRegistration = false;
                    $scope.registrationError = typeof results.error === 'object' ? 'Error during exam registration' : results.error;
                } else {
                    var invoice = results.invoice[0];
                    var registration = results.examReg[0];
                    var expiremin = results.examReg[0].expiremin;
                    var countdown = expiremin * 60;
                    var docinstid = results.examReg[0].docinstid;
                    if(!ExamCountdownService.isRunning()) {
                        ExamCountdownService.startCountdown(countdown, function() {
                            if(ExamCountdownService.getCounter()) {
                                dialogs.error('Exam Registration Cancelled', 'Your registration was cancelled because payment was not completed in the allocated time', {
                                    'size': 'sm'
                                });
                                ExamCountdownService.reset();
                            }
                        });
                    }
                    $state.go('exams.view', {examId: results.examReg[0].id, showDialog: true});


                }
            }, function(data, status) {
                toastr.error('Error!', 'Exam registration can not be done at this time.');
            });
        };

        $scope.checkProviderAndCallSave = function(schedule) {
            if($scope.examType.lifeInsurance.trim() === 'Y' || $scope.examType.accidentSickness.trim() === 'Y' || $scope.examType.common.trim() === 'Y' || $scope.examType.civil.trim() === 'Y' || $scope.examType.sf.trim() === 'Y') {
                $http.get('/' + $scope.roleContext.ciprid + '/exams/llqpeligible', {
                    params: {
                        examDate: schedule.exam_dt,
                        exmtype: $scope.examType.id,
                        lifeInsurance: $scope.examType.lifeInsurance,
                        accidentSickness: $scope.examType.accidentSickness,
                        common: $scope.examType.common,
                        civil: $scope.examType.civil,
                        sf: $scope.examType.sf
                    }
                })
                .then(function(res) {
                    var llqp = res.data[0];
                    if(llqp) {
                        var llqpProviderId = "";
                        $http.get('/' + $scope.roleContext.ciprid + '/exams/llqpProvider', {
                            params: {
                                orgciprid: llqp.orgciprid
                            }
                        })
                        .then(function(res) {
                            llqpProviderId = res.data[0] ? res.data[0].provid : "";
                            if(llqpProviderId != "") {
                                $scope.save(schedule, llqpProviderId);
                            } else {
                                $scope.disableRegistration = false;
                                $scope.registrationError = "Unable to find LLQP Provider.";
                            }

                        });

                    } else {
                        $scope.disableRegistration = false;
                        $scope.registrationError = "You do not have a valid LLQP certificate for this exam.";
                    }
                });
            } else if($scope.examType.general.trim() === 'Y') {
                $http.get('/' + $scope.roleContext.ciprid + '/exams/glqpeligible', {
                    params: {
                        examDate: schedule.exam_dt
                    }
                })
                .then(function(res) {
                    var glqp = res.data[0];
                    if(glqp) {
                        var glqpProviderId = "";
                        $http.get('/' + $scope.roleContext.ciprid + '/exams/llqpProvider', {
                            params: {
                                orgciprid: glqp.orgciprid
                            }
                        })
                        .then(function(res) {
                            glqpProviderId = res.data[0] ? res.data[0].provid : "";
                            if(glqpProviderId != "") {
                                $scope.save(schedule, glqpProviderId);
                            } else {
                                $scope.disableRegistration = false;
                                $scope.registrationError = "Unable to find GLQP Provider.";
                            }

                        });

                    } else {
                        $scope.disableRegistration = false;
                        $scope.registrationError = "You do not have a valid GLQP certificate for this exam.";
                    }
                });

            } else {
                var provId = $scope.generalProvider && $scope.examType.id.indexOf('G') == 0 ? $scope.generalProvider.provid : ''
                $scope.save(schedule, provId);
            }

        };


        $scope.register = function(schedule) {
            $scope.disableRegistration = true;

            ExamService.similarRegExists($scope.roleContext.ciprid, $scope.examType.id.trim()).then(function(res) {
                if(res.data[0]) {
                    SweetAlert.swal({
                        title: "Looks like you already register for similar exam.",
                        text: "Do you still want to register for the exam?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f2784b",
                        confirmButtonText: "Yes!",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function(confirmed) {
                        if(confirmed) {
                            $scope.checkProviderAndCallSave(schedule);
                        } else
                            $scope.disableRegistration = false;

                    });
                } else
                    $scope.checkProviderAndCallSave(schedule);
            });

        };

        $scope.additonalFeeAlert = function(){
            if(!_.includes(['OE','CD','E'],$scope.examLoc.id.trim())){
                SweetAlert.swal({
                    html: 'true',
                    title: "Invigilation Fees",
                    text: "Additional invigilation fees may apply. For more information, please refer to <a target='_blank' href='https://www.abcouncil.ab.ca/schedules-locations/'>schedules & locations</a>.",
                    type: "warning",
                    showCancelButton: false,
                    confirmButtonColor: "#f2784b",
                    confirmButtonText: "Ok!",
                    closeOnConfirm: true
                },
                function(confirmed) {
                });
            }
        }

    }
])
.controller('ExamConfirmationDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.confirmation = data;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.finish = function() {
            $uibModalInstance.close({'finish': true});
        };

        $scope.register = function() {
            $uibModalInstance.close({'finish': false});
        };
    }
])
.controller('ExamScheduleDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.daysOfWeek = [{"id": '1', "description": 'Sunday'},
            {"id": '2', "description": 'Monday'},
            {"id": '3', "description": 'Tuesday'},
            {"id": '4', "description": 'Wednesday'},
            {"id": '5', "description": 'Thursday'},
            {"id": '6', "description": 'Friday'},
            {"id": '7', "description": 'Saturday'}];
        $scope.scheduleInfo = data;
        $scope.extregallowed = undefined;
        $scope.dayOfWeek = undefined;
        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close({'single': true, 'extregallowed': $scope.extregallowed});
        };

        $scope.no = function() {
            var starttimehour = $scope.scheduleInfo.startTime.getHours();
            var starttimeminute = $scope.scheduleInfo.startTime.getMinutes();
            var starttimestr = moment(new Date($scope.scheduleInfo.startTime)).format('h:mm a');
            if(starttimehour <= 7 || starttimehour >= 20 || (starttimehour >= 19 && starttimeminute >= 30)) {
                var res = confirm("The specified start time " + starttimestr + " falls between 7:30 PM and 7:00 AM. Is the start time correct?");
                if(res == true) {
                    $uibModalInstance.close({
                        'single': false,
                        'extregallowed': $scope.extregallowed,
                        'dayOfWeek': $scope.dayOfWeek
                    });
                } else {
                    $uibModalInstance.dismiss('Canceled');
                }
            } else {
                $uibModalInstance.close({
                    'single': false,
                    'extregallowed': $scope.extregallowed,
                    'dayOfWeek': $scope.dayOfWeek
                });
            }

        };
    }
])
.controller('ExamsRegisterCtrl', ['$scope', '$state', '$stateParams', '$filter', '$http', 'dialogs', 'SweetAlert', 'ExamService', 'ExamRegisterService',
    function($scope, $state, $stateParams, $filter, $http, dialogs, SweetAlert, ExamService, ExamRegisterService) {
        $scope.examTypes = [];
        $scope.examLocs = [];
        $scope.examRegistrationStatuses = [];
        $scope.examSchedules = ExamRegisterService.examSchedules;

        $scope.examType = ExamRegisterService.examType;
        $scope.examLoc = ExamRegisterService.examLoc;
        $scope.examStatus = ExamRegisterService.examStatus;
        $scope.registrationError = undefined;
        $scope.loading = false;
        $scope.dateFormat = 'MM dd, yyyy';
        $scope.fromDate = ExamRegisterService.fromDate;
        $scope.toDate = ExamRegisterService.toDate;
        $scope.startTime = ExamRegisterService.startTime;
        // -- Initializers --
        ExamService.getExamTypes($scope.roleContext.ciprid).then(function(res) {
            $scope.examTypes = res.data;
        });

        ExamService.getExamLocations($scope.roleContext.ciprid).then(function(res) {
            $scope.examLocs = res.data;
        });

        ExamService.getRegistrationStatuses($scope.roleContext.ciprid).then(function(res) {
            $scope.examRegistrationStatuses = res.data;
            $scope.examStatus = $scope.examStatus ? $scope.examStatus : _.find($scope.examRegistrationStatuses, {'id': 'HOPN  '});
        });

        $scope.reset = function() {
            $scope.examType = undefined;
            ExamRegisterService.setExamType($scope.examType);
            $scope.examLoc = undefined;
            $scope.examStatus = _.find($scope.examRegistrationStatuses, {'id': 'HOPN  '});
            var d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            $scope.fromDate = moment(d).format('MMMM DD, YYYY');
            $scope.toDate = moment(new Date().setMonth(d.getMonth() + 1)).format('MMMM DD, YYYY');
            $scope.startTime = d;
            $scope.examSchedules = [];
        };

        $scope.setExamType = function(item, model) {
            $scope.examType = model;
            ExamRegisterService.setExamType($scope.examType);
        };

        $scope.$watch('examLoc', function() {
            ExamRegisterService.setExamLoc($scope.examLoc);
        });

        $scope.$watch('examStatus', function() {
            ExamRegisterService.setExamStatus($scope.examStatus);
        });

        $scope.$watch('fromDate', function() {
            ExamRegisterService.setFromDate($scope.fromDate);
        });

        $scope.$watch('toDate', function() {
            ExamRegisterService.setToDate($scope.toDate);
        });

        $scope.$watch('startTime', function() {
            ExamRegisterService.setStartTime($scope.startTime);
        });

        $scope.newSchedule = function() {
            var confirmation = {
                examType: $scope.examType,
                examLoc: $scope.examLoc,
                examStatus: $scope.examStatus,
                startTime: $scope.startTime,
                fromDate: moment($scope.fromDate).format('MMMM DD, YYYY'),
                toDate: moment($scope.toDate).format('MMMM DD, YYYY')
            };
            dialogs.create('exams/dialogs/schedule.html', 'ExamScheduleDialogCtrl', confirmation, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(data) {
                //alert("singe:"+data.single + "\n extregallowed:"+data.extregallowed);
                $scope.addSchedule($scope.examType, $scope.examLoc, $scope.examStatus, $scope.fromDate, $scope.toDate, $scope.startTime, data.single, data.extregallowed, data.dayOfWeek ? data.dayOfWeek.id : '')

            }, function() {
            });

        };

        $scope.addSchedule = function(examType, examLoc, status, fromDate, toDate, startTime, single, extregallowed, dayOfWeek) {
            var onsuccess = function() {
                toastr.success('Success!', 'Exam Schedule has been created.');
                $scope.go();
            };
            var onerror = function() {
                toastr.error('Error!', 'Exam Schedule cannot be able to created.');
            };
            ExamService.createSchedule($scope.roleContext.ciprid, examType.id, examLoc.id, status.id, fromDate, toDate, startTime, single, extregallowed, dayOfWeek).then(onsuccess, onerror);
        };

        $scope.searchSchedules = function(examType, examLoc, status, fromDate, toDate, startTime) {
            $scope.loading = true;
            ExamService.lookupSchedules($scope.roleContext.ciprid, examType ? examType.id : '', examLoc.id, status.id, fromDate, toDate, startTime).then(function(res) {
                $scope.loading = false;
                $scope.examSchedules = res.data[0];
                $scope.examSchedulesAvailability = res.data[1];
                if($scope.examSchedules.length === 0) $scope.registrationError = "No Exam schedules found for selected criteria.";
                else $scope.registrationError = undefined;

                var addavailabilitydetails = function(s) {

                    s.availabilitydetails = _.find($scope.examSchedulesAvailability, {
                        exam_dt: s.exam_dt,
                        start_tm: s.start_tm,
                        exmloc_cd: s.exmloc_cd
                    });
                    return s;
                };
                $scope.examSchedules = _.map($scope.examSchedules, addavailabilitydetails);
                ExamRegisterService.setExamSchedules($scope.examSchedules);

            });
        };

        $scope.go = function() {
            $scope.fromDate = $scope.fromDate ? $filter('date')($scope.fromDate, "yyyy-MM-dd") : null;
            $scope.toDate = $scope.toDate ? $filter('date')($scope.toDate, "yyyy-MM-dd") : null;
            $scope.searchSchedules($scope.examType, $scope.examLoc, $scope.examStatus, $scope.fromDate, $scope.toDate, $scope.startTime);
        };

        $scope.manage = function(schedule) {
            $state.go('register.manage', {scheduleId: schedule.id});
        };

        $scope.update = function(schedule) {

            schedule.examdate = $filter('date')(schedule.examdate, "yyyy-MM-dd");

            var onsuccess = function() {
                toastr.success('Success!', 'Exam Schedule has been updated.');
                $scope.examSchedules = _.reject($scope.examSchedules, {'id': schedule.id});
                $scope.examSchedules.push(schedule);
                ExamRegisterService.removeExamSchedule(schedule);
                ExamRegisterService.addExamSchedule(schedule);
            };
            var onerror = function() {
                toastr.error('Error!', 'Exam Schedule cannot be able to updated.');
            };

            ExamService.updateSchedule($scope.roleContext.ciprid, schedule.id, schedule.examdate, schedule.examtime, schedule.examStatus, schedule.extregallowed).then(onsuccess, onerror);
        };

        $scope.remove = function(schedule) {

            var onsuccess = function(res) {
                var data = res.data;
                if(data.error) {
                    var errorval = data.error;
                    toastr.error('Error!', errorval);
                } else {
                    toastr.success('Success!', 'Exam Schedule has been deleted.');
                    $scope.examSchedules = _.reject($scope.examSchedules, {'id': schedule.id});
                    ExamRegisterService.removeExamSchedule(schedule);
                }
            };
            var onerror = function(data, status) {
                toastr.error('Error!', 'Unable to delete exam schedule at this time. Please try again later.');
            };

            ExamService.removeSchedule($scope.roleContext.ciprid, schedule.id).then(onsuccess, onerror);
        };

        $scope.clearResults = function() {
            $scope.examSchedules = [];
        }

    }
])
.controller('ExamsRegisterManageCtrl', ['$scope', '$state', '$stateParams', '$filter', '$http', 'toastr', 'dialogs', 'SweetAlert', 'blockUI', 'ExamService', 'InvoiceService', 'ExamRegisterService', 'DownloadService',
    function($scope, $state, $stateParams, $filter, $http, toastr, dialogs, SweetAlert, blockUI, ExamService, InvoiceService, ExamRegisterService, DownloadService) {
        $scope.showAllExamRegistration = false;
        $scope.disableRegistration = false;
        ExamService.getRegisterUserDetails($scope.roleContext.ciprid, $stateParams.scheduleId, $scope.showAllExamRegistration).then(function(res) {
            $scope.registerUsers = res.data;
        });
        ExamService.getScheduleDetails($scope.roleContext.ciprid, $stateParams.scheduleId).then(function(res) {
            $scope.scheduleDetails = res.data[0];

            ExamService.getScheduleAvailability($scope.roleContext.ciprid, res.data[0].exmloc_cd, res.data[0].exam_dt, res.data[0].start_tm).then(function(res) {
                $scope.scheduleAvailability = res.data[0];
            });


            ExamService.getProviders($scope.roleContext.ciprid).then(function(res) {
                $scope.providers = res.data;
                if($scope.scheduleDetails.life === 'Y')
                    $scope.providers = _.filter($scope.providers, {life: 'Y'});
                else if($scope.scheduleDetails.a_s === 'Y')
                    $scope.providers = _.filter($scope.providers, {A_S: 'Y'});
                else if($scope.scheduleDetails.gen == 'Y')
                    $scope.providers = _.filter($scope.providers, {GEN: 'Y'});
                else if($scope.scheduleDetails.adj == 'Y')
                    $scope.providers = _.filter($scope.providers, {ADJ: 'Y'});
                else
                    $scope.providers = [];

            });

        });

        ExamService.getReScheduledHours($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.rescheduleHours = res.data.rescheduleHours;
        });


        $scope.canReschedule = function(examdate, examtime) {
            var examDateTime = moment(examdate + 'T' + examtime);
            var now = moment();
            var hoursToExam = examDateTime.diff(now, 'hours');
            if(hoursToExam <= $scope.rescheduleHours) {
                return false;
            }
            return true;
        };


        ExamService.getExamVersions($scope.roleContext.ciprid, $stateParams.scheduleId).then(function(res) {
            $scope.versions = res.data;
        });

        ExamService.getExamStatuses($scope.roleContext.ciprid).then(function(res) {
            $scope.examstatuses = res.data;
        });

        $scope.showNewRegisterForm = function() {
            if($scope.scheduleAvailability && $scope.scheduleAvailability.availability <= 0) {
                dialogs.error('Unable to add a seating.', ' Location capacity has been reached.', {
                    'size': 'sm'
                });
            } else {
                $scope.newRegistrationForm = true;
            }
        };

        $scope.hideNewRegisterForm = function() {
            $scope.newRegistrationForm = false;
        };


        $scope.update = function(registeruser) {

            registeruser.providername = registeruser.provid ? _.find($scope.providers, {'provid': registeruser.provid}).providername : '';


            var onsuccess = function() {
                toastr.success('Success!', 'Exam Registration has been updated.');
                $scope.registerUsers = _.reject($scope.registerUsers, {'regid': registeruser.regid});
                $scope.registerUsers.push(registeruser);
            };
            var onerror = function() {
                toastr.error('Error!', 'Exam Registration cannot be able to updated.');
            };

            ExamService.update($scope.roleContext.ciprid, registeruser.regid, registeruser.provid).then(onsuccess, onerror);
        };

        $scope.score = function(registeruser) {

            registeruser.versiondesc = registeruser.versionid ? _.find($scope.versions, {'id': registeruser.versionid}).description : '';
            registeruser.score = registeruser.score;


            var onsuccess = function(res) {
                var data = res.data;
                toastr.success('Success!', 'Exam successfully scored.');
                $scope.registerUsers = _.reject($scope.registerUsers, {'regid': registeruser.regid});
                registeruser.statusdesc = _.find($scope.examstatuses, {'id': data[0].statusid}).description;
                registeruser.statusid = data[0].statusid;
                registeruser.gooduntil = data[0].gooduntil;
                registeruser.c_current = data[0].c_current;
                $scope.registerUsers.push(registeruser);
            };
            var onerror = function() {
                toastr.error('Error!', 'Unable to scored exam.');
            };

            ExamService.score($scope.roleContext.ciprid, registeruser.regid, registeruser.score, registeruser.versionid).then(onsuccess, onerror);
        };

        $scope.notWritten = function(registeruser) {


            var onsuccess = function(res) {
                var data = res.data;
                toastr.success('Success!', 'Exam marked as unwritten.');
                $scope.registerUsers = _.reject($scope.registerUsers, {'regid': registeruser.regid});
                registeruser.statusdesc = _.find($scope.examstatuses, {'id': data[0].statusid}).description;
                registeruser.statusid = data[0].statusid;
                registeruser.gooduntil = data[0].gooduntil;
                registeruser.c_current = data[0].c_current;
                $scope.registerUsers.push(registeruser);
            };
            var onerror = function() {
                toastr.error('Error!', 'Unable to marked as unwritten.');
            };

            ExamService.notWritten($scope.roleContext.ciprid, registeruser.regid).then(onsuccess, onerror);
        };

        $scope.remove = function(registeruser) {
            let msg = "Are you sure you want to void exam registration?";
            if(registeruser.feebalance < registeruser.fee && registeruser.feepaidbalance > 0)
                msg = msg + " <span class='text-danger' >The exam sitting has been paid. Please refund the amount by clicking on $ button.</span>"
            var onsuccess = function(res) {
                toastr.success('Success!', 'Exam Registration has been voided.');
                $scope.registerUsers = _.reject($scope.registerUsers, {'regid': registeruser.regid});
                ExamService.getScheduleDetails($scope.roleContext.ciprid, $stateParams.scheduleId).then(function(res) {
                    $scope.scheduleDetails = res.data[0];
                    ExamService.getScheduleAvailability($scope.roleContext.ciprid, res.data[0].exmloc_cd, res.data[0].exam_dt, res.data[0].start_tm).then(function(res) {
                        $scope.scheduleAvailability = res.data[0];
                    });
                });
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to delete exam registration at this time. Please try again later');
            };

            SweetAlert.swal({
                html: 'true',
                title: "Void Registration",
                text: msg,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    ExamService.remove($scope.roleContext.ciprid, registeruser.regid).then(onsuccess, onerror);
                }
            });

        };

        $scope.refund = function(registeruser) {

            var onsuccess = function(res) {
                var data = res.data;
                toastr.success('Success!', 'Exam Fee has been successfully returned to money on account.');
                $scope.registerUsers = _.reject($scope.registerUsers, {'regid': registeruser.regid});
                registeruser.feepaidbalance = data[0].feepaidbalance;
                $scope.registerUsers.push(registeruser);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Exam fee cannot be returned at this time.');
            };
            var data = {
                title: 'Return Examination fee for ' + registeruser.name,
                registeruser: registeruser
            };

            dialogs.create('exams/dialogs/refundreason.html', 'RefundReasonDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(reason) {
                ExamService.refund($scope.roleContext.ciprid, registeruser.regid, reason).then(onsuccess, onerror);
            });
        };

        $scope.checkRegistrant = function(registrantciprid) {
            $scope.selectedRegistrantError = undefined;
            $scope.selectedRegistrantOutOfProvinceError = undefined;
            $scope.selectedRegistrant = undefined;
            return $http.get('/lookup/user/byciprid', {
                params: {
                    ciprid: registrantciprid
                }
            }).then(function(res) {
                if(res.data.profile) {
                    if($scope.scheduleDetails.exmloc_cd.trim() === "OE" && res.data.profile.address.province.toLowerCase() !== "alberta") {
                        $scope.selectedRegistrantOutOfProvinceError = 'Exam registrant address is out of province';
                    }
                    $scope.selectedRegistrant = res.data.profile;
                } else
                    $scope.selectedRegistrantError = 'invalid';

            });
        };


        $scope.save = function(registrantciprid) {
            $scope.createExamRegistrationInProgress = true;
            var onsuccess = function(res) {
                $scope.createExamRegistrationInProgress = false;
                var data = res.data;
                if(data.error) {
                    var errorval = typeof data.error === 'object' ? 'Error during exam registration' : data.error;
                    toastr.error('Error!', errorval);
                } else {
                    $scope.hideNewRegisterForm();
                    var invoice = data.invoice[0];
                    var invoiceDetails = data.invoiceDetails[0];
                    toastr.success('Success!', 'Successful Exam Registration, Added to invoice ' + invoice.invoiceId);

                    ExamService.getRegisterUserDetails($scope.roleContext.ciprid, $stateParams.scheduleId, $scope.showAllExamRegistration).then(function(res) {
                        $scope.registerUsers = res.data;
                    });
                    ExamService.getScheduleDetails($scope.roleContext.ciprid, $stateParams.scheduleId).then(function(res) {
                        $scope.scheduleDetails = res.data[0];
                        ExamService.getScheduleAvailability($scope.roleContext.ciprid, res.data[0].exmloc_cd, res.data[0].exam_dt, res.data[0].start_tm).then(function(res) {
                            $scope.scheduleAvailability = res.data[0];
                        });
                    });

                    SweetAlert.swal({
                        title: "Register user for another exam",
                        text: "Do you want to register this user for another exam?",
                        type: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#f2784b",
                        confirmButtonText: "Yes!",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function(confirmed) {
                        if(confirmed) {
                            $state.go('register.schedule');
                        } else {
                            if(invoiceDetails.totaldue == 0 && invoiceDetails.itemcount > 0) {
                                var examBlock = blockUI.instances.get('exam-block');
                                examBlock.start();
                                InvoiceService.markaspaid($scope.roleContext.ciprid, invoice.invoiceId)
                                .then(function() {
                                    ExamCountdownService.reset();
                                    examBlock.stop();
                                    $state.go('payments.view', {invoiceId: invoice.invoiceId});
                                }, function() {
                                    examBlock.stop();
                                    $state.go('payments.view', {invoiceId: invoice.invoiceId});
                                });
                            } else
                                $state.go('payments.view', {invoiceId: invoice.invoiceId});
                        }

                    });

                }
            };
            var onerror = function(res) {
                $scope.createExamRegistrationInProgress = false;
                toastr.error('Error!', 'Exam Registration failed.');
            };

            if($scope.scheduleDetails.lifeInsurance.trim() === 'Y' || $scope.scheduleDetails.accidentSickness.trim() === 'Y' || $scope.scheduleDetails.common.trim() === 'Y' || $scope.scheduleDetails.civil.trim() === 'Y' || $scope.scheduleDetails.sf.trim() === 'Y') {
                $http.get('/' + $scope.roleContext.ciprid + '/exams/llqpeligible', {
                    params: {
                        ciprid: registrantciprid,
                        examDate: $scope.scheduleDetails.exam_dt,
                        exmtype: $scope.scheduleDetails.exmtypeid,
                        lifeInsurance: $scope.scheduleDetails.lifeInsurance,
                        accidentSickness: $scope.scheduleDetails.accidentSickness,
                        common: $scope.scheduleDetails.common,
                        civil: $scope.scheduleDetails.civil,
                        sf: $scope.scheduleDetails.sf
                    }
                })
                .then(function(res) {
                    var llqp = res.data[0];
                    if(llqp) {
                        var llqpProviderId = "";
                        $http.get('/' + $scope.roleContext.ciprid + '/exams/llqpProvider', {
                            params: {
                                orgciprid: llqp.orgciprid
                            }
                        })
                        .then(function(res) {
                            llqpProviderId = res.data[0] ? res.data[0].provid : "";
                            if(llqpProviderId != "") {
                                ExamService.save($scope.roleContext.ciprid, $scope.scheduleDetails.id, registrantciprid, $scope.scheduleDetails.exmtypeid, llqpProviderId).then(onsuccess, onerror);
                            } else {
                                $scope.disableRegistration = false;
                                toastr.error('Error!', 'Unable to find LLQP Provider.');
                            }

                        });

                    } else {
                        $scope.disableRegistration = false;
                        $scope.createExamRegistrationInProgress = false;
                        toastr.error('Error!', 'You do not have a valid LLQP certificate for this exam.');
                    }
                });
            } else if($scope.scheduleDetails.general.trim() === 'Y') {
                $http.get('/' + $scope.roleContext.ciprid + '/exams/glqpeligible', {
                    params: {
                        ciprid: registrantciprid,
                        examDate: $scope.scheduleDetails.exam_dt
                    }
                })
                .then(function(res) {
                    var glqp = res.data[0];
                    if(glqp) {
                        var glqpProviderId = "";
                        $http.get('/' + $scope.roleContext.ciprid + '/exams/llqpProvider', {
                            params: {
                                orgciprid: glqp.orgciprid
                            }
                        })
                        .then(function(res) {
                            glqpProviderId = res.data[0] ? res.data[0].provid : "";
                            if(glqpProviderId != "") {
                                ExamService.save($scope.roleContext.ciprid, $scope.scheduleDetails.id, registrantciprid, $scope.scheduleDetails.exmtypeid, glqpProviderId).then(onsuccess, onerror);
                            } else {
                                $scope.disableRegistration = false;
                                toastr.error('Error!', 'Unable to find GLQP Provider.');
                            }

                        });

                    } else {
                        $scope.disableRegistration = false;
                        $scope.createExamRegistrationInProgress = false;
                        toastr.error('Error!', 'You do not have a valid GLQP certificate for this exam.');
                    }
                });

            } else {
                ExamService.save($scope.roleContext.ciprid, $scope.scheduleDetails.id, registrantciprid, $scope.scheduleDetails.exmtypeid, "").then(onsuccess, onerror);
            }

        };

        $scope.reschedule = function(registeruser) {
            ExamRegisterService.setExamLoc({
                "description": registeruser.exmloc_desc,
                "id": registeruser.exmloc_cd.trim()
            });
            ExamRegisterService.setExamLoc({"description": registeruser.exmloc_desc, "id": registeruser.exmloc_cd.trim()});
            ExamRegisterService.setExamType({"description": registeruser.exmtyp_desc, "id": registeruser.exmtyp_cd});
            var onsuccess = function(res) {
                var data = res.data;
                if(data.error) {
                    var errorval = typeof data.error === 'object' ? 'Error during exam reschedule' : data.error;
                    toastr.error('Error!', errorval);
                } else {
                    toastr.success('Success!', 'Exam Reschedule success');
                    ExamService.getRegisterUserDetails($scope.roleContext.ciprid, $stateParams.scheduleId, $scope.showAllExamRegistration).then(function(res) {
                        $scope.registerUsers = res.data;
                    });
                }
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Exam Rescheduled failed.');
            };


            var rescheduleAllowed = $scope.canReschedule(registeruser.exam_dt, registeruser.start_tm_format);


            if(!rescheduleAllowed) {
                swal("Can't reschedule exam within " + $scope.rescheduleHours + " hour(s) to exam")
                return;
            }

            var data = {
                ciprid: $scope.roleContext.ciprid,
                regid: registeruser.regid,
                examType: $scope.scheduleDetails.exmtype,
                examLoc: $scope.scheduleDetails.location,
                isPerson: $scope.isPerson(),
                profile: $scope.profile
            };
            dialogs.create('exams/dialogs/reschedule.html', 'ExamRescheduleDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(data) {
                ExamService.reschedule($scope.roleContext.ciprid, data.regid, data.scheduleid).then(onsuccess, onerror);
            });
        };

        $scope.getAllExamRegistrations = function($event) {
            var checkbox = $event.target;
            $scope.showAllExamRegistration = checkbox.checked;
            ExamService.getRegisterUserDetails($scope.roleContext.ciprid, $scope.scheduleDetails.id, $scope.showAllExamRegistration).then(function(res) {
                $scope.registerUsers = res.data;
            });
        };

        $scope.getExamRegistrations = function() {
            ExamService.getRegisterUserDetails($scope.roleContext.ciprid, $scope.scheduleDetails.id, $scope.showAllExamRegistration).then(function(res) {
                $scope.registerUsers = res.data;
            });
        };

        $scope.viewExam = function(registeruser) {
            $state.go('exams.view', {examId: registeruser.regid, showDialog: false});
        };

        /**
         * Constructs a download reg link
         * @returns {string}
         */
        $scope.downloadRegLink = function(exam) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + exam.regid + "/registrationtopdf?ciprid=" + exam.ciprid;
            var anchorText = 'exam registration.pdf';
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Constructs a download dec link
         * @returns {string}
         */
        $scope.downloadDecLink = function(exam) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + exam.regid + "/declarationtopdf?ciprid=" + exam.ciprid;
            var anchorText = 'declaration.pdf';
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Constructs a download waiver link
         * @returns {string}
         */
        $scope.downloadWaiverLink = function(exam) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/exams/" + exam.regid + "/waivertopdf?ciprid=" + exam.ciprid;
            var anchorText = 'waiver.pdf';
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Add exam to shopping cart.
         */
        $scope.addToShoppingCart = function(registeruser) {
            var e = {regid: registeruser.regid, fee: registeruser.exam_fee};
            InvoiceService.addExamsToShoppingCart($scope.roleContext.ciprid, [e], registeruser.partyid)
            .then(function() {
                toastr.success('Success!', registeruser.regid + ' added to Invoice');
                $scope.getExamRegistrations();
            }, function() {
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };

    }
])
.controller('RefundReasonDialogCtrl', ['$scope', '$uibModalInstance', '$http', '$filter', 'data',
    function($scope, $uibModalInstance, $http, $filter, data) {

        $scope.args = data;

        $scope.reason = undefined;

        // --- Initializers ---

        /**
         * When 'OK' is clicked.
         */
        $scope.refund = function() {
            $uibModalInstance.close($scope.reason);
        };

        /**
         * When 'Cancel' is clicked.
         */
        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };
    }
])
.controller('ExamRescheduleDialogCtrl', ['$scope', '$uibModalInstance', '$filter', 'data', 'dialogs', 'ExamService', 'ExamRegisterService',
    function($scope, $uibModalInstance, $filter, data, dialogs, ExamService, ExamRegisterService) {

        $scope.examTypes = [];
        $scope.examLocs = [];
        $scope.examRegistrationStatuses = [];
        $scope.examSchedules = [];

        $scope.examType = ExamRegisterService.examType;
        $scope.examLoc = ExamRegisterService.examLoc;
        $scope.examStatus = 'HOPN';
        $scope.registrationError = undefined;
        $scope.loading = false;
        $scope.dateFormat = 'MM dd, yyyy';
        $scope.fromDate = ExamRegisterService.fromDate;
        $scope.toDate = ExamRegisterService.toDate;
        $scope.startTime = ExamRegisterService.startTime;
        $scope.toDateEnd = data.isPerson ? moment().add(30, 'days').format('MMMM DD, YYYY') : "";

        // -- Initializers --
        ExamService.getExamTypes(data.ciprid).then(function(res) {
            $scope.examTypes = res.data;
        });

        ExamService.getExamLocations(data.ciprid).then(function(res) {
            $scope.examLocs = res.data;
            if(data.isPerson && data.profile.address.province.toLowerCase() !== 'alberta' || $scope.examLoc.id !== 'OE') {
                _.remove($scope.examLocs, function(loc) {
                    return loc.id.trim() === 'OE'
                });
            }
        });

        $scope.setExamType = function(item, model) {
            $scope.examType = model;
        };

        $scope.searchSchedules = function(examType, examLoc, status, fromDate, toDate, startTime) {
            $scope.loading = true;
            ExamService.lookupSchedules(data.ciprid, examType ? examType.id : '', examLoc.id, status.id, fromDate, toDate, startTime).then(function(res) {
                $scope.loading = false;
                $scope.examSchedules = res.data[0];
                $scope.examSchedules = data.isPerson ? _.reject($scope.examSchedules, function(esch) {
                    return esch.extregallowed == 'N'
                }) : $scope.examSchedules;
                $scope.examSchedulesAvailability = res.data[1];
                if($scope.examSchedules.length === 0) $scope.registrationError = "No Exam schedules found for selected criteria.";
                else $scope.registrationError = undefined;

                var addavailabilitydetails = function(s) {

                    s.availabilitydetails = _.find($scope.examSchedulesAvailability, {
                        exam_dt: s.exam_dt,
                        start_tm: s.start_tm,
                        exmloc_cd: s.exmloc_cd
                    });
                    return s;
                };
                $scope.examSchedules = _.map($scope.examSchedules, addavailabilitydetails);
                ExamRegisterService.setExamSchedules($scope.examSchedules);

            });
        };

        $scope.go = function() {
            $scope.fromDate = $scope.fromDate ? $filter('date')($scope.fromDate, "yyyy-MM-dd") : null;
            $scope.toDate = $scope.toDate ? $filter('date')($scope.toDate, "yyyy-MM-dd") : null;
            $scope.searchSchedules($scope.examType, $scope.examLoc, $scope.examStatus, $scope.fromDate, $scope.toDate, $scope.startTime);
        };


        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.save = function(schedule) {
            if(schedule.availability <= 0) {
                dialogs.error('Unable to add a seating.', ' Location capacity has been reached.', {
                    'size': 'sm'
                });
            } else
                $uibModalInstance.close({regid: data.regid, scheduleid: schedule.id});
        };

        $scope.reset = function() {
            $scope.examLoc = ExamRegisterService.examLoc;
            var d = new Date();
            d.setHours(0);
            d.setMinutes(0);
            $scope.fromDate = d;
            $scope.toDate = moment(new Date().setMonth(d.getMonth() + 1)).format('MMMM DD, YYYY');
            $scope.startTime = d;
            $scope.examSchedules = [];
        };

        $scope.clearResults = function() {
            $scope.examSchedules = [];
        }

        $scope.getLocationDescription = function(id) {
            var loc = _.find($scope.examLocs, function(loc) {
                return loc.id.trim() == id.trim()
            });
            return (loc) ? loc.description : "";
        }

    }
])
.controller('ExamsRegisterSearchCtrl', ['$scope', '$state', 'ExamService',
    function($scope, $state, ExamService) {
        $scope.examResult = undefined;

        $scope.searchExam = function() {
            ExamService.getExamDetails($scope.roleContext.ciprid, $scope.examid, ['basic'])
            .then(function(res) {
                $scope.examResult = res.data;
                if(res.data.exam) {
                    $state.go('exams.view', {examId: $scope.examResult.exam.id, showDialog: false});
                } else {
                    $scope.examSearchError = "No exam registration found for registration# " + $scope.examid;
                }
            });


        };
    }
])
.controller('ExamsProctorReportCtrl', ['$scope', '$state', 'ExamService',
    function($scope, $state, ExamService) {

        ExamService.getExamLocations($scope.roleContext.ciprid).then(function(res) {
            $scope.examLocs = res.data;
        });


        $scope.$watch('examLoc', function() {
            if($scope.examLoc) {
                $scope.scheduleProctor = undefined;
                $scope.registerUsers = undefined;
                $scope.examTypesCount = undefined;
                $scope.getSchedulesProctor($scope.examLoc);

            }
        });

        $scope.$watch('scheduleProctor', function() {
            if($scope.examLoc && $scope.scheduleProctor) {
                $scope.getRegisterUsers($scope.scheduleProctor, $scope.examLoc);
                $scope.getExamTypesCount($scope.scheduleProctor, $scope.examLoc);
            }
        });

        $scope.getRegisterUsers = function(scheduleProctor, examLoc) {
            $scope.loading = true;
            ExamService.getRegisterUsersProctor($scope.roleContext.ciprid, examLoc.id, scheduleProctor.examdate, scheduleProctor.examtime).then(function(res) {
                $scope.loading = false;
                $scope.registerUsers = res.data;
            });
        };

        $scope.getExamTypesCount = function(scheduleProctor, examLoc) {
            ExamService.getExamTypesCount($scope.roleContext.ciprid, examLoc.id, scheduleProctor.examdate, scheduleProctor.examtime).then(function(res) {
                $scope.examTypesCount = res.data;
            });
        };

        $scope.getSchedulesProctor = function(examLoc) {
            ExamService.getSchedulesProctor($scope.roleContext.ciprid, examLoc.id).then(function(res) {
                $scope.schedulesProctor = res.data;
            });
        };


    }
])
.service('ExamCountdownService', [
    function() {

        var counter = undefined;

        this.startCountdown = function(time, onStop) {
            counter = $('#exam-counter').FlipClock({
                clockFace: 'MinuteCounter',
                autoStart: false,
                callbacks: {
                    stop: onStop
                }
            });
            counter.setTime(time);
            counter.setCountdown(true);
            counter.start();
        };

        this.stopCountdown = function() {
            counter && counter.stop();
        };

        this.reset = function() {
            if(counter) {
                counter = undefined;
                $('#exam-counter').unbind();
                $('#exam-counter').empty();
            }
        };

        this.getCounter = function() {
            return counter;
        };

        this.isRunning = function() {
            return counter ? true : false;
        };

    }
])
.service('ExamRegisterService', [
    function() {
        var service = {};

        service.examType = undefined;
        service.examLoc = undefined;
        service.examStatus = undefined;
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        service.fromDate = moment(d).format('MMMM DD, YYYY');
        service.toDate = moment(new Date().setMonth(d.getMonth() + 1)).format('MMMM DD, YYYY');
        service.startTime = d;
        service.examSchedules = [];

        service.setExamType = function(examType) {
            this.examType = examType
        };

        service.setExamLoc = function(examLoc) {
            this.examLoc = examLoc
        };

        service.setExamStatus = function(examStatus) {
            this.examStatus = examStatus
        };

        service.setFromDate = function(fromDate) {
            this.fromDate = fromDate
        };

        service.setToDate = function(toDate) {
            this.toDate = toDate
        };

        service.setStartTime = function(startTime) {
            this.startTime = startTime;
        };


        service.setExamSchedules = function(examSchedules) {
            this.examSchedules = examSchedules
        };

        service.removeExamSchedule = function(schedule) {
            this.examSchedules = _.reject(this.examSchedules, {'id': schedule.id});
        };

        service.addExamSchedule = function(schedule) {
            this.examSchedules.push(schedule);
        };

        return service;

    }
])
.service('ExamService', ['$http',
    function($http) {

        this.getExamResults = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/results');
        };

        this.getExamDetails = function(ciprId, examId, details) {
            return $http.get('/' + ciprId + '/exams/' + examId + '?details=' + details);
        };

        this.getScheduledExams = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/scheduled');
        };

        this.getReScheduledHours = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/rescheduleHours');
        };

        this.getExamTypes = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/types');
        };

        this.getExamLocations = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/locations');
        };

        this.getGeneralProviders = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/generalproviders');
        };

        this.similarRegExists = function(ciprId, exmTypeId) {
            return $http.get('/' + ciprId + '/exams/similarregistrationexists', {
                params: {
                    exmtype: exmTypeId
                }
            });
        };

        this.getRegistrationStatuses = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/registrationstatuses');
        };


        this.getRegisterUserDetails = function(ciprId, scheduleId, all) {
            return $http.get('/' + ciprId + '/exams/registerusers', {
                params: {
                    scheduleId: scheduleId,
                    all: all
                }
            });
        };

        this.getScheduleDetails = function(ciprId, scheduleId) {
            return $http.get('/' + ciprId + '/exams/schedule/' + scheduleId);
        };

        this.getSchedules = function(ciprId, examTypeId, examLocId) {

            return $http.get('/' + ciprId + '/exams/registrationschedules', {
                params: {
                    location: examLocId,
                    exmtype: examTypeId
                }
            });
        };

        this.getScheduleAvailability = function(ciprId, examLocId, examdate, examtime) {
            return $http.get('/' + ciprId + '/exams/scheduleavailability', {
                params: {
                    location: examLocId,
                    examdate: examdate,
                    examtime: examtime
                }
            });
        };

        this.getSchedulesProctor = function(ciprId, examLocId) {

            return $http.get('/' + ciprId + '/exams/proctorschedules', {
                params: {
                    location: examLocId
                }
            });
        };

        this.getRegisterUsersProctor = function(ciprId, examLocId, examdate, examtime) {
            return $http.get('/' + ciprId + '/exams/registerusersproctor', {
                params: {
                    location: examLocId,
                    examdate: examdate,
                    examtime: examtime
                }
            });
        };

        this.getExamTypesCount = function(ciprId, examLocId, examdate, examtime) {
            return $http.get('/' + ciprId + '/exams/examtypescount', {
                params: {
                    location: examLocId,
                    examdate: examdate,
                    examtime: examtime
                }
            });
        };


        this.lookupSchedules = function(ciprId, examTypeId, examLocId, statusId, fromDate, toDate, startTime) {

            return $http.get('/' + ciprId + '/exams/lookupschedules', {
                params: {
                    location: examLocId,
                    exmtype: examTypeId,
                    status: statusId,
                    fromDate: fromDate,
                    toDate: toDate,
                    startTime: startTime
                }
            });
        };


        this.getProviders = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/providers');
        };

        this.getExamVersions = function(ciprId, scheduleId) {
            return $http.get('/' + ciprId + '/exams/examversions', {
                params: {
                    scheduleId: scheduleId
                }
            });
        };

        this.getAllExamVersions = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/allexamversions');
        };


        this.getExamStatuses = function(ciprId) {
            return $http.get('/' + ciprId + '/exams/examstatuses');
        };

        this.update = function(ciprId, regid, provid) {
            return $http.post('/' + ciprId + '/exams/' + regid, {
                data: {
                    provid: provid
                }
            });

        };

        this.score = function(ciprId, regid, score, versionid) {
            return $http.post('/' + ciprId + '/exams/' + regid + '/score', {
                data: {
                    score: score,
                    versionid: versionid
                }
            });

        };

        this.notWritten = function(ciprId, regid) {
            return $http.post('/' + ciprId + '/exams/' + regid + '/notwritten');

        };

        this.remove = function(ciprId, regid) {
            return $http.delete('/' + ciprId + '/exams/' + regid);
        };

        this.abandon = function(ciprId, regid) {
            return $http.post('/' + ciprId + '/exams/' + regid + '/abandon');
        };

        this.refund = function(ciprId, regid, reason) {
            return $http.post('/' + ciprId + '/exams/' + regid + '/refund', {
                data: {
                    reason: reason
                }
            });
        };

        this.removeSchedule = function(ciprId, scheduleId) {
            return $http.delete('/' + ciprId + '/exams/schedule/' + scheduleId)
        };

        this.save = function(ciprId, scheduleId, registrantCiprId, exmtypeid, llqpProviderId) {
            return $http.put('/' + ciprId + '/exams/save', {
                data: {
                    scheduleId: scheduleId,
                    registrantCiprId: registrantCiprId,
                    exmTypeId: exmtypeid,
                    llqpProviderId: llqpProviderId
                }
            })
        };

        this.updateSchedule = function(ciprId, scheduleId, examdate, examtime, examstatus, extregallowed) {
            return $http.post('/' + ciprId + '/exams/schedule/' + scheduleId, {
                data: {
                    examdate: examdate,
                    examtime: examtime,
                    examstatus: examstatus,
                    extregallowed: extregallowed
                }
            })

        };

        this.reschedule = function(ciprId, regId, scheduleId) {
            return $http.post('/' + ciprId + '/exams/reschedule', {
                data: {
                    scheduleId: scheduleId,
                    regId: regId
                }
            })
        };


        this.createSchedule = function(ciprId, examTypeId, examLocId, statusId, fromDate, toDate, startTime, single, extregallowed, dayOfWeek) {
            return $http.put('/' + ciprId + '/exams/schedule/', {
                data: {
                    location: examLocId,
                    exmtype: examTypeId,
                    status: statusId,
                    fromDate: fromDate,
                    toDate: toDate,
                    startTime: startTime,
                    single: single,
                    extregallowed: extregallowed,
                    dayOfWeek: dayOfWeek
                }
            })

        };

        this.SubmitQuesAndDecls = function(ciprid, exam, regId) {
            return $http({
                url: '/' + ciprid + '/exams/' + regId + '/quesanddecl',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {exam: exam}
            });
        };

        this.SubmitCondAndNote = function(ciprid, note, specialCondition, regId) {
            return $http.post('/' + ciprid + '/exams/' + regId + '/condandnote', {
                data: {
                    note: note,
                    specialCondition: specialCondition
                }
            });

        };

        this.getExamAllow = function(ciprid, partyId) {
            return $http.get('/' + ciprid + '/exams/examAllow', {
                params: {
                    partyId: partyId
                }
            });
        };

    }
]);
