var appointee = angular.module('aic.appointee', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
appointee.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;


    }
])
.controller('AppointeeAddEditDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data', 'AppointeeService', 'PartyService',
    function($scope, $http, $uibModalInstance, $filter, data, AppointeeService, PartyService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.activeDateEnd = moment().format('MMMM DD, YYYY');
        $scope.appointee = data.appointee || {};
        $scope.activeOrgins = data.activeOrgins;
        $scope.appointee.activeDate = (data.appointee && data.appointee.active_dt) ? moment(data.appointee.active_dt, 'YYYY-MM-DD').format('MMMM DD, YYYY') :  moment().format('MMMM DD, YYYY');
        $scope.appointee.inactiveDate = (data.appointee && data.appointee.inactive_dt) ? moment(data.appointee.inactive_dt, 'YYYY-MM-DD').format('MMMM DD, YYYY') :  undefined;
        $scope.appointee.party = data.appointee && data.appointee.stk_seq ? {
            partyid: data.appointee.stk_seq,
            party_nm: data.appointee.stk_name
        } : {};
        $scope.setActiveDate = function(selectedDate) {
            $scope.appointee.activeDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.setInActiveDate = function(selectedDate) {
            $scope.appointee.inactiveDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.clearInActiveDate = function(){
            $scope.appointee.inactiveDate = undefined;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.appointee);
        };

        $scope.getParties = function(phrase, limit) {
            return PartyService.getParties(phrase, limit);
        };

        $scope.checkParty = function() {
            if(!$scope.appointee.party.partyid) {
                $scope.appointee.party = "";
            }
        };
    }
])
.service('AppointeeService', ['$http', '$q',
    function($http, $q) {
        this.getAppointees = function(orgId) {
            return $http.get('/staff/appointee/', {
                params: {
                    orgId: orgId
                }
            })
        };

        this.getAppointeesByStk = function(stkId) {
            return $http.get('/staff/appointee/byStk', {
                params: {
                    stkId: stkId
                }
            })
        };

        this.addAppointee = function(appointee) {
            return $http.put('/staff/appointee/', {
                data: {
                    appointee: appointee
                }
            });
        };

        this.updateAppointee = function(appointee) {
            return $http.post('/staff/appointee/' + appointee.stkath_seq, {
                data: {
                    appointee: appointee
                }
            });
        };

    }
]);