var transfer = angular.module('aic.transfer', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

/**
 * Routing and config
 */
transfer.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('transfer', {
            parent: 'workspace',
            abstract: true,
            url: '/transfer',
            templateUrl: 'staff/transfer/transfer.html'
        })
        .state('transfer.search', {
            url: '/search',
            params: {
                cache: true
            },
            templateUrl: 'staff/transfer/search.html',
            controller: 'TransferSearchCtrl'
        });
    }
])
.controller('TransferAddDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'SweetAlert', 'data', 'TransferService', 'PartyService',
    function($scope, $http, $uibModalInstance, $filter, SweetAlert, data, TransferService, PartyService) {
        $scope.transfer = {transferType: 'XFER'};
        var ciprid = data.ciprid;
        $scope.validAmount = true;
        $http.get('/' + ciprid + '/account/transfer/types', {params: {filter: 'source'}}, {cache: true})
        .then(function(res) {
            $scope.srcAccountTypes = res.data;
        });
        $http.get('/' + ciprid + '/account/transfer/types', {params: {filter: 'destination'}}, {cache: true})
        .then(function(res) {
            $scope.destAccountTypes = res.data;
        });

        TransferService.getTransferTypes()
        .then(function(res) {
            $scope.transferTypes = res.data;
        });


        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.getAccountTypeDescription = function(id, accountTypes) {
            let aTyp = _.find(accountTypes, function(accountType) {
                return accountType.id.trim() === id.trim()
            });
            return (aTyp) ? aTyp.description : "";
        };

        $scope.yes = function() {
            let fromAccountTypeDesc = $scope.getAccountTypeDescription($scope.transfer.fromAccount,$scope.srcAccountTypes);
            let toAccountTypeDesc = $scope.getAccountTypeDescription($scope.transfer.toAccount,$scope.destAccountTypes);
            let msg = "You are about to make a fee transfer that will move $" + $scope.transfer.amount+ " from " + $scope.transfer.fromparty.name + " ("+fromAccountTypeDesc+ ")  to "+ $scope.transfer.toparty.name+ "("+toAccountTypeDesc +"). Would you like to proceed?";
            SweetAlert.swal({
                html: 'true',
                title: "Fee Transfer",
                text: msg,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $uibModalInstance.close($scope.transfer);
                }
            });


        };

        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getPartiesBoth(phrase, limit);
        };

        $scope.getAccountBalance = function() {
            if(!$scope.transfer.fromAccount || _.isEmpty($scope.transfer.fromparty) || !$scope.transfer.fromparty.partyid)
                return $scope.fromAccountBalance = undefined;

            $http.get('/' + ciprid + '/account/balancebyaccntandparty',
            {
                params: {
                    accntCD: $scope.transfer.fromAccount,
                    stkId: $scope.transfer.fromparty.partyType === 'PERSON' ? $scope.transfer.fromparty.partyid : null,
                    orgId: $scope.transfer.fromparty.partyType === 'ORG' ? $scope.transfer.fromparty.partyid : null
                }
            })
            .then(function(res) {
                if($scope.transfer.fromAccount === 'LIC_FEE_AR') {
                    $scope.fromAccountBalance = res.data[0];
                    if($scope.fromAccountBalance)
                        $scope.fromAccountBalance.balance = -($scope.fromAccountBalance.balance);
                }else
                        $scope.fromAccountBalance = res.data[0];
                $scope.isValidTransfer();
            });
        };

        $scope.isValidTransfer = function() {

            if($scope.fromAccountBalance.balance && $scope.transfer.amount) {
                let validAmount = parseFloat($scope.fromAccountBalance.balance) >= $scope.transfer.amount;
                return validAmount;
            } else {
                return false;
            }

        };
        $scope.isValidAmount = function() {

            if($scope.fromAccountBalance.balance && $scope.transfer.amount) {
                $scope.validAmount = parseFloat($scope.fromAccountBalance.balance) >= $scope.transfer.amount;
            } else {
                $scope.validAmount = false;

            }

        };

        $scope.checkFromParty = function() {
            if(!$scope.transfer.fromparty.partyid) {
                $scope.transfer.fromparty = "";
            }
        };
        $scope.checkToParty = function() {
            if(!$scope.transfer.toparty.partyid) {
                $scope.transfer.toparty = "";
            }
        };

    }
])
.controller('TransferSearchCtrl', ['$scope', '$state', '$http', '$stateParams', '$filter', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'toastr', 'TransferService', 'PartyService',
    function($scope, $state, $http, $stateParams, $filter, dialogs, DTOptionsBuilder, blockUI, toastr, TransferService, PartyService) {
        $scope.search = false;

        var transferSearchBlock = blockUI.instances.get('transfer-search-result-block');
        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc'])
        .withOption('language', {
            zeroRecords: 'No Transfers found for the search criteria'
        });

        var initForm = function() {
            $scope.transfers = undefined;
            $scope.transfer = {transferType: 'XFER'};
        };
        initForm();

        $http.get('/' + $scope.roleContext.ciprid + '/account/transfer/types', {cache: true})
        .then(function(res) {
            $scope.accountTypes = res.data;
        });

        TransferService.getTransferTypes()
        .then(function(res) {
            $scope.transferTypes = res.data;
        });

        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getPartiesBoth(phrase, limit);
        };


        $scope.searchTransfer = function() {
            $scope.transfers = undefined;
            $scope.searchTransferRequestInProgress = true;
            TransferService.search($scope.transfer)
            .then(function(res) {
                $scope.searchTransferRequestInProgress = false;
                $scope.transfers = res.data;
            });
        };

        $scope.getAccountDescription = function(accntCD) {
            var account = _.find($scope.accountTypes, function(accnt) {
                return accnt.id.trim() === accntCD.trim()
            });
            return (account) ? account.description : "";
        };

        $scope.clear = function() {
            initForm();
        };

        /**
         * Presents an add dialog.
         */
        $scope.createTransfer = function() {
            $scope.creationErrors = undefined;
            var data = {transfer: undefined, ciprid: $scope.roleContext.ciprid};
            dialogs.create('staff/transfer/dialogs/add.html', 'TransferAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(transfer) {

                var onsuccess = function(res) {
                    transferSearchBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Transfer cannot be created at this time.');
                    }
                    var data = res.data;
                    toastr.success('Success!', 'Transfer has been created.');
                    transfer = data[0];
                    if($scope.transfers == undefined)
                        $scope.transfers = [transfer];
                    else
                        $scope.transfers.unshift(transfer);
                };
                var onerror = function(res) {
                    transferSearchBlock.stop();
                    toastr.error('Error!', 'Unable to create transfer at this time. Please try later');
                };

                transferSearchBlock.start("Saving Transfer...");
                TransferService.createTransfer(transfer).then(onsuccess, onerror);
            });
        };


    }
])
.service('TransferService', ['$http', '$q',
    function($http, $q) {
        this.getTransferTypes = function() {
            return $http.get('/staff/transfer/types', {cache: true});
        };

        this.createTransfer = function(transfer) {
            return $http.put('/staff/transfer', {
                data: {
                    transfer: transfer
                }
            });
        };

        this.search = function(transfer) {
            return $http.get('/staff/transfer/search/', {
                params: {
                    id: transfer.id,
                    description: transfer.description,
                    amount: transfer.amount,
                    transferType: transfer.transferType,
                    toAccount: transfer.toAccount,
                    toparty: !_.isEmpty(transfer.toparty) ? transfer.toparty.partyid : null,
                    fromAccount: transfer.fromAccount,
                    fromparty: !_.isEmpty(transfer.fromparty) ? transfer.fromparty.partyid : null
                }
            });
        };

    }
]);
