var orgs = angular.module('aic.orgs', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'ui.bootstrap.tabs',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.subscribe',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

orgs.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {
        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('orgs', {
            parent: 'workspace',
            abstract: true,
            url: '/orgs',
            templateUrl: 'orgs/orgs.html'
        })
        .state('orgs.open', {
            url: '/{orgId:[0-9]+}',
            params: {'tab': ''},
            abstract: true,
            reloadOnSearch: false,
            resolve: {
                promisedOrg: ['$stateParams', 'AuthService', 'OrgsResolverService',
                    function($stateParams, AuthService, OrgsResolverService) {
                        var ciprId = AuthService.getRoleContext().ciprid;
                        var orgId = $stateParams.orgId;
                        var resolved = OrgsResolverService.get(orgId);
                        return resolved;
                    }
                ]
            },
            controller: 'OrgsOpenCtrl',
            templateUrl: 'orgs/orgs.edit.html'
        })
        .state('orgs.create', {
            url: '/create',
            templateUrl: 'orgs/orgs.create.html',
            controller: 'OrgsCreateCtrl'
        })
        .state('orgs.link', {
            url: '/link',
            templateUrl: 'orgs/orgs.link.html',
            controller: 'OrgsCtrl'
        })
        .state('orgs.search', {
            url: '/search',
            params: {
                cache: true
            },
            templateUrl: 'orgs/search.html',
            controller: 'OrgsSearchCtrl'
        })
        .state('orgs.eandoview', {
            url: '/eando/view?policyno&inscategory&carrierpartyid&insurancecategorydescr&orgid',
            templateUrl: 'orgs/eando/eando.view.html',
            controller: 'OrgsEandOViewCtrl'
        });


        $stateProvider
        .state('orgs.open.account', {
            url: '/account',
            templateUrl: 'staff/account/account.view.html',
            controller: 'OrgsAccountCtrl'
        });

        $stateProvider
        .state('orgs.open.invoice', {
            url: '/invoice',
            templateUrl: 'staff/invoices/invoices.list.html',
            controller: 'OrgsInvoicesCtrl'
        });
        $stateProvider
        .state('orgs.open.payable', {
            url: '/payable',
            templateUrl: 'orgs/payables/payables.list.html',
            controller: 'OrgsPayablesCtrl'
        });
        $stateProvider
        .state('orgs.open.eando', {
            url: '/eando',
            templateUrl: 'orgs/eando/eando.list.html',
            controller: 'OrgsEandOCtrl'
        });
        $stateProvider
        .state('orgs.open.license', {
            url: '/license',
            templateUrl: 'orgs/licenses/licenses.list.html',
            controller: 'OrgsLicenseCtrl'
        });
        $stateProvider
        .state('orgs.open.application', {
            url: '/application',
            templateUrl: 'orgs/applications/application.list.html',
            controller: 'OrgsApplicationsCtrl'
        });
        $stateProvider
        .state('orgs.open.renewal', {
            url: '/renewal',
            templateUrl: 'orgs/renewals/renewals.list.html',
            controller: 'OrgsRenewalsCtrl'
        });
        $stateProvider
        .state('orgs.open.note', {
            url: '/notes',
            templateUrl: 'staff/notes/notes.list.html',
            controller: 'OrgsNotesCtrl'
        });
        $stateProvider
        .state('orgs.open.address', {
            url: '/address',
            templateUrl: 'orgs/address/address.list.html',
            controller: 'OrgsAddressCtrl'
        });

        $stateProvider
        .state('orgs.open.documents', {
            url: '/documents',
            templateUrl: 'documents/documents.list.table.html',
            controller: 'OrgsDocumentCtrl'
        });

        $stateProvider
        .state('orgs.open.event', {
            url: '/event',
            templateUrl: 'staff/event/event.list.html',
            controller: 'OrgsEventCtrl'
        });

        $stateProvider
        .state('orgs.open.refund', {
            url: '/refund',
            templateUrl: 'staff/refund/refund.list.html',
            controller: 'OrgsRefundCtrl'
        });
        $stateProvider
        .state('orgs.open.nsf', {
            url: '/nsf',
            templateUrl: 'staff/nsf/nsf.list.html',
            controller: 'OrgsNsfCtrl'
        });
        $stateProvider
        .state('orgs.open.wo', {
            url: '/wo',
            templateUrl: 'staff/wo/wo.list.html',
            controller: 'OrgsWoCtrl'
        });
        $stateProvider
        .state('orgs.open.orgins', {
            url: '/orgins',
            templateUrl: 'staff/orgins/orgins.list.html',
            controller: 'OrgsOrginsCtrl'
        });
        $stateProvider
        .state('orgs.open.appointee', {
            url: '/appointee',
            templateUrl: 'staff/appointee/appointee.list.html',
            controller: 'OrgsAppointeeCtrl'
        });
        $stateProvider
        .state('orgs.open.assessment', {
            url: '/assessment',
            templateUrl: 'assessmentinvoices/assessment.invoice.list.html',
            controller: 'OrgsAssessmentCtrl'
        });


    }
])


.controller('OrgsCtrl', ['$scope', '$state', '$stateParams', '$http', 'toastr',
    function($scope, $state, $stateParams, $http, toastr) {

        $scope.selectedOrg = undefined;
        $scope.selectedAdmin = undefined;
        $scope.adminciprid = undefined;
        $scope.now = new Date();
        $scope.alerts = [];
        $scope.buttonDisabled = false;
        $scope.orgErrors = ['required'];
        $scope.selectedAdminError = undefined;
        $scope.userSearchEndPoint = "";

        $http.get('/staff/orgs/userSearchEndPoint', {cache: true})
        .then(function(res) {
            $scope.userSearchEndPoint = res.data.userSearchEndPoint;
        });

        addAlert = function(msg) {
            $scope.alerts.push({type: 'danger', msg: msg});
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };

        $scope.setOrg = function(item, model, label) {
            $scope.selectedOrg = model;
            $scope.orgErrors = _.without($scope.orgErrors, 'required');
            $scope.alerts = [];
        };

        $scope.getOrgs = function(val) {
            return $http.get('/staff/orgs/lookup', {
                params: {
                    searchcriteria: val
                }
            }).then(function(res) {
                return res.data;
            });
        };


        $scope.checkAdmin = function() {
            $scope.selectedAdminError = undefined;
            $scope.selectedAdmin = undefined;
            return $http.get('/lookup/user/byciprid', {
                params: {
                    ciprid: $scope.adminciprid
                }
            }).then(function(res) {
                if(res.data.profile.errors)
                    $scope.selectedAdminError = 'invalid';
                else
                    $scope.selectedAdmin = res.data.profile;

            });
        };


        $scope.reset = function() {
            $scope.selectedOrg = undefined;
            $scope.selectedAdmin = undefined;
            $scope.adminciprid = undefined;
            $scope.alerts = [];
            $scope.orgErrors = ['required'];
            $scope.selectedAdminError = undefined;
            $scope.orgsLinkForm.$setPristine();
        };

        $scope.linkOrg = function() {
            $scope.buttonDisabled = true;
            if($scope.orgErrors.length > 0) {
                addAlert('Please enter an organization.');
                return;
            }

            $http.put('/staff/orgs', {
                data: {
                    orgId: $scope.selectedOrg.partyid,
                    adminId: $scope.selectedAdmin ? $scope.adminciprid : ''
                }
            })
            .then(function(res) {
                $scope.buttonDisabled = false;
                if(res.data.status == 'success') {
                    toastr.success('Success!', 'Organization has been successfully linked orgciprid: ' + res.data.orgciprid);
                    $scope.reset();
                    $state.go('orgs.link');
                } else {
                    addAlert("Unable to link organization to cipr");
                }
            });

        };

    }

])
.controller('OrgsSearchCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'blockUI', 'toastr', 'OrgsService',
    function($scope, $state, $stateParams, DTOptionsBuilder, blockUI, toastr, OrgsService) {

        $scope.orgs = OrgsService.orgs;
        $scope.searchStr = OrgsService.searchStr;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true);

        $scope.searchOrg = function() {
            $scope.loading = true;
            OrgsService.setPartySearchStr($scope.searchStr);
            OrgsService.search($scope.searchStr)
            .then(function(res) {
                $scope.loading = false;
                $scope.orgs = res.data;
                OrgsService.setOrgs(res.data);
            });


        };

        $scope.viewOrganization = function(org) {
            //  if(org.org_type === "Insurance Company")
            //     $state.go('organizations.specific.profile.view', {organizationId: org.partyId});
            // else
            $state.go('orgs.open.account', {orgId: org.partyId});
        };

    }
])
.controller('OrgsCreateCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', 'DTOptionsBuilder', 'blockUI', 'toastr', 'OrgsService', 'OrganizationsService',
    function($scope, $state, $stateParams, $http, $filter, DTOptionsBuilder, blockUI, toastr, OrgsService, OrganizationsService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.activeDate = moment().format('MMMM DD, YYYY');
        $scope.orgTypes = [];
        $scope.checkAdminLoading = false;

        var initForm = function() {
            $scope.email = undefined;
            $scope.legalName = undefined;
            $scope.tradeName = undefined;
            $scope.orgType = {};
            $scope.comments = undefined;
            $scope.assignSponsorNumber = undefined;
            $scope.adminciprid = undefined;
            $scope.selectedAdminError = undefined;
            $scope.selectedAdmin = undefined;
            $scope.corpDocs = 'N';
        };

        initForm();

        $scope.userSearchEndPoint = "";

        $http.get('/staff/orgs/userSearchEndPoint', {cache: true})
        .then(function(res) {
            $scope.userSearchEndPoint = res.data.userSearchEndPoint;
        });

        /**
         * Fetch orgTypes and pre populate $scope.orgTypes
         */
        OrgsService.getTypes()
        .then(function(res) {
            $scope.orgTypes = res.data;
        });


        $scope.createOrganization = function() {
            var onsuccess = function(res) {
                $scope.createRequestInProgress = false;
                if(res.data.org[0]) {
                    if(res.data.errors.length > 0) toastr.error('Error!', _.map(res.data.errors, 'msg').join(','));
                    toastr.success('Success!', 'Organization successfully created.');
                    $state.go('orgs.open.account', {orgId: res.data.org[0].partyId});
                } else
                    toastr.error('Error!', 'Organization cannot be created at this time.');
            };
            var onerror = function() {
                $scope.createRequestInProgress = false;
                toastr.error('Error!', 'Organization cannot be created at this time.');
            };
            $scope.createRequestInProgress = true;
            OrgsService.create($scope.legalName, $scope.tradeName, $scope.orgType.code_value, $scope.assignSponsorNumber, $scope.corpDocs, $scope.email, $scope.comments, $scope.adminciprid, $scope.activeDate)
            .then(onsuccess, onerror);
        };

        $scope.clear = function() {
            initForm();
        };

        $scope.checkAdmin = function() {
            if(!$scope.adminciprid) return;
            $scope.checkAdminLoading = true;
            $scope.selectedAdminError = undefined;
            $scope.selectedAdmin = undefined;
            return $http.get('/lookup/user/byciprid', {
                params: {
                    ciprid: $scope.adminciprid
                }
            }).then(function(res) {
                $scope.checkAdminLoading = false;
                if(!res.data.profile || res.data.profile.errors)
                    $scope.selectedAdminError = 'invalid';
                else
                    $scope.selectedAdmin = res.data.profile;


            });
        };

        $scope.checkName = function() {
            $scope.legalTradeExists = false;
            OrganizationsService.getOrgByLegalAndTradeName($scope.roleContext.ciprid, $scope.legalName, $scope.tradeName)
            .then(function(res) {
                $scope.legalTradeExists = !_.isEmpty(res.data);
            });

        };

        $scope.viewOrganization = function(org) {
            $state.go('orgs.open.account', {orgId: org.partyId});
        };

        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setActiveDate = function(selectedDate) {
            $scope.activeDate = $filter('date')(selectedDate, "MM dd, yyyy");
        };

    }
])
.controller('OrgsOpenCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', 'promisedOrg', 'dialogs', 'blockUI', 'toastr', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, promisedOrg, dialogs, blockUI, toastr, OrgsService) {
        $scope.org = promisedOrg;
        $scope.profileEditing = false;
        $scope.updateRequestInProgress = false;
        $scope.changeAgencyNameRequestInProgress = false;
        $scope.orgType = {};
        $scope.orgStatus = {};


        $scope.agencyTypes = [{id: "CORPORATION", "description": "Corporation"},
            {"id": "PARTNERSHIP", "description": "Partnership"},
            {"id": "SOLE_PROP", "description": "Sole Proprietorship"}];

        var orgsBlock = blockUI.instances.get('orgs-block');


        $scope.goPrevious = function() {
            $previousState.go();
        };


        $scope.editProfile = function() {
            $scope.orgType = _.find($scope.org.types, function(orgType) {
                return orgType.code_value.trim() === $scope.org.org_cd.trim();
            });
            $scope.orgStatus = _.find($scope.org.statuses, function(orgStatus) {
                return orgStatus.id.trim() === $scope.org.scnrio_cd.trim();
            });
            $scope.org.partyInfo.regType = _.find($scope.agencyTypes, {id: $scope.org.partyInfo.corp_reg_type});
            $scope.org.orgType = $scope.orgType;
            $scope.org.orgStatus = $scope.orgStatus;
            $scope.profileEditing = true;
        };

        $scope.viewProfile = function() {
            $scope.profileEditing = false;
        };

        $scope.setOrgType = function(org, orgType) {
            org.orgType = orgType;
        };

        $scope.setOrgStatus = function(org, orgStatus) {
            org.orgStatus = orgStatus;
        };

        $scope.updateOrganization = function() {
            var onsuccess = function(res) {
                if(res.data.error) {
                    toastr.error('Error!', 'Organization cannot be updated at this time.');
                    $scope.updateRequestInProgress = false;
                } else {
                    toastr.success('Success!', 'Organization successfully updated.');
                    $http.get('/staff/orgs/' + $scope.org.orgId)
                    .then(function(res) {
                        var org = res.data.org;
                        org.accounts = res.data.accounts;
                        org.types = res.data.types;
                        org.statuses = res.data.statuses;
                        org.partyInfo = res.data.partyInfo[0];
                        org.partyInfo.riaTypes = res.data.riaTypes;
                        org.partyInfo.certificateTypes = res.data.certificateTypes;
                        $scope.org = org;
                        $scope.updateRequestInProgress = false;
                        $scope.profileEditing = false;
                        $scope.selectedRIATypeIds = _.map(org.partyInfo.certificateTypes, 'riaType');
                    });
                }

            };
            var onerror = function() {
                $scope.updateRequestInProgress = false;
                toastr.error('Error!', 'Organization cannot be updated at this time.');
            };
            $scope.updateRequestInProgress = true;
            OrgsService.update($scope.org)
            .then(onsuccess, onerror);
        };


        /**
         * Presents an name change dialog.
         * @param org
         */
        $scope.changeAgencyName = function() {
            $scope.changeAgencyNameRequestInProgress = true;
            OrgsService.getAgencyActiveLicenseCount($scope.org.orgId)
            .then(function(res) {
                $scope.org.activeLicenseCount = res.data[0].licenseCount;
                dialogs.create('orgs/dialogs/namechange.html', 'OrgsNameChangeDialogCtrl', $scope.org, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(org) {

                    var onsuccess = function(res) {
                        orgsBlock.stop();
                        $scope.changeAgencyNameRequestInProgress = false;
                        if(res.data.error) {
                            toastr.error('Error!', 'Unable to update organization name. Please try again later');
                        } else {
                            toastr.success('Success!', 'Organization name successfully updated.');
                            $scope.org.legal_nm = org.legal_nm;
                            $scope.org.trade_nm = org.trade_nm;
                        }

                    };
                    var onerror = function(res) {
                        orgsBlock.stop();
                        $scope.changeAgencyNameRequestInProgress = false;
                        toastr.error('Error!', 'Unable to update organization name. Please try again later');
                    };
                    orgsBlock.start('Processing agency name change. This could take upto a minute to finish.');
                    OrgsService.updateAgencyName($scope.org.orgId, org.legal_nm, org.trade_nm, org.fee, org.accountType.accnt_cd).then(onsuccess, onerror);
                }, function() {
                    $scope.changeAgencyNameRequestInProgress = false;
                });
            });

        };

        $scope.isInsurer = function() {
            return _.includes(['D', 'E', 'I', 'R'], $scope.org.org_cd.trim());
        };

        $scope.isAgency = function() {
            return _.includes(['A', 'S'], $scope.org.org_cd.trim());
        };

        /**
         * Presents an sponsor change dialog for insurance company.
         */
        $scope.changeInsSponsorCompany = function() {
            $scope.changeSpnforInsCompanyRequestInProgress = true;
            OrgsService.getOrgIns($scope.org.orgId)
            .then(function(res) {
                $scope.org.orgins = res.data;
                dialogs.create('orgs/dialogs/changeInsSponsor.html', 'changeInsSponsorDialogCtrl', [$scope.org, $scope.roleContext.ciprid], {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(org) {
                    $scope.changeSpnforInsCompanyRequestInProgress = false;
                }, function() {
                    $scope.changeSpnforInsCompanyRequestInProgress = false;
                });

            });

        };

        /**
         * Presents an sponsor change dialog for agency.
         */
        $scope.changeAgySponsorCompany = function() {
            $scope.changeSpnforAgyRequestInProgress = true;
            dialogs.create('orgs/dialogs/changeAgySponsor.html', 'changeAgySponsorDialogCtrl', [$scope.org, $scope.roleContext.ciprid], {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(org) {
                $scope.changeSpnforAgyRequestInProgress = false;
            }, function() {
                $scope.changeSpnforAgyRequestInProgress = false;
            });

        };

        $scope.selectedRIATypeIds = _.map($scope.org.partyInfo.certificateTypes, 'riaType');
        $scope.isSelectedRIAType = function(typeId) {
            return $scope.selectedRIATypeIds.indexOf(typeId) >= 0;
        };

        $scope.updateRIATypes = function($event, riaType) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                OrgsService.addRIAType($scope.org.orgId, riaType)
                .then(function(res) {
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', "Error during adding business type");
                    }
                    $scope.selectedRIATypeIds.push(riaType);
                });
                return;
            }

            let selectedRIAType = _.find($scope.org.partyInfo.riaTypes, function(riatyp) {
                return riatyp.id == riaType
            });
            if(!checkbox.checked && selectedRIAType) {
                OrgsService.removeRIAType($scope.org.orgId, riaType)
                .then(function(res) {
                    if(!_.isEmpty(res.data.error)) {
                        return toastr.error('Error!', "Error during removing business type");
                    }
                    $scope.selectedRIATypeIds = _.reject($scope.selectedRIATypeIds, function(r) {
                        return r.id == riaType
                    });
                });
            }


        };

        $scope.go = function(state) {
            $state.go(state, {orgId: $scope.org.orgId});
        };

    }
])
.controller('OrgsEditCtrl', ['$scope', '$state', '$stateParams', '$location', '$anchorScroll', '$timeout', 'dialogs', 'toastr', 'OrgsService',
    function($scope, $state, $stateParams, $location, $anchorScroll, $timeout, dialogs, toastr, OrgsService) {

    }
])
.controller('OrgsAccountCtrl', ['$scope', '$http', '$state', '$stateParams', '$location', '$anchorScroll', '$timeout', 'dialogs', 'toastr', 'OrgsService',
    function($scope, $http, $state, $stateParams, $location, $anchorScroll, $timeout, dialogs, toastr, OrgsService) {

        self.org = $scope.org;

        $http.get('/' + $scope.roleContext.ciprid + '/account/types/all', {cache: true})
        .then(function(res) {
            $scope.accountTypes = res.data;
        });


        $scope.accountsByAccountSummaryType = function(label) {
            return _.filter($scope.org.accounts, {'label': label})
        };

        $scope.countsOfNegativeBalanceAccountsByAccountSummaryType = function(label) {
            return _.filter($scope.org.accounts, function(account) {
                return account.label == label && parseFloat(account.balance) < 0;
            }).length
        };

        $scope.balanceByAccountSummaryType = function(label) {
            return _.sumBy(_.filter($scope.org.accounts, {'label': label}), function(account) {
                return parseFloat(account.balance)
            });
        };

        $scope.accountSummaryTypes = _.map(_.uniq(_.map($scope.org.accounts, 'label')), function(accountSummaryType) {
            return {
                label: accountSummaryType,
                collapsed: true,
                balance: $scope.balanceByAccountSummaryType(accountSummaryType)
            }
        });

        $scope.getAccountTrans = function(accntCD) {
            $scope.totalDebits = undefined;
            $scope.totalCredits = undefined;
            $scope.totalBalance = undefined;
            $scope.selectedAccnt = accntCD;
            $scope.accountTrans = undefined;
            $scope.loading = true;
            OrgsService.getAccountTrans(accntCD, $scope.org.orgId)
            .then(function(res) {
                $scope.loading = false;
                $scope.accountTrans = res.data;
                $scope.totalDebits = _.sumBy(_.filter($scope.accountTrans, {'db_accnt_cd': accntCD.trim()}), function(trans) {
                    return parseFloat(trans.amt)
                });
                $scope.totalCredits = _.sumBy(_.filter($scope.accountTrans, {'cr_accnt_cd': accntCD.trim()}), function(trans) {
                    return parseFloat(trans.amt)
                });
                $scope.totalBalance = $scope.totalDebits - $scope.totalCredits;
            });
        };

        $scope.getAccountDescription = function(accntCD) {
            var account = _.find($scope.accountTypes, function(accnt) {
                return accnt.id.trim() == accntCD.trim()
            });
            return (account) ? account.description : "";
        };


    }
])
.controller('OrgsNameChangeDialogCtrl', ['$scope', '$uibModalInstance', 'OrgsService', 'data',
    function($scope, $uibModalInstance, OrgsService, data) {
        $scope.org = data;
        $scope.fee = 25;
        $scope.originalLegalName = $scope.org.legal_nm;
        $scope.originalTradeName = $scope.org.trade_nm;
        $scope.accountType = _.find($scope.org.accounts, function(account) {
            return account.accnt_cd.trim() === 'UC_FEE_HOLD';
        });

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.org.fee = $scope.fee;
            $scope.org.accountType = $scope.accountType;
            $uibModalInstance.close($scope.org);
        };

        $scope.nameChanged = function() {
            return $scope.originalLegalName !== $scope.org.legal_nm || $scope.originalTradeName !== $scope.org.trade_nm;
        };

    }
])
.controller('changeInsSponsorDialogCtrl', ['$scope', '$uibModalInstance', '$filter', '$q', 'toastr', 'SweetAlert', 'OrgsService', 'data',
    function($scope, $uibModalInstance, $filter, $q, toastr, SweetAlert, OrgsService, data) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.effectiveDate = moment().format('MMMM DD, YYYY');
        $scope.authbys = [];
        $scope.accounts = [];
        $scope.sponsor = undefined;
        $scope.authorizedBy = undefined;
        $scope.accountType = undefined;
        $scope.org = data[0];
        var ciprId = data[1];
        $scope.fee = 25;
        $scope.insClass = _.head($scope.org.orgins);
        $scope.$watch('insClass', function(newInsClass) {
            if(!newInsClass) return;
            $scope.authbys = [];
            $scope.sponsor = undefined;
            OrgsService.getSponsorChangeLicenseCount($scope.org.orgId, newInsClass.orginsid)
            .then(function(res) {
                $scope.org.spnChangelicenseCount = res.data[0].licenseCount;
            });
        });

        $scope.$watch('sponsor', function(newSponsor) {
            if(!_.has(newSponsor, 'sponsorid')) return;
            $scope.authbys = [];
            $scope.accounts = [];
            $scope.authorizedBy = undefined;
            $q.all([OrgsService.getAuthBys($scope.org.orgId, newSponsor.orginsid), OrgsService.getAllAccounts(ciprId, newSponsor.sponsorid)])
            .then(function(results) {
                $scope.authbys = results[0].data;
                $scope.accounts = results[1];
                $scope.accountType = _.find($scope.accounts, function(account) {
                    return account.accnt_cd.trim() === 'UC_FEE_HOLD';
                });
            });
        });


        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setEffectiveDate = function(selectedDate) {
            $scope.effectiveDate = $filter('date')(selectedDate, "MM dd, yyyy");
        };


        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            let msg="You are about to make a Insurance Company Sponsor Change of " + $scope.org.spnChangelicenseCount + " licenses, estimated execution time " + Math.ceil($scope.org.spnChangelicenseCount * 2 / 60) + " minutes.  Would you like to proceed?";

            var onsuccess = function(res) {
                $scope.spnForInsInProgress = false;
                if(res.data.error) {
                    toastr.error('Error!', 'Unable to change sponsor for company. Please try again later');
                } else {
                    $uibModalInstance.close($scope.org);
                    toastr.success('Success!', 'Sponsor successfully updated.');
                }

            };

            var onerror = function(res) {
                $scope.spnForInsInProgress = false;
                toastr.error('Error!', 'Unable to change sponsor for company. Please try again later');
            };

            SweetAlert.swal({
                    html:'true',
                    title:"Insurance Company Sponsor Change",
                    text: msg,
                    type:"warning",
                    showCancelButton:true,
                    confirmButtonColor:"#f2784b",
                    confirmButtonText:"Yes!",
                    cancelButtonText:"No!",
                    closeOnConfirm:true,
                    closeOnCancel:true
                },
                function(confirmed){
                    if(confirmed){
                        $scope.spnForInsInProgress = true;
                        OrgsService.changeInsSponsor($scope.org.orgId,$scope.insClass.orginsid,$scope.sponsor.orginsid,$scope.fee,$scope.effectiveDate,$scope.accountType.accnt_cd,$scope.authorizedBy.id)
                            .then(onsuccess,onerror);
                    }
                });
        };

        $scope.getSponsors = function(phrase, limit) {
            return OrgsService.getSponsorsByInsClass($scope.insClass.inscls_cd, phrase, limit);
        };

    }
])
.controller('changeAgySponsorDialogCtrl', ['$scope', '$uibModalInstance', 'dialogs', '$filter', 'DTOptionsBuilder', 'toastr', 'SweetAlert', 'OrgsService', 'data',
    function($scope, $uibModalInstance, dialogs, $filter, DTOptionsBuilder, toastr, SweetAlert, OrgsService, data) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.effectiveDate = moment().format("MMMM DD, YYYY");
        $scope.authbys = [];
        $scope.sponsor = undefined;
        $scope.authorizedBy = undefined;
        $scope.accountType = undefined;
        $scope.oldSponsors = [];
        $scope.oldSponsor = undefined;
        $scope.org = data[0];
        var ciprId = data[1];
        $scope.liAccounts = [];
        $scope.lifeAgentLicenseDifferentSponsor = [];
        $scope.lifeAgents = [];
        $scope.fee = 25;

        OrgsService.getAllOrgAccounts(ciprId, $scope.org.orgId)
        .then(function(results) {
            $scope.org.accounts = results;
            $scope.liAccounts = _.filter($scope.org.accounts, function(a) {
                return a.accnt_typ.trim() === 'LI';
            });
            $scope.accountType = _.find($scope.liAccounts, function(account) {
                return account.accnt_cd.trim() === 'UC_FEE_HOLD';
            });
        });


        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('pageLength', 5);

        OrgsService.getAgySponsors($scope.org.orgId)
        .then(function(res) {
            $scope.oldSponsors = res.data;
        });

        $scope.$watch('oldSponsor', function(newInsClass) {
            if(!newInsClass) return;
            $scope.lifeAgentLicenseDifferentSponsor = [];
            $scope.lifeAgents = [];
            $scope.authbys = [];
            $scope.sponsor = undefined;
            OrgsService.getAgySponsorChangeLicenseCount($scope.org.orgId, newInsClass.orgins_seq)
            .then(function(res) {
                $scope.org.spnChangelicenseCount = res.data[0].licenseCount;
            });
        });

        $scope.$watch('sponsor', function(newSponsor) {
            if(!_.has(newSponsor, 'sponsorid')) return;
            $scope.authbys = [];
            $scope.authorizedBy = undefined;
            OrgsService.getAuthBys($scope.org.orgId, newSponsor.orginsid)
            .then(function(results) {
                $scope.authbys = results.data;
            });
            if($scope.oldSponsor.inscls_cd.trim() === 'L') {
                OrgsService.getLifeAgentLicenseDifferentSponsor($scope.org.orgId, $scope.oldSponsor.orgins_seq, newSponsor.orginsid)
                .then(function(results) {
                    $scope.lifeAgentLicenseDifferentSponsor = results.data;
                    $scope.lifeAgents = _.values(_.groupBy(results.data, 'partyId'));
                });
            }
        });


        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setEffectiveDate = function(selectedDate) {
            $scope.effectiveDate = $filter('date')(selectedDate, "MM dd, yyyy");
        };


        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            let msg = "You are about to make a change that will suspend and re-issue " + $scope.org.spnChangelicenseCount + " certificates with a new sponsor. Would you like to proceed?";

            var onsuccess = function(res) {
                toastr.success('Success!', 'Agency sponsor change process has been initiated.');
                $uibModalInstance.close($scope.org);
            };
            var onerror = function(res) {
                $scope.spnForAgyInProgress = false;
                toastr.error('Error!', 'Unable to change sponsor for agency. Please try again later');
            };

            SweetAlert.swal({
                html: 'true',
                title: "Agency Sponsor Change",
                text: msg,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    OrgsService.changeAgySponsor($scope.org.orgId, $scope.oldSponsor.orgins_seq, $scope.sponsor.orginsid, $scope.fee, $scope.effectiveDate, parseFloat($scope.fee) > 0 ? $scope.accountType.accnt_cd : "UC_FEE_HOLD", $scope.authorizedBy.id, 'N')
                    .then(onsuccess, onerror);
                }
            });


        };

        $scope.force = function() {
            let msg = "You are about to make a change that will suspend and re-issue " + $scope.org.spnChangelicenseCount + " certificates with a new sponsor. Would you like to proceed?";

            var onsuccess = function(res) {
                toastr.success('Success!', 'Agency sponsor change process has been initiated.');
                $uibModalInstance.close($scope.org);
            };
            var onerror = function(res) {
                $scope.spnForAgyInProgress = false;
                toastr.error('Error!', 'Unable to change sponsor for agency. Please try again later');
            };

            SweetAlert.swal({
                html: 'true',
                title: "Agency Sponsor Change",
                text: msg,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    OrgsService.changeAgySponsor($scope.org.orgId, $scope.oldSponsor.orgins_seq, $scope.sponsor.orginsid, $scope.fee, $scope.effectiveDate, parseFloat($scope.fee) > 0 ? $scope.accountType.accnt_cd : "UC_FEE_HOLD", $scope.authorizedBy.id, 'Y')
                    .then(onsuccess, onerror);
                }
            });

        };

        $scope.showLifeLicenses = function() {
            dialogs.create('orgs/dialogs/changeAgySponsorLifeLicenses.html', 'changeAgySponsorLifeLicensesDialogCtrl', {licenses: $scope.lifeAgentLicenseDifferentSponsor}, {
                'keyboard': true,
                'backdrop': true,
                'size': 'lg'
            });
        };

        $scope.getSponsors = function(phrase, limit) {
            return OrgsService.getSponsorsByInsClass($scope.oldSponsor.inscls_cd, phrase, limit);
        };


    }
])
.controller('changeAgySponsorLifeLicensesDialogCtrl', ['$scope', '$uibModalInstance', '$filter', 'DTOptionsBuilder', 'data',
    function($scope, $uibModalInstance, $filter, DTOptionsBuilder, data) {
        $scope.licenses = data.licenses;
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        // .withOption('pageLength', 5)
        .withButtons([
            {
                extend: 'print',
                title: "Life Licenses with different sponsor",
                text: '<i class="fa fa-print"></i> Print',
                titleAttr: 'Print',
                className: "btn btn-default"
            },
            {
                extend: 'excel',
                title: "Life Licenses with different sponsor",
                text: '<i class="fa fa-file-excel-o"></i> Excel',
                titleAttr: 'Excel',
                className: "btn btn-default"
            }
        ]);

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

    }

])
.controller('OrgsLicenseCtrl', ['$scope', '$state', '$stateParams', 'store', 'dialogs', 'DTOptionsBuilder', 'OrgsService', 'ApplService', 'PartyService', 'TerminationLetterPrintService',
    function($scope, $state, $stateParams, store, dialogs, DTOptionsBuilder, OrgsService, ApplService, PartyService, TerminationLetterPrintService) {
        var self = this;

        self.org = $scope.org;
        $scope.licenses = [];
        $scope.activeNoDRLicenses = [];
        $scope.selectedLicenseIds = [];
        $scope.loading = true;
        let filterOrgLicenses = store.get("staff_orgs_licenses_filter") || "Issued Licenses";
        $scope.filter = filterOrgLicenses;
        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true)
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        // -- Called from the form --

        $scope.getLicenses = function() {
            $scope.licenses = [];
            $scope.selectedLicenseIds = [];
            $scope.loading = true;
            $scope.filter = "Issued Licenses";
            store.set('staff_orgs_licenses_filter', "Issued Licenses");
            OrgsService.getLicenses(self.org)
            .then(function(res) {
                $scope.licenses = res.data;

                // Default select all
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });

                $scope.loading = false;
            });

        };

        $scope.getAgencyCorporateLicenses = function() {
            $scope.licenses = [];
            $scope.selectedLicenseIds = [];
            $scope.loading = true;
            $scope.filter = "Agency Licenses";
            store.set('staff_orgs_licenses_filter', "Agency Licenses");
            OrgsService.getAgencyCorporateLicenses(self.org)
            .then(function(res) {
                $scope.licenses = res.data;

                // Default select all
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });

                $scope.loading = false;
            });

        };

        $scope.getAgencyCorporateLicensesHistory = function() {
            $scope.licenses = [];
            $scope.selectedLicenseIds = [];
            $scope.loading = true;
            $scope.filter = "Agency License History";
            store.set('staff_orgs_licenses_filter', "Agency License History");
            OrgsService.getAgencyCorporateLicensesHistory(self.org)
            .then(function(res) {
                $scope.licenses = res.data;
                $scope.loading = false;
            });

        };
        /**
         * Load licenses
         */
        var init = function() {
            if(self.org.org_cd.trim() == 'A' || self.org.org_cd.trim() == 'S') {
                if(filterOrgLicenses == "Agency Licenses")
                    $scope.getAgencyCorporateLicenses();
                else if(filterOrgLicenses == "Agency License History")
                    $scope.getAgencyCorporateLicensesHistory()
                else
                    $scope.getLicenses()
            } else
                $scope.getLicenses()
        }
        init();
        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.licenses, function(license) {
                    if(license.statuscd.trim() != 'KSUS')
                        $scope.selectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        $scope.viewLicense = function(license) {
            $state.go('licenses.view', {licenseType: license.licensetype, licenseId: license.licenseid});
        };

        $scope.viewApplicationSnapshot = function(license) {
            $state.go('appl.snapshot.view', {appId: license.licenseid});
        };

        $scope.showEditAddress = function() {
            return self.org.org_type === 'Agency';
        };

        /**
         * Presents an suspend dialog.
         */
        $scope.suspendReason = "TERM";
        $scope.suspendCorpLicenses = function() {
            $scope.suspendErrors = undefined;

            OrgsService.getNoDRLicenses(self.org)
            .then(function(res) {
                $scope.activeNoDRLicenses = res.data;
                PartyService.getLmsUID()
                .then(function(resp) {
                    var data = {
                        activeNoDRLicenses: $scope.activeNoDRLicenses,
                        org: $scope.org,
                        lmsUID: resp.data[0].lmsUID
                    };
                    dialogs.create('orgs/licenses/dialogs/suspend.html', 'OrgLicenseSuspendDialogCtrl', data, {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'xlg'
                    }).result.then(function(response) {
                        var onsuccess = function(res) {
                            var data = res.data;
                            if(!_.isEmpty(res.data.error)) {
                                $scope.suspendErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                                return toastr.error('Error!', 'Licenses cannot be suspended at this time. Please try later');
                            }
                            toastr.success('Success!', 'License(s) have been suspended.');
                            if(response.terminationLetter) {
                                var allLicenseIds = response.affectedLicenseIds.length > 0 ? response.licenseIds.concat(response.affectedLicenseIds) : response.licenseIds;
                                generateTerminationLetters(allLicenseIds);
                            }

                        };
                        var onerror = function(res) {
                            toastr.error('Error!', 'Licenses cannot be suspended at this time. Please try later');
                        };

                        ApplService.suspendCorpLicenses(response.licenseIds, response.reasonId, response.suspendExpiryDate).then(onsuccess, onerror);
                    });

                });


            });
        };

        var generateTerminationLetters = function(licenseIds) {
            var onsuccess = function(res) {
                var data = res.data;
                if(!_.isEmpty(res.data.error)) {
                    $scope.suspendErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Unable to generate termination letters');
                }
                buildTerminationLetterPages(data);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to generate termination letters');
            };
            ApplService.terminationLetters(licenseIds).then(onsuccess, onerror);
        }

        var buildTerminationLetterPages = function(data) {
            var document = {};
            document.content = [];
            document.info = {title: "Termination Letter"};
            document.styles = TerminationLetterPrintService.getStyles();
            if(data.corpAgency.length > 0)
                document = TerminationLetterPrintService.generateCorpAgencyLicenseTerminationPages(document, data.corpAgency);
            if(data.corpSponsor.length > 0)
                document = TerminationLetterPrintService.generateCorpSponsorLicenseTerminationPages(document, data.corpSponsor);
            if(data.agent.length > 0)
                document = TerminationLetterPrintService.generateAgentLicenseTerminationPages(document, data.agent);
            if(data.agency.length > 0)
                document = TerminationLetterPrintService.generateAgencyLicenseTerminationPages(document, data.agency);
            if(data.sponsor.length > 0)
                document = TerminationLetterPrintService.generateSponsorLicenseTerminationPages(document, data.sponsor);
            if(data.adjAgency.length > 0)
                document = TerminationLetterPrintService.generateAdjAgencyLicenseTerminationPages(document, data.adjAgency);
            if(data.adjuster.length > 0)
                document = TerminationLetterPrintService.generateAdjLicenseTerminationPages(document, data.adjuster);

            var win = window.open('', '_blank');
            pdfMake.createPdf(document).open({}, win);
        }


        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            $state.go('licenses.eando', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid;
        };

        $scope.isLicensePaid = function(license) {
            return parseFloat(license.feebalance) == 0;
        };

        $scope.addToInvoice = function(license) {

            var data = {license: license, orgId: self.org.orgId, ciprId: $scope.roleContext.ciprid};
            dialogs.create('orgs/dialogs/addToInvoice.html', 'OrgLicenseAddToInvoiceDialogCtrl', data, {
                'keyboard': true,
                'backdrop': 'true',
                'size': 'xlg'
            }).result.then(function(response) {
                license.invoiceid = response;
            }, function() {
            });
        };

    }
])
.controller('OrgLicenseAddToInvoiceDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'SweetAlert', 'data', 'InvoiceService', 'OrgsService',
    function($scope, $http, $uibModalInstance, $filter, SweetAlert, data, InvoiceService, OrgsService) {
        /**
         * Initializers
         */
        $scope.invoice = undefined;
        $scope.invoices = [];
        $scope.license = data.license;
        $scope.license.fee = $scope.license.feebalance;
        var ciprId = data.ciprId;
        /**
         * Fetch invoices
         */
        InvoiceService.getOpenInvoices(data.orgId)
        .then(function(res) {
            $scope.invoices.push({
                'invoiceid': 'new'
            });
            $scope.invoices = $scope.invoices.concat(res.data);
        });

        $scope.close = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        /**
         * INVOICING
         */
        $scope.selectInvoice = function(item, model) {
            $scope.invoice = model;
        };

        $scope.addToInvoice = function() {
            if($scope.invoice.invoiceid == 'new') $scope.addToNewInvoice();
            else $scope.addToExistingInvoice();
        };

        $scope.addToExistingInvoice = function() {
            InvoiceService.addNoDrLicenseToInvoice(ciprId, $scope.invoice.invoiceid, [$scope.license])
            .then(function() {
                toastr.success('Success!', 'License added to invoice');
                $uibModalInstance.close($scope.invoice.invoiceid);
            }, function() {
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };

        $scope.addToNewInvoice = function() {
            InvoiceService.addNoDrLicenseToNewInvoice(data.orgId, [$scope.license])
            .then(function(res) {
                var invoiceId = res.data[0].invoiceId;
                toastr.success('Success!', 'License added to invoice ' + invoiceId);
                $uibModalInstance.close(invoiceId);
            }, function() {
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };

    }
])
.controller('OrgsApplicationsCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'OrgsService', 'ApplicationService',
    function($scope, $state, $stateParams, DTOptionsBuilder, OrgsService, ApplicationService) {
        var self = this;

        self.org = $scope.org;
        $scope.applications = [];
        $scope.selectedLicenseIds = [];
        $scope.loading = true;


        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);
        /**
         * Load applications
         */
        OrgsService.getApplications(self.org.orgId)
        .then(function(res) {
            $scope.applications = res.data;
            $scope.loading = false;
        });

        $scope.viewApplication = function(application) {
            if($scope.isStaff() && !ApplicationService.isOnlineApp(application) && (ApplicationService.isIncomplete(application) || ApplicationService.isWithRegulator(application)))
                $state.go('appl.open.edit', {appId: application.licenseid});
            else
                $state.go('appl.open.view', {appId: application.licenseid});
        };


        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.applications, function(application) {
                    $scope.selectedLicenseIds.push(application.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /*
         Edit address only for Agency
         */
        $scope.showEditAddress = function() {
            return self.org.org_type === 'Agency';
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

    }
])
.controller('OrgsRenewalsCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'SweetAlert','toastr', 'OrgsService', 'RenewalService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, SweetAlert, toastr, OrgsService, RenewalService) {
        var self = this;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        self.org = $scope.org;
        $scope.selectedLicenseIds = [];
        $scope.renewals = [];
        $scope.renewalPeriodNotStarted = true;
        /**
         * Load renewals
         */
        RenewalService.getRenewalAllow()
        .then(function(res) {

            if(res.data[0].renewalallow === 'Y') {
                $scope.renewalPeriodNotStarted = false;
            }
        });
        var init = function() {
            $scope.loading = true;
            $scope.selectedLicenseIds = [];
            OrgsService.getRenewals(self.org.orgId)
            .then(function(res) {
                $scope.renewals = res.data;
                $scope.loading = false;
            });
        };

        init();
        $scope.viewRenewal = function(renewal) {
            $state.go('renewals.open.view', {renewalId: renewal.licenseid});
        };


        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.renewals, function(renewal) {
                    $scope.selectedLicenseIds.push(renewal.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /*
         Edit address only for Agency
         */
        $scope.isAgency = function() {
            return self.org.org_type === 'Agency';
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        $scope.deleteRenewals = function() {
            SweetAlert.swal({
                title: "Delete Renewals.",
                text: "Are you sure you want to delete selected renewals?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    $scope.loading = true;
                    OrgsService.deleteRenewals(self.org.orgId, $scope.selectedLicenseIds)
                    .then(function(res) {
                        $scope.loading = false;
                        if(!_.isEmpty(res.data.error)) {
                            return toastr.error('Error!', 'Renewals cannot be deleted at this time.');
                        }
                        toastr.success('Success!', 'Renewals has been deleted.');
                        init();
                    }, function(err) {
                        toastr.error('Error!', 'Renewals cannot be deleted at this time.');
                    });
                }
            });
        };

        /**
         * Presents an generate renewal dialog.
         */
        $scope.generateRenewal = function() {
            $scope.renewalErrors = undefined;
            var data = {
                partyid: self.org.orgId
            };
            dialogs.create('staff/renewals/dialogs/generate.renewal.html', 'PartyLicenseGenerateRenewalDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'xlg'
            }).result.then(function(response) {
                init();
            });
        };

    }
])
.controller('OrgsEandOCtrl', ['$scope', '$state', '$stateParams', '$http', 'DTOptionsBuilder', 'OrgsService', 'dialogs',
    function($scope, $state, $stateParams, $http, DTOptionsBuilder, OrgsService, dialogs) {
        var self = this;

        self.org = $scope.org;
        $scope.eandos = [];
        $scope.loading = true;
        $scope.viewExpired = false;
        var allEandOs = [];
        var activeEandOs = [];

        OrgsService.getEandOs(self.org.orgId)
        .then(function(res) {
            allEandOs = res.data;
            activeEandOs = _.filter(allEandOs, function(e) {
                return e.coveragestatus !== 'Expired';
            });
            $scope.eandos = $scope.viewExpired ? allEandOs : activeEandOs;
            $scope.loading = false;
        });

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };

        $scope.showExpired = function($event) {
            var checkbox = $event.target;
            $scope.eandos = checkbox.checked ? allEandOs : activeEandOs;

        };

        $scope.viewEandOCoverage = function(eando) {
            $state.go('orgs.eandoview', {
                policyno: eando.policyno,
                inscategory: eando.insurancecategory,
                carrierpartyid: eando.carrierpartyid,
                carrier: eando.carriername,
                insurancecategorydescr: eando.insurancecategorydescr,
                orgid: self.org.orgId
            });
        };

        $scope.editEandOCoverage = function(eando) {
            var data = {
                title: 'Edit Errors and Omissions Coverage',
                eando: eando
            };

            dialogs.create('eando/dialogs/edit.html', 'EditOrgEandODialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                $http.post('/staff/orgs/' + self.org.orgId + '/eandos/covered', {
                    data: {
                        eando: eando
                    }
                })
                .then(function(res) {
                    $scope.loading = true;
                    OrgsService.getEandOs(self.org.orgId)
                    .then(function(res) {
                        allEandOs = res.data;
                        activeEandOs = _.filter(allEandOs, function(e) {
                            return e.coveragestatus !== 'Expired';
                        });
                        $scope.eandos = $scope.viewExpired ? allEandOs : activeEandOs;
                        $scope.loading = false;
                    });
                });
            });
        };

    }
])
.controller('OrgsEandOViewCtrl', ['$scope', '$state', '$http', '$stateParams', 'DTOptionsBuilder',
    function($scope, $state, $http, $stateParams, DTOptionsBuilder) {
        $scope.coveredEandos = [];
        $scope.policyno = $stateParams.policyno;
        $scope.inscategory = $stateParams.insurancecategorydescr;
        $scope.carrierName = $stateParams.carrier;
        $scope.orgid = $stateParams.orgid;
        $scope.loading = true;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('processing', true);

        $http.get('/staff/orgs/' + $stateParams.orgid + '/eandos/covered', {
            params: {
                policyno: $stateParams.policyno,
                inscategory: $stateParams.inscategory,
                carrierpartyid: $stateParams.carrierpartyid,
                orgid: $stateParams.orgid
            }
        })
        .then(function(res) {
            $scope.loading = false;
            $scope.coveredEandos = res.data;
        });
    }
])
.controller('OrgsCeCourseCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'OrgsService',
    function($scope, $state, $stateParams, DTOptionsBuilder, OrgsService) {
        var self = this;

        self.org = $scope.org;
        $scope.cecourses = [];
        $scope.loading = true;

        /**
         * Load CE Courses
         */
        OrgsService.getCeCourses(self.org.orgId)
        .then(function(res) {
            $scope.cecourses = res.data;
            _.forEach($scope.cecourses, function(cecourse) {
                cecourse.delvMethod = _.find(courseDeliveryMethods, function(d) {
                    return d.id == cecourse.deliveryMethod;
                });
                cecourse.stat = _.find(courseStatuses, function(s) {
                    return s.id == cecourse.scnrioCd;
                });
            });
            $scope.loading = false;
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc']);
    }
])
.controller('OrgsInvoicesCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'OrgsService', 'InvoiceService',
    function($scope, $state, $stateParams, DTOptionsBuilder, OrgsService, InvoiceService) {
        var self = this;

        self.org = $scope.org;
        $scope.invoices = [];
        $scope.allInvoices = [];
        $scope.loading = true;
        $scope.showHistory = false;
        $scope.displayInvoices = undefined;

        $scope.updateList = function() {
            $scope.invoices = _.filter($scope.allInvoices, function(invoice) {
                if(invoice.inv_status_cd.trim() !== 'INVVOD')
                    return invoice
            });
            $scope.displayInvoices = $scope.showHistory ? $scope.allInvoices : $scope.invoices;
        };
        /**
         * Load invoices
         */
        OrgsService.getInvoices(self.org.orgId)
        .then(function(res) {
            $scope.loading = false;
            $scope.allInvoices = res.data;
            $scope.updateList();
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc']);


        $scope.toggleList = function($event) {
            var checkbox = $event.target;
            $scope.showHistory = checkbox.checked;
            $scope.updateList();
        };

        $scope.isPaid = function(invoice) {
            return parseFloat(invoice.balance) == 0;
        };

        $scope.isInvoiceDue = function(invoice) {
            return invoice.inv_status_cd === 'INVOPN'
        };

        $scope.isInvoicePaid = function(invoice) {
            return invoice.inv_status_cd === 'INVPAD'
        };

        $scope.isInvoiceProcessing = function(invoice) {
            return invoice.inv_status_cd === 'INVPRC'
        };

        $scope.isInvoiceVoided = function(invoice) {
            return invoice.inv_status_cd === 'INVVOD'
        };

        $scope.viewInvoice = function(invoice) {
            $state.go('payments.view', {invoiceId: invoice.invoiceid});
        };

        $scope.canDelete = function(invoice) {
            return !invoice.item_cnt || invoice.item_cnt == 0;
        };

        /**
         * Delete invoice.
         * @param invoiceId
         */
        $scope.deleteInvoice = function(invoiceId) {
            InvoiceService.deleteInvoice($scope.roleContext.ciprid, invoiceId)
            .then(function(data, status) {
                $scope.displayInvoices = undefined;
                $scope.loading = true;
                toastr.success('Success!', 'Invoice has been deleted.');
                OrgsService.getInvoices(self.org.orgId)
                .then(function(res) {
                    $scope.loading = false;
                    $scope.allInvoices = res.data;
                    $scope.updateList();
                });
            }, function(data, status) {
                toastr.error('Error!', 'Invoice cannot be deleted at this time.');
            });
        };

    }
])
.controller('OrgsPayablesCtrl', ['$scope', '$state', '$http', '$filter', '$q', 'blockUI', 'toastr', 'DTOptionsBuilder', 'OrgsService', 'PayableService',
    function($scope, $state, $http, $filter, $q, blockUI, toastr, DTOptionsBuilder, OrgsService, PayableService) {
        /**
         * Initializers
         */
        self.org = $scope.org;
        $scope.payables = [];
        $scope.loading = true;

        $scope.isPaid = function(item) {
            return parseFloat(item.balance) == 0;
        };

        $scope.isLicenseApplication = function(item) {
            return item.itemtypecd == "LIC_APP";
        };

        $scope.isLicenseRenewal = function(item) {
            return item.itemtypecd == "LIC_REN";
        };

        $scope.isDRLicense = function(item) {
            return item.licenseid && item.stklic.drlicense == "Y";
        };

        $scope.isCorpLicense = function(item) {
            return item.licenseid && item.stklic.licensetype == "C";
        };

        $scope.isExamApplication = function(item) {
            return item.itemtypecd == "EXAM_APP";
        };

        OrgsService.getPayables(self.org.orgId)
        .then(function(res) {
            $scope.loading = false;
            $scope.payables = res.data;
            var corpLicenses = _.filter($scope.payables, $scope.isCorpLicense);
            $scope.payables = _.map($scope.payables, function(payable) {
                if($scope.isDRLicense(payable)) {
                    var corplicitem = _.find(corpLicenses, function(corpLicenseInvoiceItem) {
                        var corpLicense = corpLicenseInvoiceItem.stklic;
                        return payable.stklic.corporatelicenseclass == corpLicense.licenseclass.trim() && payable.stklic.agencyid == corpLicense.agencyid;
                    });
                    payable.corplic = corplicitem;
                    payable.corplicitemid = corplicitem ? corplicitem.invoiceitemid : '';
                    return payable;
                }
                return payable;
            });

        });


        /**
         * Removes item from payable list
         *
         * @param item
         */
        $scope.deleteInvoiceItem = function(item) {
            if($scope.isDRLicense(item) && item.corplicitemid) return $scope.deleteDRLicenseInvoiceItem(item);
            PayableService.deleteInvoiceItem($scope.roleContext.ciprid, item.invoiceitemid)
            .then(function(res) {
                $scope.payables = _.without($scope.payables, item);
                toastr.success('Success!', 'Invoice item has been removed.');
            }, function(res) {
                toastr.error('Error!', 'Invoice item cannot be deleted at this time.');
            });
        };

        $scope.deleteDRLicenseInvoiceItem = function(item) {
            $q.all([PayableService.deleteInvoiceItem($scope.roleContext.ciprid, item.invoiceitemid)], PayableService.deleteInvoiceItem($scope.roleContext.ciprid, item.corplicitemid))
            .then(function(res) {
                var corpitem = _.find($scope.payables, {'invoiceitemid': item.corplicitemid});
                $scope.payables = _.without($scope.payables, item, corpitem);
                toastr.success('Success!', 'Invoices item has been removed.');
            }, function(err) {
                toastr.error('Error!', 'Invoice items cannot be deleted at this time.');
            })

        };


    }
])
.controller('OrgsAddressCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'OrgsService', 'AddressService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, OrgsService, AddressService) {
        var self = this;

        self.org = $scope.org;
        $scope.addresses = [];
        $scope.loading = true;

        OrgsService.getAddresses(self.org.orgId)
        .then(function(res) {
            $scope.addresses = res.data;
            $scope.loading = false;
        });

        AddressService.getAddressTypes()
        .then(function(res) {
            $scope.addressTypes = res;
            $scope.addressTypes = _.filter($scope.addressTypes, function(adrtype) {
                return ($scope.isInsurer() ? adrtype.addr_type_cd.trim() == 'I' : adrtype.addr_type_cd.trim() == 'A') || adrtype.addr_type_cd.trim() == 'B';
            });
        });

        /**
         * Presents an edit dialog.
         * @param address
         */
        $scope.editAddress = function(address) {
            var data = {address: address, addressTypes: $scope.addressTypes};
            dialogs.create('orgs/address/dialogs/addoredit.html', 'OrgsAddressAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(address) {

                var onsuccess = function(res) {
                    toastr.success('Success!', 'Address has been updated.');
                    $scope.addresses = _.reject($scope.addresses, {'id': address.id});
                    address.description = _.find($scope.addressTypes, function(atyp) {
                        return atyp.id.trim() == address.scnrio_cd.trim()
                    }).description;
                    $scope.addresses.push(address);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Address cannot be able to updated. Please try later');
                };

                OrgsService.updateAddress(self.org.orgId, address).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an add dialog.
         */
        $scope.addAddress = function() {
            var data = {addressTypes: $scope.addressTypes};
            dialogs.create('orgs/address/dialogs/addoredit.html', 'OrgsAddressAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(address) {

                var onsuccess = function(res) {
                    var data = res.data;
                    toastr.success('Success!', 'Address has been created.');
                    address.id = data[0].id;
                    address.description = _.find($scope.addressTypes, function(atyp) {
                        return atyp.id.trim() == address.scnrio_cd.trim()
                    }).description;
                    $scope.addresses.push(address);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Unable to create address at this time. Please try later');
                };

                OrgsService.createAddress(self.org.orgId, address).then(onsuccess, onerror);
            });
        };

        $scope.removeAddress = function(address) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Address has been removed.');
                $scope.addresses = _.reject($scope.addresses, {'id': address.id});
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Address cannot be removed at this time. Please try later');
            };

            OrgsService.removeAddress(self.org.orgId, address).then(onsuccess, onerror);
        };

    }
])
.controller('OrgsAddressAddEditDialogCtrl', ['$scope', '$uibModalInstance', 'AddressService', 'OrgsService', 'data',
    function($scope, $uibModalInstance, AddressService, OrgsService, data) {
        $scope.address = data.address;
        $scope.addressTypes = data.addressTypes;

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };


        $scope.setCity = function(item, model, label) {
            $scope.address.city = model.cityname;
            $scope.address.cityid = model.cityid;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.address.province = model.provname;
            $scope.address.provinceid = model.provid;
            $scope.address.provabbrev = model.provabbreviation;
        };

        $scope.setCountry = function(item, model, label) {
            $scope.address.country = model.countryname;
            $scope.address.countryid = model.countryid;
        };


        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.address);
        };

        $scope.checkCity = function() {
            if(!$scope.address.cityid) {
                $scope.address.city = "";
            }
        };
        $scope.checkProvince = function() {
            if(!$scope.address.provinceid) {
                $scope.address.province = "";
            }
        };
        $scope.checkCountry = function() {
            if(!$scope.address.countryid) {
                $scope.address.country = "";
            }
        };

        $scope.$watch('noResultsCity', function(noNewCity) {
            if(noNewCity) {
                $scope.address.cityid = "";
                return;
            }
        });
        $scope.$watch('noResultsCountry', function(noNewCountry) {
            if(noNewCountry) {
                $scope.address.countryid = "";
                return;
            }
        });
        $scope.$watch('noResultsProvince', function(noNewProvince) {
            if(noNewProvince) {
                $scope.address.provinceid = "";
                return;
            }
        });

    }
])
.controller('OrgsNotesCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'NotesService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, NotesService) {
        var self = this;

        self.org = $scope.org;
        $scope.notes = [];
        $scope.noteTypes = [];
        $scope.loading = true;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [5, 'desc']);


        NotesService.getNotes(self.org.orgId)
        .then(function(res) {
            $scope.notes = res.data.notes;
            $scope.noteTypes = res.data.noteTypes;
            $scope.loading = false;
        });

        /**
         * Presents an edit dialog.
         * @param note
         */
        $scope.editNote = function(note) {
            var data = {note: note, noteTypes: $scope.noteTypes};
            dialogs.create('staff/notes/dialogs/addoredit.html', 'NoteAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(note) {

                var onsuccess = function(res) {
                    toastr.success('Success!', 'Note has been updated.');
                    $scope.notes = _.reject($scope.notes, {'id': note.id});
                    $scope.notes.push(note);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Note cannot be able to updated. Please try later');
                };

                NotesService.updateNote(self.org.orgId, note).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an add dialog.
         */
        $scope.addNote = function() {
            var data = {note: undefined, noteTypes: $scope.noteTypes};
            dialogs.create('staff/notes/dialogs/addoredit.html', 'NoteAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(note) {

                var onsuccess = function(res) {
                    var data = res.data;
                    toastr.success('Success!', 'Note has been created.');
                    note = data[0];
                    $scope.notes.push(note);
                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Unable to create note at this time. Please try later');
                };

                NotesService.createNote(self.org.orgId, note).then(onsuccess, onerror);
            });
        };

        $scope.removeNote = function(note) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Note has been removed.');
                $scope.notes = _.reject($scope.notes, {'id': note.id});
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Note cannot be removed at this time. Please try later');
            };

            NotesService.removeNote(self.org.orgId, note).then(onsuccess, onerror);
        };
    }
])
.controller('OrgsEventCtrl', ['$scope', '$state', '$stateParams', '$filter', 'dialogs', 'DTOptionsBuilder', 'EventService',
    function($scope, $state, $stateParams, $filter, dialogs, DTOptionsBuilder, EventService) {
        var self = this;

        self.org = $scope.org;
        $scope.events = [];
        $scope.loading = true;
        $scope.incCommEvent = 'N';
        $scope.incWebEvent = 'N';
        $scope.dateFormat = 'MM dd, yyyy';
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        $scope.fromDate = moment().subtract(30, 'days').format('MMMM DD, YYYY');
        $scope.toDate = moment(d).format('MMMM DD, YYYY');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc']);


        EventService.getOrgEvents(self.org.orgId, $scope.fromDate, $scope.toDate, $scope.incCommEvent, $scope.incWebEvent)
        .then(function(res) {
            $scope.events = res.data;
            $scope.loading = false;
        });

        /**
         * Called when date picker is closed
         * @param selectedDate
         */
        $scope.setFromDate = function(selectedDate) {
            $scope.fromDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        /**
         * Called when datepicker is dismissed
         * @param selectedDate
         */
        $scope.setToDate = function(selectedDate) {
            $scope.toDate = $filter('date')(selectedDate, "yyyy-MM-dd");
        };

        $scope.setCommEvent = function($event) {
            var checkbox = $event.target;
            $scope.incCommEvent = checkbox.checked ? 'Y' : 'N';
        };

        $scope.setWebEvent = function($event) {
            var checkbox = $event.target;
            $scope.incWebEvent = checkbox.checked ? 'Y' : 'N';
        };


        $scope.getEvents = function() {
            $scope.loading = true;
            EventService.getOrgEvents(self.org.orgId, $scope.fromDate ? $filter('date')($scope.fromDate, "yyyy-MM-dd") : null, $scope.toDate ? $filter('date')($scope.toDate, "yyyy-MM-dd") : null, $scope.incCommEvent, $scope.incWebEvent)
            .then(function(res) {
                $scope.events = res.data;
                $scope.loading = false;
            });
        }
    }
])
.controller('OrgsNsfCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'NsfService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, NsfService) {
        var self = this;

        self.org = $scope.org;
        $scope.nsfs = [];
        $scope.nsfAccounts = [];
        $scope.loading = true;
        var partyNsfBlock = blockUI.instances.get('party-nsf-block');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();

        NsfService.getNsfAccounts()
        .then(function(res) {
            $scope.nsfAccounts = res.data;
        });

        NsfService.getNsfStatuses()
        .then(function(res) {
            $scope.nsfStatuses = res.data;
        });

        NsfService.getNsfs(self.org.partyType, self.org.orgId)
        .then(function(res) {
            $scope.nsfs = res.data;
            $scope.loading = false;
        });


        $scope.getStatusDescription = function(statusCD) {
            var st = _.find($scope.nsfStatuses, function(status) {
                return status.id.trim() == statusCD.trim()
            });
            return (st) ? st.description : "";
        };

        /**
         * Presents an add dialog.
         */
        $scope.addNSF = function() {
            $scope.creationNsfErrors = undefined;
            var data = {
                nsf: undefined,
                party: $scope.org,
                ciprid: $scope.roleContext.ciprid,
                nsfStatuses: $scope.nsfStatuses,
                nsfAccounts: $scope.nsfAccounts
            };
            dialogs.create('staff/nsf/dialogs/addoredit.html', 'NsfAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(nsf) {
                var onsuccess = function(res) {
                    partyNsfBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationNsfErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Nsf cannot be created at this time. Please try later');
                    }
                    toastr.success('Success!', 'Nsf has been created.');
                    nsf = data[0];
                    if($scope.nsfs == undefined)
                        $scope.nsfs = [nsf];
                    else
                        $scope.nsfs.unshift(nsf);
                };
                var onerror = function(res) {
                    partyNsfBlock.stop();
                    toastr.error('Error!', 'Unable to create nsf at this time. Please try later');
                };
                partyNsfBlock.start("Creating Nsf...");
                NsfService.createNsf(self.org.partyType, self.org.orgId, nsf).then(onsuccess, onerror);
            });
        };

        $scope.canEdit = function(nsf) {
            return _.includes(["FLIC", "FEXM", "FMSC", "FCEE", "FPPD"], nsf.scnrio_cd.trim());
        };

        /**
         * Presents an edit dialog.
         */
        $scope.editNSF = function(nsf) {
            $scope.creationNsfErrors = undefined;
            var data = {
                nsf: nsf,
                party: $scope.org,
                ciprid: $scope.roleContext.ciprid,
                nsfStatuses: $scope.nsfStatuses,
                nsfAccounts: $scope.nsfAccounts
            };
            dialogs.create('staff/nsf/dialogs/addoredit.html', 'NsfAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(nsf) {
                var onsuccess = function(res) {
                    partyNsfBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationNsfErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Nsf cannot be updated at this time. Please try later');
                    }
                    toastr.success('Success!', 'Nsf has been updated.');
                    nsf = data[0];
                    $scope.nsfs = _.reject($scope.nsfs, {'id': nsf.id});
                    if($scope.nsfs == undefined)
                        $scope.nsfs = [nsf];
                    else
                        $scope.nsfs.unshift(nsf);
                };
                var onerror = function(res) {
                    partyNsfBlock.stop();
                    toastr.error('Error!', 'Unable to update nsf at this time. Please try later');
                };
                partyNsfBlock.start("Updating Nsf...");
                NsfService.updateNsf(self.org.partyType, self.org.orgId, nsf).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an writeOff dialog.
         */
        $scope.writeOffNSF = function(nsf) {
            $scope.creationNsfErrors = undefined;
            var data = {
                nsf: nsf,
                party: $scope.org,
                ciprid: $scope.roleContext.ciprid
            };
            dialogs.create('staff/nsf/dialogs/wo.html', 'NsfWoDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(reason) {
                var onsuccess = function(res) {
                    partyNsfBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationNsfErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Nsf cannot be write off at this time. Please try later');
                    }
                    toastr.success('Success!', 'Nsf has been written off.');
                    nsf = data[0];
                    $scope.nsfs = _.reject($scope.nsfs, {'id': nsf.id});
                    if($scope.nsfs == undefined)
                        $scope.nsfs = [nsf];
                    else
                        $scope.nsfs.unshift(nsf);
                };
                var onerror = function(res) {
                    partyNsfBlock.stop();
                    toastr.error('Error!', 'Unable to write off nsf at this time. Please try later');
                };
                partyNsfBlock.start("Write Off Nsf...");
                NsfService.writeOffNsf(self.org.partyType, self.org.orgId, nsf, reason).then(onsuccess, onerror);
            });
        };


    }
])
.controller('OrgsOrginsCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'OrginsService', 'SweetAlert',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, OrginsService, SweetAlert) {
        var self = this;
        self.org = $scope.org;
        $scope.orgins = [];
        $scope.displayOrgins = [];
        $scope.activeOrgins = [];
        $scope.loading = true;
        $scope.showHistory = false;
        var partyOrginsBlock = blockUI.instances.get('party-orgins-block');

        $scope.updateList = function() {
            $scope.activeOrgins = _.filter($scope.orgins, function(orgins) {
                if(orgins.scnrio_cd.trim() == 'IACT')
                    return orgins
            });
            $scope.displayOrgins = $scope.showHistory ? $scope.orgins : $scope.activeOrgins;
        };

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();

        OrginsService.getInsclasses()
        .then(function(res) {
            $scope.insclasses = res.data;
        });

        OrginsService.getOrgins(self.org.orgId)
        .then(function(res) {
            $scope.loading = false;
            $scope.orgins = res.data;
            $scope.updateList();
        });

        $scope.toggleList = function($event) {
            var checkbox = $event.target;
            $scope.showHistory = checkbox.checked;
            if(checkbox.checked) {
                $scope.displayOrgins = $scope.orgins;
            } else {
                $scope.displayOrgins = $scope.activeOrgins;
            }

        };


        /**
         * Presents an add dialog.
         */
        $scope.addOrgins = function() {
            $scope.creationOrginsErrors = undefined;
            var data = {
                orgins: undefined,
                org: $scope.org,
                ciprid: $scope.roleContext.ciprid,
                insclasses: $scope.insclasses
            };
            dialogs.create('staff/orgins/dialogs/addoredit.html', 'OrginsAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(orgins) {
                var onsuccess = function(res) {
                    partyOrginsBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationOrginsErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Insurance class cannot be added at this time. Please try later');
                    }
                    toastr.success('Success!', 'Insurance class has been added.');
                    orgins = data[0];
                    if($scope.orgins == undefined)
                        $scope.orgins = [orgins];
                    else
                        $scope.orgins.unshift(orgins);
                    $scope.updateList();
                };
                var onerror = function(res) {
                    partyOrginsBlock.stop();
                    toastr.error('Error!', 'Unable to add insurance class at this time. Please try later');
                };
                partyOrginsBlock.start("Adding insurance class...");
                OrginsService.addOrgins(self.org.orgId, orgins).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an edit dialog.
         */
        $scope.editOrgins = function(orgins) {
            $scope.creationOrginsErrors = undefined;
            var data = {
                orgins: orgins,
                org: $scope.org,
                ciprid: $scope.roleContext.ciprid,
                insclasses: $scope.insclasses
            };
            dialogs.create('staff/orgins/dialogs/addoredit.html', 'OrginsAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(orgins) {
                var onsuccess = function(res) {
                    partyOrginsBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationOrginsErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Insurance class cannot updated at this time. Please try later');
                    }
                    toastr.success('Success!', 'Insurance class has been updated.');
                    orgins = data[0];
                    $scope.orgins = _.reject($scope.orgins, {'id': orgins.id});
                    if($scope.orgins == undefined)
                        $scope.orgins = [orgins];
                    else
                        $scope.orgins.unshift(orgins);
                    $scope.updateList();
                };
                var onerror = function(res) {
                    partyOrginsBlock.stop();
                    toastr.error('Error!', 'Unable to update insurance class at this time. Please try later');
                };
                partyOrginsBlock.start("Updating insurance class...");
                OrginsService.updateOrgins(orgins).then(onsuccess, onerror);
            });
        };

        /**
         * reverse orgins
         */
        $scope.reverseOrgins = function(orgins) {
            $scope.creationOrginsErrors = undefined;
            var onsuccess = function(res) {
                partyOrginsBlock.stop();
                var data = res.data;
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationOrginsErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Insurance class cannot be reversed at this time. Please try later');
                }
                toastr.success('Success!', 'Insurance class has been reversed.');
                orgins = data[0];
                $scope.orgins = _.reject($scope.orgins, {'id': orgins.id});
                if($scope.orgins == undefined)
                    $scope.orgins = [orgins];
                else
                    $scope.orgins.unshift(orgins);
                $scope.updateList();
            };
            var onerror = function(res) {
                partyOrginsBlock.stop();
                toastr.error('Error!', 'Unable to reverse insurance class at this time. Please try later');
            };
            SweetAlert.swal({
                title: "Reverse Insurance Class.",
                text: "Are you sure you want to reverse off the insurance class? This can reverse suspended license attach to this Organization insurance class.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    partyOrginsBlock.start("Reversing insurance class...");
                    OrginsService.reverseOrgins(self.org.orgId, orgins).then(onsuccess, onerror);
                }
            });


        };

    }
])
.controller('OrgsAppointeeCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'AppointeeService', 'OrginsService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, AppointeeService, OrginsService) {
        var self = this;
        self.org = $scope.org;
        $scope.appointees = [];
        $scope.displayAppointees = [];
        $scope.activeAppointees = [];
        $scope.orgins = [];
        $scope.activeOrgins = [];
        $scope.loading = true;
        $scope.showHistory = false;
        var partyAppointeeBlock = blockUI.instances.get('org-appointee-block');

        $scope.updateList = function() {
            $scope.activeAppointees = _.filter($scope.appointees, function(appointee) {
                if(appointee.scnrio_cd.trim() == 'UACT')
                    return appointee
            });
            $scope.displayAppointees = $scope.showHistory ? $scope.appointees : $scope.activeAppointees;
        };

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc']);

        AppointeeService.getAppointees(self.org.orgId)
        .then(function(res) {
            $scope.loading = false;
            $scope.appointees = res.data;
            $scope.updateList();
        });

        OrginsService.getOrgins(self.org.orgId)
        .then(function(res) {
            $scope.loading = false;
            $scope.orgins = res.data;
            $scope.activeOrgins = _.filter($scope.orgins, function(orgins) {
                if(orgins.scnrio_cd.trim() == 'IACT')
                    return orgins
            });
        });

        $scope.toggleList = function($event) {
            var checkbox = $event.target;
            $scope.showHistory = checkbox.checked;
            $scope.updateList();

        };

        /**
         * Presents an add dialog.
         */
        $scope.addAppointee = function() {
            $scope.creationAppointeeErrors = undefined;
            var data = {
                appointee: undefined,
                org: $scope.org,
                ciprid: $scope.roleContext.ciprid,
                activeOrgins: $scope.activeOrgins
            };
            dialogs.create('staff/appointee/dialogs/addoredit.html', 'AppointeeAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(appointee) {
                var onsuccess = function(res) {
                    partyAppointeeBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationAppointeeErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Signing authority cannot be added at this time. Please try later');
                    }
                    toastr.success('Success!', 'Signing authority has been added.');
                    appointee = data[0];
                    if($scope.appointees == undefined)
                        $scope.appointees = [appointee];
                    else
                        $scope.appointees.unshift(appointee);
                    $scope.updateList();
                };
                var onerror = function(res) {
                    partyAppointeeBlock.stop();
                    toastr.error('Error!', 'Unable to add signing authority at this time. Please try later');
                };
                partyAppointeeBlock.start("Adding signing authority...");
                AppointeeService.addAppointee(appointee).then(onsuccess, onerror);
            });
        };


        /**
         * Presents an edit dialog.
         */
        $scope.editAppointee = function(appointee) {
            $scope.creationAppointeeErrors = undefined;
            var data = {
                appointee: appointee,
                org: $scope.org,
                ciprid: $scope.roleContext.ciprid,
                activeOrgins: $scope.activeOrgins
            };
            dialogs.create('staff/appointee/dialogs/addoredit.html', 'AppointeeAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(appointee) {
                var onsuccess = function(res) {
                    partyAppointeeBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationAppointeeErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Signing authority cannot updated at this time. Please try later');
                    }
                    toastr.success('Success!', 'Signing authority has been updated.');
                    appointee = data[0];
                    $scope.appointees = _.reject($scope.appointees, {'stkath_seq': appointee.stkath_seq});
                    if($scope.appointees == undefined)
                        $scope.appointees = [appointee];
                    else
                        $scope.appointees.unshift(appointee);
                    $scope.updateList();
                };
                var onerror = function(res) {
                    partyAppointeeBlock.stop();
                    toastr.error('Error!', 'Unable to update signing authority at this time. Please try later');
                };
                partyAppointeeBlock.start("Updating signing authority...");
                AppointeeService.updateAppointee(appointee).then(onsuccess, onerror);
            });
        };

    }
])
.controller('OrgsRefundCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'RefundService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, RefundService) {
        var self = this;

        self.org = $scope.org;
        $scope.refunds = [];
        $scope.refundAccounts = [];
        $scope.loading = true;
        var partyRefundBlock = blockUI.instances.get('party-refund-block');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();

        RefundService.getRefundAccounts()
        .then(function(res) {
            $scope.refundAccounts = res.data;
        });

        RefundService.getRefundStatuses()
        .then(function(res) {
            $scope.refundStatuses = res.data;
        });


        RefundService.getRefunds(self.org.partyType, self.org.orgId)
        .then(function(res) {
            $scope.refunds = res.data;
            $scope.loading = false;
        });


        $scope.getStatusDescription = function(statusCD) {
            var st = _.find($scope.refundStatuses, function(status) {
                return status.id.trim() == statusCD.trim()
            });
            return (st) ? st.description : "";
        };

        /**
         * Presents an add dialog.
         */
        $scope.addRefund = function() {
            $scope.creationRefundErrors = undefined;
            var data = {
                refund: undefined,
                ciprid: $scope.roleContext.ciprid,
                party: $scope.org,
                refundAccounts: $scope.refundAccounts
            };
            dialogs.create('staff/refund/dialogs/add.html', 'RefundAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(refund) {

                var onsuccess = function(res) {
                    partyRefundBlock.stop();
                    var data = res.data;
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationRefundErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Refund cannot be created at this time. Please try later');
                    }
                    toastr.success('Success!', 'Refund has been created.');
                    refund = data[0];
                    $scope.refunds.unshift(refund);
                };
                var onerror = function(res) {
                    partyRefundBlock.stop();
                    toastr.error('Error!', 'Unable to create refund at this time. Please try later');
                };
                partyRefundBlock.start("Saving Refund...");
                RefundService.createRefund(self.org.partyType, self.org.orgId, refund).then(onsuccess, onerror);
            }, function() {
            });
        };

        $scope.cancelRefund = function(refund) {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', 'Refund cannot be cancelled at this time. Please try later');
                }
                toastr.success('Success!', 'Refund has been removed.');
                var data = res.data;
                $scope.refunds = _.reject($scope.refunds, {'id': refund.id});
                refund = data[0];
                $scope.refunds.unshift(refund);
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Refund cannot be removed at this time. Please try later');
            };

            RefundService.cancelRefund(refund).then(onsuccess, onerror);
        };

        /**
         * Presents an cheque dialog.
         */
        $scope.refundCheque = function(refund) {
            $scope.creationRefundErrors = undefined;
            var data = {chqbatId: refund.chqbatId, ciprid: $scope.roleContext.ciprid};
            dialogs.create('staff/refund/dialogs/refund.cheque.html', 'RefundChequeDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(refundCheque) {

                var onsuccess = function(res) {
                    partyRefundBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationRefundErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Refund cheque cannot be updated at this time. Please try later');
                    }
                    toastr.success('Success!', 'Refund cheque has been updated.');
                };
                var onerror = function(res) {
                    partyRefundBlock.stop();
                    toastr.error('Error!', 'Unable to update refund cheque at this time. Please try later');
                };
                partyRefundBlock.start("Saving refund cheque...");
                RefundService.updateRefundCheque(refundCheque).then(onsuccess, onerror);
            }, function() {
            });
        };


    }
])
.controller('OrgsWoCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'WoService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, WoService) {
        var self = this;

        self.org = $scope.org;
        $scope.wos = [];
        $scope.loading = true;
        var partyWoBlock = blockUI.instances.get('party-wo-block');

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap();

        WoService.getWoStatuses()
        .then(function(res) {
            $scope.woStatuses = res.data;
        });

        WoService.getWos(self.org.partyType, self.org.orgId)
        .then(function(res) {
            $scope.wos = res.data;
            $scope.loading = false;
        });

        $scope.getStatusDescription = function(statusCD) {
            var st = _.find($scope.woStatuses, function(status) {
                return status.id.trim() == statusCD.trim()
            });
            return (st) ? st.description : "";
        };

    }
])
.controller('OrgsDocumentCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'DocumentService', 'DownloadService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, DocumentService, DownloadService) {
        var self = this;

        self.org = $scope.org;
        $scope.documents = {};
        $scope.loading = true;
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withOption('order', [0, 'desc'])
        .withBootstrap();

        var party = [];
        party.id = self.org.orgId;
        DocumentService.searchByParty(party)
        .then(function(res) {
            $scope.documents = res.data;
            $scope.loading = false;
        });

        $scope.downloadLink = function(doc) {
            var anchorHref = S("/staff/documents/{{docid}}/download").template({docid: doc.docId}).s;
            var anchorText = doc.fileName;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        $scope.editDocument = function(doc) {
            $state.go('documents.open.edit', {docId: doc.docId});
        };
    }
])
.controller('OrgLicenseSuspendDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'DTOptionsBuilder', 'SweetAlert', 'data', 'OrgsService', 'ApplService',
    function($scope, $http, $uibModalInstance, $filter, DTOptionsBuilder, SweetAlert, data, OrgsService, ApplService) {

        $scope.activeNoDRLicenses = data.activeNoDRLicenses;
        $scope.activeNoDRSelectedLicenseIds = [];
        $scope.suspendReason = "TERM";
        $scope.suspendExpiryDate = moment().format('MMMM DD, YYYY');
        $scope.org = data.org;
        $scope.lmsUID = data.lmsUID;

        $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc']).withOption('lengthChange', false).withOption('paging', false);

        ApplService.getSuspendReasons()
        .then(function(res) {
            $scope.suspendReasons = res.data;
        });

        $scope.canBeSuspended = function(license) {
            return (license.approvingManager && license.approvingManager.toUpperCase() != $scope.lmsUID.toUpperCase());
        };

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.activeNoDRLicenses, function(license) {
                    $scope.activeNoDRSelectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.activeNoDRSelectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.activeNoDRSelectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.activeNoDRSelectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.activeNoDRSelectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.activeNoDRSelectedLicenseIds.splice($scope.activeNoDRSelectedLicenseIds.indexOf(licenseid), 1);
            }
        };


        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.activeNoDRSelectedLicenseIds.indexOf(licenseid) >= 0;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };


        var onsuccess = function(res) {
            var data = res.data;
            if(!_.isEmpty(res.data.error)) {
                $scope.suspendErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                return toastr.error('Error!', 'Licenses cannot be updated at this time. Please try later');
            }
            OrgsService.getNoDRLicenses($scope.org)
            .then(function(res) {
                $scope.activeNoDRLicenses = res.data;
                toastr.success('Success!', 'License(s) have been updated.');
            });

        };
        var onerror = function(res) {
            toastr.error('Error!', 'Licenses cannot be updated at this time. Please try later');
        };

        $scope.approve = function() {
            ApplService.approveSuspendCorpLicenses($scope.activeNoDRSelectedLicenseIds, $scope.suspendReason).then(onsuccess, onerror);
        };

        $scope.removeApproval = function() {
            ApplService.removeApproveSuspendCorpLicenses($scope.activeNoDRSelectedLicenseIds).then(onsuccess, onerror);
        };

        $scope.suspend = function(withTerminationLetter) {
            var affectedLicenseIds = [];
            var selectedLicenses = _.filter($scope.activeNoDRLicenses, function(license) {
                return $scope.activeNoDRSelectedLicenseIds.indexOf(license.licenseid) !== -1; // -1 means not present
            });
            var selectedLicenseNumbers = _.map(selectedLicenses, _.property('license'));

            var selectedLicensesNonSusp = _.filter(selectedLicenses, function(license) {
                return !$scope.canBeSuspended(license);  // -1 means not present
            });

            if(withTerminationLetter) {
                affectedLicenseIds = _.flatten(_.map(selectedLicenses, function(sl) {
                    return sl.affectedCount > 1 ? sl.affectedLicenseIds.split(',') : sl.affectedLicenseIds;
                }));
                affectedLicenseIds = affectedLicenseIds.filter(function(e) {
                    return e
                });
            }

            if(selectedLicensesNonSusp.length > 0) {
                var selectedNonSuspLicenseNumbers = _.map(selectedLicensesNonSusp, _.property('license'));
                SweetAlert.swal({
                    title: "Suspension Failed!",
                    text: "Following licenses cannot be suspended: <br>" + selectedNonSuspLicenseNumbers + " . <br> Please select only licenses that have been approved by someone else.",
                    html: true,
                    type: "error",
                    confirmButtonColor: "#f2784b",
                    confirmButtonText: "OK!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                });
            } else {
                SweetAlert.swal({
                    title: "Confirm suspension",
                    text: "Suspending corporate licenses will also cause all other agency licenses to be suspended. <br><br><strong> THIS IS NOT REVERSIBLE!</strong><br><br> " +
                    "Do you want to suspend the corporate license(s): <br>" + selectedLicenseNumbers + (withTerminationLetter ? " and generate Termination Letter" : "") + " ?",
                    html: true,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#f2784b",
                    confirmButtonText: "Yes!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function(confirmed) {
                    if(confirmed) {
                        $uibModalInstance.close({
                            reasonId: $scope.suspendReason,
                            licenseIds: $scope.activeNoDRSelectedLicenseIds,
                            suspendExpiryDate: $scope.suspendExpiryDate,
                            terminationLetter: withTerminationLetter,
                            affectedLicenseIds: affectedLicenseIds
                        });
                    }
                });
            }
        };
    }
])
.controller('OrgsAssessmentCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'blockUI', 'toastr', 'SweetAlert', 'AssessmentInvoicesService', 'AssessmentInvoicesPrintService',
    function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, blockUI, toastr, SweetAlert, AssessmentInvoicesService, AssessmentInvoicesPrintService) {
        var self = this;
        self.org = $scope.org;
        $scope.assessmentInvoices = [];
        $scope.displayAssessmentInvoices = [];
        $scope.activeAssessmentInvoices = [];
        $scope.statuses = [];
        $scope.periods = [];
        $scope.loading = true;
        $scope.showHistory = false;

        $scope.updateList = function() {
            $scope.activeAssessmentInvoices = _.filter($scope.assessmentInvoices, function(ai) {
                if(!(ai.scnrio_cd.trim() === 'AICAN' || ai.scnrio_cd.trim() === 'AIPAID'))
                    return ai
            });
            $scope.displayAssessmentInvoices = $scope.showHistory ? $scope.assessmentInvoices : $scope.activeAssessmentInvoices;
        };

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc']);

        /**
         * Fetch statuses
         */
        AssessmentInvoicesService.getStatuses()
        .then(function(res) {
            $scope.statuses = res.data;
        });
        /**
         * Fetch periods
         */
        AssessmentInvoicesService.getPeriods()
        .then(function(res) {
            $scope.periods = res.data;
        });

        var init = function() {
            $scope.loading = true;
            AssessmentInvoicesService.getAssessmentInvoicesByOrg(self.org.orgId)
            .then(function(res) {
                $scope.loading = false;
                $scope.assessmentInvoices = res.data;
                $scope.updateList();
            });
        };

        init();

        $scope.toggleList = function($event) {
            var checkbox = $event.target;
            $scope.showHistory = checkbox.checked;
            $scope.updateList();
        };

        $scope.getStatusDescription = function(status) {
            return _.find($scope.statuses, function(s) {
                return s.scnrio_cd.trim() === status.trim()
            }).description;
        };

        $scope.getPeriodDescription = function(period) {
            return _.find($scope.periods, function(p) {
                return p.id.trim() === period.trim()
            }).descr;
        };

        $scope.otherStatus = function(assessmentInvoice) {
            return !_.includes(['AIPAID', 'AICAN', 'AINOT'], assessmentInvoice.scnrio_cd.trim())
        }

        $scope.cancelAssessment = function(assessmentInvoice) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Assessment invoice has been cancelled.');
                init();
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Error cancelling assessment invoice');
            };

            SweetAlert.swal({
                title: "Cancel Assessment Invoice",
                text: "Are you sure you want to cancel assessment invoice?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    AssessmentInvoicesService.cancel(assessmentInvoice.assess_inv_seq).then(onsuccess, onerror);
                }
            });
        }

        $scope.generateAssessmentInvoiceByOrg = function() {

            var onsuccess = function(res) {
                $scope.generateAssessmentInProgress = false;
                if(res.data.error) {
                    toastr.error('Error!', 'Error occurred during assessment generation process. Please try again later');
                } else {
                    toastr.success('Success!', 'Assessment generation process ran successfully.');
                    init();
                }

            };
            var onerror = function(res) {
                $scope.generateAssessmentInProgress = false;
                toastr.error('Error!', 'Error occurred during annual assessment generation process. Please try again later');
            };


            $scope.generateAssessmentInProgress = true;
            AssessmentInvoicesService.generateAssessmentInvoiceByOrg(self.org.orgId)
            .then(onsuccess, onerror);

        }

        $scope.printInitialAssessmentNotice = function(assessmentInvoice) {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Unable to print initial assessment(s) notice at this time. Please try later');
                }
                var pages = AssessmentInvoicesPrintService.generateInitialAssessmentPages(res.data);
                var win = window.open('', '_blank');
                pdfMake.createPdf(pages).open({}, win);
            };

            var onerror = function(res) {
                toastr.error('Error!', 'Unable to print initial assessment(s) notice at this time. Please try later');
            };
            AssessmentInvoicesService.getInitialAssessmentInvoicesToPrintByIds(assessmentInvoice.assess_inv_seq).then(onsuccess, onerror);
        };

        $scope.printPenaltyAssessmentNotice = function(assessmentInvoice) {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Unable to print penalty assessment(s) notice at this time. Please try later');
                }
                var pages = AssessmentInvoicesPrintService.generatePenaltyAssessmentPages(res.data);
                var win = window.open('', '_blank');
                pdfMake.createPdf(pages).open({}, win);
            };


            var onerror = function(res) {
                toastr.error('Error!', 'Unable to print penalty assessment(s) notice at this time. Please try later');
            };
            AssessmentInvoicesService.getPenaltyAssessmentInvoicesToPrintByIds(assessmentInvoice.assess_inv_seq).then(onsuccess, onerror);
        };

        $scope.printAssessmentStatement = function(assessmentInvoice) {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Unable to print assessment(s) statement at this time. Please try later');
                }
                var pages = AssessmentInvoicesPrintService.generateAssessmentStatementPages(res.data);
                var win = window.open('', '_blank');
                pdfMake.createPdf(pages).open({}, win);
            };


            var onerror = function(res) {
                toastr.error('Error!', 'Unable to print assessment(s) statement at this time. Please try later');
            };
            AssessmentInvoicesService.getAssessmentStatementToPrintByIds(assessmentInvoice.assess_inv_seq).then(onsuccess, onerror);
        };


    }
])
/**
 * Org services
 */
.service('OrgsService', ['$http', '$q',
    function($http, $q) {
        this.orgs = [];
        this.searchStr = undefined;

        this.setPartySearchStr = function(searchStr) {
            this.searchStr = searchStr
        };

        this.setOrgs = function(orgs) {
            this.orgs = orgs
        };

        this.search = function(searchStr) {
            return $http.get('/staff/orgs/search', {
                params: {
                    searchStr: searchStr
                }, cache: true
            });
        };

        this.searchAgency = function(searchStr) {
            return $http.get('/orgs/search', {
                params: {
                    searchStr: searchStr
                }, cache: true
            });
        };


        this.getLicenses = function(org) {
            return $http.get('/staff/orgs/' + org.orgId + '/licenses/issued/', {
                params: {org_cd: org.org_cd},
                cache: true
            });
        };

        this.getNoDRLicenses = function(org) {
            return $http.get('/staff/orgs/' + org.orgId + '/licenses/nodr/');
        };

        this.getAgencyCorporateLicenses = function(org) {
            return $http.get('/staff/orgs/' + org.orgId + '/licenses/agency/corporate/issued/');
        };

        this.getAgencyCorporateLicensesHistory = function(org) {
            return $http.get('/staff/orgs/' + org.orgId + '/licenses/agency/corporate/history/', {cache: true});
        };

        this.getApplications = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/licenses/applications/', {cache: true});
        };

        this.getRenewals = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/licenses/renewals/');
        };

        this.getCeCourses = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/cecourses/', {cache: true});
        };

        this.getInvoices = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/invoices/');
        };

        this.getPayables = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/payables/', {cache: true});
        };

        this.getEandOs = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/eandos/');
        };

        this.getAddresses = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/address/', {cache: true});
        };

        this.getTypes = function() {
            return $http.get('/staff/orgs/types/', {cache: true});
        };

        this.updateAddress = function(orgId, address) {
            return $http.post('/staff/orgs/' + orgId + '/address/' + address.id, {
                data: {
                    address: address
                }
            });
        };

        this.removeAddress = function(orgId, address) {
            return $http.delete('/staff/orgs/' + orgId + '/address/' + address.id);
        };

        this.createAddress = function(orgId, address) {
            return $http.put('/staff/orgs/' + orgId + '/address/', {
                data: {
                    address: address
                }
            });
        };

        this.create = function(legalName, tradeName, orgType, assignSponsorNumber, corpDocs, email, comments, adminciprid, activeDate) {
            return $http.post('/staff/orgs', {
                legalName: legalName,
                tradeName: tradeName,
                orgType: orgType,
                assignSponsorNumber: assignSponsorNumber,
                corpDocs: corpDocs,
                email: email,
                comments: comments,
                adminciprid: adminciprid,
                activeDate: activeDate
            });
        };

        this.update = function(org) {
            _.remove(org, 'types');
            _.remove(org, 'statuses');
            return $http.post('/staff/orgs/' + org.orgId, {
                org: org
            });
        };

        this.updateAgencyName = function(orgId, legalName, tradeName, fee, account) {
            return $http.post('/staff/orgs/' + orgId + '/agencyNameChange', {
                legalName: legalName,
                tradeName: tradeName,
                fee: fee,
                account: account
            });
        };

        this.getAgencyActiveLicenseCount = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/activeLicenseCount/', {cache: true});
        };

        this.getOrgIns = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/orgins/', {cache: true});
        };

        this.getSponsorChangeLicenseCount = function(orgId, orgInsId) {
            return $http.get('/staff/orgs/' + orgId + '/sponsorChangeLicenseCount/' + orgInsId);
        };

        this.getAgySponsorChangeLicenseCount = function(orgId, orgInsId) {
            return $http.get('/staff/orgs/' + orgId + '/agySponsorChangeLicenseCount/' + orgInsId);
        };

        this.getAuthBys = function(orgId, orgInsId) {
            return $http.get('/staff/orgs/' + orgId + '/authbys/' + orgInsId);
        };

        this.getLifeAgentLicenseDifferentSponsor = function(orgId, oldOrgInsId, newOrgInsId) {
            return $http.get('/staff/orgs/' + orgId + '/lifeAgentLicenseDifferentSponsor/', {
                params: {
                    oldOrgInsId: oldOrgInsId,
                    newOrgInsId: newOrgInsId
                }
            });
        };

        this.getSponsorsByInsClass = function(insClass, phrase, limit) {
            return $http.get('/lookup/license/insurer/' + insClass, {
                params: {
                    name: phrase,
                    limit: limit
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getAllAccounts = function(ciprId, orgId) {
            return $http.get('/' + ciprId + '/account/all?partyId=' + orgId).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getAllOrgAccounts = function(ciprId, orgId) {
            return $http.get('/' + ciprId + '/account/orgAll?orgId=' + orgId).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.changeInsSponsor = function(orgId, oldOrgInsId, newOrgInsId, fee, effectiveDate, account, authorizedBy) {
            return $http.post('/staff/orgs/' + orgId + '/insSponsorChange', {
                oldOrgInsId: oldOrgInsId,
                newOrgInsId: newOrgInsId,
                fee: fee,
                account: account,
                effectiveDate: effectiveDate,
                authorizedBy: authorizedBy
            });
        };

        this.changeAgySponsor = function(orgId, oldOrgInsId, newOrgInsId, fee, effectiveDate, account, authorizedBy, force) {
            return $http.post('/staff/orgs/' + orgId + '/agySponsorChange', {
                oldOrgInsId: oldOrgInsId,
                newOrgInsId: newOrgInsId,
                fee: fee,
                account: account,
                effectiveDate: effectiveDate,
                authorizedBy: authorizedBy,
                force: force
            });
        };

        this.getAgySponsors = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/agencySponsors/');
        };

        this.removeRIAType = function(organizationId, riaTypeId) {
            return $http({
                url: "/staff/orgs/" + organizationId + "/riatypes",
                method: 'DELETE',
                data: {
                    riaTypeId: riaTypeId
                },
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };

        this.addRIAType = function(organizationId, riaTypeId) {
            return $http.post('/staff/orgs/' + organizationId + '/riatypes', {
                riaTypeId: riaTypeId
            });
        };

        this.getAccountTrans = function(accntCD, orgId) {
            return $http.get('/staff/orgs/' + orgId + '/accountTransactions/', {
                params: {
                    accntCD: accntCD
                }
            });
        };

        this.deleteRenewals = function(orgId, licenseIds){
            return $http.delete('/staff/orgs/' + orgId + '/licenses/renewals/' , {
                params: {
                    licenseIds : licenseIds
                }
            });
        }

    }
])
.service('OrgsResolverService', ['$resource', '$http', 'OrgsService',
    function($resource, $http, OrgsService) {

        /**
         * Returns a promise which resolves a org object.
         *
         * @param ciprid
         * @param orgid
         * @returns {*}
         */
        this.get = function(orgid) {
            return $http.get('/staff/orgs/' + orgid)
            .then(function(res) {

                var org = res.data.org;

                org.accounts = res.data.accounts;
                org.types = res.data.types;
                org.statuses = res.data.statuses;
                org.partyInfo = res.data.partyInfo[0];
                org.partyInfo.riaTypes = res.data.riaTypes;
                org.partyInfo.certificateTypes = res.data.certificateTypes;
                return org;
            });
        };
    }
]);
