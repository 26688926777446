var llqp = angular.module('aic.llqp', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'aic.filters',
    'aic.ng-jquery'
]);

llqp.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('llqp', {
            parent: 'workspace',
            url: '/llqp',
            templateUrl: 'llqp/completion.html',
            controller: 'llqpCtrl'
        });
    }
])

.controller('llqpCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', 'toastr',
    function($scope, $state, $stateParams, $http, $filter, toastr) {
        //$scope.llqp = undefined;
        $scope.buttonDisabled = false;
        $scope.alerts = [];
        $scope.providers = [];
        $scope.fileName = undefined;
        // -- Initializers --

        $http.get('/staff/llqp/providers')
            .then(function(res) {
                $scope.providers = res.data;
            });

        $scope.programTypes = [{text: 'Full LLQP', value: 'FULL'}, {text: 'Accident and Sickness Only', value: 'A_S'}];

        $scope.reset = function() {
            //$scope.llqp = undefined;
            $scope.llqp.registrantciprid = undefined;
            $scope.llqp.provider = undefined;
            $scope.llqp.completionDate = '';
            $scope.llqp.programType = undefined;
            $scope.llqp.dmsfileuploaded = undefined;
            $scope.fileName = undefined;
            $scope.llqp.subject = undefined;
            $scope.llqp.keywords = undefined;
            $scope.alerts = [];
            //$scope.llqpCompletionForm.$setPristine();
        };

        addAlert = function(msg) {
            $scope.alerts.push({type: 'danger', msg: msg});
        };

        $scope.closeAlert = function(index) {
            $scope.alerts.splice(index, 1);
        };

        setFileName = function(element) {
            $scope.$apply(function() {
                $scope.fileName = element.files[0].name;
            });
        };

        $scope.checkRegistrant = function(registrantciprid){
            $scope.selectedRegistrantError = undefined;
            $scope.selectedRegistrant = undefined;
            return $http.get('/lookup/user/byciprid', {
                params: {
                    ciprid: registrantciprid
                }
            }).then(function(res) {
                if(res.data.profile)
                    $scope.selectedRegistrant = res.data.profile;
                else
                    $scope.selectedRegistrantError = 'invalid';

            });
        };

        $scope.save = function() {
            $scope.alerts = [];
            if(!$scope.llqp){
                addAlert('All Fields are required.');
                return;
            }
            if(! $scope.selectedRegistrant ) {
                addAlert('Registrant is required.');
                return;
            }
            if(! $scope.fileName && $scope.dmsfileuploaded == 'N') {
                addAlert('File is required.');
                return;
            }

            $scope.llqp.completionDate = $scope.llqp.completionDate ? $filter('date')($scope.llqp.completionDate, "yyyy-MM-dd") : null;
            $scope.buttonDisabled = true;
            $http.put('/staff/llqp', {
                data: {
                    registrantciprid: $scope.llqp.registrantciprid,
                    providerciprid: $scope.llqp.provider.orgciprid,
                    completionDate: $scope.llqp.completionDate,
                    programType: $scope.llqp.programType.value,
                    dmsfileuploaded: $scope.llqp.dmsfileuploaded,
                    fileName: $scope.fileName,
                    subject:$scope.llqp.subject,
                    keywords:$scope.llqp.keywords
                }
            })
                .then(function(res) {
                    $scope.buttonDisabled = false;
                    if(res.data && res.data.llqp) {
                        toastr.success('Success!', 'Registrant LLQP completion saved successfully');
                        $scope.reset();
                        $state.go('llqp');
                    }
                    else {
                        addAlert("Unable to save completion information");
                    }
                });

        };



    }




]);
