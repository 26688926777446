angular.module('aic.services', [])

/**
 * Download service
 */
.service('DownloadService', ['$http',
    function($http) {

        /**
         * Adds a new invisible <a> tag and simulates a click to download content at the link.
         *
         * @param jwtToken bearer token
         * @param anchorHref URL
         * @param anchorText link text
         * @param mimeType content type, defaults to 'application/pdf'
         */
        this.download = function(jwtToken, anchorHref, anchorText, mimeType) {
            var isIEOrEdge = ['IE', 'Microsoft Edge', 'IE Mobile'].indexOf(platform.name) > -1;

            $http.get(anchorHref, {
                responseType: 'arraybuffer',
                headers: {
                    Authorization: 'Bearer ' + jwtToken
                }
            })
            .then(function(res) {
                var blob = new Blob([res.data], {type: 'application/pdf'});

                if(isIEOrEdge) {
                    window.navigator.msSaveOrOpenBlob(blob, anchorText);
                } else {
                    var downloadLink = document.createElement("a");
                    downloadLink.download = anchorText;
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.onclick = function(e) {
                        document.body.removeChild(e.target);
                    };
                    downloadLink.style.display = "none";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                }
            });
        };
    }
])
/**
 * Util service
 */
.service('UtilService', [
    function() {
        this.toPhone = function (tel, areaCode, ext) {
            var formattedPhone = !_.isEmpty(areaCode) ? "(" + areaCode + ") " : '';

            if (!_.isEmpty(tel)) {
                formattedPhone += tel.length > 3 ? tel.slice(0, 3) + "-" + tel.slice(3) : tel;
            } else {
                formattedPhone = '';
            }
            formattedPhone += !_.isEmpty(ext) ? " [ext " + ext + "]" : "";

            return formattedPhone;
        };

        this.toPhoneFromString = function (val) {
           if(_.isEmpty(val)) return '';
            return "(" + val.slice(0, 3) + ") " +  val.slice(3, 6) + "-" + val.slice(6);
        };
    }
]);




