var notes = angular.module('aic.notes', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
notes.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;


    }
])
.controller('NoteAddEditDialogCtrl', ['$scope', '$uibModalInstance','data',
    function($scope, $uibModalInstance,  data) {
        $scope.note = data.note;
        $scope.noteTypes = data.noteTypes;
        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.note);
        };


    }
])
.service('NotesService', ['$http', '$q',
    function($http, $q) {
        this.getNotes = function(partyId) {
            return $http.get('/staff/notes/party/' + partyId, {cache: true});
        };

        this.updateNote = function(partyId, note) {
            return $http.post('/staff/notes/' + note.id, {
                data: {
                    note: note
                }
            });
        };

        this.removeNote = function(partyId, note) {
            return $http.delete('/staff/notes/' + note.id);
        };

        this.createNote = function(partyId, note) {
            return $http.put('/staff/notes/party/' + partyId, {
                data: {
                    note: note
                }
            });
        };

    }
]);