var eplicenses = angular.module('aic.eplicenses', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.router'
]);

eplicenses.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('eplicenses', {
            parent: 'workspace',
            abstract: true,
            url: '/eplicenses',
            templateUrl: 'eplicenses/eplicenses.html'
        })
        .state('eplicenses.list', {
            url: '/list',
            templateUrl: 'eplicenses/eplicenses.list.html',
            controller: 'EPLicensesListCtrl'
        })
        .state('eplicenses.add', {
            url: '/add',
            templateUrl: 'eplicenses/eplicenses.add.html',
            controller: 'EPLicensesAddCtrl'
        });

    }
])
.controller('EPLicensesListCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'EPLicensesService',
    function($scope, $state, $http, DTOptionsBuilder, EPLicensesService) {
        $scope.eplicenses = [];
        $scope.regulators = [];
        $scope.ceExempt = false;

        EPLicensesService.getEPLicenses($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.eplicenses = res.data.eplicenses;
            $scope.regulators = res.data.regulators;

            // if at least one of the province regulators is exempt for at least one ins. category, set the ceExempt
            // flag ( meaning the agent can enter his/her extra prov license and it will be used  to calculate his/her
            // CE status).
            _.forEach($scope.regulators, function(regulator) {
                if(regulator.ceExemptForLife == 'Y' || regulator.ceExemptForA_S == 'Y' || regulator.ceExemptForGeneral == 'Y' || regulator.ceExemptForAdjuster == 'Y') {
                    $scope.ceExempt = true;
                }
            });
        });

        $scope.voidEPLicense = function(eplicense) {
            EPLicensesService.voidEPLicense($scope.roleContext.ciprid, eplicense.id)
            .then(function(data) {
                $scope.eplicenses = _.without($scope.eplicenses, eplicense);
                toastr.success('Success!', 'Non-Alberta license ' + eplicense.licenseNumber + ' has been deleted.');
                $state.go('eplicenses.list');
            });
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions().withBootstrap();
    }
])
.controller('EPLicensesAddCtrl', ['$scope', '$state', '$http', '$stateParams', 'DTOptionsBuilder', 'EPLicensesService',
    function($scope, $state, $http, $stateParams, DTOptionsBuilder, EPLicensesService) {

        $scope.regulator = undefined;
        $scope.categories = undefined;
        $scope.declaration = undefined;
        $scope.ceExempt = false;


        EPLicensesService.getRegulators($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.regulators = res.data.regulators;
            _.forEach($scope.regulators, function(regulator) {
                if(regulator.ceExemptForLife == 'Y' || regulator.ceExemptForA_S == 'Y' || regulator.ceExemptForGeneral == 'Y' || regulator.ceExemptForAdjuster == 'Y') {
                    $scope.ceExempt = true;
                }
            });
        });

        $scope.setRegulator = function(item, model) {
            $scope.regulator = model;
        };

        $scope.saveEPLicense = function(licenseNumber) {
            EPLicensesService.saveEPLicense($scope.roleContext.ciprid, _.map($scope.selectedCategories, 'id'), $scope.regulator, licenseNumber, $scope.declaration)
            .then(function(resp) {
                toastr.success('Success!', 'Non-Alberta license ' + licenseNumber + ' has been added.');
                $state.go('eplicenses.list')
            });
        };


        $scope.setDeclaration = function() {
            $scope.selectedCategories = [];

            _.forEach($scope.categories, function(category) {
                if(category.selected) {
                    $scope.selectedCategories.push(category);
                }
            });

            EPLicensesService.getDeclaration($scope.roleContext.ciprid, _.map($scope.selectedCategories, 'label'))
            .then(function(res) {
                $scope.declaration = res.data[0];
            });

        };

        $scope.$watch('regulator', function(regulator) {
            if(typeof regulator === 'object') {
                EPLicensesService.getCategories($scope.roleContext.ciprid, regulator.id)
                .then(function(res) {
                    $scope.categories = res.data.categories;
                    $scope.declaration = undefined;
                });
            }
        });

        //validation
        $scope.atLeastOneCategorySelected = function() {
            return $scope.selectedCategories && $scope.selectedCategories.length > 0;
        };
    }
])
.service('EPLicensesService', ['$http',
    function($http) {

        this.getEPLicenses = function(ciprId, partyid) {
            return $http.get('/' + ciprId + '/eplicenses/workspace',{
                params: {licholderId: partyid}
            });
        };

        this.getRegulators = function(ciprId) {
            return $http.get('/' + ciprId + '/eplicenses/regulators')
        };

        this.getCategories = function(ciprId, regulatorId) {
            return $http.get('/' + ciprId + '/eplicenses/categories', {
                params: {regulatorId: regulatorId}
            })
        };

        this.getPeriods = function(ciprId, category) {
            return $http.get('/' + ciprId + '/eplicenses/periods', {
                params: {category: category}
            })
        };

        this.getDeclaration = function(ciprId, categories, province) {
            return $http.get('/' + ciprId + '/eplicenses/declaration', {
                params: {category: categories.join(", "), province: province}
            })
        };

        this.voidEPLicense = function(ciprId, extraProvLicenseId) {
            return $http.delete('/' + ciprId + '/eplicenses/' + extraProvLicenseId);
        };

        this.saveEPLicense = function(ciprId, category, regulator, licensenumber, declaration, licensePeriod, licHolderId) {
            return $http.put('/' + ciprId + '/eplicenses/', {
                category: category,
                regulator: regulator.id,
                licensenumber: licensenumber,
                declId: declaration.decl_seq,
                decl: declaration.declaration,
                licensePeriod:licensePeriod,
                licHolderId:licHolderId
            });
        };
    }
]);