var applications = angular.module('aic.applications', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);
var revLicClassMap = {
    'PE': 'PA',
    'H': 'F',
    'I': 'G'
};

var licClassMap = {
    'PA': 'PE',
    'F ': 'H ',
    'G ': 'I '
};

/**
 * Routing and config
 */
applications.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('application', {
            parent: 'workspace',
            abstract: true,
            url: '/application',
            templateUrl: 'applications/application.html'
        })
        .state('application.list', {
            url: '/list',
            controller: 'ApplicationListCtrl',
            templateUrl: 'applications/application.list.html'
        })
        .state('application.stafflist', {
            url: '/l',
            controller: 'ApplicationStaffListCtrl',
            templateUrl: 'applications/application.staff.list.html'
        })
        .state('application.init', {
            url: '/init',
            controller: 'ApplicationInitCtrl',
            templateUrl: 'applications/application.init.html',
            params: {'backtocombine': false}
        })
        .state('application.initiated', {
            url: '/init/view?licclass&sponsorid&agencyid&paidby&invoiceid&insureremployee&expedited',
            controller: 'ApplicationInitiatedCtrl',
            templateUrl: 'applications/application.inited.html'
        })
        .state('application.open', {
            url: '/{appId:[0-9]+}',
            abstract: true,
            resolve: {
                promisedLicense: ['$stateParams', 'AuthService', 'ApplicationResolverService',
                    function($stateParams, AuthService, ApplicationResolverService) {
                        var jwtToken = AuthService.getToken();
                        var ciprid = AuthService.getRoleContext().ciprid;
                        var licenseid = $stateParams.appId;
                        return ApplicationResolverService.get(jwtToken, ciprid, licenseid);
                    }
                ],
                promisedCorpLicense: ['$stateParams', 'AuthService', 'promisedLicense', 'ApplicationResolverService',
                    function($stateParams, AuthService, promisedLicense, ApplicationResolverService) {
                        var jwtToken = AuthService.getToken();
                        var ciprid = AuthService.getRoleContext().ciprid;
                        if(promisedLicense.drlicense === 'Y' && !_.isEmpty(promisedLicense.corpLicense) && promisedLicense.corpLicense.statuscd.trim() === 'KAPP') {
                            return ApplicationResolverService.get(jwtToken, ciprid, promisedLicense.corpLicense.licenseid)
                        }

                        return {};
                    }
                ]
            },
            controller: 'ApplicationOpenCtrl',
            template: '<div ui-view></div>'
        })
        .state('application.open.edit', {
            url: '',
            views: {
                '': {
                    templateUrl: 'applications/application.edit.html',
                    controller: 'ApplicationEditCtrl'
                },
                'header@application.open.edit': {
                    templateUrl: 'applications/header/header.html',
                    controller: 'ApplicationHeaderCtrl'
                },
                'address@application.open.edit': {
                    templateUrl: 'applications/address/address.edit.html',
                    controller: 'AddressCtrl'
                },
                'eo@application.open.edit': {
                    templateUrl: 'applications/eo/eo.add.html',
                    controller: 'ApplicationEandOCtrl'
                },
                'employment@application.open.edit': {
                    templateUrl: 'applications/employment/employment.history.edit.html',
                    controller: 'EmploymentHistoryCtrl'
                },
                'crimcheck@application.open.edit': {
                    templateUrl: 'applications/crimcheck/crimcheck.edit.html',
                    controller: 'CriminalCheckCtrl'
                },
                'qa@application.open.edit': {
                    templateUrl: 'applications/qa/qa.edit.html',
                    controller: 'QuestionsCtrl'
                },
                'payment@application.open.edit': {
                    templateUrl: 'applications/payment/payment.html',
                    controller: 'ApplicationPaymentCtrl'
                }
            }
        })
        .state('application.open.view', {
            url: '',
            views: {
                '': {
                    templateUrl: 'applications/application.view.html',
                    controller: 'ApplicationViewCtrl'
                },
                'header@application.open.view': {
                    templateUrl: 'applications/header/header.html',
                    controller: 'ApplicationHeaderCtrl'
                },
                'address@application.open.view': {
                    templateUrl: 'applications/address/address.view.html',
                    controller: 'AddressCtrl'
                },
                'eo@application.open.view': {
                    templateUrl: 'applications/eo/eo.view.html',
                    controller: 'ApplicationEandOCtrl'
                },
                'employment@application.open.view': {
                    templateUrl: 'applications/employment/employment.history.view.html',
                    controller: 'EmploymentHistoryCtrl'
                },
                'crimcheck@application.open.view': {
                    templateUrl: 'applications/crimcheck/crimcheck.view.html',
                    controller: 'CriminalCheckCtrl'
                },
                'qa@application.open.view': {
                    templateUrl: 'applications/qa/qa.view.html',
                    controller: 'QuestionsCtrl'
                },
                'payment@application.open.view': {
                    templateUrl: 'applications/payment/payment.html',
                    controller: 'ApplicationPaymentCtrl'
                }
            }
        });
    }
])

/**
 * Www routes controllers
 */
.controller('ApplicationListCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'blockUI', 'ApplicationService', 'InvoiceService', 'PayableService',
    function($scope, $state, $http, DTOptionsBuilder, blockUI, ApplicationService, InvoiceService, PayableService) {
        $scope.applications = [];
        $scope.corpApplications = [];
        var applicationBlock = blockUI.instances.get('application-block');
        applicationBlock.start();
        ApplicationService.getApplications($scope.roleContext.ciprid)
        .then(function(res) {
            setApplications(res.data);
            applicationBlock.stop();
        });

        var setApplications = function(data) {
            $scope.applications = data[0];
            $scope.corpApplications = data[1];

            // link the corp license to the D/R license
            var drApplications = _.filter($scope.applications, {drlicense: 'Y'});
            _.forEach(drApplications, function(application) {
                application.corpLicense = _.find($scope.corpApplications, {
                    licenseclass: application.corporatelicenseclass,
                    agencyid: application.agencyid
                });
            });
        };

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc']);

        $scope.viewApplication = function(application) {
            applicationBlock.start();
            if($scope.isPerson() && ApplicationService.isWithCandidate(application))
                $state.go('application.open.edit', {appId: application.licenseid});
            else
                $state.go('application.open.view', {appId: application.licenseid});
        };

        /**
         * Add application to shopping cart.
         */
        $scope.addToShoppingCart = function(application) {
            var items = ApplicationService.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense) ? [application, application.corpLicense] : [application];
            InvoiceService.addApplicationsToShoppingCart($scope.roleContext.ciprid, items)
            .then(function() {
                toastr.success('Success!', application.license + ' added to Invoice');
                applicationBlock.start();
                ApplicationService.getApplications($scope.roleContext.ciprid)
                .then(function(res) {
                    setApplications(res.data);
                    applicationBlock.stop();
                });
            }, function() {
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };

        /**
         * Add application to payable.
         */
        $scope.addToPayable = function(application) {
            var items = ApplicationService.isDRApp(application) && ApplicationService.isCorpLicenseExists(application.corpLicense) ? [application, application.corpLicense] : [application];
            PayableService.addApplicationsToPayables($scope.roleContext.ciprid, items)
            .then(function() {
                toastr.success('Success!', application.license + ' added to Payable');
                applicationBlock.start();
                ApplicationService.getApplications($scope.roleContext.ciprid)
                .then(function(res) {
                    setApplications(res.data);
                    applicationBlock.stop();
                });
            }).error(function() {
                toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
            });
        };

        $scope.isInvoiced = function(application) {
            return !!application.invoiceid || !!application.payableid;
        };

        $scope.isDRApp = function(license) {
            return ApplicationService.isDRApp(license);
        };

        /**
         * For  D/R licenses license fee is sum of the D/R License Fee and Corp License Fee
         */
        $scope.getFee = function(a) {
            var licenseFee = parseFloat(a.fee);
            return a.drlicense != 'Y' || !a.corpLicense ? licenseFee : licenseFee + parseFloat(a.corpLicense.fee);
        };

        $scope.getFeeBalance = function(a) {
            var licenseFeeBalance = parseFloat(a.feebalance);
            return a.drlicense != 'Y' || !a.corpLicense ? licenseFeeBalance : licenseFeeBalance + parseFloat(a.corpLicense.feebalance);
        };
    }
])
.controller('ApplicationStaffListCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'blockUI', 'ApplicationService',
    function($scope, $state, $http, DTOptionsBuilder, blockUI, ApplicationService) {
        $scope.applications = [];
        $scope.loading = true;

        var applicationBlock = blockUI.instances.get('application-block');
        applicationBlock.start();

        $http.get('/' + $scope.roleContext.ciprid + '/licenses/staff/applications')
        .then(function(res) {
            applicationBlock.stop();
            $scope.applications = res.data;
            $scope.loading = false;
        });

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc']);

        $scope.viewApplication = function(application) {
            //$state.go('reviews.open.application', {
            //    licenseid: application.licenseid,
            //    ciprid: application.licenseholderciprid
            //});
            $state.go('application.open.view', {appId: application.licenseid});
        };

    }
])
.controller('ApplicationInitCtrl', ['$scope', '$state', '$stateParams', '$http', 'store', 'dialogs', 'toastr', 'ApplicationService', 'InvoiceService',
    function($scope, $state, $stateParams, $http, store, dialogs, toastr, ApplicationService, InvoiceService) {
        //TODO findout why blockui conflict with license class
        $scope.classes = [];
        $scope.sponsors = [];
        $scope.unpaidInvoices = [];
        $scope.drLicenses = [];
        $scope.backtocombine = $stateParams.backtocombine;
        let applicationAgencyObj = store.get("application_agency_create");
        $scope.createApplicationInProgress = false;
        // --- Initializers ---
        var initForm = function() {
            $scope.class = {};
            $scope.category = "person";
            $scope.licHolderEmail = undefined;
            $scope.agency = undefined;
            $scope.sponsor = undefined;
            $scope.person = undefined;
            $scope.insureremployee = undefined;
            $scope.invoice = undefined;
            $scope.paidby = undefined;
            $scope.creationErrors = [];
            $scope.isTransfer = 'N';
            $scope.isReplace = 'N';
            $scope.transferableLicenses = [];
            $scope.replaceLicense = undefined;
            $scope.downgradableLicense = undefined;
            $scope.corpLicense = {};
            $scope.expedited = 'N';
        };

        $scope.paidbyOptions = [
            {label: 'I haven\'t decided yet', value: ''},
            {label: 'Agent is paying', value: 'agent'},
            {label: 'We are paying', value: $scope.roleContext.type}
        ];

        initForm();
        $scope.paidby = $scope.paidbyOptions[0];

        /**
         * Fetch classes and prepopulate $scope.classes
         */
        ApplicationService.getClasses($scope.roleContext.ciprid)
        .then(function(res) {
            var createRole = $scope.roleContext.create || {};
            var categories = _.map(createRole.inscat, 'code');
            $scope.classes = _.filter(res.data, function(licenseclass) {
                return _.includes(categories, licenseclass['insurancecategorycode']);
            });
            $scope.class = applicationAgencyObj ? _.find($scope.classes, {licenseclass: applicationAgencyObj.licClass}) : {};
            ApplicationService.setClasses($scope.classes);
            $scope.updateClasses();
        });

        ApplicationService.getDRLicenses($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.drLicenses = res.data;
        });

        $scope.updateClasses = function() {
            if($scope.category == 'person') {
                $scope.classes = _.filter(ApplicationService.classes, function(licenseclass) {
                    return _.includes(["PERSON", "OTHER"], licenseclass['onlinegroup']);
                });
            } else {
                $scope.classes = _.filter(ApplicationService.classes, function(licenseclass) {
                    return _.includes(["RESTRICTED"], licenseclass['onlinegroup']);
                });
            }
        };

        /**
         * Fetch unpaid invoices and prepopulate $scope.unpaidInvoices
         */
        InvoiceService.getUnpaidInvoices($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.unpaidInvoices.push({
                'invoiceid': 'new'
            });
            $scope.unpaidInvoices = $scope.unpaidInvoices.concat(res.data);
        });

        // --- Watchers ---

        if($scope.isAgency()) {
            $scope.agency = {
                agencyid: $scope.roleContext.partyid,
                agencyname: $scope.roleContext.name
            }
        }
        if(applicationAgencyObj) {
            $scope.agency = applicationAgencyObj.agency;
            $scope.category = applicationAgencyObj.category;
            $scope.sponsor = applicationAgencyObj.sponsor;
        }


        if($scope.isSponsor()) {
            $scope.sponsor = {
                sponsorid: $scope.roleContext.partyid,
                sponsorname: $scope.roleContext.name
            }
        }

        $scope.$watch('agency', function(newAgency) {
            if(!_.has(newAgency, 'agencyid')) return;

            $scope.sponsor = $scope.sponsor || {
                sponsorid: $scope.agency.sponsorid,
                sponsorname: $scope.agency.sponsorname
            };
            getCorpLicense();
            getReplaceLicense();
            getRestrictedTransferableLicenses();
        });

        $scope.$watch('sponsor', function(newSponsor) {
            if(!_.has(newSponsor, 'sponsorid')) return;
        });

        $scope.$watch('class', function(newClass) {
            if(!_.has(newClass, 'licenseclass')) return;

            $scope.isTransfer = 'N';
            if($scope.isAgency()) {
                ApplicationService.getSponsor($scope.class.licenseclass, $scope.agency.agencyid).then(function(res) {
                    var sponsor = res.data;
                    if(sponsor && sponsor[0]) $scope.sponsor = {
                        sponsorid: sponsor[0].spon_party_id,
                        sponsorname: sponsor[0].spon_nm
                    }
                });
            }
            $scope.transferableLicenses = [];
            getTransferableLicenses();
            getUpgradableLicenses();
            getDowngradableLicense();
            getCorpLicense();
            getReplaceLicense();
        });

        $scope.$watch('insureremployee', function(newInsureremployee) {
            if(newInsureremployee == 'SP') {
                $scope.initAgency(null, $scope.class);
            }
        });

        var getUpgradableLicenses = function() {
            if($scope.isPerson() && $scope.class.level) {
                ApplicationService.getUpgradableLicenses($scope.roleContext.ciprid, $scope.class).then(function(res) {
                    var upgradableLicenses = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, upgradableLicenses);
                });
            }
        };

        var getTransferableLicenses = function() {
            if($scope.isPerson() && !$scope.isRestrictedClass($scope.class)) {
                ApplicationService.getTransferableLicenses($scope.roleContext.ciprid, $scope.class.licenseclass).then(function(res) {
                    var transferableLicenses = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, transferableLicenses);
                });
            }
        };

        var getRestrictedTransferableLicenses = function() {
            if($scope.isPerson() && $scope.isRestrictedClass($scope.class) && $scope.agency.agencyid) {
                ApplicationService.getRestrictedTransferableLicenses($scope.roleContext.ciprid, $scope.class.licenseclass, $scope.agency.agencyid).then(function(res) {
                    $scope.transferableLicenses = res.data;
                });
            }
        };

        var getCorpLicense = function() {
            if($scope.isPerson() && $scope.class.isdrlicense === 'Y' && $scope.agency && $scope.agency.agencyid) {
                ApplicationService.getCorpLicense($scope.roleContext.ciprid, $scope.class.corp_liccls_cd, $scope.agency.agencyid).then(function(res) {
                    var corpLicense = res.data;
                    $scope.corpLicense = corpLicense && corpLicense[0] ? corpLicense[0] : {};
                });
            }
        };

        var getReplaceLicense = function() {
            $scope.isReplace = "N";
            $scope.replaceLicense = undefined;
            if($scope.isPerson() && $scope.class && ($scope.isRestrictedClass($scope.class) || $scope.class.isdrlicense === 'Y') && $scope.agency && $scope.agency.agencyid) {
                ApplicationService.getReplaceLicense($scope.roleContext.ciprid, $scope.class.licenseclass, $scope.agency.agencyid).then(function(res) {
                    var replacelicense = res.data;
                    $scope.replaceLicense = _.isArray(replacelicense) ? replacelicense[0] : replacelicense;
                    $scope.isReplace = !_.isEmpty($scope.replaceLicense) ? "Y" : "N";
                    $scope.isTransfer = $scope.isReplace === 'Y' ? 'N' : $scope.isTransfer;
                });
            }
        };

        var getDowngradableLicense = function() {
            if($scope.isPerson() && $scope.class && _.includes(['M', 'Q', 'G', 'I'], $scope.class.licenseclass.trim())) {
                ApplicationService.getDowngradableLicense($scope.roleContext.ciprid, $scope.class).then(function(res) {
                    var downgradableLicense = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, downgradableLicense);
                });
            }
        };

        // --- Called from the form ---

        /**
         * Reset the form
         */
        $scope.clear = function() {
            initForm();
        };

        /**
         * Clears invoice selection
         * @param $event
         */
        $scope.clearInvoice = function($event) {
            $event.stopPropagation();
            $scope.invoice = undefined;
        };

        /**
         * Used in typeahead for Agencies
         * @param phrase
         * @returns {*}
         */
        $scope.getAgencies = function(phrase, limit) {
            return ApplicationService.getAgencies($scope.class.licenseclass, phrase, limit, $scope.isRestrictedClass($scope.class) || $scope.class.isdrlicense === 'Y' ? 'N' : '');
        };

        /**
         * Used in typeahead for sponsors
         * @param phrase
         * @returns {*}
         */
        $scope.getSponsors = function(phrase, limit) {
            return ApplicationService.getSponsors($scope.class.licenseclass, phrase, limit);
        };

        /**
         * If invoice is selected, application is added to invoice
         * @param item
         * @param model
         */
        $scope.addToInvoice = function(item, model) {
            $scope.invoice = model;
        };

        /**
         * Set transferred from License
         * @param item
         * @param model
         */
        $scope.setTransferredFrom = function(item, model) {
            $scope.transferredFromLicense = model;
        };

        /**
         * Set Upgrade from License
         * @param item
         * @param model
         */
        $scope.setUpgradeFrom = function(item, model) {
            $scope.upgradeFromLicense = model;
        };

        /**
         * return whether the class is restricted
         * @returns {boolean}
         */
        $scope.isRestrictedClass = function(cls) {
            if(!cls) return false;
            return cls.licensefor === 'C' && cls.isorglicense == 'Y';
        };

        $scope.isDRClass = function(cls) {
            return ApplicationService.isDRClass(cls);
        };


        $scope.initAgency = function(legalName, cls) {
            store.set('application_agency_create', {
                "category": $scope.category,
                "sponsor": $scope.sponsor,
                "licClass": cls.licenseclass,
                "isExternal": true,
                "isRestricted": $scope.isRestrictedClass(cls),
                "isSoleProprietorship": $scope.insureremployee == 'SP'
            });
            $state.go('organizations.agency');
        };

        /**
         * Called by Agent to create application form
         */
        $scope.createApplication = function() {
            var ciprid = $scope.roleContext.ciprid;
            var licclass = $scope.class.licenseclass;
            var licensedescr = $scope.class.descr;
            var agencyid = $scope.agency ? $scope.agency.agencyid : '';
            var agencyname = $scope.agency ? $scope.agency.agencyname : '';
            var sponsorid = $scope.sponsor && $scope.class.sponsorrequired === 'Y' ? $scope.sponsor.sponsorid : null;
            var sponsorname = $scope.sponsor && $scope.class.sponsorrequired === 'Y' ? $scope.sponsor.sponsorname : null;
            var insureremployee = $scope.insureremployee;
            var expedited = $scope.expedited;
            var transferredFromLicenseId = $scope.isTransfer === 'Y' && $scope.transferredFromLicense ? $scope.transferredFromLicense.licenseid : null;
            transferredFromLicenseId = $scope.isReplace === 'Y' && $scope.replaceLicense ? $scope.replaceLicense.licenseid : transferredFromLicenseId;
            var isdrlicense = $scope.class.isdrlicense;
            var corpLicenseId = !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licenseid : null;
            var corpLicenseClass = $scope.class.corp_liccls_cd;
            var employeecountcd = $scope.isTransfer === 'Y' && $scope.transferredFromLicense ? $scope.transferredFromLicense.employeecountcd : null;
            employeecountcd = $scope.isReplace === 'Y' && $scope.replaceLicense ? $scope.replaceLicense.employeecountcd : employeecountcd;
            store.remove("application_agency_create");
            if($scope.agency && !agencyid) {
                toastr.error('Error!', 'The Agency does not exist in system.');
                return;
            }

            if($scope.sponsor && $scope.class.sponsorrequired == 'Y' && !sponsorid) {
                toastr.error('Error!', 'The Sponsor does not exist in system.');
                return;
            }

            var confirmoptions = {
                licensedescr: licensedescr,
                licenseholdername: $scope.roleContext.name,
                sponsorrequired: $scope.class.sponsorrequired,
                agencyname: agencyname,
                sponsorname: sponsorname,
                insureremployee: insureremployee,
                expedited: expedited,
                isdrlicense: $scope.class.isdrlicense,
                corpLicense: $scope.corpLicense
            };

            var onsuccess = function(res) {
                toastr.success('Success!', 'Application has been created.');
                $state.go('application.open.edit', {appId: res.data.licenseid});
            };
            var onerror = function() {
                $scope.createApplicationInProgress = false;
                toastr.error('Error!', 'Application cannot be created at this time.');
                $state.go('licenses.combine.list');
            };

            ApplicationService.checkParams(ciprid, licclass, agencyid, sponsorid, insureremployee, transferredFromLicenseId ? 'Y' : 'N')
            .then(function(res) {
                $scope.creationErrors = [];
                if(!_.isEmpty(res.data)) {
                    $scope.creationErrors = res.data;
                    return;
                }

                dialogs.create('applications/dialogs/init.html', 'InitDialogCtrl', confirmoptions, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function() {
                    $scope.createApplicationInProgress = true;
                    ApplicationService.create(ciprid, licclass, agencyid, sponsorid, insureremployee, null, transferredFromLicenseId, expedited, isdrlicense, corpLicenseId, corpLicenseClass, employeecountcd)
                    .then(onsuccess, onerror);
                });
            });
        };

        /**
         * Called by a 3rd party which results in an email to agent
         */
        $scope.initApplication = function() {
            var ciprid = $scope.roleContext.ciprid;
            var licHolderEmail = $scope.licHolderEmail;
            var licclass = $scope.class.licenseclass;
            var licensedescr = $scope.class.descr;
            var agencyid = $scope.agency ? $scope.agency.agencyid : '';
            var agencyname = $scope.agency ? $scope.agency.agencyname : '';
            var sponsorid = $scope.sponsor && $scope.class.sponsorrequired == 'Y' ? $scope.sponsor.sponsorid : '';
            var sponsorname = $scope.sponsor && $scope.class.sponsorrequired == 'Y' ? $scope.sponsor.sponsorname : '';
            var insureremployee = $scope.insureremployee;
            var expedited = $scope.expedited;
            var paidby = $scope.paidby.value;
            var invoiceid = $scope.invoice && $scope.invoice.invoiceid;

            var confirmoptions = {
                licensedescr: licensedescr,
                licenseholderemail: $scope.licHolderEmail,
                sponsorrequired: $scope.class.sponsorrequired,
                agencyname: agencyname,
                sponsorname: sponsorname,
                insureremployee: insureremployee,
                paidby: $scope.paidby.label,
                expedited: expedited
            };
            var onsuccess = function() {
                toastr.success('Success!', 'Application has been created. A link to the application was sent to ' + licHolderEmail);
                $state.go('licenses.combine.list');
            };

            var onerror = function(data, status) {
                toastr.error('Error!', 'There was a problem initiating application. Please try again.');
            };

            ApplicationService.checkParams(ciprid, licclass, agencyid, sponsorid, insureremployee)
            .then(function(res) {
                $scope.creationErrors = [];
                if(!_.isEmpty(res.data)) {
                    $scope.creationErrors = res.data;
                    return;
                }

                dialogs.create('applications/dialogs/initfor.html', 'InitDialogCtrl', confirmoptions, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function() {
                    ApplicationService.init(ciprid, licHolderEmail, licclass, agencyid, sponsorid, insureremployee, paidby, invoiceid, expedited)
                    .then(onsuccess, onerror);
                });
            });
        };

        $scope.isEmptyDRLicense = function(cls) {
            return _.isEmpty($scope.drLicenses) || !_.includes(_.map($scope.drLicenses, "insuranceclass"), cls.insclass.trim());
        };

        $scope.isGeneralOrAdjusterLicense = function() {
            return _.includes(['C', 'D'], $scope.class.insclass.trim());
        }
    }
])
.controller('ApplicationInitiatedCtrl', ['$scope', '$state', '$http', '$stateParams', 'toastr', 'ApplicationService', 'InvoiceService',
    function($scope, $state, $http, $stateParams, toastr, ApplicationService, InvoiceService) {
        $scope.classes = [];
        $scope.sponsors = [];

        $scope.class = {};
        $scope.agency = undefined;
        $scope.sponsor = undefined;
        $scope.person = undefined;
        $scope.insureremployee = undefined;
        $scope.invoiceid = undefined;
        $scope.paidby = $stateParams.paidby;
        $scope.creationErrors = [];
        $scope.transferableLicenses = [];
        $scope.isTransfer = 'N';
        $scope.insureremployee = undefined;
        $scope.corpLicense = {};
        $scope.expedited = $scope.profile.address.province === 'Alberta' ? 'N' : $stateParams.expedited;
        // --- Initializers ---

        var licenseClass = $stateParams.agencyid && $stateParams.licclass in revLicClassMap ? revLicClassMap[$stateParams.licclass] : $stateParams.licclass;

        if($stateParams.sponsorid) {
            ApplicationService.getParty($stateParams.sponsorid)
            .then(function(res) {
                $scope.sponsor = {sponsorid: $stateParams.sponsorid, sponsorname: res.data[0].party_nm}
            });
        }

        if($stateParams.agencyid) {
            ApplicationService.getParty($stateParams.agencyid)
            .then(function(res) {
                $scope.agency = {agencyid: $stateParams.agencyid, agencyname: res.data[0].party_nm};
                getCorpLicense();
            });
        }

        if($stateParams.invoiceid) {
            $scope.invoiceid = $stateParams.invoiceid;
        }

        if($stateParams.insureremployee) {
            $scope.insureremployee = $stateParams.insureremployee;
        }

        ApplicationService.getClasses($scope.roleContext.ciprid)
        .then(function(res) {
            var categories = _.map($scope.roleContext.create.inscat, 'description');
            $scope.classes = _.filter(res.data, function(licenseclass) {
                return _.includes(categories, licenseclass['insurancecategory']);
            });

            $scope.class = _.find($scope.classes, {licenseclass: licenseClass + " "});
            getTransferableLicenses();
            getUpgradableLicenses();
            getCorpLicense();
        });

        var getUpgradableLicenses = function() {
            if($scope.isPerson() && $scope.class.level) {
                ApplicationService.getUpgradableLicenses($scope.roleContext.ciprid, $scope.class).then(function(res) {
                    var upgradableLicenses = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, upgradableLicenses);
                });
            }
        };

        var getTransferableLicenses = function() {
            if($scope.isPerson()) {
                ApplicationService.getTransferableLicenses($scope.roleContext.ciprid, $scope.class.licenseclass).then(function(res) {
                    var transferableLicenses = res.data;
                    $scope.transferableLicenses = _.union($scope.transferableLicenses, transferableLicenses);
                });
            }
        };

        var getCorpLicense = function() {
            if($scope.isPerson() && $scope.class.isdrlicense === 'Y' && $scope.agency.agencyid) {
                ApplicationService.getCorpLicense($scope.roleContext.ciprid, $scope.class.corp_liccls_cd, $scope.agency.agencyid).then(function(res) {
                    $scope.corpLicense = res.data[0] ? res.data[0] : {};
                });
            }
        };

        // --- Called from the form ---

        /**
         * Called when agent accepts application initiated by 3rd party.
         */
        $scope.createApplication = function() {
            var ciprid = $scope.roleContext.ciprid;
            var licclass = $scope.class.licenseclass;
            var agencyid = $scope.agency ? $scope.agency.agencyid : '';
            var sponsorid = $scope.sponsor ? $scope.sponsor.sponsorid : '';
            var insureremployee = $scope.insureremployee;
            var paidby = $scope.paidby;
            var invoiceid = $scope.invoiceid;
            var expedited = $scope.expedited;
            var transferredFromLicenseId = $scope.isTransfer == 'Y' && $scope.transferredFromLicense ? $scope.transferredFromLicense.licenseid : null;
            transferredFromLicenseId = transferredFromLicenseId;
            var isdrlicense = $scope.class.isdrlicense;
            var corpLicenseId = !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licenseid : null;
            var corpLicenseClass = $scope.class.corp_liccls_cd;
            var employeecountcd = $scope.isTransfer === 'Y' && $scope.transferredFromLicense ? $scope.transferredFromLicense.employeecountcd : null;
            employeecountcd = $scope.isReplace === 'Y' && $scope.replaceLicense ? $scope.replaceLicense.employeecountcd : employeecountcd;

            if($scope.agency && !agencyid) {
                toastr.error('Error!', 'The Agency does not exist in system.');
                return;
            }

            if($scope.sponsor && $scope.class.sponsorrequired == 'Y' && !sponsorid) {
                toastr.error('Error!', 'The Sponsor does not exist in system.');
                return;
            }

            var onsuccess = function(res) {
                $state.go('application.open.edit', {appId: res.data.licenseid, paidby: paidby});
            };
            var onerror = function() {
                toastr.error('Error!', 'Application cannot be created at this time.');
                $state.go('licenses.combine.list');
            };

            ApplicationService.checkParams(ciprid, licclass, agencyid, sponsorid, insureremployee, transferredFromLicenseId ? 'Y' : 'N')
            .then(function(res) {
                $scope.creationErrors = [];
                if(_.isEmpty(res.data)) {
                    ApplicationService.create(ciprid, licclass, agencyid, sponsorid, insureremployee, invoiceid, transferredFromLicenseId, expedited, isdrlicense, corpLicenseId, corpLicenseClass, employeecountcd)
                    .then(onsuccess, onerror);
                } else {
                    $scope.creationErrors = res.data;
                }
            });
        };

        $scope.setTransferredFrom = function(item, model) {
            $scope.transferredFromLicense = model;
        };

        $scope.setUpgradeFrom = function(item, model) {
            $scope.upgradeFromLicense = model;
        };


    }
])
.controller('ApplicationOpenCtrl', ['$scope', '$state', '$stateParams', 'promisedLicense', 'promisedCorpLicense', 'toastr', 'ApplicationService',
    function($scope, $state, $stateParams, promisedLicense, promisedCorpLicense, toastr, ApplicationService) {

        $scope.license = promisedLicense;
        $scope.corpLicense = promisedCorpLicense;

        $scope.isInvoiced = function(license) {
            return !!license.invoiceid || !!license.payableid;
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) <= 0;
        };

        $scope.isWithCandidate = function(license) {
            return ApplicationService.isWithCandidate(license);
        };

        $scope.isWithApprover = function(license) {
            return ApplicationService.isWithApprover(license);
        };

        $scope.isWithRegulator = function(license) {
            return ApplicationService.isWithRegulator(license);
        };

        $scope.isSentBackFromRegulator = function(license) {
            return ApplicationService.isSentBackFromRegulator(license);
        };

        $scope.isCorpApp = function(license) {
            return ApplicationService.isCorpApp(license);
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isDRApp = function(license) {
            return ApplicationService.isDRApp(license);
        };

        $scope.isCorpLicenseExists = function(corpLicense) {
            return ApplicationService.isCorpLicenseExists(corpLicense);
        };

        $scope.isApplication = function(license) {
            return ApplicationService.isApplication(license);
        };

        $scope.isRecommendationRequired = function(license) {
            return $scope.isStaff() && license.recommendations && license.recommendations.length > 0
        };

        $scope.isDeclarationRequired = function(license) {
            return $scope.isStaff() && license.declarations && license.declarations.length > 0
        };

        $scope.can = {
            address: {
                view: true,
                edit: $scope.isPerson()
            },
            employment: {
                view: true,
                edit: $scope.isPerson()
            },
            eando: {
                view: true,
                edit: $scope.isPerson()
            },
            questions: {
                view: true,
                edit: $scope.isPerson()
            },
            declarations: {
                view: $scope.isPerson(),
                edit: $scope.isPerson()
            },
            recommendations: {
                view: $scope.license.permissions.recommendations.view,
                edit: $scope.license.permissions.recommendations.edit
            },
            approve: $scope.license.permissions.application.approve,
            sendbacktoagent: $scope.license.permissions.application.sendbacktoagent,
            notes: {
                view: false,
                edit: false
            },
            submit: {
                view: false,
                edit: false
            }
        };
    }
])
.controller('ApplicationEditCtrl', ['$scope', '$state', '$stateParams', '$location', '$anchorScroll', '$timeout', '$q', 'dialogs', 'toastr', 'blockUI', 'ApplicationService',
    function($scope, $state, $stateParams, $location, $anchorScroll, $timeout, $q, dialogs, toastr, blockUI, ApplicationService) {

        $scope.submitted = false;
        $scope.licenseValidationErrors = [];
        $scope.licenseToSave = _.cloneDeep($scope.license);
        $scope.corpLicenseToSave = _.cloneDeep($scope.corpLicense);
        var applicationBlock = blockUI.instances.get('application-edit-block');

        if($scope.license.licensetype === 'C') {
            toastr.warning('Warning!', 'Please open D/R Application associated with it.');
            $state.go('licenses.combine.list');
        }

        if(!$scope.isPerson() || !ApplicationService.isWithCandidate($scope.license)) {
            $state.go('application.open.view', {appId: $scope.license.licenseid});
        }

        /**
         * Show EO form if license.eorequired is "Y"
         */
        $scope.isEORequired = function() {
            return $scope.license.eorequired == "Y";
        };

        /**
         * Called when 'Delete' is clicked. Abandons application.
         * @param license
         */
        $scope.deleteApplication = function(license) {
            var copy = {
                license: license.license,
                licensedescr: license.licensedescr,
                corplicense: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.license : '',
                corplicensedescr: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licensedescr : ''
            };
            dialogs.create('applications/dialogs/delete.html', 'DeleteDialogCtrl', copy, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                if(ApplicationService.isDRApp(license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                    ApplicationService.discard($scope.roleContext.ciprid, license.licenseid)
                    .then(function() {
                        ApplicationService.discard($scope.roleContext.ciprid, $scope.corpLicense.licenseid)
                        .then(function(res) {
                            toastr.success('Success!', 'Applications ' + license.license + ' and ' + $scope.corpLicense.license + ' has been deleted.');
                            if($scope.isPerson())
                                $state.go('licenses.combine.list');
                            else
                                $state.go('application.list');
                        })
                    });
                } else {
                    ApplicationService.discard($scope.roleContext.ciprid, license.licenseid)
                    .then(function(data) {
                        toastr.success('Success!', 'Application ' + license.license + ' has been deleted.');
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    });
                }
            });
        };

        /**
         * Called when reset button is clicked. Reloads license.
         */
        $scope.resetApplication = function() {
            $state.go('.', {}, {reload: true});
            return $timeout(function() {
                toastr.success('Success!', 'Application ' + $scope.license.license + ' has been reset.');
            }, 1000);
        };

        /**
         * Saves application (even with errors)
         */
        $scope.saveApplication = function() {
            if(ApplicationService.isDRApp($scope.license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense))
                return saveDRCorpApplication();
            applicationBlock.start("Saving Application...");
            updateLicense($scope.licenseToSave);

            $scope.licenseToSave.declarations = [];

            ApplicationService.saveApplication($scope.roleContext.ciprid, $scope.licenseToSave)
            .then(function() {
                applicationBlock.stop();
                if($scope.licenseToSave.invoiceid) {
                    toastr.success('Saved', 'Application is saved and added to Invoice ' + $scope.license.invoiceid);
                    $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                } else {
                    dialogs.notify('Saved!', 'Application ' + $scope.license.license + ' has been saved.', {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    }).result.then(function(btn) {
                        $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                    });
                }
            });
        };

        function saveDRCorpApplication() {
            applicationBlock.start("Saving Applications...");
            updateLicense($scope.licenseToSave);

            //copy payment/invoice info
            $scope.corpLicense.paidby = $scope.license.paidby;
            $scope.corpLicense.invoice = $scope.license.invoice;

            // copy E&Os
            copyeandos($scope.license, $scope.corpLicense);


            updateCorpLicense($scope.corpLicenseToSave);
            $scope.licenseToSave.declarations = [];
            $scope.corpLicenseToSave.declarations = [];

            ApplicationService.saveApplication($scope.roleContext.ciprid, $scope.licenseToSave)
            .then(function() {
                ApplicationService.saveApplication($scope.roleContext.ciprid, $scope.corpLicenseToSave)
                .then(function() {
                    applicationBlock.stop();
                    if($scope.licenseToSave.invoiceid) {
                        toastr.success('Saved', 'Applications are saved and added to Invoice ' + $scope.license.invoiceid);
                        $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                    } else {
                        dialogs.notify('Saved!', 'Applications ' + $scope.license.license + ' and ' + $scope.corpLicense.license + ' has been saved.', {
                            'keyboard': false,
                            'backdrop': 'static',
                            'size': 'lg'
                        }).result.then(function(btn) {
                            $state.go($state.current, {appId: $scope.license.licenseid}, {reload: true});
                        });
                    }
                })
            });

        }


        var hasErrors = function(validationErrors) {
            return !_.isEmpty(validationErrors.eandoErrors)
            || !_.isEmpty(validationErrors.questionErrors)
            || !_.isEmpty(validationErrors.occupationErrors)
            || !_.isEmpty(validationErrors.employmentErrors)
            || !_.isEmpty(validationErrors.addressErrors)
            || !_.isEmpty(validationErrors.crimcheckErrors)
            || !_.isEmpty(validationErrors.paymentErrors);
        };

        var scrollToErrors = function(validationErrors) {
            var scrollto = '';
            if(!_.isEmpty(validationErrors.addressErrors)) scrollto = 'address-portlet';
            else if(!_.isEmpty(validationErrors.eandoErrors)) scrollto = 'eo-portlet';
            else if(!_.isEmpty(validationErrors.employmentErrors)) scrollto = 'employment-portlet';
            else if(!_.isEmpty(validationErrors.questionErrors)) scrollto = 'questions-portlet';
            else if(!_.isEmpty(validationErrors.occupationErrors)) scrollto = 'questions-portlet';
            else if(!_.isEmpty(validationErrors.crimcheckErrors)) scrollto = 'crimcheck-portlet';
            else if(!_.isEmpty(validationErrors.paymentErrors)) scrollto = 'payment-portlet';

            $location.hash(scrollto);
            $anchorScroll();
        };

        /**
         * Submits application for approval.
         */


        $scope.submitApplication = function() {
            $scope.submitted = true;
            if(ApplicationService.isDRApp($scope.license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                return submitDRCorpApplication();
            }
            var copy = _.cloneDeep($scope.license);
            copy.questions = _.map(copy.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            ApplicationService.validateApplication($scope.roleContext.ciprid, copy)
            .then(function(res) {
                var validationErrors = res.data;
                if(hasErrors(validationErrors)) {
                    $scope.licenseValidationErrors = validationErrors;

                    var title = 'Incomplete Application';
                    var body = 'Your application is incomplete. Please enter missing information and submit your application again.';
                    var crimcheckError = "You are required to submit criminal record check. <br> "
                    if(!_.isEmpty(validationErrors.crimcheckErrors)) {
                        body = body + '<br><br>' + ' You are required to submit criminal record check'
                        body = body + '<br>' + 'Click <a target="_blank" href="https://pages.sterlingbackcheck.ca/landing-pages/a/aic/">here</a> for BackCheck';
                        body = body + '<br>' + 'For Manual upload click criminal record check link beside criminal record check status on security clearance section.';
                    }
                    dialogs.error(title, body, {'size': 'sm'}).result.then(function() {
                        scrollToErrors(validationErrors);
                    });
                } else {
                    dialogs.create('applications/dialogs/submit.html', 'SubmitDialogCtrl', $scope.licenseToSave.declarations, {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    }).result.then(function() {
                        applicationBlock.start("Submitting Application...");
                        updateLicense($scope.licenseToSave);
                        if($scope.licenseToSave.reviewstatuscd == 'LIC_REV_B2A' || ($scope.license.insurancecategory === 'ADJ' && (ApplicationService.isDRApp($scope.license) || $scope.license.issoleproprietor == 'Y'))) {
                            ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.licenseToSave)
                            .then(function() {
                                applicationBlock.stop();
                                toastr.success('Success!', 'Application ' + $scope.licenseToSave.license + ' has been submitted to regulator.');
                                if($scope.isPerson())
                                    $state.go('licenses.combine.list');
                                else
                                    $state.go('application.list');
                            });
                        } else {
                            ApplicationService.submitForApproval($scope.roleContext.ciprid, $scope.licenseToSave)
                            .then(function() {
                                applicationBlock.stop();
                                toastr.success('Success!', 'Application ' + $scope.licenseToSave.license + ' has been sent off for approval.');
                                if($scope.isPerson())
                                    $state.go('licenses.combine.list');
                                else
                                    $state.go('application.list');
                            });
                        }
                    });
                }
            });

        };

        var submitDRCorpApplication = function() {
            var copy = _.cloneDeep($scope.license);
            copy.questions = _.map(copy.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            // copy address
            // $scope.corpLicense.address = $scope.license.address;

            //copy payment/invoice info
            $scope.corpLicense.paidby = $scope.license.paidby;
            $scope.corpLicense.invoice = $scope.license.invoice;

            // copy E&Os
            copyeandos($scope.license, $scope.corpLicense);

            var copycorp = _.cloneDeep($scope.corpLicense);
            copycorp.questions = _.map(copycorp.questions, function(q) {
                return _.omit(q, 'uploader');
            });

            $q.all([ApplicationService.validateApplication($scope.roleContext.ciprid, copy), ApplicationService.validateApplication($scope.roleContext.ciprid, copycorp)])
            .then(function(results) {
                if(hasErrors(results[0].data) || hasErrors(results[1].data)) {
                    $scope.licenseValidationErrors = _.merge(results[0].data, results[1].data);

                    var title = 'Incomplete Application';
                    var body = 'Your application is incomplete. Please enter missing information and submit your application again.';
                    var crimcheckError = "You are required to submit criminal record check record. <br> "
                    if(!_.isEmpty(results[0].data.crimcheckErrors)) {
                        body = body + '<br><br>' + ' You are required to submit criminal record check'
                        body = body + '<br>' + 'Click <a target="_blank" href="https://pages.sterlingbackcheck.ca/landing-pages/a/aic/">here</a> for BackCheck';
                        body = body + '<br>' + 'For Manual upload click criminal record check link beside criminal record check status on security clearance section.';
                    }
                    dialogs.error(title, body, {'size': 'sm'}).result.then(function() {
                        scrollToErrors(_.merge(results[0].data, results[1].data));
                    });
                } else {
                    dialogs.create('applications/dialogs/submit.html', 'SubmitDialogCtrl', ($scope.licenseToSave.declarations).concat($scope.corpLicenseToSave.declarations), {
                        'keyboard': false,
                        'backdrop': 'static',
                        'size': 'lg'
                    }).result.then(function() {
                        applicationBlock.start("Submitting Applications...");
                        updateLicense($scope.licenseToSave);

                        updateCorpLicense($scope.corpLicenseToSave);
                        if($scope.licenseToSave.reviewstatuscd == 'LIC_REV_B2A' || (ApplicationService.isDRApp($scope.license) && $scope.license.insurancecategory === 'ADJ')) {
                            ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.licenseToSave)
                            .then(function() {
                                ApplicationService.submitToRegulator($scope.roleContext.ciprid, $scope.corpLicenseToSave)
                                .then(function() {
                                    applicationBlock.stop();
                                    toastr.success('Success!', 'Application ' + $scope.licenseToSave.license + ' has been submitted to regulator.');
                                    if($scope.isPerson())
                                        $state.go('licenses.combine.list');
                                    else
                                        $state.go('application.list');
                                });
                            });

                        } else {

                            ApplicationService.submitForApproval($scope.roleContext.ciprid, $scope.licenseToSave)
                            .then(function() {
                                ApplicationService.submitForApproval($scope.roleContext.ciprid, $scope.corpLicenseToSave)
                                .then(function() {
                                    applicationBlock.stop();
                                    toastr.success('Success!', 'Applications ' + $scope.licenseToSave.license + ' and ' + $scope.corpLicenseToSave.license + ' has been sent off for approval.');
                                    if($scope.isPerson())
                                        $state.go('licenses.combine.list');
                                    else
                                        $state.go('application.list');
                                });
                            });
                        }
                    });
                }


            });

        };

        function copyeandos(license, corpLicense) {
            var deleteclause = function(eando) {
                return _.has(eando, 'deleted') ? eando.deleted == true : false;
            };
            var deletedEandos = _.chain(license.eandos).filter(deleteclause).value();
            var newclause = function(eando) {
                return _.has(eando, 'new') ? eando.new == true : false;
            };
            var newEandos = _.chain(license.eandos).filter(newclause).value();

            _.forEach(deletedEandos, function(eando) {
                var e = _.find(corpLicense.eandos, {policyno: eando.policyno, carrierpartyid: eando.carrierpartyid});
                e.deleted = eando.deleted;
            });

            _.forEach(newEandos, function(eando) {
                var copyeando = _.cloneDeep(eando);
                copyeando.licenseid = $scope.corpLicense.licenseid;
                corpLicense.eandos.push(copyeando);
            });

        };

        function updateLicense(licenseToSave) {
            licenseToSave.address = $scope.license.address;

            // get the list of updated questions and declarations and empl history
            licenseToSave.questions = _.filter($scope.license.questions, function(obj) {
                return obj.answer === '' || !_.find(licenseToSave.questions, _.omit(obj, '$$hashKey'));
            });
            licenseToSave.questions = _.map(licenseToSave.questions, function(q) {
                return _.omit(q, 'uploader');
            });
            licenseToSave.employmentHistory = _.filter($scope.license.employmentHistory, function(obj) {
                return !_.find(licenseToSave.employmentHistory, _.omit(obj, '$$hashKey'));
            });
            licenseToSave.eandos = _.filter($scope.license.eandos, function(obj) {
                return !_.find(licenseToSave.eandos, _.omit(obj, '$$hashKey'));
            });
            // get other occupation question
            var otherOccupaQuestion = _.filter($scope.license.questions, {hasotheroccupation: 'Y'});
            licenseToSave.occupations = [];
            if(otherOccupaQuestion && otherOccupaQuestion[0] && otherOccupaQuestion[0].answer != 'N') {
                licenseToSave.occupations = _.filter($scope.license.occupations, {selected: true});
            }
            _.forEach(licenseToSave.occupations, function(occupation) {
                occupation.answerseq = otherOccupaQuestion[0].answerid;
            });

            licenseToSave.fee = $scope.license.fee;
            licenseToSave.paidby = $scope.license.paidby;
            licenseToSave.employeecountcd = $scope.license.employeecountcd;
        }

        function updateCorpLicense(corpLicenseToSave) {
            corpLicenseToSave.address = $scope.corpLicense.address;
            // get the list of updated questions and declarations and empl history
            corpLicenseToSave.questions = _.filter($scope.corpLicense.questions, function(obj) {
                return obj.answer === '' || !_.find(corpLicenseToSave.questions, _.omit(obj, '$$hashKey'));
            });
            corpLicenseToSave.questions = _.map(corpLicenseToSave.questions, function(q) {
                return _.omit(q, 'uploader');
            });

            corpLicenseToSave.eandos = _.filter($scope.corpLicense.eandos, function(obj) {
                return !_.find(corpLicenseToSave.eandos, _.omit(obj, '$$hashKey'));
            });

            // get other occupation question
            var otherOccupaQuestion = _.filter($scope.corpLicense.questions, {hasotheroccupation: 'Y'});
            corpLicenseToSave.occupations = [];
            if(otherOccupaQuestion && otherOccupaQuestion[0] && otherOccupaQuestion[0].answer != 'N') {
                corpLicenseToSave.occupations = _.filter($scope.corpLicense.occupations, {selected: true});
            }
            _.forEach(corpLicenseToSave.occupations, function(occupation) {
                occupation.answerseq = otherOccupaQuestion[0].answerid;
            });

            corpLicenseToSave.fee = $scope.corpLicense.fee;
            corpLicenseToSave.paidby = $scope.corpLicense.paidby;
            corpLicenseToSave.drPartyId = $scope.license.licenseholderid;
        }
    }
])
.controller('ApplicationViewCtrl', ['$scope', '$state', '$stateParams', '$q', 'dialogs', 'toastr', 'blockUI', 'ApplicationService',
    function($scope, $state, $stateParams, $q, dialogs, toastr, blockUI, ApplicationService) {
        if($scope.license.licensetype === 'C') {
            toastr.warning('Warning!', 'Please open D/R Application associated with it.');
            if($scope.isPerson())
                $state.go('licenses.combine.list');
            else
                $state.go('application.list');
        }
        var applicationBlock = blockUI.instances.get('application-view-block');
        /**
         * Called when 'Decline' button is clicked. Presents a dialog.
         * @param license
         */
        $scope.declineApplication = function(license) {
            var copy = {
                license: license.license,
                licensedescr: license.licensedescr,
                licenseholdername: license.licenseholdername,
                corplicense: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.license : '',
                corplicensedescr: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licensedescr : '',
                corplicenseholdername: !_.isEmpty($scope.corpLicense) ? $scope.corpLicense.licenseholdername : ''
            };
            dialogs.create('applications/dialogs/decline.html', 'DeclineDialogCtrl', copy, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(reason) {
                applicationBlock.start();
                if(ApplicationService.isDRApp(license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                    $q.all([ApplicationService.declineApplication($scope.roleContext.ciprid, license, reason), ApplicationService.declineApplication($scope.roleContext.ciprid, $scope.corpLicense, reason)])
                    .then(function(results) {
                        applicationBlock.stop();
                        toastr.success('Success!', 'Application(s) ' + license.license + ' and ' + $scope.corpLicense.license + ' has been declined.');
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    }, function() {
                        applicationBlock.stop();
                        toastr.warning('Success but ...', 'Application(s) ' + license.license + ' and ' + $scope.corpLicense.license + ' has been declined. However, agent could not be notified via email.');
                        $state.go('application.list');
                    })
                } else {
                    ApplicationService.declineApplication($scope.roleContext.ciprid, license, reason)
                    .then(function(reply) {
                        applicationBlock.stop();
                        toastr.success('Success!', 'Application ' + license.license + ' has been declined.');
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    }, function(reply) {
                        applicationBlock.stop();
                        toastr.warning('Success but ...', 'Application ' + license.license + ' has been declined. However, agent could not be notified via email.');
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    });
                }

            });
        };

        /**
         * Called when 'Approve' button is clicked. Presents a dialog of recommendations.
         * @param license
         */
        $scope.approveApplication = function(license) {
            if(ApplicationService.isDRApp(license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                dialogs.create('applications/dialogs/approve.html', 'ApproveDialogCtrl', _.uniqBy((license.recommendations).concat($scope.corpLicense.recommendations), 'declarationtext'), {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(approved) {
                    applicationBlock.start();
                    $q.all([ApplicationService.approveApplication($scope.roleContext.ciprid, license), ApplicationService.approveApplication($scope.roleContext.ciprid, $scope.corpLicense)])
                    .then(function(results) {
                        applicationBlock.stop();
                        toastr.success('Success!', 'Application(s) ' + license.license + ' and ' + $scope.corpLicense.license + ' has been approved.');
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    });
                });
            } else {
                dialogs.create('applications/dialogs/approve.html', 'ApproveDialogCtrl', license.recommendations, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(approved) {
                    applicationBlock.start();
                    ApplicationService.approveApplication($scope.roleContext.ciprid, license)
                    .then(function(data) {
                        applicationBlock.stop();
                        toastr.success('Success!', 'Application ' + license.license + ' has been approved.');
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    });
                });
            }
        };

        $scope.canSponsorTakeAction = function(license) {
            return !$scope.isSponsor() || !_.includes(['H', 'I', 'J', 'PE', 'V1'], license.licenseclass.trim());
        };

        /**
         * Sends application to agent. A dialog is presented asking for a reason.
         */
        $scope.sendApplicationToAgent = function() {
            if(ApplicationService.isDRApp($scope.license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                dialogs.create('applications/dialogs/sendback.html', 'SendBackDialogCtrl', $scope.license, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(reason) {
                    applicationBlock.start();
                    $q.all([ApplicationService.sendApplicationToAgent($scope.roleContext.ciprid, $scope.license, reason), ApplicationService.sendApplicationToAgent($scope.roleContext.ciprid, $scope.corpLicense, reason)])
                    .then(function(results) {
                        applicationBlock.stop();
                        toastr.success('Success!', 'Application(s) ' + $scope.license.license + ' and ' + $scope.corpLicense.license + ' has been sent back to ' + $scope.license.licenseholdername);
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    })
                });
            } else {
                dialogs.create('applications/dialogs/sendback.html', 'SendBackDialogCtrl', $scope.license, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(reason) {
                    applicationBlock.start();
                    ApplicationService.sendApplicationToAgent($scope.roleContext.ciprid, $scope.license, reason)
                    .then(function(data) {
                        applicationBlock.stop();
                        toastr.success('Success!', 'Application ' + $scope.license.license + ' has been sent back to ' + $scope.license.licenseholdername);
                        if($scope.isPerson())
                            $state.go('licenses.combine.list');
                        else
                            $state.go('application.list');
                    });
                });
            }

        };

        $scope.updateReadyforFinalApproval = function() {
            var onsuccess = function() {
                applicationBlock.stop();
                toastr.success('Success!', 'Application set ready for approval.');
            };
            var onerror = function() {
                applicationBlock.stop();
                toastr.error('Error!', 'Application cannot be set ready for approval at this time.');
            };

            if(ApplicationService.isDRApp($scope.license) && ApplicationService.isCorpLicenseExists($scope.corpLicense) && ApplicationService.isApplication($scope.corpLicense)) {
                applicationBlock.start();
                $scope.corpLicense.readyforfinalapproval = $scope.license.readyforfinalapproval;
                $q.all([ApplicationService.updateReadyforFinalApproval($scope.roleContext.ciprid, $scope.license), ApplicationService.updateReadyforFinalApproval($scope.roleContext.ciprid, $scope.corpLicense)])
                .then(function(results) {
                    applicationBlock.stop();
                    toastr.success('Success!', 'Applications set ready for approval.');
                }, function() {
                    applicationBlock.stop();
                    toastr.error('Error!', 'Applications cannot be set ready for approval at this time.');
                })
            } else {
                applicationBlock.start();
                ApplicationService.updateReadyforFinalApproval($scope.roleContext.ciprid, $scope.license)
                .then(onsuccess)
                .error(onerror);
            }


        };

        $scope.updateInsurerEmployeeStatus = function() {
            if($scope.license.insureremployee == 'N') {
                //send application back to the agent
                dialogs.create('applications/dialogs/insureremployeechange.html', 'InsurerEmployeeChangeDialogCtrl', $scope.license, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                })
                .result.then(function(reason) {
                    applicationBlock.start();
                    ApplicationService.updateInsurerEmployee($scope.roleContext.ciprid, $scope.license)
                    .then(function(data) {
                        ApplicationService.sendApplicationToAgent($scope.roleContext.ciprid, $scope.license, reason)
                        .then(function(data) {
                            applicationBlock.stop();
                            toastr.success('Success!', 'Application ' + $scope.license.license + ' has been sent back to ' + $scope.license.licenseholdername);
                            if($scope.isPerson())
                                $state.go('licenses.combine.list');
                            else
                                $state.go('application.list');
                        });
                    });
                }, function() {
                    $scope.license.insureremployee = 'Y';
                });

            } else {
                ApplicationService.updateInsurerEmployee($scope.roleContext.ciprid, $scope.license);
            }
        };
    }
])

/**
 * Template controllers
 */
.controller('ApplicationHeaderCtrl', ['$scope', '$state', '$stateParams', 'ApplicationService',
    function($scope, $state, $stateParams, ApplicationService) {
        /**
         * Initializers
         */
        ApplicationService.getLicenseFeeStructure()
        .then(function(res) {
            $scope.feeStructure = res.data;
            $scope.license.employeecountcd = _.find($scope.feeStructure, {amount: $scope.license.fee});
        });

        $scope.isAllowUpdateRestrictedApplicationFee = function(license) {
            return ApplicationService.isRestrictedCorpApp(license) && license.c_app_trn_status === 'New Application' && !$scope.isApplicationPaid(license) && !ApplicationService.isInvoiced(license) && (ApplicationService.isWithCandidate(license) || ApplicationService.isIncomplete(license));
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isApplicationPaid = function(license) {
            return parseFloat(license.feebalance) == 0;
        };

        /**
         * In case of restricted license, set fee using scenario code.
         */
        $scope.setRestrictedLicenseFee = function(license) {
            license.fee = license.employeecountcd.amount;
        };

    }
])
.controller('ApplicationEandOCtrl', ['$scope', '$state', '$http', 'dialogs', 'EAndOService',
    function($scope, $state, $http, dialogs, EAndOService) {

        /**
         * Presents a dialog to add a new policy
         */
        $scope.addPolicy = function() {
            var data = {
                title: 'Add Errors and Omissions Coverage to the License: ' + $scope.license.license,
                insurancecategories: [$scope.license.insurancecategory]
            };
            dialogs.create('eando/dialogs/add.html', 'EandOAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                if(!$scope.license.eandos) $scope.license.eandos = [];
                eando.duplicate = EAndOService.checkIfDuplicate($scope.license.eandos, eando);
                $scope.license.eandos.push(eando);
            });
        };

        /**
         * Mark policy as 'Removed'
         * @param eando
         */
        $scope.removeEandO = function(eando) {
            eando.deleted = true;
            if(eando.new) {
                $scope.license.eandos = _.reject($scope.license.eandos, eando);
            }
        };

        /**
         * Returns eando coverage status
         * @param eando
         * @returns {boolean}
         */
        $scope.isActive = function(eando) {
            return eando.coveragestatus.trim() == 'Active';
        };

        $scope.isExpired = function(eando) {
            return eando.coveragestatus.trim() == 'Expired';
        };

        $scope.isPending = function(eando) {
            return eando.coveragestatus.trim() == 'Pending';
        };

    }
])
.controller('EmploymentHistoryCtrl', ['$scope', '$http', '$resource', '$filter',
    function($scope, $http, $resource, $filter) {

        $scope.employment = {};
        $scope.employment.employed = 'Y';
        $scope.employment.employer = '';
        $scope.employment.positionheld = '';
        $scope.employment.fromdate = '';
        $scope.employment.todate = '';
        $scope.dateFormat = 'MM dd, yyyy';

        $scope.employmentForm = false;

        $scope.remove = function(employment) {
            employment.deleted = true;
            if(!employment.partyemploymentid) {
                $scope.license.employmentHistory = _.reject($scope.license.employmentHistory, employment);
            }
        };

        $scope.save = function() {
            $scope.employment.fromdate = $filter('date')($scope.employment.fromdate, "yyyy-MM-dd");
            $scope.employment.todate = $scope.employment.todate ? $filter('date')($scope.employment.todate, "yyyy-MM-dd") : null;

            if(!$scope.license.employmentHistory) $scope.license.employmentHistory = [];
            $scope.license.employmentHistory.push($scope.employment);
            $scope.hideEmploymentForm();
        };

        $scope.update = function(employment) {
            employment.fromdate = $filter('date')(employment.fromdate, "yyyy-MM-dd");
            employment.todate = employment.todate ? $filter('date')(employment.todate, "yyyy-MM-dd") : null;

            $scope.license.employmentHistory = _.reject($scope.license.employmentHistory, {'partyemploymentid': employment.partyemploymentid});
            $scope.license.employmentHistory.push(employment);
        };

        $scope.showEmploymentForm = function() {
            $scope.employmentForm = true;
        };

        $scope.hideEmploymentForm = function() {
            $scope.employmentForm = false;
            $scope.employment = {};
            $scope.employment.fromdate = '';
            $scope.employment.todate = '';
        };
    }
])
.controller('CriminalCheckCtrl', ['$scope', '$state', '$stateParams', '$http', 'toastr', 'ApplicationService', 'DownloadService',
    function($scope, $state, $stateParams, $http, toastr, ApplicationService, DownloadService) {
        /**
         * Constructs a download link
         * @param crimcheck
         * @returns {string}
         */
        $scope.downloadLink = function(crimcheck) {
            var anchorText = crimcheck.displayname;
            var anchorHref = S("/{{ciprid}}/crimcheck/{{crimcheckid}}/download/{{filename}}?party={{partyid}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: crimcheck.partyid,
                crimcheckid: crimcheck.id,
                filename: crimcheck.displayname
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };
    }
])
.controller('QuestionsCtrl', ['$scope', '$state', '$stateParams', '$http', 'toastr', 'ApplicationService', 'DownloadService',
    function($scope, $state, $stateParams, $http, toastr, ApplicationService, DownloadService) {
        $scope.defaultOccupationDetails = function(occupation) {
            if(occupation.detailsrequired == 'Y') {
                ApplicationService.getPrevOtherOccupaDetail($scope.roleContext.ciprid, occupation)
                .then(function(res) {
                    if(!_.isEmpty(res.data)) {
                        occupation.details = res.data[0].details;
                    }
                });
            }
        };

        /**
         * Constructs a download link.
         * @param license
         * @param question
         * @param attachment
         * @returns {string}
         */
        $scope.downloadLink = function(license, question, attachment) {
            var anchorText = attachment.displayname;
            var anchorHref = S("/{{ciprid}}/licenses/{{licenseid}}/attachments/{{filename}}?questionnum={{questionnum}}&party={{partyid}}&questionpart={{questionpart}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: license.licenseholderid,
                licenseid: license.licenseid,
                filename: attachment.displayname,
                questionnum: question.questionnum,
                questionpart: question.questionpart
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };


        $scope.downloadCorpLink = function(license, question, attachment, licenseholderid) {
            var anchorText = attachment.displayname;
            var anchorHref = S("/{{ciprid}}/licenses/{{licenseid}}/attachments/{{filename}}?questionnum={{questionnum}}&party={{partyid}}&questionpart={{questionpart}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: licenseholderid,
                licenseid: license.licenseid,
                filename: attachment.displayname,
                questionnum: question.questionnum,
                questionpart: question.questionpart
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        /**
         * Deletes an attachment
         * @param attachment
         */
        $scope.removeAttachment = function(question, attachment) {
            var ciprid = $scope.roleContext.ciprid;
            var licenseid = $scope.license.licenseid;
            var attachmentid = attachment.attachmentid;
            $http.delete('/' + ciprid + '/licenses/' + licenseid + '/attachments/' + attachmentid)
            .then(function(res) {
                toastr.success('Success!', 'File ' + attachment.displayname + ' has been removed.');
                $scope.license.attachments[question.answerid] = _.reject($scope.license.attachments[question.answerid], {'attachmentid': attachmentid});
            });
        };
    }
])
.controller('ApplicationPaymentCtrl', ['$scope', 'dialogs', 'toastr', 'ApplicationService',
    function($scope, dialogs, toastr, ApplicationService) {

        $scope.paidby = '';
        // if(!ApplicationService.isInvoiced($scope.license) && ApplicationService.isWithCandidate($scope.license)) {
        //     $scope.paidby = 'agent';
        //     $scope.license.paidby = $scope.paidby;
        // }
        $scope.agentPays = function(license) {
            if(ApplicationService.isWithCandidate(license)) {
                $scope.paidby = 'agent';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.licenseholdername,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.agencyPays = function(license) {
            if(ApplicationService.isWithCandidate(license)) {
                $scope.paidby = 'agency';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.agencyname,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.sponsorPays = function(license) {
            if(ApplicationService.isWithCandidate(license)) {
                $scope.paidby = 'sponsor';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.sponsorname,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.isCorpApp = function(license) {
            return ApplicationService.isCorpApp(license);
        };

        $scope.isRestrictedCorpApp = function(license) {
            return ApplicationService.isRestrictedCorpApp(license);
        };

        $scope.isAgentPaying = function() {
            return $scope.paidby == 'agent';
        };

        $scope.isAgencyPaying = function() {
            return $scope.paidby == 'agency';
        };

        $scope.isSponsorPaying = function() {
            return $scope.paidby == 'sponsor';
        };

        $scope.isInvoicedToContextUser = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerciprid == $scope.roleContext.ciprid;
        };

        $scope.isOnAgentInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.licenseholderid;
        };

        $scope.isOnAgencyInvoice = function(application) {
            return (_.isObject(application.invoice) && application.invoice.ownerpartyid == application.agencyid) || (_.isObject(application.payable) && application.payable.ownerpartyid == application.agencyid);
        };

        $scope.isOnSponsorInvoice = function(application) {
            return (_.isObject(application.invoice) && application.invoice.ownerpartyid == application.sponsorid) || (_.isObject(application.payable) && application.payable.ownerpartyid == application.sponsorid);
        };

        $scope.isInvoiceQueued = function(application) {
            return application.invoice.statuscd == 'INVPRC';
        };

        function showConfirmDialog(payoptions) {
            dialogs.create('applications/dialogs/payment.html', 'PaymentDialogCtrl', payoptions, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                ApplicationService.savePaymentOption($scope.roleContext.ciprid, payoptions.licenseid)
                .then(function(data) {
                    toastr.success('Success!', 'Application ' + payoptions.license + ' is invoiced to ' + payoptions.name);
                    $state.go('application.open.view', {appId: payoptions.licenseid});
                });
            });
        }
    }
])
.controller('CommentsCtrl', ['$scope', 'dialogs', 'toastr', 'ApplicationService',
    function($scope, dialogs, toastr, ApplicationService) {

        $scope.paidby = '';

        $scope.agentPays = function(license) {
            if(ApplicationService.isWithCandidate(license)) {
                $scope.paidby = 'agent';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.licenseholdername,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.agencyPays = function(license) {
            if(ApplicationService.isWithCandidate(license)) {
                $scope.paidby = 'agency';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.agencyname,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.sponsorPays = function(license) {
            if(ApplicationService.isWithCandidate(license)) {
                $scope.paidby = 'sponsor';
                $scope.license.paidby = $scope.paidby;
            } else {
                showConfirmDialog({
                    name: license.sponsorname,
                    license: license.license,
                    licenseid: license.licenseid,
                    licensedescr: license.licensedescr,
                    licenseholdername: license.licenseholdername
                });
            }
        };

        $scope.isAgentPaying = function() {
            return $scope.paidby == 'agent';
        };

        $scope.isAgencyPaying = function() {
            return $scope.paidby == 'agency';
        };

        $scope.isSponsorPaying = function() {
            return $scope.paidby == 'sponsor';
        };

        $scope.isInvoicedToContextUser = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerciprid == $scope.roleContext.ciprid;
        };

        $scope.isOnAgentInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.licenseholderid;
        };

        $scope.isOnAgencyInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.agencyid;
        };

        $scope.isOnSponsorInvoice = function(application) {
            return _.isObject(application.invoice) && application.invoice.ownerpartyid == application.sponsorid;
        };

        function showConfirmDialog(payoptions) {
            dialogs.create('applications/dialogs/payment.html', 'PaymentDialogCtrl', payoptions, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {
                ApplicationService.savePaymentOption($scope.roleContext.ciprid, payoptions.licenseid)
                .then(function(data) {
                    toastr.success('Success!', 'Application ' + payoptions.license + ' is invoiced to ' + payoptions.name);
                    $state.go('application.open.view', {appId: payoptions.licenseid});
                });
            });
        }
    }
])

/**
 * Dialog and modal controllers
 */
.controller('InitDialogCtrl', ['$scope', '$uibModalInstance', 'data', 'ApplicationService',
    function($scope, $uibModalInstance, data, ApplicationService) {

        $scope.license = data;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])
.controller('SubmitDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.agreed = false;
        $scope.declarations = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.agreed = true;
            $uibModalInstance.close($scope.agreed);
        };
    }
])
.controller('ApproveDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.approved = false;
        $scope.recommendations = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $scope.approved = true;
            $uibModalInstance.close($scope.approved);
        };
    }
])
.controller('SendBackDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.license = data;
        $scope.dlg = {
            reason: ''
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.dlg.reason);
        };
    }
])
.controller('InsurerEmployeeChangeDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.license = data;
        $scope.dlg = {
            reason: ''
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close("You are not working directly for " + $scope.license.sponsorname + ". You need to provide required E & O coverage and resubmit the application.");
        };
    }
])
.controller('DeleteDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.license = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])
.controller('DeclineDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.license = data;
        $scope.dlg = {
            reason: ''
        };

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.dlg.reason);
        };
    }
])
.controller('PaymentDialogCtrl', ['$scope', '$uibModalInstance', 'data',
    function($scope, $uibModalInstance, data) {

        $scope.payoptions = data;

        $scope.no = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close();
        };
    }
])

/**
 * Application services
 */
.service('ApplicationResolverService', ['$resource', '$http', 'FileUploader', 'ApplicationService',
    function($resource, $http, FileUploader, ApplicationService) {

        /**
         * Returns a promise which resolves a license object.
         *
         * @param jwtToken
         * @param ciprid
         * @param licenseid
         * @returns {*}
         */
        this.get = function(jwtToken, ciprid, licenseid) {
            return $http.get('/' + ciprid + '/licenses/' + licenseid + '/application')
            .then(function(res) {
                var limitFilter = {
                    name: 'limitFilter',
                    fn: function() {
                        return this.queue.length < 10;
                    }
                };
                var pdfFilter = {
                    name: 'pdfFilter',
                    fn: function(item) {
                        return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
                    }
                };

                var license = res.data.license[0];

                license.address = ApplicationService.copyAddress(license);
                license.crimcheck = _.isArray(res.data.crimcheck) ? res.data.crimcheck[0] : res.data.crimcheck;
                license.displayCrimCheck = res.data.displaycrimcheck[0];
                license.questions = res.data.questions;
                license.questions = _.map(res.data.questions, function(q, index) {
                    q.index = index + 1;
                    q.uploader = new FileUploader({
                        autoUpload: true,
                        filters: [limitFilter, pdfFilter],
                        headers: {
                            Authorization: 'Bearer ' + jwtToken
                        }
                    });
                    return q;
                });

                license.attachments = _.groupBy(res.data.attachments, 'answerid');
                license.occupations = _.uniqBy(res.data.occupations, 'otheroccupationcode');
                license.declarations = res.data.declarations.length == 0 ? [] : res.data.declarations;
                license.recommendations = res.data.recommendations;
                license.employmentHistory = res.data.employmentHistory;
                license.eandos = res.data.eandos;
                license.invoice = _.isArray(res.data.invoice) ? res.data.invoice[0] : res.data.invoice;
                license.payable = _.isArray(res.data.payable) ? res.data.payable[0] : res.data.payable;

                license.permissions = {
                    application: {
                        approve: res.data.permissions.canapprove,
                        sendbacktoagent: res.data.permissions.canreview
                    },
                    recommendations: {
                        view: res.data.permissions.canapprove,
                        edit: res.data.permissions.canapprove
                    }
                };

                license.corpLicense = _.isArray(res.data.corpLicense) ? res.data.corpLicense[0] : res.data.corpLicense;

                _.forEach(license.occupations, function(occupation) {
                    occupation.selected = !_.isEmpty(occupation.answerseq);
                });

                console.log("ApplicationResolverService", license);
                return license;
            });
        };
    }
])
.service('ApplicationService', ['$resource', '$http',
    function($resource, $http) {
        this.classes = [];

        this.isIncomplete = function(license) {
            return license.appstatuscd == 'LAS_REG_PEND';
        };

        this.isWithCandidate = function(license) {
            return license.appstatuscd == 'LAS_CAND_INPUT';
        };

        this.isWithApprover = function(license) {
            return license.appstatuscd == 'LAS_APPROVER_REVIEW';
        };

        this.isWithRegulator = function(license) {
            return license.appstatuscd == 'LAS_REG_SUBMIT';
        };

        this.isOnlineApp = function(license) {
            return license.onlineapp == 'Y';
        };

        this.isSentBackFromRegulator = function(license) {
            return license.reviewstatuscd == "LIC_REV_B2A";
        };

        this.isCorpApp = function(license) {
            return license.licensetype == 'C' || license.licensetype == 'CO';
        };

        this.isDRApp = function(license) {
            return license.drlicense == 'Y';
        };

        this.isDRClass = function(cls){
            if(!cls) return false;
            return cls.isdrlicense === 'Y';
        };

        this.isCorpLicenseExists = function(corpLicense) {
            return corpLicense && corpLicense.licenseid && corpLicense.licensetype === "C";
        };

        this.isInvoiced = function(license) {
            return !!license.invoiceid || !!license.payableid;
        };

        this.isApplication = function(license) {
            return license.statuscd.trim() == 'KAPP';
        };

        this.isRestrictedCorpApp = function(license) {
            return license.licensetype == 'CO';
        };

        this.getClasses = function(ciprId) {
            return $http.get('/' + ciprId + '/licenses/classes');
        };

        this.getDRLicenses = function(ciprId, lholderid) {
            return $http.get('/' + ciprId + '/licenses/drlicenses', {
                params: {
                    lholderid: lholderid
                }
            });
        };

        this.setClasses = function(classes) {
            this.classes = classes;
        };

        this.getCities = function(phrase) {
            return $http.get('/address/cities', {
                params: {
                    name: phrase
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getProvinces = function(phrase) {
            return $http.get('/address/provinces', {
                params: {
                    name: phrase
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getCountries = function(phrase) {
            return $http.get('/address/countries', {
                params: {
                    name: phrase
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getParty = function(id) {
            return $http.get('/party/byid', {
                params: {
                    id: id
                }
            });
        };

        this.getSponsors = function(licClass, phrase, limit) {
            return $http.get('/lookup/license/companies/' + licClass, {
                params: {
                    name: phrase,
                    limit: limit
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getSponsor = function(licClass, agencyId) {
            return $http.get('/lookup/license/sponsor/' + licClass, {
                params: {
                    agencyid: agencyId
                }
            });
        };

        this.getAgencies = function(licClass, phrase, limit, strict) {
            return $http.get('/lookup/license/agencies/' + licClass, {
                params: {
                    name: phrase,
                    limit: limit,
                    strict: strict
                }
            }).then(function(res) {
                return _.isArray(res.data) ? res.data : [];
            });
        };

        this.getTransferableLicenses = function(ciprId, licClass, lholderid) {
            return $http.get('/' + ciprId + '/licenses/transferable', {
                params: {
                    licClass: licClass,
                    lholderid: lholderid
                }
            });
        };

        this.getRestrictedTransferableLicenses = function(ciprId, licClass, agencyid, lholderid) {
            return $http.get('/' + ciprId + '/licenses/restrictedTransferable', {
                params: {
                    agencyId: agencyid,
                    licClass: licClass,
                    lholderid: lholderid
                }
            });
        };

        this.getUpgradableLicenses = function(ciprId, cls, lholderid) {
            return $http.get('/' + ciprId + '/licenses/upgradable', {
                params: {
                    insClass: cls.insclass,
                    level: cls.level,
                    lholderid: lholderid
                }
            });
        };

        this.getCorpLicense = function(ciprId, cls, agencyid) {
            return $http.get('/' + ciprId + '/licenses/corp/' + agencyid + '/' + cls);
        };

        this.getReplaceLicense = function(ciprId, cls, agencyid, lholderid) {
            return $http.get('/' + ciprId + '/licenses/replace/' + agencyid + '/' + cls, {
                params: {
                    lholderid: lholderid
                }
            });
        };

        this.getDowngradableLicense = function(ciprId, cls, lholderid) {
            return $http.get('/' + ciprId + '/licenses/downgradable', {
                params: {
                    insClass: cls.insclass,
                    level: cls.level,
                    lholderid: lholderid
                }
            });
        };

        this.create = function(ciprId, licenseClass, agencyId, sponsorId, insureremployee, invoiceId, transferredFromLicenseId, expedited, isdrlicense, corpLicenseId, corpLicenseClass, employeecountcd) {
            if(agencyId && licenseClass in licClassMap) licenseClass = licClassMap[licenseClass];

            return $http.put('/' + ciprId + '/licenses', {
                licenseclass: licenseClass,
                agencyid: agencyId,
                sponsorid: sponsorId,
                insureremployee: insureremployee,
                invoiceid: invoiceId,
                transferredFromLicenseId: transferredFromLicenseId,
                expedited: expedited,
                isdrlicense: isdrlicense,
                corpLicenseId: corpLicenseId,
                corpLicenseClass: corpLicenseClass,
                employeecountcd: employeecountcd
            });
        };

        this.checkParams = function(ciprId, licenseClass, agencyId, sponsorId, insureremployee, isUpgradeOrTransfer, lholderid) {
            if(agencyId && licenseClass in licClassMap) licenseClass = licClassMap[licenseClass];

            return $http.get('/' + ciprId + '/licenses/checkParams', {
                params: {
                    licenseclass: licenseClass,
                    agencyid: agencyId,
                    sponsorid: sponsorId,
                    insureremployee: insureremployee,
                    isUpgradeOrTransfer: isUpgradeOrTransfer,
                    lholderid: lholderid
                }
            });
        };

        this.init = function(ciprId, licHolderEmail, licenseClass, agencyId, sponsorId, insureremployee, paidby, invoiceid, expedited) {
            if(agencyId && licenseClass in licClassMap) licenseClass = licClassMap[licenseClass];

            return $http.post('/' + ciprId + '/licenses/init', {
                licenseclass: licenseClass,
                agencyid: agencyId,
                sponsorid: sponsorId,
                insureremployee: insureremployee,
                licHolderEmail: licHolderEmail,
                paidby: paidby,
                invoiceid: invoiceid,
                expedited: expedited
            });
        };

        this.getApplications = function(ciprId) {
            return $http.get('/' + ciprId + '/licenses/applications', {cache: false});
        };

        this.discard = function(ciprId, licenseid) {
            return $http({method: 'DELETE', url: '/' + ciprId + '/licenses/' + licenseid});
        };

        this.discardDRCorp = function(ciprId, licenseid, corplicenseid) {
            return $http({
                method: 'DELETE',
                url: '/' + ciprId + '/licenses/drcorp',
                params: {licenseid: licenseid, corplicenseid: corplicenseid}
            });
        };

        this.saveApplication = function(ciprId, license) {
            return $http.post('/' + ciprId + '/licenses/' + license.licenseid, license);
        };

        this.saveDRCorpApplication = function(ciprId, licenses) {
            return $http({
                url: '/' + ciprId + '/licenses/drcorp',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {licenses: licenses}
            });
        };

        this.updateInsurerEmployee = function(ciprId, license) {
            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + "/insureremployee", {
                insureremployee: license.insureremployee
            });
        };

        this.updateReadyforFinalApproval = function(ciprId, license) {
            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + "/readyforfinalapproval", {
                readyforfinalapproval: license.readyforfinalapproval
            });
        };

        this.submitForApproval = function(ciprId, license) {

            //update the status
            license.submitTo = 'approver';

            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/submittoapprover', license);
        };

        this.submitToRegulator = function(ciprId, license) {

            //update the status
            license.submitTo = 'regulator';

            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/submittoregulator', license);
        };

        this.approveApplication = function(ciprId, license) {
            //update recommendations
            _.forEach(license.recommendations, function(recommendation) {
                recommendation.response = 'Y'
            });

            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/approve', license);
        };

        this.declineApplication = function(ciprId, license, reason) {
            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/decline', {
                reason: reason
            });
        };

        this.sendApplicationToAgent = function(ciprId, license, reason) {
            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/sendtoagent', {
                reason: reason
            });
        };

        this.savePaymentOption = function(ciprId, license, reason) {
            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/sendtoagent', {
                reason: reason
            });
        };

        this.validateApplication = function(ciprId, license) {
            return $http.post('/' + ciprId + '/licenses/' + license.licenseid + '/validate', license);
        };

        this.getPrevOtherOccupaDetail = function(ciprId, occupation) {
            return $http.get('/' + ciprId + '/occupation/latest', {
                params: {
                    occupatype: occupation.otheroccupationcode,
                    partyid: occupation.partyid
                }
            });
        };

        this.get = function(ciprId, licenseid) {
            return $http.get('/' + ciprId + '/licenses/' + licenseid + '/application');
        };

        this.copyAddress = function(license) {
            return _.pick(license, [
                'street', 'city', 'cityid', 'provabbrev', 'province', 'provinceid', 'provabbrev',
                'postalcode', 'country', 'countryid', 'phone', 'fax', 'email'
            ]);
        };

        this.getLicenseFeeStructure = function() {
            return $http({
                url: '/lookup/license/fee/structure',
                method: 'GET',
                cache: true,
                headers: {'Content-Type': 'application/json'}
            });
        };
    }
]);
