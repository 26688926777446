/**
 * Directives to use existing jQuery plugin within an Angular application.
 */
angular.module('aic.ng-jquery', [])

.factory('toastr', function() {
    return {
        success: function(title, text) {
            toastr.options.timeOut = 5000;
            toastr.options.closeButton = false;
            toastr.options.progressBar = true;
            toastr.success(text, title);
        },
        customSuccess: function(title, text, callback) {
            toastr.options.timeOut = 3000;
            toastr.options.closeButton = false;
            toastr.options.progressBar = true;
            toastr.success(text,title, {onHidden: callback});
        },
        error: function(title, text) {
            toastr.options.timeOut = 0;
            toastr.options.closeButton = true;
            toastr.options.progressBar = true;
            toastr.error(text, title);
        },
        warning: function(title, text) {
            toastr.options.timeOut = 5000;
            toastr.options.closeButton = false;
            toastr.options.progressBar = true;
            toastr.warning(text, title);
        }
    };
})

/**
 1. Example element usage:
 <jq-date-picker ng-model="courseCompletionDate"
 begin="courseBegin"
 end="courseEnd"
 format="dateFormat"
 on-select="setCourseCompletionDate">
 </jq-date-picker>
 2. Example component usage:
 <div class="input-group input-medium date date-picker" jq-datepicker="">
 <input type="text" class="form-control" readonly ng-model="birthday">
 <span class="input-group-btn">
 <button class="btn default" type="button"><i class="fa fa-calendar"></i></button>
 </span>
 </div>
 3. Example element usage:
 <input type="text" class="form-control" readonly ng-model="birthday" jq-datepicker="">
 4. Example datepicker options usage:
 <input type="text" class="form-control" readonly ng-model="birthday" jq-datepicker="{format: 'MM/dd/yyyy', autoclose: true}">
 */
.directive('jqDatePicker', function() {
    return {
        restrict: 'EA',
        require: '?ngModel',
        replace: true,
        scope: {
            ifEmpty: '@',
            begin: '=',
            end: '=',
            size: '=',
            format: '=',
            onSelect: '&',
            selection: '=',
            placeholder: '='
        },

        templateUrl: 'util/ng-jquery/datepicker.html',

        link: function(scope, element, attrs, ngModel) {
            var onSelect = scope.onSelect();

            element.datepicker({
                autoclose: true,
                format: scope.format,
                startDate: scope.begin,
                endDate: scope.end,
                todayHighlight: true
            }).on('changeDate', function(ev) {
                if(ngModel) {
                    ngModel.$setViewValue(ev.date);
                }
                onSelect && onSelect(ev.date);
                scope.$apply();
            }).on('hide', function(ev) {
                if(element.find('input').val() === '' || element.find('input').val() === null) {
                    element.find('input').val(scope.ifEmpty);
                }
            });

            scope.$watch('begin', function() {
                element.datepicker('setStartDate', scope.begin);
            });

            scope.$watch('end', function() {
                element.datepicker('setEndDate', scope.end);
            });

            scope.$watch('ifEmpty', function(value) {
                element.find('input').val(value)
            });

            attrs.$observe('selection', function(value) {
                element.find('input').val(value);
            });
        }
    };
})

/**
 * Captures enter key
 */
.directive('jqEnter', function() {
    return function(scope, element, attrs) {
        element.bind("keydown keypress", function(event) {
            var code = event.keyCode || event.which;
            if(code === 13) {
                scope.$apply(function() {
                    scope.$eval(attrs.jqEnter, {'event': event});
                });
                event.preventDefault();
            }
        });
    };

})

/**
 * Prints div content
 */
.directive('jqPrintDiv', function() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind("click", function(event) {
                $('#' + attrs.jqPrintDiv).print();
            });
        }
    };
});
