var refund = angular.module('aic.refund', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
refund.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;

        $stateProvider
        .state('refund', {
            parent: 'workspace',
            abstract: true,
            url: '/refund',
            templateUrl: 'staff/refund/refund.html'
        })
        .state('refund.search', {
            url: '/search',
            params: {
                cache: true
            },
            templateUrl: 'staff/refund/search.html',
            controller: 'RefundChequeSearchCtrl'
        });

    }
])
.controller('RefundAddDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data',
    function($scope, $http, $uibModalInstance, $filter, data) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.refund = {refundType: 'QUC', refundAccount: "UC_FEE_HOLD"};
        var ciprid = data.ciprid;
        $scope.validAmount = true;
        $scope.refundAccounts = data.refundAccounts;
        $scope.party = data.party;
        $scope.refund.refundDate = moment().format('MMMM DD, YYYY');
        $scope.setRefundDate = function(selectedDate) {
            $scope.refund.refundDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };
        $scope.getAccountBalance = function() {
            if(!$scope.refund.refundAccount)
                return $scope.refundAccountBalance = undefined;

            $http.get('/' + ciprid + '/account/balancebyaccntandparty',
            {
                params: {
                    accntCD: $scope.refund.refundAccount,
                    stkId: $scope.party.partyType == 'PERSON' ? $scope.party.partyid : null,
                    orgId: $scope.party.partyType == 'ORG' ? $scope.party.orgId : null
                }
            })
            .then(function(res) {
                $scope.refundAccountBalance = res.data[0];
                $scope.isValidRefund();
            });
        };

        $scope.getAccountBalance();
        $scope.setRefundAccount = function() {
            $scope.refund.refundAccount = _.find($scope.refundAccounts, function(racct) {
                return racct.type.trim() == $scope.refund.refundType.trim()
            }).account;
            $scope.getAccountBalance();
        };
        $scope.isValidRefund = function() {

            if($scope.refundAccountBalance.balance && $scope.refund.amount) {
                var validAmount = parseFloat($scope.refundAccountBalance.balance) >= $scope.refund.amount;
                return validAmount;
            } else {
                return false;
            }

        };

        $scope.isValidAmount = function() {

            if($scope.refundAccountBalance.balance && $scope.refund.amount) {
                $scope.validAmount = parseFloat($scope.refundAccountBalance.balance) >= $scope.refund.amount;
            } else {
                $scope.validAmount = false;

            }

        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.refund);
        };


    }
])
.controller('RefundChequeDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data', 'RefundService', 'PartyService',
    function($scope, $http, $uibModalInstance, $filter, data, RefundService, PartyService) {
        var chqbatId = data.chqbatId;
        $scope.refundCheque = undefined;
        $scope.insuranceClass = [];
        $scope.getRefundChequeInfo = function(chqbatId) {
            RefundService.getRefundChequeInfo(chqbatId).then(function(res) {
                var temp = res.data[0];
                $scope.refundCheque = temp;
                $scope.refundCheque.forparty = {
                    partyid: temp.payee_id,
                    party_nm: temp.payee_nm_ext
                };
                if(temp.org_payee_seq && temp.inscls) {
                    var str = temp.inscls;
                    str.split(',').forEach(function(x) {
                        var arr = x.split('=');
                        $scope.insuranceClass.push({'cls': arr[1], description: arr[0]})
                    });

                }
            });
        };

        $scope.getRefundChequeInfo(chqbatId);

        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getPartiesBoth(phrase, limit);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.refundCheque);
        };


    }
])
.controller('RefundChequeSearchCtrl', ['$scope', '$state', '$http', '$stateParams', '$filter', 'dialogs', 'DTOptionsBuilder', 'SweetAlert', 'blockUI', 'toastr', 'RefundService', 'PartyService', 'DownloadService',
    function($scope, $state, $http, $stateParams, $filter, dialogs, DTOptionsBuilder, SweetAlert, blockUI, toastr, RefundService, PartyService, DownloadService) {
        $scope.search = true;
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.refundCheques = undefined;
        var refundChequeSearchBlock = blockUI.instances.get('refund-cheque-search-result-block');
        $scope.selectedChqBatIds = [];
        $scope.isAllSelected = false;
        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ])
        .withOption('language', {
            zeroRecords: 'No Refund Cheques found for the search criteria'
        });

        RefundService.getInsuranceClasses()
        .then(function(res) {
            $scope.insuranceClasses = res.data;
        });

        var initForm = function() {
            $scope.refundCheques = undefined;
            $scope.refundCheque = {chequeType: 'CBAT'};
        };
        initForm();

        $scope.chequeStatusTypes = [{"type": "CBAT", "description": "Cheque Batch"},
            {"type": "CPRN", "description": "Cheque Printed"},
            {"type": "CVOD", "description": "Cheque Voided"}];


        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getPartiesBoth(phrase, limit);
        };


        $scope.searchRefundCheques = function() {
            $scope.refundCheques = [];
            $scope.selectedChqBatIds = [];
            $scope.isAllSelected = false;
            $scope.searchRefundChequeRequestInProgress = true;
            RefundService.search($scope.refundCheque)
            .then(function(res) {
                $scope.searchRefundChequeRequestInProgress = false;
                $scope.refundCheques = res.data;
            });
        };

        $scope.getCheckStatusDescription = function(chequeType) {
            var checkStatus = _.find($scope.chequeStatusTypes, function(cstatus) {
                return cstatus.type.trim() == chequeType.trim()
            });
            return (checkStatus) ? checkStatus.description : "";
        };

        $scope.getInsuranceClassDescription = function(inscls) {
            var insuranceClass = _.find($scope.insuranceClasses, function(insuranceCls) {
                return insuranceCls.id.trim() == inscls.trim()
            });
            return (insuranceClass) ? insuranceClass.description : "";
        };

        $scope.clear = function() {
            initForm();
        };

        $scope.setRefundChequeDate = function(selectedDate) {
            $scope.refundCheque.chequeDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };


        /**
         * Presents an details dialog.
         */
        $scope.refundChequeDetails = function(refundCheque) {
            var data = {
                chqbatId: refundCheque.chqbat_seq
            };
            dialogs.create('staff/refund/dialogs/refund.cheque.details.html', 'RefundChequeDetailsDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function() {

            }, function() {
            });
        };


        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;
            $scope.selectedChqBatIds = [];
            if(checkbox.checked) {
                $scope.isAllSelected = true;
                _.forEach($scope.refundCheques, function(refundCheque) {
                    if(refundCheque.scnrio_cd.trim() != 'CVOD')
                        $scope.selectedChqBatIds.push(refundCheque.chqbat_seq);
                });
            }
        };


        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param chqbat_seq
         */
        $scope.updateSelection = function($event, chqbat_seq) {
            var checkbox = $event.target;
            $scope.isAllSelected = false;
            if(checkbox.checked && $scope.selectedChqBatIds.indexOf(chqbat_seq) === -1) {
                $scope.selectedChqBatIds.push(chqbat_seq);
            }

            if(!checkbox.checked && $scope.selectedChqBatIds.indexOf(chqbat_seq) !== -1) {
                $scope.selectedChqBatIds.splice($scope.selectedChqBatIds.indexOf(chqbat_seq), 1);
            }
        };

        $scope.isSelected = function(chqbat_seq) {
            return $scope.selectedChqBatIds.indexOf(chqbat_seq) >= 0;
        };


        var containsStatus = function(status) {
            var fRefundCheques = _.filter($scope.refundCheques, function(refundCheque) {
                return _.includes($scope.selectedChqBatIds, refundCheque.chqbat_seq);
            });
            return _.some(fRefundCheques, function(refundCheque) {
                return refundCheque.scnrio_cd.trim() == status;
            });
        };

        var missingChequeInfo = function() {
            var fRefundCheques = _.filter($scope.refundCheques, function(refundCheque) {
                return _.includes($scope.selectedChqBatIds, refundCheque.chqbat_seq);
            });
            return _.some(fRefundCheques, function(refundCheque) {
                return !refundCheque.chq_no || !refundCheque.chqDt;
            });
        };

        $scope.markAsPrinted = function() {
            $scope.creationErrors = undefined;
            var onsuccess = function(res) {
                refundChequeSearchBlock.stop();
                var data = res.data;
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Refund cheque cannot be marked as printed at this time. Please try later');
                }
                toastr.success('Success!', 'Refund cheque has been marked as printed.');
                $scope.searchRefundCheques();
            };
            var onerror = function(res) {
                refundChequeSearchBlock.stop();
                toastr.error('Error!', 'Unable to marked as printed for refund cheque(s) at this time. Please try later');
            };
            refundChequeSearchBlock.start("Mark as printed Refund Cheque...");
            if(missingChequeInfo() || containsStatus("CPRN")) {
                refundChequeSearchBlock.stop();
                sweetAlert("Error", "You can't mark as printed as required information of cheque# or Cheque Print Date is missing or you choose already printed one to mark as printed!", "error");
            } else {
                RefundService.markAsPrintedRefundCheques($scope.selectedChqBatIds).then(onsuccess, onerror);
            }

        };

        $scope.void = function() {
            $scope.creationErrors = undefined;
            var onsuccess = function(res) {
                refundChequeSearchBlock.stop();
                var data = res.data;
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Refund cheque cannot be voided at this time. Please try later');
                }
                toastr.success('Success!', 'Refund cheque has been voided.');
                $scope.searchRefundCheques();
            };
            var onerror = function(res) {
                refundChequeSearchBlock.stop();
                toastr.error('Error!', 'Unable to void refund cheque at this time. Please try later');
            };
            refundChequeSearchBlock.start("Voiding Refund Cheque...");
            if(containsStatus("CBAT")) {
                refundChequeSearchBlock.stop();
                sweetAlert("Error", "You can't void Refund cheque with Batch status!", "error");
            } else
                RefundService.voidRefundCheques($scope.selectedChqBatIds).then(onsuccess, onerror);

        };

        /**
         * Presents an edit cheque dialog.
         */
        $scope.editCheque = function(refund) {
            $scope.creationErrors = undefined;
            var data = {chqbatId: refund.chqbat_seq, ciprid: $scope.roleContext.ciprid, party: {}};
            dialogs.create('staff/refund/dialogs/refund.cheque.html', 'RefundChequeDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(refundCheque) {

                var onsuccess = function(res) {
                    refundChequeSearchBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Refund cheque cannot be updated at this time. Please try later');
                    }
                    toastr.success('Success!', 'Refund cheque has been updated.');
                    $scope.searchRefundCheques();
                };
                var onerror = function(res) {
                    refundChequeSearchBlock.stop();
                    toastr.error('Error!', 'Unable to update refund cheque at this time. Please try later');
                };
                refundChequeSearchBlock.start("Saving refund cheque...");
                RefundService.updateRefundCheque(refundCheque).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an cheque#/date dialog.
         */
        $scope.setChequeInfo = function() {
            $scope.creationErrors = undefined;
            dialogs.create('staff/refund/dialogs/refund.cheque.number.date.html', 'RefundChequeNumberDateDialogCtrl', {}, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(refundCheque) {

                var onsuccess = function(res) {
                    refundChequeSearchBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        $scope.printCheques();
                        return toastr.error('Error!', 'Refund cheque number/date cannot be updated at this time. Please try later');
                    }
                    $scope.printCheques();
                    $scope.searchRefundCheques();
                };
                var onerror = function(res) {
                    refundChequeSearchBlock.stop();
                    toastr.error('Error!', 'Unable to update refund cheque number/date at this time. Please try later');
                };
                refundChequeSearchBlock.start("Saving refund cheque number/date...");
                RefundService.updateRefundChequeNumberDate($scope.selectedChqBatIds, refundCheque).then(onsuccess, onerror);
            });
        };

        $scope.printCheques = function() {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Refund cheque(s) cannot be printed at this time. Please try later');
                }
                var docDefinition = buildPages(res.data);
                var win = window.open('', '_blank');
                pdfMake.createPdf(docDefinition).open({}, win);
            };

            var buildDetailsTableBody = function(details) {
                var body = [];
                details.slice(0, 10).forEach(function(row) {
                    var dataRow = [];
                    ['payee_nm', 'reason', 'refundDt', 'amt'].forEach(function(column) {
                        dataRow.push(column == 'amt' ? $filter('currency')(row[column].toString(), '$') : row[column].toString());
                    });
                    body.push(dataRow);
                });
                return body;
            };

            var buildPages = function(cheques) {
                var definition = {};
                definition.content = [];
                var detailsWidths = [150, 255, 95, 40];
                cheques = _.sortBy(cheques, function(chq){ return parseInt(chq.chq_no,10)})
                cheques.forEach(function(ch, i) {
                    var chequeDate = $filter('date')(ch.chq_dt, "MMMM dd, yyyy");
                    var cheque = [
                        {
                            text: 'DATE',
                            style: 'smaller',
                            absolutePosition: {x: 459.2, y: 68.8},
                            pageBreak: i == 0 ? 'none' : 'before'
                        },
                        {
                            style: 'smaller', table: {
                                widths: [3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5],
                                body: [[ch.chq_dt[5], ch.chq_dt[6], ch.chq_dt[8], ch.chq_dt[9], ch.chq_dt[0], ch.chq_dt[1], ch.chq_dt[2], ch.chq_dt[3]], ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']]
                            },
                            layout: 'noBorders', absolutePosition: {x: 496, y: 64.8}
                        },
                        {
                            text: '********' + ch.chq_amt_str + ' Dollars and 00/100 \n\n\n' + ch.payee_nm + '\n\n' + ch.print_addr,
                            style: 'main',
                            absolutePosition: {x: 70.86, y: 101}
                        },
                        {text: ch.chq_amt.padStart(10, '*'), style: 'main', absolutePosition: {x: 489.5, y: 101}}];

                    if(ch.details.length > 0) {
                        cheque.push({
                            table: {widths: detailsWidths, body: buildDetailsTableBody(ch.details)},
                            layout: 'noBorders',
                            absolutePosition: {x: 19, y: 310},
                            style: 'main'
                        },
                        {text: chequeDate, style: 'main', absolutePosition: {x: 431, y: 555}}, {
                            style: 'main', table: {
                                widths: detailsWidths,
                                body: buildDetailsTableBody(ch.details)
                            }, layout: 'noBorders', absolutePosition: {x: 19, y: 570}
                        },
                        {
                            table: {widths: detailsWidths, body: [['', '', '', ch.chq_amt]]},
                            layout: 'noBorders',
                            absolutePosition: {x: 19, y: 752},
                            style: 'main'
                        });
                    }
                    definition.content.push(cheque);
                });
                definition.styles = {
                    main: {fontSize: 10, margin: [0], border: [false, false, false, false]},
                    smaller: {fontSize: 9}
                };
                return definition;
            };

            var onerror = function(res) {
                toastr.error('Error!', 'Unable to print refund cheque(s) at this time. Please try later');
            };
            RefundService.getRefundChequesByIds($scope.selectedChqBatIds).then(onsuccess, onerror);
        };

    }
])
.controller('RefundChequeDetailsDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data', 'RefundService',
    function($scope, $http, $uibModalInstance, $filter, data, RefundService) {
        var chqbatId = data.chqbatId;
        $scope.refundChequeDetails = undefined;
        $scope.insuranceClass = [];

        RefundService.getRefundChequeDetails(chqbatId).then(function(res) {
            $scope.refundChequeDetails = res.data;
        });


        RefundService.getRefundStatuses()
        .then(function(res) {
            $scope.refundStatuses = res.data;
        });


        $scope.getStatusDescription = function(statusCD) {
            if(!statusCD) return "";
            var st = _.find($scope.refundStatuses, function(status) {
                return status.id.trim() == statusCD.trim()
            });
            return (st) ? st.description : "";
        };


        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.cancelRefund = function(refund) {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    return toastr.error('Error!', 'Refund cannot be cancelled at this time. Please try later');
                }
                toastr.success('Success!', 'Refund has been cancelled.');
                var data = res.data;
                $scope.refundChequeDetails = _.reject($scope.refundChequeDetails, {'id': refund.id});
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Refund cannot be cancelled at this time. Please try later');
            };

            RefundService.cancelRefund(refund).then(onsuccess, onerror);
        };


    }
])
.controller('RefundChequeNumberDateDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data', 'RefundService',
    function($scope, $http, $uibModalInstance, $filter, data, RefundService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.refundCheque = {overrideChequeNumber: 'Y', overrideChequeDate: 'Y'};
        $scope.refundCheque.chequeDate = moment().format('MMMM DD, YYYY');

        $scope.setChequeDate = function(selectedDate) {
            $scope.refundCheque.chequeDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.refundCheque);
        };

    }
])
.service('RefundService', ['$http', '$q',
    function($http, $q) {
        this.getRefunds = function(partyType, partyId) {
            return $http.get('/staff/refund/', {
                params: {
                    partyType: partyType,
                    partyId: partyId
                }
            });
        };


        this.getRefundAccounts = function() {
            return $http.get('/staff/refund/accounts', {cache: true});
        };

        this.getRefundStatuses = function() {
            return $http.get('/staff/refund/statuses', {cache: true});
        };

        this.getRefundChequeInfo = function(chqbatId) {
            return $http.get('/staff/refund/chequeinfo', {
                params: {
                    chqbatId: chqbatId
                }
            });
        };

        this.getRefundChequeDetails = function(chqbatId) {
            return $http.get('/staff/refund/chequeDetails', {
                params: {
                    chqbatId: chqbatId
                }
            });
        };

        this.cancelRefund = function(refund) {
            return $http.delete('/staff/refund/' + refund.id);
        };

        this.createRefund = function(partyType, partyId, refund) {
            return $http.put('/staff/refund/', {
                data: {
                    partyType: partyType,
                    partyId: partyId,
                    refund: refund
                }
            });
        };

        this.updateRefundCheque = function(refundCheque) {
            return $http.post('/staff/refund/cheque', {
                data: {
                    refundCheque: refundCheque
                }
            });
        };


        this.search = function(refundCheque) {
            return $http.get('/staff/refund/search/', {
                params: {
                    chequeType: refundCheque.chequeType,
                    chq_no: refundCheque.chq_no,
                    chq_amt: refundCheque.chq_amt,
                    payee: !_.isEmpty(refundCheque.payee) ? refundCheque.payee.partyid : null,
                    chequeDate: refundCheque.chequeDate,
                    payee_nm: refundCheque.payee_nm,
                    comment_memo: refundCheque.comment_memo,
                    prn_cnt: refundCheque.prn_cnt,
                    print_addr: refundCheque.print_addr
                }
            });
        };

        this.getInsuranceClasses = function() {
            return $http.get('/staff/refund/insuranceClasses', {cache: true});
        };

        this.voidRefundCheques = function(chqBatIds) {
            return $http.post('/staff/refund/voidCheques', {
                chqBatIds: chqBatIds
            });
        };
        this.markAsPrintedRefundCheques = function(chqBatIds) {
            return $http.post('/staff/refund/markAsPrintedRefundCheques', {
                chqBatIds: chqBatIds
            });
        };
        this.updateRefundChequeNumberDate = function(chqBatIds, refundCheque) {
            return $http.post('/staff/refund/updateRefundChequeNumberDate', {
                chqBatIds: chqBatIds,
                refundCheque: refundCheque
            });
        };

        this.getRefundChequesByIds = function(chqBatIds, refundCheque) {
            return $http.get('/staff/refund/cheques', {params: {chqBatIds: chqBatIds}});
        };
    }
]);

