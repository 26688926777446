var receipting = angular.module('aic.receipting', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

/**
 * Routing and config
 */
receipting.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {
        blockUIConfig.autoInjectBodyBlock = false;


        $stateProvider
        .state('receipting', {
            parent: 'workspace',
            abstract: true,
            url: '/receipting',
            templateUrl: 'staff/receipting/receipting.html'
        })
        .state('receipting.search', {
            url: '/search',
            controller: 'ReceiptingSearchCtrl',
            templateUrl: 'staff/receipting/search.html'
        })
        .state('receipting.payments', {
            url: '/{batchId:[0-9]+}/payment',
            templateUrl: 'staff/receipting/payment.list.html',
            controller: 'ReceiptingPaymentListCtrl'
        })
        .state('receipting.paymentAdd', {
            url: '/{batchId:[0-9]+}/paymentAdd',
            templateUrl: 'staff/receipting/payment.add.html',
            controller: 'ReceiptingPaymentAddCtrl'
        })
        .state('receipting.paymentDetails', {
            url: '/{batchId:[0-9]+}/payment/{paymentId:[0-9]+}',
            templateUrl: 'staff/receipting/payment.details.html',
            controller: 'ReceiptingPaymentDetailsCtrl'
        });



    }
])
.controller('ReceiptingSearchCtrl', ['$scope', '$state', '$http', '$stateParams', '$filter', '$sce', 'dialogs','SweetAlert', 'DTOptionsBuilder', 'blockUI', 'toastr', 'ReceiptingService', 'PartyService',
    function($scope, $state, $http, $stateParams, $filter, $sce, dialogs, SweetAlert, DTOptionsBuilder, blockUI, toastr, ReceiptingService, PartyService) {
        $scope.search = true;
        $scope.batches = ReceiptingService.batches;
        $scope.batch = ReceiptingService.batchSearchInfo;
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ])
        .withOption('stateSave', true)
        .withOption('language', {
            zeroRecords: 'No batch found for the search criteria'
        });
        var batchSearchBlock = blockUI.instances.get('batch-search-result-block');

        var initForm = function(clearData) {
            $scope.creationErrors = undefined;
            if(clearData){
                $scope.batches = undefined;
                $scope.batch = {batchType: 'BOPN'};
            }

        };
        initForm();

        ReceiptingService.getBatchTypes()
        .then(function(res) {
            $scope.batchTypes = res.data;
        });

        ReceiptingService.getBOReportPath()
        .then(function(res) {
            $scope.BOReportPath = res.data.path;
            // let trustedPath = $scope.iwaPath + "/payment/create?batchId="+ $stateParams.batchId;
            // $scope.iwaUrl= $sce.trustAsResourceUrl(trustedPath);
        });

        PartyService.getStaffUsers()
        .then(function(res) {
            $scope.staffUsers = res.data;
        });

        $scope.setOpenDate = function(selectedDate) {
            $scope.batch.openDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.setVerifiedDate = function(selectedDate) {
            $scope.batch.verifiedDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.setExportedDate = function(selectedDate) {
            $scope.batch.exportedDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.clear = function() {
            initForm(true);
        };

        $scope.searchBatch = function() {
            $scope.batches = undefined;
            $scope.searchBatchRequestInProgress = true;
            ReceiptingService.setBatchSearchInfo($scope.batch);
            ReceiptingService.search($scope.batch)
            .then(function(res) {
                $scope.searchBatchRequestInProgress = false;
                $scope.batches = res.data;
                ReceiptingService.setBatches($scope.batches);
            });
        };

        // $scope.searchBatch();

        /**
         * Presents an add dialog.
         */
        $scope.createBatch = function() {
            $scope.creationErrors = undefined;
            var data = {batch: {control_total: 0, description: ''}, batchTypes: $scope.batchTypes};
            dialogs.create('staff/receipting/dialogs/addoredit.html', 'ReceiptingAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(batch) {

                var onsuccess = function(res) {
                    batchSearchBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Batch cannot be created at this time.');
                    }
                    var data = res.data;
                    toastr.success('Success!', 'Batch has been created.');
                    batch = data[0];
                    if($scope.batches == undefined)
                        $scope.batches = [batch];
                    else
                        $scope.batches.unshift(batch);
                };
                var onerror = function(res) {
                    batchSearchBlock.stop();
                    toastr.error('Error!', 'Unable to create batch at this time. Please try later');
                };

                batchSearchBlock.start("Creating Batch...");
                ReceiptingService.createBatch(batch).then(onsuccess, onerror);
            });
        };

        /**
         * Presents an edit dialog.
         */
        $scope.editBatch = function(batch) {
            $scope.creationErrors = undefined;
            var data = {batch: batch, batchTypes: $scope.batchTypes};
            dialogs.create('staff/receipting/dialogs/addoredit.html', 'ReceiptingAddEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(batch) {

                var onsuccess = function(res) {
                    batchSearchBlock.stop();
                    if(!_.isEmpty(res.data.error)) {
                        $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                        return toastr.error('Error!', 'Batch cannot be updated at this time.');
                    }
                    var data = res.data;
                    toastr.success('Success!', 'Batch has been updated.');
                    batch = data[0];
                    $scope.batches = _.reject($scope.batches, {'id': batch.id});
                    if($scope.batches == undefined)
                        $scope.batches = [batch];
                    else
                        $scope.batches.unshift(batch);
                };
                var onerror = function(res) {
                    batchSearchBlock.stop();
                    toastr.error('Error!', 'Unable to update batch at this time. Please try later');
                };

                batchSearchBlock.start("Updating Batch...");
                ReceiptingService.updateBatch(batch).then(onsuccess, onerror);
            });
        };

        $scope.canDelete = function(batch) {
            return batch.batchType.trim() == 'BOPN' && parseFloat(batch.batch_total) <= 0;
        };
        $scope.canReopen = function(batch) {
            return _.includes(["BCLS", "BZDL", "BEMP"], batch.batchType.trim()) &&  batch.web_batch_yn !== 'Y';
        };
        $scope.canClose = function(batch) {
            return batch.batchType.trim() == 'BOPN' && batch.web_batch_yn !== 'Y';
        };
        $scope.canVerify = function(batch) {
            return batch.batchType.trim() == 'BCLS';
        };

        $scope.deleteBatch = function(batch) {
            $scope.creationErrors = undefined;
            var onsuccess = function(res) {
                batchSearchBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Batch cannot be deleted at this time.');
                }

                toastr.success('Success!', 'Batch has been deleted.');
                $scope.batches = _.reject($scope.batches, {'id': batch.id});
            };
            var onerror = function(res) {
                batchSearchBlock.stop();
                toastr.error('Error!', 'Unable to delete batch at this time. Please try later');
            };

            SweetAlert.swal({
                title: "Delete Batch.",
                text: "Are you sure you want to delete the batch? You can't undo this action.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    batchSearchBlock.start("Deleting Batch...");
                    ReceiptingService.deleteBatch(batch).then(onsuccess, onerror);
                }

            });

        };

        $scope.closeBatch = function(batch) {
            $scope.creationErrors = undefined;
            var onsuccess = function(res) {
                batchSearchBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Batch cannot be closed at this time.');
                }

                toastr.success('Success!', 'Batch has been closed.');
                var data = res.data;
                batch = data[0];
                $scope.batches = _.reject($scope.batches, {'id': batch.id});
                if($scope.batches == undefined)
                    $scope.batches = [batch];
                else
                    $scope.batches.unshift(batch);
            };
            var onerror = function(res) {
                batchSearchBlock.stop();
                toastr.error('Error!', 'Unable to close batch at this time. Please try later');
            };

            SweetAlert.swal({
                title: "Close Batch.",
                text: "Are you sure you want to Close the batch?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    batchSearchBlock.start("Closing Batch...");
                    ReceiptingService.closeBatch(batch).then(onsuccess, onerror);
                }

            });

        };
        $scope.reopenBatch = function(batch) {
            $scope.creationErrors = undefined;
            var onsuccess = function(res) {
                batchSearchBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Batch cannot be reopened at this time.');
                }

                toastr.success('Success!', 'Batch has been reopened.');
                var data = res.data;
                batch = data[0];
                $scope.batches = _.reject($scope.batches, {'id': batch.id});
                if($scope.batches == undefined)
                    $scope.batches = [batch];
                else
                    $scope.batches.unshift(batch);
            };
            var onerror = function(res) {
                batchSearchBlock.stop();
                toastr.error('Error!', 'Unable to reopen batch at this time. Please try later');
            };

            SweetAlert.swal({
                title: "Re-Open Batch.",
                text: "Are you sure you want to Re-Open the batch?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    batchSearchBlock.start("Reopening Batch...");
                    ReceiptingService.reopenBatch(batch).then(onsuccess, onerror);
                }

            });

        };

        $scope.verifyBatch = function(batch) {
            $scope.creationErrors = undefined;
            var onsuccess = function(res) {
                batchSearchBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    $scope.creationErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Batch cannot be verified at this time.');
                }

                toastr.success('Success!', 'Batch has been verified.');
                var data = res.data;
                batch = data[0];
                $scope.batches = _.reject($scope.batches, {'id': batch.id});
                if($scope.batches == undefined)
                    $scope.batches = [batch];
                else
                    $scope.batches.unshift(batch);
            };
            var onerror = function(res) {
                batchSearchBlock.stop();
                toastr.error('Error!', 'Unable to verify batch at this time. Please try later');
            };


            SweetAlert.swal({
                title: "Verify Batch.",
                text: "Are you sure you want to Verify the batch? You can't undo this action.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    batchSearchBlock.start("Verifying Batch...");
                    ReceiptingService.verifyBatch(batch).then(onsuccess, onerror);
                }

            });

        };

        $scope.receivePayments = function(batch){
            $state.go('receipting.payments', {batchId: batch.id});
        }

    }
])
.controller('ReceiptingAddEditDialogCtrl', ['$scope', '$uibModalInstance', '$filter', 'data', 'ReceiptingService', 'PartyService',
    function($scope, $uibModalInstance, $filter, data, ReceiptingService, PartyService) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.batch = data.batch;

        // $scope.batch.batchType = $scope.batch.batchType.trim();
        $scope.batchTypes = data.batchTypes;
        $scope.setVerifiedDate = function(selectedDate) {
            $scope.batch.verifiedDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        PartyService.getStaffUsers()
        .then(function(res) {
            $scope.staffUsers = res.data;
        });

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.batch);
        };

    }
])
.controller('ReceiptingPaymentListCtrl', ['$scope', '$state', '$stateParams', '$sce', '$compile', 'dialogs', 'toastr', 'blockUI', 'DTOptionsBuilder', 'ReceiptingService',
    function($scope, $state, $stateParams, $sce, $compile, dialogs, toastr, blockUI, DTOptionsBuilder, ReceiptingService) {
        var batchPaymentBlock = blockUI.instances.get('batch-payment-block');
        batchPaymentBlock.start();
        $scope.dtInstance = undefined;
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);
        $scope.getBatch = function() {
            $scope.paymentListErrors = undefined;
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.paymentListErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Batch with Id:'+$stateParams.batchId+' Not Found');
                }
                var data = res.data;
                $scope.batch = data[0];
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to find batch at this time. Please try later');
            };

            ReceiptingService.getBatch($stateParams.batchId).then(onsuccess, onerror);
        };
        $scope.getPayments = function(){
            ReceiptingService.getBatchPayments($stateParams.batchId)
            .then(function(res) {
                batchPaymentBlock.stop();
                $scope.payments = res.data;
            });
        };
        var init = function() {
            $scope.getBatch();
            $scope.getPayments();
        };

        init();

        $scope.paymentDetailInfo = function(payment, event) {

            var scope = $scope.$new(true);
            scope.payment = payment;

            var link  = angular.element(event.currentTarget);
            var   icon  = link.find('.fa');
            var   tr    = link.parent().parent();
            var    table = $scope.dtInstance.DataTable;
             var   row   = table.row(tr);
            //
            if(row.child.isShown()) {
                icon.removeClass('fa-minus-circle font-red').addClass('fa-plus-circle font-green-jungle');
                row.child.hide();
                tr.removeClass('shown');
            }
            else {
                icon.removeClass('fa-plus-circle font-green-jungle').addClass('fa-minus-circle font-red');
                row.child($compile('<div payment-details class="table-responsive clearfix"></div>')(scope)).show();
                tr.addClass('shown');
            }
        };

        $scope.refreshPayments = function(){
            $scope.getPayments();
        };

        $scope.paymentDetails = function(payment){
            $state.go('receipting.paymentDetails', {batchId: payment.bathdr_seq,paymentId: payment.batdet_seq});
        };

        $scope.addPayment = function(batch){
            $state.go('receipting.paymentAdd', {batchId: batch.id});
        };

        $scope.search = function(){
            $state.go('receipting.search');
        };

        $scope.dtInstanceCallback = function(dtInstance) {
            $scope.dtInstance = dtInstance;
        }

    }
])
.controller('ReceiptingPaymentAddCtrl', ['$scope', '$state', '$stateParams', '$sce', 'dialogs', 'toastr', 'blockUI', 'DTOptionsBuilder', 'ReceiptingService',
    function($scope, $state, $stateParams, $sce, dialogs, toastr, blockUI, DTOptionsBuilder, ReceiptingService) {
        var batchPaymentAddBlock = blockUI.instances.get('batch-payment-add-block');
        // batchPaymentAddBlock.start();
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'desc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);
        $scope.getBatch = function() {
            $scope.batchErrors = undefined;
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.batchErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Batch with Id:'+$stateParams.batchId+' Not Found');
                }
                var data = res.data;
                $scope.batch = data[0];
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to find batch at this time. Please try later');
            };

            ReceiptingService.getBatch($stateParams.batchId).then(onsuccess, onerror);
        };

        ReceiptingService.getIWAPath()
        .then(function(res) {
            $scope.iwaPath = res.data.path;
            let trustedPath = $scope.iwaPath + "/payment/create?batchId="+ $stateParams.batchId;
            $scope.iwaUrl= $sce.trustAsResourceUrl(trustedPath);
        });

        var init = function() {
            $scope.getBatch();
        };

        init();


        $scope.iwaIframeLoad = function(ele){
            // var iwaIframe = angular.element('iwaIframe');
            // batchPaymentAddBlock.stop();

        };

        $scope.receivePayments = function(batch){
            $state.go('receipting.payments', {batchId: batch.id});
        };

        $scope.addPayment = function(batch){
            let trustedPath = $scope.iwaPath + "/payment/create?batchId="+ batch.id;
            // var iwaIframe = angular.element('iwaIframe');
            $scope.iwaUrl= $sce.trustAsResourceUrl(trustedPath);
            // iwaIframe.src = $scope.iwaUrl;
        };

    }
])
.controller('ReceiptingPaymentDetailsCtrl', ['$scope', '$state', '$stateParams', '$sce', 'dialogs', 'toastr', 'blockUI', 'DTOptionsBuilder', 'ReceiptingService',
    function($scope, $state, $stateParams, $sce, dialogs, toastr, blockUI, DTOptionsBuilder, ReceiptingService) {
        var batchPaymentDetailsBlock = blockUI.instances.get('batch-payment-details-block');
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc']);


        $scope.getBatch = function() {
            $scope.paymentDetailsError = undefined;
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    $scope.paymentDetailsError = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Batch with Id:'+$stateParams.batchId+' Not Found');
                }
                var data = res.data;
                $scope.batch = data[0];
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to find batch at this time. Please try later');
            };

            ReceiptingService.getBatch($stateParams.batchId).then(onsuccess, onerror);
        };
        $scope.getPaymentDetailInfo = function(){
            ReceiptingService.getPaymentDetailInfo($stateParams.batchId,$stateParams.paymentId)
            .then(function(res) {
                $scope.paymentInfo = res.data.paymentInfo[0];
                $scope.authInfo = res.data.authInfo;
            });
        };
        var init = function() {
            $scope.getBatch();
            $scope.getPaymentDetailInfo();
        };

        init();

        $scope.endorseForm = function(batchId, paymentId) {
            ReceiptingService.endorseForm(batchId, paymentId);
        };
        $scope.endorseCheque = function(batchId, paymentId) {
            ReceiptingService.endorseCheque(batchId, paymentId);
        };
        $scope.receipt = function(authId, type) {
            ReceiptingService.receipt(authId, type);
        };

        $scope.receiptOther = function(batchId, paymentId) {
            ReceiptingService.receiptOther(batchId, paymentId);
        };

        $scope.receivePayments = function(batch){
            $state.go('receipting.payments', {batchId: batch.id});
        };

        $scope.addPayment = function(batch){
            $state.go('receipting.paymentAdd', {batchId: batch.id});
        };

        $scope.canVoidPayment = function(){
            if(!$scope.paymentInfo || $scope.paymentInfo.voided_ts) return false;
            return _.includes(["CHQ", "CASH", "MO"], $scope.paymentInfo.paymnt_cd.trim());
        };

        $scope.voidPayment = function(batch, payment) {
            $scope.paymentDetailsError = undefined;
            var onsuccess = function(res) {
                batchPaymentDetailsBlock.stop();
                if(!_.isEmpty(res.data.error)) {
                    $scope.paymentDetailsError = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
                    return toastr.error('Error!', 'Payment cannot be voided at this time.');
                }

                toastr.success('Success!', 'Payment has been voided.');
                var data = res.data;
                $scope.paymentInfo = res.data[0];
            };
            var onerror = function(res) {
                batchPaymentDetailsBlock.stop();
                toastr.error('Error!', 'Unable to void payment at this time. Please try later');
            };

            batchPaymentDetailsBlock.start("Voiding Payment...");
            ReceiptingService.voidPayment(batch, payment).then(onsuccess, onerror);

        };


    }
])
/**
 * Dialog and modal controllers
 */
.controller('ReceiptingEndorseFormCtrl', ['$scope', '$uibModalInstance', 'endorseText',
    function($scope, $uibModalInstance, endorseText) {
        $scope.endorseText = endorseText;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
])
.controller('ReceiptingEndorseChequeCtrl', ['$scope', '$uibModalInstance', 'endorseText',
    function($scope, $uibModalInstance, endorseText) {
        $scope.endorseText = endorseText;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
])
.controller('ReceiptingReceiptCtrl', ['$scope', '$uibModalInstance', 'receipt',
    function($scope, $uibModalInstance, receipt) {
        $scope.receipt = receipt;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
])
.controller('ReceiptingReceiptOtherCtrl', ['$scope', '$uibModalInstance', 'receipt',
    function($scope, $uibModalInstance, receipt) {
        $scope.receipt = receipt;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
])
// .controller('ReceiptingPaymentCtrl', ['$scope', '$state', '$stateParams', '$sce', 'dialogs', 'toastr', 'blockUI', 'ReceiptingService',
//     function($scope, $state, $stateParams, $sce, dialogs, toastr, blockUI, ReceiptingService) {
//         var batchPaymentBlock = blockUI.instances.get('batch-payment-block');
//         // batchPaymentBlock.start();
//         $scope.getBatch = function() {
//             $scope.batchErrors = undefined;
//             var onsuccess = function(res) {
//                 if(!_.isEmpty(res.data.error)) {
//                     $scope.batchErrors = _.isObject(res.data.error) && _.isArray(res.data.error) ? res.data.error : "";
//                     return toastr.error('Error!', 'Batch with Id:'+$stateParams.batchId+' Not Found');
//                 }
//                 var data = res.data;
//                 $scope.batch = data[0];
//             };
//             var onerror = function(res) {
//                 toastr.error('Error!', 'Unable to find batch at this time. Please try later');
//             };
//
//             ReceiptingService.getBatch($stateParams.batchId).then(onsuccess, onerror);
//         };
//         $scope.getBatch();
//         ReceiptingService.getIWAPath()
//         .then(function(res) {
//             $scope.iwaPath = res.data.path;
//             let trustedPath = $scope.iwaPath + "/payment/list?batchId="+ $stateParams.batchId;
//             $scope.iwaUrl= $sce.trustAsResourceUrl(trustedPath);
//         });
//
//         $scope.iwaIframeLoad = function(ele){
//             var iwaIframe = angular.element('iwaIframe');
//             // batchPaymentBlock.stop();
//             $scope.iframePath = iwaIframe.src;
//             $scope.iframePath = ele.src;
//         };
//         // window.iwaFrameLoad = function(){
//         //     iwaIframe.contentWindow.location.href;
//         //     var ifrm = document.getElementById('myIframe');
//         //     angular.element(ifrm).scope().onIframeLoad(ifrm.src);
//         // }
//
//     }
// ])
/*
    Receipting services
*/
.service('ReceiptingService', ['$http', '$q', '$uibModal',
    function($http, $q, $uibModal) {
        this.batches = undefined;
        this.batchSearchInfo = {batchType: 'BOPN'};
        this.setBatchSearchInfo = function(batchSearchInfo) {
            this.batchSearchInfo = batchSearchInfo
        };

        this.setBatches = function(batches) {
            this.batches = batches
        };

        this.getBatchTypes = function() {
            return $http.get('/staff/receipting/batchtypes', {cache: true});
        };

        this.createBatch = function(batch) {
            return $http.put('/staff/receipting', {
                data: {
                    batch: batch
                }
            });
        };

        this.deleteBatch = function(batch) {
            return $http.delete('/staff/receipting/' + batch.id);
        };

        this.closeBatch = function(batch) {
            return $http.post('/staff/receipting/' + batch.id + '/close', {});
        };

        this.reopenBatch = function(batch) {
            return $http.post('/staff/receipting/' + batch.id + '/reopen', {});
        };

        this.verifyBatch = function(batch) {
            return $http.post('/staff/receipting/' + batch.id + '/verify', {});
        };

        this.updateBatch = function(batch) {
            return $http.post('/staff/receipting/' + batch.id, {
                data: {
                    batch: batch
                }
            });
        };
        this.getBatch = function(batchId) {
            return $http.get('/staff/receipting/' + batchId);
        };
        this.getBatchPayments = function(batchId) {
            return $http.get('/staff/receipting/' + batchId + '/payments');
        };
        this.getIWAPath = function() {
            return $http.get('/staff/receipting/iwa/path');
        };
        this.getBOReportPath = function() {
            return $http.get('/staff/receipting/boreport/path');
        };

        this.getPaymentDetailInfo = function(batchId, paymentId) {
            return $http.get('/staff/receipting/' + batchId + '/payments/'+paymentId);
        };

        this.search = function(batch) {
            return $http.get('/staff/receipting/search/', {
                params: {
                    id: batch.id,
                    batchType: batch.batchType,
                    batchTotal: batch.batchTotal,
                    controlTotal: batch.controlTotal,
                    openDate: batch.openDate,
                    description: batch.description,
                    verifiedBy: batch.verifiedBy,
                    verifiedDate: batch.verifiedDate,
                    openedBy: batch.openedBy,
                    exportedDate: batch.exportedDate
                }
            });
        };

        this.endorseForm = function(batchId, paymentId) {
            $http.get('/staff/receipting/' + batchId + '/payments/'+paymentId + '/endorseForm')
            .then(function(res) {
                $uibModal.open({
                    templateUrl: 'staff/receipting/dialogs/endorse.form.html',
                    resolve: {
                        endorseText: function() {
                            return res.data.endorseText;
                        }
                    },
                    controller: 'ReceiptingEndorseFormCtrl'
                });
            });
        };

        this.endorseCheque = function(batchId, paymentId) {
            $http.get('/staff/receipting/' + batchId + '/payments/'+paymentId + '/endorseCheque')
            .then(function(res) {
                $uibModal.open({
                    templateUrl: 'staff/receipting/dialogs/endorse.cheque.html',
                    resolve: {
                        endorseText: function() {
                            return res.data.endorseText;
                        }
                    },
                    controller: 'ReceiptingEndorseChequeCtrl'
                });
            });
        };

        this.receipt = function(authId, type) {
            $http.get('/staff/receipting/receipt', {
                params: {
                    authId: authId,
                    type: type
                }
            })
            .then(function(res) {
                $uibModal.open({
                    templateUrl: 'staff/receipting/dialogs/receipt.html',
                    resolve: {
                        receipt: function() {
                            return res.data[0].receiptText;
                        }
                    },
                    controller: 'ReceiptingReceiptCtrl'
                });
            });
        };

        this.receiptOther = function(batchId, paymentId) {
            $http.get('/staff/receipting/' + batchId + '/payments/'+paymentId + '/receiptOther')
            .then(function(res) {
                $uibModal.open({
                    templateUrl: 'staff/receipting/dialogs/receipt.other.html',
                    resolve: {
                        receipt: function() {
                            return res.data[0].receiptText;
                        }
                    },
                    controller: 'ReceiptingReceiptOtherCtrl'
                });
            });
        };

        this.voidPayment = function(batch, payment) {
            return $http.post('/staff/receipting/' + batch.id + '/payments/'+payment.batdet_seq +'/void', {});
        };

    }
]);

