var licensing = angular.module('licensing', [
    'angular-jwt',
    'angular-storage',
    'ngResource',
    'ngCookies',
    'ngMessages',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'ct.ui.router.extras',
    'datatables',
    'datatables.bootstrap',
    'jm.i18next',
    'aic.ng-jquery',
    'aic.applications',
    'aic.ce',
    'aic.eando',
    'aic.exams',
    'aic.invoices',
    'aic.licenses',
    'aic.cecourselookup',
    'aic.aglookup',
    'aic.nonrenlookup',
    'aic.organizations',
    'aic.parties',
    'aic.renewals',
    'aic.eplicenses',
    'aic.subscribe',
    'aic.payables',
    'aic.profile',
    'aic.crimcheck',
    'aic.directives',
    'aic.filters',
    'aic.findmycipr',
    'aic.templates',
    'aic.wwwlogin',
    'aic.lookups'
]);

licensing.run(['$rootScope', '$state', '$stateParams', '$cookies', '$location', 'DTDefaultOptions', 'DTOptionsBuilder', 'AuthService',
    function($rootScope, $state, $stateParams, $cookies, $location, DTDefaultOptions, DTOptionsBuilder, AuthService) {

        $rootScope.$state = $state;
        $rootScope.welcomeState = 'home';
        $rootScope.welcomeStateParams = {};

        $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams, fromState, fromStateParams) {
            $rootScope.toState = toState;
            $rootScope.toStateParams = toStateParams;
            $rootScope.fromState = fromState;
            $rootScope.fromStateParams = fromStateParams;

            if(!AuthService.isAuthenticated() && toState.data.requiresLogin) {
                event.preventDefault();
                $rootScope.welcomeState = toState;
                $rootScope.welcomeStateParams = toStateParams;

                AuthService.clearContexts();

                if($cookies.rememberme) {
                    $state.go('remember');
                } else {
                    $state.go('loginwithcipr');
                }
            }

            if(toState.name == 'home' || toState.name == 'agency' || toState.name == 'sponsor') {
                AuthService.setRoleContext(toStateParams.ciprid);
            }
        });

        $rootScope.$on('$viewContentLoaded', function() {
            App.initAjax();
        });

        $rootScope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withBootstrapOptions({
            TableTools: {
                classes: {
                    container: 'btn-group tabletools-dropdown-on-portlet',
                    buttons: {
                        normal: 'btn btn-sm default',
                        disabled: 'btn btn-sm default disabled'
                    },
                    collection: {
                        container: 'DTTT_dropdown dropdown-menu tabletools-dropdown-menu'
                    }
                }
            },
            ColVis: {
                classes: {
                    masterButton: 'btn btn-info'
                }
            }
        });

        DTDefaultOptions.setLoadingTemplate('<tr colspan="4"><td align="center"><div class="loading-message loading-message-boxed" align="center"><img src="/img/loading-spinner-default.gif"><span>&nbsp;&nbsp;LOADING...</span></div> </td></tr>');
    }
]);

licensing.config(['$httpProvider', '$urlRouterProvider', '$interpolateProvider', '$i18nextProvider', '$logProvider', 'jwtInterceptorProvider',
    function($httpProvider, $urlRouterProvider, $interpolateProvider, $i18nextProvider, $logProvider, jwtInterceptorProvider) {

        $i18nextProvider.options = {
            lng: 'en',
            useCookie: false,
            useLocalStorage: false,
            fallbackLng: 'dev',
            resGetPath: '../locales/__lng__/__ns__.json',
            defaultLoadingValue: '' // ng-i18next option, *NOT* directly supported by i18next
        };

        jwtInterceptorProvider.tokenGetter = ['store', function(store) {
            return store.get('licensing');
        }];

        if(!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }
        //disable IE ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';

        $httpProvider.interceptors.push('AuthInterceptor');
        //$httpProvider.interceptors.push('XSRFInterceptor');
        $httpProvider.interceptors.push('jwtInterceptor');

        $urlRouterProvider.otherwise('/');

        //$locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('{{');
        $interpolateProvider.endSymbol('}}');

        $logProvider.debugEnabled(false);
    }
]);

licensing.constant('OPTIONS', {
    JQ_DATE_PICKER_FORMAT: 'M dd, yyyy',
    DATE_FORMAT: 'MMM dd, yyyy'
});
