var ceproviders = angular.module('aic.ceproviders', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.subscribe',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

var providerStatuses = [
    { id: "VPROV", code: "VPROV", description: "CE Provider"},
    { id: "VACCR", code: "VACCR", description: "CE Provider - Self Accredited"}
];

cecourses.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {
        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('ceproviders', {
            parent: 'workspace',
            abstract: true,
            url: '/ceproviders',
            templateUrl: 'ceproviders/ceproviders.html'
        })
        .state('ceproviders.search', {
            url: '/search',
            params: {
                cache: true,
                resetFields: false
            },
            templateUrl: 'ceproviders/ceproviders.search.html',
            controller: 'CeProviderSearchCtrl'
        })
        .state('ceproviders.events', {
            url: '/{ceProviderId:[0-9]+}/events',
            templateUrl: 'ceproviders/ceprovider.events.html',
            controller: 'CeProviderEventsCtrl'
        })
        .state('ceproviders.open', {
            url: '/{ceProviderId:[0-9]+}',
            abstract: true,
            reloadOnSearch: false,
            resolve: {
                promisedCeProvider: ['$stateParams', 'CeProviderResolverService',
                    function($stateParams, CeProviderResolverService) {
                        var ceProviderId = $stateParams.ceProviderId;
                        return ceProviderId ? CeProviderResolverService.get(ceProviderId) : null;
                    }
                ]
            },
            controller: 'CeProviderOpenCtrl',
            template: '<div ui-view></div>'
        })
        .state('ceproviders.open.edit', {
            url: '/edit',
            views: {
                '': {
                    templateUrl: 'ceproviders/ceproviders.edit.html'
                }
            }
        })
        .state('ceproviders.create', {
                url: '/create',
                views: {
                    '': {
                        resolve: {
                            promisedCeProvider: ['$stateParams', 'CeProviderResolverService',
                                function($stateParams, CeProviderResolverService) {
                                    return null;
                                }
                            ]
                        },
                        templateUrl: 'ceproviders/ceproviders.create.html',
                        controller: 'CeProviderOpenCtrl'
                    }
                }

            });
    }
])
.controller('CeProviderSearchCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'blockUI', 'toastr', 'SweetAlert', 'CeProviderService',
    function($scope, $state, $stateParams, DTOptionsBuilder, blockUI, toastr, SweetAlert, CeProviderService) {

        if($stateParams.resetFields)CeProviderService.reset();

        $scope.ceProviders = CeProviderService.ceProviders;
        $scope.searchStr = CeProviderService.searchStr;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('stateSave', true);

        $scope.searchCeProvider = function() {
            $scope.loading = true;
            CeProviderService.search($scope.searchStr)
            .then(function(res) {
                $scope.ceProviders = res.data;
                $scope.loading = false;
                CeProviderService.setCeProviders($scope.ceProviders);
            });
        };


        $scope.viewCeProvider = function(ceProvider) {
            $state.go('ceproviders.open.edit', {ceProviderId: ceProvider.ceProviderId});
        };
     }
])
.controller('CeProviderEventsCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', '$previousState', 'EventService',
    function($scope, $state, $stateParams, DTOptionsBuilder, $previousState, EventService) {
        var ceProviderId = $stateParams.ceProviderId;
        $scope.ceProviderId = ceProviderId;
        $scope.loading = true;
        EventService.getCeProviderEvents(ceProviderId)
            .then(function(res) {
                $scope.events = res.data;
                $scope.loading = false;
            });

        $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('processing', true)
            .withOption('order', []);

        $scope.goPrevious = function() {
            $previousState.go();
        };
   }
])
.controller('CeProviderOpenCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', '$filter',  'blockUI', 'toastr', 'dialogs',  'promisedCeProvider', 'CeProviderService', 'PartyService', 'OrgsService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, $filter, blockUI, toastr, dialogs, promisedCeProvider, CeProviderService, PartyService, OrgsService) {
        $scope.providerStatuses = CeProviderService.getProviderStatuses();

        $scope.isEdit = $state.current.url == "/edit";
        $scope.isCreate = $state.current.url == "/create";


        var initCreateForm = function() {
            $scope.ceProvider = {};
            $scope.ceProvider.party = undefined;

            // Default the type to "CE Provider" . We can only change to Accredited on update.
            $scope.ceProvider.type = _.find($scope.providerStatuses, {code: 'VPROV'});
        };

        var initEditForm = function() {
            $scope.ceProvider = promisedCeProvider;
            $scope.ceProvider.party = {
                partyid: promisedCeProvider.partyId,
                partyNm: promisedCeProvider.party,
                partyType: promisedCeProvider.orgSeq ? "ORG" : "PERSON"
            };
            $scope.ceProvider.type = _.find($scope.providerStatuses, {code: $scope.ceProvider.scnrioCd.trim()});

            CeProviderService.getProviderAddress($scope.ceProvider.party.partyid, $scope.ceProvider.party.partyType)
                .then(function (response) {
                    $scope.address = _.find(response.data, function (o) {
                        return o.scnrio_cd === 'ACE' || o.scnrio_cd === 'ACEP'
                    });
                });

            CeProviderService.getCourseCount($scope.ceProvider.providerId).then(function (response) {
                $scope.hasCourses = response.data[0].count > 0;
            });
            var isSelfAccredited = $scope.ceProvider.type.code === 'VACCR';
            var feePaidUntilYear = moment($scope.ceProvider.feePaidUntil).year();
            $scope.showRenew = isSelfAccredited && feePaidUntilYear < moment().year();
        };

        if(promisedCeProvider) initEditForm();
        else initCreateForm();


        $scope.goPrevious = function() {
            $previousState.go();
        };

        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getPartiesBoth(phrase, limit);
        };

        var errorAddress = function(res) {
            toastr.error('Error!', 'Unable to create address at this time. Please try later');
        };

        $scope.createCeProvider = function() {
             var onSuccess = function(res) {
                $scope.createRequestInProgress = false;
                if(!_.isEmpty(res.data.error) || res.data.length==0) {
                    toastr.error('Error!',  "Error during CE Provider creation.");
                }else{
                    $state.go('ceproviders.open.edit', {ceProviderId: res.data[0].id});
                    toastr.success('Success!', 'CE Provider successfully created.');
                }
            };
            var onError = function() {
                $scope.createRequestInProgress = false;
                toastr.error('Error!', 'CE Provider cannot be created at this time.');
            };

            var onSuccessAddress = function(res) {
                CeProviderService.createProvider($scope.ceProvider).then(onSuccess, onError);
            };

            if($scope.address == undefined || $scope.address.street == undefined ){
                addAddress(onSuccessAddress);
            }else{
                $scope.createRequestInProgress = true;
                CeProviderService.createProvider($scope.ceProvider).then(onSuccess, onError);
            }
        };

        function addAddress(successAddress) {
            $scope.address= {};
            // default address values
            $scope.address.provinceid = ALBERTA_ID;
            $scope.address.province = ALBERTA;
            $scope.address.countryid = CANADA_ID;
            $scope.address.country = CANADA;

            dialogs.create('ceproviders/address/dialogs/addoredit.html', 'OrgsAddressAddEditDialogCtrl',  $scope.address, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function (address) {
                if ($scope.ceProvider.party.partyType === 'ORG') {
                    address.scnrio_cd = 'ACE';
                    OrgsService.createAddress($scope.ceProvider.party.partyid, address).then(successAddress, errorAddress);
                } else {
                    address.scnrio_cd = 'ACEP';
                    PartyService.createAddress($scope.ceProvider.party.partyid, address).then(successAddress, errorAddress);
                }
            });
        }

        $scope.updateCeProvider = function(renewAccreditation) {
            var onSuccessUpdate = function(res) {
                $scope.updateRequestInProgress = false;
                if(!_.isEmpty(res.data.error)) {
                    toastr.error('Error!', "Error during CE Provider update.");
                }else{
                    toastr.customSuccess('Success!', 'CE Provider successfully updated.', function() {  $state.reload(); } );
                }
            };
            var onErrorUpdate = function() {
                $scope.updateRequestInProgress = false;
                toastr.error('Error!', 'CE Provider cannot be updated at this time.');
            };

            var onSuccessAddressUpdate = function(res) {
                CeProviderService.updateProvider($scope.ceProvider).then(onSuccessUpdate, onErrorUpdate);
            };


            if($scope.address == undefined  || $scope.address.street == undefined){
                addAddress(onSuccessAddressUpdate);
            }else{
                $scope.updateRequestInProgress = true;
                CeProviderService.updateProvider($scope.ceProvider).then(onSuccessUpdate, onErrorUpdate);
            }
        };


        $scope.renewSelfAccreditation = function() {
            var onSuccess = function(res) {
                $scope.updateRequestInProgress = false;
                if(!_.isEmpty(res.data.error)) {
                    toastr.error('Error!', "Error during CE Provider Self-Accreditation update.");
                }else{
                    toastr.customSuccess('Success!', 'CE Provider Self-Accreditation successfully updated.', function() {  $state.reload(); } );
                }
            };
            var onError = function() {
                $scope.updateRequestInProgress = false;
                toastr.error('Error!', 'CE Provider Self-Accreditation cannot be updated at this time.');
            };
            CeProviderService.renewSelfAccreditation($scope.ceProvider.providerId).then(onSuccess, onError);
        };

        $scope.$watch('ceProvider.party', function(providerParty) {
            if(providerParty && providerParty.partyid) {
                CeProviderService.getProviderAddress(providerParty.partyid, providerParty.partyType)
                    .then(function (response) {
                        $scope.address = _.find(response.data, function(o) { return o.scnrio_cd === 'ACE' || o.scnrio_cd === 'ACEP'});
                    });
            } else{
                $scope.address = undefined;
            }
        });

        $scope.clear = function() {
            if($scope.isEdit) initEditForm();
            if($scope.isCreate) initCreateForm();
        };

        $scope.reload = function() {
            $state.reload();
        };
    }
])
    /**
 * CeProvider services
 */
.service('CeProviderService', ['$http', '$q',
    function($http, $q) {
        this.ceProviders = [];
        this.searchStr = undefined;

        this.setCeProviders = function(ceProviders) {
            this.ceProviders = ceProviders;
        };

        this.search = function(searchStr) {
            this.searchStr = searchStr;
            return $http.get('/staff/ceproviders/search?searchStr=' + searchStr);
        };

        this.delete = function(ceProviderId) {
            return $http.delete('/staff/ceproviders/'+ ceProviderId);
        };

        this.getProvider = function(ceProviderId) {
            return $http.get('/staff/ceproviders/' + ceProviderId);
        };

        this.getProviderStatuses = function() {
            return providerStatuses;
        };

        this.createProvider = function(provider) {
            return $http.put('/staff/ceproviders', {
                data: {
                    ceprovider: provider
                }
            })
        };

        this.getProviderAddress = function(partyId, partyType) {
            var type = partyType === 'ORG' ? 'orgs' : 'parties';
            return $http.get('/staff/' + type +  '/' + partyId + '/address');
        };

        this.updateProvider = function(provider) {
            return $http.post('/staff/ceproviders', {
                data: {
                    ceprovider: provider
                }
            });
        };

        this.renewSelfAccreditation = function(ceProviderId) {
            return $http.post('/staff/ceproviders/' + ceProviderId + '/renewselfaccr');
        };

        this.getCourseCount = function(ceProviderId) {
           return  $http.get('/staff/ceproviders/' + ceProviderId + '/coursecount');
        };

        this.reset = function() {
            this.ceProviders = {};
            this.searchStr = undefined;
        };
    }
])
.service('CeProviderResolverService', ['$resource', '$http', 'CeProviderService',
        function($resource, $http, CeProviderService) {

            /**
             * Returns a promise which resolves a ce provider object.
             *
             * @param ceProviderId
             * @returns {*}
             */
            this.get = function(ceProviderId) {
                return $http.get('/staff/ceproviders/' + ceProviderId)
                    .then(function(res) {
                       return res.data[0];
                    });
            };
        }
    ]);
