var reports = angular.module('aic.reports', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
appl.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('reports', {
            parent: 'workspace',
            abstract: true,
            url: '/reports',
            templateUrl: 'staff/reports/report.html'
        })
        .state('reports.renewal', {
            url: '/renewal',
            controller: 'ReportRenewalCtrl',
            templateUrl: 'staff/reports/reports.renewal.html'
        });

    }
])
.controller('ReportRenewalCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', '$filter','blockUI', 'ReportService',
    function($scope, $state, $http, DTOptionsBuilder, $filter, blockUI, ReportService) {

        /* Initializer*/
        $scope.dateFormat = 'MM dd, yyyy';
        $scope.fromDate = moment().subtract(7,'day').format('MMMM DD, YYYY');
        $scope.toDate = moment().format('MMMM DD, YYYY');
        $scope.options = {
            chart: {
                type: 'multiBarChart',
                height: 550,
                margin : {
                    top: 20,
                    right: 20,
                    bottom: 65,
                    left: 50
                },
                clipEdge: true,
                duration:500,
                stacked:true,
                reduceXTicks: false,
                color:['#ff6161','#32c5d2','#F1C40F','#659be0','#1BBC9B'],
                xAxis: {
                    axisLabel: 'Date/Hour',
                    showMaxMin: false,
                    rotateLabels:-30,
                    tickFormat: function(d){
                        if($scope.hourly === 'Y')
                            return d3.format(',f')(d);
                        else {
                            var dt = new Date(d);
                            dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
                            return d3.time.format('%Y-%m-%d')(dt);
                        }
                    }
                },
                yAxis: {
                    axisLabel: 'Count',
                    axisLabelDistance: -20,
                    tickFormat: function(d){
                        return d3.format(',f')(d);
                    }
                },
                useInteractiveGuideline: false,
                interactive: true,
                tooltip:{
                    contentGenerator: function(d) { //return html content
                        return '<p><b>' +  d.data.key   + '</b><br>Date/Hour: <b>' + d.data.x + '</b><br/>Renewals Count: <b> ' + d.data.y + '</b><br/> License Holders: <b>' + d.data.lholder + '</b></p>';
                    }
                }
            }
        };

        ReportService.getRenewalsData($scope.fromDate ? $filter('date')($scope.fromDate, "yyyy-MM-dd") : null,
        $scope.toDate ? $filter('date')($scope.toDate, "yyyy-MM-dd") : null, false)
        .then(function(res) {
            setChartData(res.data);
        });

        $scope.go = function() {
            $scope.fromDate = $scope.fromDate ? $filter('date')($scope.fromDate, "yyyy-MM-dd") : null;
            $scope.toDate = $scope.toDate ? $filter('date')($scope.toDate, "yyyy-MM-dd") : null;
            $scope.hourly =  $scope.fromDate && $scope.toDate && moment($scope.toDate).diff(moment($scope.fromDate), 'days') === 0 ? 'Y':'N';
            ReportService.getRenewalsData($scope.fromDate, $scope.toDate, $scope.hourly)
            .then(function(res){
                setChartData(res.data);
            });
        };

        $scope.reset = function() {
            $scope.fromDate = moment().subtract(7,'day').format('MMMM DD, YYYY');
            $scope.toDate = moment().format('MMMM DD, YYYY');
        };

        var setChartData =  function(items){
            console.log('items', JSON.stringify(items));
            var keys = _.uniq(_.map(items, "app_step"));
            keys = _.without(keys, "");
            console.log(keys);

            function createStream(key){

                var stream = {};
                stream.key = key;
                stream.values = _.chain(items)
                .filter(function(item){
                    return item.app_step ===  key;
                })
                .map(function(i){return {x: $scope.hourly === 'Y'? i.c_hour: i.c_day, y: parseFloat(i.c_lic_cnt), lholder: i.c_lh_cnt}})
                .value();

                console.log(stream);
                return stream;
            };

            var data = _.map(keys, createStream);

            console.log('data', JSON.stringify(data));
            $scope.data = data;
        };




    }
])
/**
 * Services
 */
.service('ReportService', ['$http',
    function($http) {

        this.getRenewalsData = function(fromDate, toDate, hourly) {
            return $http.get('/staff/reports/renewals/', {
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                    hourly:hourly
                }
            });
        };

    }
]);

