var licenses = angular.module('aic.licenses', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ngclipboard',
    'ui.router',
    'aic.eando',
    'aic.directives',
    'aic.filters',
    'dialogs.main',
    'dialogs.default-translations'
]);

/**
 * Routing and config
 */
licenses.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('licenses', {
            parent: 'workspace',
            abstract: true,
            url: '/licenses',
            templateUrl: 'licenses/licenses.html'
        })
        .state('licenses.list', {
            url: '/list',
            templateUrl: 'licenses/licenses.list.html',
            controller: 'LicenseListCtrl'
        })
        .state('licenses.eando', {
            url: '/eando',
            params: {
                selectedLicenseIds: {array: true}
            },
            templateUrl: 'licenses/licenses.eando.html',
            controller: 'LicenseAddEandOCtrl'
        })
        .state('licenses.address', {
            url: '/address',
            params: {
                selectedLicenseIds: {array: true}
            },
            templateUrl: 'licenses/licenses.address.html',
            controller: 'LicenseEditAddress'
        })
        .state('licenses.view', {
            url: '/view/{licenseId:[0-9]+}?licenseType',
            templateUrl: 'licenses/licenses.view.html',
            controller: 'LicenseViewCtrl'
        })
        .state('licenses.combine', {
            url: '/combine',
            abstract: true,
            template: '<div ui-view></div>',
            controller: 'LicenseCombineCtrl'
        })
        .state('licenses.combine.list', {
            url: '',
            views: {
                '': {
                    templateUrl: 'licenses/licenses.combine.list.html',
                    controller: 'LicenseCombineListCtrl'
                },
                'licenses@licenses.combine.list': {
                    templateUrl: 'licenses/licenses.list.html',
                    controller: 'LicenseListCtrl'
                },
                'applications@licenses.combine.list': {
                    templateUrl: 'applications/application.list.html',
                    controller: 'ApplicationListCtrl'
                },
                'renewals@licenses.combine.list': {
                    templateUrl: 'renewals/wizard/select.html',
                    params: {
                        cache: true
                    },
                    controller: 'RenewalSelectionCtrl',
                }
            }
        });
    }
])

/**
 * Www routes controllers
 */
.controller('LicenseListCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'LicenseService',
    function($scope, $state, $http, DTOptionsBuilder, LicenseService) {

        $scope.licenses = [];
        $scope.selectedLicenseIds = [];
        $scope.loading = true;

        /**
         * Initializers
         */
        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [1, 'asc'])
        .withOption('aoColumnDefs', [
            {"bSortable": false, "aTargets": [0]}
        ]);

        /**
         * Load licenses
         */
        LicenseService.getLicenses($scope.roleContext.ciprid)
        .then(function(res) {
            $scope.licenses = res.data;

            // Default select all for company
            if(!$scope.isPerson()){
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });
            }
            $scope.loading = false;
        });

        // -- Called from the form --

        $scope.updateAllSelection = function($event) {
            var checkbox = $event.target;

            if(checkbox.checked) {
                _.forEach($scope.licenses, function(license) {
                    $scope.selectedLicenseIds.push(license.licenseid);
                });
            }

            if(!checkbox.checked) {
                $scope.selectedLicenseIds = [];
            }
        };

        /**
         * Called when checkbox is clicked.
         *
         * @param $event
         * @param licenseid
         */
        $scope.updateSelection = function($event, licenseid) {
            var checkbox = $event.target;

            if(checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) === -1) {
                $scope.selectedLicenseIds.push(licenseid);
            }

            if(!checkbox.checked && $scope.selectedLicenseIds.indexOf(licenseid) !== -1) {
                $scope.selectedLicenseIds.splice($scope.selectedLicenseIds.indexOf(licenseid), 1);
            }
        };

        /**
         * Is any license selected - used to enable Begin Wizard button.
         * @param licenseid
         * @returns {boolean}
         */
        $scope.isSelected = function(licenseid) {
            return $scope.selectedLicenseIds.indexOf(licenseid) >= 0;
        };

        /**
         * Presents a page to apply E&O policies.
         */
        $scope.addEandO = function() {
            $state.go('licenses.eando', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

        /**
         * Present a license view.
         * @param license
         */
        $scope.viewLicense = function(license) {
            $state.go('licenses.view', {licenseType: license.licensetype, licenseId: license.licenseid});
        };

        /**
         * Presents a page to edit address.
         */
        $scope.editAddress = function() {
            $state.go('licenses.address', {selectedLicenseIds: $scope.selectedLicenseIds});
        };

    }
])
.controller('LicenseCombineCtrl', ['$scope', '$state', '$http', 'DTOptionsBuilder', 'LicenseService',
    function($scope, $state, $http, DTOptionsBuilder, LicenseService) {

    }
])
.controller('LicenseCombineListCtrl', ['$scope', '$state', '$http', '$location', '$anchorScroll', '$timeout', 'DTOptionsBuilder', 'LicenseService', 'DownloadService',
    function($scope, $state, $http, $location, $anchorScroll, $timeout, DTOptionsBuilder, LicenseService, DownloadService) {

        $http.get('/' + $scope.roleContext.ciprid + '/licenses/allbadges')
        .then(function(res) {
            var kapp = _.find(res.data, {statuscd: 'KAPP'});
            var kren = _.find(res.data, {statuscd: 'KREN'});
            var kiss = _.find(res.data, {statuscd: 'KISS'});
            $scope.applicationCount = kapp ? kapp.count : 0;
            $scope.renewalCount = kren ? kren.count : 0;
            $scope.licenseCount = kiss ? kiss.count : 0;
        });

        $scope.applicationInit = function() {
            $state.go('application.init', {backtocombine: true});
        };

        /**
         * Constructs a download license history
         * @returns {string}
         */
        $scope.exportHistory = function() {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/licenses/history";
            var anchorText = 'License History.pdf';
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        $scope.scrollTo = function(hash) {
            $location.hash(hash);
            $anchorScroll();
        };

        var hash = $location.hash();
        $timeout(function() {
            if(hash) {
                $location.hash(hash);
                $anchorScroll();
            }
        }, 2000);

    }
])
.controller('LicenseAddEandOCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'blockUI', 'EAndOService', 'LicenseService', '$filter',
    function($scope, $state, $stateParams, dialogs, toastr, blockUI, EAndOService, LicenseService, $filter) {

        $scope.selectedLicenseIds = $stateParams.selectedLicenseIds;
        $scope.selectedLicenses = [];
        $scope.eligibleLicenses = [];
        $scope.policies = [];
        $scope.daystoallowvoid = 30;

        /**
         * Initializers
         */
        var eandoBlock = blockUI.instances.get('eando-block');
        eandoBlock.start();

        EAndOService.getDaysToAllowVoid($scope.roleContext.ciprid)
        .then(function(res) {
            var data = res.data;
            $scope.daystoallowvoid = data.daystoallowvoid ? data.daystoallowvoid : 30
        });

        LicenseService.getSelectedLicenses($scope.roleContext.ciprid, $stateParams.selectedLicenseIds)
        .then(function(res) {
            var data = res.data;
            var groupedEandos = _.groupBy(data.eandos, "licenseid");
            eandoBlock.stop();
            $scope.selectedLicenses = _.map(data.licenses, function(license) {
                var activeLicense = {};
                activeLicense.license = license;
                activeLicense.eandos = groupedEandos[license.licenseid];
                return activeLicense;
            });

            $scope.eligibleLicenses = _.filter(data.licenses, {eorequired: 'Y'});
        });

        /**
         * Presents a dialog to add a new policy
         */
        $scope.addPolicy = function() {
            var insurancecategories = _.chain($scope.selectedLicenses)
            .groupBy(function(renewal) {
                return renewal.license.insurancecategory;
            })
            .keys()
            .value();

            var licensetypes = _.chain($scope.selectedLicenses)
            .groupBy(function(renewal) {
                return renewal.license.licensetype;
            })
            .keys()
            .value();

            var data = {
                title: 'Add Errors and Omissions Coverage to ' + $scope.selectedLicenses.length + ' license(s)',
                insurancecategories: insurancecategories,
                selectedLicenses: $scope.selectedLicenses
            };

            dialogs.create('eando/dialogs/add.html', 'EandOAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                var addpolicy = function(l) {
                    if(l.license.eorequired != 'Y') return l;

                    l.eandos = _.isArray(l.eandos) ? l.eandos : [];
                    var licEandO = _(eando).clone();
                    licEandO.duplicate = EAndOService.checkIfDuplicate(l.eandos, eando);
                    l.eandos.push(licEandO);

                    return l;
                };

                $scope.selectedLicenses = _.map($scope.selectedLicenses, addpolicy);
                $scope.policies.push(eando);
            });
        };

        /**
         * Presents a dialog to edit a policy
         */
        $scope.deleteEandO = function(eando) {
            var insurancecategories = _.chain($scope.selectedLicenses)
                .groupBy(function(renewal) {
                    return renewal.license.insurancecategory;
                })
                .keys()
                .value();

            var licensetypes = _.chain($scope.selectedLicenses)
                .groupBy(function(renewal) {
                    return renewal.license.licensetype;
                })
                .keys()
                .value();

            var data = {
                title: 'Edit Errors and Omissions Coverage',
                selectedLicenses: $scope.selectedLicenses
            };

            dialogs.create('eando/dialogs/add.html', 'EandOAddDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(eando) {
                var addpolicy = function(l) {
                    if(l.license.eorequired != 'Y') return l;

                    l.eandos = _.isArray(l.eandos) ? l.eandos : [];
                    eando.duplicate = EAndOService.checkIfDuplicate(l.eandos, eando);
                    l.eandos.push(eando);

                    return l;
                };

                $scope.selectedLicenses = _.map($scope.selectedLicenses, addpolicy);
                if(!eando.duplicate) $scope.policies.push(eando);
            });
        };

        /**
         * Presents a dialog to add a new policy
         */
        $scope.saveLicenses = function() {
            $scope.saving = true;
            var licenseids = _.map($scope.eligibleLicenses, 'licenseid');
            if(licenseids.length == 0) {
                toastr.warning('Nothing to do!', 'Licenses selected here do not require an E&O policy.');
                return;
            }

            EAndOService.addPoliciesToLicenses($scope.roleContext.ciprid, $scope.policies, licenseids)
            .then(function() {
                $scope.saving = false;
                var l = $scope.eligibleLicenses.length;
                _.forEach($scope.policies, function(policy) {
                    toastr.success('Success!', 'Policy by ' + policy.carriername + ' has been added to ' + l + ' license(s).');
                    $state.go($state.current, {selectedLicenseIds: $scope.selectedLicenseIds}, {reload: true});
                });
            }, function() {
                $scope.saving = false;
                toastr.error('Error!', 'An error occurred while applying policies. Please try again.');
            });
        };

        $scope.deleteEandO = function(eando) {

            var onsuccess = function(res) {
                var data = res.data;
                if(data.error) {
                    var errorval = data.error;
                    toastr.error('Error!', errorval);
                } else {
                    toastr.success('Success!', 'Policy has been deleted.');
                    eando.deleted = true;
                }
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Exam Schedule cannot be able to deleted.');
            };

            EAndOService.delete($scope.roleContext.ciprid, eando).then(onsuccess, onerror);
        };

        $scope.allowVoid = function(eando) {
            var bufferTime = moment(eando.createddate).add(30, 'd');
            return bufferTime.isAfter(moment());
        }
    }
])
.controller('LicenseEditAddress', ['$scope', '$state', '$stateParams', 'dialogs', 'toastr', 'EAndOService', 'LicenseService', 'ApplicationService',
    function($scope, $state, $stateParams, dialogs, toastr, EAndOService, LicenseService, ApplicationService) {

        $scope.selectedLicenseIds = $stateParams.selectedLicenseIds;
        $scope.selectedLicenses = [];
        $scope.saveChanges = false;
        $scope.address = undefined;
        $scope.currentDate = moment().format('MMMM DD, YYYY');
        /**
         * Initializers
         */
        LicenseService.getSelectedLicenses($scope.roleContext.ciprid, $stateParams.selectedLicenseIds)
        .then(function(res) {
            var data = res.data;

            $scope.selectedLicenses = _.map(data.licenses, function(license) {
                var activeLicense = {};
                activeLicense.license = license;
                activeLicense.address = ApplicationService.copyAddress(license);
                return activeLicense;
            });

        });


        $scope.editAddress = function(selectedLicenses) {
            var isMultiple = selectedLicenses.length > 1;
            selectedLicenses[0].address.isMultiple = isMultiple;

            var data = {
                address:selectedLicenses[0].address,
                isPerson:$scope.isPerson()
            };
            dialogs.create('licenses/address/dialogs/edit.html', 'LicenseAddressEditDialogCtrl', data, {
                'keyboard': false,
                'backdrop': 'static',
                'size': 'lg'
            }).result.then(function(address) {
                $scope.address = address;
                $scope.selectedLicenses = _.map(selectedLicenses, function(license) {
                    license.address = address;
                    return license;
                });
            });
        };

        /**
         * Save licenses
         */
        $scope.saveLicenses = function() {
            LicenseService.saveLicensesAddress($scope.roleContext.ciprid, $scope.address, $scope.selectedLicenseIds)
            .then(function() {
                var l = $scope.selectedLicenses.length;
                $scope.address = undefined;
                toastr.success('Success!', 'Address  has been updated to ' + l + ' license(s).');
            }, function() {
                toastr.error('Error!', 'An error occurred while updating address. Please try again.');
            });
        }

        $scope.onCopySuccess = function(e) {
            toastr.success('Success!', 'Address has been copied.');
            e.clearSelection();
        };
    }
])
/**
 * Dialog and modal controllers
 */
.controller('LicenseAddressEditDialogCtrl', ['$scope', '$uibModalInstance', 'AddressService', 'data',
    function($scope, $uibModalInstance, AddressService, data) {

        $scope.address = data.address;
        $scope.isPerson = data.isPerson;

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };

        $scope.setCity = function(item, model, label) {
            $scope.address.city = model.cityname;
            $scope.address.cityid = model.cityid;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.address.province = model.provname;
            $scope.address.provinceid = model.provid;
            $scope.address.provabbrev = model.provabbreviation;
        };

        $scope.setCountry = function(item, model, label) {
            $scope.address.country = model.countryname;
            $scope.address.countryid = model.countryid;
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            $uibModalInstance.close($scope.address);
        };

        $scope.checkCity = function() {
            if(!$scope.address.cityid) {
                $scope.address.city = "";
            }
        };
        $scope.checkProvince = function() {
            if(!$scope.address.provinceid) {
                $scope.address.province = "";
            }
        };
        $scope.checkCountry = function() {
            if(!$scope.address.countryid) {
                $scope.address.country = "";
            }
        };

        $scope.$watch('noResultsCity', function(noNewCity) {
            if(noNewCity){
                $scope.address.cityid = "";
                return;
            }
        });
        $scope.$watch('noResultsCountry', function(noNewCountry) {
            if(noNewCountry){
                $scope.address.countryid = "";
                return;
            }
        });
        $scope.$watch('noResultsProvince', function(noNewProvince) {
            if(noNewProvince){
                $scope.address.provinceid = "";
                return;
            }
        });

    }
])

.controller('LicenseViewCtrl', ['$scope', '$state', '$stateParams', '$http', '$sce', 'DownloadService',
    function($scope, $state, $stateParams, $http, $sce, DownloadService) {
        $scope.license = {};
        $http.get('/' + $scope.roleContext.ciprid + '/licenses/' + $stateParams.licenseId + "/active", {
            params: {
                licensetype: $stateParams.licenseType
            }
        })
        .then(function(res) {
            var data = res.data;
            $scope.license = data.license;
            $scope.address = $sce.trustAsHtml(data.license.address_formatted);
            $scope.employerName = $sce.trustAsHtml(data.license.employer_nm);
            $scope.isCorp = data.license.lic_type == 'C' || data.license.lic_type == 'CO';
        });

        /**
         * Constructs a download license
         * @returns pdf
         */
        $scope.downloadLicense = function(license) {
            var anchorHref = "/" + $scope.roleContext.ciprid + "/licenses/" + $stateParams.licenseId + "/active/pdf?licensetype="+$stateParams.licenseType;
            var anchorText = license.license + '.pdf';
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };
    }
])

/**
 * Licenses services
 */
.service('LicenseService', ['$http', '$q',
    function($http, $q) {

        this.getLicenses = function(ciprId) {
            return $http.get('/' + ciprId + '/licenses/issued');
        };

        this.getSelectedLicenses = function(ciprid, licenseids) {
            var deferred = $q.defer();
            deferred.resolve([]);

            return !_.isArray(licenseids) || licenseids.length == 0 ? deferred.promise : $http({
                url: '/' + ciprid + '/licenses/particulars',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {
                    'licenseids': licenseids,
                    'details': ['eandos']
                }
            });
        };

        this.saveLicensesAddress = function(ciprid, address, licenseids) {
            return $http({
                url: '/' + ciprid + '/licenses/address',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {address: address, licenseids: licenseids}
            });
        };
    }
]);
