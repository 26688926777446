var crimcheck = angular.module('aic.crimcheck', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations'
]);

crimcheck.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('crimcheck', {
            parent: 'workspace',
            abstract: true,
            templateUrl: 'crimcheck/crimcheck.html'
        })
        .state('crimcheck.summary', {
            url: '/crimcheck/summary',
            templateUrl: 'crimcheck/crimcheck.summary.html',
            controller: 'CrimCheckSummaryCtrl'
        });
    }
])

/**
 * Controller.
 */
.controller('CrimCheckSummaryCtrl', ['$scope', '$state', '$stateParams', '$http', 'DTOptionsBuilder', 'SweetAlert', 'FileUploader', 'CrimCheckService', 'DownloadService',
    function($scope, $state, $stateParams, $http, DTOptionsBuilder, SweetAlert, FileUploader, CrimCheckService, DownloadService) {
        $scope.crimchecks = [];
        $scope.loading = true;
        $scope.types = [
            {
                id: 'BACKCHECK',
                description: 'I have obtained and shared my BackCheck criminal record check with the AIC '
            },
            {id: 'MANUAL', description: 'I have obtained a criminal record check from the RCMP or Police '},
            {id: '', description: 'I would like to apply for a BackCheck criminal record check '}
        ];
        $scope.newCrimCheckForm = false;
        $scope.showAllCrimChecks = false;

        var limitFilter = {
            name: 'limitFilter',
            fn: function() {
                return this.queue.length < 2;
            }
        };

        var pdfFilter = {
            name: 'pdfFilter',
            fn: function(item) {
                return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
            }
        };

        var setFileUploader = function() {
            $scope.crimchecks = _.map($scope.crimchecks, function(cc, index) {
                cc.uploader = new FileUploader({
                    autoUpload: true,
                    filters: [limitFilter, pdfFilter],
                    headers: {
                        Authorization: 'Bearer ' + $scope.jwtToken
                    },
                    onSuccessItem: function(item, response, status, headers) {
                        toastr.success('Success!', 'Successfully uploaded file ' + item.file.name);
                        cc.displayname = item.file.name;
                    },
                    onErrorItem: function(item, response, status, headers) {
                        toastr.error('Error!', 'Error uploading File ' + item.file.name);
                    }
                });

                return cc;
            });
        };

        /**
         * Initializers
         */
        // Iniatlize uploader with autoupload false when they select police security clearance
        $scope.uploader = new FileUploader({
            autoUpload: false,
            filters: [limitFilter, pdfFilter],
            headers: {
                Authorization: 'Bearer ' + $scope.jwtToken
            },
            onSuccessItem: function(item, response, status, headers) {
                toastr.success('Success!', 'Successfully uploaded file ' + item.file.name);
                $('#uploaderId').val("");
                $scope.getCrimChecks();
            },
            onErrorItem: function(item, response, status, headers) {
                toastr.error('Error!', 'Error uploading File ' + item.file.name);
            }
        });

        // -- Called from form --

        $scope.getAllCrimChecks = function($event) {
            var checkbox = $event.target;
            $scope.showAllCrimChecks = checkbox.checked;
            CrimCheckService.getCrimChecks($scope.roleContext.ciprid, $scope.showAllCrimChecks, "")
            .then(function(res) {
                var data = res.data;
                $scope.crimchecks = data || [];
                $scope.loading = false;
                setFileUploader();
            });
        };

        $scope.getCrimChecks = function() {
            CrimCheckService.getCrimChecks($scope.roleContext.ciprid, $scope.showAllCrimChecks, "")
            .then(function(res) {
                var data = res.data;
                $scope.crimchecks = data || [];
                $scope.loading = false;
                setFileUploader();
            });
        };

        $scope.getCCTypeDescription = function(type) {
            return _.filter($scope.types, {id: type})[0].description
        };

        $scope.save = function(cctype) {
            $scope.alerts = [];

            var onsuccess = function(res) {
                $scope.newCrimCheckForm = false;

                var crimcheck = res.data[0];
                if(crimcheck.type == 'MANUAL') {
                    $scope.upload(crimcheck);
                } else {
                    $scope.getCrimChecks();
                }
                toastr.success('Success!', 'Successfully saved Criminal Record Check.');
            };
            var onerror = function(data, status) {
                toastr.error('Error!', 'Unable to save Crim check record at this time.');
            };

            CrimCheckService.save($scope.roleContext.ciprid, cctype.id).then(onsuccess, onerror);
        };

        /**
         * upload file and set the url with crimcheckid
         * @param crimcheck
         */
        $scope.upload = function(crimcheck) {
            var items = $scope.uploader.getNotUploadedItems();
            _.map(items, function(item, index) {
                item.url = '/' + $scope.roleContext.ciprid + '/crimcheck/' + crimcheck.id + '/upload?lholderid=' + crimcheck.partyid;
            });
            $scope.uploader.uploadAll();
        };

        /**
         * void crimcheck record
         * @param cc
         */
        $scope.remove = function(cc) {
            SweetAlert.swal({
                title: "Void Criminal Record Check",
                text: "Are you sure you want to void criminal record check?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed) {
                    CrimCheckService.remove($scope.roleContext.ciprid, cc.id)
                    .then(function() {
                        toastr.success('Success!', 'Criminal record check has been voided.');
                        $scope.crimchecks = _.reject($scope.crimchecks, {'id': cc.id});
                    }, function() {
                        toastr.error('Error!', 'Error voiding criminal record check');
                    })
                }
            });
        };

        /**
         * Constructs a download link
         * @param crimcheck
         * @returns {string}
         */
        $scope.downloadLink = function(crimcheck) {
            var anchorText = crimcheck.displayname;
            var anchorHref = S("/{{ciprid}}/crimcheck/{{crimcheckid}}/download/{{filename}}?party={{partyid}}")
            .template({
                ciprid: $scope.roleContext.ciprid,
                partyid: crimcheck.partyid,
                crimcheckid: crimcheck.id,
                filename: crimcheck.displayname
            }).s;

            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        $scope.change = function(cctype) {
            if(!cctype.id) window.open('https://pages.sterlingbackcheck.ca/landing-pages/a/aic/', '_blank');
        };

        $scope.$on('$viewContentLoaded', function() {
            $scope.getCrimChecks();
        });
    }
])
/**
 * Services
 */
.service('CrimCheckService', ['$http',
    function($http) {

        this.getCrimChecks = function(ciprid, all, lholderid) {
            return $http.get('/' + ciprid + '/crimcheck/summary/', {
                params: {
                    all: all,
                    lholderid: lholderid
                }
            });
        };

        this.getCurrent = function(ciprid, lholderid) {
            return $http.get('/' + ciprid + '/crimcheck/', {
                params: {
                    lholderid: lholderid
                }
            });
        };

        this.remove = function(ciprId, crimcheckid) {
            return $http.put('/' + ciprId + '/crimcheck/' + crimcheckid + '/void');
        };

        this.trash = function(ciprId, crimcheckid) {
            return $http.delete('/' + ciprId + '/crimcheck/' + crimcheckid);
        };

        this.notOk = function(ciprId, crimcheckid) {
            return $http.post('/' + ciprId + '/crimcheck/' + crimcheckid + '/notok');
        };

        this.ok = function(ciprId, id, expirydate) {
            return $http.post('/' + ciprId + '/crimcheck/' + id + '/ok', {
                data: {
                    expirydate: expirydate
                }
            });
        };

        this.save = function(ciprId, type, lholderid) {
            return $http.put('/' + ciprId + '/crimcheck/', {
                data: {
                    type: type,
                    lholderid: lholderid
                }
            })
        };

        this.getDaysToExpire = function(ciprid) {
            return $http.get('/' + ciprid + '/crimcheck/daystoexpire/');
        };

    }
]);

