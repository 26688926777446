var cecourses = angular.module('aic.cecourses', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.subscribe',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

var courseDeliveryMethods = [
    { id: "CLS   ", code: "CLS", description: "Classroom"},
    { id: "COR   ", code: "COR", description: "Correspondence"},
    { id: "INT   ", code: "INT", description: "Internet"},
    { id: "MUL   ", code: "MUL", description: "Multiple"},
    { id: "SEM   ", code: "SEM", description: "Seminar"}
];


var courseStatuses = [
    { id: "YABAN ", code: "YABAN", description: "Abandoned", style:"label-danger", readOnly: true},
    { id: "YAGEN ", code: "YAGEN", description: "On Agenda", style:"label-warning", readOnly: false},
    { id: "YAPPR ", code: "YAPPR", description: "Approved", style:"label-success", readOnly: false},
    { id: "YDECL ", code: "YDECL", description: "Declined", style:"label-danger", readOnly: false},
    { id: "YPEND ", code: "YPEND", description: "Pending", style:"label-warning", readOnly: false},
    { id: "YVOID ", code: "YVOID", description: "Void CE Course", style:"label-danger", readOnly: true}
];

var providerStatuses = [
    { id: "VPROV ", code: "VPROV", description: "CE Provider"},
    { id: "VACCR ", code: "VACCR", description: "CE Provider - Self Accredited"}
];

cecourses.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {
        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('cecourses', {
            parent: 'workspace',
            abstract: true,
            url: '/cecourses',
            templateUrl: 'cecourses/cecourses.html'
        })
        .state('cecourses.search', {
            url: '/search',
            params: {
                cache: true,
                resetFields: false
            },
            templateUrl: 'cecourses/cecourses.search.html',
            controller: 'CeCourseSearchCtrl'
        })
        .state('cecourses.events', {
            url: '/{ceCourseId:[0-9]+}/events',
            templateUrl: 'cecourses/cecourse.events.html',
            controller: 'CeCourseEventsCtrl'
        })
        .state('cecourses.open', {
            url: '/{ceCourseId:[0-9]+}',
            abstract: true,
            reloadOnSearch: false,
            resolve: {
                promisedCeCourse: ['$stateParams', 'CeCourseResolverService',
                    function($stateParams, CeCourseResolverService) {
                        var ceCourseId = $stateParams.ceCourseId;
                        return ceCourseId ? CeCourseResolverService.get(ceCourseId) : null;
                    }
                ]
            },
            controller: 'CeCourseOpenCtrl',
            template: '<div ui-view></div>'
        })
        .state('cecourses.open.edit', {
            url: '/edit',
            views: {
                '': {
                    templateUrl: 'cecourses/cecourses.edit.html'
                },
                'coursecontact@cecourses.open.edit': {
                    templateUrl: 'cecourses/contact/contact.edit.html'
                },
                'coursefees@cecourses.open.edit': {
                    templateUrl: 'cecourses/fees/fees.edit.html'
                }
            }
        })
        .state('cecourses.open.copy', {
            url: '/copy',
            views: {
                '': {
                    templateUrl: 'cecourses/cecourses.create.html'
                },
                'coursecontact@cecourses.open.copy': {
                    templateUrl: 'cecourses/contact/contact.edit.html'
                }
            }
        })
        .state('cecourses.create', {
            url: '/create?providerId',
                views: {
                    '': {
                        resolve: {
                            promisedCeCourse: ['$stateParams', 'CeCourseResolverService',
                                function($stateParams, CeCourseResolverService) {
                                  return null;
                                }
                            ]
                        },
                        templateUrl: 'cecourses/cecourses.create.html',
                        controller: 'CeCourseOpenCtrl'
                    },
                    'coursecontact@cecourses.create': {
                        templateUrl: 'cecourses/contact/contact.edit.html'
                    }
                }
        });
    }
])
.controller('CeCourseSearchCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'blockUI', 'toastr', 'SweetAlert', 'CeCourseService', 'EventService',
    function($scope, $state, $stateParams, DTOptionsBuilder, blockUI, toastr, SweetAlert, CeCourseService, EventService) {

        if($stateParams.resetFields)CeCourseService.reset();

        $scope.datePickerFormat = 'M dd, yyyy';
        $scope.cecourses = CeCourseService.cecourses;
        $scope.ceProviders = CeCourseService.ceProviders;
        $scope.searchStr = CeCourseService.searchStr;
        $scope.cecourse = CeCourseService.cecourse;
        $scope.simple = CeCourseService.simple;
        $scope.lookupType = CeCourseService.lookupType;
        $scope.courseStatuses = CeCourseService.getCourseStatuses();
        $scope.search = true;

        $scope.dtOptions = DTOptionsBuilder
        .newOptions()
        .withBootstrap()
        .withOption('order', [0, 'desc'])
        .withOption('stateSave', true);

        CeCourseService.getProviders()
            .then(function(response){
                $scope.providers = response.data;
                CeCourseService.setCeProviders($scope.providers);
        });


        $scope.searchCeCourse = function() {
            $scope.loading = true;
            CeCourseService.search($scope.searchStr)
            .then(function(res) {
                $scope.loading = false;
                $scope.cecourses = res.data;
                setCourseDetails($scope.cecourses);
                CeCourseService.setCeCourses($scope.cecourses);
            });
        };

        $scope.advancedSearch = function(cecourse) {
            $scope.loading = true;
            CeCourseService.advancedSearch(cecourse)
                .then(function(res) {
                    $scope.loading = false;
                    $scope.cecourses = res.data;
                    setCourseDetails($scope.cecourses);
                    CeCourseService.setCeCourses($scope.cecourses);
                });
        };
        var setCourseDetails = function(courses) {
            _.forEach(courses, function(cecourse) {
                cecourse.delvMethod = _.find(courseDeliveryMethods, function(d) { return d.id  == cecourse.deliveryMethod; });
                cecourse.stat = _.find(courseStatuses, function(s) { return s.id  == cecourse.scnrioCd; });
            });
        };

        $scope.clear = function() {
            $scope.cecourse = {};
        };

        $scope.viewCeCourse = function(course) {
            $state.go('cecourses.open.edit', {ceCourseId: course.ceCourseId});
        };

        $scope.voidCeCourse = function(ceCourse) {
            SweetAlert.swal({
                    title: "Delete course.",
                    text: "Are you sure you want to void \n" + ceCourse.ceCourseNm + "?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#f2784b",
                    confirmButtonText: "Yes!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function(confirmed) {
                    if(confirmed) {
                        var onsuccess = function(res) {
                            var data = res.data;
                            if(data.error) {
                                toastr.error('Error!', data.error);
                            } else {
                                toastr.success('Success!', 'CE Course ' + ceCourse.ceCourseId + ' has been deleted.');

                            }
                        };
                        var onerror = function(res) {
                            toastr.error('Error!', 'CE Course cannot be deleted.');
                        };
                        CeCourseService.delete(ceCourse.ceCourseId).then(onsuccess, onerror);
                        $scope.cecourses = _.without($scope.cecourses, ceCourse);
                        $scope.dtOptions.reloadData();
                    }
                });
        };

        $scope.copyCeCourse = function(course) {
            $state.go('cecourses.open.copy', {ceCourseId: course.ceCourseId});
        };

    }
])
.controller('CeCourseEventsCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', '$previousState', 'EventService',
    function($scope, $state, $stateParams, DTOptionsBuilder, $previousState, EventService) {
        var courseId = $stateParams.ceCourseId;
        EventService.getCeCourseEvents(courseId)
            .then(function(res) {
                $scope.events = res.data;
                $scope.ceCourseId = courseId;
            });

        $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('processing', true)
            .withOption('order', []);

        $scope.goPrevious = function() {
            $previousState.go();
        };
   }
])
.controller('CeCourseOpenCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', '$filter',  'blockUI', 'toastr', '$uibModal', 'promisedCeCourse', 'CeCourseService', 'AddressService', 'InvoiceService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, $filter, blockUI, toastr, $uibModal, promisedCeCourse, CeCourseService, AddressService, InvoiceService) {

        $scope.datePickerFormat = 'M dd, yyyy';
        var dbFormat = "yyyy-MM-dd";
        $scope.deliveryMethods = CeCourseService.getDeliveryMethods();
        $scope.courseStatuses = CeCourseService.getCourseStatuses();
        $scope.createErrors = [];
        $scope.invoice = undefined;
        $scope.invoices = [];

        $scope.isCopy = $state.current.url == "/copy";
        $scope.isEdit = $state.current.url == "/edit";
        $scope.isCreate = $state.current.url == "/create";


        var initCreateForm = function() {
            $scope.cecourse = {};
            $scope.cecourse.startDate = new Date();
            $scope.cecourse.endDate =  moment($scope.cecourse.startDate,  $scope.datePickerFormat).add(3, 'y').toDate();
            $scope.cecourse.receivedDate = new Date();
            $scope.cecourse.cityNm = VARIOUS;
            $scope.cecourse.cityId = VARIOUS_ID;
            $scope.cecourse.provNm = $scope.cecourse.cntctProvNm = ALBERTA;
            $scope.cecourse.provId = $scope.cecourse.cntctProvId  = ALBERTA_ID;
            $scope.cecourse.cntctCntryNm = CANADA;
            $scope.cecourse.cntctCntryId = CANADA_ID;
            $scope.cecourse.cntctAreaCode = "403";
            $scope.cecourse.courseStatus = _.find($scope.courseStatuses, {code: "YPEND"});
            $scope.cecourse.deliveryMethod = _.find($scope.deliveryMethods, {code: "SEM"});
            CeCourseService.getProviders()
                .then(function(response){
                    $scope.providers = response.data;
                    if($stateParams.providerId){
                        CeCourseService.getProvider($stateParams.providerId)
                            .then(function(response){
                                $scope.cecourse.courseProvider = response.data[0];
                            });
                    }
           });
        };

        var initEditForm = function() {
            $scope.cecourse = promisedCeCourse;
            $scope.cecourse.deliveryMethod = _.find($scope.deliveryMethods, {id: $scope.cecourse.deliveryMethod});
            $scope.cecourse.courseStatus = _.find($scope.courseStatuses, {id: $scope.cecourse.scnrioCd});
            $scope.readOnly = $scope.cecourse.courseStatus.readOnly;
            CeCourseService.getProvider($scope.cecourse.ceProviderId)
                .then(function(response){
                    $scope.cecourse.courseProvider = response.data[0];
                    CeCourseService.getInvoices($scope.cecourse.courseProvider.orgSeq || $scope.cecourse.courseProvider.stkSeq)
                        .then(function(response){
                            $scope.invoices.push({
                                'invoiceid': 'new'
                            });
                            $scope.invoices = $scope.invoices.concat(response.data);

                        });
                });
        };


        if(promisedCeCourse) initEditForm();
        else initCreateForm();

        if($scope.isCopy){
            $scope.readOnly = false;
            $scope.cecourse.ceCourseNm = undefined;
            $scope.cecourse.courseStatus = _.find( $scope.courseStatuses, {code: "YPEND"});
        }

        $scope.goPrevious = function() {
            $previousState.go();
        };

        $scope.cities = function(phrase) {
            return AddressService.getCities(phrase);
        };

        $scope.provinces = function(phrase) {
            return AddressService.getProvinces(phrase);
        };

        $scope.countries = function(phrase) {
            return AddressService.getCountries(phrase);
        };

        $scope.setCity = function(item, model, label) {
             $scope.cecourse.cityId = model.cityid;
             $scope.cecourse.cityNm = model.cityname;
        };

        $scope.setProvince = function(item, model, label) {
            $scope.cecourse.provNm = model.provname;
            $scope.cecourse.provId = model.provid;
            $scope.cecourse.provAbbrev = model.provabbreviation;
        };

        $scope.checkCity = function() {
            if(!$scope.cecourse.cityNm) {
                $scope.cecourse.cityId = "";
            }
        };
        $scope.checkProvince = function() {
            if(!$scope.cecourse.provNm) {
                $scope.cecourse.provId = "";
            }
        };

        $scope.setContactCity = function(item, model, label) {
            $scope.cecourse.cntctCityNm = model.cityname;
            $scope.cecourse.cntctCityId = model.cityid;
        };

        $scope.setContactProvince = function(item, model, label) {
            $scope.cecourse.cntctProvNm = model.provname;
            $scope.cecourse.cntctProvId = model.provid;
            $scope.cecourse.cntctProvAbbrev = model.provabbreviation;
        };

        $scope.setContactCountry = function(item, model, label) {
            $scope.cecourse.cntctCntryId = model.countryid;
            $scope.cecourse.cntctCntryNm = model.countryname;
        };

        $scope.checkContactCity = function() {
            if(!$scope.cecourse.cntctCityNm) {
                $scope.cecourse.cntctCityId = "";
            }
        };
        $scope.checkContactProvince = function() {
            if(!$scope.cecourse.cntctProvNm) {
                $scope.cecourse.cntctProvId = "";
            }
        };
        $scope.checkContactCountry = function() {
            if(!$scope.cecourse.cntctCntryNm) {
                $scope.cecourse.cntctCntryId = "";
            }
        };

        $scope.$watch('noResultsCity', function(noNewCity) {
            if(noNewCity) {
                $scope.cecourse.cityId = "";
                return;
            }
        });

        $scope.$watch('noResultsProvince', function(noNewProvince) {
            if(noNewProvince) {
                $scope.cecourse.provId = "";
                return;
            }
        });

        $scope.$watch('noResultsContactCity', function(noNewContactCity) {
            if(noNewContactCity) {
                $scope.cecourse.cntctCityId = "";
                return;
            }
        });

        $scope.$watch('noResultsContactProvince', function(noNewContactProvince) {
            if(noNewContactProvince) {
                $scope.cecourse.cntctProvId = "";
                return;
            }
        });

        $scope.$watch('noResultsContactCountry', function(noNewContactCountry) {
            if(noNewContactCountry) {
                $scope.cecourse.cntctCntryId = "";
                return;
            }
        });
        $scope.setStartDate = function(selectedDate) {
             $scope.cecourse.startDate = $filter('date')(selectedDate, dbFormat);
        };
        $scope.setEndDate = function(selectedDate) {
            $scope.cecourse.endDate = $filter('date')(selectedDate,dbFormat);
        };
        $scope.setReceivedDate = function(selectedDate) {
            $scope.cecourse.receivedDate = $filter('date')(selectedDate, dbFormat);
        };
        $scope.setAgendaDate = function(selectedDate) {
            $scope.cecourse.agendaDate = $filter('date')(selectedDate,dbFormat);
        };
        $scope.setApprovedDate = function(selectedDate) {
            $scope.cecourse.approvedDate = $filter('date')(selectedDate, dbFormat);
        };

        var validateCourse = function(ceCourse) {
            if (ceCourse.courseProvider.scnrioCd.trim() != 'VACCR' && ceCourse.self_accr_yn == 'Y'){
                $scope.createErrors.push("The CE Provider is NOT marked as Self-Accredited and you have indicated that the course is self-accredited. Please reset the course Self-Accreditation flag.");
            }
        };

        $scope.createCeCourse = function(isCopy) {
            $scope.createErrors = [];
            validateCourse($scope.cecourse);

            var onsuccess = function(res) {
                $scope.createRequestInProgress = false;
                if(!_.isEmpty(res.data.error) || res.data.length==0) {
                    toastr.error('Error!',  "Error during CE Course creation.");
                }else{
                    $state.go(isCopy ? 'cecourses.open.copy' : 'cecourses.open.edit', {ceCourseId: res.data[0].id});
                    toastr.success('Success!', 'CE Course successfully created.');
                }
            };
            var onerror = function() {
                $scope.createRequestInProgress = false;
                toastr.error('Error!', 'CE Course cannot be created at this time.');
            };

            if($scope.createErrors.length == 0){
                $scope.createRequestInProgress = true;
                CeCourseService.createCourse($scope.cecourse, "YPEND")
                    .then(onsuccess, onerror);
            }
        };

        $scope.updateCeCourse = function() {
            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error)) {
                    toastr.error('Error!', "Error during CE Course update.");
                }else{
                    toastr.customSuccess('Success!', 'CE Course successfully updated.', function() {  $state.reload(); } );
                }
            };
            var onerror = function() {
                toastr.error('Error!', 'CE Course cannot be updated at this time.');
            };

            CeCourseService.updateCourse($scope.cecourse).then(onsuccess, onerror);
        };

        $scope.clear = function() {
            if($scope.isEdit) initEditForm();
            if($scope.isCreate) initCreateForm();
        };

        $scope.reload = function() {
            $state.reload();
        };

        $scope.$watch('cecourse.startDate', function(startDate,oldDate) {
             if(oldDate !== startDate) {
                var newEndDate =  moment($scope.cecourse.startDate).add(3, 'y').format("ll");
                $scope.cecourse.endDate =   newEndDate;
            }
        });

        /**
         * INVOICING
         */
        $scope.selectInvoice = function(item, model) {
            $scope.invoice = model;
        };

        $scope.addToInvoice = function() {
            if($scope.invoice.invoiceid == 'new') $scope.addToNewInvoice();
            else $scope.addToExistingInvoice();
        };

        $scope.addToExistingInvoice = function() {
            InvoiceService.addCECoursesToInvoice($scope.invoice.invoiceid, [$scope.cecourse])
                .then(function() {
                   toastr.success('Success!', 'CE Course added to invoice');
                    $scope.cecourse.invoiceid = $scope.invoice.invoiceid;
                }, function() {
                    toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
                });
        };

        $scope.removeFromInvoice = function() {
            InvoiceService.removeCECourseFromInvoice($scope.cecourse.invoiceid, $scope.cecourse.ceCourseId)
                .then(function() {
                    toastr.success('Success!', 'CE Course removed from invoice ' + $scope.cecourse.invoiceid);
                    $scope.cecourse.invoiceid = undefined;
                }, function() {
                    toastr.error('Error!', 'An error occurred while removing CE Course from invoice. Please try again.');
                });
        };

        $scope.addToNewInvoice = function() {
            InvoiceService.addCECoursesToNewInvoice($scope.cecourse.courseProvider.orgSeq || $scope.cecourse.courseProvider.stkSeq, [$scope.cecourse])
                .then(function(res) {
                    var invoiceId = res.data[0].invoiceId;
                    toastr.success('Success!', 'CE Course added to invoice ' +  invoiceId);
                    $scope.cecourse.invoiceid = invoiceId;
                }, function() {
                    toastr.error('Error!', 'An error occurred while adding to invoice. Please try again.');
                });
        };
    }
])
/**
 * CeCourse services
 */
.service('CeCourseService', ['$http', '$q',
    function($http, $q) {
        this.cecourses = [];
        this.ceProviders = [];
        this.searchStr = undefined;
        this.cecourse =[];
        this.simple = true;
        this.lookupType = "numName";

        this.setProviderSearchStr = function(searchStr) {
            this.searchStr = searchStr;
        };

        this.setProvider = function(provider) {
            this.provider = provider;
        };

        this.setCeCourses = function(cecourses) {
            this.cecourses = cecourses;
        };

        this.setCeProviders = function(ceProviders) {
            this.ceProviders = ceProviders;
        };


        this.search = function(searchStr) {
            this.searchStr = searchStr;
            this.simple = true;
            return $http.get('/staff/cecourses/search?searchStr=' + searchStr);
        };

        this.advancedSearch = function(ceCourse) {
            this.cecourse = ceCourse;
            this.simple = false;
            return $http.get('/staff/cecourses/advancedsearch/', {
                params: {
                    provider: ceCourse.provider ? ceCourse.provider.providerId : null,
                    name: ceCourse.courseName,
                    status: ceCourse.courseStatus ? ceCourse.courseStatus.id : null,
                    agendaDate: ceCourse.agendaDate
                }
            });
        };

        this.delete = function(cecourseId) {
            return $http.delete('/staff/cecourses/'+ cecourseId);
        };

        this.getDeliveryMethods = function() {
            return courseDeliveryMethods;
        };

        this.getCourseStatuses = function() {
            return courseStatuses;
        };

        this.getProviders = function() {
            return $http.get('/staff/ceproviders/all/', {cache: true});
        };

        this.getProvider = function(providerId) {
            return $http.get('/staff/ceproviders/' + providerId);
        };

        this.createCourse = function(course, status) {
             return $http.put('/staff/cecourses', {
                data: {
                    cecourse: course
                }
            })

        };

        this.updateCourse = function(course) {
            return $http.post('/staff/cecourses', {
                data: {
                    cecourse: course
                }
            });
        };

        this.getInvoices = function(orgId) {
            return $http.get('/staff/orgs/' + orgId + '/openinvoices/');
        };


        this.addCECoursesToInvoice = function(invoiceid, ceCourses) {
            return $http.put('/staff/invoices/items', {
                    data: {
                        'ceCourses': ceCourses
                    }
                }
            );
        };

       this.reset = function() {
            this.cecourses = {};
        };
    }
])
.service('CeCourseResolverService', ['$resource', '$http', 'CeCourseService',
        function($resource, $http, CeCourseService) {

            /**
             * Returns a promise which resolves a ce course object.
             *
             * @param ceCourseId
             * @returns {*}
             */
            this.get = function(ceCourseId) {
                return $http.get('/staff/cecourses/' + ceCourseId)
                    .then(function(res) {
                       var ceCourse =  res.data[0];
                       ceCourse.lifeReqHrs = parseFloat((parseFloat(ceCourse.lifeReqHrs)).toFixed(2));
                       ceCourse.asReqHrs = parseFloat((parseFloat(ceCourse.asReqHrs)).toFixed(2));
                       ceCourse.adjReqHrs = parseFloat((parseFloat(ceCourse.adjReqHrs)).toFixed(2));
                       ceCourse.genReqHrs = parseFloat((parseFloat(ceCourse.genReqHrs)).toFixed(2));
                       ceCourse.lifeApprHrs =  parseFloat((parseFloat(ceCourse.lifeApprHrs)).toFixed(2));
                       ceCourse.asApprHrs =  parseFloat((parseFloat(ceCourse.asApprHrs)).toFixed(2));
                       ceCourse.adjApprHrs =  parseFloat((parseFloat(ceCourse.adjApprHrs)).toFixed(2));
                       ceCourse.genApprHrs =  parseFloat((parseFloat(ceCourse.genApprHrs)).toFixed(2));
                       return ceCourse;
                    });
            };
        }
    ]);
