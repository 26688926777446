var findmycipr = angular.module('aic.findmycipr', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'dialogs.main',
    'dialogs.default-translations'
]);

findmycipr.config(['$httpProvider', '$stateProvider',
    function($httpProvider, $stateProvider) {

        $stateProvider
        .state('findmycipr', {
            parent: 'findmyciprentry',
            url: '/findmycipr',
            templateUrl: 'findmycipr/findmycipr.html',
            controller: 'FindMyCiprCtrl'
        });

    }
])
.controller('FindMyCiprCtrl', ['$scope', '$rootScope', '$state', '$http', 'blockUI', 'FindMyCiprService',
    function($scope, $rootScope, $state, $http, blockUI, FindMyCiprService) {
        $scope.examTypes = [];
        $scope.examLocs = [];
        $scope.examSchedules = [];
        $scope.insclasses = [];
        $scope.helplink = undefined;

        $scope.resultCipr = undefined;
        $scope.resultEmail = undefined;
        $scope.showFound = false;
        $scope.showNotFound = false;

        $scope.verificationTypes = [{id: 'licnum', description: 'I am currently licensed in Alberta'},
            {id: 'examinfo', description: 'I have written an Alberta exam in last six month'}];

        FindMyCiprService.getAllInfo().then(function(res) {
            $scope.examTypes = res.data.exmtypes;
            $scope.examLocs = res.data.exmlocations;
            $scope.insclasses = res.data.insclasses;
            $scope.helplink = res.data.help.personal;
        });

        $scope.getSchedules = function() {
            FindMyCiprService.getSchedules($scope.examType.id, $scope.examLoc.id).then(function(res) {
                $scope.examSchedules = res.data;
            });
        };

        $scope.$watch('examType', function() {
            $scope.examSchedules = [];
            if($scope.examType && $scope.examLoc)
                $scope.getSchedules();
        });

        $scope.$watch('examLoc', function() {
            $scope.examSchedules = [];
            if($scope.examType && $scope.examLoc)
                $scope.getSchedules();
        });

        $scope.go = function() {
            var findMyCiprBlock = blockUI.instances.get('findmycipr-block');
            findMyCiprBlock.start('Searching...');

            var params = {
                firstname: $scope.firstName,
                lastname: $scope.lastName,
                birthdate: $scope.birthDate,
                postalcode: $scope.postalCode,
                licensenumber: $scope.licenseNumber,
                examsch: $scope.examSch ? $scope.examSch.id : ''
            };

            var onsuccess = function(res) {
                var data = res.data;
                findMyCiprBlock.stop();
                if(data.length === 1 ){
                    $scope.showFound = true;
                    $scope.resultCipr = data[0].ciprno;
                    $scope.resultEmail = data[0].email;

                }else{
                    $scope.showNotFound = true;
                }
            };
            var onerror = function(res) {
                findMyCiprBlock.stop();
                $scope.error = status;
            };

            FindMyCiprService.findmycipr(params).then(onsuccess, onerror);
        };

        $scope.tryAgain = function(){
            $scope.showFound = false;
            $scope.showNotFound = false;
            $scope.examSchedules = [];
            $scope.firstName ='';
            $scope.lastName ='';
            $scope.birthDate ='';
            $scope.postalCode ='';
            $scope.verificationType = '';
            $scope.licenseNumber ='';
            $scope.examLoc ='';
            $scope.examSch = '';
            $scope.examType ='';
        };

        $scope.sendToAIC = function(){
            var findMyCiprBlock = blockUI.instances.get('findmycipr-block');
            findMyCiprBlock.start('Sending...');

            var params = {
                firstname: $scope.firstName,
                lastname: $scope.lastName,
                birthdate: $scope.birthDate,
                postalcode: $scope.postalCode,
                licensenumber: $scope.licenseNumber,
                examsch: $scope.examSch ? $scope.examSch.id : '',
                email: $scope.rawEmail,
                phone: $scope.rawPhone
            };

            var onsuccess = function(res) {
                findMyCiprBlock.stop();
                alert('Your request has been successfully sent to AIC.\n\nSomeone will get back to you within 3 business days.');
                $state.go('loginwithcipr');
            };
            var onerror = function(res) {
                findMyCiprBlock.stop();
                $scope.error = res.status;
            };

            FindMyCiprService.sendToAIC(params).then(onsuccess, onerror);
        }
    }
])
.
service('FindMyCiprService', ['$http',
    function($http) {

        this.getAllInfo = function() {
            return $http.get('/findmycipr');
        };

        this.getSchedules = function(examTypeId, examLocId) {

            return $http.get('/lookup/exam/schedules', {
                params: {
                    location: examLocId,
                    exmtype: examTypeId
                }
            });
        };

        this.findmycipr = function(data) {
            return $http.post('/findmycipr', {
                data: data
            });
        };

        this.sendToAIC = function(data) {
            return $http.post('/findmycipr/contact/aic', {
                data: data
            });
        };

    }
]);