var documents = angular.module('aic.documents', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'ui.bootstrap',
    'ui.router',
    'blockUI',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'aic.subscribe',
    'aic.ng-jquery',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

cecourses.config(['$httpProvider', '$urlRouterProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $urlRouterProvider, $stateProvider, blockUIConfig) {
        blockUIConfig.autoInjectBodyBlock = false;
        $stateProvider
        .state('documents', {
            parent: 'workspace',
            abstract: true,
            url: '/documents',
            templateUrl: 'documents/documents.html'
        })
        .state('documents.search', {
            url: '/search',
            params: {
                cache: true
            },
            views: {
                '': {
                    templateUrl: 'documents/documents.search.html',
                    controller: 'DocumentSearchCtrl'
                },
                'documents@documents.search': {
                    templateUrl: 'documents/documents.list.table.html'
                }
            }
        })
        .state('documents.open', {
            url: '/{docId:[0-9]+}',
            abstract: true,
            resolve: {
                promisedDocument: ['$stateParams', 'DocumentResolverService',
                    function($stateParams, DocumentResolverService) {
                        var docId = $stateParams.docId;
                        return docId ? DocumentResolverService.get(docId) : null;
                    }
                ]
            },
            controller: 'DocumentOpenCtrl',
            template: '<div ui-view></div>'
        })
        .state('documents.create', {
            url: '/create',
            templateUrl: 'documents/documents.create.html',
            controller: 'DocumentCreateCtrl'
        })
        .state('documents.open.edit', {
            url: '/edit',
            views: {
                '': {
                    templateUrl: 'documents/documents.edit.html'
                },
                'info@documents.open.edit': {
                    templateUrl: 'documents/info/info.html'
                }
            }
        });
    }
])
.controller('DocumentSearchCtrl', ['$scope', '$state', '$stateParams', 'DTOptionsBuilder', 'blockUI', 'toastr', 'DocumentService', 'DownloadService',
    function($scope, $state, $stateParams, DTOptionsBuilder, blockUI, toastr, DocumentService, DownloadService) {
        $scope.searchType = DocumentService.searchType;
        $scope.datePickerFormat = 'M dd, yyyy';

        var initForm = function() {
            $scope.documents = DocumentService.documents;
            $scope.doc = DocumentService.doc;
            $scope.property = DocumentService.property;
            $scope.party = DocumentService.party;
        };

        initForm();

        $scope.dtOptions = DTOptionsBuilder
            .newOptions()
            .withBootstrap()
            .withOption('stateSave', true);

        DocumentService.getClassificationTypes()
            .then(function(res) {
                $scope.docClassificationTypes = res.data;
            });

        DocumentService.getPropertyTypes()
            .then(function(res) {
                $scope.propertyTypes = res.data;
                $scope.property.type=  _.find($scope.propertyTypes, {'type': "CMSNUM"});
            });

        $scope.searchDocument = function(doc) {
            $scope.loading = true;
            DocumentService.searchType = $scope.searchType;
            if($scope.searchType == 'docInfo'){
                DocumentService.search(doc)
                    .then(function(res) {
                        $scope.documents = res.data;
                        DocumentService.documents = $scope.documents;
                        $scope.loading = false;
                    });
            }
            if($scope.searchType == 'property'){
                DocumentService.searchByProperty($scope.property)
                    .then(function(res) {
                        $scope.documents = res.data;
                        DocumentService.documents = $scope.documents;
                        $scope.loading = false;
                    });
            }
            if($scope.searchType == 'party'){
                DocumentService.searchByParty($scope.party)
                    .then(function(res) {
                        $scope.documents = res.data;
                        DocumentService.documents = $scope.documents;
                        $scope.loading = false;
                    });
            }


        };

        $scope.editDocument = function(doc) {
            $state.go('documents.open.edit', {docId: doc.docId});
        };

        $scope.downloadLink = function(doc) {
            var anchorHref = S("/staff/documents/{{docid}}/download").template({
                docid: doc.docId
            }).s;
            var anchorText = doc.fileName;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        $scope.clear = function() {
           initForm();
        };
   }
])
.controller('DocumentCreateCtrl', ['$scope', '$state', '$stateParams', '$http', '$filter', 'DTOptionsBuilder', 'blockUI', 'toastr', 'DocumentService', 'PartyService', 'FileUploader', 'SweetAlert',
    function($scope, $state, $stateParams, $http, $filter, DTOptionsBuilder, blockUI, toastr, DocumentService, PartyService, FileUploader, SweetAlert) {
        var initForm = function() {
            $scope.doc = {};
            $scope.attachCms = false;
            $scope.attachStakeholder = false;
         };

        DocumentService.getClassificationTypes()
            .then(function(res) {
                $scope.docClassificationTypes = res.data;
            });

        initForm();

        var limitFilter = {
            name: 'limitFilter',
            fn: function() {
                return this.queue.length < 2;
            }
        };

        var pdfFilter = {
            name: 'pdfFilter',
            fn: function(item) {
                return item.type === 'application/pdf' || item.name.indexOf('pdf', item.name.length - 3) !== -1;
            }
        };

        /**
         * Initializers
         */
        // Iniatlize uploader with autoupload false
        $scope.uploader = new FileUploader({
            autoUpload: false,
            filters: [limitFilter, pdfFilter],
            headers: {
                Authorization: 'Bearer ' + $scope.jwtToken
            },
            onSuccessItem: function(item, response, status, headers) {
                $scope.createRequestInProgress = false;
                toastr.success('Success!', 'Successfully uploaded file ' + item.file.name);
                $('#uploaderId').val("");
                $state.go('documents.open.edit', {docId: item.docInfoId});
            },
            onErrorItem: function(item, response, status, headers) {
                $scope.createRequestInProgress = false;
                toastr.error('Error!', 'Error uploading File ' + item.file.name);
            }
        });

        $scope.upload = function(docInfoId) {
            var items = $scope.uploader.getNotUploadedItems();
            _.map(items, function(item, index) {
                item.url = '/staff/public/documents/' + docInfoId + '/upload';
                item.docInfoId = docInfoId;
            });
            $scope.uploader.uploadAll();
        };

        $scope.setFileName = function() {
            var items = $scope.uploader.getNotUploadedItems();
           _.map(items, function(item, index) {
                $scope.doc.fileName = item.file.name;
            });
        };

        $scope.getPartiesBoth = function(phrase, limit) {
            return PartyService.getDMSPartiesBoth(phrase, limit);
        };

        $scope.createDocument = function() {

            var onsuccess = function(res) {
                if(!_.isEmpty(res.data.error) || res.data.length==0) {
                    toastr.error('Error!',  "Error during Document creation.");
                }else{
                    $scope.upload(res.data[0].id);
                }
            };
            var onerror = function() {
                $scope.createRequestInProgress = false;
                toastr.error('Error!', 'Document cannot be created at this time.');
            };
            $scope.setFileName();
            if(!$scope.attachCms) $scope.doc.cmsNum = null;

            if($scope.doc.party == undefined || $scope.doc.party == null || $scope.doc.party.partyId == undefined ){
                SweetAlert.swal({
                        title: "Save Document Without a Party",
                        text: "Are you sure you want to save document without attaching a party?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#f2784b",
                        confirmButtonText: "Yes!",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function(confirmed) {
                        if(confirmed) {
                            $scope.createRequestInProgress = true;
                            DocumentService.createDocument($scope.doc).then(onsuccess, onerror);
                        }else{
                            $('#docParty').focus();
                        }
                    });
            } else{
                $scope.createRequestInProgress = true;
                DocumentService.createDocument($scope.doc).then(onsuccess, onerror);
            }
        };

        $scope.clear = function() {
            initForm();
        };
    }
])
.controller('DocumentOpenCtrl', ['$scope', '$state', '$stateParams', '$http', '$location', '$timeout', '$previousState', '$filter',  'blockUI', 'toastr', 'dialogs',  'promisedDocument', 'DocumentService', 'DownloadService',
    function($scope, $state, $stateParams, $http, $location, $timeout, $previousState, $filter, blockUI, toastr, dialogs, promisedDocument, DocumentService, DownloadService) {

        $scope.infoEditing = false;
        $scope.tabs = [{id: 'role'}, {id: 'classification'}, {id: 'property'}];
        $scope.tabactive = 0;


        var searchObj = $location.search();

        $scope.setTabActive = function(tabType) {
            $scope.tabactive = _.findIndex($scope.tabs, {id: tabType});
        };

        if($stateParams.tab || searchObj.tab) {
            var tabType = $stateParams.tab || searchObj.tab;
            $scope.setTabActive(tabType);
        }

        $scope.setLocation = function(tab) {
            $location.search('tab', tab);
            $scope.$broadcast(tab + 'TabActiveEvent');
        };


        $scope.downloadLink = function(doc) {
            var anchorHref = S("/staff/documents/{{docid}}/download").template({
                docid: doc.docId
            }).s;
            var anchorText = doc.fileName;
            DownloadService.download($scope.jwtToken, anchorHref, anchorText);
        };

        var initEditForm = function() {
            $scope.doc = promisedDocument.doc[0];
            DocumentService.getClassificationTypes()
                .then(function(res) {
                    $scope.docClassificationTypes = res.data;
                    $scope.doc.classType = _.find($scope.docClassificationTypes, {'code': $scope.doc.classificationCode});

                });
            $scope.doc.classifications = promisedDocument.classifications;
            $scope.doc.properties = promisedDocument.properties;
            $scope.doc.docRoles = promisedDocument.docRoles;
        };

        initEditForm();

        $scope.editInfo = function() {
            $scope.infoEditing = true;
        };

        $scope.viewInfo = function() {
            $scope.infoEditing = false;
         };

        $scope.goPrevious = function() {
            $previousState.go();
        };

        $scope.clear = function() {
            initEditForm();
         };

        $scope.reload = function() {
            $state.reload();
        };

        $scope.updateDocument = function(doc) {
            var onsuccess = function(res) {
                toastr.success('Success!', 'Document successfully updated.');
                $scope.viewInfo();
            };
            var onerror = function(res) {
                toastr.error('Error!', 'Unable to update document at this time. Please try later.');
            };

            DocumentService.updateDocument(doc).then(onsuccess, onerror);
        };
    }
])
    .controller('DocumentRolesCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'DocumentService',
        function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, DocumentService) {
            $scope.dtOptions = DTOptionsBuilder
                .newOptions()
                .withBootstrap()
                .withOption('order', [0, 'desc']);


            $scope.removeDocRole = function(role) {
                var onsuccess = function(res) {
                    toastr.success('Success!', 'Document role has been deleted.');
                    $scope.doc.docRoles = _.reject($scope.doc.docRoles, {'id': role.id});

                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Error deleting document role record.');
                };

                DocumentService.removeDocRole(role.id).then(onsuccess, onerror);
            };

            $scope.addDocRole = function() {
                var data = {role: undefined};

                dialogs.create('documents/roles/dialogs/addoredit.html', 'DocumentRoleAddEditDialogCtrl', data, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(role) {

                    var onsuccess = function(res) {
                        var data = res.data;
                        toastr.success('Success!', 'Document role has been created.');
                        DocumentService.getDocRoles($scope.doc.docId).then(function(res) {
                            $scope.doc.docRoles = res.data;
                        });
                    };
                    var onerror = function(res) {
                        toastr.error('Error!', 'Unable to create document role record at this time. Please try later.');
                    };

                    DocumentService.addDocRole($scope.doc.docId, role).then(onsuccess, onerror);
                });
            };
        }
    ])
    .controller('DocumentRoleAddEditDialogCtrl', ['$scope', '$uibModalInstance','data', 'PartyService',
        function($scope, $uibModalInstance, data, PartyService) {
            $scope.role = data.role;
            $scope.cancel = function() {
                $uibModalInstance.dismiss('Canceled');
            };
            $scope.yes = function() {
                $uibModalInstance.close($scope.role);
            };

            $scope.getPartiesBoth = function(phrase, limit) {
                return PartyService.getDMSPartiesBoth(phrase, limit);
            };
        }
    ])
    .controller('DocumentClassificationsCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'DocumentService',
        function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, DocumentService) {

            DocumentService.getGeneralClassificationTypes()
                .then(function(res) {
                    $scope.genClassTypes = res.data;
                });

            $scope.dtOptions = DTOptionsBuilder
                .newOptions()
                .withBootstrap()
                .withOption('order', [0, 'desc']);

            $scope.removeClassification = function(classification) {
                var onsuccess = function(res) {
                    toastr.success('Success!', 'Document general classification has been deleted.');
                    $scope.doc.classifications = _.reject($scope.doc.classifications, {'id': classification.id});

                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Error deleting general classification record');
                };

                DocumentService.removeClassification(classification.id).then(onsuccess, onerror);
            };


            $scope.addClassification = function() {
                var data = {classification: undefined, genClassTypes: $scope.genClassTypes};

                dialogs.create('documents/classifications/dialogs/addoredit.html', 'DocumentClassificationAddEditDialogCtrl', data, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(classification) {

                    var onsuccess = function(res) {
                        var data = res.data;
                        toastr.success('Success!', 'Document general classification has been created.');
                        DocumentService.getClassifications($scope.doc.docId).then(function(res) {
                            $scope.doc.classifications = res.data;
                        });
                    };
                    var onerror = function(res) {
                        toastr.error('Error!', 'Unable to create general classification record at this time. Please try later.');
                    };

                    DocumentService.addClassification($scope.doc.docId, classification).then(onsuccess, onerror);
                });
            };
        }
    ])
    .controller('DocumentClassificationAddEditDialogCtrl', ['$scope', '$uibModalInstance','data',
        function($scope, $uibModalInstance,  data) {
            $scope.classification = data.classification;
            $scope.genClassTypes = data.genClassTypes;
            $scope.cancel = function() {
                $uibModalInstance.dismiss('Canceled');
            };
            $scope.yes = function() {
                $uibModalInstance.close($scope.classification);
            };
        }
    ])
    .controller('DocumentPropertiesCtrl', ['$scope', '$state', '$stateParams', 'dialogs', 'DTOptionsBuilder', 'DocumentService',
        function($scope, $state, $stateParams, dialogs, DTOptionsBuilder, DocumentService) {
            DocumentService.getPropertyTypes()
                .then(function(res) {
                    $scope.propertyTypes = res.data;
                });

            $scope.dtOptions = DTOptionsBuilder
                .newOptions()
                .withBootstrap()
                .withOption('order', [0, 'desc']);

            $scope.addProperty = function() {
                $scope.property= {};
                $scope.property.type=  _.find($scope.propertyTypes, {'type': "CMSNUM"});
                var data = {property: $scope.property, propertyTypes: $scope.propertyTypes};

                dialogs.create('documents/properties/dialogs/addoredit.html', 'DocumentPropertyAddEditDialogCtrl', data, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'md'
                }).result.then(function(property) {
                    var onsuccess = function(res) {
                        var data = res.data;
                        toastr.success('Success!', 'Document property has been added.');
                        DocumentService.getProperties($scope.doc.docId).then(function(res) {
                            $scope.doc.properties = res.data;
                        });
                    };
                    var onerror = function(res) {
                        toastr.error('Error!', 'Unable to add document property at this time. Please try later.');
                    };

                    DocumentService.addProperty($scope.doc.docId, property).then(onsuccess, onerror);
                });
            };

            $scope.removeProperty = function(property) {
                var onsuccess = function(res) {
                    toastr.success('Success!', 'Document property has been deleted.');
                    $scope.doc.properties = _.reject($scope.doc.properties, {'id': property.id});

                };
                var onerror = function(res) {
                    toastr.error('Error!', 'Error deleting property record');
                };

                DocumentService.removeProperty(property.id).then(onsuccess, onerror);
            };

            $scope.updateProperty = function(property) {
                property.type=  _.find($scope.propertyTypes, {'type': property.type});
                var data = {property: property, propertyTypes: $scope.propertyTypes};

                dialogs.create('documents/properties/dialogs/addoredit.html', 'DocumentPropertyAddEditDialogCtrl', data, {
                    'keyboard': false,
                    'backdrop': 'static',
                    'size': 'lg'
                }).result.then(function(property) {

                    var onsuccess = function(res) {
                        toastr.success('Success!', 'Document property has been updated.');
                        DocumentService.getProperties($scope.doc.docId).then(function(res) {
                            $scope.doc.properties = res.data;
                        });
                    };
                    var onerror = function(res) {
                        toastr.error('Error!', 'Unable to update property record at this time. Please try later.');
                    };

                    DocumentService.updateProperty(property).then(onsuccess, onerror);
                });
            };

        }
    ])
    .controller('DocumentPropertyAddEditDialogCtrl', ['$scope', '$uibModalInstance','data',
        function($scope, $uibModalInstance,  data) {
            $scope.property = data.property;
            $scope.propertyTypes = data.propertyTypes;
            $scope.edit = data.edit;
            $scope.cancel = function() {
                $uibModalInstance.dismiss('Canceled');
            };
            $scope.yes = function() {
                $uibModalInstance.close($scope.property);
            };
        }
    ])
    /**
 * Document services
 */
.service('DocumentService', ['$http', '$q', '$window',
    function($http, $q, $window) {
        this.documents = {};
        this.doc = [];
        this.property = [];
        this.party = [];
        this.searchType = 'docInfo';

        this.search = function(document) {
            this.doc = document;
            return $http.get('staff/documents/search/', {
                params: {
                    docId: document.docId,
                    authDate: document.authDate,
                    recvDate: document.recvDate,
                    subject: document.subject,
                    keywords: document.keywords,
                    classType: document.classType
                }
            });
        };

        this.searchByProperty = function(property) {
            this.property = property;
            return $http.get('staff/documents/searchByProperty/', {
                params: {
                    propertyType: property.type.type,
                    value: property.value
                }
            });
        };

        this.searchByParty = function(party) {
            this.party = party;
            return $http.get('staff/documents/searchByParty/', {
                params: {
                    id: party.id,
                    ciprId: party.ciprId,
                    name: party.name,
                    licenseId: party.licenseId
                }
            });
        };

        this.updateDocument = function(doc) {
            return $http.post('/staff/documents', {
                data: {
                    doc: doc
                }
            });
        };

        this.createDocument = function(doc) {
            return $http.put('/staff/documents', {
                data: {
                    doc: doc
                }
            });
        };

        this.getClassificationTypes = function() {
            return $http.get('/staff/documents/classificationtypes', {cache: true});
        };

        this.getGeneralClassificationTypes = function() {
            return $http.get('/staff/documents/generalclassificationtypes', {cache: true});
        };

        this.getPropertyTypes = function() {
            return $http.get('/staff/documents/propertytypes', {cache: true});
        };

        this.getProperties = function(docId) {
            return $http.get('/staff/documents/' + docId + '/properties/');
        };

        this.removeProperty = function(propertyId) {
            return $http.delete('/staff/documents/properties/' + propertyId);
        };

        this.addProperty = function(docId, property) {
            return $http.put('/staff/documents/' + docId + '/properties/', {
                data: {
                    property: property
                }
            });
        };

        this.updateProperty = function(property) {
            return $http.post('/staff/documents/properties/' + property.id, {
                data: {
                    property: property
                }
            });
        };

        this.getClassifications = function(docId) {
            return $http.get('/staff/documents/' + docId + '/classifications/');
        };

        this.removeClassification = function(classificationId) {
            return $http.delete('/staff/documents/classifications/' + classificationId);
        };

        this.addClassification = function(docId, classification) {
            return $http.put('/staff/documents/' + docId + '/classifications/', {
                data: {
                    classification: classification
                }
            });
         };

        this.updateClassification = function(classification) {
            return $http.post('/staff/documents/classifications/' + classification.id, {
                data: {
                    classification: classification
                }
            });
        };

        this.getDocRoles = function(docId) {
            return $http.get('/staff/documents/' + docId + '/docroles/');
        };

        this.removeDocRole = function(id) {
            return $http.delete('/staff/documents/docroles/' + id);
        };

        this.addDocRole = function(docId, role) {
            return $http.put('/staff/documents/' + docId + '/docroles/', {
                data: {
                    role: role
                }
            });
        };
    }
])
.service('DocumentResolverService', ['$resource', '$http', 'DocumentService',
    function($resource, $http, DocumentService) {

        /**
         * Returns a promise which resolves a document object.
         *
         * @param docId
         * @returns {*}
         */
        this.get = function(docId) {
            return $http.get('/staff/documents/' + docId)
                .then(function(res) {
                    return res.data;
                });
        };
    }
]);

