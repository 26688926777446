var nsf = angular.module('aic.nsf', [
    'ngResource',
    'ngCookies',
    'ngSanitize',
    'angularFileUpload',
    'ui.bootstrap',
    'ui.router',
    'ui.select',
    'blockUI',
    'aic.address',
    'aic.eando',
    'aic.invoices',
    'aic.ng-jquery',
    'aic.directives',
    'aic.filters',
    'aic.services',
    'dialogs.main',
    'dialogs.default-translations',
    'oitozero.ngSweetAlert'
]);

/**
 * Routing and config
 */
nsf.config(['$httpProvider', '$stateProvider', 'blockUIConfig',
    function($httpProvider, $stateProvider, blockUIConfig) {

        blockUIConfig.autoInjectBodyBlock = false;


    }
])
.controller('NsfAddEditDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data',
    function($scope, $http, $uibModalInstance, $filter, data) {
        $scope.datePickerFormat = 'MM dd, yyyy';
        $scope.nsf = data.nsf || {scnrio_cd: "FLIC", nsf_fee: 50.00};
        $scope.nsfStatuses = data.nsfStatuses;
        $scope.nsfAccounts = data.nsfAccounts;
        $scope.party = data.party;
        var ciprid = data.ciprid;
        $scope.nsf.nsfDate = (data.nsf && data.nsf.nsf_dt) ? moment(data.nsf.nsf_dt, 'YYYY-MM-DD').format('MMMM DD, YYYY') :  moment().format('MMMM DD, YYYY');
        $scope.validAmount = false;
        $scope.validLastPaidAmount = false;

        $http.get('/' + ciprid + '/account/balancebyaccntandparty',
        {
            params: {
                accntCD: "NSF_RCV_ACCT",
                stkId: $scope.party.partyType == 'PERSON' ? $scope.party.partyid : null,
                orgId: $scope.party.partyType == 'ORG' ? $scope.party.orgId : null
            }
        })
        .then(function(res) {
            $scope.nsfAccountBalance = res.data[0];
            $scope.isValidLastPaidAmount();
        });



        $scope.setNsfDate = function(selectedDate) {
            $scope.nsf.nsfDate = $filter('date')(selectedDate, 'MMMM dd, yyyy');
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            if(!$scope.isValidNsfForm) return;
            $uibModalInstance.close($scope.nsf);
        };

        $scope.isValidAmount = function(){
            $scope.validAmount = parseFloat($scope.nsf.amt) > 0;
        };



        $scope.isValidLastPaidAmount = function(){
            if($scope.nsfAccountBalance.balance && $scope.nsf.balance && $scope.nsf.last_paid_amt) {
                $scope.validLastPaidAmount = parseFloat($scope.nsfAccountBalance.balance) >= $scope.nsf.last_paid_amt && parseFloat($scope.nsf.balance) >= $scope.nsf.last_paid_amt;
            } else {
                $scope.validLastPaidAmount = false;
            }
        };

        $scope.isValidNsfForm = function(){
            if (!$scope.isValidAmount())
                return false;
            if($scope.nsf.id && !$scope.isValidLastPaidAmount())
                return false;
            return true;
        };

        $scope.isChangable = function(nsf){
            return _.includes(["FLIC", "FEXM", "FMSC", "FCEE"], nsf.scnrio_cd.trim());
        };

    }
])
.controller('NsfWoDialogCtrl', ['$scope', '$http', '$uibModalInstance', '$filter', 'data','SweetAlert',
    function($scope, $http, $uibModalInstance, $filter, data, SweetAlert) {
        $scope.nsf = data.nsf;
        $scope.party = data.party;
        var ciprid = data.ciprid;

        $scope.cancel = function() {
            $uibModalInstance.dismiss('Canceled');
        };

        $scope.yes = function() {
            SweetAlert.swal({
                title: "Write Off NSF.",
                text: "Are you sure you want to write off the nsf? You can't undo this action.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f2784b",
                confirmButtonText: "Yes!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(confirmed) {
                if(confirmed)
                    $uibModalInstance.close($scope.reason);
                else
                    $uibModalInstance.dismiss('Canceled');

            });


        };

    }
])
.service('NsfService', ['$http', '$q',
    function($http, $q) {
        this.getNsfs = function(partyType, partyId) {
            return $http.get('/staff/nsf/', {
                params: {
                    partyType: partyType,
                    partyId: partyId
                }
            });
        };


        this.getNsfAccounts = function() {
            return $http.get('/staff/nsf/accounts', {cache: true});
        };

        this.getNsfStatuses = function() {
            return $http.get('/staff/nsf/statuses', {cache: true});
        };

        this.createNsf = function(partyType, partyId, nsf) {
            return $http.put('/staff/nsf/', {
                data: {
                    partyType: partyType,
                    partyId: partyId,
                    nsf: nsf
                }
            });
        };

        this.updateNsf = function(partyType, partyId, nsf) {
            return $http.post('/staff/nsf/' + nsf.id, {
                data: {
                    partyType: partyType,
                    partyId: partyId,
                    nsf: nsf
                }
            });
        };

        this.writeOffNsf = function(partyType, partyId, nsf, reason) {
            return $http.post('/staff/nsf/' + nsf.id + '/writeOff', {
                data: {
                    partyType: partyType,
                    partyId: partyId,
                    nsf: nsf,
                    reason: reason
                }
            });
        };

    }
]);